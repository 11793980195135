var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder",
      class: { "input-field--error": _vm.error },
    },
    [
      !_vm.error
        ? _c(
            "label",
            {
              staticClass: "input-field__label",
              class: { "input-field__label--show": _vm.isInput },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("google-maps-autocomplete", {
        staticClass: "input-field__input",
        class: {
          "input-field__input--padding-bottom": _vm.error,
          "input-field__input--padding-top": _vm.isInputValid,
        },
        attrs: {
          options: _vm.options,
          placeholder: _vm.placeholder,
          value: _vm.value,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          place_changed: function ($event) {
            return _vm.onPlaceChanged($event)
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }