<template>
    <div class="h100">
        <stoper-controls :is-panel-gate="isPanelGate" />

        <stoper-numbers />
        <div
            v-if="
                stoperStarted &&
                    (stoperMeasures.length || stoperOfflineMeasures.length)
            "
            class="row justify-content-end"
        >
            <span
                class="stoper-export"
                :class="{ 'stoper-export--gate': $route.name === 'panel-gate' }"
                @click="exportMeasures()"
            >Export pomiarów</span>
        </div>
        <!-- <stoper-search /> -->
        <stoper-prices
            v-if="
                competition &&
                    !stoperMeasures.length &&
                    !stoperOfflineMeasures.length &&
                    isDesktop
            "
            :competition="competition"
        />
        <stoper-list
            v-else
            :is-large-screen="isLargeScreen"
        />
        <stoper-keyboard
            :is-panel-gate="isPanelGate"
            :is-large-screen="isLargeScreen"
        />
    </div>
</template>

<script>
import StoperControls from '@/components/StoperControls';
import StoperNumbers from '@/components/StoperNumbers';
// import StoperSearch from "@/components/StoperSearch";
import StoperList from '@/components/StoperList';
import StoperPrices from '@/components/StoperPrices';
import StoperKeyboard from '@/components/StoperKeyboard';
import { mapGetters } from 'vuex';
import { downloadCsv, dataToCsv } from '@/utils/files';

export default {
    components: {
        StoperControls,
        StoperNumbers,
        StoperList,
        // StoperSearch,
        StoperKeyboard,
        StoperPrices,
    },
    props: {
        isPanelGate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLargeScreen: false,
            isDesktop: true,
        };
    },
    computed: {
        ...mapGetters([
            'competitions',
            'stoperGates',
            'stoperOfflineMeasures',
            'stoperMeasures',
            'stoperDurationMeasure',
            'stoperPlayers',
            'stoperGate',
            'stoperStarted',
        ]),
        competition() {
            return this.competitions[this.stoperGates[0].competition];
        },
    },
    created() {
        this.measureScreenHeight();
        if (window.innerWidth < 992) {
            this.isDesktop = false;
        }
    },
    methods: {
        measureScreenHeight() {
            this.isLargeScreen = window.screen.height >= 730;
        },
        // @TODO move to file.
        createData(measures) {
            const data = [];
            let i = 1;
            let position = 1;
            data[0] = [
                'Pozycja',
                'Numer zawodnika',
                'Czas',
                'Czy pomiar usuniety',
            ];
            for (const measure of measures) {
                data[i] = [];
                i += 1;
                let j = 0;
                const player = this.stoperPlayers.find(
                    (stoperPlayer) => stoperPlayer.playerNumber
                        === Number.parseInt(measure.tag, 10),
                );
                let duration;
                let time;
                if (player) {
                    duration = this.stoperDurationMeasure(measure, player);
                    time = this.$options.filters.asStoper(duration);
                }
                const playerMeasure = {
                    position,
                    number: player?.playerNumber ?? 'brak',
                    time: time ?? 'brak',
                    isRemoved: measure.invalidated ? 'Tak' : 'Nie',
                };
                position += 1;
                for (const info of Object.values(playerMeasure)) {
                    data[i - 1][j] = info;
                    j += 1;
                }
            }
            return data;
        },
        exportMeasures() {
            const measures = this.stoperMeasures.concat(
                this.stoperOfflineMeasures,
            );
            const sortedMeasures = Object.values(measures).sort((a, b) => a.deviceTime.diff(b.deviceTime));
            const data = this.createData(sortedMeasures);
            const csv = dataToCsv(data);
            downloadCsv(
                `urzadzenie-${this.stoperGate.device}-bramka-${this.stoperGate.id}`,
                csv,
            );
        },
    },
};
</script>
<style scoped>
.stoper-export {
    text-decoration: underline;
    color: var(--color-cool-grey);
    cursor: pointer;
    margin: 15px 0;
}
.stoper-export--gate {
    margin-right: 15px;
}
</style>
