export function hexToRgb(hex) {
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);

    return [r, g, b];
}

export function toUpperCaseFirst(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function toSnakeCase(text) {
    return text.split(/(?=[A-Z])/).join('_').toLowerCase();
}

export function linkify(text) {
    const detectHttpHttpsFtp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~|!:,.;]*[-A-Z0-9+&@#/%=~|])/gim;
    const detectWww = /(^|[^/])(www.[\S]+(\b|$))/gim;
    const detectMailto = /(([a-zA-Z0-9-_.])+@[a-zA-Z_]+?(.[a-zA-Z]{2,6})+)/gim;

    return text
        .replace(detectHttpHttpsFtp, '<a href="$1" target="_blank">$1</a>')
        .replace(detectWww, '$1<a href="http://$2" target="_blank">$2</a>')
        .replace(detectMailto, '<a href="mailto:$1">$1</a>');
}
