<template>
    <div>
        <div class="info-box--grey pa16 matb15">
            <div class="row justify-content-space-between align-items-center">
                <strong class="col-12 col-lg-9">{{ say.add_members_to_your_family }}</strong>
                <div class="col-12 col-lg-3">
                    <base-button
                        class="btn-add"
                        btn1
                        @click="$router.push({name: 'user-family-children-add', alias: true})"
                    >
                        {{ say.add_member }}
                    </base-button>
                </div>
            </div>
        </div>

        <div class="pat5 pab40">
            <user-family-item-child
                v-for="association of childrenAssociations"
                :id="association.id"
                :key="association.id"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserFamilyItemChild from '@/components/UserFamilyItemChild';
import { READ_USER_CHILDREN } from '@/store/actions.type';

export default {
    components: {
        UserFamilyItemChild,
    },
    computed: {
        ...mapGetters(['say', 'user', 'childrenAssociationsBy']),
        childrenAssociations() {
            return this.childrenAssociationsBy(this.user);
        },
    },
    async created() {
        await this.READ_USER_CHILDREN();
    },
    methods: {
        ...mapActions([READ_USER_CHILDREN]),
    },
};
</script>

<style scoped>
.btn-add {
    margin-top: 16px;
}
@media (min-width: 992px) {
    .btn-add {
        margin-top: 0;
    }
}
</style>
