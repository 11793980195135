import api from '@/api';
import {
    SEND_CONTACT_MAIL,
    SEND_PAYLINK_MAIL,
    BOOTSTRAP_MINISITE,
    READ_COMPETITION,
    ADD_FILES,
    CREATE_FILES,
    DECLARE_RESULT,
    READ_DECLARE_RESULT_CONFIG,
    CREATE_PLAYER_ADDRESS,
    CREATE_RESULT,
    DECLARE_ADDRESS,
    DECLARE_FILES,
    CREATE_BASE_POINT,
    CREATE_LOCATION_POINT,
    SET_PLAYERS_REGISTERED,
    SET_PLAYERS_PAID,
    DECLARE_PLAYERS_REGISTERED,
    DECLARE_PLAYERS_PAID,
} from '@/store/actions.type';
import { SET_ENTITIES, SET_MINISITE } from '@/store/mutations.type';

export default {
    state: {
        id: null,
        players: {
            registered: null,
            paid: null
        }
    },
    getters: {
        minisiteId: (state) => state.id,
        registeredPlayers: (state) => state.players.registered,
        paidPlayers: (state) => state.players.paid,
    },
    mutations: {
        [SET_MINISITE](state, id) {
            state.id = id;
        },
        [SET_PLAYERS_REGISTERED](state, registered) {
            state.players.registered = registered;
        },
        [SET_PLAYERS_PAID](state, paid) {
            state.players.paid = paid;
        },
    },
    actions: {
        async [BOOTSTRAP_MINISITE]({ dispatch, commit }, id) {
            commit(SET_MINISITE, null);

            await dispatch(READ_COMPETITION, id);

            commit(SET_MINISITE, id);
        },
        async [ADD_FILES]({ dispatch }, { token, files }) {
            await dispatch(CREATE_FILES, {
                token,
                files,
            });
        },
        async [SEND_CONTACT_MAIL]({ state }, mail) {
            await api.post(`/contact-mail/${state.id}`, mail);
        },
        async [SEND_PAYLINK_MAIL](context, player) {
            await api.post(`/paylink-mail/${player.id}`);
        },
        async [READ_DECLARE_RESULT_CONFIG](context, id) {
            const data = await api.get(`/competitions/${id}/remote`);

            return data;
        },
        // Declare actions returns only errors.
        async [DECLARE_RESULT]({ dispatch }, { result, token }) {
            try {
                await dispatch(CREATE_RESULT, { token, result });
            } catch (e) {
                return e;
            }
            return null;
        },
        async [DECLARE_ADDRESS]({ dispatch }, { address, token }) {
            try {
                await dispatch(CREATE_PLAYER_ADDRESS, { address, token });
            } catch (e) {
                return e;
            }
            return null;
        },
        async [DECLARE_FILES]({ dispatch }, { files, token }) {
            try {
                await dispatch(CREATE_FILES, {
                    token,
                    files,
                    isResult: true,
                });
            } catch (e) {
                return e;
            }
            return null;
        },
        // TODO remove if api will create default trackpoint with location.
        [CREATE_BASE_POINT]({ commit }, payload) {
            /**
             * payload:
             *  classifications - Array
             *  type - String
             *  locationId - Number
             */
            const trackpoint = {
                id: Math.random(),
                classifications: payload.classifications,
                type: payload.type,
                location: payload.locationId,
            };
            commit(SET_ENTITIES, { trackpoints: { [trackpoint.id]: trackpoint } });
            const point = {
                location: payload.locationId,
                trackpoints: {
                    STA: trackpoint.id,
                },
            };

            return point;
        },
        [CREATE_LOCATION_POINT]({ commit }, locationInfo) {
            const location = {
                id: Math.random(),
                lat: locationInfo.lat,
                lng: locationInfo.lng,
            };
            commit(SET_ENTITIES, { locations: { [location.id]: location } });

            return location;
        },
        [DECLARE_PLAYERS_REGISTERED]({ commit }, registered) {
            commit(SET_PLAYERS_REGISTERED, registered);
        },
        [DECLARE_PLAYERS_PAID]({ commit }, paid) {
            commit(SET_PLAYERS_PAID, paid);
        },
    },
};
