var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-players" }, [
    _c("div", { staticClass: "chart-players__info" }, [
      _c("p", { staticClass: "chart-players__info-value" }, [
        _vm._v(" " + _vm._s(_vm.summary) + " "),
      ]),
      _c("p", { staticClass: "chart-players__info-text" }, [
        _vm._v(" " + _vm._s(_vm.description) + " "),
      ]),
    ]),
    _c("canvas", { ref: "ctx", attrs: { width: "262", height: "262" } }),
    _vm.isMobile
      ? _c("p", { staticClass: "chart-players__hint" }, [
          _vm._v(" " + _vm._s(_vm.description) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }