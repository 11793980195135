var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.role && Object.keys(_vm.role).length > 0
    ? _c(
        "div",
        { staticClass: "mat40" },
        [
          _vm.submitSuccess
            ? _c("PanelInfoBox", [
                _c("img", {
                  staticStyle: { "max-height": "48px" },
                  attrs: {
                    src: require("../assets/images/icons/checkmark-circle.svg"),
                  },
                }),
                _c("p", { staticClass: "text-lg" }, [
                  _c("b", [_vm._v("Dane zostały poprawnie zapisane")]),
                ]),
              ])
            : _vm._e(),
          _vm.organizerId && _vm.showDataUpdateInfo
            ? _c("div", { attrs: { classs: "row" } }, [_vm._m(0)])
            : _vm._e(),
          _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c(
                  "h2",
                  [
                    _vm._v("Dane "),
                    _vm.organizerId
                      ? [_vm._v("organizatora")]
                      : _vm.operatorId
                      ? [_vm._v("operatora")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm.organizerId
                ? _c(
                    "div",
                    { staticClass: "row", staticStyle: { width: "100%" } },
                    [
                      _c("div", { staticClass: "col-12 col-md-6 mab16" }, [
                        _c("p", { staticClass: "form-input__label" }, [
                          _vm._v("Osobowość prawna organizatora"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "radio-field radio-field__row" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.legalType,
                                  expression: "form.legalType",
                                },
                              ],
                              staticClass: "d-none",
                              attrs: {
                                id: "private",
                                type: "radio",
                                value: "private",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.legalType, "private"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "legalType",
                                    "private"
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "radio-field__label",
                                attrs: { for: "private" },
                              },
                              [_vm._v("Osoba prywatna")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.legalType,
                                  expression: "form.legalType",
                                },
                              ],
                              staticClass: "d-none",
                              attrs: {
                                id: "company",
                                type: "radio",
                                value: "company",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.legalType, "company"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "legalType",
                                    "company"
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "radio-field__label",
                                attrs: { for: "company" },
                              },
                              [_vm._v("Firma / Fundacja / Stowarzyszenie")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.form.legalType === "company"
                ? _c(
                    "div",
                    {
                      staticClass: "row mab16",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c("p", { staticClass: "form-input__label" }, [
                            _vm._v("NIP"),
                          ]),
                          _c("base-input-text", {
                            model: {
                              value: _vm.form.fvNip,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fvNip", $$v)
                              },
                              expression: "form.fvNip",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "malr24" }, [
                          _c(
                            "p",
                            { staticClass: "form-input__helper" },
                            [
                              _vm._v("Dane "),
                              _vm.organizerId
                                ? [_vm._v("organizatora")]
                                : _vm.operatorId
                                ? [_vm._v("operatora")]
                                : _vm._e(),
                              _vm._v(
                                " wymagane są do zawarcia umów powerzenia przetwarzania danych osobowych uczestników oraz w przypadku uruchomienia płatności online, umów z operatorem płatności."
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.form.legalType === "company"
                ? _c(
                    "div",
                    { staticClass: "row", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6 mab16" },
                        [
                          _c("p", { staticClass: "form-input__label" }, [
                            _vm._v("Nazwa firmy"),
                          ]),
                          _c("base-input-text", {
                            model: {
                              value: _vm.form.fvFirmname,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fvFirmname", $$v)
                              },
                              expression: "form.fvFirmname",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 mab16" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Imię"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.firstname,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "firstname", $$v)
                          },
                          expression: "form.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 mab16" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Nazwisko"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.lastname,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "lastname", $$v)
                          },
                          expression: "form.lastname",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 mab16" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Miejscowość"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.fvCity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fvCity", $$v)
                          },
                          expression: "form.fvCity",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 mab16" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Kod pocztowy"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.fvPostcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fvPostcode", $$v)
                          },
                          expression: "form.fvPostcode",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 mab16" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Ulica"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.fvAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fvAddress", $$v)
                          },
                          expression: "form.fvAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "row unset mab16",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-8 col-md-4" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Nr domu"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.fvHouseNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fvHouseNumber", $$v)
                          },
                          expression: "form.fvHouseNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-4 col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "mal8" },
                      [
                        _c("p", { staticClass: "form-input__label" }, [
                          _vm._v("Nr lokalu"),
                        ]),
                        _c("base-input-text", {
                          model: {
                            value: _vm.form.fvFlatNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fvFlatNumber", $$v)
                            },
                            expression: "form.fvFlatNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c(
                  "h2",
                  [
                    _vm._v("Publiczne dane "),
                    _vm.organizerId
                      ? [_vm._v("organizatora")]
                      : _vm.operatorId
                      ? [_vm._v("operatora")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "p",
                        { staticClass: "form-input__label" },
                        [
                          _vm._v("Nazwa "),
                          _vm.organizerId
                            ? [_vm._v("organizatora")]
                            : _vm.operatorId
                            ? [_vm._v("operatora")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("div", { staticClass: "malr24" }, [
                      _c(
                        "p",
                        { staticClass: "form-input__helper" },
                        [
                          _vm._v(
                            "Wypełnij dane kontaktowe, jeśli chcesz udostępnić uczestnikom inne dane niż swój adres e-mail używany do logowania do panelu i/lub innej nazwy "
                          ),
                          _vm.organizerId
                            ? [_vm._v("organizatora")]
                            : _vm.operatorId
                            ? [_vm._v("operatora")]
                            : _vm._e(),
                          _vm._v(" niż Imię i Nazwisko."),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Adres e-mail"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Numer telefonu"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
            _c("div", { staticClass: "row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { disabled: _vm.loading, btn1: "", short: "" },
                      on: { click: _vm.saveForm },
                    },
                    [_vm._v("Zapisz")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.isModerator && _vm.organizerId
            ? _c(
                "div",
                [
                  _c(
                    "panel-competition-form-box",
                    { staticClass: "panel-form" },
                    [
                      _c("div", { staticClass: "col-12 mab24" }, [
                        _c("h2", [_vm._v("Dane Logowania")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row mab16",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-12 col-md-6" },
                            [
                              _c("p", { staticClass: "form-input__label" }, [
                                _vm._v("Adres e-mail logowania organizatora"),
                              ]),
                              _c("base-input-text", {
                                model: {
                                  value: _vm.org.userEmail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.org, "userEmail", $$v)
                                  },
                                  expression: "org.userEmail",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c("div", { staticClass: "malr24" }, [
                              _c("p", { staticClass: "form-input__helper" }, [
                                _vm._v(
                                  "Uzupełnij adres e-mail na który loguje się organizator"
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
                    _c("div", { staticClass: "row justify-content-end" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-4" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                disabled: _vm.loading,
                                btn1: "",
                                short: "",
                              },
                              on: { click: _vm.saveOrgEmailForm },
                            },
                            [_vm._v("Zmień")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mab24" }, [
      _c("div", { staticClass: "info-box info-bg" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "h3",
              { staticClass: "mab16", staticStyle: { "line-height": "1.1" } },
              [
                _vm._v(
                  "Uzupełnij dane organizatora i zaakceptuj umowę powierzenia przetwarzania danych osobowych."
                ),
              ]
            ),
            _c("p", [
              _vm._v(
                "Aby dostosować działanie systemu do obowiązujących przepisów, potrzebujemy danych organizatorów do wystawienia umów o przetwarzaniu danych osobowych uczestników."
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "info-box alert-bg" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("p", [
              _vm._v(
                "Od 1.01.2025 wejście do panelu organizatora będzie możliwe tylko po uzupełnieniu wymaganych danych i zaakceptowaniu umowy."
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }