var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder",
      class: { "input-field--error": _vm.error },
    },
    [
      !_vm.error
        ? _c(
            "label",
            {
              staticClass: "input-field__label",
              class: { "input-field__label--show": _vm.value },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("input", {
        staticClass: "input-field__input",
        class: {
          "input-field__input--padding-bottom": _vm.error,
          "input-field__input--padding-top": _vm.isInputValid,
        },
        attrs: {
          placeholder: _vm.label,
          type: _vm.hidden ? "password" : "text",
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
      _c("i", {
        staticClass: "input-field__icon",
        class: _vm.hidden ? "far fa-eye" : "far fa-eye-slash",
        on: {
          click: function ($event) {
            _vm.hidden = !_vm.hidden
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }