<template>
    <div class="row mat16">
        <!-- Default slot / left side. -->
        <div
            class="col-12"
            :class="{'input__left': half, 'col-md-3': half, 'col-md-6': !half}"
        >
            <slot />
        </div>

        <!-- Right side. -->
        <div
            v-if="half"
            class="col-12"
            :class="{'input__right': half, 'col-md-3': half, 'col-md-6': !half}"
        >
            <slot name="right" />
        </div>

        <!-- Hint. -->
        <div class="col-12 col-md-6 input__hint">
            <slot name="hint" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        half: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.input__hint {
    line-height: 1.43;
}

.input__right {
    margin-top: 16px;
}

@media (min-width: 768px) {
    .input__hint {
        box-sizing: border-box;
        padding-left: 30px;
    }
    .input__right {
        box-sizing: border-box;
        padding-left: 15px;
        margin-top: 0;
    }
    .input__left {
        box-sizing: border-box;
        padding-right: 15px;
    }
}
</style>
