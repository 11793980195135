var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn--info" }, [
    _c(
      "a",
      {
        staticClass: "abtn",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [_vm._t("default")],
      2
    ),
    _c("p", [_vm._t("info")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }