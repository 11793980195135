var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder",
      class: { "input-field--error": _vm.error },
    },
    [
      !_vm.error && _vm.label
        ? _c(
            "label",
            {
              staticClass: "input-field__label",
              class: { "input-field__label--show": _vm.isInput },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("v-select", {
        ref: "VSelect",
        staticClass: "autocompleter-competition__input",
        class: {
          "input-field__input--padding-top":
            (_vm.isInputValid || _vm.withoutErrorMessage) && _vm.label,
          "input-field__input--padding-bottom":
            _vm.error && !_vm.withoutErrorMessage,
        },
        attrs: {
          label: "text",
          options: _vm.suggestions,
          "no-drop": _vm.suggestions.length === 0,
          placeholder: _vm.placeholder,
          "append-to-body": true,
          value: _vm.value,
          filterable: false,
          "clear-search-on-select": _vm.clearSearchOnSelect,
          "clear-search-on-blur": _vm.clearSearchOnBlur,
          clearable: _vm.clearable,
        },
        on: {
          search: function ($event) {
            return _vm.$emit("input", $event)
          },
          input: function ($event) {
            return _vm.$emit("select", $event)
          },
          "search:blur": function ($event) {
            return _vm.$emit("search-blur")
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error && !_vm.withoutErrorMessage },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }