var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { overflow: "hidden" } },
    [
      !_vm.ready
        ? _c("main", [
            _c("div", { staticClass: "d-flex flex-column mat24" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(_vm.say.results_are_loading) + " "),
              ]),
              _vm._m(0),
            ]),
          ])
        : _c("div", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "panel-competition-result-toolbar none-mobile",
                    },
                    [
                      _c("div", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Międzyczasy"),
                        ]),
                        Object.values(_vm.splitTime).length
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "list-header__switch row" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-header__switch-btn col-5",
                                      class: {
                                        "list-header__switch-btn--right":
                                          _vm.isSplitTime,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isSplitTime = !_vm.isSplitTime
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isSplitTime ? "SPLIT" : "LAP"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "classificationsWrap" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Klasyfikacja"),
                          ]),
                          _c("base-input-select", {
                            staticClass: "minimal",
                            attrs: {
                              options: _vm.classificationsBy(_vm.competition),
                              clearable: false,
                              label: "Klasyfikacja",
                              "label-select": "namePl",
                            },
                            model: {
                              value: _vm.selectedClassification,
                              callback: function ($$v) {
                                _vm.selectedClassification = $$v
                              },
                              expression: "selectedClassification",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "categoryWrap" },
                        [
                          _vm.selectedClassification
                            ? _c("label", { attrs: { for: "" } }, [
                                _vm._v("Kategoria"),
                              ])
                            : _vm._e(),
                          _vm.selectedClassification
                            ? _c("base-input-select", {
                                staticClass: "minimal",
                                attrs: {
                                  options: _vm.categoriesBy(
                                    _vm.selectedClassification
                                  ),
                                  label: "Kategoria",
                                  "label-select": "namePl",
                                },
                                model: {
                                  value: _vm.selectedCategory,
                                  callback: function ($$v) {
                                    _vm.selectedCategory = $$v
                                  },
                                  expression: "selectedCategory",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "searchWrap" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v(" ")]),
                          _c("base-input-text-with-icon", {
                            staticClass: "minimal",
                            attrs: {
                              label: "Szukaj",
                              icon: "fas fa-search",
                              badge: "fas fa-times",
                            },
                            on: {
                              "icon-click": function ($event) {
                                _vm.search = ""
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Wgraj wyniki z pliku"),
                          ]),
                          _c(
                            "base-button",
                            {
                              staticClass: "minimal",
                              attrs: {
                                btn2: "",
                                icon: "fas fa-cloud-upload-alt",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "panel-competition-result-legacy",
                                    params: _vm.competition,
                                    alias: true,
                                  })
                                },
                              },
                            },
                            [_vm._v("Plik CSV")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Udostępnij wyniki"),
                          ]),
                          _c(
                            "base-button",
                            {
                              staticClass: "minimal",
                              attrs: { btn2: "", icon: "fas fa-file-export" },
                              on: { click: _vm.openShareModal },
                            },
                            [_vm._v("Pobierz, wyślij...")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "panel-competition-result-toolbar-mobile none-desktop",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wrap" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Klasyfikacja"),
                          ]),
                          _c("base-input-select", {
                            staticClass: "minimal",
                            attrs: {
                              options: _vm.classificationsBy(_vm.competition),
                              clearable: false,
                              label: "Klasyfikacja",
                              "label-select": "namePl",
                            },
                            model: {
                              value: _vm.selectedClassification,
                              callback: function ($$v) {
                                _vm.selectedClassification = $$v
                              },
                              expression: "selectedClassification",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "buttons-wrap" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: {
                              "is-active": _vm.buttonsTabs === "search",
                            },
                            attrs: { id: "search" },
                            on: {
                              click: function ($event) {
                                return _vm.showCompetitionPanel("search")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M20.8536 20.1464L16.9994 16.2923C18.2445 14.882 19 13.0292 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C13.0292 19 14.882 18.2445 16.2923 16.9994L20.1464 20.8536C20.3417 21.0488 20.6583 21.0488 20.8536 20.8536C21.0488 20.6583 21.0488 20.3417 20.8536 20.1464ZM18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                              _c("div", {
                                staticClass: "circle",
                                class: { "is-active": _vm.search !== "" },
                              }),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Szukaj"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: {
                              "is-active": _vm.buttonsTabs === "splitTime",
                            },
                            attrs: { id: "splitTime" },
                            on: {
                              click: function ($event) {
                                return _vm.showCompetitionPanel("splitTime")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.9069 14.7906C16.0674 14.5659 16.0153 14.2536 15.7906 14.0931L12.4355 11.6966L10.9824 6.36844C10.9097 6.10203 10.6349 5.94496 10.3684 6.01762C10.102 6.09028 9.94496 6.36515 10.0176 6.63156L11.5176 12.1316C11.5479 12.2427 11.6157 12.3399 11.7094 12.4069L15.2094 14.9069C15.4341 15.0674 15.7464 15.0153 15.9069 14.7906Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Międzyczasy"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: {
                              "is-active": _vm.buttonsTabs === "categories",
                            },
                            attrs: { id: "categories" },
                            on: {
                              click: function ($event) {
                                return _vm.showCompetitionPanel("categories")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "25",
                                    height: "24",
                                    viewBox: "0 0 25 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22ZM12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21ZM6.5 9.5C6.5 9.77614 6.72386 10 7 10H18C18.2761 10 18.5 9.77614 18.5 9.5C18.5 9.22386 18.2761 9 18 9H7C6.72386 9 6.5 9.22386 6.5 9.5ZM9 13C8.72386 13 8.5 12.7761 8.5 12.5C8.5 12.2239 8.72386 12 9 12H16C16.2761 12 16.5 12.2239 16.5 12.5C16.5 12.7761 16.2761 13 16 13H9ZM10.5 15.5C10.5 15.7761 10.7239 16 11 16H14C14.2761 16 14.5 15.7761 14.5 15.5C14.5 15.2239 14.2761 15 14 15H11C10.7239 15 10.5 15.2239 10.5 15.5Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                              _c("div", {
                                staticClass: "circle",
                                class: {
                                  "is-active": _vm.selectedCategory !== null,
                                },
                              }),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Kategoria"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: {
                              "is-active": _vm.buttonsTabs === "download",
                            },
                            attrs: { id: "download" },
                            on: { click: _vm.openShareModal },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M19.5 9.00003C19.2239 9.00003 19 8.77617 19 8.50003V7.00003H15.5C15.2239 7.00003 15 6.77617 15 6.50003V3.00003H6.5C5.67157 3.00003 5 3.6716 5 4.50003V19.5C5 20.3285 5.67157 21 6.5 21H17.5C18.3284 21 19 20.3285 19 19.5V17.5C19 17.2239 19.2239 17 19.5 17C19.7761 17 20 17.2239 20 17.5V19.5C20 20.8807 18.8807 22 17.5 22H6.5C5.11929 22 4 20.8807 4 19.5V4.50003C4 3.11931 5.11929 2.00003 6.5 2.00003H15.472C15.6048 1.99161 15.7429 2.03587 15.8536 2.14647L19.8536 6.14647C19.9642 6.25708 20.0084 6.39526 20 6.528V8.50003C20 8.77617 19.7761 9.00003 19.5 9.00003ZM18.2929 13H10.4861C10.21 13 9.98612 12.7762 9.98612 12.5C9.98612 12.2239 10.21 12 10.4861 12H18.2929L16.1464 9.85358C15.9512 9.65832 15.9512 9.34173 16.1464 9.14647C16.3417 8.95121 16.6583 8.95121 16.8536 9.14647L19.8536 12.1465C20.0488 12.3417 20.0488 12.6583 19.8536 12.8536L16.8536 15.8536C16.6583 16.0488 16.3417 16.0488 16.1464 15.8536C15.9512 15.6583 15.9512 15.3417 16.1464 15.1465L18.2929 13ZM16 6.00003V3.70713L18.2929 6.00003H16Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Pobierz"),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "buttons-content-wrap",
                          class: { "is-active": _vm.buttonsTabs !== null },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "buttons-content",
                              class: {
                                "is-active": _vm.buttonsTabs === "search",
                              },
                            },
                            [
                              _c("base-input-text-with-icon", {
                                staticClass: "minimal",
                                attrs: {
                                  label: "Szukaj",
                                  icon: "fas fa-search",
                                  badge: "fas fa-times",
                                },
                                on: {
                                  "icon-click": function ($event) {
                                    _vm.search = ""
                                  },
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "buttons-content",
                              class: {
                                "is-active": _vm.buttonsTabs === "splitTime",
                              },
                            },
                            [
                              Object.values(_vm.splitTime).length
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "list-header__switch row",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "list-header__switch-btn col-5",
                                              class: {
                                                "list-header__switch-btn--right":
                                                  _vm.isSplitTime,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isSplitTime =
                                                    !_vm.isSplitTime
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isSplitTime
                                                      ? "SPLIT"
                                                      : "LAP"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.isSplitTime
                                        ? _c("p", [
                                            _vm._v(
                                              "Wyświelnany jest czas łączny (narastający)"
                                            ),
                                          ])
                                        : _c("p", [
                                            _vm._v(
                                              "Wyświelnany jest czas odcinków lub okrążeń"
                                            ),
                                          ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "buttons-content",
                              class: {
                                "is-active": _vm.buttonsTabs === "categories",
                              },
                            },
                            [
                              _vm.selectedClassification
                                ? _c("base-input-select", {
                                    staticClass: "minimal",
                                    attrs: {
                                      options: _vm.categoriesBy(
                                        _vm.selectedClassification
                                      ),
                                      label: "Kategoria",
                                      "label-select": "namePl",
                                    },
                                    model: {
                                      value: _vm.selectedCategory,
                                      callback: function ($$v) {
                                        _vm.selectedCategory = $$v
                                      },
                                      expression: "selectedCategory",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm.competition.status === 4
                    ? _c(
                        "div",
                        {
                          staticClass: "panel-competition-players-on-the-route",
                        },
                        [
                          _c("div", [_vm._v(" Liczba uczestników na trasie ")]),
                          _c("div", [
                            _c("div", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.classificationPlayersOnTheRoute)
                                ),
                              ]),
                              _vm._v(" w wybranej klasyfikacji"),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "container-result" },
              [
                _vm.items.length
                  ? _c("base-table", {
                      staticClass: "table-list",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.items,
                        search: _vm.search,
                        sortColumnDefault: "0",
                        sortReverseDefault: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function ({ sort }) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "table-list__row bg--dark" },
                                  _vm._l(
                                    _vm.headers,
                                    function (header, column) {
                                      return _c(
                                        "th",
                                        {
                                          key: column,
                                          staticClass: "table-list__header",
                                          class: {
                                            "table-list__header--second-cell":
                                              column === 1,
                                            "table-list__header--first-cell":
                                              column === 0,
                                            "d-none":
                                              (column === 1 &&
                                                _vm.competition.isRemote &&
                                                _vm.competition.id !== 4877) ||
                                              (header.value ===
                                                "meta_summary" &&
                                                _vm.isSplitTime),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return sort(column)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.isSplitTime &&
                                          column === _vm.headers.length - 3 &&
                                          !_vm.competition.isRemote
                                            ? [_vm._v(" LAP ")]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(header.text) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                          },
                          {
                            key: "body",
                            fn: function ({ displayedItems }) {
                              return _vm._l(displayedItems, function (item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    staticClass: "table-list__row",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modal(item)
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.headers,
                                    function (header, column) {
                                      return _c(
                                        "td",
                                        {
                                          key: column,
                                          staticClass: "table-list__data",
                                          class: {
                                            "table-list__data--second-cell":
                                              column === 1,
                                            "table-list__data--first-cell":
                                              column === 0,
                                            "d-none":
                                              (column === 1 &&
                                                _vm.competition.isRemote &&
                                                _vm.competition.id !== 4877) ||
                                              (header.value ===
                                                "meta_summary" &&
                                                _vm.isSplitTime),
                                            "table-list__data--right-border":
                                              column === 0 &&
                                              _vm.competition.isRemote &&
                                              _vm.competition.id !== 4877,
                                            "table-list__data--padding":
                                              header.value === "type",
                                            "align-right":
                                              header.value === "isDeclaredTime",
                                          },
                                        },
                                        [
                                          header.value === "meta_summary"
                                            ? _c("p", [
                                                _vm._v(
                                                  " " + _vm._s(item.time) + " "
                                                ),
                                              ])
                                            : header.value ===
                                                "isDeclaredTime" &&
                                              item.isDeclaredTime
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "table-list__data--info-time",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.say.declared_time
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : header.value ===
                                                "isDeclaredDistance" &&
                                              item.isDeclaredDistance
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "table-list__data--info-time",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.say
                                                          .declared_distance
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : (header.value ===
                                                "isDeclaredTime" &&
                                                !item.isDeclaredTime) ||
                                              (header.value ===
                                                "isDeclaredDistance" &&
                                                !item.isDeclaredDistance)
                                            ? _c("p")
                                            : header.value === "distance" &&
                                              item[header.value]
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      `${item[header.value]} km`
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : column === 0
                                            ? [
                                                _vm.selectedCategory
                                                  ? _c("div", [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedCategoryPosition(
                                                              item.position
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  : _c("div", [
                                                      _vm._v(
                                                        _vm._s(item.position)
                                                      ),
                                                    ]),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[header.value]) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              })
                            },
                          },
                          {
                            key: "no-results",
                            fn: function ({ search: term }) {
                              return [
                                _c("p", { staticClass: "bg--gray pa16" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.say.no_search_results) +
                                      " "
                                  ),
                                  _c("b", [_vm._v(_vm._s(term))]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2641988004
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
      _c("modal", {
        ref: "modalName",
        attrs: { maxHeight: "" },
        on: { "modal-close": _vm.defaultModalData },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(" Korekta czasu ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "selected-player-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "selected-player-details",
                      style:
                        _vm.selectedPlayerMeasures &&
                        _vm.selectedPlayerMeasures.length > 0
                          ? ""
                          : "margin-bottom: 0",
                    },
                    [
                      _c("div", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Uczestnik"),
                        ]),
                        _c("b", [_vm._v(_vm._s(_vm.selectedPlayer.name))]),
                      ]),
                      _c("div", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Nr startowy"),
                        ]),
                        _c("b", [_vm._v(_vm._s(_vm.selectedPlayer.number))]),
                      ]),
                      _vm.selectedPlayer.position
                        ? _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Miejsce"),
                            ]),
                            _c("b", [
                              _vm._v(_vm._s(_vm.selectedPlayer.position)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.selectedCategory
                        ? _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                "Miejsce w kategorii " +
                                  _vm._s(_vm.selectedCategory.namePl)
                              ),
                            ]),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedCategoryPosition(
                                    _vm.selectedPlayer.position
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "selectedPlayerStatusSelect" },
                        [
                          _c("base-input-select", {
                            staticClass: "minimal",
                            attrs: {
                              options: _vm.playerStatus,
                              clearable: false,
                              label: "Wybierz status",
                              "label-select": "name",
                              error: _vm.errors.status,
                            },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(_vm.errors, "status", $event)
                              },
                              input: _vm.updatePlayerStatus,
                            },
                            model: {
                              value: _vm.selectedPlayerStatus,
                              callback: function ($$v) {
                                _vm.selectedPlayerStatus = $$v
                              },
                              expression: "selectedPlayerStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.selectedPlayerMeasures &&
                  _vm.selectedPlayerMeasures.length > 0
                    ? _c(
                        "div",
                        { staticClass: "selected-player-gates" },
                        [
                          _c(
                            "div",
                            { staticClass: "selected-player-gates__header" },
                            [
                              _c("div", [_vm._v("Bramka")]),
                              _c("div", [_vm._v("Czas urządzenia")]),
                              _c("div", [_vm._v("Czas poprawiony")]),
                              _c("div", [_vm._v(" ")]),
                            ]
                          ),
                          _vm._l(
                            _vm.selectedPlayerMeasures,
                            function (measure, index) {
                              return _c(
                                "div",
                                {
                                  key: measure.id,
                                  staticClass: "selected-player-gates__item",
                                  class: { "is-waiting": _vm.isWaiting },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "none-desktop" },
                                      [_vm._v("Bramka")]
                                    ),
                                    _vm._v(
                                      _vm._s(_vm.gateName(measure.gate)) +
                                        " (" +
                                        _vm._s(measure.gate) +
                                        ")"
                                    ),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "none-desktop" },
                                      [_vm._v("Czas urządz.")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: measure.invalidated
                                          ? "line-through"
                                          : "",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatTime(
                                              measure.deviceIntervalTime
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "none-desktop" },
                                        [_vm._v("Czas poprawiony")]
                                      ),
                                      _c("imask-input", {
                                        class: measure.invalidated
                                          ? "not-active"
                                          : "",
                                        attrs: {
                                          placeholder: "HH:MM:SS.XXX",
                                          mask: "00:00:00.000",
                                          name: `${measure.gate}_${measure.id}`,
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.activeSaveButton(
                                              measure.gate + "_" + measure.id
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.measures[
                                              measure.gate + "_" + measure.id
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.measures,
                                              measure.gate + "_" + measure.id,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "measures[ measure.gate + '_' + measure.id ]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "none-desktop",
                                        staticStyle: {
                                          opacity: "0",
                                          visibility: "hidden",
                                        },
                                      },
                                      [_vm._v("Opcje")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex items-center" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            ref:
                                              measure.gate + "_" + measure.id,
                                            refInFor: true,
                                            staticClass: "btn btn-disabled",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPlayerTime(
                                                  measure.gate,
                                                  measure.id,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Zapisz")]
                                        ),
                                        measure.invalidated
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "removeTime",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.invalidatedTime(
                                                      measure.id,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "18",
                                                      height: "18",
                                                      viewBox: "0 0 18 18",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M2.00001 3.34222C3.68698 1.25541 6.23675 0 9.00001 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9.00001 18C4.23548 18 0.303942 14.2867 0.016742 9.55304C1.84961e-05 9.27741 0.209908 9.0404 0.485544 9.02368C0.761179 9.00696 0.998183 9.21685 1.01491 9.49248C1.27013 13.6991 4.76502 17 9.00001 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9.00001 1C6.53059 1 4.25396 2.12713 2.75426 4H5.50001C5.77615 4 6.00001 4.22386 6.00001 4.5C6.00001 4.77614 5.77615 5 5.50001 5H1.50001C1.22387 5 1.00001 4.77614 1.00001 4.5V0.5C1.00001 0.223858 1.22387 0 1.50001 0C1.77615 0 2.00001 0.223858 2.00001 0.5V3.34222Z",
                                                        fill: "#455A64",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "removeTime",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.invalidatedTime(
                                                      measure.id,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "32",
                                                      height: "32",
                                                      viewBox: "0 0 32 32",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M16 15.2929L20.1464 11.1464C20.3417 10.9512 20.6583 10.9512 20.8536 11.1464C21.0488 11.3417 21.0488 11.6583 20.8536 11.8536L16.7071 16L20.8536 20.1464C21.0488 20.3417 21.0488 20.6583 20.8536 20.8536C20.6583 21.0488 20.3417 21.0488 20.1464 20.8536L16 16.7071L11.8536 20.8536C11.6583 21.0488 11.3417 21.0488 11.1464 20.8536C10.9512 20.6583 10.9512 20.3417 11.1464 20.1464L15.2929 16L11.1464 11.8536C10.9512 11.6583 10.9512 11.3417 11.1464 11.1464C11.3417 10.9512 11.6583 10.9512 11.8536 11.1464L16 15.2929Z",
                                                        fill: "#455A64",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "selected-player-addtime" }, [
                  _c("b", { staticClass: "title" }, [_vm._v("Dodaj czas")]),
                  _c("div", { staticClass: "selected-player-addtimeWrap" }, [
                    _c(
                      "div",
                      { staticClass: "selected-player-addtime__header" },
                      [
                        _c("div", [_vm._v("Bramka pomiarowa")]),
                        _c("div", [_vm._v("Czas")]),
                        _c("div", [_vm._v(" ")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "selected-player-addtime__item",
                        class: { "is-waiting": _vm.isWaiting },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("base-input-select", {
                              staticClass: "minimal",
                              attrs: {
                                options: _vm.gates,
                                clearable: false,
                                label: "Wybierz bramkę pomiarową",
                                "label-select": "name",
                                error: _vm.errors.gate,
                              },
                              on: {
                                "update:error": function ($event) {
                                  return _vm.$set(_vm.errors, "gate", $event)
                                },
                              },
                              model: {
                                value: _vm.selectedPlayerGate,
                                callback: function ($$v) {
                                  _vm.selectedPlayerGate = $$v
                                },
                                expression: "selectedPlayerGate",
                              },
                            }),
                            _vm.selectedPlayerGateCheckpoints
                              ? _c("div", [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "margin-bottom": "4px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Punkty pomiarowe przypisane do bramki"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b",
                                    _vm._l(
                                      _vm.selectedPlayerGateCheckpoints,
                                      function (checkpoint, index) {
                                        return _c(
                                          "span",
                                          { key: checkpoint.id },
                                          [
                                            index != 0
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                            _c("span", [
                                              _vm._v(_vm._s(checkpoint.name)),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("imask-input", {
                              attrs: {
                                placeholder: "HH:MM:SS.XXX",
                                mask: "00:00:00.000",
                              },
                              model: {
                                value: _vm.addGateTime,
                                callback: function ($$v) {
                                  _vm.addGateTime = $$v
                                },
                                expression: "addGateTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addPlayerTime },
                            },
                            [_vm._v("Dodaj")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", [_vm._v(" ")]),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.modalName.closeModal()
                      },
                    },
                  },
                  [_vm._v("Zamknij")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "shareModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Udostępnianie wyników")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "shareUrl" }, [
                  _c("p", [_vm._v("Lista wyników dostępna jest pod adresem")]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.competitionPageLink,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.competitionPageLink))]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "shareCarouselWrap",
                    class: { "is-waiting": _vm.isWaiting },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "shareCarousel",
                        attrs: { id: "carousel" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "shareCarouselItem",
                            class: { active: _vm.isShareItemActive === 1 },
                            attrs: { "data-id": "0" },
                            on: {
                              click: function ($event) {
                                _vm.isShareItemActive = 1
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v("Pobierz wszyskie wyniki")]),
                            _c("p", [
                              _vm._v(
                                "Pełna lista wyników, zebrana w jednym dokumencie"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "shareCarouselItem",
                            class: { active: _vm.isShareItemActive === 2 },
                            attrs: { "data-id": "1" },
                            on: {
                              click: function ($event) {
                                _vm.isShareItemActive = 2
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v("Pobierz podium kategorii")]),
                            _c("p", [
                              _vm._v("Lista medalowych miejsc w kategoriach"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "shareCarouselItem",
                            class: { active: _vm.isShareItemActive === 3 },
                            attrs: { "data-id": "2" },
                            on: {
                              click: function ($event) {
                                _vm.isShareItemActive = 3
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v("Wyślij do uczestników")]),
                            _c("p", [
                              _vm._v(
                                "Lista wyników, uwzględniająca ustawione filtry"
                              ),
                            ]),
                          ]
                        ),
                        _vm.competition.status > 3 &&
                        _vm.resultsLiveLink.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "shareCarouselItem",
                                class: { active: _vm.isShareItemActive === 4 },
                                attrs: { "data-id": "3" },
                                on: {
                                  click: function ($event) {
                                    _vm.isShareItemActive = 4
                                  },
                                },
                              },
                              [
                                _c("h4", [_vm._v("Wyniki live")]),
                                _c("p", [_vm._v("Lista wyników na żywo")]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "shareContent",
                    class: { "is-waiting": _vm.isWaiting },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "shareContentItem",
                        class: { active: _vm.isShareItemActive === 1 },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-4" }, [
                            _c("h3", [_vm._v("Format pliku")]),
                            _c("div", { staticClass: "radio-field" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shareFile,
                                    expression: "shareFile",
                                  },
                                ],
                                staticClass: "d-none",
                                attrs: {
                                  id: "pdf",
                                  type: "radio",
                                  value: "pdf",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.shareFile, "pdf"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.shareFile = "pdf"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "radio-field__label",
                                  attrs: { for: "pdf" },
                                },
                                [_vm._v("PDF")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shareFile,
                                    expression: "shareFile",
                                  },
                                ],
                                staticClass: "d-none",
                                attrs: {
                                  id: "csv",
                                  type: "radio",
                                  value: "csv",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.shareFile, "csv"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.shareFile = "csv"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "radio-field__label",
                                  attrs: { for: "csv" },
                                },
                                [_vm._v("CSV")]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c("h3", [_vm._v("Wyświetlanie międzyczasów")]),
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Międzyczasy"),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5" }, [
                                _c(
                                  "div",
                                  { staticClass: "list-header__switch row" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "list-header__switch-btn col-5",
                                        class: {
                                          "list-header__switch-btn--right":
                                            _vm.isSplitTimeResults,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isSplitTimeResults =
                                              !_vm.isSplitTimeResults
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isSplitTimeResults
                                                ? "SPLIT"
                                                : "LAP"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-6" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.isSplitTimeResults
                                      ? "Wyświetlnany jest czas łączny (narastający)"
                                      : "Wyświetlnany jest czas odcinków lub okrążeń"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [_vm._v(" ")]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shareContentItem",
                        class: { active: _vm.isShareItemActive === 2 },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c("h3", [
                              _vm._v("Liczba miejsc dla każdej kategorii"),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-5" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "label",
                                      attrs: { for: "" },
                                    },
                                    [_vm._v("Kategoria open")]
                                  ),
                                  _c("base-input-text", {
                                    model: {
                                      value: _vm.place_open,
                                      callback: function ($$v) {
                                        _vm.place_open = $$v
                                      },
                                      expression: "place_open",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-5" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "label",
                                      attrs: { for: "" },
                                    },
                                    [_vm._v("Kategoria wiekowe")]
                                  ),
                                  _c("base-input-text", {
                                    model: {
                                      value: _vm.place_category,
                                      callback: function ($$v) {
                                        _vm.place_category = $$v
                                      },
                                      expression: "place_category",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-top": "24px" },
                          },
                          [
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("h3", [
                                _vm._v(
                                  "Czy zawodnicy z klasyfikacji open mają pojawić się w kategoriach wiekowych?"
                                ),
                              ]),
                              _c("div", { staticClass: "radio-field" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.repeat,
                                      expression: "repeat",
                                    },
                                  ],
                                  staticClass: "d-none",
                                  attrs: {
                                    id: "yes_1",
                                    type: "radio",
                                    value: "yes_1",
                                  },
                                  domProps: {
                                    value: 1,
                                    checked: _vm._q(_vm.repeat, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.repeat = 1
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio-field__label",
                                    attrs: { for: "yes_1" },
                                  },
                                  [_vm._v("Tak")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.repeat,
                                      expression: "repeat",
                                    },
                                  ],
                                  staticClass: "d-none",
                                  attrs: {
                                    id: "no_1",
                                    type: "radio",
                                    value: "no_1",
                                  },
                                  domProps: {
                                    value: 0,
                                    checked: _vm._q(_vm.repeat, 0),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.repeat = 0
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio-field__label",
                                    attrs: { for: "no_1" },
                                  },
                                  [_vm._v("Nie")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("h3", [
                                _vm._v(
                                  "Czy na liście ma pojawić się podium z każdej klasyfikacji?"
                                ),
                              ]),
                              _c("div", { staticClass: "radio-field" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.all_classification,
                                      expression: "all_classification",
                                    },
                                  ],
                                  staticClass: "d-none",
                                  attrs: {
                                    id: "all_classification_yes_1",
                                    type: "radio",
                                    value: "yeall_classification_yes_1s_1",
                                  },
                                  domProps: {
                                    value: 1,
                                    checked: _vm._q(_vm.all_classification, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.all_classification = 1
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio-field__label",
                                    attrs: { for: "all_classification_yes_1" },
                                  },
                                  [_vm._v("Tak")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.all_classification,
                                      expression: "all_classification",
                                    },
                                  ],
                                  staticClass: "d-none",
                                  attrs: {
                                    id: "all_classification_no_1",
                                    type: "radio",
                                    value: "all_classification_no_1",
                                  },
                                  domProps: {
                                    value: 0,
                                    checked: _vm._q(_vm.all_classification, 0),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.all_classification = 0
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio-field__label",
                                    attrs: { for: "all_classification_no_1" },
                                  },
                                  [_vm._v("Nie")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shareContentItem",
                        class: { active: _vm.isShareItemActive === 3 },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c(
                              "h3",
                              { staticStyle: { "margin-bottom": "24px" } },
                              [_vm._v("Wybierz formę wysyłki")]
                            ),
                            _c("div", { staticClass: "radio-field" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shareResultsType,
                                    expression: "shareResultsType",
                                  },
                                ],
                                staticClass: "d-none",
                                attrs: {
                                  disabled: _vm.competition.status < 5,
                                  id: "email",
                                  type: "radio",
                                  value: "email",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.shareResultsType,
                                    "email"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.shareResultsType = "email"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "radio-field__label",
                                  attrs: { for: "email" },
                                },
                                [
                                  _vm._v("Email"),
                                  _vm.competition.status < 5
                                    ? _c("small", [
                                        _vm._v(
                                          "Wysyłka możliwa tylko, gdy zawody są w statusie: "
                                        ),
                                        _c("b", [
                                          _vm._v("oficjalnym, nieoficjalnym"),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shareResultsType,
                                    expression: "shareResultsType",
                                  },
                                ],
                                staticClass: "d-none",
                                attrs: {
                                  disabled: _vm.competition.status < 5,
                                  id: "sms",
                                  type: "radio",
                                  value: "sms",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.shareResultsType, "sms"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.shareResultsType = "sms"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "radio-field__label",
                                  attrs: { for: "sms" },
                                },
                                [
                                  _vm._v("SMS "),
                                  _vm.competition.status < 5
                                    ? _c("small", [
                                        _vm._v(
                                          "Wysyłka możliwa tylko, gdy zawody są w statusie: "
                                        ),
                                        _c("b", [
                                          _vm._v("oficjalnym, nieoficjalnym"),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                          Object.keys(_vm.smsQuantity).length > 0
                            ? _c("div", { staticClass: "col-12 col-md-5" }, [
                                _c(
                                  "h3",
                                  { staticStyle: { "margin-bottom": "8px" } },
                                  [
                                    _vm._v("Sms zostaną wysłane do "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.smsQuantity.smsesCount)
                                      ),
                                    ]),
                                    _vm._v(" uczestników"),
                                  ]
                                ),
                                _c("p", [
                                  _vm._v("Opłata wynosi: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.smsQuantity.price) + " zł"
                                    ),
                                  ]),
                                ]),
                                _c("p", [_vm._v("Wysyłana treść: ")]),
                                _c("p", [
                                  _vm._v('"'),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.smsQuantity.information)),
                                  ]),
                                  _vm._v('"'),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm.competition.status > 3 && _vm.resultsLiveLink.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "shareContentItem",
                            class: { active: _vm.isShareItemActive === 4 },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "h3",
                                  { staticStyle: { "margin-bottom": "8px" } },
                                  [_vm._v("Wyniki live")]
                                ),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.resultsLiveLink,
                                    function (live, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticStyle: {
                                            "margin-bottom": "16px",
                                          },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(live.name) +
                                                " (" +
                                                _vm._s(live.short) +
                                                ")"
                                            ),
                                          ]),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { display: "block" },
                                              attrs: {
                                                href: live.url,
                                                target: "_blank",
                                                rel: "noopener noreferrer",
                                              },
                                            },
                                            [_vm._v(_vm._s(live.url))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: { "is-waiting": _vm.isWaiting },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.shareModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
                _vm.isShareItemActive === 3
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        class: { "is-waiting": _vm.isWaiting },
                        on: { click: _vm.downloadResultsWrap },
                      },
                      [_vm._v("Wyślij")]
                    )
                  : _vm.isShareItemActive !== 4
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        class: { "is-waiting": _vm.isWaiting },
                        on: { click: _vm.downloadResultsWrap },
                      },
                      [_vm._v("Pobierz")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.loading
        ? _c("div", { staticClass: "loader" }, [
            _c("div", { staticClass: "loader__backdrop" }),
            _vm._m(1),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "mat16 lds-ellipsis lds-ellipsis--center" },
      [_c("div"), _c("div"), _c("div")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis lds-ellipsis--center" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }