import { SET_ENTITIES, CLEAR_ENTITIES } from '@/store/mutations.type';
import Vue from 'vue';

export default {
    [SET_ENTITIES](state, entities) {
        // eslint-disable-next-line guard-for-in
        for (const type in entities) {
            // eslint-disable-next-line guard-for-in
            for (const id in entities[type]) {
                // Patch update entity.
                const entity = {
                    ...(state[type][id] ?? {}),
                    ...(entities[type][id]),
                };
                Vue.set(state[type], id, entity);
            }
        }
    },
    [CLEAR_ENTITIES](state, entities) {
        // eslint-disable-next-line guard-for-in
        for (const type in entities) {
            for (const id of entities[type]) {
                // Delete entity.
                Vue.delete(state[type], id);
            }
        }
    },
};
