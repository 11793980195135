var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row panel-promote" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-4 col-lg-2" }, [
        _c("div", { staticClass: "panel-promote__box-img" }, [
          _vm.isFetching
            ? _c("div", { staticClass: "panel-promote__lack-poster" }, [
                _vm._m(1),
              ])
            : _vm.isPoster
            ? _c("img", {
                staticClass: "panel-promote__poster",
                attrs: { src: _vm.layout.posterLink, alt: "promotion poster" },
              })
            : _c("div", { staticClass: "panel-promote__lack-poster" }, [
                _vm._v(" Brak plakatu "),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "col-8 col-lg-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "panel-promote__box panel-promote__box--first" },
              [
                _c(
                  "p",
                  { staticClass: "panel-promote__box-label none-mobile" },
                  [_vm._v(" Nazwa zawodów ")]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "panel-promote__box-value panel-promote__box-value--link",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          path: `https://dostartu.pl/${_vm.competition.name}-v${_vm.competition.id}`,
                          tab: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.competition.name) + " ")]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "panel-promote__box panel-promote__box--start" },
              [
                _c("p", { staticClass: "panel-promote__box-label" }, [
                  _vm._v(" Start zawodów "),
                ]),
                _c("p", { staticClass: "panel-promote__box-value" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("asDate")(_vm.competition.startedTime)) +
                      " "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-4 panel-promote__actions" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "panel-promote__box" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "panel-promote__box-label panel-promote__box-label--date",
                  },
                  [_vm._v(" W promowanych od ")]
                ),
                _c("base-input-date", {
                  staticClass: "panel-promote__date",
                  attrs: { label: "DD.MM.RRRR" },
                  model: {
                    value: _vm.dateStart,
                    callback: function ($$v) {
                      _vm.dateStart = $$v
                    },
                    expression: "dateStart",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-12 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "panel-promote__box" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "panel-promote__box-label panel-promote__box-label--date",
                  },
                  [_vm._v(" W promowanych do ")]
                ),
                _c("base-input-date", {
                  staticClass: "panel-promote__date",
                  attrs: { label: "DD.MM.RRRR" },
                  model: {
                    value: _vm.dateEnd,
                    callback: function ($$v) {
                      _vm.dateEnd = $$v
                    },
                    expression: "dateEnd",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row panel-promote__buttons" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-6" },
            [
              _c(
                "base-button",
                {
                  staticClass: "panel-promote__btn",
                  attrs: { btn2: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-promote")
                    },
                  },
                },
                [_vm._v(" Anuluj ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-6" },
            [
              _c(
                "base-button",
                {
                  staticClass: "panel-promote__btn panel-promote__btn--promote",
                  attrs: { disabled: !_vm.isPoster, btn1: "" },
                  on: {
                    click: function ($event) {
                      return _vm.promote()
                    },
                  },
                },
                [_vm._v(" Promuj ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-2" }, [
      _c("h1", { staticClass: "panel-promote__title" }, [
        _vm._v(" Promuj zawody na dostartu.pl "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mal15 lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }