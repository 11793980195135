export const CLEAR_CLOCK = 'CLEAR_CLOCK';
export const CLEAR_STOPER_TICKER = 'CLEAR_STOPER_TICKER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_BOOTSTRAPED = 'SET_BOOTSTRAPED';
export const SET_CLOCK = 'SET_CLOCK';
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_FILTER_FUTURE_FINISH_PAGE = 'SET_FILTER_FUTURE_FINISH_PAGE';
export const SET_FILTER = 'SET_FILTER';
export const SET_LANG = 'SET_LANG';
export const SET_LANGS = 'SET_LANGS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_MINISITE = 'SET_MINISITE';
export const SET_STOPER_COMPETITION = 'SET_STOPER_COMPETITION';
export const SET_STOPER_GATE = 'SET_STOPER_GATE';
export const SET_STOPER_NUMBER = 'SET_STOPER_NUMBER';
export const SET_STOPER_NUMBERS = 'SET_STOPER_NUMBERS';
export const SET_MEASURE_NUMBER = 'SET_MEASURE_NUMBER';
export const SET_EDITING_MEASURE = 'SET_EDITING_MEASURE';
export const SET_IS_EDITING_MEASURE_NUMBER = 'SET_IS_EDITING_MEASURE_NUMBER';
export const SET_STOPER_TICKER = 'SET_STOPER_TICKER';
export const SET_TIME = 'SET_TIME';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_ZEN_MODE = 'SET_ZEN_MODE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';
export const SET_OFFLINE_MEASURE = 'SET_OFFLINE_MEASURE';
export const CLEAR_OFFLINE_MEASURE = 'CLEAR_OFFLINE_MEASURE';
export const SET_IS_ONLINE = 'SET_IS_ONLINE';
export const SAVE_PROMPT_EVENT = 'SAVE_PROMPT_EVENT';
export const CLEAR_PROMPT_EVENT = 'CLEAR_PROMPT_EVENT';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_CREATE_NOTIFICATIONS = 'SET_CREATE_NOTIFICATIONS';
export const SET_USER_MESSAGES = 'SET_USER_MESSAGES';
export const SET_IS_USER_MESSAGES = 'SET_IS_USER_MESSAGES';
export const SET_RESULTS_EDIT_STATUS = 'SET_RESULTS_EDIT_STATUS';
