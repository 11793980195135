var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c("main", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("minisite-sidebar"),
            _c("div", { staticClass: "col-12 col-md-9 mat33 pat15" }, [
              _c("div", { staticClass: "row minisite-contact" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h3", { staticClass: "pab16 inf" }, [
                    _vm._v(" " + _vm._s(_vm.say.contact_with_organizer) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 mab48" }, [
                  _c("div", { staticClass: "bgarea pa24" }, [
                    _c("div", { staticClass: "form-wrapper" }, [
                      _c("div", { staticClass: "row d-md-none mab16" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v(" " + _vm._s(_vm.say.do_you_want_ask) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "row mab16" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-input" },
                            [
                              _c("base-input-text", {
                                attrs: {
                                  label: _vm.say.firstname_and_lastname,
                                  error: _vm.errors.name,
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(_vm.errors, "name", $event)
                                  },
                                },
                                model: {
                                  value: _vm.mail.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.mail, "name", $$v)
                                  },
                                  expression: "mail.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6 d-md-block" },
                          [
                            _c("div", { staticClass: "form-description" }, [
                              _vm._v(
                                " " + _vm._s(_vm.say.do_you_want_ask) + " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row mab16" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-input" },
                            [
                              _c("base-input-text", {
                                attrs: {
                                  label: _vm.say.email_address,
                                  error: _vm.errors.email,
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(_vm.errors, "email", $event)
                                  },
                                },
                                model: {
                                  value: _vm.mail.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.mail, "email", $$v)
                                  },
                                  expression: "mail.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6 d-md-block" },
                          [
                            _c("div", { staticClass: "form-description" }, [
                              _vm._v(
                                " " + _vm._s(_vm.say.organizer_email) + ": "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.competition.contactEmail)),
                              ]),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(_vm.say.organizer_phone) + ": "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.competition.contactPhone)),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row mab16" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-input" },
                            [
                              _c("base-input-area", {
                                attrs: {
                                  label: _vm.say.message_content,
                                  rows: 5,
                                  error: _vm.errors.message,
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(
                                      _vm.errors,
                                      "message",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.mail.message,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.mail, "message", $$v)
                                  },
                                  expression: "mail.message",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row d-md-none mab16" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-description" }, [
                            _vm._v(
                              " " + _vm._s(_vm.say.organizer_email) + ": "
                            ),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.competition.contactEmail)),
                            ]),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.say.organizer_phone) + ": "
                            ),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.competition.contactPhone)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row justify-content-end" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-5 col-lg-4" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "mab20",
                          attrs: { btn1: "" },
                          on: {
                            click: function ($event) {
                              return _vm.send()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.say.send) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }