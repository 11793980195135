<template>
  <div class="rangeSlider__newWrap">
    <div class="rangeSlider__new"  @click="newRange" ref="newHandle"></div>
    <div id="newHandle">
      <div class="newLine"></div>
      <img src="@/assets/images/icons/range-slider/marker_new.svg" class="newMarker" ref="handle" alt="">
    </div>
  </div>
</template>

<script>
  import { gsap } from "gsap";

  export default {
    name: 'ageCategoriesRangeSliderNewHandle',
    props: {
      gap: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        handle: null,
      }
    },
    watch: {},
    computed: {},
    methods: {
      newRange() {
        this.$emit('new', {
          x: gsap.getProperty("#newHandle", "x")
        })
      }
    },
    mounted() {
      // window.addEventListener("mousemove", e => {
      //   gsap.to(this.$refs.newHandle, {
      //     duration: 0.35,
      //     x: e.pageX,
      //     y: 0,
      //     ease: "none",
      //     overwrite: "auto",
      //   });
      // });
      this.$refs.newHandle.onmousemove = (e) => {
        var x = e.offsetX;
        gsap.set("#newHandle",{x: Math.round(x / this.$props.gap) * this.$props.gap, autoRound: true});
      }
    }    
  }
</script>

<style scoped>
.rangeSlider__newWrap {
  position: relative;
}
.rangeSlider__new {
  position: relative;
  width: 100%;
  height: 36px;
  /* background-color: #f2f2f2; */
  z-index: 13;
  cursor: pointer;
}
#newHandle {
  position: absolute;
  left: -24px;
  bottom: -26px;
  display: none;
}
.newLine {
  position: absolute;
  border-left: 1px dashed #fff;
  width: 1px;
  height: 100%;
  top: -59px;
  left: 24px;
  z-index: -1;
}
.newMarker {
  cursor: pointer;
  position: relative;
  z-index: 14;
}
.rangeSlider__new:hover + #newHandle {
  display: block;
}
</style>