var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.ifUserMore
    ? _c("div", { staticClass: "subnav" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _vm.$router.matched("user-competitions", _vm.$route)
              ? [
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-competitions-proposed", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.your_proposed_competitions) + " "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-competitions-future", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.your_future_competitions) + " "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-competitions-past", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.your_past_competitions) + " ")]
                  ),
                ]
              : _vm.$router.matched("user-edit", _vm.$route)
              ? [
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-edit-profile", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.edit_personal_data) + " ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-edit-insurance", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.edit_insurance_data) + " ")]
                  ),
                ]
              : _vm.$router.matched("user-insurance", _vm.$route)
              ? [
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-insurance-policies", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.your_insurnace_policies) + " "
                      ),
                    ]
                  ),
                ]
              : _vm.$router.matched("user-family", _vm.$route)
              ? [
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-family-children", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.manage_family) + " ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "subnav__link",
                      attrs: {
                        to: { name: "user-family-parents", alias: true },
                        "active-class": "subnav__link--active",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.who_can_manage_me) + " ")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }