var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row bg--gray result__classification-item" },
    [
      _c(
        "div",
        { staticClass: "col-12 col-lg-4 result__classification-info" },
        [
          _c("div", [
            _c("h3", [_vm._v(_vm._s(_vm.classification.short))]),
            _c("p", [_vm._v(_vm._s(_vm.classification.namePl))]),
          ]),
          _c("div", { staticClass: "align-items-center" }, [
            _c("i", { staticClass: "fas fa-users f18" }),
            _c("p", { staticClass: "mal8" }, [
              _vm._v(" " + _vm._s(_vm.limits.playersUnavailable) + " "),
            ]),
          ]),
        ]
      ),
      _c("hr", { staticClass: "col-12 d-sm mab8" }),
      _vm.topPlayer && _vm.showTopResults
        ? _c("div", { staticClass: "col-12 col-lg-4 result__best-time" }, [
            _c("p", [_vm._v(_vm._s(_vm.say.best_time))]),
            _c("div", [
              _c("h3", [
                _vm._v(_vm._s(_vm._f("asStoper")(_vm.topPlayer.time))),
              ]),
              _c("p", { staticClass: "result__player-name mar8" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.topPlayer.firstname) +
                    " " +
                    _vm._s(_vm.topPlayer.lastname) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "result__player-country" }, [
                _vm._v(" " + _vm._s(_vm.topPlayer.country) + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.topPlayer && _vm.showTopResults
        ? _c("hr", { staticClass: "mat8 col-12 d-sm" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-12 col-lg-3 result__btn" },
        [
          _c(
            "base-button",
            {
              attrs: { btn2: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "minisite-results",
                    params: {
                      id: _vm.competition.id,
                      name: _vm.competition.name,
                      classificationId: _vm.classification.id,
                    },
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.say.see_results) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }