var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.operator
    ? _c("div", { staticClass: "operator-header__operator" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row operator-header__operator-box" }, [
            _c("div", { staticClass: "patb8" }, [
              _vm._v(" Twoje zawody obsługuje "),
              _c("b", [_vm._v(_vm._s(_vm.operator.name))]),
              _vm._v(" "),
              _vm.operator.phone
                ? _c("span", [
                    _vm._v("tel. "),
                    _c(
                      "a",
                      { attrs: { href: `tel:+48${_vm.operator.phone}` } },
                      [_vm._v(_vm._s(_vm.operator.phone))]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }