var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.label && _vm.label.length
      ? _c("p", { staticClass: "mab8" }, [
          _vm._v(" " + _vm._s(_vm.label)),
          _vm.optional
            ? _c("span", { staticClass: "positive" }, [_vm._v("(opcjonalnie)")])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("textarea", {
      staticClass: "input-field__textarea",
      attrs: { rows: _vm.rows },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _c(
      "span",
      { staticClass: "input-error", class: { "input-error--show": _vm.error } },
      [_vm._v(_vm._s(_vm.say[_vm.error]))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }