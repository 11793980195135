import operator from '@/api/schema/operator';
import { schema } from 'normalizr';

export default new schema.Entity('devices', {
    operator,
}, {
    processStrategy: (device) => ({
        // Meta.
        _type: 'device',
        // Fields.
        ...device,
    }),
});
