import { getDuration, isStarted } from '@/service/stoper';
import {
    ADD_STOPER_MEASURE,
    BOOTSTRAP_STOPER,
    CREATE_MEASURE,
    TEARDOWN_STOPER,
    READ_CLASSIFICATION_CHECKPOINTS,
    READ_CLASSIFICATION_PLAYERS,
    READ_COMPETITION,
    READ_COMPETITION_CLASSIFICATIONS,
    READ_GATE_MEASURES,
    SELECT_STOPER_GATE,
    START_STOPER,
    SWAP_STOPER_MEASURES,
    UPDATE_CLASSIFICATION,
    UPDATE_MEASURE,
    ADD_STOPER_MEASURE_NUMBERS,
    READ_COMPETITION_GATES,
    RESET_STOPER,
    READ_OPERATOR_DEVICES,
    READ_COMPETITION_ORGANIZER,
    UPDATE_MEASURE_WITHOUT_ID,
} from '@/store/actions.type';
import {
    CLEAR_STOPER_TICKER,
    SET_STOPER_COMPETITION,
    SET_STOPER_TICKER,
    SET_STOPER_GATE,
    SET_STOPER_NUMBER,
    SET_STOPER_NUMBERS,
    SET_MEASURE_NUMBER,
    SET_EDITING_MEASURE,
    SET_IS_EDITING_MEASURE_NUMBER,
    SET_OFFLINE_MEASURE,
    CLEAR_OFFLINE_MEASURE,
} from '@/store/mutations.type';
import moment, { duration } from 'moment';
import { STATUSES } from '@/config/index';
import _ from 'lodash';

export default {
    state: {
        gateId: null,
        competitionId: null,
        tick: 0,
        ticker: null,
        frequency: 1000, // ms
        number: '',
        numbers: [],
        measureNumber: '',
        isEditingMeasureNumber: false,
        editingMeasure: null,
        offlineMeasures: [],
    },
    getters: {
        stoperGate: (state, getters) => getters.gates[state.gateId],
        stoperClassificationsGate: (state, getters) => {
            const classificationsIdsFromCheckpoints = getters
                .checkpointsBy(getters.stoperGate)
                .map((checkpoint) => checkpoint.classification);
            const classificationsGate = [];
            for (const id of classificationsIdsFromCheckpoints) {
                classificationsGate.push(getters.classifications[id]);
            }
            return classificationsGate;
        },
        stoperCompetition: (state, getters) => getters.competitions[state.competitionId],
        // Create reactive dependency on tick.
        // eslint-disable-next-line no-unused-vars
        stoperTime: ({ tick }) => moment(),
        stoperDuration: (state, getters) => getDuration(getters.stoperClassificationsGate, getters.stoperTime),
        stoperStarted: (state, getters) => isStarted(getters.stoperGate, getters.stoperClassificationsGate),
        stoperMeasures: (state, getters) => getters.measuresBy(getters.stoperGate),
        stoperPlayers: (state, getters) => getters.playersBy(getters.stoperClassifications),
        stoperDurationMeasure: (state, getters) => ({ deviceTime }, player) => duration(deviceTime.diff(getters.classificationsBy(player)[0].startTime)),
        stoperMeasured: (state, getters) => (number) => getters.stoperMeasures.find((measure) => measure.tag === number) !== undefined,
        stoperNumbers: (state) => state.numbers,
        stoperNumber: (state) => state.number,
        stoperClassifications: (state, getters) => getters.classificationsBy(getters.stoperCompetition),
        stoperCheckpoints: (state, getters) => getters.checkpointsBy(getters.stoperClassifications),
        stoperGates: (state, getters) => getters.gatesBy(getters.stoperCheckpoints),
        measureNumber: (state) => state.measureNumber,
        isEditingMeasureNumber: (state) => state.isEditingMeasureNumber,
        editingMeasure: (state) => state.editingMeasure,
        stoperStopped: (state, getters) => getters.stoperCompetition.status === STATUSES.official,
        stoperOfflineMeasures: (state) => state.offlineMeasures.filter((measure) => measure.gate === state.gateId),
    },
    mutations: {
        [SET_STOPER_GATE](state, { id }) {
            state.gateId = id;
        },
        [SET_STOPER_TICKER](state) {
            // Ticker must not use mutation because is called very frequently.
            state.ticker = setInterval(() => {
                state.tick += 1;
            }, state.frequency);
        },
        [CLEAR_STOPER_TICKER](state) {
            clearInterval(state.ticker);
        },
        [SET_STOPER_COMPETITION](state, { id }) {
            state.competitionId = id;
        },
        [SET_STOPER_NUMBER](state, number) {
            state.number = number;
        },
        [SET_STOPER_NUMBERS](state, numbers) {
            state.numbers = numbers;
        },
        [SET_MEASURE_NUMBER](state, number) {
            state.measureNumber = number;
        },
        [SET_EDITING_MEASURE](state, editingMeasure) {
            state.editingMeasure = editingMeasure;
        },
        [SET_IS_EDITING_MEASURE_NUMBER](state, isEditingMeasureNumber) {
            state.isEditingMeasureNumber = isEditingMeasureNumber;
        },
        [SET_OFFLINE_MEASURE](state, measure) {
            state.offlineMeasures.push(measure);
        },
        [CLEAR_OFFLINE_MEASURE](state, measureIdx) {
            state.offlineMeasures.splice(measureIdx, 1);
        },
    },
    actions: {
        async [BOOTSTRAP_STOPER]({ dispatch, getters, commit }, { id, isSettingPage }) {
            await Promise.all([
                dispatch(READ_COMPETITION, id),
                dispatch(READ_COMPETITION_CLASSIFICATIONS, id),
                dispatch(READ_COMPETITION_GATES, id),
                dispatch(READ_COMPETITION_ORGANIZER, id),
            ]);
            commit(SET_STOPER_COMPETITION, { id });
            await dispatch(READ_OPERATOR_DEVICES, getters.stoperOrganizer.operator);

            const readClassifications = [];

            for (const classification of getters.stoperClassifications) {
                readClassifications.push(dispatch(READ_CLASSIFICATION_CHECKPOINTS, classification.id));
                if (!isSettingPage) {
                    readClassifications.push(dispatch(READ_CLASSIFICATION_PLAYERS, classification.id));
                }
            }

            await Promise.all(readClassifications);
        },
        async [TEARDOWN_STOPER]({ commit }) {
            commit(CLEAR_STOPER_TICKER);

            await Promise.resolve();
        },
        async [START_STOPER]({ dispatch, getters }) {
            const promises = [];

            for (const classification of getters.stoperClassificationsGate) {
                promises.push(
                    dispatch(UPDATE_CLASSIFICATION, {
                        ...classification,
                        startTime: getters.stoperTime,
                    }),
                );
            }

            await Promise.all(promises);
        },
        async [RESET_STOPER]({ dispatch, getters }) {
            const promises = [];

            for (const classification of getters.stoperClassificationsGate) {
                promises.push(
                    dispatch(UPDATE_CLASSIFICATION, {
                        ...classification,
                        startTime: null,
                    }),
                );
            }

            await Promise.all(promises);
        },
        async [SWAP_STOPER_MEASURES]({ dispatch }, [measureA, measureB]) {
            await Promise.all([
                dispatch(UPDATE_MEASURE, { ...measureA, tag: measureB.tag }),
                dispatch(UPDATE_MEASURE, { ...measureB, tag: measureA.tag }),
            ]);
        },
        async [SELECT_STOPER_GATE]({ dispatch, commit, state }, gate) {
            await dispatch(READ_GATE_MEASURES, gate.id);

            if (gate && !state.ticker) {
                commit(SET_STOPER_TICKER);
            }

            if (!gate && state.ticker) {
                commit(CLEAR_STOPER_TICKER);
            }

            commit(SET_STOPER_GATE, gate);
        },
        async [ADD_STOPER_MEASURE]({ dispatch, state }, tag) {
            await dispatch(CREATE_MEASURE, {
                gate: state.gateId,
                deviceTime: moment(),
                invalidated: false,
                tag,
            });
        },
        async [ADD_STOPER_MEASURE_NUMBERS]({ state, dispatch, commit }) {
            const [number, ...numbers] = state.numbers;

            if (number === undefined && !state.number) {
                await dispatch(ADD_STOPER_MEASURE, '');

                return;
            }

            if (number === undefined && state.number) {
                const nr = state.number;
                commit(SET_STOPER_NUMBER, '');

                await dispatch(ADD_STOPER_MEASURE, nr);

                return;
            }

            if (state.number) {
                numbers.push(state.number);
            }

            commit(SET_STOPER_NUMBERS, numbers);
            commit(SET_STOPER_NUMBER, '');

            await dispatch(ADD_STOPER_MEASURE, number);
        },
        [UPDATE_MEASURE_WITHOUT_ID]({ commit, getters }, updatedMeasure) {
            const measureToUpdateIdx = _.findIndex(getters.stoperOfflineMeasures, (measure) => measure.deviceTime.isSame(updatedMeasure.deviceTime));
            const measure = {
                ...getters.stoperOfflineMeasures[measureToUpdateIdx],
                ...updatedMeasure,
            };
            commit(CLEAR_OFFLINE_MEASURE, measureToUpdateIdx);
            commit(SET_OFFLINE_MEASURE, measure);
        },
    },
};
