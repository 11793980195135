<template>
    <div class="container">
        <div class="row mat40 flex-nowrap">
            <!-- Sidebar. -->
            <div class="side-bar none-mobile">
                <panel-competition-setting-sidebar />
            </div>

            <!-- View. -->
            <router-view :competition="competition" />
        </div>
    </div>
</template>

<script>
import PanelCompetitionSettingSidebar from '@/components/PanelCompetitionSettingSidebar';

export default {
    components: {
        PanelCompetitionSettingSidebar,
    },
    props: {
        competition: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style scoped>
.side-bar {
    width: 165px;
}
</style>
