<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-3" />
            <div class="col-12 col-lg-9">
                <div class="row mat80 home__organize-button">
                    <div class="col-12">
                        <h1>{{ say.organize_competitions_with_us }}</h1>
                    </div>
                    <div class="col-12 col-lg-4 mat16">
                        <a :href="organizerUrl">
                            <base-button btn2>
                                {{ say.go_to_organizer_zone }}
                            </base-button>
                        </a>
                    </div>
                </div>
                <div class="row mat80 home__organize-button">
                    <div class="col-12">
                        <div class="podcast-banner">
                            <div class="podcast-banner-logos">
                                <img src="@/assets/images/dostartu-logo-white.png" />
                                <img src="@/assets/images/dostartu-podcast-logo.svg" />
                            </div>
                            <a :href="podcastUrl">
                                <base-button btn2>
                                    {{ say.go_to_podcast }}
                                </base-button>
                            </a>
                            <img src="@/assets/images/podcast-banner-bg.svg" class="podcast-banner-bg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            organizerUrl: 'https://organizator.dostartu.pl',
            podcastUrl: 'https://open.spotify.com/show/3vaTPt1QEthuAEgdkTI2vH',
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.podcast-banner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 24px 32px;
    border-radius: 4px;
    background: var(--color-pinky-red, linear-gradient(270deg, #FF3D00 0%, #FF1744 99.28%));
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(55, 71, 79, 0.20);
}

.podcast-banner-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.podcast-banner-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 767px) {
    .podcast-banner {
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }
    .podcast-banner-bg {
        width: 100%;
        height: 100%;
    }
}
@media (min-width: 768px) {
    .podcast-banner a {margin-left: auto;}
}
</style>