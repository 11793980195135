var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organizer
    ? _c("div", { staticClass: "operator-header__organizer" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row operator-header__organizer-box" }, [
            _c("div", { staticClass: "patb8" }, [
              _vm._v(" " + _vm._s(_vm.organizer.name) + " "),
            ]),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                {
                  staticClass: "operator-header__organizer-btn",
                  class: {
                    "operator-header__organizer-btn--active":
                      this.$route.name === "panel-organizer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "panel-organizer",
                        params: {
                          organizerId: _vm.organizer.id,
                        },
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" Lista zawodów organizatora ")]
              ),
              _c(
                "div",
                {
                  staticClass: "operator-header__organizer-btn",
                  class: {
                    "operator-header__organizer-btn--active":
                      this.$route.name.includes("panel-setting-"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "panel-setting-new",
                        params: {
                          name: _vm.organizer.name,
                          id: _vm.organizer.id,
                        },
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" Ustawienia organizatora ")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }