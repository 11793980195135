<template>
    <div>
        <!-- First group. -->
        <base-button-group>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-info', params: competition, alias: true})"
            >
                Informacje<br>o zawodach
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-classifications', params: competition, alias: true})"
            >
                Klasyfikacje,
                <br>limity, opłaty
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-numbers', params: competition, alias: true})"
            >
                Numeracja
                <br>startowa
            </base-button>
        </base-button-group>

        <!-- Second group. -->
        <base-button-group class="mat16">
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-rebates', params: competition, alias: true})"
            >
                Kody rabatowe
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-discount', params: competition, alias: true})"
            >
                Rabaty za starty
            </base-button>
        </base-button-group>

        <!-- Third group. -->
        <base-button-group class="mat16">
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-register', params: competition, alias: true})"
            >
                Rejestracja
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-cycle', params: competition, alias: true})"
            >
                Zawody cykliczne
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-regulation', params: competition, alias: true})"
            >
                Regulamin zawodów
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-statement', params: competition, alias: true})"
            >
                Zgody
                <br>uczestników
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-confirmation', params: competition, alias: true})"
            >
                Potwierdzenie<br>rejestracji
            </base-button>
            <base-button
                btn11
                @click="$router.push({name: 'panel-competition-setting-layout', params: competition, alias: true})"
            >
                Wygląd
            </base-button>
        </base-button-group>

        <!-- Fourth group. -->
        <base-button-group v-if="isOperator" class="mat16">
            <base-button
                btn11
                novelty
                @click="competitionSettingStoperFullscreen"
            >
                Pomiar czasu
            </base-button>
        </base-button-group>
        <base-button-group v-else class="mat16">
            <base-button
                btn11
                novelty
                @click="$router.push({name: 'panel-competition-setting-stoper', params: competition, alias: true})"
            >
                Pomiar czasu
            </base-button>
        </base-button-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['user', 'competitions']),
        isOperator() {
            return this.user.operator;
        },        
        competition() {
            return this.competitions[this.$route.params.id];
        },
    },
    methods: {
        competitionSettingStoperFullscreen() {
            const routerData = this.$router.resolve({name: 'panel-competition-setting-stoper-fullscreen', params: this.competition, alias: true})
            window.open(routerData.href, '_blank');
        }
    },
};
</script>
