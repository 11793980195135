<template>
<div class="iframe-wrap">
  <iframe v-for="(link, index) in resultsLiveLink" :key="index" :src="link.url" frameborder="0"></iframe>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
    READ_COMPETITION_CLASSIFICATIONS,
} from '@/store/actions.type';
import {
    SET_ZEN_MODE,
} from '@/store/mutations.type';
export default {
  name: 'PanelCompetitionResultsLiveAll',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },  
  computed: {
    ...mapGetters([
      'isZenMode',
      'competitions',
      'classificationsBy',
    ]),
    competition() {
      return this.competitions[this.id];
    },
    classifications() {
      return this.classificationsBy(this.competition);
    },
    resultsLiveLink() {
      const classifications = this.classificationsBy(this.competition);
      const array = []
      for (const classification of classifications) {
        const host = window.location.origin;
        const params = {
          name: this.competition.name,
          id: this.competition.id,
          classificationId: classification.id,
        }
        const { href } = this.$router.resolve({
          name: 'panel-competition-results-live',
          params,
          alias: true,
        });

        const item = {
          name: classification.namePl,
          short: classification.short,
          url: `${host}${href}`,
        }
        array.push(item)
      }

      return array;
    }     
  },
  methods: {
    ...mapMutations([SET_ZEN_MODE]),
    ...mapActions([
      READ_COMPETITION_CLASSIFICATIONS,
    ]),
  },
  async created() {
    await this.READ_COMPETITION_CLASSIFICATIONS(this.competition.id);
    this.SET_ZEN_MODE(true)
  }
}
</script>


<style scoped>
.iframe-wrap{display: flex;flex-wrap: wrap}
iframe {width: calc(50% - 4px);    height: calc(50vh - 4px);    border: 2px solid #000;}
</style>