<template>
  <transition name="fade">
    <div class="modal" v-if="show" v-bind="$attrs">
      <div class="modal__backdrop" v-on="disabledBackdrop ? {} : { 'click': () => closeModal() }" />

      <div class="modal__dialog" :class="size">
        <div class="modal__header">
          <slot name="header" />
          <button v-show="!disabledBackdrop" type="button" class="modal__close" @click="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="modal__body" :class="bodyClass">
          <slot name="body" />
        </div>

        <div class="modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    maxHeight: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    disabledBackdrop: {
      type: Boolean,
      default: false,
    }
  },  
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("modal-close");
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.$emit("modal-open");
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
 .modal__backdrop {
  background-color: rgba(55, 71, 79, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
 .modal__dialog {
  background-color: #fff;
  position: relative;
  width: 832px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 2;
  color: #000;
}
.modal-background .modal__dialog {
 background-color: #ECEFF1;
}
.modal.max-height .modal__dialog {
  height: 95%;
}
.modal.max-height .modal__footer {
  margin-top: auto;
}
.modal__body.bg {
  background: #ECEFF1;
}
.modal__body.h-auto {
  height: auto;
}
.modal__body.visible {
  overflow: visible;
}
 @media screen and (max-width: 991px) {
  .modal__dialog {
    width: 100%;
    height: 100vh;
	}
}
 @media screen and (min-width: 992px) {
  .modal__body {
    height: 600px;
  }
  .modal-hd .modal__dialog {
    width: 1132px;
  } 
  .modal__dialog.xl {
    width: 1132px;
  }
}
.modal__close {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  color: #fff;
}
.modal__header {
  background-color: #546E7A;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.modal__body {
  /* padding: 24px 16px; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.modal__footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #ECEFF1;
  padding: 20px 32px;
}

.modal__footer:before {
  content: '';
  position: absolute;
  top: 0;
  left: 32px;
  height: 1px;
  width: calc(100% - 64px);
  background-color: rgba(84, 110, 122, 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
