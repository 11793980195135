<template>
    <div class="row bg--gray result__classification-item">
        <!-- Classification. -->
        <div class="col-12 col-lg-4 result__classification-info">
            <!-- Name. -->
            <div>
                <h3>{{ classification.short }}</h3>
                <p>{{ classification.namePl }}</p>
            </div>
            <!-- Players unavailable. -->
            <div class="align-items-center">
                <i class="fas fa-users f18" />
                <p class="mal8">
                    {{ limits.playersUnavailable }}
                </p>
            </div>
        </div>

        <hr class="col-12 d-sm mab8">

        <!-- Top player. -->
        <div
            v-if="topPlayer && showTopResults"
            class="col-12 col-lg-4 result__best-time"
        >
            <p>{{ say.best_time }}</p>
            <div>
                <!-- Time. -->
                <h3>{{ topPlayer.time | asStoper }}</h3>
                <!-- Name. -->
                <p class="result__player-name mar8">
                    {{ topPlayer.firstname }} {{ topPlayer.lastname }}
                </p>
                <p class="result__player-country">
                    {{ topPlayer.country }}
                </p>
            </div>
        </div>

        <hr
            v-if="topPlayer && showTopResults"
            class="mat8 col-12 d-sm"
        >

        <!-- See results button. -->
        <div class="col-12 col-lg-3 result__btn">
            <base-button
                btn2
                @click="
                    $router.push({
                        name: 'minisite-results',
                        params: {
                            id: competition.id,
                            name: competition.name,
                            classificationId: classification.id,
                        },
                        alias: true,
                    })
                "
            >
                {{ say.see_results }}
            </base-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        topPlayerId: {
            type: Number,
            default: null,
        },
        showTopResults: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'say',
            'classifications',
            'players',
            'competitions',
            'limitsBy',
            'minisiteId',
        ]),
        classification() {
            return this.classifications[this.id];
        },
        competition() {
            return this.competitions[this.minisiteId];
        },
        limits() {
            return this.limitsBy(this.classification)[0];
        },
        topPlayer() {
            return this.players[this.topPlayerId];
        },
    },
};
</script>
