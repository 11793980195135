var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "max-width": "100vw" } }, [
    _c(
      "div",
      { staticClass: "row nowrap-lg", staticStyle: { overflow: "hidden" } },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-2" },
          [_c("main-list-filters", { staticClass: "map-margin-top" })],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-10 mapWrap" }, [
          _vm.loading
            ? _c("div", { staticClass: "loading" }, [_vm._m(0)])
            : _c(
                "div",
                [
                  _vm.showMap
                    ? _c(
                        "l-map",
                        {
                          ref: "map",
                          staticClass: "mapWrapper",
                          attrs: {
                            zoom: _vm.zoom,
                            center: _vm.center,
                            options: _vm.mapOptions,
                          },
                          on: { ready: _vm.onReady },
                        },
                        [
                          _c(
                            "l-control",
                            {
                              staticClass: "custom-control",
                              attrs: { position: "topleft" },
                            },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "ma4",
                                  attrs: { btn6: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleMap()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-md-inline-block" },
                                    [_vm._v("Zobacz listę")]
                                  ),
                                  _vm._v(" "),
                                  _c("i", { staticClass: "fas fa-list" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("l-control-zoom", {
                            attrs: { position: "topright" },
                          }),
                          _c("l-tile-layer", {
                            attrs: {
                              url: _vm.url,
                              attribution: _vm.attribution,
                            },
                          }),
                          _c(
                            "v-marker-cluster",
                            { ref: "markers" },
                            [
                              _vm._l(
                                _vm.filteredAllCompetitions,
                                function (competition, index) {
                                  return [
                                    _c("CompetitionsMapMarker", {
                                      key: competition,
                                      attrs: {
                                        index: index,
                                        selectedIndex: _vm.selectedIndex,
                                        competitionId: competition,
                                      },
                                      on: {
                                        markers: _vm.bounds,
                                        ref: _vm.markersRefs,
                                        click: function ($event) {
                                          return _vm.changeSlide(index)
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading && _vm.selectedIndex !== null,
                  expression: "!loading && selectedIndex !== null",
                },
              ],
              staticClass: "carousel-wrap",
            },
            [
              _c(
                "swiper",
                {
                  ref: "mySwiper",
                  staticClass: "carousel",
                  attrs: { options: _vm.swiperOption },
                  on: { slideChange: _vm.slideChanged },
                },
                _vm._l(_vm.filteredAllCompetitions, function (id, index) {
                  return _c(
                    "swiper-slide",
                    { key: id, attrs: { index: index, id: id } },
                    [
                      _vm._v(" " + _vm._s(index) + " "),
                      _c("base-item-competition", { attrs: { id: id } }),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.boundsArr.length > _vm.selectedIndex + 1,
                      expression: "boundsArr.length > selectedIndex + 1",
                    },
                  ],
                  staticClass: "carousel-prev",
                  attrs: { btn6: "" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "12",
                        height: "9",
                        viewBox: "0 0 12 9",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1.70711 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H1.70711L4.85355 8.14645C5.04882 8.34171 5.04882 8.65829 4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L0.146447 4.85355C-0.0488155 4.65829 -0.0488155 4.34171 0.146447 4.14645L4.14645 0.146447C4.34171 -0.0488155 4.65829 -0.0488155 4.85355 0.146447C5.04882 0.341709 5.04882 0.658291 4.85355 0.853553L1.70711 4Z",
                          fill: "#4A4A4A",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedIndex !== 0,
                      expression: "selectedIndex !== 0",
                    },
                  ],
                  staticClass: "carousel-next",
                  attrs: { btn6: "" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "12",
                        height: "9",
                        viewBox: "0 0 12 9",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z",
                          fill: "#4A4A4A",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis lds-ellipsis--center" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }