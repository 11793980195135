<template>
    <div>
        <div class="container pab1">
            <div class="row admin-header">
                <div class="col-12 col-lg-5">
                    <!-- Switch between list operators/competitions -->
                    <base-button-group
                        horizontal
                        class="admin-header__views"
                    >
                        <base-button
                            :pressed="!isCompetitionList"
                            class="admin-header__views-btn"
                            btn11
                            @click="isCompetitionList = false"
                        >
                            Lista operatorów
                        </base-button>
                        <base-button
                            class="admin-header__views-btn"
                            btn11
                            :pressed="isCompetitionList"
                            @click="isCompetitionList = true"
                        >
                            Lista zawodów
                        </base-button>
                    </base-button-group>
                </div>
                <div class="col-12 col-lg-4">
                    <!-- Search input for organizers. -->
                    <base-input-text
                        v-if="!isCompetitionList"
                        v-model="searchOperators"
                        icon="fas fa-search"
                        label="Szukaj..."
                        class="admin-header__search"
                    />
                </div>
                <div class="col-12 col-lg-3">
                    <!-- Add new organizer. -->
                    <base-button
                        v-if="!isCompetitionList"
                        class="admin-header__add-operator"
                        btn1
                        @click="showAddOperator = !showAddOperator"
                    >
                        Dodaj operatora
                    </base-button>
                    <!-- Search input for competitions -->
                    <base-input-text
                        v-else
                        v-model="searchCompetitions"
                        icon="fas fa-search"
                        label="Szukaj..."
                        class="admin-header__search admin-header__search--competitions"
                    />
                </div>
            </div>
        </div>
        <!-- Adding new organizer. -->
        <transition name="admin-transition">
            <panel-promote-competition
                v-if="showPromotePanel"
                :competition="competitions[competitionIdToPromote]"
                @close-promote="
                    (showPromotePanel = false), (competitionIdToPromote = null)
                "
            />
            <panel-add-person
                v-if="showAddOperator"
                is-operator
                class="admin-header__add-box"
                @add-person="addOperator"
                @close="showAddOperator = false"
            />
        </transition>
        <div
            v-if="!ready"
            class="container mat32"
        >
            <div class="row">
                <div class="col-12">
                    <div class="admin-header__loading-state">
                        Trwa wczytywanie
                        <div class="mal15 lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <panel-list
            v-else
            :is-operators="!isCompetitionList"
            :is-competitions="isCompetitionList"
            is-admin
            :items="items"
            @search-competitions="searchInput = $event"
            @row-click="onRowClick"
            @send-access="sendAccessToOperator"
            @delete-competition="
                (showPopupWithDelete = true), (competitionIdToDelete = $event)
            "
            @delete-person="
                (showPopupWithDelete = true), (operatorIdToDelete = $event)
            "
            @switch-show-competition="onSwitchCompetition"
            @switch-global-shop-competition="globalShopCompetition"
            @promote-competition="promoteCompetition"
            @gamification-competition="setGamification"
        />
        <!-- Pagination competitions list. -->
        <div
            v-if="isCompetitionList && ready"
            class="container"
        >
            <div class="row admin__pages">
                <div
                    class="admin__pages-btn"
                    :class="{
                        'admin__pages-btn--disabled': !isPreviousPage,
                    }"
                    @click="isPreviousPage ? prevPage() : ''"
                >
                    <div
                        v-if="isLoadingPrevCompetitions"
                        class="admin__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Poprzednie
                </div>
                <div
                    class="admin__pages-btn"
                    :class="{ 'admin__pages-btn--disabled': !isNextPage }"
                    @click="isNextPage ? nextPage() : ''"
                >
                    <div
                        v-if="isLoadingNextCompetitions"
                        class="admin__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Następne
                </div>
            </div>
        </div>
        <!-- Popup -->
        <base-popup v-if="showPopupWithDelete">
            <!-- Title. -->
            <template #title>
                {{ isCompetitionList ? 'Usuń zawody' : 'Usuń operatora' }}
            </template>
            <!-- Subtitle. -->
            <template #subtitle>
                <b>Czy na pewno, chcesz usunąć
                    {{ isCompetitionList ? 'zawody' : 'operatora' }}?</b>
            </template>
            <!-- Content. -->
            <template #content>
                <div class="mat16">
                    Tej czynności nie będzie można cofnąć.
                </div>
            </template>
            <!-- Buttons. -->
            <template #buttons>
                <base-button
                    class="mar15"
                    btn2
                    @click="onClosePopup()"
                >
                    anuluj
                </base-button>
                <base-button
                    class="mal15"
                    btn1
                    @click="
                        onDelete(), (showPopupWithDelete = !showPopupWithDelete)
                    "
                >
                    Usuń
                </base-button>
            </template>
        </base-popup>
    </div>
</template>

<script>
import PanelAddPerson from '@/components/PanelAddPerson';
import PanelPromoteCompetition from '@/components/PanelPromoteCompetition';
import PanelList from '@/components/PanelList';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    BOOTSTRAP_ADMIN,
    DELETE_OPERATOR,
    READ_COMPETITIONS,
    SEND_OPERATOR_ACCESS,
    UPDATE_COMPETITION,
    DELETE_COMPETITION,
    CREATE_GAMIFICATION,
    DELETE_GAMIFICATION,
} from '@/store/actions.type';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        PanelAddPerson,
        PanelPromoteCompetition,
        PanelList,
    },
    data() {
        return {
            operatorsIds: [],
            competitionsIds: [],
            initialOperatorsIds: [],
            searchOperators: '',
            searchCompetitions: '',
            isCompetitionList: true,
            showAddOperator: false,
            showPromotePanel: false,
            ready: false,
            showPopupWithDelete: false,
            operatorIdToDelete: null,
            competitionIdToDelete: null,
            competitionIdToPromote: null,
            page: 1,
            isLoadingNextCompetitions: false,
            isLoadingPrevCompetitions: false,
        };
    },

    computed: {
        ...mapGetters(['operators', 'competitions', 'competitionPlayerCounts']),
        items() {
            const list = [];
            if (this.isCompetitionList) {
                for (const id of this.competitionsIds) {
                    const competition = this.competitions[id];
                    const competitionInfo = {
                        id,
                        startedTime: this.$options.filters.asTime(
                            competition.startedTime,
                        ),
                        name: competition.name,
                        registered: this.competitionPlayerCounts[id]
                            .playersCount,
                        paid: this.competitionPlayerCounts[id].playersPayCount,
                        location: competition.location,
                        status: this.$options.filters.asStatus(
                            competition.status,
                        ),
                        statusId: competition.status,
                        isShow: competition.mainPromo,
                        globalShop: competition.globalShop,
                        isGamification: competition.gamification,
                    };
                    list.push(competitionInfo);
                }
            } else {
                for (const id of this.operatorsIds) {
                    const operator = this.operators[id];
                    const operatorInfo = {
                        id,
                        name: operator.name,
                        phone: operator.phone,
                        email: operator.email,
                        addedTime: operator.addedTime.format('DD.MM.YYYY'),
                    };
                    list.push(operatorInfo);
                }
            }
            return list;
        },
        isPreviousPage() {
            return this.page > 1;
        },
        isNextPage() {
            // Pagination is blocked if search input is in use
            return this.competitionsIds.length > 0 && !this.searchCompetitions;
        },
    },
    watch: {
        // Search competitions and operators by text from inputs.
        async searchCompetitions() {
            if (this.searchCompetitions.length >= 3) {
                const { competitions } = await this.READ_COMPETITIONS({
                    page: 1,
                    panel: 1,
                    itemsPerPage: 10,
                    name: this.searchCompetitions,
                });
                this.competitionsIds = competitions;
            } else if (!this.searchCompetitions) {
                this.page = 1;
                const { competitions } = await this.READ_COMPETITIONS({
                    page: 1,
                    panel: 1,
                    itemsPerPage: 30,
                });
                this.competitionsIds = competitions;
            }
        },
        async searchOperators() {
            if (this.searchOperators.length) {
                const searchValue = this.searchOperators;
                const matchedItemsIds = [];
                for (const id of this.initialOperatorsIds) {
                    const operator = this.operators[id];
                    if (
                        this.matchSome(
                            [operator.name, operator.email],
                            searchValue,
                        )
                    ) {
                        matchedItemsIds.push(id);
                    }
                }
                this.operatorsIds = matchedItemsIds;
            } else {
                this.operatorsIds = this.initialOperatorsIds;
            }
        },
    },
    async created() {
        const { competitionsIds, operatorsIds } = await this.BOOTSTRAP_ADMIN();
        this.competitionsIds = competitionsIds;
        this.operatorsIds = operatorsIds;
        this.initialOperatorsIds = operatorsIds;
        this.ready = true;
    },
    methods: {
        ...mapMutations([SET_NOTIFICATION]),
        ...mapActions([
            BOOTSTRAP_ADMIN,
            DELETE_OPERATOR,
            READ_COMPETITIONS,
            SEND_OPERATOR_ACCESS,
            UPDATE_COMPETITION,
            CREATE_GAMIFICATION,
            DELETE_COMPETITION,
            DELETE_GAMIFICATION,
        ]),
        async addOperator(id) {
            this.operatorsIds.push(id);
            this.showAddOperator = false;
        },
        async nextPage() {
            this.page += 1;
            this.isLoadingNextCompetitions = true;
            const { competitions } = await this.READ_COMPETITIONS({
                page: this.page,
                itemsPerPage: 30,
                panel: 1,
            });
            this.competitionsIds = competitions;
            this.isLoadingNextCompetitions = false;
        },
        async prevPage() {
            this.page -= 1;
            this.isLoadingPrevCompetitions = true;
            const { competitions } = await this.READ_COMPETITIONS({
                page: this.page,
                itemsPerPage: 30,
                panel: 1,
            });
            this.competitionsIds = competitions;
            this.isLoadingPrevCompetitions = false;
        },
        matchSome(arrayValues, searchValue) {
            const exp = new RegExp(searchValue, 'i');
            for (const value of arrayValues) {
                if (value.match(exp)) {
                    return true;
                }
            }
            return false;
        },
        onRowClick(item) {
            if (this.isCompetitionList) {
                this.$router.push({
                    name: 'panel-competition-dashboard',
                    params: {
                        name: item.name,
                        id: item.id,
                    },
                    alias: true,
                });
            } else {
                this.$router.push({
                    name: 'panel-operator',
                    params: {
                        operatorId: item.id,
                    },
                    alias: true,
                });
            }
        },
        // send accest to organizer handler.
        async sendAccessToOperator(id) {
            try {
                await this.SEND_OPERATOR_ACCESS(id);
                this.SET_NOTIFICATION('alerts_positive_22');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
            }
        },
        onClosePopup() {
            this.operatorIdToDelete = null;
            this.competitionIdToDelete = null;
            this.showPopupWithDelete = false;
        },
        // To discuss. @todo
        // async deleteOperator(id) {
        //     try {
        //         let operatorIndex = this.operatorsIds.findIndex(
        //             (opId) => opId === id
        //         );
        //         let operatorIndexInInitial = this.initialOperatorsIds.findIndex(
        //             (opId) => opId === id
        //         );
        //         this.initialOperatorsIds.splice(operatorIndexInInitial, 1);
        //         this.operatorsIds.splice(operatorIndex, 1);
        //         await this.DELETE_OPERATOR(id);
        //         this.SET_NOTIFICATION("alerts_positive_44");
        //     } catch (e) {
        //         this.SET_NOTIFICATION("alerts_negative_4");
        //         this.operatorsIds.push(id);
        //         this.initialOperatorsIds.push(id);
        //     }
        // },
        // Delete competition handler.
        async deleteCompetition(id) {
            try {
                const competitionIndex = this.competitionsIds.findIndex(
                    (competitionId) => competitionId === id,
                );
                this.competitionsIds.splice(competitionIndex, 1);
                await this.DELETE_COMPETITION(id);
                this.SET_NOTIFICATION('alerts_positive_56');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
                this.competitionsIds.push(id);
            }
        },
        async onDelete() {
            if (this.isCompetitionList) {
                await this.deleteCompetition(this.competitionIdToDelete);
                this.competitionIdToDelete = null;
            } else {
                await this.deleteOperator(this.operatorIdToDelete);
                this.operatorIdToDelete = null;
            }
        },
        // Visibility switch handler.
        async onSwitchCompetition(id) {
            const competition = {
                ...this.competitions[id],
                mainPromo: !this.competitions[id].mainPromo,
            };
            await this.UPDATE_COMPETITION(competition);
        },
        // Visibility switch global shop.
        async globalShopCompetition(id) {
            const competition = {
                ...this.competitions[id],
                globalShop: !this.competitions[id].globalShop,
            };
            await this.UPDATE_COMPETITION(competition);
        },
        promoteCompetition(id) {
            this.competitionIdToPromote = id;
            this.showPromotePanel = true;
        },
        async setGamification(id) {
            try {
                if (this.competitions[id].gamification) {
                    await this.DELETE_GAMIFICATION(id);
                } else {
                    await this.CREATE_GAMIFICATION(id);
                }
                this.SET_NOTIFICATION('alerts_positive_46');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
            }
        },
    },
};
</script>

<style scoped>
.admin__pages {
    margin-top: 32px;
    margin-bottom: 32px;
}
.admin__pages-btn {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    position: relative;
}

.admin__pages-btn--disabled {
    opacity: 0.5;
}
.admin__pages-loading {
    position: absolute;
    top: -25px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.admin-header__add-box {
    overflow: hidden;
}
.admin-header {
    margin-top: 24px;
    margin-bottom: 24px;
}
.admin-header__views-btn {
    width: 100%;
    justify-content: center;
    user-select: none;
}
.admin-header__search {
    margin-top: 16px;
    min-height: 40px;
    max-height: 40px;
}
.admin-header__link {
    margin-top: 16px;
}
.admin-header__add-operator {
    margin-top: 16px;
}

.admin-transition-enter {
    max-height: 0;
    overflow: hidden;
}
.admin-transition-enter-to {
    max-height: 500px;
    overflow: hidden;
}
.admin-transition-leave {
    max-height: 500px;
    overflow: hidden;
}
.admin-transition-leave-to {
    max-height: 0;
    overflow: hidden;
}
.admin-transition-enter-active,
.admin-transition-leave-active {
    transition: all var(--animation-slow);
}

.admin-header__loading-state {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.lds-hourglass:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 8px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
}
@media (min-width: 992px) {
    .admin-header {
        margin-top: 48px;
    }
    .admin-header__views {
        margin-right: 15px;
    }
    .admin-header__search {
        margin: 0 15px;
    }
    .admin-header__link {
        margin: 0 15px;
    }
    .admin-header__add-operator {
        margin-left: 15px;
        margin-top: 0;
        min-height: 40px;
        max-height: 40px;
        box-sizing: border-box;
        display: flex;
    }
    .admin-header__search--competitions {
        margin-right: 0;
    }
}
</style>
