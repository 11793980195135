<template>
    <div :class="classes">
        <div class="container">
            <div class="row">
                <div :class="leftSideHeaderClasses">
                    <slot name="left">
                        <!-- Counter. -->
                        <transition name="home-top__counter">
                            <div
                                v-if="$route.name === 'main-landing'"
                                class="row home-top__counter"
                            >
                                <div class="col-12 home-top__logo">
                                    <h1
                                        class="home-top__info"
                                        v-html="headTitle"
                                    />
                                </div>
                            </div>
                        </transition>
                    </slot>
                </div>

                <div
                    ref="rightSideHeader"
                    :class="rightSideHeaderClasses"
                >
                    <!-- Search -->
                    <div
                        v-if="isMainSite"
                        :style="backgroundMainSite"
                        :class="rightSideHeaderHeightPhoto"
                    >
                        <img
                            class="background__image"
                            src="../assets/images/photo@3x.jpg"
                            alt="operator photo"
                        >
                    </div>
                    <slot name="right" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        headerColor: {
            type: String,
            default: '',
        },
        isMainSite: {
            type: Boolean,
            default: false,
        },
        withPosters: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            width: undefined,
        };
    },
    computed: {
        ...mapGetters(['say']),
        classes() {
            return {
                'home-top': this.$slots.right && !this.$slots.left,
                'header-top': this.$slots.right && this.$slots.left,
                'home-top--tall': this.$route.name === 'main-landing',
                'home-top--short': this.$route.name === 'main-list',
                'd-none d-md-block': this.$route.name === 'main-list-map',
            };
        },
        headTitle() {
            let info = this.say.platform_for_organizers_and_players;
            const wordsWithRedirect = [
                'organizatorów',
                'organizátory',
                'Organisatoren',
                'organizers',
                'organisateurs',
            ];
            wordsWithRedirect.forEach((word) => {
                info = info.replace(
                    word,
                    `<a class="home-top__info-link" href="https://organizator.dostartu.pl">${word}</a>`,
                );
            });
            return info;
        },
        leftSideHeaderClasses() {
            return {
                'col-12': true,
                'col-lg-3': this.$slots.right && this.$slots.left,
            };
        },
        rightSideHeaderClasses() {
            return {
                'col-12': true,
                'col-lg-9': this.$slots.right && this.$slots.left,
                'header-box__search-container': this.isMainSite,
                'header-box__search-container--short':
                    !this.withPosters && this.isMainSite,
                'header-box__search-container--tall':
                    this.withPosters && this.isMainSite,
            };
        },
        rightSideHeaderHeightPhoto() {
            return {
                'background__main-site--short':
                    !this.withPosters && this.isMainSite,
                'background__main-site--tall':
                    this.withPosters && this.isMainSite,
            };
        },
        backgroundMainSite() {
            return {
                width: this.width,
                backgroundImage: this.headerColor,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.measurePhotoWidth);
        });

        // Init
        if (this.isMainSite) {
            this.measurePhotoWidth();
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.measurePhotoWidth);
    },
    methods: {
        measurePhotoWidth() {
            if (this.getWindowWidth() >= 1200) {
                this.width = `${this.$refs.rightSideHeader.clientWidth
                    + (window.innerWidth - 1200) / 2
                    + 6}px`;
            } else if (this.getWindowWidth() >= 992) {
                this.width = `${74}%`;
            } else {
                this.width = `${100}%`;
            }
        },
        getWindowWidth() {
            return window.innerWidth;
        },
    },
};
</script>
<style scoped>
.header__right-side {
    position: absolute;
    width: 100%;
    height: 100%;
}
.header-top {
    min-height: 313px;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
}
.header-box__search-container {
    position: relative;
    max-width: 100%;
}
.header-box__search-container--short {
    height: 313px;
    display: flex;
    align-items: center;
}
.header-box__search-container--tall {
    display: flex;
    align-items: flex-start;
    height: 375px;
}

.background__image {
    object-fit: cover;
    opacity: 0.4;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.background__main-site--short {
    height: 313px;
    position: absolute;
    right: 0;
    background-position: center;
    background-size: cover;
    display: flex;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.background__main-site--tall {
    height: 375px;
    position: absolute;
    right: 0;
    background-position: center;
    background-size: cover;
    display: flex;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
* >>> .home-top__info-link {
    text-decoration: underline;
}
@media (min-width: 992px) {
    .header-box__search-container--short {
        align-items: flex-end;
    }
    .header-box__search-container {
        position: static;
        max-width: 75%;
    }
    .header-top {
        margin-left: 0;
        margin-right: 0;
    }
    .d-md-block {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .background__main-site--short {
        width: unset;
    }
    .background__main-site--tall {
        width: unset;
    }
}
</style>
