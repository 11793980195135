var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "none-mobile pab1" },
    [
      _c(
        "p",
        {
          staticClass: "mat32 pointer mab40 mal15",
          on: {
            click: function ($event) {
              _vm.isHide = !_vm.isHide
            },
          },
        },
        [_vm._v(" Pozostałe numery ")]
      ),
      _c("transition", { attrs: { name: "toggle" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isHide,
                expression: "isHide",
              },
            ],
            staticClass: "container-number-groups transition",
          },
          [
            _c(
              "div",
              { staticClass: "w100 mab40 dflex flex-wrap overflow-auto" },
              _vm._l(_vm.sortedStoperPlayers, function (player, idx) {
                return _c(
                  "base-button",
                  {
                    key: player.id,
                    staticClass: "number-other",
                    class: { "awarded-btn": idx % 10 === 0 },
                    attrs: {
                      btn9: "",
                      disabled: _vm.stoperMeasured(player.playerNumber),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.ADD_STOPER_MEASURE(player.playerNumber)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(player.playerNumber) + " ")]
                )
              }),
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }