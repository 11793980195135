<template>
    <div class="confirmation row">
        <div class="col-12">
            <h1 class="confirmation__title">
                Dziękujemy za wysłanie danych.
            </h1>
            <!-- <h1 class="confirmation__title">{{ say.thanks_for_sending_data }}</h1> -->
            <div class="confirmation__hint">
                Twoje pliki zostały wysłane.
            </div>
            <!-- <div class="confirmation__hint">{{ say.files_have_been_sent }}</div> -->
            <div class="row confirmation__close">
                <div class="col-6">
                    <base-button
                        class="confirmation__close-btn"
                        btn2
                        @click="$router.push({name: 'minisite-landing', params: competition, alias: true})"
                    >
                        {{ say.close }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['competitions', 'say']),
        competition() {
            return this.competitions[this.id];
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'minisite-transfer-files') {
            next();
            return;
        }
        next(from);
    },
};
</script>

<style scoped>
.confirmation {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.confirmation__hint {
    margin-top: 15px;
    font-size: 16px;
}
.confirmation__close {
    justify-content: center;
}
.confirmation__close-btn {
    text-transform: uppercase;
    margin-top: 32px;
}
.confirmation__title {
    text-transform: uppercase;
}
</style>
