export function getCookie(key) {
    const matches = document.cookie.match(new RegExp(`${key}=([^;]+)`));

    return matches !== null ? matches[1] : null;
}

export function setCookie(key, value, domain, expires) {
    document.cookie = `${key}=${value};domain=${domain};expires=${expires};path=/`;
}

export function deleteCookie(key, domain) {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domain};path=/`;
}
