var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container mat40" }, [
    _c("div", { staticClass: "row admin-header" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _vm.listParent === "payments"
          ? _c("h1", [_vm._v("Lista płatności")])
          : _vm._e(),
        _vm.listParent === "agreements"
          ? _c("h1", [_vm._v("Lista umów")])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "col-12 col-md-8" }, [
        _c(
          "div",
          { staticClass: "buttons-wrap" },
          [
            _c("base-input-text", {
              staticClass: "admin-header__search",
              attrs: { icon: "fas fa-search", label: "Szukaj..." },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _c(
              "base-button-group",
              { staticClass: "admin-header__views", attrs: { horizontal: "" } },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "admin-header__views-btn",
                    attrs: {
                      btn11: "",
                      pressed: _vm.listParent === "payments",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeUsersList("payments")
                      },
                    },
                  },
                  [_vm._v(" Płatności ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "admin-header__views-btn",
                    attrs: {
                      btn11: "",
                      pressed: _vm.listParent === "agreements",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeUsersList("agreements")
                      },
                    },
                  },
                  [_vm._v(" Umowy ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    !_vm.ready
      ? _c("div", { staticClass: "mat32" }, [_vm._m(0)])
      : _c("div", { staticClass: "row container-list" }, [
          _vm.listParent === "payments"
            ? _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { ref: "list" },
                  [
                    _c("base-table", {
                      staticClass: "list",
                      attrs: {
                        headers: _vm.tableHeaders,
                        items: _vm.items,
                        sortColumnDefault: "0",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function ({ sort }) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "list__row" },
                                  _vm._l(
                                    _vm.tableHeaders,
                                    function (header, column) {
                                      return _c(
                                        "th",
                                        {
                                          key: column,
                                          staticClass: "list__row-header",
                                          on: {
                                            click: function ($event) {
                                              return sort(column)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(header.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                          },
                          {
                            key: "body",
                            fn: function ({ displayedItems }) {
                              return _vm._l(displayedItems, function (item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    staticClass: "list__row",
                                    class: {
                                      "list__row--overtime": item.overDate,
                                      "list__row--payed": item.payed,
                                      "list__row--notpayed": !item.payed,
                                    },
                                  },
                                  _vm._l(
                                    _vm.tableHeaders,
                                    function (header, column) {
                                      return _c(
                                        "td",
                                        {
                                          key: column,
                                          staticClass: "list__row-data",
                                          class: {
                                            "list__row-data--name":
                                              header.value ===
                                              "competitionName",
                                            "list__row-data--loading":
                                              _vm.loading,
                                            "list__row-data--date":
                                              item[header.value] ===
                                              item.paymentDate,
                                            "list__row-data--payed":
                                              item[header.value] === item.payed,
                                          },
                                          on: {
                                            click: function ($event) {
                                              header.value === "more"
                                                ? _vm.menuClickHandler(
                                                    $event,
                                                    item.id
                                                  )
                                                : ""
                                            },
                                          },
                                        },
                                        [
                                          header.value === "more"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    ref: item.id,
                                                    refInFor: true,
                                                    staticClass: "more-btn",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-ellipsis-v",
                                                    }),
                                                    _vm.menuCompetitionId ===
                                                    item.id
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "more-btn__content",
                                                            class: {
                                                              "more-btn__content--from-up":
                                                                _vm.isMenuCompetitionIdInBottom,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "more-btn__content-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.sendPaymentMessage(
                                                                        item.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "mar8",
                                                                    attrs: {
                                                                      width:
                                                                        "24",
                                                                      height:
                                                                        "24",
                                                                      viewBox:
                                                                        "0 0 24 24",
                                                                      fill: "none",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        "fill-rule":
                                                                          "evenodd",
                                                                        "clip-rule":
                                                                          "evenodd",
                                                                        d: "M19.5 5H4.5C3.11929 5 2 6.11929 2 7.5V16.5096C2 17.8903 3.11929 19.0096 4.5 19.0096H19.5C20.8807 19.0096 22 17.8903 22 16.5096V7.5C22 6.11929 20.8807 5 19.5 5ZM20.1444 6.1451C19.9492 6.05207 19.7307 6 19.5 6H4.5C4.26933 6 4.05081 6.05207 3.85557 6.1451L12 12.8523L20.1444 6.1451ZM3.1431 17.1498C3.14773 17.1451 3.15247 17.1405 3.15732 17.1359L8.9772 11.6584L3.18215 6.88597C3.16951 6.87556 3.15752 6.86466 3.14617 6.85332C3.05247 7.04913 3 7.26844 3 7.5V16.5096C3 16.7386 3.05132 16.9557 3.1431 17.1498ZM3.84675 17.8603C4.04422 17.956 4.26584 18.0096 4.5 18.0096H19.5C19.7342 18.0096 19.9558 17.956 20.1533 17.8603L14.2446 12.2992L12.3179 13.886C12.1332 14.038 11.8668 14.038 11.6821 13.886L9.75538 12.2992L3.84675 17.8603ZM21 16.5096C21 16.7386 20.9487 16.9557 20.8569 17.1498C20.8523 17.1451 20.8475 17.1405 20.8427 17.1359L15.0228 11.6584L20.8179 6.88597C20.8305 6.87556 20.8425 6.86466 20.8538 6.85333C20.9475 7.04914 21 7.26844 21 7.5V16.5096Z",
                                                                        fill: "#455A64",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "sended",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Wiadomość wysłana"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "send",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Wyślij wiadmość o płatności"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            item.payed
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "more-btn__content-btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.sendPaymentStatus(
                                                                            item.id,
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "mar8",
                                                                        attrs: {
                                                                          width:
                                                                            "16",
                                                                          height:
                                                                            "16",
                                                                          viewBox:
                                                                            "0 0 16 16",
                                                                          fill: "none",
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                d: "M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z",
                                                                                fill: "#455A64",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Oznacz jako nieopłacony "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "more-btn__content-btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.sendPaymentStatus(
                                                                            item.id,
                                                                            true
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "mar8",
                                                                        attrs: {
                                                                          width:
                                                                            "24",
                                                                          height:
                                                                            "24",
                                                                          viewBox:
                                                                            "0 0 24 24",
                                                                          fill: "none",
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "fill-rule":
                                                                                  "evenodd",
                                                                                "clip-rule":
                                                                                  "evenodd",
                                                                                d: "M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.8536 9.14645C15.6583 8.95118 15.3417 8.95118 15.1464 9.14645L10.5 13.7929L8.85355 12.1464C8.65829 11.9512 8.34171 11.9512 8.14645 12.1464C7.95118 12.3417 7.95118 12.6583 8.14645 12.8536L10.1464 14.8536C10.3417 15.0488 10.6583 15.0488 10.8536 14.8536L15.8536 9.85355C16.0488 9.65829 16.0488 9.34171 15.8536 9.14645Z",
                                                                                fill: "#455A64",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Oznacz jako opłacony "
                                                                    ),
                                                                  ]
                                                                ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "more-btn__content-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        {
                                                                          name: "panel-competition-dashboard",
                                                                          params:
                                                                            {
                                                                              name: item.competitionName,
                                                                              id: item.competition,
                                                                            },
                                                                          alias: true,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "mar8",
                                                                    attrs: {
                                                                      width:
                                                                        "24",
                                                                      height:
                                                                        "24",
                                                                      viewBox:
                                                                        "0 0 24 24",
                                                                      fill: "none",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        "fill-rule":
                                                                          "evenodd",
                                                                        "clip-rule":
                                                                          "evenodd",
                                                                        d: "M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM3.5 12H14.2929L11.1464 15.1464C10.9512 15.3417 10.9512 15.6583 11.1464 15.8536C11.3417 16.0488 11.6583 16.0488 11.8536 15.8536L15.8536 11.8536C16.0488 11.6583 16.0488 11.3417 15.8536 11.1464L11.8536 7.14645C11.6583 6.95118 11.3417 6.95118 11.1464 7.14645C10.9512 7.34171 10.9512 7.65829 11.1464 7.85355L14.2929 11H3.5C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12Z",
                                                                        fill: "#455A64",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Przejdź do zawodów "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : item[header.value] === item.payed
                                            ? [
                                                item.overDate
                                                  ? _c("span", [
                                                      _c("img", {
                                                        staticClass: "icon",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/notpaid.svg"),
                                                        },
                                                      }),
                                                      _vm._v(" Opóźniona "),
                                                    ])
                                                  : item.payed
                                                  ? _c("span", [
                                                      _c("img", {
                                                        staticClass: "icon",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/checkmark-circle.svg"),
                                                        },
                                                      }),
                                                      _vm._v(" Opłacona "),
                                                    ])
                                                  : _c("span", [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass: "icon",
                                                          attrs: {
                                                            width: "16",
                                                            height: "16",
                                                            viewBox:
                                                              "0 0 16 16",
                                                            fill: "none",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z",
                                                              fill: "#455A64",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" Nieopłacona "),
                                                    ]),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[header.value]) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              })
                            },
                          },
                          {
                            key: "no-results",
                            fn: function ({ search: term }) {
                              return [
                                term.length
                                  ? _c("div", [
                                      _vm._v(" Brak wyników wyszukiwania "),
                                      _c("b", [_vm._v(_vm._s(term))]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1517366409
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.listParent === "agreements"
            ? _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { ref: "list" },
                  [
                    _c("base-table", {
                      staticClass: "list",
                      attrs: {
                        headers: _vm.tableHeaders2,
                        items: _vm.items,
                        sortColumnDefault: "0",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function ({ sort }) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "list__row" },
                                  _vm._l(
                                    _vm.tableHeaders2,
                                    function (header, column) {
                                      return _c(
                                        "th",
                                        {
                                          key: column,
                                          staticClass: "list__row-header",
                                          on: {
                                            click: function ($event) {
                                              return sort(column)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(header.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                          },
                          {
                            key: "body",
                            fn: function ({ displayedItems }) {
                              return _vm._l(displayedItems, function (item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    staticClass: "list__row",
                                    class: {
                                      "list__row--overtime": item.overDate,
                                      "list__row--payed": item.payed,
                                      "list__row--notpayed": !item.payed,
                                    },
                                  },
                                  _vm._l(
                                    _vm.tableHeaders2,
                                    function (header, column) {
                                      return _c(
                                        "td",
                                        {
                                          key: column,
                                          staticClass: "list__row-data",
                                          class: {
                                            "list__row-data--name":
                                              header.value ===
                                              "competitionName",
                                            "list__row-data--loading":
                                              _vm.loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              header.value === "more"
                                                ? _vm.menuClickHandler(
                                                    $event,
                                                    item.id
                                                  )
                                                : ""
                                            },
                                          },
                                        },
                                        [
                                          header.value === "more"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    ref: item.id,
                                                    refInFor: true,
                                                    staticClass: "more-btn",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-ellipsis-v",
                                                    }),
                                                    _vm.menuCompetitionId ===
                                                    item.id
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "more-btn__content",
                                                            class: {
                                                              "more-btn__content--from-up":
                                                                _vm.isMenuCompetitionIdInBottom,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "more-btn__content-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.sendPaymentMessage(
                                                                        item.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "mar8",
                                                                    attrs: {
                                                                      width:
                                                                        "24",
                                                                      height:
                                                                        "24",
                                                                      viewBox:
                                                                        "0 0 24 24",
                                                                      fill: "none",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        "fill-rule":
                                                                          "evenodd",
                                                                        "clip-rule":
                                                                          "evenodd",
                                                                        d: "M19.5 5H4.5C3.11929 5 2 6.11929 2 7.5V16.5096C2 17.8903 3.11929 19.0096 4.5 19.0096H19.5C20.8807 19.0096 22 17.8903 22 16.5096V7.5C22 6.11929 20.8807 5 19.5 5ZM20.1444 6.1451C19.9492 6.05207 19.7307 6 19.5 6H4.5C4.26933 6 4.05081 6.05207 3.85557 6.1451L12 12.8523L20.1444 6.1451ZM3.1431 17.1498C3.14773 17.1451 3.15247 17.1405 3.15732 17.1359L8.9772 11.6584L3.18215 6.88597C3.16951 6.87556 3.15752 6.86466 3.14617 6.85332C3.05247 7.04913 3 7.26844 3 7.5V16.5096C3 16.7386 3.05132 16.9557 3.1431 17.1498ZM3.84675 17.8603C4.04422 17.956 4.26584 18.0096 4.5 18.0096H19.5C19.7342 18.0096 19.9558 17.956 20.1533 17.8603L14.2446 12.2992L12.3179 13.886C12.1332 14.038 11.8668 14.038 11.6821 13.886L9.75538 12.2992L3.84675 17.8603ZM21 16.5096C21 16.7386 20.9487 16.9557 20.8569 17.1498C20.8523 17.1451 20.8475 17.1405 20.8427 17.1359L15.0228 11.6584L20.8179 6.88597C20.8305 6.87556 20.8425 6.86466 20.8538 6.85333C20.9475 7.04914 21 7.26844 21 7.5V16.5096Z",
                                                                        fill: "#455A64",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "sended",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Wiadomość wysłana"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "send",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Wyślij wiadmość o płatności"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            item.payed
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "more-btn__content-btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.sendPaymentStatus(
                                                                            item.id,
                                                                            false
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "mar8",
                                                                        attrs: {
                                                                          width:
                                                                            "16",
                                                                          height:
                                                                            "16",
                                                                          viewBox:
                                                                            "0 0 16 16",
                                                                          fill: "none",
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                d: "M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z",
                                                                                fill: "#455A64",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Oznacz jako nieopłacony "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "more-btn__content-btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.sendPaymentStatus(
                                                                            item.id,
                                                                            true
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        staticClass:
                                                                          "mar8",
                                                                        attrs: {
                                                                          width:
                                                                            "24",
                                                                          height:
                                                                            "24",
                                                                          viewBox:
                                                                            "0 0 24 24",
                                                                          fill: "none",
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "fill-rule":
                                                                                  "evenodd",
                                                                                "clip-rule":
                                                                                  "evenodd",
                                                                                d: "M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.8536 9.14645C15.6583 8.95118 15.3417 8.95118 15.1464 9.14645L10.5 13.7929L8.85355 12.1464C8.65829 11.9512 8.34171 11.9512 8.14645 12.1464C7.95118 12.3417 7.95118 12.6583 8.14645 12.8536L10.1464 14.8536C10.3417 15.0488 10.6583 15.0488 10.8536 14.8536L15.8536 9.85355C16.0488 9.65829 16.0488 9.34171 15.8536 9.14645Z",
                                                                                fill: "#455A64",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Oznacz jako opłacony "
                                                                    ),
                                                                  ]
                                                                ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "more-btn__content-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$router.push(
                                                                        {
                                                                          name: "panel-competition-dashboard",
                                                                          params:
                                                                            {
                                                                              name: item.competitionName,
                                                                              id: item.competition,
                                                                            },
                                                                          alias: true,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "mar8",
                                                                    attrs: {
                                                                      width:
                                                                        "24",
                                                                      height:
                                                                        "24",
                                                                      viewBox:
                                                                        "0 0 24 24",
                                                                      fill: "none",
                                                                      xmlns:
                                                                        "http://www.w3.org/2000/svg",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("path", {
                                                                      attrs: {
                                                                        "fill-rule":
                                                                          "evenodd",
                                                                        "clip-rule":
                                                                          "evenodd",
                                                                        d: "M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM3.5 12H14.2929L11.1464 15.1464C10.9512 15.3417 10.9512 15.6583 11.1464 15.8536C11.3417 16.0488 11.6583 16.0488 11.8536 15.8536L15.8536 11.8536C16.0488 11.6583 16.0488 11.3417 15.8536 11.1464L11.8536 7.14645C11.6583 6.95118 11.3417 6.95118 11.1464 7.14645C10.9512 7.34171 10.9512 7.65829 11.1464 7.85355L14.2929 11H3.5C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12Z",
                                                                        fill: "#455A64",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Przejdź do zawodów "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : header.value === "email"
                                            ? [_vm._v(" pole@zmailem.pl ")]
                                            : header.value === "allPayments"
                                            ? [_vm._v(" 16 789,23 PLN ")]
                                            : header.value ===
                                              "lastCountedProvision"
                                            ? [_vm._v(" 6 % ")]
                                            : header.value === "agreement"
                                            ? [
                                                _c("img", {
                                                  staticClass: "icon",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/checkmark-circle.svg"),
                                                  },
                                                }),
                                                _vm._v(" Podpisana "),
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      width: "16",
                                                      height: "16",
                                                      viewBox: "0 0 16 16",
                                                      fill: "none",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        d: "M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z",
                                                        fill: "#455A64",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" Niepodpisana "),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[header.value]) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              })
                            },
                          },
                          {
                            key: "no-results",
                            fn: function ({ search: term }) {
                              return [
                                term.length
                                  ? _c("div", [
                                      _vm._v(" Brak wyników wyszukiwania "),
                                      _c("b", [_vm._v(_vm._s(term))]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2309677650
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
    _vm.ready
      ? _c(
          "div",
          { staticClass: "row admin__pages", staticStyle: { display: "none" } },
          [
            _c(
              "div",
              {
                staticClass: "admin__pages-btn",
                class: {
                  "admin__pages-btn--disabled": !_vm.isPreviousPage,
                },
                on: {
                  click: function ($event) {
                    _vm.isPreviousPage ? _vm.prevPage() : ""
                  },
                },
              },
              [
                _vm.isLoadingPrev
                  ? _c("div", { staticClass: "admin__pages-loading" }, [
                      _vm._m(1),
                    ])
                  : _vm._e(),
                _vm._v(" Poprzednie "),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "admin__pages-btn",
                class: { "admin__pages-btn--disabled": !_vm.isNextPage },
                on: {
                  click: function ($event) {
                    _vm.isNextPage ? _vm.nextPage() : ""
                  },
                },
              },
              [
                _vm.isLoadingNext
                  ? _c("div", { staticClass: "admin__pages-loading" }, [
                      _vm._m(2),
                    ])
                  : _vm._e(),
                _vm._v(" Następne "),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "admin-header__loading-state" }, [
          _vm._v(" Trwa wczytywanie "),
          _c("div", { staticClass: "mal15 lds-ellipsis" }, [
            _c("div"),
            _c("div"),
            _c("div"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }