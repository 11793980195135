var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        [
          _vm.device.type === _vm.deviceTypes.stoper
            ? _c("stoper", { attrs: { "is-panel-gate": "" } })
            : _c("input", {
                attrs: { type: "file" },
                on: {
                  change: function ($event) {
                    _vm.file = $event.target.files[0]
                  },
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }