<template>
    <div class="nav-wrapper">
        <img
            src="@/assets/images/close-white-icon.svg"
            alt="close"
            class="nav-close"
            @click="TOGGLE_NAV()"
        >
        <div class="nav-status">
            <p class="nav-status__title">
                {{ isResultsStatuses ? 'Wyniki' : 'Rejestracja online' }}
            </p>
            <template v-if="!isResultsStatuses">
                <base-button
                    btn9
                    class="nav-status__button"
                    :class="{
                        'nav-status__button--negative': [STATUSES.close, STATUSES.info].includes(competition.status)
                    }"
                    @click="updateCompetitionStatus(STATUSES.close)"
                >
                    Zamknięta
                </base-button>
                <base-button
                    btn9
                    class="nav-status__button"
                    :class="{
                        'nav-status__button--positive':
                            competition.status == STATUSES.open,
                    }"
                    @click="updateCompetitionStatus(STATUSES.open)"
                >
                    Otwarta
                </base-button>
            </template>
            <template v-else>
                <base-button
                    btn9
                    class="nav-status__button nav-status__button--results"
                    :class="{
                        'nav-status__button--positive':
                            competition.status == STATUSES.live,
                    }"
                    @click="updateCompetitionStatus(STATUSES.live)"
                >
                    Live
                </base-button>
                <base-button
                    btn9
                    class="nav-status__button nav-status__button--results"
                    :class="{
                        'nav-status__button--positive':
                            competition.status == STATUSES.unofficial,
                    }"
                    @click="updateCompetitionStatus(STATUSES.unofficial)"
                >
                    Nieoficjalne
                </base-button>
                <base-button
                    btn9
                    class="nav-status__button nav-status__button--results"
                    :class="{
                        'nav-status__button--positive':
                            competition.status == STATUSES.official,
                    }"
                    @click="updateCompetitionStatus(STATUSES.official)"
                >
                    Oficjalne
                </base-button>
            </template>
        </div>
        <base-button-group class="nav-box">
            <base-button
                btn11
                class="nav-box__button nav-box__button--with-icon"
                :pressed="$route.name === 'panel-competition-dashboard'"
                @click="navigateTo('panel-competition-dashboard')"
            >
                <img
                    src="@/assets/images/icons/home.svg"
                    alt="Home icon"
                    class="nav-box__button-icon"
                >
                Zawody
            </base-button>
        </base-button-group>

        <base-button-group class="nav-box">
            <base-button
                btn11
                class="nav-box__button nav-box__button--with-icon"
                @click="navigateTo('panel-competition-setting-info')"
            >
                <img
                    src="@/assets/images/icons/sliders.svg"
                    alt="Slider icon"
                    class="nav-box__button-icon"
                >
                Ustawienia zawodów
            </base-button>
            <base-button
                btn11
                class="nav-box__button"
                :pressed="$route.name === 'panel-competition-setting-info'"
                @click="navigateTo('panel-competition-setting-info')"
            >
                Informacje o zawodach
            </base-button>
            <base-button
                btn11
                class="nav-box__button"
                :pressed="
                    $route.name === 'panel-competition-setting-classifications'
                "
                @click="navigateTo('panel-competition-setting-classifications')"
            >
                Klasyfikacje, opłaty, limity
            </base-button>
            <base-button
                btn11
                class="nav-box__button"
                :pressed="$route.name === 'panel-competition-setting-numbers'"
                @click="navigateTo('panel-competition-setting-numbers')"
            >
                Numeracja startowa
            </base-button>
            <base-button
                btn11
                class="nav-box__button"
                :pressed="
                    $route.name === 'panel-competition-setting-regulation'
                "
                @click="navigateTo('panel-competition-setting-regulation')"
            >
                Regulamin zawodów
            </base-button>
            <div class="nav-box__hint">
                Aby skorzystać z zaawansowanych ustawień zawodów, przejdź do
                pełnej wersji panelu, używając przeglądarki na komputerze.
            </div>
        </base-button-group>
        <base-button-group class="nav-box">
            <base-button
                btn11
                class="nav-box__button nav-box__button--with-icon"
                :pressed="$route.name === 'panel-competition-list-new'"
                @click="navigateTo('panel-competition-list-new')"
            >
                <img
                    src="@/assets/images/icons/users.svg"
                    alt="Users icon"
                    class="nav-box__button-icon"
                >
                Lista startowa
            </base-button>
        </base-button-group>
        <base-button-group class="nav-box">
            <base-button
                btn11
                class="nav-box__button nav-box__button--with-icon"
                :pressed="$route.name === 'panel-competition-result'"
                @click="navigateTo('panel-competition-result')"
            >
                <img
                    src="@/assets/images/icons/list-number.svg"
                    alt="List icon"
                    class="nav-box__button-icon"
                >
                Wyniki
            </base-button>
            <div class="nav-box__hint">
                Aby wgrać wyniki z pliku, przejdź do pełnej wersji panelu, używając przeglądarki na komputerze.
            </div>
        </base-button-group>
        <base-button-group class="nav-box nav-box--last">
            <base-button
                btn11
                class="nav-box__button nav-box__button--with-icon"
                :pressed="$route.name === 'panel-competition-stoper'"
                @click="navigateTo('panel-competition-stoper')"
            >
                <img
                    src="@/assets/images/icons/clock.svg"
                    alt="Clock icon"
                    class="nav-box__button-icon"
                >
                Stoper
            </base-button>
        </base-button-group>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { TOGGLE_NAV } from '@/store/mutations.type';
import { UPDATE_COMPETITION } from '@/store/actions.type';
import { STATUSES } from '@/config';

export default {
    data() {
        return { STATUSES };
    },

    computed: {
        ...mapGetters(['competitions']),
        competition() {
            return this.competitions[this.$route.params.id];
        },
        isResultsStatuses() {
            return (
                (this.$route.name === 'panel-competition-stoper'
                    && this.competition.status <= 4)
                || this.competition.status >= 4
            );
        },
    },
    methods: {
        ...mapMutations([TOGGLE_NAV]),
        ...mapActions([UPDATE_COMPETITION]),
        async updateCompetitionStatus(status) {
            await this.UPDATE_COMPETITION({ ...this.competition, status });
        },

        navigateTo(name) {
            this.TOGGLE_NAV();
            this.$router.push({
                name,
                params: this.competition,
                alias: true,
            });
        },
    },
};
</script>

<style scoped>
.nav-close {
    background-color: var(--color-slate-two);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
}
.nav-wrapper {
    position: fixed;
    overscroll-behavior: contain;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    background: var(--color-charcoal-grey);
    z-index: 999;
    padding: 40px 16px;
    font-weight: 400;
}
.nav-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 8px;
}
.nav-status__title {
    width: 100%;
    margin-bottom: 8px;
}
.nav-status__button {
    width: calc(50% - 2px);
}
.nav-status__button--results {
    width: calc(33.33% - 2px);
}
.nav-status__button--positive {
    background-color: var(--color-dark-pastel-green);
}
.nav-status__button--negative {
    background-color: var(--color-lipstick);
}
.nav-box {
    margin-top: 16px;
}
.nav-box--last {
    margin-bottom: 40px;
}
.nav-box__button {
    height: 48px;
    padding-left: 12px;
    align-items: center;
}
.nav-box__button--with-icon {
    padding-left: 8px;
}
.nav-box__button-icon {
    margin-right: 8px;
}
.nav-box__hint {
    background-color: var(--color-slate);
    padding: 8px 12px 12px 12px;
    font-size: 12px;
    line-height: 16px;
}
@media (min-width: 992px) {
    .nav-wrapper {
        display: none;
    }
}
</style>
