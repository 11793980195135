var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container matb45" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("h1", { staticClass: "mab16" }, [
            _vm._v(" " + _vm._s(_vm.say.error_404) + " "),
          ]),
          _c(
            "router-link",
            { attrs: { to: { name: "main-landing", alias: true } } },
            [_vm._v(" " + _vm._s(_vm.say.go_home) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }