var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready && _vm.stoperGate
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "h100", class: { height: !_vm.stoperGate } },
          [_c("stoper")],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }