import { take } from '@/utils/array';
import _ from 'lodash';
import moment, { duration } from 'moment';

//
// Stoper service.
//

export function getDuration(classifications, time) {
    const classificationsStartTimes = take(classifications, 'startTime').filter((startTime) => startTime !== null);
    const [earliestStartTime] = classificationsStartTimes.sort((a, b) => a?.diff(b));

    return duration(time.diff(earliestStartTime));
}

export function isStarted(gate, classifications) {
    let isStartedTime = false;
    for (const classification of classifications) {
        if (classification.startTime?.unix() < moment().unix()) {
            isStartedTime = true;
        }
    }
    return gate && isStartedTime;
}

export function createCheckpointNumber(checkpointGroups, position = null, oldPosition = null) {
    let prevGroup;
    let nextGroup;

    if (position) {
        // Insert into given position.
        prevGroup = checkpointGroups[position - Number(position < oldPosition)];
        nextGroup = checkpointGroups[position + Number(position > oldPosition)];
    } else {
        // Insert into position before first meta.
        const checkpointGroupsMeta = checkpointGroups.filter((checkpoint) => checkpoint.number === 1);
        if (checkpointGroups.length > 1) {
            [prevGroup] = checkpointGroups.slice(-(checkpointGroupsMeta.length + 1));
        } else {
            prevGroup = undefined;
        }
        [nextGroup] = checkpointGroups.slice(-checkpointGroupsMeta.length);
    }

    if (!nextGroup) {
        return 1;
    }

    if (!prevGroup) {
        return 0;
    }

    return (prevGroup.number + nextGroup.number) / 2;
}

export function createCheckpointName(checkpointGroups, isStart, isFinish) {
    const checkpointGroupsStarts = checkpointGroups.filter((checkpointGroup) => checkpointGroup.number === 0);
    const checkpointGroupsNames = checkpointGroups.map((group) => group.name);
    const checkpointGroupsFinishes = checkpointGroups.filter((checkpointGroup) => checkpointGroup.number === 1);
    let i;
    let name;

    // Create name as follows based on checkpoint type:
    // Start 1, Start 2, ..., Start X, Checkpoint 1, Checkpoint 2, ..., Checkpoint Y, Meta.
    if (isStart) {
        i = checkpointGroupsStarts.length + 1;
        name = () => `Start ${i}`;
    } else if (isFinish) {
        i = checkpointGroupsFinishes.length + 1;
        name = () => `Meta ${i}`;
    } else {
        i = checkpointGroups.length - 1;
        name = () => `Międzyczas ${i}`;
    }

    while (checkpointGroupsNames.includes(name())) {
        i += 1;
    }

    return name();
}

export function createCheckpoint(checkpointGroups, isStart, isFinish = false) {
    let nr;
    if (isStart) {
        nr = 0;
    } else if (isFinish) {
        nr = 1;
    } else {
        nr = createCheckpointNumber(checkpointGroups);
    }
    return {
        name: createCheckpointName(checkpointGroups, isStart, isFinish),
        gates: [],
        number: nr,
        hidden: false,
        classification: null,
    };
}

export function createCheckpointGroups(competition, getters) {
    const map = new Map();

    for (const classification of getters.classificationsBy(competition)) {
        for (const checkpoint of getters.checkpointsBy(classification)) {
            if (!map.has(checkpoint.name)) {
                const checkpointGroup = {
                    // Meta.
                    _type: 'checkpointGroup',
                    // Fields.
                    competition: competition.id,
                    name: checkpoint.name,
                    number: checkpoint.number,
                    checkpointsIds: [checkpoint.id],
                };

                map.set(checkpoint.name, checkpointGroup);
            } else {
                map.get(checkpoint.name).checkpointsIds.push(checkpoint.id);
            }
        }
    }

    return _.sortBy(Array.from(map.values()), ['number', 'name']);
}
