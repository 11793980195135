import store from '@/store';
import { BOOTSTRAP } from '@/store/actions.type';

export default async (to, from, next) => {
    const { bootstraped } = store.getters;

    if (!bootstraped) {
        await store.dispatch(BOOTSTRAP);
    }

    next();
};
