import api from '@/api';
import {
    DISABLE_FAMILY, ENABLE_FAMILY, ADD_ORGANIZER_ROLE, ADD_USER_ROLE,
} from '@/store/actions.type';
import { SET_ENTITIES } from '@/store/mutations.type';

export default {
    actions: {
        async [ENABLE_FAMILY]({ commit, getters }, familyAssociation) {
            const parentId = getters.users[familyAssociation.parentUser].id;
            await api.post(`/user/parents/${parentId}/enable`);

            const enabled = {
                ...familyAssociation,
                pending: false,
                enabled: true,
            };

            commit(SET_ENTITIES, { familyAssociations: { [familyAssociation.id]: enabled } });
        },
        async [DISABLE_FAMILY]({ commit, getters }, familyAssociation) {
            const parentId = getters.users[familyAssociation.parentUser].id;

            await api.post(`/user/parents/${parentId}/disable`);

            const disabled = {
                ...familyAssociation,
                pending: false,
                enabled: false,
            };

            commit(SET_ENTITIES, { familyAssociations: { [familyAssociation.id]: disabled } });
        },
        async [ADD_ORGANIZER_ROLE]({ commit, getters }) {
            let data;
            try {
                // Response - organizer object.
                data = await api.post('/organizers');
            } catch (e) {
                return e;
            }
            const user = {
                ...getters.user,
                organizer: data.id,
                roles: [...getters.user.roles, 'ROLE_ORGANIZER'],
            };
            commit(SET_ENTITIES, { users: { [user.id]: user } });

            return true;
        },
        async [ADD_USER_ROLE]({ commit, getters }) {
            try {
                await api.post('/role/user');
            } catch (e) {
                return e;
            }
            const user = {
                ...getters.user,
                roles: [...getters.user.roles, 'ROLE_USER'],
            };
            commit(SET_ENTITIES, { users: { [user.id]: user } });

            return true;
        },
    },
};
