<template>
    <div :style="isMobile ? 'padding-bottom: 48px;' : null">
        <div
            v-if="!isOnline"
            class="network-info network-info--sticky"
        >
            <div class="row">
                <div class="col-12">
                    <p class="align-center">
                        Jesteś w trybie offline.
                    </p>
                </div>
            </div>
        </div>
        <base-notification-global />

        <layout-front-header v-if="$route.name !== 'stoper-demo'" />

        <router-view />

        <layout-front-footer />
    </div>
</template>

<script>
import LayoutFrontHeader from '@/components/LayoutFrontHeader';
import LayoutFrontFooter from '@/components/LayoutFrontFooter';
import { mapGetters } from 'vuex';

export default {
    components: {
        LayoutFrontHeader,
        LayoutFrontFooter,
    },
    computed: {
        ...mapGetters(['isOnline']),
        isMobile() {
            return ['sm'].includes(this.$mq);
        },        
    },
};
</script>
