import { many } from '@/utils/array';

//
// Security service.
//

function checkUserRoles(user, roles) {
    // Success if roles empty or any role matches.
    return (
        roles.length === 0
        || (roles.includes('anonymous') && !user)
        || (roles.includes('user') && user && user.roles.includes('ROLE_USER'))
        || (roles.includes('organizer') && user && user.roles.includes('ROLE_ORGANIZER'))
        || (roles.includes('operator') && user && user.roles.includes('ROLE_OPERATOR'))
        || (roles.includes('admin') && user && user.roles.includes('ROLE_ADMIN'))
    );
}

function getFailedRedirection(user, to) {
    // Authentication fail.
    if (!user) {
        // Redirect to login page.
        return {
            name: 'authentication-login',
            query: {
                redirect: to.fullPath,
            },
            alias: true,
        };
    }

    // Redirect to main page.
    return { name: 'main-landing', alias: true };
}

// eslint-disable-next-line import/prefer-default-export
export function authorize(to, from, user) {
    for (const route of to.matched) {
        const roles = many(route.meta.userRole);

        // Check user roles.
        // Exclude route panel-gate because has anonymous user role (edge case).
        if (!checkUserRoles(user, roles) && to.name !== 'panel-gate') {
            // Redirect.
            return getFailedRedirection(user, to);
        }
    }

    // No redirect.
    return null;
}
