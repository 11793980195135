import { schema } from 'normalizr';

export default new schema.Entity(
    'competitionPlayerCounts',
    {},
    {
        idAttribute: 'competition',
        processStrategy: (competitionPlayerCount) => ({
            // Meta.
            _type: 'competitionPlayerCount',
            // Fields.
            ...competitionPlayerCount,
            playersCount: Number.parseInt(competitionPlayerCount.playersCount, 10),
            playersPayCount: Number.parseInt(competitionPlayerCount.playersPayCount, 10),
        }),
    },
);
