import countries from '@/assets/data/countries';
import Vue from 'vue';

Vue.filter('asNationality', (code) => {
    for (const country of countries) {
        if (country.id === code) {
            return country.text;
        }
    }

    return '';
});
