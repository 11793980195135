import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('prices', {}, {
    processStrategy: (price) => ({
        // Meta.
        _type: 'price',
        // Fields.
        ...price,
        // Cast types.
        endedTime: moment(price.endedTime),
    }),
});
