import { schema } from 'normalizr';

export default new schema.Entity('cycles', {}, {
    processStrategy: (cycle) => ({
        // Meta.
        _type: 'cycle',
        // Fields.
        ...cycle,
    }),
});
