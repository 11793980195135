var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rangeSlider__newWrap" }, [
    _c("div", {
      ref: "newHandle",
      staticClass: "rangeSlider__new",
      on: { click: _vm.newRange },
    }),
    _c("div", { attrs: { id: "newHandle" } }, [
      _c("div", { staticClass: "newLine" }),
      _c("img", {
        ref: "handle",
        staticClass: "newMarker",
        attrs: {
          src: require("@/assets/images/icons/range-slider/marker_new.svg"),
          alt: "",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }