<template>
    <div
        v-if="ready"
        class="container map-container"
        :class="{ 'map-container--zen-mode': isZenMode }"
    >
        <div
            class="row"
            :class="{ h100: isZenMode }"
        >
            <!-- Sidebar. -->
            <div
                :class="
                    isZenMode
                        ? 'col-lg-3 map-container__sidebar--zen-mode'
                        : 'col-lg-3'
                "
            >
                <div class="map-container__sidebar">
                    <div>
                        <h2>Trasa i punkty na trasie</h2>
                        <p class="mat16">
                            Klasyfikacja
                        </p>
                        <base-input-select
                            v-model="selectedClassification"
                            class="mat8 map-container__sidebar-select"
                            :options="classifications"
                            label-select="namePl"
                            label="Klasyfikacja"
                            :error.sync="errors.classification"
                        />
                        <p class="mat16">
                            Dodaj trasę w pliku .gpx
                        </p>

                        <base-input-file
							:key="inputFileKey"
                            v-model="trackFileList"
                            class="mat8"
                            :class="{
                                'map-container__sidebar-file': !isZenMode,
                            }"
                            hint="Wybierz plik"
                            :disabled="disabledInput"
                            @input="createGpx()"
                            @click="
                                disabledInput
                                    ? (errors.classification =
                                        'Wybierz klasyfikacje')
                                    : ''
                            "
                        />
                        <base-button
                            class="mat48"
                            btn2
                            icon="fas fa-plus"
                            @click="addPoint()"
                        >
                            Dodaj punkt
                        </base-button>
                    </div>
                    Tracking obłsugiwany przez:
                        <img
                            class="img-fluid mal24"
                            src="@/assets/images/logo-ap-white.png"
                            style="max-width:200px"
                            @click="
                                $router.push({
                                    path: `http://tracking.activeprogress.eu`,
                                    tab: true,
                                })
                        "
                        >
                    <base-button
                        btn2
                        class="mat16"
                        @click="
                            $router.push({
                                path: `http://tracking.activeprogress.eu/${competition.name}-v${competition.id}`,
                                tab: true,
                            })
                        "
                    >
                        Twój tracking GPS
                    </base-button>
                </div>
            </div>

            <!-- Map. -->
            <div
                :class="
                    isZenMode
                        ? 'col-lg-9 map-container__map--zen-mode'
                        : 'col-lg-9'
                "
            >
                <map-competition
                    :competition="competition"
                    :classifications="classifications"
                    :selected-classification.sync="selectedClassification"
                    @delete-point="onDeletePointClick"
                    @ready="map = $event"
                />
            </div>
            <!-- Popup. -->
            <base-popup v-if="showPopupForRemove">
                <!-- Title. -->
                <template #title>
                    Usuń punkt
                </template>
                <!-- Subtitle. -->
                <template #subtitle>
                    <b>Czy na pewno, chcesz usunąć wybrany punkt?</b>
                </template>
                <!-- Content. -->
                <template #content>
                    <div class="mat16">
                        Zostanie on usunięty z
                        <b>wszystkich klasyfikacji</b>
                        . Aby nie był wyświetlany w tej klasyfikacji, nie
                        wybieraj żadnej kategorii w punkcie (nie wybrane Start,
                        Meta, Pomiar, Punkt żywieniowy)
                    </div>
                </template>
                <!-- Buttons. -->
                <template #buttons>
                    <base-button
                        class="mar15"
                        btn2
                        @click="
                            (showPopupForRemove = !showPopupForRemove),
                            (pointToDelete = null)
                        "
                    >
                        anuluj
                    </base-button>
                    <base-button
                        class="mal15"
                        btn1
                        @click="
                            (showPopupForRemove = !showPopupForRemove),
                            deletePoint()
                        "
                    >
                        Usuń punkt
                    </base-button>
                </template>
            </base-popup>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    READ_PANEL_COMPETITION,
    READ_COMPETITION_CLASSIFICATIONS,
    CREATE_LOCATION,
    CREATE_TRACKPOINT,
    DELETE_TRACKPOINT,
    DELETE_LOCATION,
    CREATE_CLASSIFICATION_GPX_FILE,
} from '@/store/actions.type';
import { TRACKPOINT_TYPES } from '@/config';
import MapCompetition from '@/components/MapCompetition';

export default {
    components: {
        MapCompetition,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            selectedClassification: null,
            map: undefined,
            showPopupForRemove: false,
            pointToDelete: null,
            confirmDeletePoint: false,
            trackFileList: [],
            errors: {},
			inputFileKey: 0
        };
    },
    computed: {
        ...mapGetters(['competitions', 'classificationsBy', 'isZenMode']),
        competition() {
            return this.competitions[this.id];
        },
        classifications() {
            return this.classificationsBy(this.competition);
        },
        disabledInput() {
            return !this.selectedClassification;
        },
    },
    async created() {
        await Promise.all([
            this.READ_PANEL_COMPETITION(this.id),
            this.READ_COMPETITION_CLASSIFICATIONS(this.id),
        ]);

        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_PANEL_COMPETITION,
            READ_COMPETITION_CLASSIFICATIONS,
            CREATE_LOCATION,
            CREATE_TRACKPOINT,
            DELETE_LOCATION,
            DELETE_TRACKPOINT,
            CREATE_CLASSIFICATION_GPX_FILE,
        ]),
        async addPoint() {
            const centerMap = this.map.getBounds().getCenter();
            const locationId = await this.CREATE_LOCATION({
                lat: centerMap.lat,
                lng: centerMap.lng,
            });

            await this.CREATE_TRACKPOINT({
                location: locationId,
                type: TRACKPOINT_TYPES.checkpoint,
                classifications: this.classifications.map(
                    (classification) => classification.id,
                ),
            });
        },
        onDeletePointClick(point) {
            this.showPopupForRemove = true;
            this.pointToDelete = point;
        },
        async deletePoint() {
            const promises = [];
            const trackpointsIds = Object.values(
                this.pointToDelete.trackpoints,
            );
            for (const id of trackpointsIds) {
                promises.push(this.DELETE_TRACKPOINT(id));
            }

            await Promise.all(promises);
            await this.DELETE_LOCATION(this.pointToDelete.location);
            this.pointToDelete = null;
        },
        async createGpx() {
            await this.CREATE_CLASSIFICATION_GPX_FILE({
                id: this.selectedClassification.id,
                gpxFile: this.trackFileList[0],
            });
            this.trackFileList = [];
			this.inputFileKey += 1
        },
        trackClickHandler(track) {
            const clickedClassification = this.classifications.filter(
                (classification) => classification.id === track.classification,
            );
            const [classification] = clickedClassification;
            this.selectedClassification = classification;
        },
    },
};
</script>

<style scoped>
.map-container__map {
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}
.map-container {
    border-radius: 4px;
    background-color: var(--color-slate);
    padding: 0;
    margin-top: 40px;
}
.map-container__sidebar {
    height: 100%;
    padding: 24px 30px 24px 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
}

.map-container__sidebar-select >>> .vs__selected {
    white-space: normal;
    padding-top: 5px;
}
.map-container__sidebar-file {
    max-width: 238.5px;
}
.map-container--zen-mode {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: unset;
}
.map-container__sidebar--zen-mode {
    max-width: 380px;
}
.map-container__map--zen-mode {
    flex-grow: 1;
}
</style>
