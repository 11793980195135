var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.validationList.length
    ? _c("base-notification-local", {
        attrs: { negative: "" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.say.correct_form_fields) + ": "),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.validationList) + ". ")]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2833925022
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }