var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.competition && Object.keys(_vm.layout).length > 0
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("ValidationObserver", {
            ref: "registerFormObserver",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ handleSubmit }) {
                    return [
                      _c(
                        "form",
                        {
                          class: { "is-waiting": _vm.isWaiting },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.registerSubmit)
                            },
                          },
                        },
                        [
                          _vm.currentFormStep === "playerData"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [
                                        _vm._v("Zapisz się do zawodów"),
                                      ]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-3" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-step-header",
                                            },
                                            [
                                              _c("h4", [
                                                _vm._v("Dane uczestnika"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-9" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "section-wrap bg--gray",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.firstname",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        error:
                                                                          errors[0],
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .firstname,
                                                                        border:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .firstname,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "firstname",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.firstname",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.lastname",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        error:
                                                                          errors[0],
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .lastname,
                                                                        border:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .lastname,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "lastname",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.lastname",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.email",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        error:
                                                                          errors[0],
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .email,
                                                                        border:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .email,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "email",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.email",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "row p4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-4",
                                                            },
                                                            [
                                                              _c(
                                                                "ValidationProvider",
                                                                {
                                                                  staticClass:
                                                                    "scroll-margin",
                                                                  attrs: {
                                                                    name: "playerData.phonePrefix",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "base-input-select",
                                                                                {
                                                                                  staticClass:
                                                                                    "no-border",
                                                                                  attrs:
                                                                                    {
                                                                                      reduce:
                                                                                        (
                                                                                          prefix
                                                                                        ) =>
                                                                                          prefix.id,
                                                                                      clearable: false,
                                                                                      options:
                                                                                        _vm.prefixes,
                                                                                      error:
                                                                                        errors[0],
                                                                                      label:
                                                                                        _vm
                                                                                          .say
                                                                                          .prefix,
                                                                                    },
                                                                                  on: {
                                                                                    "update:error":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          errors,
                                                                                          0,
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .form
                                                                                          .user
                                                                                          .phonePrefix,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm
                                                                                              .form
                                                                                              .user,
                                                                                            "phonePrefix",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "form.user.phonePrefix",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-8",
                                                            },
                                                            [
                                                              _c(
                                                                "ValidationProvider",
                                                                {
                                                                  staticClass:
                                                                    "scroll-margin",
                                                                  attrs: {
                                                                    name: "playerData.phone",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "base-input-text",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm
                                                                                          .say
                                                                                          .phone,
                                                                                      error:
                                                                                        errors[0],
                                                                                    },
                                                                                  on: {
                                                                                    "update:error":
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$set(
                                                                                          errors,
                                                                                          0,
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .form
                                                                                          .user
                                                                                          .phone,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm
                                                                                              .form
                                                                                              .user,
                                                                                            "phone",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "form.user.phone",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.gender",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-radio",
                                                                    {
                                                                      staticClass:
                                                                        "no-border",
                                                                      attrs: {
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .gender,
                                                                        options:
                                                                          [
                                                                            {
                                                                              value:
                                                                                "M",
                                                                              title:
                                                                                "man",
                                                                            },
                                                                            {
                                                                              value:
                                                                                "K",
                                                                              title:
                                                                                "woman",
                                                                            },
                                                                          ],
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .gender,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "gender",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.gender",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.birthDate",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-date",
                                                                    {
                                                                      staticClass:
                                                                        "no-border",
                                                                      attrs: {
                                                                        error:
                                                                          errors[0],
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .birthtime,
                                                                        initialView:
                                                                          "year",
                                                                        openDate:
                                                                          new Date(
                                                                            "1983-01-01"
                                                                          ),
                                                                        format:
                                                                          "yyyy.MM.dd",
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .birthDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "birthDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.birthDate",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.city",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .city,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .city,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "city",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.city",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.nationality",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-select",
                                                                    {
                                                                      staticClass:
                                                                        "no-border",
                                                                      attrs: {
                                                                        options:
                                                                          _vm.countries,
                                                                        clearable: false,
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .nationality,
                                                                        reduce:
                                                                          (
                                                                            country
                                                                          ) =>
                                                                            country.id,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .nationality,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "nationality",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.nationality",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.clubname",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .club,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .clubname,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "clubname",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.clubname",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "playerData.icePhone",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "base-input-text",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm
                                                                            .say
                                                                            .phone_ice,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .user
                                                                            .icePhone,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .form
                                                                                .user,
                                                                              "icePhone",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.user.icePhone",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c(
                                                        "base-input-checkbox",
                                                        {
                                                          attrs: {
                                                            "checkbox-value": false,
                                                            name: "anonymous",
                                                            wrap: "",
                                                            label:
                                                              "Ukrycie uczestnictwa",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.user
                                                                .isAnonymous,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form.user,
                                                                "isAnonymous",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.user.isAnonymous",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Uczestnictwo anonimowe"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-md-6",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        { staticClass: "hint" },
                                                        [
                                                          _vm._v(
                                                            "Zaznacz, aby ukryć swoje dane w serwisie DOSTARTU. Uwaga - Twoje dane będą widoczne z podpisem “Uczestnik anonimowy”. "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "classification"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [
                                        _vm._v("Wybierz klasyfikacje"),
                                      ]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-3" },
                                        [_vm._v(" ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-9" },
                                        [
                                          _c("ValidationProvider", {
                                            staticClass: "scroll-margin",
                                            attrs: {
                                              name: "form.classificationId",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.form
                                                                .classificationId,
                                                            expression:
                                                              "form.classificationId",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "hidden",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.form
                                                              .classificationId,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.form,
                                                              "classificationId",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._l(
                                                        _vm.form
                                                          .classifications,
                                                        function (
                                                          classification
                                                        ) {
                                                          return _c(
                                                            "Classification",
                                                            {
                                                              key: classification.id,
                                                              attrs: {
                                                                id: classification.id,
                                                                classification:
                                                                  classification,
                                                                "competition-id":
                                                                  _vm.id,
                                                                playerAge:
                                                                  _vm.birthAge,
                                                                checked:
                                                                  _vm.form
                                                                    .classificationId ===
                                                                  classification.id,
                                                                error:
                                                                  errors[0],
                                                              },
                                                              on: {
                                                                input: (
                                                                  data
                                                                ) => {
                                                                  _vm.form.classificationId =
                                                                    parseInt(
                                                                      data.value
                                                                    )
                                                                  _vm.form.price =
                                                                    parseInt(
                                                                      data.price
                                                                    ).toFixed(2)
                                                                },
                                                                "update:error":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      errors,
                                                                      0,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "validation-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.say[
                                                                    errors[0]
                                                                  ]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "specialCategories"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [_vm._v("Kategorie specjalne")]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-3" },
                                        [_vm._v(" ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-9" },
                                        _vm._l(
                                          _vm.form.specialCategories,
                                          function (category) {
                                            return _c("SpecialCategory", {
                                              key: category.id,
                                              attrs: {
                                                id: category.id,
                                                category: category,
                                                checked:
                                                  _vm.form
                                                    .specialCategorieId ===
                                                  category.id,
                                                error: _vm.errors[0],
                                              },
                                              on: {
                                                input: (data) => {
                                                  _vm.form.specialCategorieId =
                                                    parseInt(data.value)
                                                  _vm.form.price = parseInt(
                                                    data.price
                                                  ).toFixed(2)
                                                },
                                                "update:error": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.errors,
                                                    0,
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "inputs"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [_vm._v("Pola dodatkowe")]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-3" },
                                        [_vm._v(" ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-9" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "section-wrap bg--gray",
                                            },
                                            [
                                              _vm.inputsAdditionalInformation &&
                                              _vm.inputsAdditionalInformation
                                                .length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.inputsAdditionalInformation,
                                                      function (input, index) {
                                                        return _c(
                                                          "ValidationProvider",
                                                          {
                                                            key:
                                                              input.id +
                                                              "_" +
                                                              index +
                                                              "wrapAddUsr",
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name:
                                                                "playerInputs" +
                                                                input.id +
                                                                "_" +
                                                                index +
                                                                "wrapAddUsr",
                                                              rules: {
                                                                required:
                                                                  input.fRequired,
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-wrap",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              data: input,
                                                                              errors:
                                                                                errors[0],
                                                                              options:
                                                                                {
                                                                                  fr: {
                                                                                    wrap: true,
                                                                                  },
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .playerInputs[
                                                                                  input
                                                                                    .id
                                                                                ]
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .form
                                                                                      .playerInputs[
                                                                                      input
                                                                                        .id
                                                                                    ],
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.playerInputs[input.id].value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.inputsSupportFormUser &&
                                              _vm.inputsSupportFormUser.length >
                                                0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.inputsSupportFormUser,
                                                      function (input, index) {
                                                        return _c(
                                                          "ValidationProvider",
                                                          {
                                                            key:
                                                              input.id +
                                                              "_" +
                                                              index +
                                                              "wrapAddSuppUsr",
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name:
                                                                "playerInputs" +
                                                                input.id +
                                                                "_" +
                                                                index +
                                                                "wrapAddSuppUsr",
                                                              rules: {
                                                                required: {
                                                                  allowFalse:
                                                                    !input.fRequired,
                                                                },
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-wrap",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              data: input,
                                                                              errors:
                                                                                errors[0],
                                                                              options:
                                                                                {
                                                                                  fr: {
                                                                                    wrap: true,
                                                                                  },
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .playerInputs[
                                                                                  input
                                                                                    .id
                                                                                ]
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .form
                                                                                      .playerInputs[
                                                                                      input
                                                                                        .id
                                                                                    ],
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.playerInputs[input.id].value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.inputsConfirmationInformation &&
                                              _vm.inputsConfirmationInformation
                                                .length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.inputsConfirmationInformation,
                                                      function (input, index) {
                                                        return _c(
                                                          "ValidationProvider",
                                                          {
                                                            key:
                                                              input.id +
                                                              "_" +
                                                              index +
                                                              "wrapAddConfUsr",
                                                            staticClass:
                                                              "scroll-margin",
                                                            attrs: {
                                                              name:
                                                                "playerInputs" +
                                                                input.id +
                                                                "_" +
                                                                index +
                                                                "wrapAddConfUsr",
                                                              rules: {
                                                                required: {
                                                                  allowFalse:
                                                                    !input.fRequired,
                                                                },
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "panel-input-wrap",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              data: input,
                                                                              errors:
                                                                                errors[0],
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .playerInputs[
                                                                                  input
                                                                                    .id
                                                                                ]
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .form
                                                                                      .playerInputs[
                                                                                      input
                                                                                        .id
                                                                                    ],
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.playerInputs[input.id].value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm.form.totalPrice
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-summary-info",
                                                  staticStyle: {
                                                    "margin-top": "16px",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c("div", [
                                                      _c("h4", [
                                                        _vm._v("Do zapłaty"),
                                                      ]),
                                                    ]),
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.form.totalPrice
                                                        ) + " zł"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "insurance"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [
                                        _vm._v("Ubezpiecz swój start "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-success text-normal",
                                          },
                                          [_vm._v("(opcjonalnie)")]
                                        ),
                                      ]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _vm._l(
                                      _vm.insuranceProducts,
                                      function (insurance) {
                                        return _c(
                                          "div",
                                          {
                                            key: insurance.id,
                                            staticClass: "row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-lg-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "register-step-header",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(insurance.name)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-lg-9",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "section-wrap bg--gray",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 col-md-6 col-lg-7",
                                                          },
                                                          [
                                                            _c(
                                                              "base-input-checkbox",
                                                              {
                                                                attrs: {
                                                                  "checkbox-value":
                                                                    insurance.id,
                                                                  value:
                                                                    insurance.id,
                                                                  name: "insurances",
                                                                  wrap: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .insurances,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "insurances",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.insurances",
                                                                },
                                                              },
                                                              [
                                                                _c("h4", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      insurance.name
                                                                    ) + " "
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "asPrice"
                                                                        )(
                                                                          insurance
                                                                            .packages[0]
                                                                            .price
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                insurance.id ===
                                                                4
                                                                  ? _c("p", [
                                                                      _vm._v(
                                                                        " maksymalny zwrot kosztów rehabilitacji " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "asNumberFormat"
                                                                            )(
                                                                              insurance
                                                                                .packages[0]
                                                                                .max_refund
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : insurance.id ===
                                                                    5
                                                                  ? _c("p", [
                                                                      _vm._v(
                                                                        " Złamania (max. suma ubezpieczenia " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "asNumberFormat"
                                                                            )(
                                                                              insurance
                                                                                .packages[0]
                                                                                .max_bone_group_3
                                                                            )
                                                                          ) +
                                                                          "), urazy (max. suma ubezpieczenia " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "asNumberFormat"
                                                                            )(
                                                                              insurance
                                                                                .packages[0]
                                                                                .max_injury_group_3
                                                                            )
                                                                          ) +
                                                                          "), śmierć w wyniku nieszczęśliwego wypadku (suma ubezpieczenia " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "asNumberFormat"
                                                                            )(
                                                                              insurance
                                                                                .packages[0]
                                                                                .max_death_refund
                                                                            )
                                                                          ) +
                                                                          "). "
                                                                      ),
                                                                      _c("br"),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        "Okres ubezpieczenia: od godziny startu w wydarzeniu sportowym do przekroczenia linii mety. "
                                                                      ),
                                                                    ])
                                                                  : insurance.id ===
                                                                    6
                                                                  ? _c("p", [
                                                                      _vm._v(
                                                                        " maksymalny zwrot kosztów rezygnacji z zawodów " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "asNumberFormat"
                                                                            )(
                                                                              insurance
                                                                                .packages[0]
                                                                                .max_resignation_refund
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm.form.totalPrice
                                      ? _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12 col-lg-3" },
                                            [_vm._v(" ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-12 col-lg-9" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-summary-info",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("div", [
                                                      _c("h4", [
                                                        _vm._v("Do zapłaty"),
                                                      ]),
                                                    ]),
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.form.totalPrice
                                                        ) + " zł"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "shop"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [_vm._v("Sklep")]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _vm.shopProducts &&
                                    _vm.shopProducts.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "register-shop-items",
                                          },
                                          [
                                            _vm._l(
                                              _vm.shopProducts.filter(
                                                (i) => i.visible
                                              ),
                                              function (product, index) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "register-shop-item",
                                                    },
                                                    [
                                                      product.images &&
                                                      product.images.length >
                                                        0 &&
                                                      product.images[0].visible
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "register-shop-img",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: `https://dostartu.pl/files/shop/products/images/${product.images[0].filename}`,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "register-shop-details",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "register-shop-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "register-shop-price",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product.price
                                                                ) + " zł"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      product.models &&
                                                      product.models.length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "register-shop-variants",
                                                            },
                                                            _vm._l(
                                                              product.models.filter(
                                                                (i) => i.visible
                                                              ),
                                                              function (model) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "register-shop-variants-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "register-shop-variants__name",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            model.name
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "QuantityInput",
                                                                      {
                                                                        attrs: {
                                                                          product:
                                                                            product,
                                                                          model:
                                                                            model,
                                                                          value:
                                                                            _vm.getShopProductValue(
                                                                              product.id,
                                                                              model.id
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          "quantity-change":
                                                                            _vm.handleQuantityChange,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "register-shop-variants",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "register-shop-variants-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "register-shop-variants__name",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "QuantityInput",
                                                                    {
                                                                      attrs: {
                                                                        product:
                                                                          product,
                                                                        value:
                                                                          _vm.getShopProductValue(
                                                                            product.id
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        "quantity-change":
                                                                          _vm.handleQuantityChange,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                      product.description
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "register-shop-description",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product.description
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.form.totalPrice
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "row",
                                            staticStyle: {
                                              "margin-top": "16px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-lg-3",
                                              },
                                              [_vm._v(" ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-lg-9",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "register-summary-info",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c("div", [
                                                        _c("h4", [
                                                          _vm._v("Do zapłaty"),
                                                        ]),
                                                      ]),
                                                      _c("h4", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.form.totalPrice
                                                          ) + " zł"
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "summary"
                            ? _c("div", { staticClass: "register-step" }, [
                                _c(
                                  "div",
                                  { staticClass: "register-header" },
                                  [
                                    _c("div", { staticClass: "left" }, [
                                      _c("h3", [_vm._v("Podsumowanie")]),
                                    ]),
                                    _c("Progress", {
                                      attrs: {
                                        items: _vm.allFormSteps,
                                        current: _vm.currentFormStep,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "register-step-section" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-3" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "user-summary" },
                                            [
                                              _c("div", [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(_vm.say.firstname) +
                                                      " " +
                                                      _vm._s(_vm.say.lastname)
                                                  ),
                                                ]),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.form.user.firstname
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.form.user.lastname
                                                      )
                                                  ),
                                                ]),
                                              ]),
                                              _c("div", [
                                                _c("label", [
                                                  _vm._v(_vm._s(_vm.say.email)),
                                                ]),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(_vm.form.user.email)
                                                  ),
                                                ]),
                                              ]),
                                              _c("div", [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(_vm.say.birthtime)
                                                  ),
                                                ]),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      new Date(
                                                        _vm.form.user.birthDate
                                                      ).toLocaleDateString()
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-lg-9" },
                                        [
                                          _vm._l(
                                            _vm.form.items,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.itemData.id,
                                                  staticClass:
                                                    "register-summary-info",
                                                },
                                                [
                                                  item.itemData.itemType ===
                                                  "competition"
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.itemData
                                                                  .name ||
                                                                  "competition"
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        item.price
                                                          ? _c("h4", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.price
                                                                ) + " zł"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    : item.itemData.itemType ===
                                                      "classification"
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.itemData
                                                                  .name ||
                                                                  "classification"
                                                              )
                                                            ),
                                                          ]),
                                                          item.itemData
                                                            .specialCategory
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.say
                                                                      .special_category
                                                                  ) +
                                                                    ": " +
                                                                    _vm._s(
                                                                      item
                                                                        .itemData
                                                                        .specialCategory
                                                                    )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]),
                                                        item.price
                                                          ? _c("h4", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.price
                                                                ) + " zł"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    : item.itemData.itemType ===
                                                      "shopProduct"
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.itemData
                                                                  .name ||
                                                                  "shopProduct"
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c("h4", [
                                                          item.itemData.quantity
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    "font-weight":
                                                                      "400",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .itemData
                                                                        .quantity
                                                                    ) + "x "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.price
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.price
                                                                  ) + " zł"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]),
                                                      ])
                                                    : item.itemData.itemType ===
                                                      "insurance"
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.itemData
                                                                  .name ||
                                                                  "insurance"
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c("h4", [
                                                          item.itemData.quantity
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    "font-weight":
                                                                      "400",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .itemData
                                                                        .quantity
                                                                    ) + "x "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.price
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.price
                                                                  ) + " zł"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]),
                                                      ])
                                                    : _vm._e(),
                                                  Object.keys(
                                                    item.itemData
                                                      .extraAttributes
                                                  ).length > 0
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.itemData
                                                                .extraAttributes
                                                                .modelName
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-summary-info",
                                            },
                                            [
                                              _c("div", [
                                                _c("div", [
                                                  _c("h4", [
                                                    _vm._v("Do zapłaty"),
                                                  ]),
                                                ]),
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.form.totalPrice
                                                    ) + " zł"
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "24px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-summary-info",
                                                },
                                                [
                                                  _c("ValidationProvider", {
                                                    staticClass:
                                                      "scroll-margin",
                                                    attrs: {
                                                      rules: {
                                                        required: {
                                                          allowFalse: false,
                                                        },
                                                      },
                                                      name: "regulations_0",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "base-input-checkbox",
                                                                {
                                                                  attrs: {
                                                                    "checkbox-value": 1,
                                                                    name: "regulations_0",
                                                                    wrap: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .regulations
                                                                        .reg0,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form
                                                                            .regulations,
                                                                          "reg0",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.regulations.reg0",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "asReplaceShortcode"
                                                                        )(
                                                                          _vm
                                                                            .say
                                                                            .i_agree_with_data_processing_dostartu,
                                                                          "organizer",
                                                                          _vm
                                                                            .organizer
                                                                            .name
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              errors[0]
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "validation-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .say[
                                                                            errors[0]
                                                                          ]
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                _vm.regulations,
                                                function (reg, index) {
                                                  return _c(
                                                    "ValidationProvider",
                                                    {
                                                      key: index + 1,
                                                      staticClass:
                                                        "scroll-margin",
                                                      attrs: {
                                                        rules: {
                                                          required: {
                                                            allowFalse: false,
                                                          },
                                                        },
                                                        name: `regulations_${
                                                          index + 1
                                                        }`,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "register-summary-info",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-top":
                                                                          "8px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-input-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          "checkbox-value": 1,
                                                                          value:
                                                                            index +
                                                                            1,
                                                                          name: `regulations_${
                                                                            index +
                                                                            1
                                                                          }`,
                                                                          wrap: "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .regulations[
                                                                              `reg${
                                                                                index +
                                                                                1
                                                                              }`
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form
                                                                                  .regulations,
                                                                                `reg${
                                                                                  index +
                                                                                  1
                                                                                }`,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.regulations[`reg${index+1}`]",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .say
                                                                                .i_agree_with_competition_statute
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: reg,
                                                                                target:
                                                                                  "_blank",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                reg
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    errors[0]
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "validation-text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .say[
                                                                                  errors[0]
                                                                                ]
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.currentFormStep === "status"
                            ? _c(
                                "div",
                                { staticClass: "register-step status" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "register-status" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "register-status-image",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/icons/success.svg"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-status-content",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "text-success" },
                                            [
                                              _vm._v(
                                                "Dziękujemy za zgłoszenie udziału."
                                              ),
                                            ]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              "Przypominamy, że data startu to "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("asTime")(
                                                    _vm.competition.startedTime
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("p", [
                                            _vm._v("Lokalizacja "),
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.competition.location)
                                              ),
                                            ]),
                                          ]),
                                          (_vm.competition.status <= 3 ||
                                            _vm.competition.isRemote) &&
                                          ![
                                            4439, 4094, 4095, 5165, 8515, 8502,
                                            8519, 8520, 8526,
                                          ].includes(_vm.competition.id)
                                            ? _c(
                                                "base-button",
                                                {
                                                  staticClass:
                                                    "minisite__button",
                                                  attrs: { btn2: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$router.push({
                                                        name: "minisite-list",
                                                        params: _vm.competition,
                                                        alias: true,
                                                      })
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.say.start_list
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.currentFormStep !== "status"
                            ? _c(
                                "div",
                                { staticClass: "register-step-section" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-3" },
                                      [_vm._v(" ")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-lg-9" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "register-step-buttons row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "order-2 col-12 col-lg-3",
                                              },
                                              [
                                                _vm.currentFormStep ===
                                                "playerData"
                                                  ? _c(
                                                      "base-button",
                                                      {
                                                        class: {
                                                          tc2bo:
                                                            _vm.layout
                                                              .colourDefault,
                                                        },
                                                        style: _vm.styleObject,
                                                        attrs: { btn4: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$router.back()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.say.cancel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "base-button",
                                                      {
                                                        class: {
                                                          tc2bo:
                                                            _vm.layout
                                                              .colourDefault,
                                                        },
                                                        style: _vm.styleObject,
                                                        attrs: { btn4: "" },
                                                        on: {
                                                          click:
                                                            _vm.prevFormStep,
                                                        },
                                                      },
                                                      [_vm._v(" Cofnij ")]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "order-1 col-12 col-lg-4",
                                              },
                                              [
                                                _c(
                                                  "base-button",
                                                  {
                                                    class: {
                                                      tc1bg:
                                                        _vm.layout
                                                          .colourDefault,
                                                    },
                                                    style: _vm.styleObject,
                                                    attrs: { btn1: "" },
                                                    on: {
                                                      click: _vm.registerSubmit,
                                                    },
                                                  },
                                                  [
                                                    _vm.currentFormStep ===
                                                      "summary" &&
                                                    _vm.form.totalPrice
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.say
                                                                .submit_form
                                                            )
                                                          ),
                                                        ]
                                                      : _vm.currentFormStep ===
                                                        "summary"
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(_vm.say.save)
                                                          ),
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.say
                                                                  .step_next
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              410617458
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }