<template>
    <div
        class="sticky"
        :class="{
            'sticky--bottom': showInstallMessageOnIOS || promptEvent,
        }"
    >
        <div
            v-if="isOrganizerPanel && isNotification"
            class="prompt"
            :class="{ 'prompt--ios': showInstallMessageOnIOS }"
        >
            <div
                class="prompt__cancel-ios"
                @click.stop="dismiss"
            >
                <img
                    src="@/assets/images/cancel-icon.svg"
                    alt=""
                >
            </div>
            <div class="row w100">
                <div class="col-12">
                    <div
                        v-if="promptEvent"
                        class="d-flex"
                        @click="showPrompt"
                    >
                        <img
                            class="prompt__icon"
                            src="@/assets/images/AppIconWithBackground.png"
                            alt="Logo"
                        >
                        <p
                            class="prompt__description"
                            @click.prevent
                        >
                            Dodaj aplikację DO:ST:AR:TU do ekranu głównego
                        </p>
                        <i
                            class="fas fa-times prompt__close"
                            @click.stop="dismiss"
                        />
                    </div>
                    <div
                        v-else-if="showInstallMessageOnIOS"
                        class="prompt__ios-message"
                    >
                        <img
                            class="prompt__ios-logo"
                            src="@/assets/images/dostartu-icon.png"
                        >
                        <div>
                            <p class="prompt__ios-title">
                                Zainstaluj dostartu jako aplikację
                            </p>
                            <div class="d-flex">
                                <p>1. Tapnij ikonę</p>
                                <img src="@/assets/images/ios-share-icon.svg">
                            </div>

                            <div class="d-flex">
                                <p>2. Dodaj do ekranu głównego</p>
                                <img
                                    src="@/assets/images/ios-icon-add-app.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                    <div v-else-if="isInstalledInfo">
                        Pomyślnie zainstalowano aplikacje.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { CLEAR_PROMPT_EVENT, SET_IS_ONLINE } from '@/store/mutations.type';
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            isRefreshing: false,
            registration: null,
            showInstallMessageOnIOS: false,
            isInstalledInfo: false,
        };
    },
    computed: {
        ...mapGetters(['promptEvent']),
        isNotification() {
            return (
                (!this.promptCookies
                    && (this.promptEvent || this.showInstallMessageOnIOS))
                || this.isInstalledInfo
            );
        },
        promptCookies() {
            return Cookies.get('prompt-pwa');
        },
        isOrganizerPanel() {
            const rootRoute = this.$route.matched.find(
                (route) => route.meta.layout,
            );
            if (rootRoute.meta.layout === 'front') {
                return false;
            }
            return true;
        },
    },
    created() {
        // recognize ios devices.
        if (this.isSafari() && !this.isInStandaloneMode()) {
            this.showInstallMessageOnIOS = true;
        }
        document.addEventListener('serviceWorkerUpdated', (e) => this.refreshServiceWorker(e));
        window.addEventListener('appinstalled', () => {
            this.CLEAR_PROMPT_EVENT();
            this.showInfoAfterInstalled();
        });
        // Init network status.
        this.updateOnlineStatus();
        // Listener for changes network status.
        window.addEventListener('online', () => this.updateOnlineStatus());
        window.addEventListener('offline', () => this.updateOnlineStatus());
        if ('serviceWorker' in navigator) {
            // Listener for adding new service worker.
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.isRefreshing) return;
                this.isRefreshing = true;
                window.location.reload();
            });
        }
    },
    methods: {
        ...mapMutations([SET_IS_ONLINE, CLEAR_PROMPT_EVENT]),
        dismiss() {
            this.showInstallMessageOnIOS = null;
            this.CLEAR_PROMPT_EVENT();
        },
        updateOnlineStatus() {
            this.SET_IS_ONLINE(navigator.onLine);
        },
        refreshServiceWorker(event) {
            this.registration = event.detail;
            if (!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
        showPrompt() {
            this.promptEvent.prompt();
        },
        showInfoAfterInstalled() {
            this.isInstalledInfo = true;
            setTimeout(() => {
                this.isInstalledInfo = false;
            }, 4000);
        },
        isInStandaloneMode() {
            return (
                'standalone' in window.navigator && window.navigator.standalone
            );
        },
        isSafari() {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        },
    },
};
</script>
<style scoped>
.prompt {
    background: var(--color-white);
    color: var(--color-greyish-brown);
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
}
.prompt-btn {
    margin-top: 15px;
    max-height: 20px;
    font-size: 14px;
    text-transform: none;
    padding: 10px;
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 99999;
}
.sticky--bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: unset;
    text-align: center;
}
.prompt__icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.prompt__close {
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.prompt__description {
    text-align: left;
    user-select: none;
}
.prompt__ios-message {
    line-height: 1.8;
    display: flex;
    font-size: 14px;
    text-align: left;
}
.prompt--ios {
    margin: 8px;
    border-radius: 4px;
    position: relative;
}
.prompt--ios:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -3px;
    transform: rotate(45deg);
    background-color: var(--color-white);
}
.prompt__ios-logo {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}
.prompt__ios-title {
    font-weight: bold;
}
.prompt__cancel-ios {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    display: flex;
}
</style>
