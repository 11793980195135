var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.$route.name === "panel-competition-results-live" ||
      this.$route.name === "panel-competition-results-live-all"
        ? _c("div")
        : _c("div", { staticClass: "container" }, [
            !_vm.ready
              ? _c("div", { staticClass: "row mat32" }, [_vm._m(0)])
              : _vm._e(),
            _vm.ready
              ? _c(
                  "div",
                  {
                    staticClass: "row mat16",
                    class: {
                      "d-none": _vm.isZenMode,
                      "none-mobile":
                        _vm.$route.name === "panel-competition-stoper",
                    },
                  },
                  [_c("h2", [_vm._v(_vm._s(_vm.competition.name))])]
                )
              : _vm._e(),
            _vm.ready
              ? _c(
                  "div",
                  {
                    staticClass: "row buttons-row",
                    class: {
                      "d-none": _vm.isZenMode,
                      "none-mobile":
                        _vm.$route.name === "panel-competition-stoper",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-8 minisite-link none-mobile" },
                      [_c("span", [_vm._v(" " + _vm._s(_vm.link) + " ")])]
                    ),
                    _c("div", { staticClass: "col-3 none-mobile" }, [
                      _vm.competition.status < 4
                        ? _c("p", [_vm._v(" Rejestracja online ")])
                        : _c("p", [_vm._v(" Wyniki online ")]),
                    ]),
                    _c("panel-competition-topbar", {
                      staticClass: "col-lg-12 mat8 none-mobile",
                    }),
                    _c("panel-competition-update-results", {
                      attrs: { competition: _vm.competition },
                    }),
                    _c("div", { staticClass: "d-flex w100 none-desktop" }, [
                      _c(
                        "div",
                        {
                          staticClass: "button-page",
                          on: {
                            click: function ($event) {
                              return _vm.TOGGLE_NAV()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button-page__icon",
                            attrs: { src: _vm.currentPage.icon, alt: "Icon" },
                          }),
                          _c("p", { staticClass: "button-page__title" }, [
                            _vm._v(" " + _vm._s(_vm.currentPage.text) + " "),
                          ]),
                          _c("img", {
                            staticClass: "button-page__arrow",
                            attrs: {
                              src: require("@/assets/images/arrow-down-icon.svg"),
                              alt: "Arrow down",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
      _vm.competition
        ? _c("router-view", { attrs: { competition: _vm.competition } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "admin-header__loading-state" }, [
        _vm._v(" Trwa wczytywanie "),
        _c("div", { staticClass: "mal15 lds-ellipsis" }, [
          _c("div"),
          _c("div"),
          _c("div"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }