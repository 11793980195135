<template>
  <VTooltip 
    :triggers="['click', 'hover']"
    :distance="0"
    :skidding="-8"
    placement="top-end"
    class="ds-panel"
  >
    <slot />
    <template #popper>
      <span v-if="data.html" v-html="data.content" />
      <span v-else>{{ data.content }}</span>
    </template>
  </VTooltip>
</template>

<script>
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu
} from 'floating-vue'

export default {
  directives: {
    'tooltip': VTooltip,
    'close-popper': VClosePopper
  },
  components: {
    'VDropdown': Dropdown,
    'VTooltip': Tooltip,
    'VMenu': Menu
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
}
</script>

<style src='floating-vue/dist/style.css'></style>

<style>
.v-popper--theme-tooltip .v-popper__inner {
  padding: 6px 12px;
  background: #263238;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: #263238;
}
</style>