var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label && _vm.label.length
        ? _c("p", { staticClass: "mab8" }, [
            _vm._v(" " + _vm._s(_vm.label)),
            _vm.optional
              ? _c("span", { staticClass: "positive" }, [
                  _vm._v("(opcjonalnie)"),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("base-input-text", {
        staticClass: "box-shadow",
        attrs: {
          value: _vm.value,
          hint: _vm.hint,
          "is-short": "",
          "without-error-message": "",
          error: _vm.error,
          type: _vm.type,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          "update:error": function ($event) {
            return _vm.$emit("update:error", $event)
          },
        },
      }),
      _vm.error && _vm.error === "field_occupied"
        ? _c("div", { staticClass: "panel-tooltip-error" }, [
            _c("span", { staticClass: "panel-tooltip-text" }, [
              _vm._v("Numer zajęty"),
            ]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/error-exclamation-mark.svg"),
              },
            }),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "panel-input-error input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }