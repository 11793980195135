import { IS_PRODUCTION_ENV } from '@/config';
import { deleteCookie, setCookie } from '@/utils/cookies';
import Vue from 'vue';

if (!IS_PRODUCTION_ENV) {
    setCookie('XDEBUG_SESSION', 'xdostartu', '.dostartu.pl');
} else {
    deleteCookie('XDEBUG_SESSION', '.dostartu.pl');
}

Vue.config.productionTip = false;
