<template>
    <div v-if="ready">
        <stoper
            v-if="device.type === deviceTypes.stoper"
            is-panel-gate
        />
        <input
            v-else
            type="file"
            @change="file = $event.target.files[0]"
        >
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
    READ_GATE,
    IMPORT_MEASURES,
    READ_DEVICE,
    BOOTSTRAP_STOPER,
    SELECT_STOPER_GATE,
} from '@/store/actions.type';
import { readTextFile } from '@/utils/files';
import Stoper from '@/components/Stoper';
import { DEVICE_TYPES } from '../config';

export default {
    components: {
        Stoper,
    },
    props: {
        secureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            id: null,
            ready: false,
            importer: null,
            file: null,
            interval: 32 * 1000,
            deviceTypes: DEVICE_TYPES,
        };
    },
    computed: {
        ...mapGetters(['gates', 'devices']),
        gate() {
            return this.gates[this.id];
        },
        device() {
            return this.devices[this.gate.device];
        },
    },
    watch: {
        file() {
            this.importMeasures();
        },
    },
    async created() {
        try {
            this.id = await this.READ_GATE(this.secureId);

            await this.READ_DEVICE(this.gate.device);

            if (this.device.type === DEVICE_TYPES.stoper) {
                await this.BOOTSTRAP_STOPER({ id: this.gate.competition, isSettingPage: false });
                await this.SELECT_STOPER_GATE(this.gate);
            }

            this.importer = setInterval(this.importMeasures, this.interval);
            this.ready = true;
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }
    },
    destroyed() {
        clearInterval(this.importer);
    },
    methods: {
        ...mapActions([
            READ_GATE,
            IMPORT_MEASURES,
            READ_DEVICE,
            BOOTSTRAP_STOPER,
            SELECT_STOPER_GATE,
        ]),
        async importMeasures() {
            if (this.file) {
                const file = await readTextFile(this.file);

                await this.IMPORT_MEASURES({ gate: this.gate, file });
            }
        },
    },
};
</script>
