var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mat16" }, [
    _c(
      "div",
      {
        staticClass: "col-12",
        class: {
          input__left: _vm.half,
          "col-md-3": _vm.half,
          "col-md-6": !_vm.half,
        },
      },
      [_vm._t("default")],
      2
    ),
    _vm.half
      ? _c(
          "div",
          {
            staticClass: "col-12",
            class: {
              input__right: _vm.half,
              "col-md-3": _vm.half,
              "col-md-6": !_vm.half,
            },
          },
          [_vm._t("right")],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-12 col-md-6 input__hint" },
      [_vm._t("hint")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }