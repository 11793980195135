var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cbx--photo--container" },
    [
      _c(
        "base-photo-sport",
        {
          attrs: {
            type: _vm.competition.type,
            colour: _vm.competitionLayout.colourDefault,
          },
        },
        [
          _c("div", { staticClass: "cbx--photo" }, [
            _c(
              "h4",
              { staticClass: "mab16" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "minisite-landing",
                        params: _vm.competition,
                        alias: true,
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm._f("asType")(_vm.competition.type)) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }