<template>
    <div>
        <!-- Empty state. -->
        <div v-if="empty">
            <div class="info-box--grey pa16 matb15">
                <h3>{{ say.you_have_no_future_competitions }}</h3>
                <base-button
                    class="btn-proposed"
                    btn4
                    @click="$router.push({name:'user-competitions-proposed', alias: true})"
                >
                    {{ say.see_proposed_competitions }}
                </base-button>
            </div>
        </div>

        <!-- Competitions list. -->
        <base-item-competition
            v-for="id in competitionsIds"
            :id="id"
            :key="id"
        />

        <!-- Search more button. -->
        <base-button
            v-if="!empty"
            class="btn-findmore"
            btn4
            @click="$router.push({name:'main-list', alias: true})"
        >
            {{ say.register_to_more_competitions }}
        </base-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { READ_USER_COMPETITIONS } from '@/store/actions.type';

export default {
    data() {
        return {
            competitionsIds: [-1],
        };
    },
    computed: {
        ...mapGetters(['say']),
        empty() {
            return this.competitionsIds.length === 0;
        },
    },
    async created() {
        const { competitions } = await this.READ_USER_COMPETITIONS({
            type: 'future',
        });

        this.competitionsIds = competitions;
    },
    methods: {
        ...mapActions([READ_USER_COMPETITIONS]),
    },
};
</script>

<style scoped>
.btn-proposed {
    margin-bottom: 16px;
    margin-top: 60px;
    max-width: 263px;
    background-color: var(--color-white);
}
.btn-findmore {
    margin: 30px 0;
    box-sizing: border-box;
}

@media (min-width: 992px) {
    .btn-findmore {
        width: 263px;
    }
}
</style>
