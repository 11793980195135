import classificationSetting from '@/api/schema/classification-setting';
import competition from '@/api/schema/competition';
import price from '@/api/schema/price';
import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('classifications', {
    competition,
    classificationSetting,
    classificationPrices: [price],
}, {
    processStrategy: (classification) => ({
        // Meta.
        _type: 'classification',
        // Fields.
        ...classification,
        // Cast types.
        startTime: classification.startTime ? moment(classification.startTime) : null,
    }),
});
