import { schema } from 'normalizr';

export default new schema.Entity('competitionsCurrency', {}, {
    idAttribute: 'competition',
    processStrategy: (competitionCurrency) => ({
        // Meta.
        _type: 'competitionCurrency',
        // Fields.
        ...competitionCurrency,
    }),
});
