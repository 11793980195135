import { any, many, take } from '@/utils/array';

export default (haystack, target, swap = false, cfk = undefined, isMany = false) => {
    const sources = Object.values(haystack);

    const owning = (a, b) => (swap ? b : a);
    const inverse = (a, b) => (swap ? a : b);

    const pk = 'id';
    let fk = cfk ?? take(many(inverse(sources, target)), '_type')[0];

    if (isMany) {
        fk += 's';
    }

    const criteria = (source) => {
        const fks = take(many(owning(source, target)), fk);
        const pks = take(many(inverse(source, target)), pk);

        return any(fks.flat(), pks.flat());
    };

    return fk ? sources.filter(criteria) : [];
};
