<template>
    <div class="container">
        <div class="row organizer-header">
            <div class="col-12 col-lg-4">
                <base-input-text
                    v-model="search"
                    icon="fas fa-search"
                    label="Szukaj..."
                    class="organizer-header__search"
                />
            </div>
            <div class="col-12 col-lg-5 organizer-header__link">
                <base-link-input
                    class="organizer-header__link-box"
                    :link="organizerLink"
                    button-name="Kopiuj link"
                    hint="To jest publiczny adres strony z listą Twoich zawodów"
                />
            </div>
            <div class="col-12 col-lg-3">
                <base-button
                    class="organizer-header__add-competition"
                    btn1
                    @click="$emit('add-competition')"
                >
                    Zorganizuj nowe zawody
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        organizerId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            search: '',
        };
    },
    computed: {
        ...mapGetters(['organizers']),
        organizerLink() {
            if (this.organizers[this.organizerId]) {
                return `dostartu.pl/${
                    this.organizers[this.organizerId].name
                }-or${this.organizerId}`;
            }
            return 'Wczytywanie...';
        },
    },
    watch: {
        search() {
            this.$emit('search-competitions', this.search);
        },
    },
};
</script>

<style scoped>
.organizer-header__link {
    margin-top: 16px;
    max-width: var(--col-12);
}
.organizer-header {
    margin-top: 24px;
}
.organizer-header__search {
    min-height: 40px;
    max-height: 40px;
}
.organizer-header__add-competition {
    display: none;
}
@media (min-width: 992px) {
    .organizer-header__link-box {
        margin: 0 15px;
    }
    .organizer-header {
        margin-top: 48px;
    }
    .organizer-header__search {
        margin-right: 15px;
    }
    .organizer-header__link {
        max-width: var(--col-5);
        margin-top: 0;
    }
    .organizer-header__add-competition {
        margin-left: 15px;
        min-height: 40px;
        max-height: 40px;
        box-sizing: border-box;
        display: flex;
    }
}
</style>
