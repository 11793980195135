import age from './age';
import category from './category';
import checkpoint from './checkpoint';
import classification from './classification';
import classificationSetting from './classification-setting';
import competition from './competition';
import competitionPrice from './competition-price';
import competitionSetting from './competition-setting';
import cycle from './cycle';
import device from './device';
import familyAssociation from './family-association';
import gate from './gate';
import gpxFile from './gpx-file';
import layout from './layout';
import limit from './limit';
import location from './location';
import measure from './measure';
import operator from './operator';
import organizer from './organizer';
import player from './player';
import panelPlayer from './panel-player';
import price from './price';
import result from './result';
import trackpoint from './trackpoint';
import user from './user';
import competitionCurrency from './competition-currency';
import competitionPlayerCount from './competition-player-count';
import paymentCount from './payment-count';
import categoryCount from './category-count';

export default {
    age,
    category,
    checkpoint,
    classification,
    classificationSetting,
    competition,
    competitionPrice,
    competitionSetting,
    cycle,
    device,
    familyAssociation,
    gate,
    gpxFile,
    layout,
    limit,
    location,
    measure,
    operator,
    organizer,
    player,
    panelPlayer,
    price,
    result,
    trackpoint,
    user,
    competitionCurrency,
    competitionPlayerCount,
    paymentCount,
    categoryCount,
};
