var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-marker",
    {
      attrs: { draggable: !_vm.readOnly, "lat-lng": [_vm.lat, _vm.lng] },
      on: {
        ready: function ($event) {
          return _vm.hookEvents($event)
        },
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "l-icon",
        {
          attrs: {
            "class-name": Object.entries(_vm.iconClasses)
              .filter(([cls, x]) => x)
              .map(([cls]) => cls)
              .join(" "),
            "icon-anchor": [20, 41],
          },
        },
        [_vm._t("icon"), _c("div", { class: _vm.pointerClasses })],
        2
      ),
      !_vm.readOnly
        ? _c(
            "l-popup",
            {
              staticClass: "marker__popup",
              attrs: {
                options: { closeButton: false, offset: _vm.pointAnchorPopup },
              },
            },
            [_vm._t("popup")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }