var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "radio-field",
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    },
    [
      _c("span", { staticClass: "radio-field__desc" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._l(_vm.options, function (option) {
        return [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.picked,
                expression: "picked",
              },
            ],
            key: option.value,
            staticClass: "d-none",
            attrs: { id: option.value, type: "radio" },
            domProps: {
              value: option.value,
              checked: _vm._q(_vm.picked, option.value),
            },
            on: {
              change: function ($event) {
                _vm.picked = option.value
              },
            },
          }),
          _c(
            "label",
            {
              key: option.title,
              staticClass: "radio-field__label",
              attrs: { for: option.value },
            },
            [_vm._v(_vm._s(_vm.say[option.title] || option.title))]
          ),
        ]
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }