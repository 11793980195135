<template>
    <div class="row input-time--margin-right">
        <div class="col-3">
            <div class="input-time__field">
                <base-input-text
                    :value="value.hours"
                    hint="00"
                    :label="say.hours"
                    :error="error"
                    without-error-message
                    @input="$emit('input', { ...value, hours: $event })"
                    @update:error="$emit('update:error', $event)"
                />
            </div>
        </div>

        <div class="col-3">
            <div class="input-time__field">
                <base-input-text
                    :value="value.minutes"
                    hint="00"
                    :label="say.minutes"
                    :error="error"
                    without-error-message
                    @input="$emit('input', { ...value, minutes: $event })"
                    @update:error="$emit('update:error', $event)"
                />
            </div>
        </div>

        <div class="col-3">
            <div class="input-time__field">
                <base-input-text
                    :value="value.seconds"
                    hint="00"
                    :label="say.seconds"
                    :error="error"
                    without-error-message
                    @input="$emit('input', { ...value, seconds: $event })"
                    @update:error="$emit('update:error', $event)"
                />
            </div>
        </div>

        <div class="col-3">
            <div class="input-time__field">
                <base-input-text
                    :value="value.milliseconds"
                    hint="00"
                    :label="say.centyseconds"
                    :error="error"
                    without-error-message
                    @input="$emit('input', { ...value, milliseconds: $event })"
                    @update:error="$emit('update:error', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        error: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
