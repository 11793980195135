var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _vm._t("default"),
      _c("div", { staticClass: "arrow" }),
      _c(
        "base-button-group",
        { staticClass: "menu-button-group" },
        [_vm._t("menu")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }