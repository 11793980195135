<template>
    <footer
        v-if="
            $route.name !== 'panel-competition-stoper' &&
                $router.name !== 'panel-competition-map' &&
                !isZenMode
        "
        class="footer"
    >
        <div class="container">
            <div class="row">
                <p>© DO:ST:AR:TU {{ time.format("YYYY") }}</p>
                <router-link
                    tag="p"
                    class="pointer"
                    :to="{ name: 'main-landing', alias: true }"
                >
                    regulamin platformy dostartu
                </router-link>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['time', 'isZenMode']),
    },
};
</script>

<style scoped>
.footer {
    padding: 14px 0;
    line-height: 1.43;
    color: var(--color-silver);
    border-top: 1px solid var(--color-bluish-grey);
}
</style>
