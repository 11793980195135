export default {
    props: {
        value: {
            type: [String, Object, Array, Date, Number],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        icon: {
            // Icon on the left side.
            type: String,
            default: null,
        },
        badge: {
            // Icon on the right side.
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        withoutErrorMessage: {
            type: Boolean,
            default: false,
        },
        isShort: {
            type: Boolean,
            default: false,
        },
        isMultiple: {
            type: Boolean,
            default: true,
        },
        accept: {
            type: String,
            default: '*',
        },
    },
    computed: {
        isInput() {
            return Boolean(this.hint || this.value);
        },
        placeholder() {
            return this.hint ? this.hint : this.label;
        },
        isInputValid() {
            return this.isInput && !this.error;
        },
    },
    watch: {
        value() {
            this.$emit('update:error', null);
        },
    },
};
