import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  apiKey: "AIzaSyAx9uOU6awdibE6-HQ28sVTgCcdoOSUWI0",
  authDomain: "dostartu-push-test.firebaseapp.com",
  projectId: "dostartu-push-test",
  storageBucket: "dostartu-push-test.appspot.com",
  messagingSenderId: "388058786669",
  appId: "1:388058786669:web:1ba58abb39c8a671010864",
  measurementId: "G-3HCPKVCFCQ"
}

firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export default messaging