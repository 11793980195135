<template>
  <div class="update-results" v-if="showUpdate">
    <div class="update-results-content">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.1429 20.5625C17.1429 20.7232 17.0179 20.8571 16.8571 20.8571H15.1429C14.9821 20.8571 14.8571 20.7232 14.8571 20.5625V18.8661C14.8571 18.7054 14.9821 18.5714 15.1429 18.5714H16.8571C17.0179 18.5714 17.1429 18.7054 17.1429 18.8661V20.5625ZM17.125 17.2232C17.1161 17.3393 16.9821 17.4286 16.8214 17.4286H15.1696C15 17.4286 14.8661 17.3393 14.8661 17.2232L14.7143 13.1429C14.7143 13.0893 14.7411 13 14.8036 12.9554C14.8571 12.9107 14.9375 12.8571 15.0179 12.8571H16.9821C17.0625 12.8571 17.1429 12.9107 17.1964 12.9554C17.2589 13 17.2857 13.0714 17.2857 13.125L17.125 17.2232ZM17 8.88393C16.8036 8.51786 16.4196 8.28571 16 8.28571C15.5804 8.28571 15.1964 8.51786 15 8.88393L8.14286 21.4554C7.94643 21.8036 7.95536 22.2321 8.16071 22.5804C8.36607 22.9286 8.74107 23.1429 9.14286 23.1429H22.8571C23.2589 23.1429 23.6339 22.9286 23.8393 22.5804C24.0446 22.2321 24.0536 21.8036 23.8571 21.4554L17 8.88393Z" fill="#FED25A"/>
      </svg>
      <b>Wyniki na stronie dla uczestników nie zostały zaktualizowane</b>
    </div>
    <base-button
      btn9
      @click="updateResults()"
    >
      Uaktualnij wyniki
    </base-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { READ_RESULTS_EDIT_STATUS } from '@/store/actions.type';
import { SET_RESULTS_EDIT_STATUS } from '@/store/mutations.type';

import api from '@/api';

export default {
  name: 'PanelCompetitionUpdateResults',
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isResultsEdit']),
    showUpdate() {
      if(this.competition.status > 4 && this.isResultsEdit) {
        return true
      } 
      return false      
    },
  },
  mounted() {
    this.READ_RESULTS_EDIT_STATUS(this.competition.id)
  },
  methods: {
    ...mapActions([READ_RESULTS_EDIT_STATUS]),
    ...mapMutations([SET_RESULTS_EDIT_STATUS]),
    async updateResults() {
      this.SET_RESULTS_EDIT_STATUS(false)
      await api.post(`panel/competitions/${this.competition.id}/results/update`);
    }
  },
}
</script>

<style>
.update-results {
  width: 100%;
  background: #FFF1CC;
  border-radius: 4px;
  padding: 24px;
  color: #455A64;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.update-results-content {
  display: flex;
  align-items: center;
}
.update-results-content svg {
  margin-right: 24px;
}

@media (max-width: 991px) {
  .update-results {
    margin-top: 16px;
  }
  .update-results .btn {
    margin-top: 16px;
    width: 100%;
  }
  .update-results-content {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .update-results {
    margin-top: 32px;
  }
  .update-results .btn {
    min-width: 270px;
  }
}
</style>