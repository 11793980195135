var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [_c("b", [_vm._t("label")], 2)]),
    _c("div", { staticClass: "user-widget col-12" }, [_vm._t("content")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }