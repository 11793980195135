var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-3" }),
      _c("div", { staticClass: "col-12 col-lg-9" }, [
        _c("div", { staticClass: "row mat80 home__organize-button" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h1", [_vm._v(_vm._s(_vm.say.organize_competitions_with_us))]),
          ]),
          _c("div", { staticClass: "col-12 col-lg-4 mat16" }, [
            _c(
              "a",
              { attrs: { href: _vm.organizerUrl } },
              [
                _c("base-button", { attrs: { btn2: "" } }, [
                  _vm._v(" " + _vm._s(_vm.say.go_to_organizer_zone) + " "),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row mat80 home__organize-button" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "podcast-banner" }, [
              _vm._m(0),
              _c(
                "a",
                { attrs: { href: _vm.podcastUrl } },
                [
                  _c("base-button", { attrs: { btn2: "" } }, [
                    _vm._v(" " + _vm._s(_vm.say.go_to_podcast) + " "),
                  ]),
                ],
                1
              ),
              _c("img", {
                staticClass: "podcast-banner-bg",
                attrs: {
                  src: require("@/assets/images/podcast-banner-bg.svg"),
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "podcast-banner-logos" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/dostartu-logo-white.png") },
      }),
      _c("img", {
        attrs: { src: require("@/assets/images/dostartu-podcast-logo.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }