<template>
    <div :class="classes">
        <div class="notification__center">
            <h4 class="notification__header">
                <slot name="header" />
            </h4>
            <p class="notification__content">
                <slot name="content" />
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        positive: {
            type: Boolean,
            default: false,
        },
        negative: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                notification: true,
                'notification--positive': this.positive,
                'notification--negative': this.negative,
            };
        },
    },
};
</script>

<style scoped>
.notification {
    border-radius: 4px;
    margin-top: 24px;
    padding: 16px;
}

.notification--negative {
    background-color: var(--color-pinky-red-10);
}

.notification--positive {
    background-color: var(--color-light-sage-40);
}

.notification__center {
    max-width: 700px;
    margin: 0 auto;
}

.notification__content {
    margin-top: 8px;
}
</style>
