<template>
    <div class="container">
        <div class="row reset-password">
            <div class="col-12 col-lg-3">
                <h1>{{ say.reset_your_password }}</h1>
            </div>
            <div class="col-12 col-lg-9">
                <div class="row bg--gray reset-password__confirmation">
                    <div class="col-12">
                        <h2 class="confirmation__title">
                            {{ say.check_email }}
                        </h2>
                    </div>
                    <div class="col-12 confirmation__message">
                        <p>{{ say.password_reset_msg }}</p>
                    </div>
                    <div class="col-12 col-lg-3">
                        <base-button
                            btn4
                            class="confirmation__return-button"
                            @click="$router.replace({name: 'authentication-login', alias: true})"
                        >
                            {{ say.return_to_login }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
