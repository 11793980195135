<template>
    <div
        v-if="organizer"
        class="operator-header__organizer"
    >
        <div class="container">
            <div class="row operator-header__organizer-box">
                <div class="patb8">
                    {{ organizer.name }}
                </div>
                <div class="d-flex">
                    <div
                        class="operator-header__organizer-btn"
                        :class="{
                            'operator-header__organizer-btn--active':
                                this.$route.name === 'panel-organizer',
                        }"
                        @click="
                            $router.push({
                                name: 'panel-organizer',
                                params: {
                                    organizerId: organizer.id,
                                },
                                alias: true,
                            })
                        "
                    >
                        Lista zawodów organizatora
                    </div>
                    <div
                        class="operator-header__organizer-btn"
						:class="{
                            'operator-header__organizer-btn--active':
                                this.$route.name.includes('panel-setting-'),
                        }"
                        @click="
                            $router.push({
                                name: 'panel-setting-new',
                                params: {
                                    name: organizer.name,
                                    id: organizer.id,
                                },
                                alias: true,
                            })
                        "
                    >
                        Ustawienia organizatora
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { READ_ORGANIZER } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        organizerId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['organizers']),
        organizer() {
            return this.organizers[this.organizerId];
        },
    },
    async created() {
        await this.READ_ORGANIZER(this.organizerId);
    },
    methods: {
        ...mapActions([READ_ORGANIZER]),
    },
};
</script>

<style>
.operator-header__organizer {
    background-color: var(--color-slate);
    margin-top: 2px;
}

.operator-header__organizer-btn {
    border-left: 1px solid var(--color-charcoal-grey);
    padding: 8px 16px;
    cursor: pointer;
    display: none;
}
.operator-header__organizer-btn--active {
    background-color: var(--color-charcoal-grey);
}
@media (min-width: 992px) {
    .operator-header__organizer-btn {
        border-left: 1px solid var(--color-charcoal-grey);
        padding: 8px 16px;
        cursor: pointer;
        display: block;
    }
    .operator-header__organizer-box {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        border-right: 1px solid var(--color-charcoal-grey);
    }
}
</style>
