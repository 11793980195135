var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", { staticClass: "switch" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.innerValue,
            expression: "innerValue",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: _vm.innerValue,
          checked: Array.isArray(_vm.innerValue)
            ? _vm._i(_vm.innerValue, null) > -1
            : _vm.innerValue,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.innerValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.innerValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.innerValue = $$c
            }
          },
        },
      }),
      _c("span", { staticClass: "slider" }),
    ]),
    _c(
      "span",
      { staticClass: "input-error", class: { "input-error--show": _vm.error } },
      [_vm._v(_vm._s(_vm.say[_vm.error]))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }