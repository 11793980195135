<template>
    <div class="columnWrap">
        <div
            class="d-flex align-items-center"
            :class="{ inactive: !familyAssociation.enabled }"
        >
            <div class="family-child__img">
                <!-- <div
                class="profile-img medium mar25i"
                style="text-align: center; padding-top: 10px; font-size: 30px;"
                >-->
                <strong>{{ parent.firstname.charAt(0) }}{{ parent.lastname.charAt(0) }}</strong>
            </div>
            <p class="ma0i">
                <big class="mar15">
                    {{ parent.firstname }} {{ parent.lastname }}
                </big>
                {{ parent.city }}
            </p>
        </div>

        <div class="btnWrap" v-if="familyAssociation.pending">
            <base-button btn1 @click="ENABLE_FAMILY(familyAssociation)" style="width: 100%;">{{ say.accept_invitation }}</base-button>
            <base-button btn7 @click="DISABLE_FAMILY(familyAssociation)" style="box-shadow: none;padding-left: 21px;padding-right: 21px;">{{ say.reject_invitation }}</base-button>
        </div>

        <div v-else-if="familyAssociation.enabled" class="btnWrap">
            <base-button btn7 @click="DISABLE_FAMILY(familyAssociation)" style="box-shadow: none;padding-left: 21px;padding-right: 21px;">{{ say.disable_registration_by_parent }}</base-button>
        </div>
        <div v-else class="btnWrap">
            <base-button btn1 @click="ENABLE_FAMILY(familyAssociation)" style="width: 100%;">{{ say.accept_invitation }}</base-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DISABLE_FAMILY, ENABLE_FAMILY } from '@/store/actions.type';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['say', 'familyAssociations', 'users']),
        familyAssociation() {
            return this.familyAssociations[this.id];
        },
        parent() {
            return this.users[this.familyAssociation.parentUser];
        },
    },
    methods: {
        ...mapActions([ENABLE_FAMILY, DISABLE_FAMILY]),
    },
};
</script>

<style scoped>
.family-child__img {
    width: 64px;
    height: 64px;
    background-position: center center;
    background-size: cover;
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #546E7A;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.family-child__img strong { 
    font-size: 30px;
}
.justify-content-between{-ms-flex-pack:justify!important;justify-content:space-between!important}
.btnWrap {display: flex;min-width: 263px;}
.btnWrap .btn + .btn {margin-left: 8px;}
.columnWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
@media (max-width: 991px) {
.columnWrap {
    flex-direction: column;
    align-items: baseline;
}
.columnWrap .btnWrap {margin-top: 16px;width: 100%;}
}
</style>