<template>
    <div
        ref="trackVisibility"
        class="col-12 col-md-3 mat33 pat15"
    >
        <!-- Topbar. -->
        <div
            v-if="!visibility"
            class="mtopbar patb15"
        >
            <div class="container">
                <div class="row flex-nowrap">
                    <div class="col-6 col-md-3">
                        <minisite-button class="mtopbar__button" />
                    </div>

                    <div class="col-6 col-md-5">
                        <h4>
                            {{ competition.name }}
                        </h4>
                    </div>

                    <div class="d-none d-md-block col-md-4">
                        <p v-if="competition.location">
                            {{ competition.location }}
                            <br>
                            {{ competition.startedTime | asTime }}
                        </p>
                        <p v-else>
                            {{ say.whole_world }}
                            <br>
                            {{ competition.startedTime | asTime }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sidebar-wrapper">
            <!-- Sidebar. -->
            <minisite-button />

            <div
                :class="[
                    competition.status === 2 || competition.status >= 4
                        ? 'bsh mat32'
                        : 'bsh',
                ]"
            >
                <!-- Start list. -->
                <!-- @TODO remove checking competition.id -->
                <base-button
                    v-if="
                        (competition.status <= 3 || competition.isRemote) &&
                            ![4439, 4094, 4095, 5165, 8515, 8502, 8519, 8520, 8526].includes(competition.id)
                    "
                    btn6
                    class="minisite__button"
                    @click="
                        $router.push({
                            name: 'minisite-list',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    {{ say.start_list }}
                </base-button>

                <base-button
                    v-if="canDeclareResults(user, competition)"
                    btn6
                    class="minisite__button"
                    @click="
                        competition.status < 3
                            ? $router.push({
                                name: 'user-competitions-future',
                                alias: true,
                            })
                            : $router.push({
                                name: 'user-competitions-past',
                                alias: true,
                            })
                    "
                >
                    {{ say.add_your_result }}
                </base-button>
                <base-button
                    v-if="competition.status === 4 || competition.isRemote"
                    btn6
                    class="minisite__button"
                    @click="
                        $router.push({
                            name: 'minisite-results-pick-classification',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    {{ say.results }}
                </base-button>

                <base-button
                    v-if="
                        competition.statuteLinkPl || competition.statuteFilePl
                    "
                    btn6
                    class="minisite__button"
                    @click="
                        $router.push({
                            path:
                                competition.statuteLinkPl ||
                                competition.statuteFilePl,
                            tab: true,
                        })
                    "
                >
                    {{ say.regulations }}
                </base-button>

                <base-button
                    btn6
                    class="minisite__button"
                    @click="
                        $router.push({
                            name: 'minisite-contact',
                            params: competition,
                            alias: true,
                        })
                    "
                >
                    {{ say.contact_with_organizer }}
                </base-button>
            </div>

            <img
                v-if="layout.logoLink"
                class="mat48 w100"
                :src="layout.logoLink"
            >
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import visibilityTracking from '@/mixins/visibility-tracking';
import actions from '@/mixins/actions';
import MinisiteButton from '@/components/MinisiteButton';

export default {
    components: {
        MinisiteButton,
    },
    mixins: [visibilityTracking, actions],
    data() {
        return {
            visibility: true,
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'user',
            'competitions',
            'layoutsBy',
            'minisiteId',
        ]),
        competition() {
            return this.competitions[this.minisiteId];
        },
        layout() {
            return this.layoutsBy(this.competition)[0];
        },
    },
    created() {
        this.$on('visibility:update', this.setVisibility);
    },
    methods: {
        setVisibility(visibility) {
            this.visibility = visibility;
        },
    },
};
</script>
<style scoped>
@media (min-width: 768px) {
    .sidebar-wrapper {
        padding-right: 15px;
    }
}
.col-6 {
    max-width: var(--col-6);
}

.patb15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.d-none {
    display: none;
}
.bsh {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(55, 71, 79, 0.2);
    border-radius: 4px;
}
.minisite__button {
    padding: 14px 15px;
    line-height: 1.43;
    border: none;
    border-bottom: 1px solid #edf0f1;
    box-shadow: none;
}
.w100 {
    width: 100%;
}
.minisite__button:last-of-type {
    border-bottom: none;
}
.mtopbar {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 99999;
    transition: all 0.4s;
}

.mtopbar p {
    line-height: 1.71;
    text-align: right;
    margin: 0;
}

.mtopbar h4 {
    margin: 0 15px 0 0;
    line-height: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mtopbar .row {
    flex-direction: row-reverse;
}
.mtopbar__button {
    margin-left: 15px;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 767px) {
    .mtopbar .row {
        flex-direction: row;
    }
}
@media (min-width: 768px) {
    .col-md-3 {
        max-width: var(--col-3);
    }
    .mtopbar .row {
        flex-direction: row;
    }
    .d-md-block {
        display: block;
    }
    .container {
        max-width: 720px;
    }
    .mtopbar__button {
        box-sizing: border-box;
        margin-right: 15px;
        margin-left: 0;
    }
    .mtopbar h4 {
        margin: 0 0 0 15px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}
.mtopbar__button >>> .btn {
    box-sizing: border-box;
}
</style>
