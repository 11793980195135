import { schema } from 'normalizr';
import moment from 'moment';

export default new schema.Entity(
    'operators',
    {},
    {
        processStrategy: (operator) => ({
            // Meta.
            _type: 'operator',
            // Fields.
            ...operator,
            addedTime: operator.addedTime ? moment(operator.addedTime) : '',
        }),
    },
);
