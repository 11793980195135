export default {
    ages: {},
    categories: {},
    checkpoints: {},
    classifications: {},
    classificationsSettings: {},
    competitionPlayerCounts: {},
    competitions: {},
    competitionsPrices: {},
    competitionsSettings: {},
    competitionsCurrency: {},
    cycles: {},
    devices: {},
    familyAssociations: {},
    gates: {},
    layouts: {},
    limits: {},
    locations: {},
    measures: {},
    operators: {},
    organizers: {},
    players: {},
    panelPlayers: {},
    prices: {},
    results: {},
    trackpoints: {},
    users: {},
    gpxFiles: {},
    paymentCounts: {},
    categoryCounts: {},
    notifications: {},
};
