<template>
  <div>
    <p
      v-if="selectLabel.length > 0"
      class="mab8"
    >{{ selectLabel }}</p>

    <multiselect
      v-model="innerValue"
      :options="options"
      :track-by="trackBy"
      :label="label"
      :show-labels="false"
      :searchable="searchable"
      :multiple="multiple"
      :limit="limit"
      :placeholder="placeholder"
      :max-height="maxHeight"
      :hide-selected="hideSelected"
      :limit-text="limitText"
      :class="{ 'input-field--error': error }"
    >
      <template slot="tag" slot-scope="{ option }">
        <span class="custom__tag">{{ option[label] }},&nbsp;</span>
      </template>
      <template v-if="multiple" slot="caret">
        <span v-if="innerValue && innerValue.length > 0" class="caret-count">{{ innerValue.length }}</span>
        <div v-else class="multiselect__select"></div>
      </template>
      <template slot="option" slot-scope="{ option }">
        <div class="option__desc"><span class="option__title">{{ label ? option[label] : option }}</span></div>
      </template>
    </multiselect>
    <span
        class="panel-input-error input-error"
        :class="{'input-error--show' : error}"
    >{{ say[error] }}</span>
    <!-- <div
      class="input-field"
      :class="{'input-field--error' : error}"
    >
      <v-select
        append-to-body
        class="input-field__select"
        :class="{
            'input-field__select--with-selected': !isInput,
            'input-field__input--padding-bottom' : error,
            'input-field__input--padding-top' : isInputValid,
        }"
        :options="options"
        :label="labelSelect"
        :reduce="reduce"
        :value="value"
        :placeholder="placeholder"
        :clearable="clearable"
        :searchable="searchable"
        :multiple="multiple"
        @input="$emit('input', $event), $emit('update:error', null)"
      />

      <span
        class="input-error"
        :class="{'input-error--show' : error && !withoutErrorMessage}"
      >{{ say[error] || error }}</span>
    </div> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
  components: {
    Multiselect
  },
  props: {
    selectLabel: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },    
    options: {
      type: Array,
      default: () => [],
    },      
    label: {
      type: String,
      default: 'namePl',
    },
    trackBy: {
      type: String,
      default: 'namePl',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 3,
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
    placeholder: {
      type: String,
      default: '',
    },
    limitText: {
      type: Function,
      default() {
        return ``
      }
      // default(count) {
      //   return `and ${count} more`
      // }
    },
    error: {
      type: [String, Object],
      default: null,
    },
  },
  data () {
    return {
      innerValue: null,
    }
  },
  computed: {
    ...mapGetters(['say']),
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.$emit('update:error', null)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.caret-count {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 7px;
  right: 10px;
  border: 2px solid #50AE55;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.multiselect {
  color: var(--color-slate);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}
.multiselect__tags {
  display: table;
  width: 100%;
  padding-top: 9px;
}
.multiselect__tags:before {
  display: table-column;
  width: 100%;
  content: '';
}
.multiselect__tags-wrap,
.multiselect__single {
  max-width: 1px;
  display: table-cell;
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow: hidden;
}
.multiselect__single,
.option__title {
  font-size: 14px;
}
.panel-input-error.input-error {
  bottom: 2px;
  left: 20px;
}
</style>