<template>
    <div class="container pab1">
        <div class="row operator-header">
            <div class="col-12 col-lg-5">
                <!-- Switch between list organizers/competitions -->
                <base-button-group
                    horizontal
                    class="operator-header__views"
                >
                    <base-button
                        :pressed="isOrganizerList"
                        class="operator-header__views-btn"
                        btn11
                        @click="$emit('update:isOrganizerList', true)"
                    >
                        Lista organizatorów
                    </base-button>
                    <base-button
                        class="operator-header__views-btn"
                        btn11
                        :pressed="!isOrganizerList"
                        @click="$emit('update:isOrganizerList', false)"
                    >
                        Lista zawodów
                    </base-button>
                </base-button-group>
            </div>
            <div class="col-12 col-lg-4 operator-header__inputs">
                <!-- Search input for organizers. -->
                <base-input-text
                    v-if="isOrganizerList"
                    v-model="searchOrganizers"
                    icon="fas fa-search"
                    label="Szukaj..."
                    class="operator-header__search"
                />
                <!-- Link to operator site. -->
                <base-link-input
                    v-else
                    class="operator-header__link"
                    :link="operatorLink"
                    button-name="Kopiuj link"
                    hint="To jest publiczny adres strony z listą Twoich zawodów"
                />
            </div>
            <div class="col-12 col-lg-3">
                <!-- Add new organizer. -->
                <base-button
                    v-if="isOrganizerList"
                    class="operator-header__add-organizer"
                    btn1
                    @click="$emit('show-add-organizer')"
                >
                    Dodaj organizatora
                </base-button>
                <!-- Search input for competitions -->
                <base-input-text
                    v-else
                    v-model="searchCompetitions"
                    icon="fas fa-search"
                    label="Szukaj..."
                    class="operator-header__search operator-header__search--competitions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        // eslint-disable-next-line vue/require-prop-types
        operatorId: {
            required: true,
        },
        isOrganizerList: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            searchCompetitions: '',
            searchOrganizers: '',
        };
    },
    computed: {
        ...mapGetters(['operators']),
        operatorLink() {
            if (this.operators[this.operatorId]) {
                return `dostartu.pl/${this.operators[this.operatorId].name}-op${
                    this.operatorId
                }`;
            }
            return 'Wczytywanie...';
        },
    },

    watch: {
        searchCompetitions() {
            this.$emit('search-competitions', this.searchCompetitions);
        },
        searchOrganizers() {
            this.$emit('search-organizers', this.searchOrganizers);
        },
    },
};
</script>

<style scoped>
.operator-header {
    margin-top: 24px;
    margin-bottom: 24px;
}
.operator-header__views-btn {
    width: 100%;
    justify-content: center;
    user-select: none;
}
.operator-header__search {
    margin-top: 16px;
    min-height: 40px;
    max-height: 40px;
}
.operator-header__link {
    margin-top: 16px;
}
.operator-header__add-organizer {
    margin-top: 16px;
}
.operator-header__search--competitions {
    margin-top: 32px;
}
.operator-header__inputs {
    max-width: var(--col-12);
}
@media (min-width: 992px) {
    .operator-header {
        margin-top: 48px;
    }
    .operator-header__views {
        margin-right: 15px;
    }
    .operator-header__search {
        margin: 0 15px;
    }
    .operator-header__link {
        margin: 0 15px;
    }
    .operator-header__inputs {
        max-width: 33%;
    }
    .operator-header__add-organizer {
        margin-left: 15px;
        min-height: 40px;
        max-height: 40px;
        box-sizing: border-box;
        display: flex;
        margin-top: 0;
    }
    .operator-header__search--competitions {
        margin-right: 0;
    }
}
</style>
