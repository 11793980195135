<template>
    <div>
        <!-- User profile.  -->
        <div>
            <p class="sidebar__attribute">
                {{ say.firstname_and_lastname }}
            </p>
            <p class="sidebar__value">
                {{ user.firstname }} {{ user.lastname }}
            </p>
        </div>

        <div>
            <p class="sidebar__attribute">
                {{ say.birthtime }}
            </p>
            <p class="sidebar__value">
                {{ user.birthTime | asDate }}
            </p>
        </div>

        <div>
            <p class="sidebar__attribute">
                {{ say.email_address }}
            </p>
            <p class="sidebar__value">
                {{ user.email }}
            </p>
        </div>

        <div>
            <p class="sidebar__attribute">
                {{ say.city }}
            </p>
            <p class="sidebar__value">
                {{ user.city }}, {{ user.nationality | asNationality }}
            </p>
        </div>

        <!-- User edit button. -->
        <base-button
            v-if="!$router.matched('user-edit', $route)"
            btn4
            class="sidebar__edit-btn"
            @click="$router.push({ name: 'user-edit-profile', alias: true })"
        >
            {{ say.edit_data }}
        </base-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['say', 'user']),
    },
};
</script>

<style scoped>
.sidebar__attribute {
    margin: 0 0 8px 0;
    line-height: 1.1;
    word-break: break-all;
}

.sidebar__value {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    padding-bottom: 16px;
    word-break: break-all;
}

.sidebar__edit-btn {
    display: inline-flex;
}
</style>
