import api from '@/api';
import schema from '@/api/schema';
import {
    READ_CLASSIFICATION,
    READ_CLASSIFICATION_CHECKPOINTS,
    READ_CLASSIFICATION_PLAYERS,
    READ_COMPETITION,
    READ_COMPETITIONS,
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_ORGANIZER,
    READ_COMPETITION_PLAYERS,
    READ_GATE,
    READ_GATE_MEASURES,
    READ_NEXT_IN_CYCLE_COMPETITIONS,
    READ_OPERATOR,
    READ_OPERATOR_DEVICES,
    READ_PLAYER,
    READ_PLAYER_CYCLE,
    READ_PLAYER_SHOP,
    READ_USER,
    READ_USER_CHILD,
    READ_USER_CHILDREN,
    READ_USER_COMPETITIONS,
    READ_USER_COMPETITION_PLAYERS,
    READ_USER_COMPETITION_POLICIES_PDF,
    READ_USER_PARENTS,
    READ_CLASSIFICATION_RESULTS,
    READ_CLASSIFICATION_MEASURES,
    READ_DEVICE,
    READ_COMPETITION_TRACKPOINTS,
    READ_LOCATION,
    READ_CLASSIFICATION_GPX_FILE,
    READ_CLASSIFICATION_RESULTS_POSITIONS,
    READ_COMPETITION_GATES,
    READ_ORGANIZER,
    READ_ORGANIZER_LAYOUT,
    READ_OPERATOR_LAYOUT,
    READ_CLASSIFICATION_RESULTS_SPLIT,
    READ_CLASSIFICATION_RESULTS_LAP,
    READ_COMPETITION_LIMITS,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
    READ_COMPETITION_CLASSIFICATIONS_AGES,
    READ_COMPETITION_CLASSIFICATIONS_ACTIVE_PRICE,
    READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS,
    READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
    READ_OPERATOR_ORGANIZERS,
    READ_OPERATORS,
    READ_COMPETITION_COUNT,
    READ_PANEL_COMPETITION,
    READ_COMPETITION_LAYOUT,
    READ_COMPETITION_PAYMENT_COUNT,
    READ_COMPETITION_CATEGORY_COUNT,
    READ_PLAYERS_MEASURES,
    READ_PANEL_COMPETITION_PLAYERS,
    READ_PANEL_COMPETITION_PLAYERS_INPUTS,
} from '@/store/actions.type';
import { SET_ENTITIES } from '@/store/mutations.type';
import { download, zip } from '@/utils/files';
import { normalize } from 'normalizr';

export default {
    async [READ_USER]({ commit }, id) {
        const data = await api.get(`/users/${id}`);

        const normalized = normalize(data, schema.user);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_ORGANIZER]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/organizer`);

        const normalized = normalize(data, schema.organizer);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_USER_COMPETITIONS]({ commit }, { type, ...params }) {
        const data = await api.get(`/user/competitions/${type}`, {
            params,
        });

        const normalized = normalize(data, {
            competitions: [schema.competition],
            competitionsLayouts: [schema.layout],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_USER_COMPETITION_PLAYERS]({ commit }, id) {
        const data = await api.get(`/user/competitions/${id}/players`);

        const normalized = normalize(data, [schema.player]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CLASSIFICATIONS]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/classifications`);

        const normalized = normalize(data, {
            classifications: [schema.classification],
            classificationsCategories: [[schema.category]],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CLASSIFICATIONS_LIMITS]({ commit }, id) {
        const data = await api.get(
            `/competitions/${id}/classifications/limits`,
        );

        const normalized = normalize(data, [schema.limit]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CLASSIFICATIONS_AGES]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/classifications/ages`);

        const normalized = normalize(data, [schema.age]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CLASSIFICATIONS_ACTIVE_PRICE]({ commit }, id) {
        const data = await api.get(
            `/competitions/${id}/classifications/activePrice`,
        );

        const normalized = normalize(data, [schema.price]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS]({ commit }, id) {
        const data = await api.get(
            `competitions/${id}/classifications/topResults`,
        );

        const normalized = normalize(data, [schema.player]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_USER_COMPETITION_POLICIES_PDF](context, id) {
        const data = await api.get(`/user/competitions/${id}/policies.pdf`, {
            timeout: 10000,
        });

        if (data.length === 1) {
            download('polisa.pdf', data[0].content64);
        } else {
            download('polisy.zip', await zip(data));
        }
    },
    async [READ_USER_PARENTS]({ commit }) {
        const data = await api.get('/user/parents');

        const normalized = normalize(data, {
            parents: [schema.user],
            associations: [schema.familyAssociation],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_USER_CHILDREN]({ commit }) {
        const data = await api.get('/user/children');

        const normalized = normalize(data, {
            children: [schema.user],
            associations: [schema.familyAssociation],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_USER_CHILD]({ commit }, id) {
        const data = await api.get(`/user/children/${id}`);

        const normalized = normalize(data, schema.user);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION]({ commit }, id) {
        const data = await api.get(`/competitions/${id}`);

        const normalized = normalize(data, {
            competition: schema.competition,
            competitionLayout: schema.layout,
            competitionCurrency: schema.competitionCurrency,
        });

        commit(SET_ENTITIES, normalized.entities);
    },
    async [READ_PANEL_COMPETITION]({ commit }, id) {
        const data = await api.get(`panel/competitions/${id}`);

        const normalized = normalize(data, {
            competition: schema.competition,
            competitionLayout: schema.layout,
            competitionCurrency: schema.competitionCurrency,
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_LIMITS]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/limits`);

        const normalized = normalize(data, schema.limit);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_NEXT_IN_CYCLE_COMPETITIONS]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/next-in-cycle`);

        const normalized = normalize(data, {
            competitions: [schema.competition],
            competitionsLayouts: [schema.layout],
            competitionsPrices: [schema.competitionPrice],
            competitionsCurrency: [schema.competitionCurrency],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_PANEL_COMPETITION_PLAYERS](context, id) {
        // const data = await api.get(`panel/competitions/${id}/players`);

        // const normalized = normalize(data, [schema.panelPlayer]);

        // commit(SET_ENTITIES, normalized.entities);

        // return normalized.result;
        try {
            const data = await api.get(`panel/competitions/${id}/players`);
            return data;
        } catch(err) {
            return err
        }
    },
    async [READ_PANEL_COMPETITION_PLAYERS_INPUTS](context, id) {
        try {
            const data = await api.get(`panel/competitions/${id}/inputs`);
            return data;
        } catch(err) {
            return err
        }
    },    
    async [READ_COMPETITION_PLAYERS]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/players`);

        const normalized = normalize(data, [schema.player]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION]({ commit }, id) {
        const data = await api.get(`/classifications/${id}`);

        const normalized = normalize(data, {
            classification: schema.classification,
            categories: [schema.category],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION_PLAYERS]({ commit }, id) {
        const data = await api.get(`/classifications/${id}/players`);

        const normalized = normalize(data, [schema.player]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_PLAYERS_MEASURES](context, id) {
        try {
            const data = await api.get(`/players/${id}/measures`);
            return data;
        } catch(err) {
            return err
        }
    },
    async [READ_PLAYER]({ commit }, idOrToken) {
        const data = await api.get(`/players/${idOrToken}`);

        const normalized = normalize(data, schema.player);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_PLAYER_CYCLE](context, id) {
        try {
            const data = await api.get(`/panel/players/${id}/cycle`);
            return data;
        } catch(err) {
            return err
        }
    },
    async [READ_PLAYER_SHOP](context, id) {
        try {
            const data = await api.get(`/panel/players/${id}/shop`);
            return data;
        } catch(err) {
            return err
        }
    },        
    async [READ_COMPETITIONS]({ commit }, filter) {
        const apiFilter = {
            ...filter,
            dateTo: filter.dateTo?.toISOString(),
            dateSince: filter.dateSince?.toISOString(),
            locationLat: filter.location?.geometry?.lat,
            locationLng: filter.location?.geometry?.lng,
            location: undefined,
        };

        const data = await api.get('/competitions', {
            params: apiFilter,
        });

        const normalized = normalize(data, {
            competitions: [schema.competition],
            competitionsLayouts: [schema.layout],
            competitionsPlayerCount: [schema.competitionPlayerCount],
        });

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION_CHECKPOINTS]({ commit }, id) {
        const data = await api.get(`/classifications/${id}/checkpoints`);

        const normalized = normalize(data, [schema.checkpoint]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_OPERATOR_DEVICES]({ commit }, id) {
        const data = await api.get(`operators/${id}/devices`);

        const normalized = normalize(data, [schema.device]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_OPERATOR]({ commit }, id) {
        const data = await api.get(`operators/${id}`);

        const normalized = normalize(data, schema.operator);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_ORGANIZER]({ commit }, id) {
        const data = await api.get(`organizers/${id}`);

        const normalized = normalize(data, schema.organizer);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_OPERATOR_LAYOUT]({ commit }, id) {
        const data = await api.get(`operators/${id}/layout`);

        if (data === null) return null;

        const normalized = normalize(data, schema.layout);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_ORGANIZER_LAYOUT]({ commit }, id) {
        const data = await api.get(`organizers/${id}/layout`);

        if (data === null) return null;

        const normalized = normalize(data, schema.layout);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },

    async [READ_GATE]({ commit }, idOrSecureId) {
        const data = await api.get(`/gates/${idOrSecureId}`);

        const normalized = normalize(data, schema.gate);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_GATES]({ commit }, id) {
        const data = await api.get(`competitions/${id}/gates`);

        const normalized = normalize(data, [schema.gate]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_GATE_MEASURES]({ commit }, id) {
        const data = await api.get(`/gates/${id}/measures`);

        const normalized = normalize(data, [schema.measure]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION_RESULTS]({ commit }, id) {
        const data = await api.get(`/classifications/${id}/results`, {
            headers: {
                Accept: 'application/json',
            },
        });
        const normalized = normalize(data, [schema.result]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION_RESULTS_POSITIONS](context, id) {
        const results = await api.get(`/classifications/${id}/results`, {
            headers: {
                Accept: 'application/json+positions',
            },
        });

        return results;
    },
    async [READ_CLASSIFICATION_RESULTS_SPLIT](context, id) {
        const results = await api.get(`/classifications/${id}/results`, {
            headers: {
                Accept: 'application/json+split',
            },
        });

        return results;
    },
    async [READ_CLASSIFICATION_RESULTS_LAP](context, id) {
        const results = await api.get(`/classifications/${id}/results`, {
            headers: {
                Accept: 'application/json+lap',
            },
        });

        return results;
    },
    async [READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN](context, id) {
        const positions = await api.get(`/classifications/${id}/results`, {
            headers: {
                Accept: 'application/json+open',
            },
        });

        return positions;
    },
    async [READ_CLASSIFICATION_MEASURES]({ commit }, id) {
        const data = await api.get(`/classifications/${id}/measures`);

        const normalized = normalize(data, [schema.measure]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_DEVICE]({ commit }, id) {
        const data = await api.get(`/devices/${id}`);

        const normalized = normalize(data, schema.device);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_TRACKPOINTS]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/trackpoints`);

        const normalized = normalize(data, [schema.trackpoint]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_LOCATION]({ commit }, id) {
        const data = await api.get(`/locations/${id}`);

        const normalized = normalize(data, schema.location);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_CLASSIFICATION_GPX_FILE]({ commit }, id) {
        const data = await api.get(`/classifications/${id}/gpx-link`);

        const gpxFile = {
            classification: id,
            url: data,
        };

        const normalized = normalize(gpxFile, schema.gpxFile);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_OPERATOR_ORGANIZERS]({ commit }, id) {
        const data = await api.get(`panel/operators/${id}/organizers`);

        const normalized = normalize(data, [schema.organizer]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_OPERATORS]({ commit }) {
        const data = await api.get('/panel/operators');

        const normalized = normalize(data, [schema.operator]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_COUNT]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/players/count`);

        const normalized = normalize(data, schema.competitionPlayerCount);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_LAYOUT]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/layout`);

        const normalized = normalize(data, schema.layout);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_PAYMENT_COUNT]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/count/payment`);
        const prize = {
            value: data,
            competition: id,
        };
        const normalized = normalize(prize, schema.paymentCount);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [READ_COMPETITION_CATEGORY_COUNT]({ commit }, id) {
        const data = await api.get(`/competitions/${id}/category/count`);
        const normalized = normalize(data, [schema.categoryCount]);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
};
