<template>
    <div
        class="w100"
    >
        <div class="mal30 mab40">
            <PanelCompetitionSettingStoperMain :id="id" />
        </div>
    </div>
</template>

<script>
import PanelCompetitionSettingStoperMain from '@/components/PanelCompetitionSettingStoperMain';
export default {
    components: {
        PanelCompetitionSettingStoperMain,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
        };
    },
};
</script>

<style scoped>
.save-btn {
    height: 40px;
    box-sizing: border-box;
}

.border-box--gray {
    border-radius: 4px;
    border: solid 1px var(--color-bluish-grey);
}
</style>
