<template>
    <!-- Minisite link. -->
    <div
        class="competition-box pointer"
        @click="
            competition
                ? $router.push({
                    name: 'minisite-landing',
                    params: competition,
                    alias: true,
                })
                : ''
        "
    >
        <!-- Sport photo. -->
        <div class="row">
            <div class="col-12">
                <div
                    v-if="!competition"
                    class="competition-box__image d-flex"
                >
                    <div class="competition-box__image-content--skeleton" />
                </div>
                <div
                    v-else
                    class="competition-box__image"
                    :class="classPhoto"
                >
                    <div
                        :style="styleColour"
                        class="competition-box__image-content"
                    >
                        <!-- Competition type. -->
                        <p class="competition-box__category">
                            {{ competition.type | asType }}
                        </p>

                        <!-- Prices. -->
                        <!-- <template v-if="competition.status === 2"> -->
                        <!-- Price range. -->
                        <!-- <template v-if="competitionPrice">
                                <div
                                    v-if="
                                        competitionPrice.minPrice &&
                                        competitionPrice.minPrice !==
                                            competitionPrice.maxPrice
                                    "
                                    class="competition-box__price"
                                >
                                    <p>{{ say.price }}</p>
                                    <p>
                                        {{ competitionPrice.minPrice }}
                                        {{ currency }} -
                                        {{ competitionPrice.maxPrice }}
                                        {{ currency }}
                                    </p>
                                </div> -->

                        <!-- Single price. -->
                        <!-- <div
                                    v-else-if="competitionPrice.minPrice"
                                    class="competition-box__price"
                                >
                                    <p>{{ say.price }}</p>
                                    <p>
                                        {{ competitionPrice.minPrice }}
                                        {{ currency }}
                                    </p>
                                </div>
                            </template> -->

                        <!-- Feeless. -->
                        <!-- <div
                                v-else
                                class="competition-box__price competition-box__price--free"
                            >
                                <p>{{ say.competitions_feeless }}</p>
                            </div>
                        </template> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Description. -->
        <div class="row competition-box__description">
            <div class="col-12">
                <!-- Name skeleton. -->
                <p
                    v-if="!competition"
                    class="base-skeleton-text competition-box__description-title--skeleton"
                />
                <!-- Name. -->
                <h2 v-else>
                    {{ competition.name }}
                </h2>
                <!-- Status skeleton. -->
                <div
                    v-if="!competition"
                    class="competition-box__status competition-box__status--skeleton competition-box__status--mobile"
                />
                <template v-else>
                    <!-- Status on mobile. -->
                    <div
                        v-if="[2, 3].includes(competition.status)"
                        class="competition-box__status competition-box__status--mobile"
                        :class="
                            competition.status !== 2
                                ? 'competition-box__status--close'
                                : 'competition-box__status--open'
                        "
                    >
                        {{ competition.status | asStatus }}
                    </div>
                    <div
                        v-if="[4, 5, 6].includes(competition.status)"
                        class="competition-box__status competition-box__status--mobile competition-box__status--results"
                    >
                        {{ competition.status | asStatus }}
                    </div>
                </template>
                <!-- Location skeleton. -->
                <div
                    v-if="!competition"
                    class="competition-box__location"
                >
                    <p
                        class="base-skeleton-text base-skeleton-text--location"
                    />
                    <p class="base-skeleton-text base-skeleton-text--date" />
                </div>
                <!-- Location. -->
                <div
                    v-else
                    class="competition-box__location"
                >
                    <p>{{ competition.location }}</p>
                    <p>{{ competition.startedTime | asTime }}</p>
                </div>

                <!-- Invite friends. -->
                <!-- <div class="competition-box__invite">
                    <i class="fab fa-facebook-f" />
                    <p class="mal8 pointer">Zaproś znajomych</p>
                </div>-->

                <!-- Registerer person. -->
                <!-- <div class="competition-box__save-info">
                    <div class="col-12 col-lg-unset nowrap">Zapisał Cie</div>
                    <div class="competition-box__registering-person">
                        <img src="https://via.placeholder.com/150x150" alt class="image-circle" />
                        <p class="d-inline mal8">Konrad Miszczak</p>
                    </div>
                    <base-button btn5>OK</base-button>
                </div>-->

                <!-- User actions. -->
                <template
                    v-if="
                        competition &&
                            ready &&
                            Object.keys(userPlayers).length &&
                            (competition.status == 2 || competition.isRemote || competition.status >= 5 )
                    "
                >
                    <div
                        v-for="player of userPlayers"
                        :key="player.id"
                    >
                        <!-- Player name. -->
                        <div class="col-12 mab8">
                            <template
                                v-if="
                                    (Object.keys(userPlayers).length > 1 &&
                                        !isOnlyCurrentUserRegistered) ||
                                        (Object.keys(userPlayers).length == 1 &&
                                            player.user != user.id)
                                "
                            >
                                <b>{{ player.firstname }}
                                    {{ player.lastname }}</b>
                                ,
                            </template>
                            {{ classifications[player.classification].namePl }}
                        </div>

                        <!-- User actions. -->
                        <div
                            v-if="
                                competition.status <= 3 ||
                                    (competition.status != 6 &&
                                        competition.isRemote)
                            "
                            class="row"
                        >
                            <div
                                class="competition-box__actions"
                                :class="{
                                    mab16:
                                        !(lastUserPlayerId == player.id) ||
                                        competition.status >= 5,
                                }"
                            >
                                <!-- Button pay. -->
                                <div
                                    v-if="canPlayerPay(player, competition)"
                                    class="col-12 col-lg-unset mar8"
                                >
                                    <base-button
                                        btn1
                                        @click="
                                            $router.push({
                                                name: 'minisite-pay',
                                                params: {
                                                    name: competition.name,
                                                    id: competition.id,
                                                    token: player.token,
                                                },
                                                alias: true,
                                            })
                                        "
                                    >
                                        {{ say.pay_for_participation }}
                                    </base-button>
                                </div>
                                <div
                                    class="col-12 col-lg-unset align-items-center"
                                >
                                    <div class="competition-box__actions-group">
                                        <!-- Button edit. -->
                                        <base-button
                                            v-if="
                                                canPlayerEdit(
                                                    player,
                                                    competition,
                                                )
                                            "
                                            btn5
                                            @click="
                                                $router.push({
                                                    name: 'minisite-edit',
                                                    params: {
                                                        name: competition.name,
                                                        id: competition.id,
                                                        token: player.token,
                                                    },
                                                    alias: true,
                                                })
                                            "
                                        >
                                            {{ say.edit }}
                                        </base-button>

                                        <!-- Button transfer. -->
                                        <base-button
                                            v-if="
                                                canPlayerTransfer(
                                                    player,
                                                    competition,
                                                )
                                            "
                                            btn5
                                            @click="
                                                $router.push({
                                                    name: 'minisite-transfer',
                                                    params: {
                                                        name: competition.name,
                                                        id: competition.id,
                                                        token: player.token,
                                                    },
                                                    alias: true,
                                                })
                                            "
                                        >
                                            {{ say.transfer_pay }}
                                        </base-button>

                                        <!-- Button shop. -->
                                        <base-button
                                            v-if="
                                                canPlayerShop(
                                                    player,
                                                    competition,
                                                )
                                            "
                                            btn5
                                            @click="
                                                $router.push({
                                                    name: 'minisite-shop',
                                                    params: {
                                                        name: competition.name,
                                                        id: competition.id,
                                                        token: player.token,
                                                    },
                                                    alias: true,
                                                })
                                            "
                                        >
                                            {{ say.shop }}
                                        </base-button>

                                        <!-- Button declare result. -->
                                        <base-button
                                            v-if="
                                                canPlayerDeclareResult(
                                                    player,
                                                    competition,
                                                )
                                            "
                                            btn5
                                            @click="
                                                $router.push({
                                                    name:
                                                        'minisite-declare-result',
                                                    params: {
                                                        token: player.token,
                                                    },
                                                    alias: true,
                                                })
                                            "
                                        >
                                            {{ say.add_result }}
                                        </base-button>
                                    </div>
                                </div>
                                <!-- Pay status: payed. -->
                                <div
                                    v-if="player.pay"
                                    class="competition-box__actions-status c-positive mal15"
                                >
                                    <i
                                        class="fas fa-check-circle"
                                        aria-hidden="true"
                                    />
                                    {{ say.payed }}
                                </div>
                                <!-- Pay status: not payed. -->
                                <div
                                    v-else-if="
                                        classificationsSettings[
                                            player.classification
                                        ].isPay &&
                                            !player.pay &&
                                            (competition.status != 2 ||
                                                (limits[player.classification]
                                                    .playersAvailable === 0 &&
                                                    competitionSetting.playersKindLimit ===
                                                    'pay'))
                                    "
                                    class="c-negative competition-box__actions-status mal15"
                                >
                                    {{ say.player_is_not_payed }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    lastUserPlayerId == player.id &&
                                        [2, 3].includes(competition.status)
                                "
                                class="competition-box__status competition-box__status--desktop competition-box__status--no-margin-bottom"
                                :class="[
                                    competition.status !== 2
                                        ? 'competition-box__status--close'
                                        : 'competition-box__status--open ',
                                ]"
                            >
                                {{ competition.status | asStatus }}
                            </div>
                        </div>

                        <!-- Competition results. -->
                        <template v-if="competition.status >= 5">
                            <div
                                class="competition-box__result"
                                :class="{
                                    mab16: !(lastUserPlayerId == player.id),
                                }"
                            >
                                <div class="col-12">
                                    <div class="row justify-content-start">
                                        <!-- Player with result. -->
                                        <template
                                            v-if="
                                                player.officialTime ||
                                                    player.declaredTime
                                            "
                                        >
                                            <!-- Player time. -->
                                            <div
                                                class="col-12 col-lg-4 competition-box__result-time"
                                            >
                                                <p>{{ say.your_time }}</p>
                                                <strong class="l-tall">{{
                                                    (player.officialTime ||
                                                        player.declaredTime)
                                                        | asStoper
                                                }}</strong>
                                            </div>

                                            <!-- Place in classification. -->
                                            <div
                                                class="col-12 col-lg-4 competition-box__result-place"
                                            >
                                                <p>
                                                    {{ say.in_classification }}
                                                </p>
                                                <strong
                                                    v-if="
                                                        player.placeClassification
                                                    "
                                                    class="mar8"
                                                >#{{
                                                    player.placeClassification
                                                }}</strong>
                                            </div>

                                            <!-- Place in category. -->
                                            <div
                                                class="col-12 col-lg-4 competition-box__result-classification"
                                            >
                                                <p>{{ say.in_category }}</p>
                                                <strong
                                                    v-if="player.placeCategory"
                                                    class="mar8"
                                                >#{{
                                                    player.placeCategory
                                                }}</strong>
                                            </div>
                                        </template>

                                        <!-- Player without result. -->
                                        <template v-else>
                                            {{ say.time_not_added }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>

                <!-- Ask for results. -->
                <!-- <div class="row">
                    <div class="competition-box__actions">
                        <div class="col-12 col-lg-unset">
                            <div class="competition-box__actions-group">
                                <base-button btn5>Zapytaj organizatora o wyniki</base-button>
                            </div>
                        </div>
                    </div>
                </div>-->
                <!-- Box with status on desktop and hint. -->
                <template
                    v-else-if="
                        competition && [2, 3].includes(competition.status)
                    "
                >
                    <div class="competition-box__hint">
                        <!-- <div class="items-center d-flex">
                        <i class="fas fa-info-circle f16" />
                        <p class="d-inline mal8">Zawody w Twojej okolicy</p>
                        </div>-->
                        <div
                            class="competition-box__status competition-box__status--desktop"
                            :class="[
                                competition.status !== 2
                                    ? 'competition-box__status--close'
                                    : 'competition-box__status--open',
                            ]"
                        >
                            {{ competition.status | asStatus }}
                        </div>
                    </div>
                </template>
                <template
                    v-else-if="
                        competition && [4, 5, 6].includes(competition.status)
                    "
                >
                    <div class="competition-box__hint">
                        <!-- <div class="items-center d-flex">
                        <i class="fas fa-info-circle f16" />
                        <p class="d-inline mal8">Zawody w Twojej okolicy</p>
                        </div>-->
                        <div
                            class="competition-box__status competition-box__status--desktop competition-box__status--results"
                        >
                            {{ competition.status | asStatus }}
                        </div>
                    </div>
                </template>
                <!-- Box with status on desktop skeleton -->
                <div
                    v-if="!competition"
                    class="competition-box__hint competition-box__hint--skeleton"
                >
                    <div
                        class="competition-box__status w200px competition-box__status--skeleton competition-box__status--desktop"
                    />
                </div>

                <!-- Voucher. -->
                <!-- <div class="competition-box__voucher">
                    <div class="col-12 col-lg-6 competition-box__voucher-classification">
                        <p>Klasyfikacja</p>
                        <Strong>Kobiety 10 km</Strong>
                    </div>
                    <div class="col-12 col-lg-6">
                        <p>Vouchery</p>
                        <p>
                            <strong>
                                <i class="fas fa-check-circle c-positive" />
                                K9B-7C-M5K
                            </strong>
                        </p>
                        <p>
                            <strong>
                                <i class="fas fa-times-circle c-negative" />
                                K9B-7C-M5K
                            </strong>
                        </p>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hexToRgb } from '@/utils/text';
import _ from 'lodash';
import actions from '@/mixins/actions';
import {
    READ_USER_COMPETITION_PLAYERS,
    READ_CLASSIFICATION,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
} from '@/store/actions.type';
import { pick } from '@/utils/object';

export default {
    mixins: [actions],
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            userPlayersIds: [],
            ready: false,
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            // "competitionsPrices",
            'layoutsBy',
            'user',
            'classifications',
            'players',
            'classificationsSettings',
            // "competitionsCurrency",
            'limits',
            'competitionsSettings',
        ]),
        // currency() {
        //     if (this.competitionsCurrency[this.competition.id]) {
        //         return this.competitionsCurrency[this.competition.id]
        //             .currencySymbol;
        //     }
        //     return "zł";
        // },
        competition() {
            return this.competitions[this.id];
        },
        competitionSetting() {
            return this.competitionsSettings[this.id];
        },
        // competitionPrice() {
        //     return this.competitionsPrices[this.id];
        // },
        userPlayers() {
            return pick(this.players, this.userPlayersIds);
        },
        layout() {
            return this.layoutsBy(this.competition)[0];
        },
        classPhoto() {
            return `sport-image--${this.competition.type}`;
        },
        styleColour() {
            const hex = this.layout.colourDefault;
            const rgb = hexToRgb(hex).join(',');

            return `background-image: linear-gradient(to top, ${hex}, rgba(${rgb}, 0.5))`;
        },
        lastUserPlayerId() {
            return _.findLastKey(this.userPlayers);
        },
        isOnlyCurrentUserRegistered() {
            let isOnlyCurrentUserRegistered = false;
            for (const playerId in this.userPlayers) {
                if (this.players[playerId].user === this.user.id) {
                    isOnlyCurrentUserRegistered = true;
                } else {
                    isOnlyCurrentUserRegistered = false;
                }
            }
            return isOnlyCurrentUserRegistered;
        },
    },
    async created() {
        if (this.user && this.user.roles.includes('ROLE_USER') && this.id > 0) {
            const userPlayersIds = await this.READ_USER_COMPETITION_PLAYERS(
                this.id,
            );
            const readPlayersClassifications = [];
            if (userPlayersIds.length) {
                for (const id of userPlayersIds) {
                    readPlayersClassifications.push(
                        this.READ_CLASSIFICATION(
                            this.players[id].classification,
                        ),
                    );
                }
                // Fetch classifications limits.
                readPlayersClassifications.push(
                    this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
                );
                await Promise.all(readPlayersClassifications);
            }
            this.userPlayersIds = userPlayersIds;
        }
        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_USER_COMPETITION_PLAYERS,
            READ_CLASSIFICATION,
            READ_COMPETITION_CLASSIFICATIONS_LIMITS,
        ]),
    },
};
</script>
