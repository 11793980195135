import { schema } from 'normalizr';

export default new schema.Entity('competitionsPrices', {}, {
    idAttribute: 'competition', // one-to-one relation
    processStrategy: (competitionPrice) => ({
        // Meta.
        _type: 'competitionPrice',
        // Fields.
        ...competitionPrice,
    }),
});
