var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-wrapper" },
    [
      _c("img", {
        staticClass: "nav-close",
        attrs: {
          src: require("@/assets/images/close-white-icon.svg"),
          alt: "close",
        },
        on: {
          click: function ($event) {
            return _vm.TOGGLE_NAV()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "nav-status" },
        [
          _c("p", { staticClass: "nav-status__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isResultsStatuses ? "Wyniki" : "Rejestracja online"
                ) +
                " "
            ),
          ]),
          !_vm.isResultsStatuses
            ? [
                _c(
                  "base-button",
                  {
                    staticClass: "nav-status__button",
                    class: {
                      "nav-status__button--negative": [
                        _vm.STATUSES.close,
                        _vm.STATUSES.info,
                      ].includes(_vm.competition.status),
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(_vm.STATUSES.close)
                      },
                    },
                  },
                  [_vm._v(" Zamknięta ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "nav-status__button",
                    class: {
                      "nav-status__button--positive":
                        _vm.competition.status == _vm.STATUSES.open,
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(_vm.STATUSES.open)
                      },
                    },
                  },
                  [_vm._v(" Otwarta ")]
                ),
              ]
            : [
                _c(
                  "base-button",
                  {
                    staticClass:
                      "nav-status__button nav-status__button--results",
                    class: {
                      "nav-status__button--positive":
                        _vm.competition.status == _vm.STATUSES.live,
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(_vm.STATUSES.live)
                      },
                    },
                  },
                  [_vm._v(" Live ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass:
                      "nav-status__button nav-status__button--results",
                    class: {
                      "nav-status__button--positive":
                        _vm.competition.status == _vm.STATUSES.unofficial,
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(
                          _vm.STATUSES.unofficial
                        )
                      },
                    },
                  },
                  [_vm._v(" Nieoficjalne ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass:
                      "nav-status__button nav-status__button--results",
                    class: {
                      "nav-status__button--positive":
                        _vm.competition.status == _vm.STATUSES.official,
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(
                          _vm.STATUSES.official
                        )
                      },
                    },
                  },
                  [_vm._v(" Oficjalne ")]
                ),
              ],
        ],
        2
      ),
      _c(
        "base-button-group",
        { staticClass: "nav-box" },
        [
          _c(
            "base-button",
            {
              staticClass: "nav-box__button nav-box__button--with-icon",
              attrs: {
                btn11: "",
                pressed: _vm.$route.name === "panel-competition-dashboard",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-dashboard")
                },
              },
            },
            [
              _c("img", {
                staticClass: "nav-box__button-icon",
                attrs: {
                  src: require("@/assets/images/icons/home.svg"),
                  alt: "Home icon",
                },
              }),
              _vm._v(" Zawody "),
            ]
          ),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "nav-box" },
        [
          _c(
            "base-button",
            {
              staticClass: "nav-box__button nav-box__button--with-icon",
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-setting-info")
                },
              },
            },
            [
              _c("img", {
                staticClass: "nav-box__button-icon",
                attrs: {
                  src: require("@/assets/images/icons/sliders.svg"),
                  alt: "Slider icon",
                },
              }),
              _vm._v(" Ustawienia zawodów "),
            ]
          ),
          _c(
            "base-button",
            {
              staticClass: "nav-box__button",
              attrs: {
                btn11: "",
                pressed: _vm.$route.name === "panel-competition-setting-info",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-setting-info")
                },
              },
            },
            [_vm._v(" Informacje o zawodach ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "nav-box__button",
              attrs: {
                btn11: "",
                pressed:
                  _vm.$route.name ===
                  "panel-competition-setting-classifications",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo(
                    "panel-competition-setting-classifications"
                  )
                },
              },
            },
            [_vm._v(" Klasyfikacje, opłaty, limity ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "nav-box__button",
              attrs: {
                btn11: "",
                pressed:
                  _vm.$route.name === "panel-competition-setting-numbers",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-setting-numbers")
                },
              },
            },
            [_vm._v(" Numeracja startowa ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "nav-box__button",
              attrs: {
                btn11: "",
                pressed:
                  _vm.$route.name === "panel-competition-setting-regulation",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-setting-regulation")
                },
              },
            },
            [_vm._v(" Regulamin zawodów ")]
          ),
          _c("div", { staticClass: "nav-box__hint" }, [
            _vm._v(
              " Aby skorzystać z zaawansowanych ustawień zawodów, przejdź do pełnej wersji panelu, używając przeglądarki na komputerze. "
            ),
          ]),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "nav-box" },
        [
          _c(
            "base-button",
            {
              staticClass: "nav-box__button nav-box__button--with-icon",
              attrs: {
                btn11: "",
                pressed: _vm.$route.name === "panel-competition-list-new",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-list-new")
                },
              },
            },
            [
              _c("img", {
                staticClass: "nav-box__button-icon",
                attrs: {
                  src: require("@/assets/images/icons/users.svg"),
                  alt: "Users icon",
                },
              }),
              _vm._v(" Lista startowa "),
            ]
          ),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "nav-box" },
        [
          _c(
            "base-button",
            {
              staticClass: "nav-box__button nav-box__button--with-icon",
              attrs: {
                btn11: "",
                pressed: _vm.$route.name === "panel-competition-result",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-result")
                },
              },
            },
            [
              _c("img", {
                staticClass: "nav-box__button-icon",
                attrs: {
                  src: require("@/assets/images/icons/list-number.svg"),
                  alt: "List icon",
                },
              }),
              _vm._v(" Wyniki "),
            ]
          ),
          _c("div", { staticClass: "nav-box__hint" }, [
            _vm._v(
              " Aby wgrać wyniki z pliku, przejdź do pełnej wersji panelu, używając przeglądarki na komputerze. "
            ),
          ]),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "nav-box nav-box--last" },
        [
          _c(
            "base-button",
            {
              staticClass: "nav-box__button nav-box__button--with-icon",
              attrs: {
                btn11: "",
                pressed: _vm.$route.name === "panel-competition-stoper",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateTo("panel-competition-stoper")
                },
              },
            },
            [
              _c("img", {
                staticClass: "nav-box__button-icon",
                attrs: {
                  src: require("@/assets/images/icons/clock.svg"),
                  alt: "Clock icon",
                },
              }),
              _vm._v(" Stoper "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }