<template>
    <div class="input-field input-field--focus-bolder">
        <label
            class="input-field__label"
            :class="{'input-field__label--show' : value}"
        >{{ label }}</label>

        <input
            class="input-field__input"
            type="text"
            :class="{ 'input-field__input--padding-top' : value }"
            :value="value"
            :placeholder="label"
            @input="$emit('input', $event.target.value)"
        >

        <i
            class="input-field__icon"
            :class="[ value && badge ? badge : icon ]"
            @click="$emit('icon-click')"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
