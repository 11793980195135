import events from '@/events';
import {
    cast, getCarouselFilter, getListFilter, uncast,
} from '@/service/filter';
import {
    SEARCH_COMPETITIONS_LIST,
    SEARCH_COMPETITIONS_CAROUSEL,
    READ_COMPETITIONS,
    READ_COMPETITIONS_COUNT,
    READ_COMPETITION_WEATHER,
} from '@/store/actions.type';
import {
    SET_FILTER,
    SET_FILTER_FUTURE_FINISH_PAGE,
} from '@/store/mutations.type';
import api from '@/api';

export default {
    getters: {
        filter: (state, getters) => cast(getters.route.query),
    },
    mutations: {
        [SET_FILTER](state, { filter, route, destination = null }) {
            if(destination) {
                events.$emit('router:push', {
                    name: destination,
                    query: uncast(filter),
                    alias: true,
                });
            } else {
                if (['main-site-operator', 'main-site-organizer'].includes(route.name)) {
                    events.$emit('router:replace', {
                        query: uncast(filter),
                    });
                } else if(['main-list-map'].includes(route.name)) {
                    events.$emit('router:replace', {
                        name: 'main-list-map',
                        query: uncast(filter),
                        alias: true,
                    });
                } else {
                    events.$emit('router:push', {
                        name: 'main-list',
                        query: uncast(filter),
                        alias: true,
                    });
                }
            }
        },
        [SET_FILTER_FUTURE_FINISH_PAGE](state, { page }) {
            state.futureFinishPage = page;
        },
    },
    actions: {
        async [SEARCH_COMPETITIONS_CAROUSEL]({ dispatch }, optionalFilter = undefined) {
            let filter = getCarouselFilter();
            if (optionalFilter) {
                filter = {
                    ...filter,
                    ...optionalFilter,
                };
            }
            const { competitions } = await dispatch(READ_COMPETITIONS, filter);

            return competitions;
        },
        async [SEARCH_COMPETITIONS_LIST]({
            getters, commit, dispatch,
        }) {
            const [promotedFilter, futureFilter, pastFilter] = getListFilter(getters.filter, getters);

            const search = (filter) => {
                if (filter !== undefined) {
                    return dispatch(READ_COMPETITIONS, filter);
                }

                return Promise.resolve({ competitions: [] });
            };

            // Search promoted, future and past competitions.
            const [
                { competitions: promotedCompetitions },
                { competitions: futureCompetitions },
                { competitions: pastCompetitions },
            ] = await Promise.all([
                search(promotedFilter),
                search(futureFilter),
                search(pastFilter),
            ]);

            // Check if future competitions has finished on this page.
            if (futureFilter && futureFilter.itemsPerPage !== futureCompetitions.length) {
                commit(SET_FILTER_FUTURE_FINISH_PAGE, getters.filter);
            }

            return [promotedCompetitions, futureCompetitions, pastCompetitions];
        },
        async [READ_COMPETITIONS_COUNT]() {
            const data = await api.get('/competitions/count');

            return data;
        },
        async [READ_COMPETITION_WEATHER](context, id) {
            const data = await api.get(`/competitions/${id}/weather`);

            return data;
        },
    },
};
