<template>
    <base-form-section>
        <!-- Firstname. -->
        <base-form-input>
            <base-input-text
                v-model="form.firstname"
                :error.sync="errors.firstname"
                :label="say.firstname"
            />
            <template v-slot:hint>
                {{
                    $router.matched("user-edit-profile", $route)
                        ? say.fill_user_data_to_register_faster
                        : say.fill_family_member_data
                }}
            </template>
        </base-form-input>

        <!-- Lastname. -->
        <base-form-input>
            <base-input-text
                v-model="form.lastname"
                :error.sync="errors.lastname"
                :label="say.lastname"
            />
        </base-form-input>

        <!-- Gender. -->
        <base-form-input>
            <base-input-radio
                v-model="form.gender"
                :options="[
                    { value: 'M', title: 'man' },
                    { value: 'K', title: 'woman' },
                ]"
                :error.sync="errors.gender"
            />
        </base-form-input>

        <!-- Birthtime. -->
        <base-form-input>
            <base-input-date
                v-model="form.birthTime"
                border
                :error.sync="errors.birthTime"
                :label="say.birthtime"
                initialView="year"
                :openDate="new Date('1983-01-01')"
            />
        </base-form-input>

        <!-- Phone. -->
        <base-form-input half>
            <!-- Phone prefix. -->
            <base-input-select
                v-model="form.phonePrefix"
                :reduce="(prefix) => prefix.id"
                :clearable="false"
                :options="prefixes"
                :label="say.prefix"
            />
            <!-- Phone number. -->
            <template v-slot:right>
                <base-input-text
                    v-model="form.phone"
                    :label="say.phone"
                    :error.sync="errors.phone"
                />
            </template>
        </base-form-input>

        <!-- Clubname. -->
        <base-form-input>
            <base-input-text
                v-model="form.clubname"
                :label="say.club"
                :error.sync="errors.clubname"
            />
        </base-form-input>

        <!-- City. -->
        <base-form-input>
            <base-input-autocomplete
                v-model="searchValueCity"
                class="user-form__autocomplete"
                :label="say.city"
                :error.sync="cityError"
                action="AUTOCOMPLETE_LOCATION"
                @input="cityWithoutSelect"
                @select="selectCity"
                @search-blur="onSearchBlur"
            />
        </base-form-input>
        <transition name="fade-map">
            <base-form-input v-if="isCoordsCompetition">
                <div class="form-map">
                    <base-map
                        class="form-map__map"
                        :lat="form.cityLat"
                        :lng="form.cityLng"
                        :zoom="10"
                        :fullscreenable="false"
                    >
                        <l-marker
                            :lat-lng="[
                                form.cityLat,
                                form.cityLng,
                            ]"
                            :draggable="true"
                            @update:lat-lng="updateCoords"
                        >
                            <l-icon :icon-anchor="[12, 32]">
                                <div class="form-map__marker">
                                    <img
                                        src="@/assets/images/marker-ds.svg"
                                    >
                                </div>
                            </l-icon>
                        </l-marker>
                    </base-map>
                </div> 
            </base-form-input>
        </transition>
        <!-- Nationality. -->
        <base-form-input>
            <base-input-select
                v-model="form.nationality"
                :options="countries"
                :clearable="false"
                :label="say.nationality"
                :reduce="(country) => country.id"
                :error.sync="errors.nationality"
            />
        </base-form-input>
    </base-form-section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '@/assets/data/countries';
import prefixes from '@/assets/data/prefixes';
import form from '@/mixins/form';
import { debounce } from 'lodash';
import { LMarker, LIcon } from 'vue2-leaflet';

export default {
    mixins: [form],
    components: {
        LMarker,
        LIcon,
    },
    data() {
        return {
            countries,
            prefixes,
            searchValueCity: form.city,
        };
    },
    computed: {
        ...mapGetters(['say']),
        birthDate: {
            get() {
                const birthTime = moment(this.form.birthTime);

                return birthTime.isValid() ? birthTime.toDate() : null;
            },
            set(birthDate) {
                this.form.birthTime = new Date(birthDate);
            },
        },
        isCoordsCompetition() {
            return this.form.cityLat && this.form.cityLng;
        },
        cityError: {
            get() {
                return (
                    this.errors.city
                    || this.errors.cityLat
                    || this.errors.cityLng
                );
            },
            set() {
                this.errors.city = '';
                this.errors.cityLat = '';
                this.errors.cityLng = '';
            },
        },
    },
    watch: {
        'form.city': {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.searchValueCity = this.form.city;
                });
            },
        },
    },
    methods: {
        updateCoords(coords) {
            this.$set(this.form, 'cityLng', coords.lng);
            this.$set(this.form, 'cityLat', coords.lat);
        },
        cityWithoutSelect: debounce(function(city) {
            this.selectCity(city)
        }, 500),
        onSearchBlur() {
            // Waiting for emit selected value in location input.
            setTimeout(() => {
                this.searchValueCity = this.form.city;
            }, 250);
        },
        selectCity(value) {
            // place changed event
            if (typeof value === 'string' && value !== '') {
                this.$set(this.form, 'cityLng', 19.423828125000004);
                this.$set(this.form, 'cityLat', 52.142479791733464);
                this.$set(this.form, 'city', value);
            } else if (value) {
                this.$set(this.form, 'cityLng', value.cityLng);
                this.$set(this.form, 'cityLat', value.cityLat);
                this.$set(this.form, 'city', value.city);
                setTimeout(() => {
                    this.searchValueCity = value.text;
                }, 40);
            } else {
                this.$set(this.form, 'cityLng', 0);
                this.$set(this.form, 'cityLat', 0);
                this.$set(this.form, 'city', null);
                setTimeout(() => {
                    this.searchValueCity = null;
                }, 40);
            }
        },
    },
};
</script>

<style scoped>
.form-map {
    overflow: hidden;
}
.form-map__map {
    width: 100%;
    height: 300px;
}
.fade-map-enter {
    max-height: 0;
}

.fade-map-enter-to {
    max-height: 300px;
}

.fade-map-leave-to {
    max-height: 0;
}

.fade-map-enter-active,
.fade-map-leave-active {
    transition: all 0.6s;
}
</style>