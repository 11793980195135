<template>
    <base-map-marker
        :lat="location.lat"
        :lng="location.lng"
        :point="point"
        :selected-classification-id="selectedClassificationId"
        :read-only="readOnly"
        @change:latlng="updateLocationLatLng($event)"
        @click="popup = !popup"
    >
        <!-- Point icons. -->
        <template v-slot:icon>
            <!-- Type icons. -->
            <template v-for="(type, typeName) in types">
                <div
                    v-if="hasType(type)"
                    :key="type"
                    :class="{
                        'icon': true,
                        [`icon--${typeName}`]: true,
                        'disabled': hasType(type) && !hasSelectedClassification(type),
                    }"
                />
            </template>
        </template>

        <!-- Point popup. -->
        <template v-slot:popup>
            <!-- Toggle type buttons. -->
            <base-button
                v-for="type in types"
                :key="type"
                btn11
                :badge="Object.entries({
                    'fas': true,
                    'fa-map-marker-alt': type === types.start,
                    'fa-flag-checkered': type === types.meta,
                    'fa-stopwatch': type === types.checkpoint,
                    'fa-utensils': type === types.buffet,
                    'fa-undo': type === types.return,
                    'fa-exchange-alt': type === types.change,
                    'fa-users': type === types.fans,
                    'fa-id-card': type === types.office,
                    'fa-parking': type === types.parking
                }).filter(([cls, x]) => x).map(([cls]) => cls).join(' ')"
                :class="{
                    'button': true,
                    'button--enabled': hasType(type) && hasSelectedClassification(type),
                    'button--disabled': hasType(type) && !hasSelectedClassification(type),
                }"
                @click="toggleType(type)"
            >
                {{ typesNames[type] }}
            </base-button>

            <div class="line mat4" />

            <!-- Delete button. -->
            <base-button
                btn11
                class="button mat4"
                badge="fas fa-trash-alt"
                @click="$emit('delete-point', point)"
            >
                Usuń punkt
            </base-button>
        </template>
    </base-map-marker>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TRACKPOINT_TYPES } from '@/config';
import { remove } from '@/utils/array';
import {
    UPDATE_LOCATION,
    UPDATE_TRACKPOINT,
    CREATE_TRACKPOINT,
    DELETE_TRACKPOINT,
} from '@/store/actions.type';

export default {
    props: {
        point: {
            type: Object,
            required: true,
        },
        competitionId: {
            type: Number,
            default: null,
        },
        selectedClassificationId: {
            type: Number,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popup: false,
            types: TRACKPOINT_TYPES,
            typesNames: {
                [TRACKPOINT_TYPES.start]: 'Start',
                [TRACKPOINT_TYPES.meta]: 'Meta',
                [TRACKPOINT_TYPES.checkpoint]: 'Punkt kontrolny',
                [TRACKPOINT_TYPES.buffet]: 'Bufet',
                [TRACKPOINT_TYPES.return]: 'Nawrót',
                [TRACKPOINT_TYPES.change]: 'Strefa zmian',
                [TRACKPOINT_TYPES.fans]: 'Strefa kibica',
                [TRACKPOINT_TYPES.office]: 'Biuro zawodów',
                [TRACKPOINT_TYPES.parking]: 'Parking',
            },
        };
    },
    computed: {
        ...mapGetters([
            'trackpoints',
            'locations',
            'competitions',
            'classificationsBy',
        ]),
        competition() {
            return this.competitions[this.competitionId];
        },
        classifications() {
            return this.classificationsBy(this.competition);
        },
        selectedClassification() {
            return this.$store.getters.classifications[
                this.selectedClassificationId
            ];
        },
        location() {
            return this.locations[this.point.location];
        },
    },
    methods: {
        ...mapActions([
            UPDATE_LOCATION,
            UPDATE_TRACKPOINT,
            CREATE_TRACKPOINT,
            DELETE_TRACKPOINT,
        ]),
        hasType(type) {
            return this.point.trackpoints[type] !== undefined;
        },
        hasSelectedClassification(type) {
            const trackpointId = this.point.trackpoints[type];
            const trackpoint = this.trackpoints[trackpointId];
            if (this.selectedClassification) {
                return trackpoint.classifications.includes(
                    this.selectedClassification.id,
                );
            }
            return (
                trackpoint.classifications.length
                === this.classifications.length
            );
        },
        async toggleType(type) {
            const trackpointId = this.point.trackpoints[type];
            const trackpoint = this.trackpoints[trackpointId];

            if (trackpoint) {
                let classifications;

                if (this.selectedClassification) {
                    if (this.hasSelectedClassification(type)) {
                        classifications = remove(
                            trackpoint.classifications,
                            this.selectedClassification.id,
                        );
                    } else {
                        classifications = trackpoint.classifications.concat(
                            this.selectedClassification.id,
                        );
                    }
                } else if (this.hasSelectedClassification(type)) {
                    classifications = [];
                } else {
                    classifications = this.classifications.map((c) => c.id);
                }

                if (classifications.length > 0) {
                    await this.UPDATE_TRACKPOINT({
                        ...trackpoint,
                        classifications,
                    });
                } else {
                    await this.DELETE_TRACKPOINT(trackpoint.id);
                }
            } else {
                await this.CREATE_TRACKPOINT({
                    location: this.location.id,
                    type,
                    classifications: this.selectedClassification
                        ? [this.selectedClassification.id]
                        : this.classifications.map((c) => c.id),
                });
            }
        },
        async updateLocationLatLng(latLng) {
            await this.UPDATE_LOCATION({
                ...this.location,
                ...latLng,
            });
        },
    },
};
</script>

<style>
.icon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon--meta::before {
    content: "\f11e";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--start::before {
    content: "\f3c5";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--checkpoint::before {
    content: "\f2f2";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--return::before {
    content: "\f0e2";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--change::before {
    content: "\f362";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--fans::before {
    content: "\f0c0";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--office::before {
    content: "\f2c2";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--parking::before {
    content: "\f540";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}
.icon--buffet::before {
    content: "\f2e7";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
}

.button {
    justify-content: flex-start !important;
    padding-left: 12px !important;
    color: white;
    opacity: 1;
    font-family: "Open Sans";
    font-size: 14px;
    border-radius: 4px;
    user-select: none;
    margin-bottom: 8px;
}
.button--enabled {
    background-color: var(--color-dark-pastel-green);
}
.button--disabled {
    background-color: var(--color-bluish-grey);
}
.button--disabled:hover {
    background-color: var(--color-bluish-grey) !important;
}
.button--enabled:hover {
    background-color: var(--color-dark-pastel-green) !important;
}
.button:not(.button--enabled):hover {
    background-color: var(--color-bluish-grey) !important;
}
.line {
    border-top: 1px solid var(--color-bluish-grey);
}
.leaflet-popup-tip-container .leaflet-popup-tip {
    background: var(--color-slate-two);
}
</style>
