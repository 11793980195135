import classification from '@/api/schema/classification';
import gate from '@/api/schema/gate';
import { schema } from 'normalizr';

export default new schema.Entity('checkpoints', {
    classification,
    gates: [gate],
}, {
    processStrategy: (checkpoint) => ({
        // Meta.
        _type: 'checkpoint',
        // Fields.
        ...checkpoint,
    }),
});
