var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "switch-slot": _vm.label, icons: _vm.icons } }, [
    _c(
      "label",
      { staticClass: "switch" },
      [
        _c(
          "input",
          _vm._b(
            {
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.checked },
              on: {
                change: function ($event) {
                  return _vm.$emit("input", $event.target.checked)
                },
              },
            },
            "input",
            _vm.$attrs,
            false
          )
        ),
        _c("span", { staticClass: "check" }),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }