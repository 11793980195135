var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sticky",
      class: {
        "sticky--bottom": _vm.showInstallMessageOnIOS || _vm.promptEvent,
      },
    },
    [
      _vm.isOrganizerPanel && _vm.isNotification
        ? _c(
            "div",
            {
              staticClass: "prompt",
              class: { "prompt--ios": _vm.showInstallMessageOnIOS },
            },
            [
              _c(
                "div",
                {
                  staticClass: "prompt__cancel-ios",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.dismiss.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/cancel-icon.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "row w100" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm.promptEvent
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          on: { click: _vm.showPrompt },
                        },
                        [
                          _c("img", {
                            staticClass: "prompt__icon",
                            attrs: {
                              src: require("@/assets/images/AppIconWithBackground.png"),
                              alt: "Logo",
                            },
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "prompt__description",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " Dodaj aplikację DO:ST:AR:TU do ekranu głównego "
                              ),
                            ]
                          ),
                          _c("i", {
                            staticClass: "fas fa-times prompt__close",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.dismiss.apply(null, arguments)
                              },
                            },
                          }),
                        ]
                      )
                    : _vm.showInstallMessageOnIOS
                    ? _c("div", { staticClass: "prompt__ios-message" }, [
                        _c("img", {
                          staticClass: "prompt__ios-logo",
                          attrs: {
                            src: require("@/assets/images/dostartu-icon.png"),
                          },
                        }),
                        _vm._m(0),
                      ])
                    : _vm.isInstalledInfo
                    ? _c("div", [
                        _vm._v(" Pomyślnie zainstalowano aplikacje. "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "prompt__ios-title" }, [
        _vm._v(" Zainstaluj dostartu jako aplikację "),
      ]),
      _c("div", { staticClass: "d-flex" }, [
        _c("p", [_vm._v("1. Tapnij ikonę")]),
        _c("img", {
          attrs: { src: require("@/assets/images/ios-share-icon.svg") },
        }),
      ]),
      _c("div", { staticClass: "d-flex" }, [
        _c("p", [_vm._v("2. Dodaj do ekranu głównego")]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/ios-icon-add-app.svg"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }