<template>
  <div class="competition-edit ml-md-30">
    <div v-if="!ready" class="row mat32">
        <div class="col-12">
            <div class="admin-header__loading-state">
                Trwa wczytywanie
                <div class="mal15 lds-ellipsis">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    </div>    
    <template v-if="ready">
      <panel-competition-form-box >
        <div class="row w100">
          <div class="col-12">
            <p>Regulamin zawodów</p>
          </div>
          <div class="col-12 col-md-8">
            <div class="radios2">
              <input type="radio" id="statute_type_file" v-model="statute_type" value="file"><label for="statute_type_file" style="line-height:32px;">Wgraj plik</label><br>
              <div class="hrline-left fileinput mab16 jsStatuteFile" :class="{ disabled: statute_type === 'link' }">
                <div class="row">
                  <div :class="[ isThereAStatute ? 'col-9' : 'col-12' ]">
                    <base-input-file
                      v-model="statuteList"
                      :isMultiple="false"
                      accept=".pdf"
                      hint="Wybierz plik"
                      :disabled="statute_type === 'link'"
                    />
                  </div>
                  <div v-if="isThereAStatute" col="col-3">
                    <base-button btn2 @click="removeFile" style="height: 40px;box-sizing: border-box;">Usuń plik</base-button>
                  </div>
                </div>
              </div>
              <input type="radio" id="statute_type_link" v-model="statute_type"  value="link"><label for="statute_type_link" style="line-height:32px;">Regulamin pod linkiem</label>
              <div class="hrline-left mab8 jsStatuteLink" :class="{ disabled: statute_type === 'file' }">
                <base-input-text
                  v-model="statute_link"
                  without-error-message
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="competition-content ml-md-30">
              <template v-if="isThereAStatute">
                <p>Podgląd pliku</p>
                <a :href="competition.statuteFilePl" target="_blank" rel="noopener noreferrer">Regulamin zawodów</a>
              </template>
              <template v-else>
                <p>Dodaj regulamin w pliku PDF lub podaj link do regulaminu na zewnętrznej stronie.</p>
                <p>Link może prowadzić do strony html lub bezpośrednio do pliku z regulaminem.</p>
              </template>
            </div>
          </div>
        </div>
      </panel-competition-form-box>
      <div class="col-lg-12 mat16 mab64">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-4">
            <base-button btn1 @click="save">Zapisz</base-button>
          </div>
        </div>
      </div>
    </template>  
  </div>
</template>

<script>
import axios from 'axios';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';

export default {
  name: 'PanelCompetitionSettingRegulation',
  components: {
    PanelCompetitionFormBox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
  },   
  data() {
    return {
      ready: false,
      statute_type: 'file',
      statuteList: [],
      statute_link: '',
      isThereAStatute: false,
    }
  },
  created() {
    this.isThereAFile()
    if (this.competition.statuteLinkPl) {
      this.statute_type = 'link'
      this.statute_link = this.competition.statuteLinkPl
    }
  },
  methods: {
    async postFile() {
      await axios({
          method: 'post',
          url: `panel/competitions/${this.id}/files/statute`,
          data: this.statuteList[0]
      })
      this.isThereAStatute = true
      this.statuteList = []
      this.statute_link = null;
      this.putLink()
    },
    async removeFile() {
      await axios({
          method: 'delete',
          url: `panel/competitions/${this.id}/files/statute`,
      })
      this.isThereAStatute = false
    },
    async isThereAFile() {
      const response = await axios({
          method: 'get',
          url: `panel/competitions/${this.id}/files/statute`,
      })
      this.isThereAStatute = response 
      this.ready = true
    },
    async putLink() {
      await axios({
          method: 'put',
          url: `panel/competitions/${this.id}`,
          data: {
            statuteLinkPl: this.statute_link
          }
      })
      if(this.isThereAStatute && this.statute_type === 'link') {
        this.removeFile()
      }
      this.competition.statuteLinkPl = this.statute_link
    },
    save() {
      if (this.statute_type === 'file') {
        this.postFile()
      } else if (this.statute_type === 'link') {
        this.putLink()
      }

    }
  }
}
</script>

<style scoped>
.competition-edit {width: 100%;}
.competition-edit >>> .input-field {height: 40px;min-height: auto;}
.competition-edit .disabled {pointer-events: none;}
.competition-content p {line-height: 18px;margin-bottom: 16px;}

.radios2 input[type=radio] { display: none; }
.radios2 input[type=radio] + label { padding-left: 32px; display: inline-block; cursor: pointer; position: relative; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.radios2 input[type=radio] + label:before { content: ""; box-sizing: border-box; transition: all 0.1s ease-in-out; display: inline-block; width: 20px; height: 20px; margin: 6px; position: absolute; left: 0; top: 1px; z-index:2; border: 1px solid #fff; border-radius: 20px; background-color: transparent; }
.radios2 input[type=radio]:checked + label:before { background-color: #ff3d00; border-width: 5px; }
.radios2.error input[type=radio]:checked + label:after { content: ""; transition: all 0.1s ease-in-out; display: inline-block; width: 22px; height: 22px; margin: 5px; position: absolute; left: 0; top: 1px; z-index:2; border: 2px solid #ff3d00; border-radius: 22px;  }
.hrline-left { padding-left: 16px; margin-left: 16px; border: 0 solid #78909c; border-left-width: 1px; }

@media (min-width: 992px) {
  .ml-md-30 {
    margin-left: 30px;
  }
}
</style>
