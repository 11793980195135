<template>
    <div class="chart-players">
        <div class="chart-players__info">
            <p class="chart-players__info-value">
                {{ summary }}
            </p>
            <p class="chart-players__info-text">
                {{ description }}
            </p>
        </div>
        <canvas
            ref="ctx"
            width="262"
            height="262"
        />
        <p
            v-if="isMobile"
            class="chart-players__hint"
        >
            {{ description }}
        </p>
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
    props: {
        labels: {
            type: Array,
            required: true,
        },
        backgroundColors: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        summary: {
            type: Number,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    computed: {
        isMobile() {
            return ['sm', 'md'].includes(this.$mq);
        },
        dataChart() {
            if (this.summary === 0) {
                return [1];
            }
            return this.data;
        },
    },
    mounted() {
        const el = this.$refs.ctx;
        this.chart = new Chart(el, {
            type: 'doughnut',
            data: {
                labels: this.labels,
                datasets: [
                    {
                        data: this.dataChart,
                        backgroundColor: this.backgroundColors,
                        borderWidth: 4,
                        borderColor: '#37474f',
                        hoverBorderColor: '#37474f',
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                cutoutPercentage: 60,
                tooltips: {
                    enabled: !!this.summary,
                },
            },
        });
    },
};
</script>

<style scoped>
.chart-players {
    position: relative;
    display: flex;
}
.chart-players__info {
    position: absolute;
    display: flex;
    color: var(--color-silver);
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 140px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.chart-players__info-value {
    font-size: 32px;
    font-family: 'Bariol Bold';
}
.chart-players__info-text {
    display: none;
}
.chart-players__hint {
    position: absolute;
    width: 100%;
    bottom: -35px;
    text-align: center;
}
@media (min-width: 576px) {
    .chart-players__info-value {
        font-size: 48px;
    }
}
@media (min-width: 992px) {
    .chart-players__info-value {
        font-size: 72px;
    }
    .chart-players__info-text {
        display: inline-block;
    }
}
</style>
