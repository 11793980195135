import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from '@/router';

import { GOOGLE_ANALYTICS_KEY } from '@/config';

Vue.use(
    VueGtag,
    {
        config: { id: GOOGLE_ANALYTICS_KEY },
    },
    router,
);
