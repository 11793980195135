<template>
    <div
        class="input-field input-field--focus-bolder overflow-hidden"
        :class="{'input-field--error' : error}"
    >
        <label
            class="input-field__label"
            :class="{'input-field__label--show' : isInput}"
        >{{ label }}</label>

        <textarea
            :placeholder="placeholder"
            class="input-field__textarea"
            :class="{'input-field__textarea--padding-top': !label}"
            :rows="rows"
            :value="value"
            @input="$emit('input', $event.target.value)"
        />

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        rows: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
