import { schema } from 'normalizr';

export default new schema.Entity(
    'limits',
    {},
    {
        idAttribute: (limit) => (limit.competition ? limit.competition : limit.classification),
        processStrategy: (limits) => ({
            // Meta.
            _type: 'limits',
            // Fields.
            ...limits,
        }),
    },
);
