var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "progress" },
    _vm._l(_vm.items, function (item, index) {
      return _c("li", {
        key: index,
        staticClass: "progressItem",
        class: { active: _vm.items[index] === _vm.current },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }