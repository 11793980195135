import { schema } from 'normalizr';

export default new schema.Entity('categories', {}, {
    processStrategy: (category) => ({
        // Meta.
        _type: 'category',
        // Fields.
        ...category,
    }),
});
