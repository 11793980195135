import { schema } from 'normalizr';

export default new schema.Entity('gpxFiles', {}, {
    idAttribute: 'classification', // one to one relation
    processStrategy: (gpxFile) => ({
        // Meta.
        _type: 'gpxFile',
        // Fields.
        ...gpxFile,
    }),
});
