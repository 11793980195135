import { schema } from 'normalizr';

export default new schema.Entity('familyAssociations', {}, {
    processStrategy: (familyAssociation) => ({
        // Meta.
        _type: 'familyAssociation',
        // Fields.
        ...familyAssociation,
    }),
});
