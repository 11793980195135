var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columnWrap" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-items-center",
        class: { inactive: !_vm.familyAssociation.enabled },
      },
      [
        _c("div", { staticClass: "family-child__img" }, [
          _c("strong", [
            _vm._v(
              _vm._s(_vm.parent.firstname.charAt(0)) +
                _vm._s(_vm.parent.lastname.charAt(0))
            ),
          ]),
        ]),
        _c(
          "p",
          { staticClass: "ma0i" },
          [
            _c("big", { staticClass: "mar15" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.parent.firstname) +
                  " " +
                  _vm._s(_vm.parent.lastname) +
                  " "
              ),
            ]),
            _vm._v(" " + _vm._s(_vm.parent.city) + " "),
          ],
          1
        ),
      ]
    ),
    _vm.familyAssociation.pending
      ? _c(
          "div",
          { staticClass: "btnWrap" },
          [
            _c(
              "base-button",
              {
                staticStyle: { width: "100%" },
                attrs: { btn1: "" },
                on: {
                  click: function ($event) {
                    return _vm.ENABLE_FAMILY(_vm.familyAssociation)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.say.accept_invitation))]
            ),
            _c(
              "base-button",
              {
                staticStyle: {
                  "box-shadow": "none",
                  "padding-left": "21px",
                  "padding-right": "21px",
                },
                attrs: { btn7: "" },
                on: {
                  click: function ($event) {
                    return _vm.DISABLE_FAMILY(_vm.familyAssociation)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.say.reject_invitation))]
            ),
          ],
          1
        )
      : _vm.familyAssociation.enabled
      ? _c(
          "div",
          { staticClass: "btnWrap" },
          [
            _c(
              "base-button",
              {
                staticStyle: {
                  "box-shadow": "none",
                  "padding-left": "21px",
                  "padding-right": "21px",
                },
                attrs: { btn7: "" },
                on: {
                  click: function ($event) {
                    return _vm.DISABLE_FAMILY(_vm.familyAssociation)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.say.disable_registration_by_parent))]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "btnWrap" },
          [
            _c(
              "base-button",
              {
                staticStyle: { width: "100%" },
                attrs: { btn1: "" },
                on: {
                  click: function ($event) {
                    return _vm.ENABLE_FAMILY(_vm.familyAssociation)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.say.accept_invitation))]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }