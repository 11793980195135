var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { class: _vm.leftSideHeaderClasses },
          [
            _vm._t("left", function () {
              return [
                _c("transition", { attrs: { name: "home-top__counter" } }, [
                  _vm.$route.name === "main-landing"
                    ? _c("div", { staticClass: "row home-top__counter" }, [
                        _c("div", { staticClass: "col-12 home-top__logo" }, [
                          _c("h1", {
                            staticClass: "home-top__info",
                            domProps: { innerHTML: _vm._s(_vm.headTitle) },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { ref: "rightSideHeader", class: _vm.rightSideHeaderClasses },
          [
            _vm.isMainSite
              ? _c(
                  "div",
                  {
                    class: _vm.rightSideHeaderHeightPhoto,
                    style: _vm.backgroundMainSite,
                  },
                  [
                    _c("img", {
                      staticClass: "background__image",
                      attrs: {
                        src: require("../assets/images/photo@3x.jpg"),
                        alt: "operator photo",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._t("right"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }