<template>
    <div class="container">
        <div class="row organizer-hint">
            <div class="col-12">
                <h1 class="organizer-hint__title">
                    Witaj w panelu organizatora zawodów!
                </h1>
                <base-button
                    btn1
                    class="organizer-hint__button"
                    @click="createDefaultCompetition()"
                >
                    Utwórz przykładowe zawody
                </base-button>
                <p class="organizer-hint__or">
                    lub
                </p>
                <base-button
                    btn4
                    class="organizer-hint__button organizer-hint__button--self"
                    @click="
                        $router.push({
                            name: 'panel-create-competition',
                            params: {
                                organizerId: organizerId,
                            },
                            alias: true,
                        })
                    "
                >
                    Dodaj swoje zawody
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { CREATE_DEFAULT_COMPETITION } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        organizerId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['competitions']),
    },
    methods: {
        ...mapActions([CREATE_DEFAULT_COMPETITION]),
        async createDefaultCompetition() {
            const competitionId = await this.CREATE_DEFAULT_COMPETITION(
                this.organizerId,
            );
            this.$router.push({
                name: 'panel-competition-dashboard',
                params: this.competitions[competitionId],
                alias: true,
            });
        },
    },
};
</script>

<style scoped>
.organizer-hint {
    margin-top: 48px;
    background-color: var(--color-slate);
    padding: 32px 16px;
    border-radius: 4px;
}
.organizer-hint__title {
    text-align: center;
    margin-bottom: 16px;
}
.organizer-hint__button {
    margin: auto;
    max-width: 320px;
}
.organizer-hint__or {
    text-align: center;
    margin: 16px 0;
}
.organizer-hint__button--self {
    color: white;
}
@media (min-width: 992px) {
    .organizer-hint__or {
        margin: 32px 0;
    }
    .organizer-hint__title {
        margin-bottom: 32px;
    }
}
</style>
