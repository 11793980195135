var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "resize-parent",
      staticClass: "rangeSliderItemWrap",
      class: { active: _vm.isActive, hover: _vm.hover },
    },
    [
      _c(
        "div",
        {
          ref: "resizable-element",
          staticClass: "rangeSlider__item",
          style: _vm.styles,
          attrs: { id: `panel-${_vm.index}` },
          on: {
            click: _vm.itemHandle,
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "rangeSlider__text",
              class: { active: _vm.isActive || _vm.hover },
            },
            [_vm._v(_vm._s(_vm.dateRangeText))]
          ),
          _vm.isActive && _vm.hover
            ? _c("span", { staticClass: "rangeSlider__icon" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icons/range-slider/close.svg"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
          !_vm.isActive && _vm.hover
            ? _c("span", { staticClass: "rangeSlider__icon" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icons/range-slider/plus.svg"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }