var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("imask-input", {
    staticClass: "input-mask input-mask--border",
    attrs: {
      value: _vm.value,
      mask: _vm.mask,
      unmask: false,
      placeholder: _vm.placeholder,
      overwrite: true,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      accept: _vm.onAccept,
      complete: _vm.onComplete,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }