import { DNF_TIME, DNS_TIME, DSQ_TIME } from '@/config';
import Vue from 'vue';
import moment from 'moment';
import durationFormatSetup from 'moment-duration-format';

durationFormatSetup(moment);

Vue.filter('asStoper', (time) => {
    if (!time) {
        return '';
    }

    if (time.valueOf() === DNS_TIME) {
        return 'DNS';
    }

    if (time.valueOf() === DNF_TIME) {
        return 'DNF';
    }

    if (time.valueOf() === DSQ_TIME) {
        return 'DSQ';
    }

    if (moment.isDuration(time)) {
        if (!time.as('ms')) {
            return '';
        }
        return time.format('HH:mm:ss.SS', {
            trim: false,
        });
    }

    return moment.duration(time).format('HH:mm:ss.SS', { trim: false });
});
