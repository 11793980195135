var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.classButton,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          !_vm.disabled && _vm.$emit("click")
        },
        mouseover: function ($event) {
          return _vm.$emit("mouseover")
        },
        mouseleave: function ($event) {
          return _vm.$emit("mouseleave")
        },
      },
    },
    [
      _vm.loading
        ? _c("div", { class: _vm.classAnimation }, [
            _c("div"),
            _c("div"),
            _c("div"),
            _c("div"),
          ])
        : _vm.btn8
        ? [
            _c("p", [_vm._t("default")], 2),
            _c("i", { staticClass: "fab fa-facebook-square btn__icon" }),
          ]
        : [
            _vm.badge
              ? _c("i", {
                  class: [_vm.badge, "f18", { mar10: !_vm.isEmptySlot }],
                })
              : _vm._e(),
            _vm._t("default"),
            _vm.icon
              ? _c("i", { staticClass: "mal12 f18", class: _vm.icon })
              : _vm._e(),
            _vm.novelty
              ? _c("p", { staticClass: "btn__novelty" }, [_vm._v(" nowość ")])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }