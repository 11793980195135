import competitionSetting from '@/api/schema/competition-setting';
import cycle from '@/api/schema/cycle';
import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('competitions', {
    competitionSetting,
    cycle,
}, {
    processStrategy: (competition) => ({
        // Meta.
        _type: 'competition',
        // Fields.
        ...competition,
        // Cast types.
        provisionTime: moment(competition.provisionTime),
        startedTime: moment(competition.startedTime),
        preStartedTime: competition.preStartedTime ? moment(competition.preStartedTime) : null,
    }),
});
