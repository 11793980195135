<template>
    <div
        class="row chrono-classification mat8"
        :class="{'chrono-classification--disabled': !checkpoint}"
    >
        <!-- Classification name. -->
        <div
            class="col-lg-8 align-items-center"
            :class="{'disabled': !checkpoint}"
        >
            {{ classification.namePl }}
        </div>
        <div class="col-lg-4 d-flex align-items-center justify-content-end">
            <!-- Brutto start time.  -->
            <!-- <base-input-text
                v-if="checkpoint && checkpoint.number === 0"
                v-model.lazy="startTime"
                class="chrono-classification__input"
                label="DD.MM.RRRR GG:MM:SS"
            /> -->
            <div class="event-wrap" v-if="checkpoint && checkpoint.number === 0">
                <base-input-mask
                    v-model.lazy="startTime"
                    mask="00.00.0000 00:00:00"
                    placeholder="DD.MM.RRRR GG:MM:SS"
                    class="chrono-classification__input"
                />
                <img v-if="loading" class="event-wrap-img" src="@/assets/images/icons/loading.svg" />
                <img v-if="saved"  class="event-wrap-img" src="@/assets/images/icons/checkmark-circle.svg" />
            </div>
            <!-- Checkbox. -->
            <div
                class="chrono-classification__checkbox"
                :class="{'chrono-classification__checkbox--disabled': checkpoint && (isStart || isMeta)}"
                @click="!checkpoint ? ENABLE_CHECKPOINT({ classification, checkpointGroup }) : isStart || isMeta ? '' : DISABLE_CHECKPOINT(checkpoint)"
            >
                <i
                    class="fas"
                    :class="[checkpoint ? 'fa-check-square' : 'fa-square']"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import {
    UPDATE_CLASSIFICATION,
    ENABLE_CHECKPOINT,
    DISABLE_CHECKPOINT,
} from '@/store/actions.type';

export default {
    props: {
        checkpointName: {
            type: String,
            required: true,
        },
        classificationId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            saved: false,
        };
    },
    computed: {
        ...mapGetters(['classifications', 'checkpointGroups', 'checkpointsBy']),
        classification() {
            return this.classifications[this.classificationId];
        },
        checkpointGroup() {
            // Should be excatly one checkpoint group with this name.
            const matchingName = (checkpointGroup) => checkpointGroup.name === this.checkpointName;

            return this.checkpointGroups.find(matchingName);
        },
        checkpoint() {
            // Should be excatly zero or one checkpoint in this classification in this checkpoint group.
            const matchingClassification = (checkpoint) => checkpoint.classification === this.classificationId;

            return this.checkpointsBy(this.checkpointGroup).find(
                matchingClassification,
            );
        },
        isStart() {
            return this.checkpointGroup.number === 0;
        },
        isMeta() {
            return this.checkpointGroup.number === 1;
        },
        startTime: {
            get() {
                return this.classification.startTime
                    ? this.classification.startTime.format(
                        'DD.MM.YYYY HH:mm:ss',
                    )
                    : '';
            },
            set(value) {
                const startTime = moment(value, 'DD.MM.YYYY HH:mm:ss', true);
                
                if (
                    startTime.isValid()
                    && !startTime.isSame(this.classification.startTime)
                ) {
                    this.loading = true
                    this.UPDATE_CLASSIFICATION({
                        ...this.classification,
                        startTime,
                    }).then(() => {
                        this.loading = false
                        this.saved = true
                        setTimeout(() => {
                            this.saved = false
                        }, 2000)
                    });
                }
            },
        },
    },
    methods: {
        ...mapActions([
            UPDATE_CLASSIFICATION,
            ENABLE_CHECKPOINT,
            DISABLE_CHECKPOINT,
        ]),
    },
};
</script>

<style scoped>
.chrono-classification {
    border-radius: 4px;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--color-slate-two);
    justify-content: flex-start;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    height: 48px;
    padding: 4px 12px 4px 16px;
}
.chrono-classification--disabled {
    background-color: #4c646f !important; /* color-slate-two with 50% opacity */
}
.chrono-classification__input {
    min-height: 40px;
    max-height: 40px;
}
.chrono-classification__checkbox {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
}
.chrono-classification__checkbox--disabled {
    cursor: default;
    opacity: 0.5;
}
.chrono-content__classification--disabled {
    opacity: 0.5;
}
.input-field__icons {
    color: var(--color-slate);
    align-items: center;
    display: flex;
    font-size: 18px;
}
.chrono-classification__input >>> .input-field__label {
    top: 0;
}
.chrono-classification__input >>> .input-field__input--padding-top {
    padding-top: 13px;
}

.event-wrap {
    position: relative;
    width: 100%;
}
.event-wrap-img {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 11px;
    right: 11px;
}
</style>
