import api from '@/api';
import {
    READ_USER_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATIONS,
    CREATE_USER_NOTIFICATIONS,
} from '@/store/actions.type';
import {
  SET_NOTIFICATIONS,
  SET_CREATE_NOTIFICATIONS,
} from '@/store/mutations.type';
export default {
    state: {
      notifications: {
        "proposedCompetition": false,
        "incomingCompetition": false,
        "myResults": false,
        "deviceToken": null,
      },
      createNotifications: false
    },
    getters: {
      notifications: (state) => state.notifications,
      createNotifications: (state) => state.createNotifications,
    },
    mutations: {
      [SET_NOTIFICATIONS](state, payload) {
        state.notifications = payload;
      },
      [SET_CREATE_NOTIFICATIONS](state, payload) {
        state.createNotifications = payload;
      },
    },
    actions: {
      async [READ_USER_NOTIFICATIONS]({ commit }) {
        const data = await api.get(`/user/notifications`);
        if(data === null) {
          commit(SET_CREATE_NOTIFICATIONS, true);
        } else {
          commit(SET_NOTIFICATIONS, data);
        }

        return data;
      },
      async [CREATE_USER_NOTIFICATIONS]({ commit }, notifications) {
        await api.post(`/user/notifications`, notifications);
        
        commit(SET_NOTIFICATIONS, notifications);
        commit(SET_CREATE_NOTIFICATIONS, false);

        return notifications;
      },      
      async [UPDATE_USER_NOTIFICATIONS]({ commit }, notifications) {
        await api.put(`/user/notifications`, notifications);
        
        commit(SET_NOTIFICATIONS, notifications);
        
        return notifications;
      },      
    }
};
