<template>
    <div
        v-if="ready"
        class="container"
    >
        <h1 class="remote-competition__title">
            {{ say.add_your_result }}
        </h1>

        <div class="row">
            <!-- Sidebar. -->
            <div class="col-12 col-lg-4">
                <div class="row">
                    <h2>{{ player.firstname }} {{ player.lastname }}</h2>
                </div>
                <template v-if="competition.id !== 5165">
                    <div class="row person__birthdate flex-column">
                        <p>{{ say.birthtime }}</p>
                        <strong>{{ player.birthTime | asDate }}</strong>
                    </div>
                    <div class="row person__location flex-column">
                        <p>{{ say.city }}</p>
                        <strong>{{ player.city }}</strong>
                    </div>
                    <div class="row person__email flex-column">
                        <p>{{ say.email_address }}</p>
                        <strong>{{ player.email }}</strong>
                    </div>
                    <div class="row person__classification flex-column">
                        <p>{{ say.classification }}</p>
                        <strong>{{ classification.namePl }}</strong>
                    </div>
                </template>
            </div>

            <!-- View. -->
            <div class="col-12 col-lg-8">
                <div class="remote-competition__box bg--gray">
                    <div class="row">
                        <div class="col-12">
                            <h2 v-if="config.isTime">
                                {{ say.your_time }}
                            </h2>
                            <h2 v-else>
                                {{ say.your_distance }}
                            </h2>
                        </div>
                    </div>

                    <!-- Declared time. -->
                    <div
                        v-if="config.isTime"
                        class="row remote-competition__box-time"
                    >
                        <div class="col-12 col-lg-6">
                            <base-input-time
                                v-model="declaredTime"
                                label="Twój czas"
                                :error.sync="errors.declaredTime"
                            />
                        </div>
                    </div>

                    <!-- Declared distance. -->
                    <div
                        v-if="config.isDistance"
                        class="row remote-competition__box-distance"
                    >
                        <div class="col-12 col-lg-6">
                            <base-input-text
                                v-model="declaredDistance"
                                :label="say.distance + ' [km]'"
                                :error.sync="errors.declaredDistance"
                                hint="00.0"
                            />
                        </div>
                    </div>
                    <!-- Declared file. -->
                    <div
                        v-if="config.isFile"
                        class="row remote-competition__box-file"
                    >
                        <div class="col-12 col-lg-5 remote-competition__hint">
                            <p v-if="competition.id === 5165">
                                Dodaj plik który umożliwi organizatorowi
                                weryfikację twojego wyniku np. widok z
                                Endomondo.
                            </p>
                            <p v-else>
                                {{ say.add_file_to_verify }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-6">
                            <base-input-filearea
                                v-model="files"
                                :error.sync="errors.files"
                            />
                        </div>
                    </div>

                    <!-- Declared description. -->
                    <div class="row remote-competition__box-description">
                        <div class="col-12 col-lg-5 remote-competition__hint">
                            {{ say.you_can_add_description }}
                        </div>
                        <div class="col-12 col-lg-6">
                            <base-input-area
                                v-model="declaredDescription"
                                :rows="10"
                                :label="say.description"
                                :error.sync="errors.declaredDescription"
                            />
                        </div>
                    </div>
                </div>

                <div
                    v-if="config.isAddress"
                    class="shipping-address bg--gray mat24"
                >
                    <div class="row">
                        <p>{{ say.shipping_address }}</p>
                    </div>

                    <!-- Street. -->
                    <div class="row shipping-address__street">
                        <div class="col-12 col-lg-5 shipping-address__hint">
                            {{ say.add_address_hint }}
                        </div>
                        <div class="col-12 col-lg-6">
                            <base-input-text
                                v-model="addressInfo.street"
                                :label="say.street"
                                :error.sync="errors.street"
                            />
                        </div>
                    </div>

                    <!-- House number and flat number. -->
                    <div class="row shipping-address__number">
                        <div class="col-6 col-lg-3">
                            <base-input-text
                                v-model="addressInfo.houseNumber"
                                class="mar15"
                                :label="say.house_number"
                                :error.sync="errors.houseNumber"
                            />
                        </div>
                        <div class="col-6 col-lg-3">
                            <base-input-text
                                v-model="addressInfo.flatNumber"
                                class="mal15"
                                :label="say.flat_number"
                                :error.sync="errors.flatNumber"
                            />
                        </div>
                    </div>

                    <!-- City. -->
                    <div class="row shipping-address__location">
                        <div class="col-12 col-lg-6">
                            <base-input-text
                                v-model="addressInfo.city"
                                :label="say.city"
                                :error.sync="errors.city"
                            />
                        </div>
                    </div>

                    <!-- Zip code. -->
                    <div class="row shipping-address__zip-code">
                        <div class="col-6 col-lg-3">
                            <base-input-text
                                v-model="addressInfo.postcode"
                                class="mar15"
                                :label="say.zip_code"
                                :error.sync="errors.postcode"
                            />
                        </div>
                    </div>

                    <!-- Country. -->
                    <div class="row shipping-address__country">
                        <div class="col-12 col-lg-6">
                            <div class="input-field input-field--border-none">
                                <label
                                    class="input-field__label input-field__label--show"
                                >{{ say.country }}</label>
                                <base-input-select
                                    v-model="addressInfo.country"
                                    style="width: 100%"
                                    :options="countries"
                                    :clearable="false"
                                    :label="say.country"
                                    :reduce="(country) => country.id"
                                    :error.sync="errors.country"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-end">
                    <div class="col-12 col-lg-4 remote-competition__button">
                        <base-button
                            btn1
                            :loading="loading"
                            @click="addResult"
                        >
                            {{ say.add_result }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    READ_PLAYER,
    READ_CLASSIFICATION,
    READ_COMPETITION,
    BOOTSTRAP_MINISITE,
    READ_DECLARE_RESULT_CONFIG,
    DECLARE_FILES,
    DECLARE_ADDRESS,
    DECLARE_RESULT,
} from '@/store/actions.type';
import { SET_ENTITIES } from '@/store/mutations.type';
import countries from '@/assets/data/countries';
import moment from 'moment';

export default {
    props: {
        token: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            declaredDescription: '',
            declaredDistance: '',
            ready: false,
            files: [],
            errors: {},
            countries,
            loading: false,
            playerId: null,
            config: null,
            addressInfo: {},
            declaredTime: {
                hours: '',
                minutes: '',
                seconds: '',
                milliseconds: '',
            },
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'players',
            'classificationsBy',
            'competitions',
            'minisiteId',
        ]),
        player() {
            return this.players[this.playerId];
        },
        classification() {
            return this.classificationsBy(this.player)[0];
        },
        competition() {
            return this.competitions[this.minisiteId];
        },
    },
    async created() {
        try {
            // Decode player and competition from token.
            this.playerId = await this.READ_PLAYER(this.token);

            await this.READ_CLASSIFICATION(this.player.classification);
        } catch (e) {
            // Bad token.
            this.$router.push({ name: 'minisite-landing', alias: true });

            return;
        }
        const [config] = await Promise.all([
            this.READ_DECLARE_RESULT_CONFIG(this.classification.competition),
            this.BOOTSTRAP_MINISITE(this.classification.competition),
        ]);
        this.config = config;
        const {
            street = '',
            houseNumber = '',
            flatNumber = '',
            city = '',
            postcode = '',
            country = '',
        } = this.player;

        this.addressInfo = {
            street,
            houseNumber,
            flatNumber,
            city,
            postcode,
            country,
        };
        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_PLAYER,
            READ_CLASSIFICATION,
            READ_COMPETITION,
            BOOTSTRAP_MINISITE,
            READ_DECLARE_RESULT_CONFIG,
            DECLARE_FILES,
            DECLARE_ADDRESS,
            DECLARE_RESULT,
        ]),
        ...mapMutations([SET_ENTITIES]),
        async addResult() {
            this.loading = true;

            const declarePromises = [];
            if (this.config.isAddress) {
                declarePromises.push(
                    this.DECLARE_ADDRESS({
                        token: this.token,
                        address: { ...this.addressInfo },
                    }),
                );
            }
            if (this.config.isFile) {
                declarePromises.push(
                    this.DECLARE_FILES({ token: this.token, files: this.files }),
                );
            }
            const result = {
                declaredDescription: this.declaredDescription,
                declaredDistance: Number.parseFloat(
                    this.declaredDistance.replace(',', '.'),
                ),
                declaredTime: moment.duration(this.declaredTime).valueOf()
                    ? Number.parseInt(
                        moment.duration(this.declaredTime).valueOf() / 10,
                        10,
                    )
                    : null,
            };
            declarePromises.push(
                this.DECLARE_RESULT({ result, token: this.token }),
            );
            // Declare actions returns only errors.
            // Request done if errorList has null values.
            const errorList = await Promise.all(declarePromises);
            for (const error of errorList) {
                if (error) {
                    this.errors = Object.assign(this.errors, error);
                }
            }
            for (const error of Object.values(this.errors)) {
                if (error) {
                    this.loading = false;
                    return;
                }
            }

            this.loading = false;
            this.SET_ENTITIES({
                players: {
                    [this.player.id]: {
                        ...this.player,
                        // Time in panel with a precision of 2 decimal places but time in vue is with a precision of 3
                        declaredTime: result.declaredTime * 10,
                        declaredDistance: result.declaredDistance,
                    },
                },
            });
            this.$router.push({
                name: 'minisite-declare-result-confirmation',
                params: {
                    playerId: this.playerId,
                    isTimeCompetition: Number(this.config.isTime),
                },
                alias: true,
            });
        },
    },
};
</script>
