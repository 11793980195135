<template>
    <div
        class="bg-dark"
        :class="{
            'bg-dark--darken': [
                'panel-gate',
                'panel-competition-stoper',
            ].includes($route.name),
        }"
    >
        <div
            v-if="!isOnline"
            class="network-info"
        >
            <div class="row">
                <div class="col-12">
                    <p class="align-center">
                        Jesteś w trybie offline.
                    </p>
                </div>
            </div>
        </div>
        <div id="layoutBack">
            <base-notification-global />
            <layout-back-header
                v-if="$route.name !== 'panel-gate'"
                :class="{
                    'd-none': isZenMode,
                    'none-mobile': $route.name === 'panel-competition-stoper',
                }"
            />
            <div
                v-if="isMobile && isStoper"
                class="stoper-header"
            >
                <img
                    class="stoper-header__logo"
                    src="@/assets/images/logo-dostartu-white.svg"
                    alt="Logo dostartu"
                >
                <img
                    src="@/assets/images/icons/home.svg"
                    alt="home logo"
                    class="stoper-header__menu"
                    @click="TOGGLE_NAV()"
                >
            </div>
            <panel-competition-nav-mobile v-if="isNavActive" />
            <router-view
                :class="{
                    relative: !$router.matched(
                        ['panel-competition-stoper', 'panel-competition-map'],
                        $route,
                    ),
                }"
            />
        </div>

        <layout-back-footer
            v-if="$route.name !== 'panel-gate'"
            class="panel-footer"
        />
        <Communicator v-show="showCommunicator" />
    </div>
</template>

<script>
import LayoutBackHeader from '@/components/LayoutBackHeader';
import LayoutBackFooter from '@/components/LayoutBackFooter';
import Communicator from '@/components/Communicator';
import { mapGetters, mapMutations } from 'vuex';
import PanelCompetitionNavMobile from '@/components/PanelCompetitionNavMobile';
import { TOGGLE_NAV } from '@/store/mutations.type';

export default {
    components: {
        LayoutBackHeader,
        LayoutBackFooter,
        PanelCompetitionNavMobile,
        Communicator,
    },
    computed: {
        ...mapGetters(['isZenMode', 'isOnline', 'isNavActive']),
        isMobile() {
            return ['sm', 'md'].includes(this.$mq);
        },
        isStoper() {
            return this.$route.name === 'panel-competition-stoper';
        },
        showCommunicator() {
            if(this.isStoper && this.isMobile) {
                return false
            } 
            return true
        }
    },
    methods: {
        ...mapMutations([TOGGLE_NAV]),
    },
};
</script>

<style scoped>
.bg-dark {
    background-color: var(--color-charcoal-grey);
    color: var(--color-white);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.panel-footer {
    margin-top: auto;
}

.stoper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-charcoal-grey);
    height: 64px;
    padding: 0 12px;
}
.stoper-header__menu {
    background-color: var(--color-slate-two);
    padding: 4px;
    border-radius: 50%;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
    .bg-dark {
        min-height: 100vh;
    }
    .bg-dark--darken {
        background-color: #263238;
    }
}
.relative {
    position: relative;
}

.pat1 {
    padding-top: 1px;
}

.d-none {
    display: none;
}
</style>
