import transition from '@/router/guards/transition';
import { hexToRgb } from '@/utils/text';

export default {
    data() {
        return {
            layoutId: undefined,
            ready: false,
            carouselReady: false,
            carouselCompetitionsIds: undefined,
        };
    },
    computed: {
        canShowCarousel() {
            // show when carousel fetching competitions.
            if (!this.carouselReady) {
                return true;
            }
            // Mobile view need minimum 1 competition.
            if (window.innerWidth < 575 && this.carouselCompetitionsIds.length) {
                return true;
                // Tablet view need minimum 2 competitions.
            }
            if (window.innerWidth < 992 && this.carouselCompetitionsIds.length > 1) {
                return true;
                // Desktop view need minimum 3 competitions.
            }
            if (window.innerWidth >= 992 && this.carouselCompetitionsIds.length >= 3) {
                return true;
            }
            return false;
        },
        headerColor() {
            const hex = this.layout.colourDefault;
            const rgb = hexToRgb(hex).join(',');

            return `linear-gradient(to top, ${hex}, rgba(${rgb}, 0.5))`;
        },
        layout() {
            if (!this.layoutId) {
                return {
                    colourDefault: '#37474f',
                };
            }
            return this.layouts[this.layoutId];
        },
    },
    beforeRouteUpdate(to, from, next) {
        transition(to, from, next);
    },
};
