<template>
    <div
        class="radio-field"
        @change="$emit('input', $event.target.value)"
    >
        <span class="radio-field__desc">{{ label }}</span>

        <template v-for="option of options">
            <input
                :id="option.value"
                :key="option.value"
                v-model="picked"
                type="radio"
                class="d-none"
                :value="option.value"
            >
            <label
                :key="option.title"
                class="radio-field__label"
                :for="option.value"
            >{{ say[option.title] || option.title }}</label>
        </template>

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        options: {
            // [ { value: String, title: String }, ... ]
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            picked: this.value,
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
