<template>
    <div class="box-user">
        <div class="container">
            <div class="row user-header">
                <div
                    class="col-12 col-md-4 col-lg-3 d-flex justify-content-space-between align-items-center"
                >
                    <div class="d-flex align-items-center">
                        <div
                            class="user-header__img"
                            @click="$refs.avatar.click()"
                        >
                            <input
                                ref="avatar"
                                type="file"
                                class="user-header__img-input"
                                @input="inputAvatarHandler($event)"
                            >
                            <strong
                                v-if="!user.avatar"
                                class="user-header__img-initials"
                            >{{ (user.firstname || "").charAt(0)
                            }}{{ (user.lastname || "").charAt(0) }}</strong>
                            <img
                                v-else
                                class="user-header__img-avatar"
                                :src="user.avatar"
                                alt="Avatar"
                            >
                        </div>
                        <div class="user-header__name">
                            <p>
                                {{ say.hello }},
                                <br>
                                <strong class="user-header__name-text">{{
                                    user.firstname
                                }}</strong>
                            </p>
                        </div>
                    </div>
                    <!-- <div class="d-md-none text-right user-header__distance">
                        <div>
                            {{ say.your_distance }}
                            <br />
                            <h2 class="mat8">{{ user.distance }}&nbsp;km</h2>
                        </div>
                    </div>-->
                </div>
                <div
                    class="hidden-md col-12 col-md-8 overflow-hidden col-lg-9 d-flex justify-content-space-between align-items-center"
                >
                    <div class="user-header__nav">
                        <!-- Competitions button. -->
                        <base-button
                            class="user-header__nav-btn"
                            btn6
                            :class="{
                                'user-header__nav-btn--active': $router.matched(
                                    'user-competitions',
                                    $route
                                ),
                            }"
                            @click="
                                $router.push({
                                    name: 'user-competitions-future',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.competitions }}
                        </base-button>

                        <!-- Family button. -->
                        <base-button
                            class="user-header__nav-btn"
                            :class="{
                                'user-header__nav-btn--active': $router.matched(
                                    'user-family',
                                    $route
                                ),
                            }"
                            btn6
                            @click="
                                $router.push({
                                    name: 'user-family-children',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.family }}
                        </base-button>
                        
                        <!-- Insurance polices button. -->
                        <base-button
                            class="user-header__nav-btn"
                            btn6
                            :class="{
                                'user-header__nav-btn--active': $router.matched(
                                    'user-insurance',
                                    $route
                                ),
                            }"
                            @click="
                                $router.push({
                                    name: 'user-insurance-policies',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.insurance }}
                        </base-button>

                        <!-- Profile button. -->
                        <base-button
                            :class="{
                                'user-header__nav-btn--active': $router.matched(
                                    'user-edit',
                                    $route
                                ),
                            }"
                            class="d-md-none user-header__nav-btn"
                            btn6
                            @click="
                                $router.push({
                                    name: 'user-edit-profile',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.profile }}
                        </base-button>

                    </div>
                    <!-- <div class="d-none d-md-flex text-right user-header__distance">
                        <div>
                            {{ say.your_total_distance }}
                            <br />
                            <h2 class="mat8">{{ user.distance }}&nbsp;km</h2>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inputFiles from '@/mixins/input-files';
import { CREATE_USER_AVATAR } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    mixins: [inputFiles],
    computed: {
        ...mapGetters(['say', 'user']),
    },
    methods: {
        ...mapActions([CREATE_USER_AVATAR]),
        async inputAvatarHandler(event) {
            const { files } = event.target;
            const [file] = await this.createBinaryFileList(files);

            await this.CREATE_USER_AVATAR(file);
        },
    },
};
</script>

<style scoped>
.box-user {
    background-color: var(--color-pale-grey);
    min-height: 108px;
}
.user-header {
    box-sizing: border-box;
    min-height: 108px;
    padding-top: 16px;
}

.user-header__name {
    padding-left: 12px;
}

.user-header__name-text {
    display: block;
    font-family: "barb", sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding-top: 8px;
}
.user-header__nav {
    width: 100%;
    display: flex;
    margin: 16px 0 24px 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 4px;
    overflow: auto;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.user-header__nav-btn {
    width: 100%;
    min-width: 132px;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0;
    box-shadow: inset 1px 0px 0px 0px var(--color-pale-grey);
    height: 48px;
}
.user-header__nav-btn--active {
    background-color: var(--color-slate-two);
    color: var(--color-white);
}
.user-header__nav-btn--active:hover {
    background-color: var(--color-slate-two) !important;
    opacity: 1 !important;
}

.user-header__img {
    width: 64px;
    height: 64px;
    background-position: center center;
    background-size: cover;
    background-color: #fff;
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    margin-left: 16px;
    cursor: pointer;
    position: relative;
}
.user-header__img:hover::after {
    content: "";
    background-image: url("../assets/images/add-light.png");
    height: 64px;
    width: 64px;
    position: absolute;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}
.user-header__img:hover::before {
    content: "";
    height: 64px;
    width: 64px;
    background-color: rgba(237, 240, 241, 0.9);
    position: absolute;
}
.overflow-hidden {
    overflow: hidden;
}
.user-header__img-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-header__img-initials {
    font-size: 30px;
}
.user-header__img-input {
    position: absolute;
    opacity: 0;
}
@media (min-width: 768px) {
    .user-header__img:hover::after {
        background-image: url("../assets/images/add-light@3.png");
        background-size: contain;
    }
    .user-header__name-text {
        font-size: 32px;
    }
    .d-md-none {
        display: none !important;
    }
    .user-header__nav {
        margin: 0 15px;
        width: auto;
    }
    .user-header {
        padding: 0;
    }
}
@media (max-width: 992px) {
    .hidden-md {
        display: none !important;
    }
}
</style>
