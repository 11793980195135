<template>
    <div>
        <user-form-child
            v-model="newChild"
            :validation.sync="errors"
        />

        <user-form-actions
            @save="save()"
            @cancel="$router.push({
                name: 'user-family-children',
                alias: true
            })"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserFormChild from '@/components/UserFormChild';
import UserFormActions from '@/components/UserFormActions';
import { CREATE_USER_CHILD } from '@/store/actions.type';

export default {
    components: {
        UserFormChild,
        UserFormActions,
    },
    data() {
        return {
            newChild: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
    methods: {
        ...mapActions([CREATE_USER_CHILD]),
        async save() {
            this.errors = {};
            try {
                await this.CREATE_USER_CHILD(this.newChild);

                this.$router.push({
                    name: 'user-family-children',
                    alias: true,
                });
            } catch (e) {
                this.errors = e;
            }
        },
    },
};
</script>
