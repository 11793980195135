import { schema } from 'normalizr';

export default new schema.Entity(
    'paymentCounts',
    {},
    {
        idAttribute: 'competition',
        processStrategy: (paymentCount) => ({
            // Meta.
            _type: 'paymentCount',
            // Fields.
            ...paymentCount,
        }),
    },
);
