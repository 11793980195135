import store from "@/store";
// import moment from "moment";
import { intervalToDuration, differenceInMinutes } from "date-fns";
import Vue from "vue";

Vue.filter("asCounter", (time) => {
  //   const t = moment(time.diff(store.getters.time)).unix();
  const result = intervalToDuration({
    start: new Date(store.getters.time),
    end: new Date(time),
  });
  const diffInMin = differenceInMinutes(
    new Date(time),
    new Date(store.getters.time)
  );
  // eslint-disable-next-line
  return diffInMin <= 0
    ? store.getters.say.time_is_up
    : `${
        result.years > 0 ? `${result.years}${store.getters.say.years_y}` : ``
      } ${
        result.months > 0 ? `${result.months}${store.getters.say.months_m}` : ``
      } ${result.days}${store.getters.say.days_d} ${result.hours}${
        store.getters.say.hours_h
      }  ${result.minutes}${store.getters.say.minutes_m}`;
  //   return t > 86400
  //     ? moment(t - 86400, "X")
  //         .utc()
  //         .format(
  //           `DDD[${store.getters.say.days_d}] HH[${store.getters.say.hours_h}] mm[${store.getters.say.minutes_m}]`
  //         )
  //     : t <= 86400 && t > 0
  //     ? moment(t, "X")
  //         .utc()
  //         .format(
  //           `0[${store.getters.say.days_d}] HH[${store.getters.say.hours_h}] mm[${store.getters.say.minutes_m}]`
  //         )
  //     : store.getters.say.time_is_up;
});
