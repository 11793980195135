export function remove(a, x) {
    a.splice(a.indexOf(x), 1);

    return a;
}

export function intersection(a, b) {
    return a.filter((x) => b.includes(x));
}

export function any(a, b) {
    return intersection(a, b).length > 0;
}

export function all(a, b) {
    return intersection(a, b).length === Math.max(a.length, b.length);
}

export function many(x) {
    const y = x ?? [];

    return Array.isArray(y) ? y : [y];
}

export function take(a, n) {
    return a.map((x) => x[n]);
}

export function flatten(a, n) {
    return take(a, n).flat();
}

export function insert(a, n, b) {
    return [
        ...a.slice(0, n),
        ...b,
        ...a.slice(n),
    ];
}
