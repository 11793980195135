<template>
  <div>
    <div class="welcome">
      <p v-if="role === 'list'"><b>Wybierz kolumny z imieniem, nazwiskiem, datą urodzenia i płcią.</b>Opcjonalnie wskaż inne kolumny np. numer startowy.</p>
      <p v-else-if="role === 'chip'"><b>Wybierz kolumny z numerem startowym i numerem chip.</b></p>
    </div>
    <div class="wrap">
       <swiper :class="{ 'fog fog-left': isNavLeft, 'fog fog-right': isNavRight }" :options="swiperOption" @slideChange="slideChanged" ref="carousel">
        <swiper-slide v-for="index in data[0].length" :key="index">
          <div class="item">
            <div class="header">
              <select v-model="headers[index - 1]" class="select">
                <option v-for="(header, idx) in headersData" :value="header.value" :key="idx">{{ header.name }}</option>
              </select>
            </div>
            <div class="body">
              <div v-for="row in data" class="body-item">
                {{ row[index - 1] }}
              </div>
            </div>
          </div> 
        </swiper-slide> 
        <div v-show="isNavLeft" class="nav-btn button-prev" slot="button-prev">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.70711 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H1.70711L4.85355 8.14645C5.04882 8.34171 5.04882 8.65829 4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L0.146447 4.85355C-0.0488155 4.65829 -0.0488155 4.34171 0.146447 4.14645L4.14645 0.146447C4.34171 -0.0488155 4.65829 -0.0488155 4.85355 0.146447C5.04882 0.341709 5.04882 0.658291 4.85355 0.853553L1.70711 4Z" fill="white"/>
          </svg>
        </div>
        <div v-show="isNavRight" class="nav-btn button-next" slot="button-next">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z" fill="white"/>
          </svg>
        </div>
      </swiper>
      
    </div>        
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'PanelUploadTable',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    headersData: {
      type: Array,
      required: false,
      default: () => [
        {
          "name": "Pomiń kolumnę",
          "value": "hidden",
        },
        {
          "name": "Imię",
          "value": "firstname",
        },
        {
          "name": "Nazwisko",
          "value": "lastname",
        },
        {
          "name": "Płeć (K/M)",
          "value": "gender",
        },
        {
          "name": "Data urodzenia (DD.MM.YYYY)",
          "value": "birthdate",
        },
        {
          "name": "Miasto",
          "value": "city",
        },
        {
          "name": "Email",
          "value": "email",
        },
        {
          "name": "Telefon",
          "value": "phone",
        },
        {
          "name": "Klub",
          "value": "clubname",
        },
        {
          "name": "Skrót klasyfikacji",
          "value": "classification",
        },
        {
          "name": "Numer startowy",
          "value": "playerNumber",
        },
        {
          "name": "Kategoria dodatkowa (Tak/Nie)",
          "value": "special_category",
        },
        {
          "name": "Opłacony (Tak/Nie)",
          "value": "pay",
        }
      ]
    },
    role: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      swiperOption: {
        initialSlide: this.data[0].length > 3 ? 1 : 0,
        slidesPerView: this.data[0].length > 3 ? 4 : this.data[0].length,
        centeredSlides: this.data[0].length > 3 ? true : false,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev'
        }        
      },
      isNavLeft: false,
      isNavRight: this.data[0].length > 3 ? true : false,
      headers: [],
      activeIndex: 1,
    }
  },
  watch: {
    'headers': function() {
      this.$emit("change", this.headers);
    }
  },
  computed: {
    dataLength() {
      return this.data[0].length
    },
  },
  created() {
    this.data[0].forEach(element => {
      this.$props.role === 'list' ? this.headers.push('hidden') : this.headers.push('null')
    });
  },
  methods: {
    slideChanged() {
      const dataLength = this.dataLength > 5 ? this.dataLength - 2 : this.dataLength
      this.activeIndex = this.$refs.carousel.$swiper.activeIndex
      this.activeIndex > 1 && this.activeIndex < this.dataLength ? this.isNavLeft = true : this.isNavLeft = false
      this.activeIndex >= dataLength ? this.isNavRight = false : this.isNavRight = true
    }
  }
}
</script>

<style scoped>
.swiper-container.fog:before, .swiper-container.fog:after {content: '';position: absolute;height: 100%;width: 100px;top: 0;z-index: 1;}
.swiper-container.fog.fog-right:after {right: 0;background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 2.93%, #FFFFFF 99.04%); transform: matrix(1, 0, 0, -1, 0, 0);}
.swiper-container.fog.fog-left:before {left: 0;background: linear-gradient(90deg, rgba(236, 239, 241, 0.0001) 2.93%, #ECEFF1 99.04%);transform: matrix(-1, 0, 0, 1, 0, 0);height:64px;z-index: 3;}
.nav-btn {height: 40px;width: 40px;background: #546E7A;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);border-radius: 4px;position: absolute;top:50%;z-index: 2;cursor: pointer;display: flex;align-items: center;justify-content: center;}
.nav-btn.button-prev {left: 8px;}
.nav-btn.button-next {right: 8px;}
.wrap {display: flex;height: 376px;overflow: hidden;}
.welcome {background-color: #ECEFF1;padding: 24px 32px;color: #455A64;}
.welcome b {display: block;}
.wrap {background: linear-gradient(180deg, rgba(236,239,241,1) 0%, rgba(236,239,241,1) 64px, rgba(255,255,255,1) 64px, rgba(255,255,255,1) 100%);}
.header {padding: 0 16px; margin-bottom: 24px;}
.body {padding: 24px 16px;position:relative;color: #37474F;}
.body:before {content: '';position: absolute;width: 1px;height: 274px;top: 24px;left: 0;background-color: rgba(84, 110, 122, 0.1);}
.body-item {margin-bottom: 16px;}
.select {overflow:hidden;white-space:nowrap; text-overflow: ellipsis;background: #fff;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);border-radius: 4px;width: 100%;height: 40px;border: none;padding: 0 34px 0 12px;appearance: none;background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");background-repeat: no-repeat;background-position: right 12px center;background-size: 1em;}
</style>