var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row incoming-competitions" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-3" },
        [_c("main-list-filters")],
        1
      ),
      _c(
        "div",
        {
          ref: "listTop",
          staticClass: "col-12 col-lg-9 incoming-competitions__listing",
          attrs: { id: "listTop" },
        },
        [
          _c(
            "div",
            { staticClass: "incoming-competitions__listing--padding" },
            [
              _vm.futureCompetitions.length || _vm.promotedCompetitions.length
                ? _c(
                    "div",
                    [
                      _c("p", [
                        _c("b", [
                          _vm._v(_vm._s(_vm.say.upcoming_competitions)),
                        ]),
                      ]),
                      _vm.promotedCompetitions.length &&
                      _vm.$route.name === "main-list"
                        ? _c("base-item-competition-promoted", {
                            attrs: { id: _vm.promotedCompetitions[0] },
                          })
                        : _vm._e(),
                      _vm._l(_vm.futureCompetitions, function (id) {
                        return _c("base-item-competition", {
                          key: id,
                          attrs: { id: id },
                        })
                      }),
                      _vm.$route.name === "main-landing"
                        ? _c(
                            "div",
                            { staticClass: "col-12 mat32" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "main-list",
                                      query: {
                                        od: _vm.time.format("DD.MM.YYYY"),
                                        strona: 2,
                                      },
                                      alias: true,
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticClass:
                                        "incoming-competitions__listing-button",
                                      attrs: { btn4: "" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("asUpperCase")(
                                              _vm.say
                                                .see_all_upcoming_competitions
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.pastCompetitions.length
                ? _c(
                    "div",
                    { class: { mat32: _vm.futureCompetitions.length } },
                    [
                      _c("p", [
                        _c("b", [_vm._v(_vm._s(_vm.say.past_competitions))]),
                      ]),
                      _vm._l(_vm.pastCompetitions, function (id) {
                        return _c("base-item-competition", {
                          key: id,
                          attrs: { id: id },
                        })
                      }),
                      _vm.$route.name === "main-landing"
                        ? _c(
                            "div",
                            { staticClass: "col-12 mat32" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "main-list",
                                      query: {
                                        do: _vm.time.format("DD.MM.YYYY"),
                                        strona: 2,
                                      },
                                      alias: true,
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticClass:
                                        "incoming-competitions__listing-button",
                                      attrs: { btn4: "" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("asUpperCase")(
                                              _vm.say.see_all_past_competitions
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              [
                "main-list",
                "main-site-operator",
                "main-site-organizer",
              ].includes(_vm.$route.name)
                ? _c(
                    "div",
                    { staticClass: "row mat32" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: { btn6: "", disabled: _vm.filter.page === 1 },
                          on: {
                            click: function ($event) {
                              return _vm.prev()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.say.previous) + " ")]
                      ),
                      _c(
                        "base-button",
                        {
                          attrs: {
                            btn6: "",
                            disabled:
                              !_vm.futureCompetitions.length &&
                              !_vm.pastCompetitions.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.next()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.say.next) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }