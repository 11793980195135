<template>
  <div class="container pab1">
    <div class="row admin-header">
        <div class="col-12">
            <h1>Lista użytkowników</h1>
        </div>
        <div class="col-12 col-lg-5">
            <!-- Search input for users. -->
            <base-input-text
                v-model="searchUsers"
                icon="fas fa-search"
                label="Szukaj..."
                class="admin-header__search"
            />
        </div>        
        <div class="col-12 col-lg-7">
            <!-- Switch between lists -->
            <base-button-group
                horizontal
                class="admin-header__views"
            >
                <base-button
                    class="admin-header__views-btn"
                    btn11
                    :pressed="listParent === 'all'"
                    @click="changeUsersList('all')"
                >
                    Wszyscy
                </base-button>
                <base-button
                    class="admin-header__views-btn"
                    btn11
                    :pressed="listParent === 'user'"
                    @click="changeUsersList('user')"                  
                >
                    Uczestnicy
                </base-button>
                <base-button
                    class="admin-header__views-btn"
                    btn11
                    :pressed="listParent === 'organizer'"
                    @click="changeUsersList('organizer')"                  
                >
                    Organizatorzy
                </base-button>
                <base-button
                    class="admin-header__views-btn"
                    btn11
                    :pressed="listParent === 'operator'"
                    @click="changeUsersList('operator')"                  
                >
                    Operatorzy
                </base-button>
            </base-button-group>
        </div>
    </div>
    <div
        v-if="!ready"
        class="mat32"
    >
        <div class="row">
            <div class="col-12">
                <div class="admin-header__loading-state">
                    Trwa wczytywanie
                    <div class="mal15 lds-ellipsis">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <div v-else class="row">
        <div class="col-12">
            <base-table
                class="list"
                :headers="tableHeaders"
                :items="items"
            >
                <!-- Headers. -->
                <template v-slot:header="{ sort }">
                    <tr class="list__row">
                        <th
                            v-for="(header, column) in tableHeaders"
                            :key="column"
                            class="list__row-header"
                            :class="{
                                'list__row-data--profil': header.value === 'profil',
                            }" 
                            @click="sort(column)"
                        >
                            {{ header.text }}
                        </th>
                    </tr>
                </template>
                <!-- Body. -->
                <template v-slot:body="{ displayedItems }">
                    <tr
                        v-for="item in displayedItems"
                        :key="item.id"
                        class="list__row list__row--pointer list__row--hover"
                        @click="modal(item)"
                    >
                        <td
                            v-for="(header, column) in tableHeaders"
                            :key="column"
                            class="list__row-data"
                            :class="{
                                'list__row-data--name': header.value === 'fname',
                                'list__row-data--profil': header.value === 'profil',
                                'list__row-data--loading': loading
                            }"                            
                        >
                            <template v-if="header.value === 'profil'">
                                <span v-for="(profil, index) in item[header.value]" :key="item['id']+'_'+index" class="profil-badge">{{ profilName(profil) }}</span>
                            </template>    
                            <template v-else>{{ item[header.value] }}</template>
                        </td>
                    </tr>
                </template>
                <template v-slot:no-results="{ search: term }">
                    <div v-if="term.length">
                        Brak wyników wyszukiwania
                        <b>{{ term }}</b>
                    </div>
                    <!-- <div v-else-if="isCompetitions">
                        Brak zorganizowanych zawodów
                    </div>
                    <div v-else-if="isOrganizers">
                        Brak organizatorów
                    </div>
                    <div v-else>
                        Brak operatorów
                    </div> -->
                </template>
            </base-table>
        </div>
    </div>
    <div v-if="ready" class="row admin__pages">
        <div
            class="admin__pages-btn"
            :class="{
                'admin__pages-btn--disabled': !isPreviousPage,
            }"
            @click="isPreviousPage ? prevPage() : ''"
        >
            <div
                v-if="isLoadingPrev"
                class="admin__pages-loading"
            >
                <div class="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            Poprzednie
        </div>
        <div
            class="admin__pages-btn"
            :class="{ 'admin__pages-btn--disabled': !isNextPage }"
            @click="isNextPage ? nextPage() : ''"
        >
            <div
                v-if="isLoadingNext"
                class="admin__pages-loading"
            >
                <div class="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            Następne
        </div>
    </div>
    <modal ref="userModal" class="modal-hd modal-background" @modal-close="defaultModalData">
        <template v-slot:header>
            Edycja konta użytkownika
        </template>
        <template v-slot:body>
            <div class="userFormWrap">
                <div class="row unset mab24">
                    <div class="col-12">
                        <h3>Dane konta</h3>
                    </div>
                    <div class="col-12 col-lg-2">
                        <p class="form-input__label">Imię</p>                        
                        <base-input-text v-model="selectedUser.firstname" :error.sync="errors.firstname" />
                    </div>
                    <div class="col-12 col-lg-2">
                        <div class="mal32">
                            <p class="form-input__label">Nazwisko</p>                        
                            <base-input-text v-model="selectedUser.lastname" :error.sync="errors.lastname" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-2">
                        <div class="mal32">
                            <p class="form-input__label">E-mail</p>                        
                            <base-input-text v-model="selectedUser.email" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="mal32">
                            <p class="form-input__label">Reset hasła</p>                        
                            <p style="line-height: 40px;" v-if="sendingThePassword">Wysyłam hasło</p>
                            <p style="line-height: 40px;" v-else-if="passwordSent">Link wysłany</p>
                            <div class="btn btn-outline" v-else @click="sendPassword">Wyślij link do resetu hasła</div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-2">
                        <div class="mal32">
                            <p class="form-input__label">Link do hasła</p>
                            <base-input-text v-if="linkPassword && linkPassword.length > 0" v-model="selectedUser.email" />
                            <div class="btn btn-outline" v-else @click="sendLinkPassword">Wyślij</div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row unset mab24">
                    <div class="col-12 mab24">
                        <base-input-switch v-model="selectedUser.userRole" label icons :checked="selectedUser.userRole" @input="changeUserRole"><h3>Profil uczestnika</h3></base-input-switch>
                    </div>
                    <div v-if="selectedUser.userRole" class="row unset mab16" style="width: 100%;">
                         <div class="col-12 col-lg-2">
                            <p class="form-input__label">Data urodzenia</p>                        
                            <base-input-date
                                v-model="selectedUser.birthTime"
                                initialView="year"
                                class="no-shadow"
                                :error.sync="errors.birthTime"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                             <div class="mal32">
                                <p class="form-input__label">Płeć</p>
                                <base-input-radio
                                    v-model="selectedUser.gender"
                                    class="radio-field__row"
                                    :options="[
                                        { value: 'M', title: 'man' },
                                        { value: 'K', title: 'woman' },
                                    ]"
                                    :error.sync="errors.gender"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Narodowość</p>   
                                <base-input-select
                                    v-model="selectedUser.nationality"
                                    :options="countries"
                                    :clearable="false"
                                    label="Narodowość"
                                    :reduce="(country) => country.id"
                                    :error.sync="errors.nationality"
                                />                                                     
                                <!-- <base-input-text v-model="selectedUser.country" /> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedUser.userRole" class="row unset" style="width: 100%;">
                        <div class="col-12 col-lg-2">
                            <p class="form-input__label">Telefon</p>                        
                            <base-input-text v-model="selectedUser.phone" :error.sync="errors.phone" />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Miejscowość</p>       
                                <base-input-autocomplete
                                    v-model="searchValueCity"
                                    class="user-form__autocomplete"
                                    label="Miejscowość"
                                    :error.sync="cityError"
                                    :clear-search-on-select="true"
                                    action="AUTOCOMPLETE_LOCATION"
                                    @select="selectCity($event)"
                                />                                                 
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Klub</p>                        
                                <base-input-text v-model="selectedUser.clubname"  />
                            </div>
                        </div>

                    </div>
                </div>
                <hr>
                <div class="row mab24">
                    <div class="col-12 mab24">
                        <base-input-switch v-model="selectedUser.orgRole" label icons :checked="selectedUser.orgRole" @input="changeOrgRole" :disabled="selectedUser.opRole"><h3>Profil organizatora</h3></base-input-switch>
                    </div>
                    <div v-if="selectedUser.orgRole" class="row unset mab16" style="width: 100%;">
                        <div class="col-12 mab24">
                            <p class="form-input__label">Osobowość prawna organizatora</p>
                            <!-- <base-input-radio
                                v-model="selectedUser.organizerObj.legalType"
                                class="radio-field__row"
                                :options="[
                                    { value: 'private', title: 'Osoba prywatna' },
                                    { value: 'company', title: 'Firma / Fundacja / Stowarzyszenie' },
                                ]"
                            /> -->
                            <div class="radio-field radio-field__row">
                                <input id="private" v-model="selectedUser.organizerObj.legalType" type="radio" class="d-none" value="private"><label for="private" class="radio-field__label">Osoba prywatna</label>
                                <input id="company" v-model="selectedUser.organizerObj.legalType" type="radio" class="d-none" value="company"><label for="company" class="radio-field__label">Firma / Fundacja / Stowarzyszenie</label>
                            </div>
                         </div>
                        <div class="col-12 col-lg-2">
                                <p class="form-input__label">NIP</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvNip" />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Nazwa firmy</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvFirmname" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Miejscowość</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvCity" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Prowizja od opłaty startowej</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.commision" />
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedUser.orgRole" class="row unset mab32" style="width: 100%;">
                        <div class="col-12 col-lg-2">
                            <p class="form-input__label">Kod pocztowy</p>                        
                            <base-input-text v-model="selectedUser.organizerObj.fvPostcode" />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Ulica</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvAddress" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-min">
                            <div class="mal32">
                                <p class="form-input__label">Nr domu</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvHouseNumber" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-min">
                            <div class="mal32">
                                <p class="form-input__label">Nr lokalu</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.fvFlatNumber" />
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedUser.orgRole" class="row unset" style="width: 100%;">
                        <div class="col-12 mab16"><b>Dane organizatora widoczne dla uczestników</b></div>
                        <div class="col-12 col-lg-2">
                            <p class="form-input__label">Numer telefonu</p>                        
                            <base-input-text v-model="selectedUser.organizerObj.phone" />
                        </div>                        
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Adres e-mail</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.email" />
                            </div>
                        </div>                        
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Nazwa organizatora</p>                        
                                <base-input-text v-model="selectedUser.organizerObj.name" />
                            </div>
                        </div>                        
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Przypisz do operatora</p>                        
                                <base-input-select
                                    v-model="selectedUser.organizerObj.operator"
                                    :options="operators"
                                    label="Operator"
                                    label-select="name"
                                    :clearable="false"
                                    :searchable="true"
                                    :reduce="(operator) => operator.id"
                                />
                            </div>
                        </div>                        
                    </div>
                </div>
                <hr>
                <div class="row mab24">
                    <div class="col-12 mab24">
                        <base-input-switch v-model="selectedUser.opRole" label icons :checked="selectedUser.opRole" @input="changeOpRole" :disabled="selectedUser.orgRole"><h3>Profil operatora</h3></base-input-switch>
                    </div>
                    <div v-if="selectedUser.opRole" class="row unset mab16" style="width: 100%;">
                        <!-- <div class="col-12 mab24">
                            <p class="form-input__label">Osobowość prawna organizatora</p>
                            <base-input-radio
                                v-model="selectedUser.operatorObj.legalType"
                                class="radio-field__row"
                                :options="[
                                    { value: 'private', title: 'Osoba prywatna' },
                                    { value: 'company', title: 'Firma / Fundacja / Stowarzyszenie' },
                                ]"
                            />
                        </div> -->
                        <div class="col-12 col-lg-2">
                                <p class="form-input__label">NIP</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvNip" />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Nazwa firmy</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvFirmname" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Miejscowość</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvCity" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Prowizja od zawodów</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.commision" />
                            </div>
                        </div>                        
                    </div>
                    <div v-if="selectedUser.opRole" class="row unset mab32" style="width: 100%;">
                        <div class="col-12 col-lg-2">
                            <p class="form-input__label">Kod pocztowy</p>                        
                            <base-input-text v-model="selectedUser.operatorObj.fvPostcode" />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Ulica</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvAddress" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-min">
                            <div class="mal32">
                                <p class="form-input__label">Nr domu</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvHouseNumber" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-min">
                            <div class="mal32">
                                <p class="form-input__label">Nr lokalu</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.fvFlatNumber" />
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedUser.opRole" class="row unset" style="width: 100%;">
                        <div class="col-12 mab16"><b>Dane operatora widoczne dla uczestników</b></div>
                        <div class="col-12 col-lg-2">
                            <p class="form-input__label">Numer telefonu</p>                        
                            <base-input-text v-model="selectedUser.operatorObj.phone" />
                        </div>                        
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Adres e-mail</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.email" />
                            </div>
                        </div>                        
                        <div class="col-12 col-lg-3">
                            <div class="mal32">
                                <p class="form-input__label">Nazwa operatora</p>                        
                                <base-input-text v-model="selectedUser.operatorObj.name" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="loader" v-if="loading">
                <div class="loader__backdrop"/>
                <div class="lds-ellipsis lds-ellipsis--center">
                    <div />
                    <div />
                    <div />
                </div>
            </div>            
        </template>
        <template v-slot:footer>
            <button class="btn" @click="$refs.userModal.closeModal()">Anuluj</button>
            <button class="btn btn-primary" @click="saveForm">Zapisz</button>
        </template>        
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
    READ_USERS_LIST,
} from '@/store/actions.type';
import { debounce } from 'lodash';
import Modal from '@/components/Modal';
import axios from 'axios';
import countries from '@/assets/data/countries';
import { RESET_PASSWORD, RESET_PASSWORD_LINK } from '@/store/actions.type';

export default {
    name: 'PanelUsersList',
    components: {
        Modal,
    },    
    data() {
        return {
            countries,
            searchUsers: '',
            listParent: 'all',
            ready: false,
            loading: false,
            tableHeaders: [
                { text: 'Imię i Nazwisko', value: 'fname' },
                { text: 'Adres e-mail', value: 'email' },
                { text: 'Miejscowość', value: 'city' },
                { text: 'Profil', value: 'profil' },
            ],
            page: 1,
            itemsPerPage: 50,
            data: [],
            isLoadingNext: false,
            isLoadingPrev: false,
            selectedUser: null, 
            operators: [],
            errors: {},
            searchValueCity: null,
            loading: false,
            passwordSent: false,
            sendingThePassword: false,
            linkPassword: ''
        };
    },
    watch: {
        searchUsers() {
            this.debouncedSearchQuery();
        },
        'selectedUser.city': {
            immediate: false,
            handler(value) {
                if(value) {
                    this.searchValueCity = this.selectedUser.city;
                }
            },
        },        
    },
    computed: {
        apiParams() {
            return  {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                name: this.searchUsers,
                role: this.listParent,
            }
        },
        items() {
            const list = [];
            for (const user of this.data) {
                const userInfo = {
                    id: user.id,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    fname: `${user.firstname ?? ''} ${user.lastname ?? ''}`,
                    email: `${user.email ?? ''}`,
                    city: `${user.city ?? ''}`,
                    birthTime: user.birthTime,
                    gender: user.gender,
                    profil: user.roles,
                    city: user.city,
                    clubname: user.clubname,
                    country: user.country,
                    nationality: user.nationality,
                    cityLat: user.cityLat,
                    cityLng: user.cityLng,
                    phone: user.phone, 
                    operator: user.operator,
                    organizer: user.organizer,
                    userRole: false,
                    orgRole: false,
                    opRole: false,
                    organizerObj: {},
                    operatorObj: {}
                };
                list.push(userInfo);
            } 
            return list           
        },
        isPreviousPage() {
            return this.page > 1;
        },
        isNextPage() {
            // Pagination is blocked if search input is in use
            // return !this.searchUsers;
            return true
        },
        cityError: {
            get() {
                return (
                    this.errors.city
                    || this.errors.cityLat
                    || this.errors.cityLng
                );
            },
            set() {
                this.errors.city = '';
                this.errors.cityLat = '';
                this.errors.cityLng = '';
            },
        },        
    },
    async created() {
        this.debouncedSearchQuery = debounce(this.searchQuery, 500);
        const data = await this.READ_USERS_LIST(this.apiParams);
        this.data = data;
        this.ready = true;
        this.getOperators()
    },
    methods: {
        ...mapActions([
            READ_USERS_LIST,
            RESET_PASSWORD,
            RESET_PASSWORD_LINK
        ]),
        async searchQuery() {
            this.ready = false;
            this.page = 1
            const data = await this.READ_USERS_LIST(this.apiParams);
            this.data = data;
            this.ready = true;
        },
        profilName(profil) {
            let name = '';
            if(profil === 'ROLE_USER') {
                name = 'uczestnik'
            } else if(profil === 'ROLE_ORGANIZER') {
                name = 'organizator'
            } else if(profil === 'ROLE_OPERATOR') {
                name = 'operator'
            }

            return name
        },
        async nextPage() {
            this.page += 1;
            this.isLoadingNext = true;
            const data = await this.READ_USERS_LIST(this.apiParams);
            this.data = data;
            this.scrollToTop();
            this.isLoadingNext = false;
        },
        async prevPage() {
            this.page -= 1;
            this.isLoadingPrev = true;
            const data = await this.READ_USERS_LIST(this.apiParams);
            this.data = data;
            this.scrollToTop();
            this.isLoadingPrev = false;
        },
        async changeUsersList(status) {
            this.ready = false;
            this.listParent = status;
            this.page = 1
            const data = await this.READ_USERS_LIST(this.apiParams);
            this.data = data;
            this.ready = true;

        },    
        scrollToTop() {
            window.scroll({top: 0, left: 0, behavior: 'smooth'});
        },
        async defaultModalData() {
            this.selectedUser = null
            this.errors = {}
        }, 
        modal(item) {
            this.loading = true
            this.selectedUser = item
            this.loading = false
            this.selectedUser.userRole = item.profil.includes('ROLE_USER')
            this.selectedUser.orgRole = item.profil.includes('ROLE_ORGANIZER')
            this.selectedUser.opRole = item.profil.includes('ROLE_OPERATOR')
            if(this.selectedUser.orgRole) {
                this.getOrganizer(this.selectedUser.organizer)
            }
            if(this.selectedUser.opRole) {
                this.getOperator(this.selectedUser.operator)
            }
            this.$refs.userModal.openModal()
        },
        async getOrganizer(id) {
            const response = await axios({
                    method: 'get',
                    url: `/panel/organizers/${id}`,
                })
            this.selectedUser.organizerObj = response
        },
        async getOperator(id) {
            const response = await axios({
                    method: 'get',
                    url: `/panel/operators/${id}`,
                })
            this.selectedUser.operatorObj = response
            this.selectedUser.firstname = response.firstname
            this.selectedUser.lastname = response.lastname
        },
        async getOperators() {
            const response = await axios({
                    method: 'get',
                    url: `/panel/operators`,
                })
            this.operators = response
        },
        selectCity(value) {
            // place changed event
            if (value) {
                this.$set(this.selectedUser, 'cityLng', value.cityLng);
                this.$set(this.selectedUser, 'cityLat', value.cityLat);
                this.$set(this.selectedUser, 'city', value.city);
                setTimeout(() => {
                    this.searchValueCity = value.text;
                }, 40);
            }
        },        
        changeUserRole(checked) {
            if(checked) {
                axios({
                    method: 'post',
                    url: `/role/users/${this.selectedUser.id}`,
                })
            } else {
                axios({
                    method: 'delete',
                    url: `/role/users/${this.selectedUser.id}`,
                })
            }
        },
        changeOrgRole(checked) {
            if(checked) {
                axios({
                    method: 'post',
                    url: `/panel/organizers`,
                    data: {
                        operator: this.selectedUser.organizerObj.operator,
                        email: this.selectedUser.email,
                    }
                }).then(data => {
                    this.selectedUser.organizer = data.id
                    this.selectedUser.organizerObj = data
                })
            } else {
                axios({
                    method: 'delete',
                    url: `role/users/${this.selectedUser.id}/organizer`,
                })
            }
        },
        changeOpRole(checked) {
            if(checked) {
                axios({
                    method: 'post',
                    url: `/panel/operators`,
                    data: {
                        email: this.selectedUser.email,
                    }                    
                }).then(data => {
                    this.selectedUser.operator = data.id
                    this.selectedUser.operatorObj = data
                })
            } else {
                axios({
                    method: 'delete',
                    url: `/role/users/${this.selectedUser.id}/operator`,
                })
            }
        },
        async saveForm() {
            this.loading = true
            const promises = []
                if(this.selectedUser.userRole) {
                    const userPUT = axios({
                        method: 'put',
                        url: `/panel/users/${this.selectedUser.id}`,
                        data: {
                            "id": this.selectedUser.id,
                            "firstname": this.selectedUser.firstname,
                            "lastname": this.selectedUser.lastname,
                            "gender": this.selectedUser.gender,
                            "birthTime": this.selectedUser.birthTime,
                            "clubname": this.selectedUser.clubname,
                            "phone": this.selectedUser.phone,
                            "city": this.selectedUser.city,
                            "nationality":  this.selectedUser.nationality,
                            "cityLat": this.selectedUser.cityLat,
                            "cityLng": this.selectedUser.cityLng
                        }                  
                    })
                    promises.push(userPUT)
                }
                if(this.selectedUser.orgRole) {
                    const orgPUT = axios({
                        method: 'put',
                        url: `/panel/organizers/${this.selectedUser.organizer}`,
                        data: {
                            "operator": this.selectedUser.organizerObj.operator,
                            "email": this.selectedUser.email,
                            "fvFirmname": this.selectedUser.organizerObj.fvFirmname,
                            "fvPostcode": this.selectedUser.organizerObj.fvPostcode,
                            "fvCity": this.selectedUser.organizerObj.fvCity,
                            "fvAddress": this.selectedUser.organizerObj.fvAddress,
                            "fvNip": this.selectedUser.organizerObj.fvNip,
                            "name": this.selectedUser.organizerObj.name,
                            "phone": this.selectedUser.organizerObj.phone,
                            "fvHouseNumber": this.selectedUser.organizerObj.fvHouseNumber,
                            "fvFlatNumber": this.selectedUser.organizerObj.fvFlatNumber,
                            "legalType": this.selectedUser.organizerObj.legalType,
                            "commision": this.selectedUser.organizerObj.commision,
                            // "firstname": this.selectedUser.firstname,
                            // "lastname": this.selectedUser.lastname,
                        }                 
                    })
                    promises.push(orgPUT)
                }
                if(this.selectedUser.opRole) {
                    const opPUT = axios({
                        method: 'put',
                        url: `/panel/operators/${this.selectedUser.operator}`,
                        data: {
                            // "operator": this.selectedUser.operatorObj.operator,
                            // "legalType": this.selectedUser.operatorObj.legalType
                            "email": this.selectedUser.email,
                            "fvFirmname": this.selectedUser.operatorObj.fvFirmname,
                            "fvPostcode": this.selectedUser.operatorObj.fvPostcode,
                            "fvCity": this.selectedUser.operatorObj.fvCity,
                            "fvAddress": this.selectedUser.operatorObj.fvAddress,
                            "fvNip": this.selectedUser.operatorObj.fvNip,
                            "name": this.selectedUser.operatorObj.name,
                            "phone": this.selectedUser.operatorObj.phone,
                            "fvHouseNumber": this.selectedUser.operatorObj.fvHouseNumber,
                            "fvFlatNumber": this.selectedUser.operatorObj.fvFlatNumber,
                            "commision": this.selectedUser.operatorObj.commision,
                            "firstname": this.selectedUser.firstname,
                            "lastname": this.selectedUser.lastname,
                        }                 
                    })
                    promises.push(opPUT)
                }
            await Promise.all(promises).catch(err => {
                this.errors = err;
            });
            this.loading = false
            const data = await this.READ_USERS_LIST(this.apiParams);
            this.data = data;
            this.ready = true;            
        },
        async sendPassword() {
            this.sendingThePassword = true
            try {
                await this.RESET_PASSWORD(this.selectedUser.email);

                this.sendingThePassword = false
                this.passwordSent = true
            } catch (e) {
                this.errors = e;
            }
        },
        async sendLinkPassword() {
            try {
                const response = await this.RESET_PASSWORD_LINK(this.selectedUser.email);
                this.linkPassword = response

            } catch (e) {
                this.errors = e;
            }
        }
    },
}
</script>

<style scoped>
.admin__pages {
    margin-top: 32px;
    margin-bottom: 32px;
}
.admin__pages-btn {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    position: relative;
}

.admin__pages-btn--disabled {
    opacity: 0.5;
}
.admin__pages-loading {
    position: absolute;
    top: -25px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.admin-header__add-box {
    overflow: hidden;
}
.admin-header {
    margin-top: 24px;
    margin-bottom: 24px;
}
.admin-header__views-btn {
    width: 100%;
    justify-content: center;
    user-select: none;
}
.admin-header__search {
    min-height: 40px;
    max-height: 40px;
}
.admin-header__link {
    margin-top: 16px;
}
.admin-header__add-operator {
    margin-top: 16px;
}

.admin-transition-enter {
    max-height: 0;
    overflow: hidden;
}
.admin-transition-enter-to {
    max-height: 500px;
    overflow: hidden;
}
.admin-transition-leave {
    max-height: 500px;
    overflow: hidden;
}
.admin-transition-leave-to {
    max-height: 0;
    overflow: hidden;
}
.admin-transition-enter-active,
.admin-transition-leave-active {
    transition: all var(--animation-slow);
}

.admin-header__loading-state {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.lds-hourglass:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 8px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
}
.admin-header h1 {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
    .admin-header {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .admin-header__views {
        margin-left: 32px;
    }
    .admin-header__link {
        margin: 0 15px;
    }
    .admin-header__add-operator {
        margin-left: 15px;
        margin-top: 0;
        min-height: 40px;
        max-height: 40px;
        box-sizing: border-box;
        display: flex;
    }
    .admin-header__search--competitions {
        margin-right: 0;
    }
}
.container-list {
    overflow: auto;
    margin-top: 36px;
    margin-bottom: 36px;
}
.list {
    width: 100%;
    border-spacing: 0;
    border-radius: 4px;
}
.list >>> tbody {
    background-color: var(--color-white);
    color: var(--color-slate);
}
.list__row-data {
    padding: 10px;
    border-bottom: 1px solid rgba(84, 110, 122, 0.1);
    white-space: nowrap;
    height: 48px;
    box-sizing: border-box;
}
.list__row--pointer {
    cursor: pointer;
}
.list__row--hover:hover {
    background: #f7f9f9;
}
.list__row-header {
    text-align: left;
    padding-bottom: 8px;
    padding-left: 10px;
    color: var(--color-silver);
    font-weight: normal;
}
.more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}
.more-btn__content {
    position: absolute;
    top: 16px;
    right: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-pale-grey);
    box-sizing: border-box;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 99;
}
.more-btn__content--from-up {
    bottom: 16px;
    top: unset;
}
.more-btn__content-btn {
    padding: 14px 8px;
    box-shadow: inset 0px -1px 0px #edf0f1;
    font-size: 14px;
}
.more-btn__content-btn:hover {
    background: #f7f9f9;
}
.more-btn__content-btn:last-of-type {
    box-shadow: none;
}
.list__row-data--sticky-right {
    position: sticky;
    right: 0;
    z-index: 1;
    max-width: 48px;
    min-width: 48px;
    background-color: var(--color-white);
    box-shadow: inset 1px 0px 0px #e5e5e5;
    cursor: pointer;
    opacity: 1 !important;
}
.list__row--hover:hover .list__row-data--sticky-right {
    background: #f7f9f9;
}
.list__row-data--active {
    z-index: 10;
}
.list__row--disabled td {
    opacity: 0.5;
}
.list__row-data--location {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.list__row-data--loading {
    opacity: 0.1 !important;
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}
th.list__row-data--profil {
    padding-right: 16px;
}
.list__row-data--profil {
text-align: right;
}
@media (min-width: 992px) {
    .list__row-data--sticky-right {
        box-shadow: none;
    }
    .container-list {
        overflow: visible;
    }
    .list__row-data--name {
        max-width: 340px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.profil-badge {
    background: #DBE3E6;
    border-radius: 12px;
    padding: 0 8px;
    min-height: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #455A64;
    display: inline-flex;
    align-items: center;
}
.profil-badge + .profil-badge {
    margin-left: 4px;
}

.userFormWrap h3 {
    font-size: 24px;
    line-height: 28px;
    color: #455A64;
    margin-bottom: 24px;
}
.userFormWrap .form-input__label {
    margin-bottom: 4px;
}
.userFormWrap .switch h3 {
    margin-bottom: 0;
    margin-left: 16px;
}
.radio-field__row {
    border: 0;
    background: none;
    flex-direction: row;
    padding: 0;
}

.row.unset {
    justify-content: unset;
}
.modal .btn {
  height: 40px;
  min-width: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #546E7A;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
}

.modal .btn-disabled {
  background: #ECEFF1;
  border: 1px solid #CFD8DC;
  box-shadow: none;
  color: #455A64;
  pointer-events: none;
}

.modal .input-field {
    min-height: 40px;
}

.modal .input-field.user-form__autocomplete {
    max-height: 40px;
}

.modal .filters-datepicker {
    height: 40px;
}

.modal .btn-outline {
    box-shadow: none;
    color: #455A64;
    border: 1px solid #37474F;
    background-color: transparent;
    box-sizing: border-box;
}

.modal .btn-success {
  background: #50AE55;
  color: #fff;
}
.modal .btn-primary {
  min-width: 268px;
  background: linear-gradient(270deg, #FF3D00 0%, #FF1744 99.2%);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}
.loader {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader__backdrop {
  background-color: rgba(55, 71, 79, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.loader .lds-ellipsis {
  z-index: 55;
}
@media (min-width: 992px) {
    .userFormWrap {
        padding: 32px;
    }
    .mal32 {
        margin-left: 32px;
    }
    .col-lg-min {
        flex: 0 0 10%;
    }
}
</style>

<style>
.no-shadow .filters-datepicker__wrapper-class {
    box-shadow: none;
}
</style>