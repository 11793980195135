var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mab24" }, [_vm._v(_vm._s(_vm.say.messages))]),
      _vm._l(_vm.messages, function (msg, index) {
        return _c("Messages", { key: index, attrs: { data: msg } })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }