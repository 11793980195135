var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-form-section",
    [
      _c(
        "base-form-input",
        {
          scopedSlots: _vm._u([
            {
              key: "hint",
              fn: function () {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.say.you_will_use_this_email_to_login) + " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("base-input-text", {
            attrs: { label: _vm.say.email, error: _vm.errors.email },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "email", $event)
              },
            },
            model: {
              value: _vm.form.email,
              callback: function ($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        {
          scopedSlots: _vm._u([
            {
              key: "hint",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.say.password_should_contain) + " "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("base-input-password", {
            attrs: { error: _vm.errors.plainPassword, label: _vm.say.password },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "plainPassword", $event)
              },
            },
            model: {
              value: _vm.form.plainPassword,
              callback: function ($$v) {
                _vm.$set(_vm.form, "plainPassword", $$v)
              },
              expression: "form.plainPassword",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }