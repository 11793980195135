var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "remote-competition__title" }, [
          _vm._v(" " + _vm._s(_vm.say.add_your_result) + " "),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c("div", { staticClass: "row" }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(_vm.player.firstname) +
                      " " +
                      _vm._s(_vm.player.lastname)
                  ),
                ]),
              ]),
              _vm.competition.id !== 5165
                ? [
                    _c(
                      "div",
                      { staticClass: "row person__birthdate flex-column" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.say.birthtime))]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm._f("asDate")(_vm.player.birthTime))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person__location flex-column" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.say.city))]),
                        _c("strong", [_vm._v(_vm._s(_vm.player.city))]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person__email flex-column" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.say.email_address))]),
                        _c("strong", [_vm._v(_vm._s(_vm.player.email))]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person__classification flex-column" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.say.classification))]),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.classification.namePl)),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "col-12 col-lg-8" }, [
            _c("div", { staticClass: "remote-competition__box bg--gray" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm.config.isTime
                    ? _c("h2", [_vm._v(" " + _vm._s(_vm.say.your_time) + " ")])
                    : _c("h2", [
                        _vm._v(" " + _vm._s(_vm.say.your_distance) + " "),
                      ]),
                ]),
              ]),
              _vm.config.isTime
                ? _c(
                    "div",
                    { staticClass: "row remote-competition__box-time" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-6" },
                        [
                          _c("base-input-time", {
                            attrs: {
                              label: "Twój czas",
                              error: _vm.errors.declaredTime,
                            },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(
                                  _vm.errors,
                                  "declaredTime",
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.declaredTime,
                              callback: function ($$v) {
                                _vm.declaredTime = $$v
                              },
                              expression: "declaredTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.config.isDistance
                ? _c(
                    "div",
                    { staticClass: "row remote-competition__box-distance" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-6" },
                        [
                          _c("base-input-text", {
                            attrs: {
                              label: _vm.say.distance + " [km]",
                              error: _vm.errors.declaredDistance,
                              hint: "00.0",
                            },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(
                                  _vm.errors,
                                  "declaredDistance",
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.declaredDistance,
                              callback: function ($$v) {
                                _vm.declaredDistance = $$v
                              },
                              expression: "declaredDistance",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.config.isFile
                ? _c(
                    "div",
                    { staticClass: "row remote-competition__box-file" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-lg-5 remote-competition__hint",
                        },
                        [
                          _vm.competition.id === 5165
                            ? _c("p", [
                                _vm._v(
                                  " Dodaj plik który umożliwi organizatorowi weryfikację twojego wyniku np. widok z Endomondo. "
                                ),
                              ])
                            : _c("p", [
                                _vm._v(
                                  " " + _vm._s(_vm.say.add_file_to_verify) + " "
                                ),
                              ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-6" },
                        [
                          _c("base-input-filearea", {
                            attrs: { error: _vm.errors.files },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(_vm.errors, "files", $event)
                              },
                            },
                            model: {
                              value: _vm.files,
                              callback: function ($$v) {
                                _vm.files = $$v
                              },
                              expression: "files",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row remote-competition__box-description" },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-5 remote-competition__hint" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.you_can_add_description) + " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("base-input-area", {
                        attrs: {
                          rows: 10,
                          label: _vm.say.description,
                          error: _vm.errors.declaredDescription,
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(
                              _vm.errors,
                              "declaredDescription",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.declaredDescription,
                          callback: function ($$v) {
                            _vm.declaredDescription = $$v
                          },
                          expression: "declaredDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm.config.isAddress
              ? _c("div", { staticClass: "shipping-address bg--gray mat24" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("p", [_vm._v(_vm._s(_vm.say.shipping_address))]),
                  ]),
                  _c("div", { staticClass: "row shipping-address__street" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-5 shipping-address__hint" },
                      [_vm._v(" " + _vm._s(_vm.say.add_address_hint) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6" },
                      [
                        _c("base-input-text", {
                          attrs: {
                            label: _vm.say.street,
                            error: _vm.errors.street,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "street", $event)
                            },
                          },
                          model: {
                            value: _vm.addressInfo.street,
                            callback: function ($$v) {
                              _vm.$set(_vm.addressInfo, "street", $$v)
                            },
                            expression: "addressInfo.street",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row shipping-address__number" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3" },
                      [
                        _c("base-input-text", {
                          staticClass: "mar15",
                          attrs: {
                            label: _vm.say.house_number,
                            error: _vm.errors.houseNumber,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "houseNumber", $event)
                            },
                          },
                          model: {
                            value: _vm.addressInfo.houseNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.addressInfo, "houseNumber", $$v)
                            },
                            expression: "addressInfo.houseNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3" },
                      [
                        _c("base-input-text", {
                          staticClass: "mal15",
                          attrs: {
                            label: _vm.say.flat_number,
                            error: _vm.errors.flatNumber,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "flatNumber", $event)
                            },
                          },
                          model: {
                            value: _vm.addressInfo.flatNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.addressInfo, "flatNumber", $$v)
                            },
                            expression: "addressInfo.flatNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row shipping-address__location" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6" },
                      [
                        _c("base-input-text", {
                          attrs: {
                            label: _vm.say.city,
                            error: _vm.errors.city,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "city", $event)
                            },
                          },
                          model: {
                            value: _vm.addressInfo.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.addressInfo, "city", $$v)
                            },
                            expression: "addressInfo.city",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row shipping-address__zip-code" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 col-lg-3" },
                      [
                        _c("base-input-text", {
                          staticClass: "mar15",
                          attrs: {
                            label: _vm.say.zip_code,
                            error: _vm.errors.postcode,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "postcode", $event)
                            },
                          },
                          model: {
                            value: _vm.addressInfo.postcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addressInfo, "postcode", $$v)
                            },
                            expression: "addressInfo.postcode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row shipping-address__country" }, [
                    _c("div", { staticClass: "col-12 col-lg-6" }, [
                      _c(
                        "div",
                        { staticClass: "input-field input-field--border-none" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "input-field__label input-field__label--show",
                            },
                            [_vm._v(_vm._s(_vm.say.country))]
                          ),
                          _c("base-input-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.countries,
                              clearable: false,
                              label: _vm.say.country,
                              reduce: (country) => country.id,
                              error: _vm.errors.country,
                            },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(_vm.errors, "country", $event)
                              },
                            },
                            model: {
                              value: _vm.addressInfo.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.addressInfo, "country", $$v)
                              },
                              expression: "addressInfo.country",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 remote-competition__button" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { btn1: "", loading: _vm.loading },
                      on: { click: _vm.addResult },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.add_result) + " ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }