import { schema } from 'normalizr';

export default new schema.Entity('classificationsSettings', {}, {
    idAttribute: 'classification', // one-to-one relation
    processStrategy: (classificationSetting) => ({
        // Meta.
        _type: 'classificationSetting',
        // Fields.
        ...classificationSetting,
    }),
});
