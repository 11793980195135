import classification from '@/api/schema/classification';
import user from '@/api/schema/user';
import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('panelPlayers', {
    classification,
    user,
    parent: user,
}, {
    processStrategy: (panelPlayer) => ({
        // Meta.
        _type: 'panelPlayer',
        // Fields.
        ...panelPlayer,
        // Cast fields.
        // Time must have multiply by 10, becouse in panel time is reading to 2 decimal places.
        time: panelPlayer.time ? moment.utc(panelPlayer.time * 10, 'x') : null,
        birthTime: panelPlayer.birthTime ? moment(panelPlayer.birthTime) : null,
    }),
});
