<template>
    <div v-if="!ifUserMore" class="subnav">
        <div class="d-flex align-items-center">
            <!-- User competitions navigation. -->
            <template v-if="$router.matched('user-competitions', $route)">
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-competitions-proposed', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.your_proposed_competitions }}
                </router-link>
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-competitions-future', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.your_future_competitions }}
                </router-link>
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-competitions-past', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.your_past_competitions }}
                </router-link>
            </template>
            <!-- User edit navigation. -->
            <template v-else-if="$router.matched('user-edit', $route)">
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-edit-profile', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.edit_personal_data }}
                </router-link>
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-edit-insurance', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.edit_insurance_data }}
                </router-link>
            </template>
            <!-- User insurance navigation. -->
            <template v-else-if="$router.matched('user-insurance', $route)">
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-insurance-policies', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.your_insurnace_policies }}
                </router-link>
            </template>
            <!-- User family navigation. -->
            <template v-else-if="$router.matched('user-family', $route)">
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-family-children', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.manage_family }}
                </router-link>
                <router-link
                    class="subnav__link"
                    :to="{ name: 'user-family-parents', alias: true }"
                    active-class="subnav__link--active"
                >
                    {{ say.who_can_manage_me }}
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['say']),
        ifUserMore() {
            return this.$route.name === 'user-more'
        },     
    },
};
</script>

<style scoped>
.subnav {
    position: relative;
    width: 100%;
    overflow: auto;
    margin: 0 0 30px 0;
    -webkit-box-shadow: inset 0px -1px 0px 0px var(--color-cool-grey);
    box-shadow: inset 0px -1px 0px 0px var(--color-cool-grey);
    width: calc(100vw - 30px);
}

.subnav__link {
    position: relative;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-left: 30px;
    font-weight: 600;
    outline: none;
}

.subnav__link:first-child {
    margin-left: 0;
}

.subnav__link--active:before {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
        to left,
        var(--color-red-orange),
        var(--color-pinky-red)
    );
}
@media (min-width: 768px) {
    .subnav {
        width: unset;
    }
}
</style>
