var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.ready
      ? _c("main", [
          _c("div", { staticClass: "d-flex flex-column mat24" }, [
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.say.start_list_is_loading) + " "),
            ]),
            _vm._m(0),
          ]),
        ])
      : _vm._e(),
    _vm.ready
      ? _c("main", [
          _c("div", { staticClass: "list-header" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-12 col-lg-3 mar-auto align-items-center",
                  },
                  [
                    _c("h1", { staticClass: "l-short" }, [
                      _vm._v(" " + _vm._s(_vm.say.start_list) + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-3" },
                  [
                    _c("base-input-select", {
                      staticClass: "mar15",
                      attrs: {
                        options: _vm.classifications,
                        label: _vm.say.classification,
                        "label-select": `name${_vm.activeLang}`,
                      },
                      model: {
                        value: _vm.selectedClassification,
                        callback: function ($$v) {
                          _vm.selectedClassification = $$v
                        },
                        expression: "selectedClassification",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-3" },
                  [
                    _vm.selectedClassification
                      ? _c("base-input-select", {
                          attrs: {
                            options: _vm.categoriesBy(
                              _vm.selectedClassification
                            ),
                            label: _vm.say.category,
                            "label-select": `name${_vm.activeLang}`,
                          },
                          model: {
                            value: _vm.selectedCategory,
                            callback: function ($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-lg-3" }, [
                  _c(
                    "div",
                    { staticClass: "list-header__search-input" },
                    [
                      _c("base-input-text-with-icon", {
                        attrs: {
                          label: _vm.say.search,
                          icon: "fas fa-search",
                          badge: "fas fa-times",
                        },
                        on: {
                          "icon-click": function ($event) {
                            _vm.search = ""
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "container-result" },
              [
                _vm.items.length
                  ? _c("base-table", {
                      staticClass: "table-list",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.items,
                        search: _vm.search,
                        sortColumnDefault: "1",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function ({ sort }) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "table-list__row bg--white" },
                                  _vm._l(
                                    _vm.headers,
                                    function (header, column) {
                                      return _c(
                                        "th",
                                        {
                                          key: column,
                                          staticClass: "table-list__header",
                                          class: {
                                            "table-list__header-pay":
                                              header.value === "isPay",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return sort(column)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(header.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                          },
                          {
                            key: "body",
                            fn: function ({ displayedItems }) {
                              return _vm._l(displayedItems, function (item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    staticClass: "table-list__row",
                                  },
                                  _vm._l(
                                    _vm.headers,
                                    function (header, column) {
                                      return _c(
                                        "td",
                                        {
                                          key: column,
                                          staticClass: "table-list__data",
                                          class: {
                                            "align-right table-list__data-pay":
                                              header.value === "isPay",
                                          },
                                        },
                                        [
                                          header.value === "isPay" &&
                                          !item[header.value] &&
                                          ((_vm.limits[item.classificationId]
                                            .playersAvailable > 0 &&
                                            _vm.competitionSetting
                                              .playersKindLimit === "pay") ||
                                            _vm.competitionSetting
                                              .playersKindLimit == "register")
                                            ? [
                                                _vm.competition.isAutoPay !==
                                                  null &&
                                                _vm.competition.status < 3
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        staticClass: "c-orange",
                                                        attrs: {
                                                          to: {
                                                            name: "minisite-paylink",
                                                            params: {
                                                              name: _vm
                                                                .competition
                                                                .name,
                                                              id: _vm.id,
                                                              playerId: item.id,
                                                            },
                                                            alias: true,
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.say
                                                                .pay_for_participation
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.competition.status === 3
                                                  ? _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.say.not_payed
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("p"),
                                              ]
                                            : header.value === "isPay" &&
                                              !item[header.value] &&
                                              (_vm.competition.status === 3 ||
                                                (_vm.limits[
                                                  item.classificationId
                                                ].playersAvailable === 0 &&
                                                  _vm.competitionSetting
                                                    .playersKindLimit ===
                                                    "pay"))
                                            ? _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.say.not_payed) +
                                                    " "
                                                ),
                                              ])
                                            : header.value === "isPay" &&
                                              item[header.value] === true
                                            ? _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.say.payed) +
                                                    " "
                                                ),
                                              ])
                                            : header.value === "isPay" &&
                                              item[header.value] === "free"
                                            ? _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.say.feeless) +
                                                    " "
                                                ),
                                              ])
                                            : header.value == "name" &&
                                              item.isAnonymous
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.say
                                                        .anonymous_participant
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : header.value == "number"
                                            ? [
                                                item[header.value] === 0
                                                  ? _c("span", [_vm._v("-")])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item[header.value]
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[header.value]) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              })
                            },
                          },
                          {
                            key: "no-results",
                            fn: function ({ search: term }) {
                              return [
                                _c("p", { staticClass: "bg--gray pa16" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.say.no_search_results) +
                                      " "
                                  ),
                                  _c("b", [_vm._v(_vm._s(term))]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2637228964
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "mat16 lds-ellipsis lds-ellipsis--center" },
      [_c("div"), _c("div"), _c("div")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }