var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row login__content" }, [
      _c("div", { staticClass: "col-12 col-lg-3" }, [
        _c("h1", { staticClass: "par15" }, [
          _vm._v(" " + _vm._s(_vm.say.create_new_account) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-12 col-lg-9" },
        [
          _c("div", { staticClass: "login-section bg--gray row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "login-section__inputs" }, [
                _c("div", { staticClass: "row mat32" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.say.type_your_email_and_password)),
                  ]),
                ]),
                _c("div", { staticClass: "row mat24" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-5" },
                    [
                      _c("base-input-text", {
                        attrs: {
                          label: _vm.say.email_address,
                          error: _vm.errors.email,
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(_vm.errors, "email", $event)
                          },
                        },
                        model: {
                          value: _vm.credentials.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.credentials, "email", $$v)
                          },
                          expression: "credentials.email",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row mat24" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-5" },
                    [
                      _c("base-input-password", {
                        attrs: {
                          label: _vm.say.password,
                          error: _vm.errors.plainPassword,
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(_vm.errors, "plainPassword", $event)
                          },
                        },
                        model: {
                          value: _vm.credentials.plainPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.credentials, "plainPassword", $$v)
                          },
                          expression: "credentials.plainPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-4 login-section__password-hint",
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.say.password_should_contain))])]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.errors.email === "account_email_occupied"
            ? _c("base-notification-local", {
                attrs: { negative: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.say[_vm.errors.email]) + " "),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.say.troubles_with_login) + ", "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "authentication-reset",
                                  alias: true,
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("asLowerCase")(
                                      _vm.say.reset_your_password
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(" . "),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3117980037
                ),
              })
            : _vm._e(),
          _c("div", { staticClass: "row login__buttons-box" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "login__btn-primary",
                    attrs: { btn1: "" },
                    on: {
                      click: function ($event) {
                        return _vm.registerUser()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.step_next) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "login__btn-secondary",
                    attrs: { btn4: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({
                          name: "authentication-login",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.login_to_account) + " ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-lg-4" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "login__btn-cancel",
                    attrs: { btn4: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.back()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.cancel) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }