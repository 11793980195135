<template>
    <div class="container">
        <div class="row incoming-competitions">
            <div class="col-12 col-lg-3">
                <main-list-filters />
            </div>

            <div
                id="listTop"
                ref="listTop"
                class="col-12 col-lg-9 incoming-competitions__listing"
            >
                <div class="incoming-competitions__listing--padding">
                    <!-- Future competitions section. -->
                    <div
                        v-if="
                            futureCompetitions.length ||
                                promotedCompetitions.length
                        "
                    >
                        <p>
                            <b>{{ say.upcoming_competitions }}</b>
                        </p>

                        <!-- Promoted competition item. -->
                        <base-item-competition-promoted
                            v-if="
                                promotedCompetitions.length &&
                                    $route.name === 'main-list'
                            "
                            :id="promotedCompetitions[0]"
                        />

                        <!-- Future competitions list. -->
                        <base-item-competition
                            v-for="id in futureCompetitions"
                            :id="id"
                            :key="id"
                        />

                        <div
                            v-if="$route.name === 'main-landing'"
                            class="col-12 mat32"
                        >
                            <!-- See more future button. -->
                            <router-link
                                :to="{
                                    name: 'main-list',
                                    query: {
                                        od: time.format('DD.MM.YYYY'),
                                        strona: 2,
                                    },
                                    alias: true,
                                }"
                            >
                                <base-button
                                    class="incoming-competitions__listing-button"
                                    btn4
                                >
                                    {{
                                        say.see_all_upcoming_competitions
                                            | asUpperCase
                                    }}
                                </base-button>
                            </router-link>
                        </div>
                    </div>

                    <!-- Past competition section. -->
                    <div
                        v-if="pastCompetitions.length"
                        :class="{ mat32: futureCompetitions.length }"
                    >
                        <p>
                            <b>{{ say.past_competitions }}</b>
                        </p>

                        <!-- Past competitions list. -->
                        <base-item-competition
                            v-for="id in pastCompetitions"
                            :id="id"
                            :key="id"
                        />

                        <div
                            v-if="$route.name === 'main-landing'"
                            class="col-12 mat32"
                        >
                            <!-- See more past button. -->
                            <router-link
                                :to="{
                                    name: 'main-list',
                                    query: {
                                        do: time.format('DD.MM.YYYY'),
                                        strona: 2,
                                    },
                                    alias: true,
                                }"
                            >
                                <base-button
                                    btn4
                                    class="incoming-competitions__listing-button"
                                >
                                    {{
                                        say.see_all_past_competitions
                                            | asUpperCase
                                    }}
                                </base-button>
                            </router-link>
                        </div>
                    </div>

                    <!-- Pagination. -->
                    <div
                        v-if="
                            [
                                'main-list',
                                'main-site-operator',
                                'main-site-organizer',
                            ].includes($route.name)
                        "
                        class="row mat32"
                    >
                        <!-- Previous button. -->
                        <base-button
                            btn6
                            :disabled="filter.page === 1"
                            @click="prev()"
                        >
                            {{ say.previous }}
                        </base-button>

                        <!-- Next button. -->
                        <base-button
                            btn6
                            :disabled="
                                !futureCompetitions.length &&
                                    !pastCompetitions.length
                            "
                            @click="next()"
                        >
                            {{ say.next }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import { SEARCH_COMPETITIONS_LIST } from '@/store/actions.type';
import { SET_FILTER } from '@/store/mutations.type';
import MainListFilters from '@/components/MainListFilters';

export default {
    components: {
        MainListFilters,
    },
    data() {
        return {
            promotedCompetitions: [],
            futureCompetitions: [-5, -4, -3, -2, -1],
            pastCompetitions: [-5, -4, -3, -2, -1],
        };
    },
    computed: {
        ...mapGetters(['say', 'time', 'filter']),
    },
    watch: {
        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.searchCompetitions();
            },
        },
    },
    methods: {
        ...mapActions([SEARCH_COMPETITIONS_LIST]),
        ...mapMutations([SET_FILTER]),
        scrollToListTop() {
            const el = this.$refs.listTop;
            if (window.pageYOffset > el.getBoundingClientRect().top) {
                el.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
        searchCompetitions() {
            this.searchCompetitionsThrottled(this);
        },
        searchCompetitionsThrottled: _.throttle(
            (vm) => vm.searchCompetitionsDo(),
            250,
        ),
        async searchCompetitionsDo() {
            const [
                promotedCompetitions,
                futureCompetitions,
                pastCompetitions,
            ] = await this.SEARCH_COMPETITIONS_LIST();

            this.promotedCompetitions = promotedCompetitions;
            this.futureCompetitions = futureCompetitions;
            this.pastCompetitions = pastCompetitions;
        },
        prev() {
            this.setPage(this.filter.page - 1);
        },
        next() {
            this.setPage(this.filter.page + 1);
        },
        setPage(page) {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    page,
                },
                route: this.$route,
            });
            this.scrollToListTop();
        },
    },
};
</script>
