<template>
  <div>
    <h3 class="mab24">{{ say.messages }}</h3>
    <Messages v-for="(msg, index) in messages" :data="msg" :key="index"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { READ_USER_MESSAGES, UPDATE_USER_MESSAGES, READ_USER_MESSAGES_UNREAD } from '@/store/actions.type';

import Messages from '@/components/Messages';
export default {
  name: 'UserMessages',
  components: {
    Messages,
  },
  computed: {
    ...mapGetters(['say', 'messages', 'isMessagesUnread']),
  },
  methods: {
    ...mapActions([READ_USER_MESSAGES, UPDATE_USER_MESSAGES, READ_USER_MESSAGES_UNREAD]),
    async readMessages() {
      await this.READ_USER_MESSAGES();          
    }, 
    async updateMessages() {
      await this.UPDATE_USER_MESSAGES();
    }   
  },
  mounted() {
    this.READ_USER_MESSAGES_UNREAD();
    this.readMessages();
    if(this.isMessagesUnread) {
      setTimeout(() => {
        Promise.all([this.updateMessages(), this.READ_USER_MESSAGES_UNREAD(), this.readMessages()])
      }, 15000)
    }
  }
}
</script>

<style></style>