<template>
    <div
        v-if="ready"
        class="row"
        :class="[isZenMode ? 'mat40-desktop' : 'mat56-desktop']"
    >
        <!-- Configuration. -->
        <div
            v-if="!isPanelGate"
            class="col-12 col-lg-3"
        >
            <div class="controls">
                <!-- Gate select. -->
                <base-input-select
                    class="gate-select"
                    label-select="name"
                    :clearable="false"
                    :options="stopers"
                    :value="stoperGate"
                    @input="SELECT_STOPER_GATE($event)"
                />

                <div class="row controls__buttons">
                    <!-- Full screen button. -->
                    <base-button
                        btn9
                        :icon="
                            isZenMode
                                ? 'fas fa-compress-alt'
                                : 'fas fa-expand-alt'
                        "
                        @click="
                            SET_ZEN_MODE(!isZenMode),
                            events.$emit('resize-stoper')
                        "
                    />

                    <!-- Configuration button. -->
                    <base-button
                        btn9
                        class="setting-btn"
                        icon="fas fa-cog"
                        @click="
                            $router.push({
                                name: 'panel-competition-setting-stoper',
                                params: competitions[stopers[0].competition],
                                alias: true,
                            })
                        "
                    >
                        Konfiguracja
                    </base-button>
                </div>
            </div>
        </div>

        <!-- Timer box. (With reset button) -->
        <div
            v-if="!stoperStopped"
            class="col-12"
            :class="{ 'col-lg-9': !isPanelGate, 'col-lg-12': isPanelGate }"
        >
            <div
                class="row"
                :class="{ marl15: isPanelGate }"
            >
                <div class="col-lg-6 col-12 time--mobile relative">
                    <div :class="{ 'justify-content-center': isPanelGate }">
                        <div
                            v-if="stoperStarted"
                            class="mat15 d-flex"
                        >
                            <!-- Timer with milliseconds animation. -->
                            <div
                                class="time"
                                :class="{ 'time--without-margin': isPanelGate }"
                            >
                                {{ stoperDuration | asTimer }}.
                                <div class="time__milliseconds">
                                    <div
                                        class="time__milliseconds-box time__milliseconds-box--first"
                                    >
                                        <span
                                            v-for="i in 10"
                                            :key="i"
                                            class="time__milliseconds-value"
                                        >{{ i - 1 }}</span>
                                    </div>
                                    <div
                                        class="time__milliseconds-box time__milliseconds-box--second"
                                    >
                                        <span
                                            v-for="i in 10"
                                            :key="i"
                                            class="time__milliseconds-value"
                                        >{{ i - 1 }}</span>
                                    </div>
                                    <div
                                        class="time__milliseconds-box time__milliseconds-box--third"
                                    >
                                        <span
                                            v-for="i in 10"
                                            :key="i"
                                            class="time__milliseconds-value"
                                        >{{ i - 1 }}</span>
                                    </div>
                                </div>
                            </div>
                            <span
                                v-if="!isPanelGate"
                                class="reset-button"
                                @click="showPopup = !showPopup"
                            >
                                Resetuj
                            </span>
                        </div>
                        <p
                            v-else
                            class="time disabled"
                            :class="{
                                'time--without-margin': isPanelGate,
                                'time--hide': !isPanelGate && !isMobileView,
                                mat15: isPanelGate,
                            }"
                        >
                            00:00:00.0
                        </p>
                    </div>
                </div>
                <!-- Number input with stop button. (Hidden on mobile view.) -->
                <div
                    class="none-mobile"
                    :class="{
                        'col-lg-12': !stoperStarted && !isPanelGate,
                        'col-lg-6': stoperStarted || isPanelGate,
                    }"
                >
                    <!-- Stoper started. -->
                    <template v-if="stoperStarted || isPanelGate">
                        <div class="row">
                            <!-- Numbers inputs. -->
                            <div class="col-lg-9">
                                <base-input-multiple
                                    :value="numbers"
                                    :input="number"
                                    @change="numbers = $event"
                                    @input="number = $event"
                                    @enter="ADD_STOPER_MEASURE_NUMBERS()"
                                />
                            </div>
                            <!-- Stop button. -->
                            <div class="col-lg-3">
                                <base-button
                                    btn1
                                    :disabled="!stoperStarted"
                                    class="stop-btn"
                                    @click="ADD_STOPER_MEASURE_NUMBERS()"
                                >
                                    Stop
                                    <img
                                        src="@/assets/images/icon-enter.svg"
                                        class="mal12"
                                    >
                                </base-button>
                            </div>
                        </div>
                        <div class="row mat16">
                            <p class="hint">
                                Spacja dodaje numer do kolejki, Enter zatrzymuje
                                czas.
                            </p>
                        </div>
                    </template>
                    <!-- Start button. -->
                    <div
                        v-else
                        class="none-mobile bg-slate justify-content-center"
                    >
                        <base-button
                            btn1
                            class="col-lg-6"
                            @click="startStoper"
                        >
                            start
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="col-12 d-flex"
            :class="{ 'col-lg-9': !isPanelGate, 'col-lg-12': isPanelGate }"
        >
            <span class="stoper-end">Zawody zakończone</span>
        </div>
        <base-popup v-if="showPopup">
            <!-- Title. -->
            <template #title>
                Resetowanie stopera
            </template>
            <!-- Subtitle. -->
            <template #subtitle>
                <b>Czy na pewno, chcesz zresetować stoper?</b>
            </template>
            <!-- Content. -->
            <template #content>
                <div class="mat16">
                    Tej czynności nie będzie można cofnąć.
                </div>
            </template>
            <!-- Buttons. -->
            <template #buttons>
                <base-button
                    class="mar15"
                    btn2
                    @click="showPopup = !showPopup"
                >
                    anuluj
                </base-button>
                <base-button
                    class="mal15"
                    btn1
                    @click="RESET_STOPER(), (showPopup = !showPopup)"
                >
                    Resetuj
                </base-button>
            </template>
        </base-popup>
    </div>
</template>

<script>
import events from '@/events';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
    SET_ZEN_MODE,
    SET_STOPER_NUMBER,
    SET_STOPER_NUMBERS,
} from '@/store/mutations.type';
import {
    START_STOPER,
    SELECT_STOPER_GATE,
    ADD_STOPER_MEASURE_NUMBERS,
    READ_OPERATOR_DEVICES,
    READ_COMPETITION_ORGANIZER,
    RESET_STOPER,
    UPDATE_COMPETITION,
} from '@/store/actions.type';
import { DEVICE_TYPES, STATUSES } from '@/config';

export default {
    props: {
        isPanelGate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ready: false,
            showPopup: false,
            events,
            isMobileView: false,
            STATUSES,
        };
    },
    computed: {
        ...mapGetters([
            'isZenMode',
            'stoperGate',
            'stoperGates',
            'stoperDuration',
            'stoperStarted',
            'stoperNumbers',
            'stoperNumber',
            'devices',
            'stoperOrganizer',
            'stoperStopped',
            'competitions',
        ]),
        competition() {
            return this.competitions[this.$route.params.id];
        },
        numbers: {
            get() {
                return this.stoperNumbers;
            },
            set(numbers) {
                this.SET_STOPER_NUMBERS(numbers);
            },
        },
        number: {
            get() {
                return this.stoperNumber;
            },
            set(number) {
                this.SET_STOPER_NUMBER(number);
            },
        },
        stopers() {
            return this.stoperGates.filter(
                (stoper) => this.devices[stoper.device].type === DEVICE_TYPES.stoper,
            );
        },
    },
    async created() {
        if (!this.isPanelGate) {
            await this.READ_COMPETITION_ORGANIZER(this.$route.params.id);
            await this.READ_OPERATOR_DEVICES(this.stoperOrganizer.operator);
        }
        if (window.innerWidth < 992) {
            this.isMobileView = true;
        }
        this.ready = true;
    },
    methods: {
        ...mapActions([
            START_STOPER,
            SELECT_STOPER_GATE,
            ADD_STOPER_MEASURE_NUMBERS,
            READ_COMPETITION_ORGANIZER,
            READ_OPERATOR_DEVICES,
            RESET_STOPER,
            UPDATE_COMPETITION,
        ]),
        ...mapMutations([SET_ZEN_MODE, SET_STOPER_NUMBERS, SET_STOPER_NUMBER]),
        startStoper() {
            if(this.competition.status !== 4) {
                this.updateCompetitionStatus(STATUSES.live)
            }
            this.START_STOPER()
        },
        async updateCompetitionStatus(status) {
            await this.UPDATE_COMPETITION({
                ...this.competition,
                status,
            });
        },
    },
};
</script>

<style scoped>
.gate-select {
    height: 40px;
    margin-bottom: 16px;
}
.gate-select >>> .vs__search {
    height: 100%;
}
.gate-select >>> .input-field__select {
    padding-top: 0;
}
.gate-select >>> .vs__selected {
    margin-top: 0;
}
.gate-select >>> .vs__selected-options {
    padding-bottom: 0;
}
.setting-btn {
    width: 191px;
    margin-left: 16px;
}

.time {
    font-size: 64px;
    display: flex;
    margin-left: 15px;
}
.time--without-margin {
    margin-left: 0;
}
.time--hide {
    display: none !important;
}
.stop-btn {
    height: 56px;
    box-sizing: border-box;
    padding: 10px 4px 10px 12px;
    text-transform: capitalize;
    margin-left: 4px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.bg-slate {
    background-color: var(--color-slate);
    padding: 24px 0;
    border-radius: 4px;
}

.hint {
    color: var(--color-silver);
}
.controls__buttons {
    display: none;
}
.controls {
    margin-top: 16px;
}
.stoper-end {
    font-size: 24px;
    margin: auto;
    padding-top: 12px;
}
.reset-button {
    position: absolute;
    right: 0;
    top: 22px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-cool-grey);
}
.relative {
    position: relative;
}

@media (min-width: 992px) {
    .reset-button {
        bottom: -17px;
        left: 15px;
        top: unset;
        right: unset;
    }
    .mat40-desktop {
        margin-top: 40px;
    }
    .mat56-desktop {
        margin-top: 56px;
    }
    .controls {
        margin-right: 30px;
        margin-top: 0px;
    }
    .controls__buttons {
        display: flex;
    }
    .stoper-end {
        font-size: 32px;
    }
}

.time__milliseconds {
    position: relative;
    overflow: hidden;
    height: 42px;
    display: flex;
}
.time__milliseconds-value {
    height: 36px;
}
@keyframes timer {
    to {
        transform: translateY(-360px);
    }
}
@keyframes timer-desktop {
    to {
        transform: translateY(-730px);
    }
}
.time__milliseconds-box {
    display: flex;
    flex-direction: column;
}
.time__milliseconds-box--first {
    animation: timer 1s steps(10) infinite;
}
.time__milliseconds-box--second {
    animation: timer 0.5s steps(10) infinite;
}
.time__milliseconds-box--third {
    animation: timer 0.2s steps(10) infinite;
}
@media (max-width: 992px) {
    .time {
        font-size: 32px;
        margin-left: 0;
    }
    .time--mobile {
        display: flex;
        justify-content: center;
        position: relative;
    }
}
@media (min-width: 992px) {
    .time__milliseconds {
        height: 73.6px;
    }
    .time__milliseconds-value {
        height: 73px;
    }
    .time__milliseconds-box--first {
        animation: timer-desktop 1s steps(10) infinite;
    }
    .time__milliseconds-box--second {
        animation: timer-desktop 0.5s steps(10) infinite;
    }
    .time__milliseconds-box--third {
        animation: timer-desktop 0.2s steps(10) infinite;
    }
}
</style>
