var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-checkbox", class: [`is-${this.$props.variant}`] },
    [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.innerValue,
              expression: "innerValue",
            },
          ],
          attrs: { name: _vm.name, type: "checkbox" },
          domProps: {
            value: _vm.checkboxValue,
            checked: Array.isArray(_vm.innerValue)
              ? _vm._i(_vm.innerValue, _vm.checkboxValue) > -1
              : _vm.innerValue,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.innerValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.checkboxValue,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.innerValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.innerValue = $$c
                }
              },
              _vm.onChange,
            ],
          },
        }),
        _c("span", [_vm._t("default")], 2),
      ]),
      _c(
        "span",
        {
          staticClass: "panel-input-error input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }