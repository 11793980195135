<template>
    <div>
        <!-- Empty state. -->
        <div
            v-if="ready && empty"
            class="info-box--grey pa16 matb15"
        >
            <h3>{{ say.you_have_no_insurance_policies }}</h3>
            <base-button
                class="matb30 bg--white"
                btn4
                @click="$router.push({name: 'user-competitions-proposed', alias: true})"
            >
                {{ say.see_proposed_competitions }}
            </base-button>
        </div>

        <!-- Competitions list. -->
        <base-item-competition
            v-for="id in competitionsIds"
            :id="id"
            :key="id"
        />

        <!-- Find more button. -->
        <base-button
            v-if="hasMore"
            class="btn-findmore"
            btn4
            @click="readMoreCompetitions"
        >
            {{ say.see_more }}
        </base-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { READ_USER_COMPETITIONS } from '@/store/actions.type';

export default {
    data() {
        return {
            ready: false,
            competitionsIds: [],
            hasMore: true,
        };
    },
    computed: {
        ...mapGetters(['say']),
        empty() {
            return this.competitionsIds.length === 0;
        },
    },
    created() {
        this.readMoreCompetitions();
    },

    methods: {
        ...mapActions([READ_USER_COMPETITIONS]),
        async readMoreCompetitions() {
            this.ready = false;

            const { competitions } = await this.READ_USER_COMPETITIONS({
                type: 'with-policy',
                offset: this.competitionsIds.length,
                limit: 10,
            });

            this.competitionsIds = this.competitionsIds.concat(competitions);
            this.hasMore = competitions.length > 0;
            this.ready = true;
        },
    },
};
</script>

<style scoped>
.btn-findmore {
    margin: 30px 0;
    box-sizing: border-box;
}

@media (min-width: 992px) {
    .btn-findmore {
        width: 263px;
    }
}
</style>
