<template>
    <div
        class="multiple"
        :class="{'error': error}"
    >
        <div class="multiple__values">
            <span
                v-for="(val, idx) of value"
                :key="idx"
                class="value"
            >{{ val === '' ? '?' : val }}</span>
        </div>

        <input
            id="multipleInput"
            :value="input"
            class="multiple__input"
            type="text"
            :readonly="readOnly"
            @input="$event.target.value === ' ' ? null : $emit('input', $event.target.value)"
            @keydown.delete="input.length === 0 ? popItem() : null"
            @keyup.space="pushItem()"
            @keyup.enter="handleEnter(), $emit('enter')"
        >
    </div>
</template>

<script>
import input from '@/mixins/input';
import { mapGetters } from 'vuex';

export default {
    mixins: [input],
    props: {
        input: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['stoperNumbers']),
    },
    methods: {
        pushItem() {
            this.$emit('change', this.value.concat(this.input));
            this.$emit('input', '');
        },
        popItem() {
            this.$emit('change', this.value.slice(0, -1));
        },
        handleEnter() {
            if (this.input || !this.stoperNumbers.length) {
                return this.pushItem();
            }
            return null;
        },
    },
};
</script>

<style scoped>
.multiple {
    display: flex;
    background-color: white;
    padding: 8px;
    padding-right: 0;
    border-radius: 4px;
    box-sizing: border-box;
    height: 56px;
    overflow: hidden;
}
.multiple__input {
    border: none;
    width: 100%;
    overflow: hidden;
    padding-left: 4px;
}
.multiple__values {
    display: flex;
    max-width: 230px;
}
.value {
    color: var(--color-slate);
    padding: 12px 10px;
    background-color: var(--color-silver);
    margin-right: 4px;
    border-radius: 4px;
    font-weight: bold;
}
.error {
    border: 1px solid var(--color-pinky-red);
    padding: 7px;
}
</style>
