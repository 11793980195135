<template>
<div>
  <p
    v-if="label.length"
    class="mab8"
  >
    {{ label }}
  </p>

  <div
    class="input-field panel-input-autocomplete"
    :class="{ 'input-field--error': error, 'focused': focused  }"
  >
    <v-select
        ref="vselect"
        :label="optionLabel"
        class="autocompleter-competition__input"
        :options="suggestions"
        :no-drop="this.suggestions.length === 0"
        :append-to-body="true"
        :value="value"
        :filterable="false"
        :clear-search-on-select="clearSearchOnSelect"
        :clear-search-on-blur="clearSearchOnBlur"
        :clearable="clearable"
        @search="onSearch"
        @input="onSelect"
        @search:blur="focused = !focused"
        @search:focus="focused = !focused"
    >
      <template v-slot:option="option">
        <slot name="option-data" v-bind="option"></slot>
      </template>
    </v-select>
    <span v-if="$refs.vselect ? $refs.vselect.stateClasses['vs--open'] : ''" @click="$refs.vselect.blur()" class="close">
      <i class="fas fa-times" />
    </span>
</div>
<span
    class="panel-input-error input-error"
    :class="{ 'input-error--show': error && !withoutErrorMessage }"
>{{ say[error] }}</span>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VSelect from 'vue-select';
import _ from 'lodash';
import {
  AUTOCOMPLETE_COMPETITION_PLAYER,
} from '@/store/actions.type';
import input from '@/mixins/input';

export default {
    components: {
        VSelect,
    },
    mixins: [input],
    props: {
        action: {
            type: String,
            required: true,
            validator: (action) => [AUTOCOMPLETE_COMPETITION_PLAYER].includes(
                action,
            ),
        },
        actionId: {
          type: Number,
          required: true,
        },
        onlyPoland: {
            type: Boolean,
            default: false,
        },
        clearSearchOnSelect: {
            type: Boolean,
            default: false,
        },
        clearSearchOnBlur: {
            type: Function,
            default: () => false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        optionLabel: {
            type: String,
            default: 'label',
        },
    },
    data() {
        return {
            suggestions: [],
            session: 0,
            focused: false,
        };
    },
    computed: {
      ...mapGetters(['say']),
      isMobile() {
        return ['sm', 'md'].includes(this.$mq);
      },
    },
    mounted() {
      if(this.value !== '') {
        this.$refs.vselect.search = this.value
      }
    },
    methods: {
      onSearch(search, loading) {
        this.$emit('input', search)
        if(search !== this.value) {
          loading(true);
          this.autocomplete(loading, search, this);
        }
      },      
      onSelect($event) {
        this.$refs.vselect.search = $event.lastname
        this.$emit('select', $event)
      },
      autocomplete: _.debounce(async (loading, search, vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.session += 1;
        let suggestions;

        const { session } = vm;

        if (search) {
            const response = await vm.$store.dispatch(vm.action, {id: vm.actionId, search });
            if (response) {
                suggestions = response
            } 
        } else {
            suggestions = [];
        }

        loading(false);


        if (session === vm.session) {
            // eslint-disable-next-line no-param-reassign
            vm.suggestions = suggestions;
        }
    }, 250),
  },
};
</script>

<style scoped>
.input-field.panel-input-autocomplete {
  min-height: 40px;
  border-color: transparent;
  box-shadow: 0px 8px 12px rgb(0, 0, 0, 0.1);
}
.input-field.focused {
  border: solid 2px var(--color-dark-pastel-green);
}
.panel-input-autocomplete >>> .autocompleter-competition__input .vs__search,
.panel-input-autocomplete >>> .autocompleter-competition__input .vs__selected {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}
.panel-input-autocomplete >>> .vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 1;
}
.panel-input-error.input-error {
    bottom: 2px;
    left: 20px;
}
.panel-input-autocomplete {
  position: relative;
}
.panel-input-autocomplete .close {
  position: absolute;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  z-index: 55;
}
</style>