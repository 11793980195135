import find from '@/store/modules/entities/find';
import { many, take } from '@/utils/array';
import { pick } from 'lodash';

export default {
    agesBy: (state) => (entity) => find(state.ages, entity),
    categoriesBy: (state) => (entity) => {
        const [{ _type: type }] = many(entity);

        if (type === 'player') {
            return find(state.categories, entity, true);
        }

        return find(state.categories, entity);
    },
    checkpointsBy: (state) => (entity) => {
        const [{ _type: type }] = many(entity);

        if (type === 'checkpointGroup') {
            return Object.values(pick(state.checkpoints, entity.checkpointsIds));
        }

        return find(state.checkpoints, entity, false, undefined, type === 'gate');
    },
    childrenAssociationsBy: (state) => (entity) => find(state.familyAssociations, entity, false, 'parentUser'),
    classificationsBy: (state) => (entity) => find(state.classifications, entity, take(many(entity), '_type')[0] === 'player'),
    competitionsBy: (state) => (entity) => find(state.competitions, entity, true),
    devicesBy: (state) => (entity) => find(state.devices, entity),
    gatesBy: (state) => (entity) => {
        const isEntityCheckpoint = take(many(entity), '_type')[0] === 'checkpoint';

        return find(state.gates, entity, isEntityCheckpoint, undefined, isEntityCheckpoint);
    },
    layoutsBy: (state) => (entity) => find(state.layouts, entity),
    limitsBy: (state) => (entity) => find(state.limits, entity),
    playersBy: (state) => (entity) => find(state.players, entity),
    pricesBy: (state) => (entity) => find(state.prices, entity),
    measuresBy: (state) => (entity) => find(state.measures, entity),
    trackpointsBy: (state) => (entity) => find(state.trackpoints, entity, false, undefined, true),
    parentsAssociationsBy: (state) => (entity) => find(state.familyAssociations, entity, false, 'childUser'),
};
