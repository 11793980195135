<template>
    <div
        class="input-field input-field--focus-bolder"
        :class="{ 'input-field--error': error }"
    >
        <label
            v-if="!error && label"
            class="input-field__label"
            :class="{ 'input-field__label--show': isInput }"
        >{{ label }}</label>

        <v-select
            ref="VSelect"
            label="text"
            class="autocompleter-competition__input"
            :class="{
                'input-field__input--padding-top':
                    (isInputValid || withoutErrorMessage) && label,
                'input-field__input--padding-bottom':
                    error && !withoutErrorMessage,
            }"
            :options="suggestions"
            :no-drop="suggestions.length === 0"
            :placeholder="placeholder"
            :append-to-body="true"
            :value="value"
            :filterable="false"
            :clear-search-on-select="clearSearchOnSelect"
            :clear-search-on-blur="clearSearchOnBlur"
            :clearable="clearable"
            @search="$emit('input', $event)"
            @input="$emit('select', $event)"
            @search:blur="$emit('search-blur')"
        />

        <span
            class="input-error"
            :class="{ 'input-error--show': error && !withoutErrorMessage }"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VSelect from 'vue-select';
import _ from 'lodash';
import {
    AUTOCOMPLETE_COMPETITION_NAME,
    AUTOCOMPLETE_LOCATION,
} from '@/store/actions.type';
import input from '@/mixins/input';

export default {
    components: {
        VSelect,
    },
    mixins: [input],
    props: {
        action: {
            type: String,
            required: true,
            validator: (action) => [AUTOCOMPLETE_COMPETITION_NAME, AUTOCOMPLETE_LOCATION].includes(
                action,
            ),
        },
        onlyPoland: {
            type: Boolean,
            default: false,
        },
        clearSearchOnSelect: {
            type: Boolean,
            default: false,
        },
        clearSearchOnBlur: {
            type: Function,
            default: () => false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            suggestions: [],
            session: 0,
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
    watch: {
        value() {
            this.autocomplete(this);
        },
    },
    methods: {
        autocomplete: _.debounce(async (vm) => {
            // eslint-disable-next-line no-param-reassign
            vm.session += 1;

            let suggestions;
            let hasMoreSuggestions;

            const { session } = vm;

            if (vm.value) {
                const response = await vm.$store.dispatch(vm.action, vm.value);
                if (response.suggestions) {
                    suggestions = response.suggestions;
                    hasMoreSuggestions = response.hasMoreSuggestions;
                } else {
                    suggestions = response;
                }
            } else {
                vm.$refs.VSelect.onEscape()
                suggestions = [];
                hasMoreSuggestions = false;
            }

            if (hasMoreSuggestions) {
                suggestions.push({
                    text: 'Zobacz więcej',
                    value: 0,
                });
            }

            if (session === vm.session) {
                // eslint-disable-next-line no-param-reassign
                vm.suggestions = suggestions;
            }
        }, 250),
    },
};
</script>
