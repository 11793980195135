<template>
    <div class="dark-bg dark-bg--fixed">
        <div
            class="popup"
            :class="className"
        >
            <div class="popup__label">
                <!-- Slot for title on dark label. -->
                <slot name="title" />
            </div>
            <div class="popup__content">
                <!-- Slot for subtitle. -->
                <slot name="subtitle" />
                <!-- Slot for content. -->
                <slot name="content" />
            </div>
            <div class="popup__buttons">
                <!-- Slot for buttons. -->
                <slot
                    name="buttons"
                    class="mat16"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        className: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
.popup {
    position: fixed;
    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: #eceff1;
    line-height: 1.43;
    min-width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: var(--color-slate-two);
}
.popup__content {
    padding: 40px 30px 24px 30px;
    border-bottom: 1px solid rgba(84, 110, 122, 0.1);
    color: var(--color-slate);
    background-color: var(--color-white);
}
.popup__label {
    background-color: var(--color-slate-two);
    padding: 14px;
}
.popup__buttons {
    display: flex;
    padding: 24px 30px;
    background-color: var(--color-white);
}
.popup__buttons > * {
    height: 40px;
    box-sizing: border-box;
    text-transform: uppercase;
    width: 100%;
}
.relative-box {
    position: relative;
    height: 100%;
    width: 100%;
}
@media (min-width: 992px) {
    .popup {
        width: 614px;
    }
}
</style>
