<template>
    <div
        class="dropdown"
        :class="{ 'dropdown--dark': isPanel }"
    >
        <div
            class="dropdown__button"
            :class="{
                'dropdown__button--center dropdown__button--pointer': isExistUserRole,
                'dropdown__button--border-dark': isPanel,
            }"
            @click="navigateToUserProfile()"
        >
            <div>
                <img
                    class="dropdown__button-img mat7"
                    :src="
                        isPanel
                            ? require('@/assets/images/t-shirt-icon-white.svg')
                            : require('@/assets/images/t-shirt-icon.svg')
                    "
                >
            </div>
            <div class="dropdown__button-box">
                <base-button
                    v-if="!isExistUserRole"
                    class="dropdown__button-create"
                    :class="{ 'dropdown__button-create--white': isPanel }"
                    btn4
                    @click="createUserRole()"
                >
                    {{ say.create_participant_profile }}
                </base-button>
                <template v-else>
                    <input
                        id="participant"
                        type="radio"
                        name="participant"
                        class="d-none"
                        :checked="!isPanel"
                    >
                    <div class="dropdown__button-radio">
                        {{ say.participant_profile }}
                    </div>
                </template>

                <div
                    v-if="!withoutHints && !isExistUserRole"
                    class="dropdown__button-description"
                >
                    <p>• {{ say.collect_results }}</p>
                    <p>• {{ say.save_more_efficiently }}</p>
                </div>
            </div>
        </div>
        <div
            class="dropdown__button"
            :class="{
                'dropdown__button--center dropdown__button--pointer': isExistPanelRole,
                'dropdown__button--border-dark': isPanel,
            }"
            @click="navigateToPanel()"
        >
            <img
                class="dropdown__button-img mat7"
                :src="
                    isPanel
                        ? require('@/assets/images/stoper-icon-white.svg')
                        : require('@/assets/images/stoper-icon.svg')
                "
            >
            <div class="dropdown__button-box">
                <base-button
                    v-if="!isExistPanelRole"
                    class="dropdown__button-create"
                    :class="{ 'dropdown__button-create--white': isPanel }"
                    btn4
                    @click="createOrganizerRole"
                >
                    {{ say.create_organizer_profile }}
                </base-button>
                <template v-else>
                    <input
                        id="participant"
                        type="radio"
                        name="participant"
                        class="d-none"
                        :checked="isPanel"
                    >
                    <div class="dropdown__button-radio">
                        {{ profileType }}
                    </div>
                </template>
                <div
                    v-if="!withoutHints && !isExistPanelRole"
                    class="dropdown__button-description"
                >
                    <p>• {{ say.create_competition_site }}</p>
                    <p>• {{ say.run_registration_form }}</p>
                    <p>• {{ say.measure_results }}</p>
                </div>
            </div>
        </div>
        <div
            v-if="isExistUserRole || isExistPanelRole"
            class="dropdown__button dropdown__button--center dropdown__button--pointer"
            :class="{ 'dropdown__button--border-dark': isPanel }"
            @click="$router.push({ name: 'edit-credentials' })"
        >
            <img
                class="dropdown__button-img"
                :src="
                    isPanel
                        ? require('@/assets/images/edit-pencil-white.svg')
                        : require('@/assets/images/edit-pencil.svg')
                "
            >
            {{ say.change_password }}
        </div>
        <div
            class="dropdown__button dropdown__button--center dropdown__button--pointer"
            :class="{ 'dropdown__button--border-dark': isPanel }"
            @click="logoutUser()"
        >
            <img
                class="dropdown__button-img"
                :src="
                    isPanel
                        ? require('@/assets/images/sign-out-white.svg')
                        : require('@/assets/images/sign-out.svg')
                "
            >
            {{ say.logout }}
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
    ADD_ORGANIZER_ROLE,
    ADD_USER_ROLE,
    LOGOUT_USER,
    UPDATE_USER,
} from '@/store/actions.type';

export default {
    props: {
        isPanel: {
            type: Boolean,
            default: false,
        },
        withoutHints: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['user', 'operators', 'organizers', 'say']),
        isExistUserRole() {
            return this.user.roles.includes('ROLE_USER');
        },
        isExistPanelRole() {
            return this.user.roles.some(
                (role) => role === 'ROLE_ORGANIZER'
                    || role === 'ROLE_OPERATOR'
                    || role === 'ROLE_ADMIN',
            );
        },
        isAdmin() {
            return this.user.roles.includes('ROLE_ADMIN');
        },
        isOperator() {
            return this.user.roles.includes('ROLE_OPERATOR');
        },
        profileType() {
            if (this.isAdmin) {
                return 'Panel admina';
            }
            if (this.isOperator) {
                return 'Panel operatora';
            }
            return 'Panel organizatora';
        },
    },
    methods: {
        ...mapActions([
            LOGOUT_USER,
            ADD_ORGANIZER_ROLE,
            ADD_USER_ROLE,
            UPDATE_USER,
        ]),
        async logoutUser() {
            await this.LOGOUT_USER();

            this.$router.replace({
                name: 'authentication-login',
                alias: true,
            });
        },
        // Create user / organizer.
        async createUserRole() {
            await this.ADD_USER_ROLE();
            this.navigateToUserProfile();
        },
        async createOrganizerRole() {
            await this.ADD_ORGANIZER_ROLE();
            this.navigateToPanel();
        },

        // Navigation.
        navigateToUserProfile() {
            this.UPDATE_USER({
                id: this.user.id,
                loginTo: null,
            });
            this.$router.push({
                name: 'user-competitions-future',
                alias: true,
            });
        },
        navigateToPanel() {
            this.UPDATE_USER({ id: this.user.id, loginTo: 'panel' });

            if (this.isAdmin) {
                this.$router.push({ name: 'panel-admin', alias: true });
            } else if (this.isOperator) {
                this.$router.push({ name: 'panel-operator', alias: true });
            } else {
                this.$router.push({ name: 'panel-organizer', alias: true });
            }
        },
    },
};
</script>

<style scoped>
.dropdown {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    background-color: var(--color-white);
}
.dropdown--dark {
    background-color: var(--color-slate);
    border: 1px solid #37474f;
}
.dropdown__button {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;
    align-items: baseline;
}
.dropdown__button--pointer {
    cursor: pointer;
}
.dropdown__button-img {
    height: 34px;
    margin-right: 16px;
}
.dropdown__button-create {
    max-height: 48px;
    font-size: 14px;
    color: var(--color-greyish-brown) !important;
}
.dropdown__button-description {
    line-height: 24px;
    margin-top: 16px;
    font-size: 14px;
    white-space: normal;
}
/* Styles for minisite */
.dropdown__button-description p {
    margin-bottom: 0;
    text-align: left;
}
/*  */
.dropdown__button-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.dropdown__button--center {
    align-items: center;
}
.dropdown__button-radio {
    position: relative;
    font-size: 14px;
}
.dropdown__button-radio:before {
    /* green dot */
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    background: #fff;
    box-sizing: border-box;
    border-radius: 50%;
}

.dropdown__button-radio:after {
    /* border of dot  */
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    border: solid 1px var(--color-greyish-brown);
    background: var(--color-white);
    box-sizing: border-box;
    border-radius: 50%;
}
.dropdown input[id='participant']:checked + .dropdown__button-radio:before {
    background-color: var(--color-dark-pastel-green);
    transition: background var(--animation-fast);
}
.dropdown__button-create--white {
    color: white !important;
}
.dropdown__button--border-dark {
    border-bottom: 1px solid #37474f;
}
@media (min-width: 768px) {
    .dropdown {
        width: 300px;
    }
}
</style>
