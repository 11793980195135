<template>
    <div v-if="ready">
        <div v-if="empty">
            <div class="info-box--grey pa16 matb15">
                <h3>{{ say.empty_parents }}</h3>
                <p>{{ say.empty_parents_hint }}</p>
            </div>
        </div>

        <user-family-item-parent
            v-for="association of parentsAssociations"
            :id="association.id"
            :key="association.id"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserFamilyItemParent from '@/components/UserFamilyItemParent';
import { READ_USER_PARENTS } from '@/store/actions.type';

export default {
    components: {
        UserFamilyItemParent,
    },
    data() {
        return {
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['say', 'user', 'parentsAssociationsBy']),
        parentsAssociations() {
            return this.parentsAssociationsBy(this.user);
        },
        empty() {
            return this.parentsAssociations.length === 0;
        },
    },
    async created() {
        await this.READ_USER_PARENTS();

        this.ready = true;
    },
    methods: {
        ...mapActions([READ_USER_PARENTS]),
    },
};
</script>
