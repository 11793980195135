var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { ref: "minisite-top", staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row justify-content-start" },
      [
        _vm.ready
          ? _c("minisite-sidebar", { attrs: { id: _vm.id } })
          : _vm._e(),
        !_vm.ready
          ? _c("div", { staticClass: "col-12 col-md-3 mat33 pat15" }, [
              _c("div", { staticClass: "minisite-sidebar--skeleton" }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-9 mat48" }, [
          _vm.ready && !_vm.competitionSetting[`${_vm.lang}On`]
            ? _c("div", { staticClass: "row mab32" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "nolang" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.say.translation_not_provided_by_organizer
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.ready && _vm.competition.isRemote && _vm.competition.id !== 4762
            ? _c("div", { staticClass: "row minisite-section" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "bg--green remote-competition" }, [
                    _c(
                      "div",
                      { staticClass: "row justify-content-space-between" },
                      [
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-lg-7 remote-competition__description",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "remote-competition__description-header",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.say.remote_competition) + " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.say.remote_competition_information
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          !_vm.ready
            ? _c(
                "div",
                {
                  staticClass:
                    "row minisite-section minisite-section--without-padding",
                },
                [_vm._m(1)]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "row minisite-section minisite-section--without-padding",
                },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.competition.formType === "registration"
                      ? _c(
                          "div",
                          { staticClass: "row competition__info" },
                          [
                            _c("div", { staticClass: "col-12 col-lg-5" }, [
                              _c(
                                "p",
                                { staticClass: "pair-lbl pair-lbl--no-margin" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.say.competition_status) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.competition.status === 2 &&
                              _vm.limits.playersAvailable === 0
                                ? _c(
                                    "p",
                                    { staticClass: "pair-inf tc--negative" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.say.statuses_3) + " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "p",
                                    {
                                      staticClass: "pair-inf",
                                      class: {
                                        "tc--positive": [2, 6].includes(
                                          _vm.competition.status
                                        ),
                                        "tc--negative": ![2, 6].includes(
                                          _vm.competition.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("asStatus")(
                                              _vm.competition.status
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]),
                            _vm.competition.preStartedTime
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-lg-4" },
                                    [
                                      _c("p", { staticClass: "pair-lbl" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.say.duration_of_competition
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("p", { staticClass: "pair-inf" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("asDate")(
                                                _vm.competition.preStartedTime
                                              )
                                            ) +
                                            " - "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "pair-inf-countdown" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("asDate")(
                                                  _vm.competition.startedTime
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : _vm.competition.status === 3
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-lg-4" },
                                    [
                                      _c("p", { staticClass: "pair-lbl" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.say.registration_end_in
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pair-inf pair-inf-countdown",
                                        },
                                        [_vm._v("0d 0g 0m")]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-lg-4" },
                                    [
                                      _c("p", { staticClass: "pair-lbl" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.say.registration_end_in
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pair-inf pair-inf-countdown",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("asCounter")(
                                                  _vm.competition.provisionTime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                            _c(
                              "div",
                              { staticClass: "col-12 col-lg-3" },
                              [
                                (_vm.competition.status === 3 &&
                                  _vm.limits.playersLimit > 0) ||
                                (_vm.competition.status === 2 &&
                                  _vm.limits.playersAvailable === 0)
                                  ? [
                                      _c("p", { staticClass: "pair-lbl" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.say.available) + " "
                                        ),
                                      ]),
                                      _vm.limits.playersAvailable === 0
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pair-inf tc--negative",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.say.age_limit_none
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pair-inf tc--negative",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.say.time_is_up) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : [
                                      _vm.canRegister(
                                        _vm.competition,
                                        _vm.limits
                                      ) && _vm.limits.playersAvailable !== true
                                        ? [
                                            _c(
                                              "p",
                                              { staticClass: "pair-lbl" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.say.available) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "pair-inf" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "pair-inf-countdown",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.limits
                                                          .playersAvailable
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        _vm.limits.playersLimit
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "p",
                                              { staticClass: "pair-lbl" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.say
                                                        .number_of_participants
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "pair-inf" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.limits
                                                        .playersUnavailable
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                              ],
                              2
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row competition__info" }, [
                      _c("div", { staticClass: "col-12 col-lg-5" }, [
                        _c("p", { staticClass: "pair-lbl" }, [
                          _vm._v(" " + _vm._s(_vm.say.location) + " "),
                        ]),
                        _vm.competition.location
                          ? _c(
                              "p",
                              { staticClass: "pair-inf pair-inf--wrap" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.competition.location) + " "
                                ),
                              ]
                            )
                          : _c(
                              "p",
                              { staticClass: "pair-inf pair-inf--wrap" },
                              [_vm._v(" " + _vm._s(_vm.say.whole_world) + " ")]
                            ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-4" },
                        [
                          _vm.competition.websitePl
                            ? [
                                _c("p", { staticClass: "pair-lbl" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.say.competition_website) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass: "pair-inf cpointer",
                                    attrs: { href: _vm.competition.websitePl },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          path: _vm.competition.websitePl,
                                          tab: true,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.competition.websitePl) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-3" },
                        [
                          !_vm.competition.preStartedTime
                            ? [
                                _c("p", { staticClass: "pair-lbl" }, [
                                  _vm._v(" " + _vm._s(_vm.say.start) + " "),
                                ]),
                                _vm.competition.startedTime
                                  ? _c("p", { staticClass: "pair-inf" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("asTime")(
                                              _vm.competition.startedTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "row competition__info" }, [
                      _c("div", { staticClass: "col-12 col-lg-5" }, [
                        _c("p", { staticClass: "pair-lbl" }, [
                          _vm._v(" " + _vm._s(_vm.say.organizer) + " "),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "pair-inf",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "main-site-organizer",
                                  params: _vm.organizer,
                                  alias: true,
                                })
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.organizer.name) + " ")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-12 col-lg-4" }, [
                        _c("p", { staticClass: "pair-lbl" }, [
                          _vm._v(" " + _vm._s(_vm.say.organizer_email) + " "),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "pair-inf pair-inf--wrap pointer",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "minisite-contact",
                                  params: _vm.competition,
                                  alias: true,
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.competition.contactEmail) + " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-12 col-lg-3" }, [
                        _c("p", { staticClass: "pair-lbl" }, [
                          _vm._v(" " + _vm._s(_vm.say.organizer_phone) + " "),
                        ]),
                        _c("p", { staticClass: "pair-inf" }, [
                          _vm._v(
                            " " + _vm._s(_vm.competition.contactPhone) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
          _vm.ready && _vm.competition.remarksPl
            ? _c("div", { staticClass: "row minisite-section" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "boxremarks" }, [
                    _c("h4", [
                      _vm._v(_vm._s(_vm._f("asUpperCase")(_vm.say.attention))),
                    ]),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.sanitizeHtml(
                            _vm.$options.filters.asLink(
                              _vm.$options.filters.asLang(
                                _vm.competition,
                                "remarks",
                                _vm.lang
                              )
                            )
                          )
                        ),
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row minisite-section" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("h4", { staticClass: "mab16" }, [
                  _vm._v(" " + _vm._s(_vm.say.classifications) + " "),
                ]),
                !_vm.ready
                  ? _vm._l(1, function (i) {
                      return _c("base-item-classification", {
                        key: i,
                        attrs: {
                          id: 0,
                          "competition-id": _vm.id,
                          "is-light": "",
                        },
                      })
                    })
                  : _vm._l(_vm.classifications, function (classification) {
                      return _c("base-item-classification", {
                        key: classification.id,
                        attrs: {
                          id: classification.id,
                          "competition-id": _vm.id,
                          "is-light": "",
                        },
                      })
                    }),
              ],
              2
            ),
          ]),
          _vm.ready && _vm.competition.descriptionPl
            ? _c("div", { staticClass: "row minisite-section" }, [
                _c("div", {
                  staticClass: "col-12 pre-line",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.sanitizeHtml(
                        _vm.$options.filters.asLink(
                          _vm.$options.filters.asLang(
                            _vm.competition,
                            "description",
                            _vm.lang
                          )
                        )
                      )
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _vm.ready && _vm.isGpxTrack
            ? _c("div", { staticClass: "row minisite-section" }, [
                _vm.isReadyMap
                  ? _c("div", { staticClass: "col-12 mab16" }, [
                      _c("h4", [_vm._v(_vm._s(_vm.say.map))]),
                    ])
                  : _vm._e(),
                _vm.isReadyMap
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "row minisite-section__nav" },
                        _vm._l(_vm.classifications, function (classification) {
                          return _c(
                            "div",
                            {
                              key: classification.id,
                              staticClass: "minisite-section__nav-btn",
                              class: {
                                "minisite-section__nav-btn--active":
                                  _vm.selectedClassification.id ===
                                  classification.id,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.selectedClassification = classification
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    classification[
                                      `name${
                                        _vm.lang.charAt(0).toUpperCase() +
                                        _vm.lang.slice(1)
                                      }`
                                    ]
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("map-competition", {
                      attrs: {
                        "disable-scroll": "",
                        competition: _vm.competition,
                        classifications: _vm.classifications,
                        "selected-classification": _vm.selectedClassification,
                        "read-only": "",
                        fullscreenable: false,
                      },
                      on: {
                        "update:selectedClassification": function ($event) {
                          _vm.selectedClassification = $event
                        },
                        "update:selected-classification": function ($event) {
                          _vm.selectedClassification = $event
                        },
                        "map:ready": function ($event) {
                          ;(_vm.isGpxTrack = $event), (_vm.isReadyMap = $event)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "optional-button",
                            fn: function () {
                              return [
                                _c(
                                  "l-control",
                                  { attrs: { position: "bottomright" } },
                                  [
                                    _vm.gpxFiles[_vm.selectedClassification.id]
                                      ? _c(
                                          "base-button-download",
                                          {
                                            staticClass:
                                              "minisite-section__btn-download",
                                            attrs: {
                                              disabled:
                                                _vm.gpxFiles[
                                                  _vm.selectedClassification.id
                                                ].url === null,
                                              url: _vm.gpxFiles[
                                                _vm.selectedClassification.id
                                              ].url,
                                              "file-name":
                                                _vm.selectedClassification
                                                  .namePl + ".gpx",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.say.download_gpx_track
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3013377069
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _vm.ready &&
              _vm.competition.locationLat &&
              _vm.competition.locationLng &&
              _vm.baseLocationPoint
            ? _c("div", { staticClass: "row minisite-section" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h4", { staticClass: "mab16" }, [
                    _vm._v(" " + _vm._s(_vm.say.location) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "minisite-section__map" },
                    [
                      _c(
                        "base-map",
                        {
                          attrs: {
                            "disable-scroll": "",
                            lat: _vm.competition.locationLat,
                            lng: _vm.competition.locationLng,
                            fullscreenable: false,
                            zoom: 12,
                          },
                        },
                        _vm._l(_vm.points, function (point) {
                          return _c("map-competition-point", {
                            key: point.id,
                            attrs: {
                              "read-only": "",
                              point: point,
                              "competition-id": _vm.id,
                              "selected-classification-id":
                                _vm.selectedClassification.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.ready && _vm.nextInCycleIds.length
            ? _c("div", { staticClass: "row minisite-section" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h4", { staticClass: "mab0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.say.other_cycle_competitions) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row cycles pab25" },
                    _vm._l(_vm.nextInCycle, function (cmp) {
                      return _c("base-item-competition-small", {
                        key: cmp.id,
                        attrs: { id: cmp.id },
                      })
                    }),
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.ready
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("facebook-comments", {
                      attrs: {
                        settings: {
                          href: _vm.competition.permaLink,
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-12 col-lg-5 d-flex justify-content-center" },
      [
        _c("img", {
          staticClass: "remote-competition__image",
          attrs: { src: require("@/assets/images/illustration-remote.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "row competition__info" }, [
        _c("div", { staticClass: "col-12 col-lg-5" }, [
          _c("p", {
            staticClass: "pair-lbl pair-lbl--no-margin pair-lbl--skeleton",
          }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
      ]),
      _c("div", { staticClass: "row competition__info" }, [
        _c("div", { staticClass: "col-12 col-lg-5" }, [
          _c("p", {
            staticClass: "pair-lbl pair-lbl--no-margin pair-lbl--skeleton",
          }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
      ]),
      _c("div", { staticClass: "row competition__info" }, [
        _c("div", { staticClass: "col-12 col-lg-5" }, [
          _c("p", {
            staticClass: "pair-lbl pair-lbl--no-margin pair-lbl--skeleton",
          }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("p", { staticClass: "pair-lbl pair-lbl--skeleton" }),
          _c("p", { staticClass: "pair-inf pair-inf--skeleton" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }