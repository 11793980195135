var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "panel-radio-field",
      attrs: { role: "group", "aria-labelledby": _vm.name },
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    },
    [
      _c("p", { staticClass: "mab8", attrs: { id: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c(
        "div",
        {
          staticClass: "panel-radio-field__wrap",
          class: { "input-field--error": _vm.error },
        },
        [
          _vm._l(_vm.options, function (option) {
            return [
              _c(
                "div",
                {
                  staticClass: "panel-radio-wrapper",
                  class: { w100: _vm.wrap },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.innerValue,
                        expression: "innerValue",
                      },
                    ],
                    key: `${_vm.name}_${option.value}`,
                    attrs: {
                      id: `${_vm.name}_${option.value}`,
                      type: "radio",
                      name: _vm.name,
                    },
                    domProps: {
                      value: option.value,
                      checked: _vm._q(_vm.innerValue, option.value),
                    },
                    on: {
                      change: function ($event) {
                        _vm.innerValue = option.value
                      },
                    },
                  }),
                  _c(
                    "label",
                    { attrs: { for: `${_vm.name}_${option.value}` } },
                    [_vm._v(_vm._s(_vm.say[option.title] || option.title))]
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "panel-input-error input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }