var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "notification__center" }, [
      _c("h4", { staticClass: "notification__header" }, [_vm._t("header")], 2),
      _c("p", { staticClass: "notification__content" }, [_vm._t("content")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }