<template>
    <div class="h100">
        <pwa-notification />
        <component :is="`layout-${layout}`" />
    </div>
</template>

<script>
import Vue from 'vue';
import style from '@/assets/style/app.css';
import LayoutFront from '@/components/LayoutFront';
import LayoutBack from '@/components/LayoutBack';
import PwaNotification from '@/components/PwaNotification';
// Register layouts.
Vue.component('layout-front', LayoutFront);
Vue.component('layout-back', LayoutBack);

// Include modern stylesheet as default.
style.use();
export default {
    components: {
        PwaNotification,
    },
    props: {
        layout: {
            type: String,
            required: true,
        },
    },
    created() {
        if(this.$messaging) {
            this.$messaging.onMessage((payload) => {
                //console.log('Message received. ', payload);
                
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                    body: payload.notification.body,
                    click_action: payload.notification.click_action
                };
    
                if (!("Notification" in window)) {
                    alert("This browser does not support system notifications");
                }
                else {
                if (Notification.permission === "granted") {
                    try {
                    var notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = function(event) {
                        event.preventDefault();
                        window.open(payload.notification.click_action, '_self');
                        notification.close();
                    }
                    } catch (err) {
                    try { 
                        navigator.serviceWorker.ready.then(function(registration) {              
                        registration.showNotification(notificationTitle, notificationOptions);
                        });
                    } catch (err1) {
                        alert(err1.message);
                    }
                    }
                }
                }
            });
        }
    }, 
    mounted() {
        document.querySelector('.loading').remove()
    },   
};
</script>

<!-- Include common stylesheet. -->
<style src="@/assets/style/font.css"></style>
