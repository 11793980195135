<template>
    <div 
      role="group" :aria-labelledby="name"
      class="panel-radio-field"
    >
        <p :id="name" class="mab8">{{ label }}</p>
        <div class="panel-radio-field__wrap">
          <template v-for="option of options">
            <input
                :id="`${name}_${option.value}`"
                :key="option.value"
                v-model="innerValue"
                type="radio"
                :value="option.value"
                :name="name"
            >
            <label
                :key="option.title"
                :for="`${name}_${option.value}`"
            ><span>{{ say[option.title] || option.title }}</span></label>
          </template>
        </div>

        <!-- 
          @change="$emit('input', $event.target.value)"
          <div class="panel-radio-field__wrap">
          <template v-for="option of options">
              <input
                  :id="option.value"
                  :key="option.value"
                  v-model="picked"
                  type="radio"
                  class="d-none"
                  :value="option.value"
              >
              <label
                  :key="option.title"
                  class="panel-radio-field__label"
                  :for="option.value"
              >{{ say[option.title] || option.title }}</label>
          </template>
        </div> -->

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        options: {
            // [ { value: String, title: String }, ... ]
            type: Array,
            required: true,
        },
        name: {
          type: String,
          required: true,
        },
        value: {
            type: null,
            default: null,
        },          
    },
    data () {
        return {
            innerValue: null,
        }
    },
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        },
    },
    created() {
        if (this.value || this.value === false) {
            this.innerValue = this.value
        }
    },  
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.panel-radio-field {
  border: 0;
  padding: 0;
  margin: 0;
  color: var(--color-slate);
}
.panel-radio-field__wrap {
  position: relative;
  height: 40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
input[type=radio] + label {
  position: relative;
  width: calc(50% - 4px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
input[type=radio] + label span { 
  position: relative;
}

input[type=radio] + label::before {
  content: "";
  transition: border 0.5s ease-in-out;
  display: inline-block;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #455a64;
  position: absolute;
  top: 0;
  left: 0;
}

.panel-radio-field__wrap input[type=radio]:checked + label:first-of-type::before {
  background: #50AE55;
}

.panel-radio-field__wrap input[type=radio]:checked + label:last-of-type::before {
  background: #ff1744;
}
.panel-radio-field__wrap input[type=radio]:focus + label:first-of-type::before {
  box-shadow: 0 0px 8px #50AE55;
}
.panel-radio-field__wrap input[type=radio]:focus + label:last-of-type::before {
  box-shadow: 0 0px 8px #ff1744;
}
</style>