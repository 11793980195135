<template>
    <div class="container matb45">
        <div class="row">
            <div class="col-12">
                <h1 class="mab16">
                    {{ say.error_404 }}
                </h1>

                <router-link :to="{name: 'main-landing', alias: true}">
                    {{ say.go_home }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
