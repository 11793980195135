<template>
    <section
        class="classification"
        :class="{
            'classification--dark': !isLight,
            'classification--light': isLight,
        }"
    >
        <div class="row palr15">
            <!-- Name. -->
            <div class="col-12 col-lg-4">
                <p
                    v-if="!id"
                    class="classification__text-skeleton classification__text-skeleton--margin"
                />
                <h5
                    v-else
                    class="classification__name"
                >
                    {{ classification.namePl }}
                </h5>
            </div>

            <div class="col-12 col-lg-8 classification__description">
                <!-- Basic information. -->
                <div class="row">
                    <!-- Distance. -->
                    <div class="col-sm-3 col-4 col-md-3 col-lg-3">
                        <p
                            class="nowrap classification__description-label"
                            :class="{
                                'classification__description-label--light-mode': isLight,
                            }"
                        >
                            {{ say.distance }}
                        </p>
                        <p
                            v-if="!id"
                            class="classification__text-skeleton mat8i"
                        />
                        <p
                            v-else
                            class="classification__description-value"
                            :class="{
                                'classification__description-value--light-mode': isLight,
                            }"
                        >
                            {{ classification.distance }} km
                        </p>
                    </div>

                    <!-- Gender. -->
                    <div class="col-sm-2 col-4 col-md-2 col-lg-3">
                        <p
                            class="nowrap classification__description-label"
                            :class="{
                                'classification__description-label--light-mode': isLight,
                            }"
                        >
                            {{ say.gender }}
                        </p>
                        <p
                            v-if="!id"
                            class="classification__text-skeleton mat8i"
                        />
                        <p
                            v-else
                            class="classification__description-value"
                            :class="{
                                'classification__description-value--light-mode': isLight,
                            }"
                        >
                            <template v-if="canM && canK">
                                {{ say.woman_short }},{{ say.man_short }}
                            </template>
                            <template v-else-if="canM">
                                {{ say.man_short }}
                            </template>
                            <template v-else-if="canK">
                                {{ say.woman_short }}
                            </template>
                        </p>
                    </div>

                    <!-- Age. -->
                    <div class="col-sm-3 col-4 col-md-2 col-lg-3">
                        <p
                            class="nowrap classification__description-label"
                            :class="{
                                'classification__description-label--light-mode': isLight,
                            }"
                        >
                            {{ say.age }}
                        </p>
                        <p
                            v-if="!id"
                            class="classification__text-skeleton mat8i"
                        />
                        <p
                            v-else
                            class="classification__description-value"
                            :class="{
                                'classification__description-value--light-mode': isLight,
                            }"
                        >
                            {{ age.from }}-{{ age.to }}
                        </p>
                    </div>

                    <!-- Limits. -->
                    <template v-if="!id">
                        <div
                            class="col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits"
                        >
                            <p
                                class="classification__description-label"
                                :class="{
                                    'classification__description-label--light-mode': isLight,
                                }"
                            >
                                {{ say.available }}
                            </p>
                            <p class="classification__text-skeleton mat8i" />
                        </div>
                    </template>
                    <template v-else>
                        <!-- @todo need for classifications in minisite. -->
                        <!-- <template
                            v-if="
                                (competition.status === 3 &&
                                    limits.playersLimit > 0) ||
                                (competition.status === 2 &&
                                    limits.playersAvailable === 0)
                            "
                        >
                            <div
                                class="col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits"
                            >
                                <p class="classification__description-label"
                            :class="{'classification__description-label--light-mode': isLight}"
                                >
                                    {{ say.available }}
                                </p>
                                <p
                                    class="classification__description-value tc--negative"
                            :class="{'classification__description-value--light-mode': isLight}"

                                >
                                    {{ say.age_limit_none }}
                                </p>
                            </div>
                        </template> -->
                        <template>
                            <div
                                v-if="
                                    canRegisterClassification(
                                        competition,
                                        limits,
                                    )
                                "
                                class="col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits"
                            >
                                <p
                                    class="classification__description-label"
                                    :class="{
                                        'classification__description-label--light-mode': isLight,
                                    }"
                                >
                                    {{ say.available }}
                                </p>
                                <p
                                    class="classification__description-value"
                                    :class="{
                                        'classification__description-value--light-mode': isLight,
                                    }"
                                >
                                    {{ limits.playersAvailable }} /
                                    {{ limits.playersLimit }}
                                </p>
                            </div>
                            <div
                                v-else
                                class="col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits"
                            >
                                <p
                                    class="classification__description-label"
                                    :class="{
                                        'classification__description-label--light-mode': isLight,
                                    }"
                                >
                                    {{ say.number_of_participants }}
                                </p>
                                <p
                                    class="classification__description-value"
                                    :class="{
                                        'classification__description-value--light-mode': isLight,
                                    }"
                                >
                                    {{ limits.playersUnavailable }}
                                </p>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="col-12 pa0">
                    <hr
                        class="classification__line"
                        :class="{ 'classification__line--light': isLight }"
                    >
                </div>

                <!-- Prices. -->
                <div class="row out-gutter">
                    <template v-if="!id">
                        <p class="classification__text-skeleton w40i mat8i" />
                    </template>
                    <template v-else>
                        <!-- Price unknown. -->
                        <div
                            v-if="classificationSetting.isPay && !prices.length"
                            class="col-12"
                        >
                            <p
                                class="classification__description-label"
                                :class="{
                                    'classification__description-label--light-mode': isLight,
                                }"
                            >
                                {{ say.unknown_payment_information }}
                            </p>
                        </div>

                        <!-- Priceless. -->
                        <div
                            v-else-if="!classificationSetting.isPay"
                            class="col-12 col-sm-3"
                        >
                            <p
                                class="classification__description-label"
                                :class="{
                                    'classification__description-label--light-mode': isLight,
                                }"
                            >
                                {{ say.feeless }}
                            </p>
                        </div>

                        <!-- Prices. -->
                        <template v-else>
                            <div class="col-12 col-sm-3">
                                <p
                                    class="classification__description-label"
                                    :class="{
                                        'classification__description-label--light-mode': isLight,
                                    }"
                                >
                                    {{ say.prices }}
                                </p>
                            </div>
                            <div
                                v-for="(price, idx) in ascSortPrices"
                                :key="price.id"
                                class="col-6 col-sm-3 boxclass__price"
                            >
                                <p
                                    class="classification__description-label"
                                    :class="{
                                        'classification__description-label--light-mode': isLight,
                                    }"
                                >
                                    <template
                                        v-if="
                                            classificationSetting.pricesControlledBy ===
                                                'date'
                                        "
                                    >
                                        {{ say.until }}
                                        {{ price.endedTime | asDate }}
                                    </template>
                                    <template v-else>
                                        {{
                                            say[
                                                [
                                                    'first_players_fee',
                                                    'next_players_fee',
                                                    'rest_players_fee',
                                                ][idx]
                                            ]
                                        }}
                                        {{ price.playersLimit }}
                                    </template>
                                </p>
                                <p
                                    class="classification__description-value"
                                    :class="{
                                        'classification__description-value--light-mode': isLight,
                                    }"
                                >
                                    {{ price.price }} {{ currency }}
                                </p>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import actions from '@/mixins/actions';
import _ from 'lodash';

export default {
    mixins: [actions],
    props: {
        id: {
            type: Number,
            required: true,
        },
        competitionId: {
            type: Number,
            required: true,
        },
        isLight: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters([
            'say',
            'classifications',
            'classificationsSettings',
            'competitions',
            'limitsBy',
            'pricesBy',
            'agesBy',
            'competitionsCurrency',
        ]),
        currency() {
            if (this.competitionsCurrency[this.competition.id]) {
                return this.competitionsCurrency[this.competition.id]
                    .currencySymbol;
            }
            return 'zł';
        },
        classification() {
            return this.classifications[this.id];
        },
        classificationSetting() {
            return this.classificationsSettings[this.id];
        },
        competition() {
            return this.competitions[this.competitionId];
        },
        limits() {
            return this.limitsBy(this.classification)[0];
        },
        ages() {
            return this.agesBy(this.classification)[0];
        },
        prices() {
            return this.pricesBy(this.classification);
        },
        ascSortPrices() {
            if (this.classificationSetting.pricesControlledBy === 'date') {
                return _.sortBy(this.prices, (price) => price.endedTime);
            }
            return _.sortBy(this.prices, (price) => Number.parseFloat(price.price));
        },
        canM() {
            return !!this.ages.m;
        },
        canK() {
            return !!this.ages.k;
        },
        ageM() {
            return {
                from: this.ages.m.from || 0,
                to: this.ages.m.to || 99,
            };
        },
        ageK() {
            return {
                from: this.ages.k.from || 0,
                to: this.ages.k.to || 99,
            };
        },
        age() {
            const froms = [];
            const tos = [];

            if (this.canM) {
                froms.push(this.ageM.from);
                tos.push(this.ageM.to);
            }

            if (this.canK) {
                froms.push(this.ageK.from);
                tos.push(this.ageK.to);
            }

            return {
                from: Math.min(...froms),
                to: Math.max(...tos),
            };
        },
    },
};
</script>
<style scoped>
.classification {
    border-radius: 4px;
    padding: 16px 30px;
    margin-bottom: 8px;
}
.classification--dark {
    background-color: var(--color-slate);
}
.classification--light {
    background-color: rgba(84, 110, 122, 0.1);
}
.classification__text-skeleton {
    height: 16px;
    width: 60%;
    background-color: var(--color-cool-grey);
    border-radius: 8px;
}
.classification__text-skeleton--margin {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}
.classification__name {
    margin-bottom: 24px;
    padding-right: 15px;
    font-size: 18px;
}
.classification__description-label {
    color: #f7f9f9;
    line-height: 1.43;
    font-size: 12px;
    opacity: 0.7;
}
.classification__description-label--light-mode {
    color: var(--color-greyish-brown);
    font-size: 14px;
}
.classification__description-value {
    color: var(--color-white);
    font-size: 16px;
    line-height: 1.5;
}
.classification__description-value--light-mode {
    color: var(--color-greyish-brown);
    font-weight: 600;
}
.boxclass__players-limits {
    margin-top: 16px;
}
.tc--negative {
    color: #ff1744;
    font-size: 18px;
    font-weight: bold;
}
.w40i {
    width: 40% !important;
}
.mat8i {
    margin-top: 8px;
}
.boxclass__price {
    margin-top: 16px;
}
.classification__line {
    border: 0;
    border-top: 1px solid var(--color-slate-two);
    margin: 16px 0;
    opacity: 1;
}
.classification__line--light {
    border-top: 1px solid var(--color-greyish-brown);
    opacity: 0.2;
}
@media (min-width: 576px) {
    .boxclass__players-limits {
        margin-top: 0px;
    }
    .boxclass__price {
        margin-top: 0;
    }
}
@media (min-width: 992px) {
    .classification__name {
        padding-top: 16px;
        margin-bottom: 0;
    }
}
</style>
