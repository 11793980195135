<template>
    <base-map
        v-if="ready"
        class="map-container__map"
        :class="{ h100i: isZenMode }"
        :zoom="12"
        :lat="competition.locationLat"
        :lng="competition.locationLng"
        :fullscreenable="fullscreenable"
        :disable-scroll="disableScroll"
        @ready="(map = $event), $emit('ready', $event)"
    >
        <map-competition-point
            v-for="point in points"
            :key="point.id"
            :read-only="readOnly"
            :point="point"
            :competition-id="competition.id"
            :selected-classification-id="
                selectedClassification && selectedClassification.id
            "
            @delete-point="$emit('delete-point', $event)"
        />

        <base-map-track
            v-if="map"
            :classifications="classifications"
            :map="map"
            :selected-classification-id="
                selectedClassification && selectedClassification.id
            "
            @trackClick="trackClickHandler"
        />
        <slot name="optional-button" />>
    </base-map>
</template>

<script>
import { createPoints } from '@/service/panel-map';
import { mapGetters, mapActions } from 'vuex';
import {
    READ_COMPETITION_TRACKPOINTS,
    READ_CLASSIFICATION_GPX_FILE,
    READ_LOCATION,
} from '@/store/actions.type';
import _ from 'lodash';
import MapCompetitionPoint from '@/components/MapCompetitionPoint';

export default {
    components: {
        MapCompetitionPoint,
    },
    props: {
        competition: {
            type: Object,
            required: true,
        },
        classifications: {
            type: Array,
            required: true,
        },
        selectedClassification: {
            type: Object,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        fullscreenable: {
            type: Boolean,
            default: true,
        },
        disableScroll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            map: undefined,
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['trackpointsBy', 'isZenMode', 'gpxFiles']),
        points() {
            return createPoints(this.trackpoints);
        },
        trackpoints() {
            return this.trackpointsBy(this.classifications);
        },
    },
    async created() {
        await this.READ_COMPETITION_TRACKPOINTS(this.competition.id);
        const promises = [];
        for (const trackpoint of this.trackpoints) {
            promises.push(this.READ_LOCATION(trackpoint.location));
        }
        await Promise.all(promises);
        const gpxPromises = [];
        for (const classification of this.classifications) {
            gpxPromises.push(
                this.READ_CLASSIFICATION_GPX_FILE(classification.id),
            );
        }
        await Promise.all(gpxPromises);
        this.findGpxTrack();
        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_COMPETITION_TRACKPOINTS,
            READ_CLASSIFICATION_GPX_FILE,
            READ_LOCATION,
        ]),
        trackClickHandler(track) {
            // change selected classification.
            const clickedClassification = this.classifications.filter(
                (classification) => classification.id === track.classification,
            );
            const [classification] = clickedClassification;
            this.$emit('update:selectedClassification', classification);
        },
        findGpxTrack() {
            const classificationsIds = this.classifications.map((c) => c.id);
            if (
                _.find(
                    this.gpxFiles,
                    (gpxFile) => gpxFile.url
                        && classificationsIds.includes(gpxFile.classification),
                )
            ) {
                return this.$emit('map:ready', true);
            }
            return this.$emit('map:ready', false);
        },
    },
};
</script>

<style scoped>
.map-container__map {
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}
</style>
