var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container change-box" }, [
    _c("div", { staticClass: "row" }, [
      _c("h1", [_vm._v(_vm._s(_vm.say.edit_email_password))]),
    ]),
    _c("div", { staticClass: "row change-box__form" }, [
      _c("div", { staticClass: "col-12" }, [_c("user-edit-credentials")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }