var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bg--gray" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row result-classification__header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.say.results))]),
        ]),
      ]),
    ]),
    _vm.ready
      ? _c(
          "div",
          { staticClass: "container" },
          _vm._l(_vm.classifications, function (classification) {
            return _c("minisite-item-classification-result", {
              key: classification.id,
              attrs: {
                id: classification.id,
                "top-player-id":
                  _vm.topPlayersIdsByClassification[classification.id],
                "show-top-results": _vm.showTopResult,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }