var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 confirmation" }, [
        _c("h1", { staticClass: "c-positive" }, [
          _vm._v(" " + _vm._s(_vm.say.thank_you) + " "),
        ]),
        _c("p", { staticClass: "mat8" }, [
          _vm._v(" " + _vm._s(_vm.say.your_account_is_active) + " "),
        ]),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row profile" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-12 col-lg-9" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-6" }, [
                _c("div", { staticClass: "row profile__option" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "profile__option-box" }, [
                    _c("p", { staticClass: "profile__option-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.say.take_part_in_competition) + " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.say.create_participant_profile_for) +
                          ": "
                      ),
                    ]),
                    _c("p", [_vm._v("• " + _vm._s(_vm.say.collect_results))]),
                    _c("p", [
                      _vm._v("• " + _vm._s(_vm.say.save_more_efficiently)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 profile__option-btn" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: { btn2: "" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseUser()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.say.create_participant_profile) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-12 col-lg-6" }, [
                _c("div", { staticClass: "row profile__option" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "profile__option-box" }, [
                    _c("p", { staticClass: "profile__option-title" }, [
                      _vm._v(" " + _vm._s(_vm.say.organizes_competition) + " "),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.say.create_organizer_profile_for) +
                          ": "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.create_competition_page) + " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.start_online_registration) + " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.measure_results_stoper) + " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 profile__option-btn" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: { btn2: "" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseOrganizer()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.say.create_organizer_profile) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-3 profile__success" }, [
      _c("img", {
        staticClass: "profile__success-img",
        attrs: { src: require("@/assets/images/success-thumb.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("img", {
        staticClass: "profile__option-image",
        attrs: { src: require("@/assets/images/t-shirt-icon.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("img", {
        staticClass: "profile__option-image",
        attrs: { src: require("@/assets/images/stoper-icon.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }