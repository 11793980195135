var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "overflow-hidden mat48 pab16" },
        [
          _c("main-header", {
            attrs: {
              "header-color": _vm.headerColor,
              "is-main-site": "",
              "with-posters": _vm.canShowCarousel,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c("main-site", {
                        attrs: {
                          "is-tall": _vm.canShowCarousel,
                          person: _vm.organizer,
                          layout: _vm.layout,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c("main-header-search", {
                        attrs: { "is-main-site": "" },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3611439590
            ),
          }),
          _vm.canShowCarousel
            ? _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row justify-content-end" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-9 main-site__box-carousel" },
                    [
                      _c("main-carousel", {
                        attrs: {
                          "competition-filter": { organizer: _vm.organizer.id },
                        },
                        on: {
                          ready: function ($event) {
                            ;(_vm.carouselCompetitionsIds = $event),
                              (_vm.carouselReady = true)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("main-list"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }