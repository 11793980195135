<template>
    <div
        class="row h100 header-box__person"
        :class="{'header-box__person--tall': isTall}"
    >
        <div
            v-if="layout.logoLink"
            class="col-12"
        >
            <img
                class="header-box__person-logo"
                :src="layout.logoLink"
            >
        </div>
        <div class="col-12">
            <div class="row header-box__person-info">
                <div class="header-box__person-phone">
                    <p>{{ say.organizer_phone }}</p>
                    <strong>{{ person.phone }}</strong>
                </div>
                <div
                    class="header-box__person-email"
                    :class="{'header-box__person-email--margin': !person.website}"
                >
                    <p>{{ say.organizer_email }}</p>
                    <strong>{{ person.email }}</strong>
                </div>
                <div
                    v-if="person.website"
                    class="header-box__person-site"
                >
                    <base-button
                        class="person-btn w100"
                        btn6
                        @click="openWebsite()"
                    >
                        {{ websiteUrl }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        person: {
            type: Object,
            required: true,
        },
        layout: {
            type: Object,
            required: true,
        },
        isTall: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapGetters(['say']),
        websiteUrl() {
            return this.person.website.replace(/\/\/|.+\/\//, '');
        },
    },
    methods: {
        openWebsite() {
            window.open(`https://${this.websiteUrl}`, '_blank');
        },
    },
};
</script>

<style scoped>
.header-box__person-info {
    flex-direction: column;
}
.header-box__person-logo {
    width: 100%;
    height: auto;
    margin-bottom: auto;
    max-height: 100px;
    object-fit: contain;
}
.header-box__person-site {
    margin-top: 24px;
    display: flex;
    box-sizing: border-box;
    height: 48px;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(55, 71, 79, 0.2);
    width: 100%;
    margin-bottom: 20px;
    margin-right: 10px;
    border-radius: 4px;
}
.header-box__person-email {
    margin-top: 16px;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.header-box__person-email--margin {
    margin-bottom: 16px;
}
.header-box__person-phone {
    margin-top: 16px;
    line-height: 1.5;
    width: 100%;
}
.header-box__person {
    padding: 0 15px;
    align-content: space-between;
    overflow-wrap: anywhere;
}
.person-btn {
    white-space: normal;
    text-align: center;
}
* >>> .home-promote {
    margin-top: 0;
}

@media (min-width: 992px) {
    .header-box__person-email--margin {
        margin-bottom: 0;
    }
    .header-box__person-info {
        flex-direction: row;
    }
    .header-box__person {
        padding: 0 15px;
    }
    .header-box__person-phone {
        margin-top: auto;
    }
    .header-box__person-site {
        width: unset;
        margin-bottom: 0;
    }
    .header-box__person--tall {
        height: 375px;
    }
}
</style>
