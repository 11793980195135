var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "keyboard" },
    [
      !_vm.isEditingMeasureNumber
        ? [
            !_vm.stoperStarted && !_vm.isPanelGate
              ? _c(
                  "base-button",
                  {
                    staticClass: "keyboard__primary-btn",
                    class: _vm.isLargeScreen
                      ? "keyboard__primary-btn--large"
                      : "keyboard__primary-btn--small",
                    attrs: { btn1: "" },
                    on: {
                      click: function ($event) {
                        return _vm.START_STOPER()
                      },
                    },
                  },
                  [_vm._v(" Start ")]
                )
              : _c(
                  "base-button",
                  {
                    staticClass: "keyboard__primary-btn",
                    class: _vm.isLargeScreen
                      ? "keyboard__primary-btn--large"
                      : "keyboard__primary-btn--small",
                    attrs: {
                      btn1: "",
                      disabled: !_vm.stoperStarted || _vm.stoperStopped,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.ADD_STOPER_MEASURE_NUMBERS(_vm.stoperNumber)
                      },
                    },
                  },
                  [_vm._v(" Stop ")]
                ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "row",
                class: { palr8: _vm.$route.name === "panel-gate" },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "mar4 cancel-btn",
                        attrs: { btn9: "" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelEditNumber()
                          },
                        },
                      },
                      [_vm._v(" Anuluj ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { btn9: "" },
                        on: {
                          click: function ($event) {
                            return _vm.updateMeasureNumber()
                          },
                        },
                      },
                      [_vm._v(" Zapisz ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
      _c(
        "div",
        {
          staticClass: "keyboard__keys",
          class: _vm.isLargeScreen ? "mat16" : "mat12",
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              class: { palr8: _vm.$route.name === "panel-gate" },
            },
            [
              _vm._l(9, function (x) {
                return _c(
                  "div",
                  { key: x, staticClass: "col-4" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "keyboard__keys-item",
                        class: [
                          { "mar4 mab4": ![3, 6, 9].includes(x) },
                          _vm.isLargeScreen
                            ? "keyboard__keys-item--large"
                            : "keyboard__keys-item--small",
                        ],
                        attrs: { btn9: "" },
                        on: {
                          click: function ($event) {
                            _vm.isEditingMeasureNumber
                              ? _vm.digitMeasureInput(x)
                              : _vm.digit(x)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(x) + " ")]
                    ),
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "keyboard__keys-action mar4",
                    class: _vm.isLargeScreen
                      ? "keyboard__keys-item--large"
                      : "keyboard__keys-item--small",
                    on: {
                      click: function ($event) {
                        return _vm.space()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-arrow-right" })]
                ),
              ]),
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mar4 keyboard__keys-item",
                      class: _vm.isLargeScreen
                        ? "keyboard__keys-item--large"
                        : "keyboard__keys-item--small",
                      attrs: { btn9: "" },
                      on: {
                        click: function ($event) {
                          _vm.isEditingMeasureNumber
                            ? _vm.digitMeasureInput(0)
                            : _vm.digit(0)
                        },
                      },
                    },
                    [_vm._v(" 0 ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "keyboard__keys-action",
                    class: _vm.isLargeScreen
                      ? "keyboard__keys-item--large"
                      : "keyboard__keys-item--small",
                    on: {
                      click: function ($event) {
                        _vm.isEditingMeasureNumber
                          ? _vm.backspaceMeasureInput()
                          : _vm.backspace()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-backspace" })]
                ),
              ]),
            ],
            2
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }