var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-user" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row user-header" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-md-4 col-lg-3 d-flex justify-content-space-between align-items-center",
          },
          [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "div",
                {
                  staticClass: "user-header__img",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.avatar.click()
                    },
                  },
                },
                [
                  _c("input", {
                    ref: "avatar",
                    staticClass: "user-header__img-input",
                    attrs: { type: "file" },
                    on: {
                      input: function ($event) {
                        return _vm.inputAvatarHandler($event)
                      },
                    },
                  }),
                  !_vm.user.avatar
                    ? _c(
                        "strong",
                        { staticClass: "user-header__img-initials" },
                        [
                          _vm._v(
                            _vm._s((_vm.user.firstname || "").charAt(0)) +
                              _vm._s((_vm.user.lastname || "").charAt(0))
                          ),
                        ]
                      )
                    : _c("img", {
                        staticClass: "user-header__img-avatar",
                        attrs: { src: _vm.user.avatar, alt: "Avatar" },
                      }),
                ]
              ),
              _c("div", { staticClass: "user-header__name" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.say.hello) + ", "),
                  _c("br"),
                  _c("strong", { staticClass: "user-header__name-text" }, [
                    _vm._v(_vm._s(_vm.user.firstname)),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "hidden-md col-12 col-md-8 overflow-hidden col-lg-9 d-flex justify-content-space-between align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "user-header__nav" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "user-header__nav-btn",
                    class: {
                      "user-header__nav-btn--active": _vm.$router.matched(
                        "user-competitions",
                        _vm.$route
                      ),
                    },
                    attrs: { btn6: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "user-competitions-future",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.competitions) + " ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "user-header__nav-btn",
                    class: {
                      "user-header__nav-btn--active": _vm.$router.matched(
                        "user-family",
                        _vm.$route
                      ),
                    },
                    attrs: { btn6: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "user-family-children",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.family) + " ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "user-header__nav-btn",
                    class: {
                      "user-header__nav-btn--active": _vm.$router.matched(
                        "user-insurance",
                        _vm.$route
                      ),
                    },
                    attrs: { btn6: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "user-insurance-policies",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.insurance) + " ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "d-md-none user-header__nav-btn",
                    class: {
                      "user-header__nav-btn--active": _vm.$router.matched(
                        "user-edit",
                        _vm.$route
                      ),
                    },
                    attrs: { btn6: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "user-edit-profile",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.profile) + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }