var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("panel-competition-form-box", { staticClass: "info mab24" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-8 col-md-10" }, [
              _c("div", { staticClass: "form__headline" }, [
                _vm._v("Konfiguracja zaawansowana"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-4 col-md-2" },
              [
                !_vm.advanced
                  ? _c(
                      "base-button",
                      {
                        staticClass: "mal4 w100",
                        attrs: {
                          btn2: "",
                          short: "",
                          icon: "fa fa-caret-down",
                        },
                        on: {
                          click: function ($event) {
                            _vm.advanced = !_vm.advanced
                          },
                        },
                      },
                      [_vm._v("Rozwiń")]
                    )
                  : _c(
                      "base-button",
                      {
                        staticClass: "mal4 w100",
                        attrs: { btn2: "", short: "", icon: "fa fa-caret-up" },
                        on: {
                          click: function ($event) {
                            _vm.advanced = !_vm.advanced
                          },
                        },
                      },
                      [_vm._v("Zwiń")]
                    ),
              ],
              1
            ),
          ]),
        ]),
        _vm.advanced
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "col-12 col-lg-8" }, [
                _c("div", { staticClass: "row justify-content-start" }, [
                  _c("div", { staticClass: "col-12 mab16" }, [
                    _c("p", [_vm._v("Limity liczone według")]),
                  ]),
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 col-md-3 col-lg-2" },
                      [
                        _c("panel-input-text", {
                          staticClass: "mar16",
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-8 col-md-9 col-lg-10" }, [
                      _c("p", [
                        _vm._v("Limit uczestników dla całych zawodów "),
                        _c("span", { staticClass: "optional" }, [
                          _vm._v("(opcjonalnie)"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-12 col-lg-4" }, [
                _c("p", { staticClass: "field-helper" }, [
                  _vm._v(
                    " Limity pozwalją ograniczyć ilość uczestników w zawodach. Po osiągnięciu limitu, możliwość rejestracji zostanie automatycznie zablokowana. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row mat16 mab16" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-8" },
                  [
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "formGender",
                        option: {
                          value: "qwe",
                          title: "Zarejestrowanych uczestników",
                        },
                      },
                      model: {
                        value: _vm.form.limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "limit", $$v)
                        },
                        expression: "form.limit",
                      },
                    }),
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "formGender",
                        option: {
                          value: "qwer",
                          title: "Opłaconych uczestników",
                        },
                      },
                      model: {
                        value: _vm.form.limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "limit", $$v)
                        },
                        expression: "form.limit",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "indent1 mat8 pat8" },
                      [
                        _c("PanelInputBaseRadio", {
                          attrs: {
                            name: "limite",
                            option: { value: "qwe1", title: "Miękki limit" },
                          },
                          model: {
                            value: _vm.form.limite,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "limite", $$v)
                            },
                            expression: "form.limite",
                          },
                        }),
                        _c("PanelInputBaseRadio", {
                          attrs: {
                            name: "limite",
                            option: { value: "qwer2", title: "Twardy limit" },
                          },
                          model: {
                            value: _vm.form.limite,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "limite", $$v)
                            },
                            expression: "form.limite",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "indent2" }, [
                      _c("div", { staticClass: "row justify-content-start" }, [
                        _c("div", { staticClass: "col-12 mab8" }, [
                          _c("p", [
                            _vm._v("Oczekiwanie na potwierdzenie płatności"),
                          ]),
                        ]),
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c(
                            "div",
                            { staticClass: "col-4 col-md-3 col-lg-2" },
                            [
                              _c("panel-input-text", {
                                staticClass: "mar16",
                                model: {
                                  value: _vm.form.paylimit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "paylimit", $$v)
                                  },
                                  expression: "form.paylimit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-8 col-md-9 col-lg-10" },
                            [
                              _c("p", [
                                _vm._v(
                                  "Czas (w minutach), po którym płatność online zostanie przedawniona (3 minuty - 99 minut) "
                                ),
                                _c("span", { staticClass: "optional" }, [
                                  _vm._v("(opcjonalnie)"),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c("p", { staticClass: "field-helper" }, [
                    _vm._v(
                      " Miękki limit daje możliwość udostępnienia uczestnikom klasycznego przelewu jako formy płatności, jednak z powowdu opóźnień wpływania opłat, limit liczby uczestników może zostać przekroczony o uczestników płacących przelewem. "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "row mat16 mab16" }, [
                _c("div", { staticClass: "col-12 mab16" }, [
                  _c("p", [_vm._v("Przydzielanie do kategorii według")]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-8" },
                  [
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "birthlimit",
                        option: {
                          value: "qwe",
                          title: "Dokładnej daty urodzenia",
                        },
                      },
                      model: {
                        value: _vm.form.birthlimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "birthlimit", $$v)
                        },
                        expression: "form.birthlimit",
                      },
                    }),
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "birthlimit",
                        option: { value: "qwer", title: "Roku urodzenia" },
                      },
                      model: {
                        value: _vm.form.birthlimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "birthlimit", $$v)
                        },
                        expression: "form.birthlimit",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c("p", { staticClass: "field-helper" }, [
                    _vm._v(
                      "Przydzielanie uczestników do kategorii, może odbywać się według dokładnej daty urodzenia lub samego roku urodzenia."
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "row mat16 mab16" }, [
                _c("div", { staticClass: "col-12 mab16" }, [
                  _c("p", [_vm._v("Wyświetlanie na liście startowej")]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-8" },
                  [
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "startlimit",
                        option: {
                          value: "qwe",
                          title: "Wszystkich uczestników",
                        },
                      },
                      model: {
                        value: _vm.form.startlimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startlimit", $$v)
                        },
                        expression: "form.startlimit",
                      },
                    }),
                    _c("PanelInputBaseRadio", {
                      attrs: {
                        name: "startlimit",
                        option: { value: "qwer", title: "Tylko opłaconych" },
                      },
                      model: {
                        value: _vm.form.startlimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startlimit", $$v)
                        },
                        expression: "form.startlimit",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c("p", { staticClass: "field-helper" }, [
                    _vm._v(
                      "Lista startowa może zawierać wszystkich zarejestrowanych uczestników, lub tylko tych, którzy dokonali płatności za start."
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "row mat16 mab16" }, [
                _c("div", { staticClass: "col-12 col-lg-8" }, [
                  _c("div", { staticClass: "row justify-content-start" }, [
                    _c("div", { staticClass: "col-12 mab16" }, [
                      _c("p", [_vm._v("Płatność odroczona")]),
                    ]),
                    _c("div", { staticClass: "row align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-4 col-md-3 col-lg-2" },
                        [
                          _c("panel-input-text", {
                            staticClass: "mar16",
                            model: {
                              value: _vm.form.namqwee,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "namqwee", $$v)
                              },
                              expression: "form.namqwee",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-8 col-md-9 col-lg-10" }, [
                        _c("p", [
                          _vm._v("Ilość dni na opłatę od chwili rejestracji "),
                          _c("span", { staticClass: "optional" }, [
                            _vm._v("(zawody płatne)"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c("p", { staticClass: "field-helper" }, [
                    _vm._v(
                      "Uczestnicy mogą mieć możliwość opłaty za start po dokonaniu rejestracji online. Aby zablokować płatność odroczoną, ustaw 0 dni."
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "col-lg-12 mat16" }, [
                _c("div", { staticClass: "row justify-content-end" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-4" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: { btn1: "", short: "" },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v(" Zapisz ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "classifications-wrap" }, [
        _c("div", { staticClass: "classifications-items" }, [
          _c("div", { staticClass: "classification is-active" }, [
            _vm._m(0),
            _c("div", { staticClass: "classification-content" }, [
              _c("ul", { staticClass: "classification-content-list" }, [
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$refs.classificationAgeCategoryModal.openModal()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "classification-content-list-item" },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "BaseTooltip",
                          {
                            attrs: {
                              data: {
                                content: `W tym<br>opłaconych: 72<br>nieopłaconych: 23`,
                                html: true,
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "classification-tooltip" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/icons/shopping-trolley.svg"),
                                  },
                                }),
                                _vm._v(" 95"),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._m(2),
                  ]
                ),
                _vm._m(3),
                _vm._m(4),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "classification" }, [
            _vm._m(5),
            _c("div", { staticClass: "classification-content" }, [
              _c("ul", { staticClass: "classification-content-list" }, [
                _c("li", [
                  _c(
                    "span",
                    { staticClass: "classification-content-list-item" },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "BaseTooltip",
                        {
                          attrs: {
                            data: {
                              content: `W tym<br>opłaconych: 72<br>nieopłaconych: 23`,
                              html: true,
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "classification-tooltip" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/icons/shopping-trolley.svg"),
                                },
                              }),
                              _vm._v(" 95"),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._m(7),
                _vm._m(8),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "classification-actions" },
            [
              _c(
                "base-button",
                { staticClass: "w100", attrs: { btn2: "", short: "" } },
                [_vm._v("Dodaj klasyfikację")]
              ),
              _c(
                "base-button",
                { staticClass: "w100", attrs: { btn2: "", short: "" } },
                [_vm._v("Importuj z istniejących zawodów")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "classifications-content" }, [
          _vm._m(9),
          _c(
            "div",
            { staticClass: "classification-actions" },
            [
              _c(
                "base-button",
                {
                  staticClass: "status",
                  attrs: { btn2: "", short: "", status: "positive" },
                },
                [_vm._v("Klasyfikacja domyślna")]
              ),
              _c(
                "base-button",
                { attrs: { btn2: "", short: "", icon: "fas fa-trash-alt" } },
                [_vm._v("Usuń")]
              ),
              _c(
                "base-button",
                {
                  attrs: { btn2: "", short: "", icon: "fas fa-pencil-alt" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.classificationModal.openModal()
                    },
                  },
                },
                [_vm._v("Edytuj")]
              ),
              _c(
                "base-button-group",
                { attrs: { horizontal: "", shadow: "", border: false } },
                [
                  _c("base-button", {
                    attrs: {
                      btn2: "",
                      short: "",
                      radius: false,
                      icon: "fas fa-long-arrow-alt-up",
                    },
                  }),
                  _c("base-button", {
                    attrs: {
                      btn2: "",
                      short: "",
                      radius: false,
                      icon: "fas fa-long-arrow-alt-down",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "classification-headline" }, [
            _vm._v(" Parametry klasyfikacji "),
          ]),
          _vm._m(10),
          _c("div", { staticClass: "classification-headline" }, [
            _vm._v(" Daty "),
          ]),
          _vm._m(11),
          _c("div", { staticClass: "classification-headline" }, [
            _vm._v(" Opłaty "),
          ]),
          _vm._m(12),
        ]),
      ]),
      _c("modal", {
        ref: "classificationModal",
        staticClass: "modal-background",
        attrs: { id: "classificationModal" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Edytuj klasyfikację")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "form-body" }, [
                  _c("div", { staticClass: "form-row form-row-center" }, [
                    _c("div", { staticClass: "form-col form-col-3" }, [
                      _c("p", [_vm._v("Typ klasyfikacji")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-9" },
                      [
                        _c("panel-input-radio", {
                          staticClass: "nobg mab16",
                          attrs: {
                            name: "formGender",
                            label: "",
                            options: [
                              { value: "M", title: "dla dorosłych" },
                              { value: "K", title: "dla dzieci" },
                            ],
                            value: _vm.form.gender,
                            error: _vm.errors.gender,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "gender", $event)
                            },
                          },
                          model: {
                            value: _vm.form.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "hr__line" }, [_vm._v(" ")]),
                  _c("div", { staticClass: "form-row mab16" }, [
                    _c(
                      "div",
                      { staticClass: "form-col form-col-6" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Nazwa klasyfikacji",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-mobile-6 form-col-3" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Skrót nazwy",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-mobile-6 form-col-3" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Dystans",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "hr__line" }, [_vm._v(" ")]),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-date", {
                          staticClass: "mab16",
                          attrs: {
                            value: _vm.form.birthTime,
                            label: "Zapisy otwarte do",
                            placeholder: "dd.mm.yyyy",
                            error: _vm.errors.birthTime,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "birthTime", $event)
                            },
                          },
                          model: {
                            value: _vm.form.birthTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "birthTime", $$v)
                            },
                            expression: "form.birthTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-date", {
                          staticClass: "mab16",
                          attrs: {
                            value: _vm.form.birthTime,
                            label: "Klasyfikacja trwa do",
                            placeholder: "dd.mm.yyyy",
                            error: _vm.errors.birthTime,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "birthTime", $event)
                            },
                          },
                          model: {
                            value: _vm.form.birthTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "birthTime", $$v)
                            },
                            expression: "form.birthTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-mobile-6 form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Limit uczestników",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "hr__line" }, [_vm._v(" ")]),
                  _c("div", { staticClass: "form-row form-row-center" }, [
                    _c("div", { staticClass: "form-col form-col-4" }, [
                      _c("p", [_vm._v("Opłata za udział")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-8" },
                      [
                        _c("panel-input-switch", {
                          staticClass: "gap32 mab24",
                          attrs: {
                            value: _vm.form.pay,
                            name: "formStatus",
                            label: "",
                            options: [
                              {
                                value: "free",
                                title: "Klasyfikacja bezpłatna",
                              },
                              { value: "paid", title: "Klasyfikacja płatna" },
                            ],
                            vBoolean: false,
                            error: _vm.errors.pay,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "pay", $event)
                            },
                          },
                          model: {
                            value: _vm.form.pay,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pay", $$v)
                            },
                            expression: "form.pay",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-row mat32 mab16" }, [
                    _c(
                      "div",
                      { staticClass: "d-mobile-none form-col form-col-4" },
                      [_vm._v(" ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-mobile-6 form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Opłata",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-col form-mobile-6 form-col-4 mat24",
                      },
                      [
                        _c("p", [
                          _vm._v("Obowiązuje do"),
                          _c("br"),
                          _vm._v(" dnia zawodów"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "classification-paid-info" }, [
                    _c("i", { staticClass: "fas fa-money-bill-alt" }),
                    _c("p", [_c("b", [_vm._v("Pobierasz opłatę startową?")])]),
                    _c("p", [
                      _vm._v(
                        "Za organizowanie zawodów z płatnymi klasyfikacjami pobieramy "
                      ),
                      _c("b", [_vm._v("6% prowizji od opłaty za udział")]),
                      _vm._v(", którą pobierasz od uczestnika."),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Jeśli ustawisz opłatę za klasyfikację, wystawimy Ci fakturę VAT i wyślemy po dacie startu zawodów. Termin płatności faktury to 30 dni od daty startu zawodów."
                      ),
                    ]),
                    _c("p", [
                      _vm._v("Przykład:"),
                      _c("br"),
                      _vm._v(
                        "Organizujesz zawody z opłatą za udział w wysokości 20 zł. Jesli na zawody zapisze się 100 uczestników, otrzymasz od nas fakturę VAT na kwotę 120,00 zł (netto)."
                      ),
                    ]),
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          "Organizowanie zawodów bezpłatnych jest darmowe."
                        ),
                      ]),
                    ]),
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _vm._v("Akceptuję prowizję"),
                    ]),
                  ]),
                  _c("div", { staticClass: "hr__line" }, [_vm._v(" ")]),
                  _c("div", { staticClass: "form-row form-row-center" }, [
                    _c("div", { staticClass: "form-col form-col-4" }, [
                      _c("p", [_vm._v("Opłaty promocyjne")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-8" },
                      [
                        _c("panel-input-radio", {
                          staticClass: "mab16 nobg",
                          attrs: {
                            name: "formGender",
                            options: [
                              { value: "M", title: "Limit uczestników" },
                              { value: "K", title: "Limit czasu" },
                            ],
                            value: _vm.form.gender,
                            error: _vm.errors.gender,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "gender", $event)
                            },
                          },
                          model: {
                            value: _vm.form.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-row form-row-end" }, [
                    _c("div", { staticClass: "form-col form-col-4" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            gap: "12px",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", { staticClass: "form-col-btn" }, [
                            _c("i", { staticClass: "fas fa-trash" }),
                          ]),
                          _vm._v(" Stawka 1 "),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Opłata",
                            error: _vm.errors.icePhone,
                            disabled: "",
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Limit uczestników",
                            error: _vm.errors.icePhone,
                            disabled: "",
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-row form-row-end" }, [
                    _c("div", { staticClass: "form-col form-col-4" }, [
                      _c("div", { staticClass: "form-col-btn" }, [
                        _vm._v("Dodaj stawkę"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Opłata",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-col form-col-4" },
                      [
                        _c("panel-input-text", {
                          attrs: {
                            label: "Limit uczestników",
                            error: _vm.errors.icePhone,
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "icePhone", $event)
                            },
                          },
                          model: {
                            value: _vm.form.icePhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icePhone", $$v)
                            },
                            expression: "form.icePhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.classificationModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
                _c("button", { staticClass: "btn btn-primary" }, [
                  _vm._v("Zapisz"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "classificationAgeCategoryModal",
        staticClass: "modal-background",
        attrs: { id: "classificationAgeCategoryModal" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Edytuj kategorie")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [_c("ageCategoriesRangeSlider")]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.classificationAgeCategoryModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
                _c("button", { staticClass: "btn btn-primary" }, [
                  _vm._v("Zapisz"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classification-title" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 classification-label" }, [
          _vm._v("Klasyfikacja dorosłych"),
        ]),
        _c("div", { staticClass: "col-6 text-right" }, [
          _c("span", { staticClass: "badge mar8" }, [_vm._v("bezpł.")]),
          _c("span", { staticClass: "badge badge-positive" }, [
            _vm._v("domyślna"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "classification-name classification-text" },
            [_vm._v("Mężczyźni 10 km")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "classification-content-list-title" }, [
      _c("i", { staticClass: "fas fa-caret-down" }),
      _vm._v(" Mężczyźni"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("ul", [
        _c("li", [
          _vm._v("Mężczyźni 18-29 "),
          _c("div", { staticClass: "count" }, [_c("span", [_vm._v("23")])]),
        ]),
        _c("li", [
          _vm._v("Mężczyźni 30-39 "),
          _c("div", { staticClass: "count" }, [_c("span", [_vm._v("31")])]),
        ]),
        _c("li", [
          _vm._v("Mężczyźni 40-49 "),
          _c("div", { staticClass: "count" }, [_c("span", [_vm._v("19")])]),
        ]),
        _c("li", [
          _vm._v("Mężczyźni 50-59 "),
          _c("div", { staticClass: "count" }, [_c("span", [_vm._v("13")])]),
        ]),
        _c("li", [
          _vm._v("Mężczyźni 60-99 "),
          _c("div", { staticClass: "count" }, [
            _c("span", { staticClass: "badge" }, [_vm._v("-15%")]),
            _c("span", [_vm._v("9")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "classification-content-list-item is-empty" }, [
        _c("span", { staticClass: "classification-content-list-title" }, [
          _c("i", { staticClass: "fas fa-plus-square" }),
          _vm._v(" "),
          _c("span", [_vm._v("Kobiety")]),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("brak kategorii")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "classification-content-list-item is-empty" }, [
        _c("span", { staticClass: "classification-content-list-title" }, [
          _c("i", { staticClass: "fas fa-plus-square" }),
          _vm._v(" "),
          _c("span", [_vm._v("Kategorie specjalne")]),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("brak kategorii")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classification-title" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 classification-label" }, [
          _vm._v("Klasyfikacja dorosłych"),
        ]),
        _c("div", { staticClass: "col-6 text-right" }, [
          _c("span", { staticClass: "badge" }, [_vm._v("bezpłatna")]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "classification-name classification-text" },
            [_vm._v("Kobiety 10 km")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "classification-content-list-title" }, [
      _c("i", { staticClass: "fas fa-caret-right" }),
      _vm._v(" Kobiety"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "classification-content-list-item is-empty" }, [
        _c("span", { staticClass: "classification-content-list-title" }, [
          _c("i", { staticClass: "fas fa-plus-square" }),
          _vm._v(" Mężczyźni"),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("brak kategorii")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", { staticClass: "classification-content-list-item is-empty" }, [
        _c("span", { staticClass: "classification-content-list-title" }, [
          _c("i", { staticClass: "fas fa-plus-square" }),
          _vm._v(" Kategorie specjalne"),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("brak kategorii")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classification-title" }, [
      _c(
        "div",
        { staticClass: "classification-category classification-label" },
        [_vm._v("Klasyfikacja dorosłych")]
      ),
      _c("div", { staticClass: "classification-name classification-text" }, [
        _vm._v("Nazwa klasyfikacji"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mab32" }, [
      _c("div", { staticClass: "col-6 col-md-4" }, [
        _c("div", { staticClass: "classification-label" }, [
          _vm._v("Skrót nazwy"),
        ]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("M15"),
        ]),
      ]),
      _c("div", { staticClass: "col-6 col-md-4" }, [
        _c("div", { staticClass: "classification-label" }, [_vm._v("Dystans")]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("10 km"),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "classification-label" }, [
          _vm._v("Limit uczestników"),
        ]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("500"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mab32" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "classification-label" }, [
          _vm._v("Zapisy otwarte do"),
        ]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("15.12.2023 23:59"),
        ]),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "classification-label" }, [
          _vm._v("Klasyfikacja trwa do"),
        ]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("01.12.2019"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-4 mab24" }, [
        _c("div", { staticClass: "classification-label" }, [
          _vm._v("Opłata za start"),
        ]),
        _c("div", { staticClass: "classification-text text-bold" }, [
          _vm._v("75,00 zł"),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-md-8" }, [
        _c("div", { staticClass: "classification-label mab16" }, [
          _vm._v("Opłaty promocyjne"),
        ]),
        _c("ul", [
          _c("li", [
            _vm._v("Opłata do 15.06.2023"),
            _c("span", { staticClass: "classification-text text-bold" }, [
              _vm._v("50,00 zł"),
            ]),
          ]),
          _c("li", [
            _vm._v("Opłata do 15.08.2023"),
            _c("span", { staticClass: "classification-text text-bold" }, [
              _vm._v("60,00 zł"),
            ]),
          ]),
          _c("li", [
            _vm._v("Opłata do 15.10.2023"),
            _c("span", { staticClass: "classification-text text-bold" }, [
              _vm._v("65,00 zł"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }