var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder overflow-hidden",
      class: { "input-field--error": _vm.error },
    },
    [
      _c(
        "label",
        {
          staticClass: "input-field__label",
          class: { "input-field__label--show": _vm.isInput },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _c("textarea", {
        staticClass: "input-field__textarea",
        class: { "input-field__textarea--padding-top": !_vm.label },
        attrs: { placeholder: _vm.placeholder, rows: _vm.rows },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }