<template>
    <div class="col-12 col-md-6 col-lg-4">
        <div class="item-competition--small">
            <router-link
                tag="div"
                :to="{
                    name: 'minisite-landing',
                    params: competition,
                    alias: true,
                }"
                class="pointer"
            >
                <base-item-competition-icon-photo
                    :competition="competition"
                    :competition-layout="layout"
                />
                <div class="pa16">
                    <h4>{{ competition.name }}</h4>
                    <div
                        class="item-competition__status"
                        :class="{
                            'item-competition__status--open':
                                competition.status === 2,
                            'item-competition__status--close':
                                competition.status !== 2,
                        }"
                    >
                        {{ competition.status | asStatus }}
                    </div>
                    <p>{{ competition.location }}</p>
                    <p>{{ competition.startedTime | asTime }}</p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['say', 'competitions', 'layoutsBy']),
        competition() {
            return this.competitions[this.id];
        },

        layout() {
            return this.layoutsBy(this.competition)[0];
        },
    },
};
</script>
<style scoped>
.item-competition--small {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(84, 110, 122, 0.1);
    border-radius: 4px;
    margin-top: 16px;
    overflow: hidden;
}

.item-competition--small p {
    margin: 0;
}
.item-competition__status {
    padding: 8px 12px;
    margin: 16px 0;
    line-height: 1.71;
}

.item-competition__status--open {
    background-color: rgba(184, 233, 134, 0.4);
}

.item-competition__status--close {
    background-color: rgb(255, 23, 68, 0.4);
}
</style>
