var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-classification", class: { error: _vm.error } },
    [
      _c("div", { staticClass: "register-classification__header" }, [
        _c("div", { staticClass: "register-classification__name" }, [
          _vm._v(_vm._s(_vm.category.namePl)),
        ]),
      ]),
      _c("div", { staticClass: "register-classification__details" }, [
        _c("div", [_vm._v(" ")]),
        _c("div", [
          _c("div", { staticClass: "btnchk" }, [
            _c(
              "label",
              {
                attrs: {
                  for: `specialCategories[${_vm.category.id}][${_vm.id}]`,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("input", { value: $event.target.value })
                  },
                },
              },
              [
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: `specialCategories[${_vm.category.id}][${_vm.id}]`,
                    name: `specialCategory[${_vm.category.id}]`,
                  },
                  domProps: { value: _vm.id, checked: _vm.checked },
                }),
                _vm._m(0),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btnchk-wrap" }, [
      _c("span", { staticClass: "btnchk-txt" }, [_vm._v("Wybierz kategorie")]),
      _c("span", { staticClass: "btnchk-ico" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }