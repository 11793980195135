var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container confirmation" }, [
    _c("div", { staticClass: "row confirmation__content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-12 col-lg-3" },
        [
          _c("h2", { staticClass: "confirmation__content-title" }, [
            _vm._v(" " + _vm._s(_vm.say.thanks_for_result) + " "),
          ]),
          _vm.isTime
            ? _c("p", { staticClass: "confirmation__content-subtitle" }, [
                _vm._v(
                  " " + _vm._s(`${_vm.say.your_declared} ${_vm.say.time}`) + " "
                ),
              ])
            : _c("p", { staticClass: "confirmation__content-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${
                        _vm.say.your_declared
                      } ${_vm.$options.filters.asLowerCase(_vm.say.distance)}`
                    ) +
                    " "
                ),
              ]),
          _c("h2", { staticClass: "confirmation__content-result" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isTime ? _vm.time : `${_vm.player.declaredDistance} km`
                ) +
                " "
            ),
          ]),
          _c(
            "base-button",
            {
              staticClass: "confirmation__content-button",
              attrs: { btn2: "" },
              on: {
                click: function ($event) {
                  return _vm.showResultList()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.say.see_list_results) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-3" }, [
      _c("img", {
        staticClass: "confirmation__content-image",
        attrs: { src: require("@/assets/images/success-thumb.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }