var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "row justify-content-end mat16" }, [
      _c(
        "div",
        { staticClass: "col-lg-10" },
        [
          _c("panel-competition-form", {
            attrs: { validation: _vm.errors },
            on: {
              "update:validation": function ($event) {
                _vm.errors = $event
              },
              save: function ($event) {
                return _vm.createCompetition()
              },
            },
            model: {
              value: _vm.competition,
              callback: function ($$v) {
                _vm.competition = $$v
              },
              expression: "competition",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mat32" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("h3", [_vm._v("Nowe zawody")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }