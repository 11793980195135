export function diff(a, b) {
    const keys = Object.keys({
        ...a,
        ...b,
    });

    return keys.filter((key) => a[key] !== b[key]);
}

export function equal(a, b) {
    return diff(a, b).length === 0;
}

export function pick(object, props) {
    return props.reduce((accumulator, key) => ({ ...accumulator, [key]: object[key] }), {});
}
