var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.empty
        ? _c("div", [
            _c(
              "div",
              { staticClass: "info-box--grey pa16 matb15" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.say.empty_proposals))]),
                _c(
                  "base-button",
                  {
                    staticClass: "btn-fill",
                    attrs: { btn4: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "user-edit-profile",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.fill_city) + " ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._l(_vm.competitionsIds, function (id) {
        return _c("base-item-competition", { key: id, attrs: { id: id } })
      }),
      !_vm.empty
        ? _c(
            "base-button",
            {
              staticClass: "btn-findmore",
              attrs: { btn4: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "main-list", alias: true })
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.say.find_other_competition) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }