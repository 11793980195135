<template>
  <div 
    role="group" :aria-labelledby="name"
    class="panel-radio-base-field"
    @change="$emit('input', $event.target.value)"
  >
    <input
        :id="option.value || option.title"
        :key="option.value"
        v-model="innerValue"
        type="radio"
        :value="option.value"
        :name="name"
    >
    <label
        :key="option.title"
        :for="option.value || option.title"
    >{{ say[option.title] || option.title }}</label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
  mixins: [input],
  props: {
      option: {
          // { value: String, title: String }
          type: Object,
          required: true,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
          type: null,
          default: '',
      },                 
  },
  data () {
      return {
          innerValue: null,
      }
  },
  watch: {
      innerValue(newVal) {
          this.$emit('input', newVal)
      },
      value(newVal) {
          this.innerValue = newVal
      },
  },
  created() {
      if (this.value !== null || this.value !== undefined ) {
          this.innerValue = this.value === "1" ? true : this.value
      }
  },    
  computed: {
      ...mapGetters(['say']),
  },
};
</script>

<style scoped>
.panel-radio-base-field {
  border: 0;
  padding: 0 0 0 24px;
  margin: 0;
  min-height: 32px;
  display: flex;
  align-items: center;
}

.panel-radio-base-field + .panel-radio-base-field {
  margin-top: 16px;
}

.panel-radio-field.mab24 {
  margin-bottom: 24px;
}
input[type=radio] {
  opacity: 0;
  width: 0;
  height: 0;
}
input[type=radio] + label {    
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
  line-height: 1.3;
}
input[type=radio] + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -26px;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}
input[type=radio] + label::after {
  content: "";
  position: absolute;
  display: inline-block;
  left: -21px;
  top: 5px;
  border-radius: 50%;
  width: 10px;
  height: 9px;
}
input[type=radio]:checked + label::before {
  background: var(--color-white);
}
input[type=radio]:checked + label::after {
  background: #FF3D00;
  transition: background var(--animation-fast);
}
input[type=radio]:focus + label::before {
  box-shadow: 0 0px 8px #db3846;
}
.panel-input-error.input-error {
  bottom: -1px;
  left: 20px;
}
</style>