var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _c("p", { staticClass: "sidebar__attribute" }, [
          _vm._v(" " + _vm._s(_vm.say.firstname_and_lastname) + " "),
        ]),
        _c("p", { staticClass: "sidebar__value" }, [
          _vm._v(
            " " +
              _vm._s(_vm.user.firstname) +
              " " +
              _vm._s(_vm.user.lastname) +
              " "
          ),
        ]),
      ]),
      _c("div", [
        _c("p", { staticClass: "sidebar__attribute" }, [
          _vm._v(" " + _vm._s(_vm.say.birthtime) + " "),
        ]),
        _c("p", { staticClass: "sidebar__value" }, [
          _vm._v(" " + _vm._s(_vm._f("asDate")(_vm.user.birthTime)) + " "),
        ]),
      ]),
      _c("div", [
        _c("p", { staticClass: "sidebar__attribute" }, [
          _vm._v(" " + _vm._s(_vm.say.email_address) + " "),
        ]),
        _c("p", { staticClass: "sidebar__value" }, [
          _vm._v(" " + _vm._s(_vm.user.email) + " "),
        ]),
      ]),
      _c("div", [
        _c("p", { staticClass: "sidebar__attribute" }, [
          _vm._v(" " + _vm._s(_vm.say.city) + " "),
        ]),
        _c("p", { staticClass: "sidebar__value" }, [
          _vm._v(
            " " +
              _vm._s(_vm.user.city) +
              ", " +
              _vm._s(_vm._f("asNationality")(_vm.user.nationality)) +
              " "
          ),
        ]),
      ]),
      !_vm.$router.matched("user-edit", _vm.$route)
        ? _c(
            "base-button",
            {
              staticClass: "sidebar__edit-btn",
              attrs: { btn4: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "user-edit-profile",
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.say.edit_data) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }