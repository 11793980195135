<template>
  <div>
	<panel-mini-header
		v-if="isModerator && organizerId"
		:organizer-id="organizerId"
	/>
	<div class="container mat40">
		<div class="row admin-header">
		<div class="col-12 col-md-4">
			<h1>Ustawienia <template v-if="organizerId">profilu organizatora</template></h1>
			<h3>Potrzebujesz pomocy? Zadzwoń</h3>
			<a href="tel:+502678564" class="call-now">502 678 564</a>
		</div>
		<div class="col-12 col-md-8">
			<div class="buttons-wrap">
			<base-button-group
				horizontal
				class="admin-header__views"
			>
				<base-button
				class="admin-header__views-btn"
				btn11
				:pressed="$route.name === 'panel-setting-set'"
					@click="
						$router.push({
							name: 'panel-setting-set',
							params: {
								name: organizer.name,
								id: organizer.id,
							},
							alias: true,
						})
					"
				>
				Konto 
					<template v-if="organizerId">organizatora</template>
					<template v-else-if="operatorId">operatora</template>
				</base-button>
				<base-button
				class="admin-header__views-btn"
				btn11
				:pressed="$route.name === 'panel-setting-new-agreements'"
					@click="
						$router.push({
						name: 'panel-setting-new-agreements',
						params: {
								name: organizer.name,
								id: organizer.id,
							},
							alias: true,
						})
					"
				>
				Moje umowy
				</base-button>
				<base-button
					v-if="organizerId"
					class="admin-header__views-btn"
					btn11
					:pressed="$route.name === 'panel-setting-new-info-payments'"
					@click="
						$router.push({
						name: 'panel-setting-new-info-payments',
						params: {
								name: organizer.name,
								id: organizer.id,
							},
							alias: true,
						})
					"
				>
				Płatności
				</base-button>
				<base-button
				class="admin-header__views-btn"
				btn11
				:pressed="$route.name === 'panel-setting-new-web'"
					@click="
						$router.push({
						name: 'panel-setting-new-web',
						params: {
								name: organizer.name,
								id: organizer.id,
							},
							alias: true,
						})
					"
				>
					Własna domena i e-mail
				</base-button>
				<base-button
				class="admin-header__views-btn"
				btn11
				:pressed="$route.name === 'panel-setting-new-custom'"
					@click="
						$router.push({
						name: 'panel-setting-new-custom',
						params: {
								name: organizer.name,
								id: organizer.id,
							},
							alias: true,
						})
					"
				>
				Wygląd
				</base-button>
			</base-button-group>
			</div>
			<router-view />
		</div>  
		</div>
  	</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PanelMiniHeader from '@/components/PanelMiniHeader';

export default {
    components: {
		PanelMiniHeader
	},
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
    data() {
        return {};
    },
    computed: {
      	...mapGetters(['user', 'organizers']),
        organizer() {
            return this.organizers[this.organizerId];
        },
	  	operatorId() {
			return this.organizerId ? null : this.user.operator
		},
		organizerId() {
			return this.$route.params?.id || this.user.organizer
		},
		isModerator() {
            return (
                this.user.roles.includes('ROLE_ADMIN')
                || this.user.roles.includes('ROLE_OPERATOR')
            );
        },
    },
    async created() {},
    methods: {
        // navigateToSettings() {
        //   this.$router.push({
        //       name: 'panel-setting',
        //       params: { name: this.organizers[this.user.organizer].name },
        //       alias: true,
        //   });
        // },
    },
};
</script>

<style scoped>
.admin-header h1 { font-size: 24px; margin-bottom: 16px; }
.admin-header h3 { font-size: 18px; margin-bottom: 6px; }
.admin-header .call-now { font-family: 'Bariol Bold';font-size: 36px;}
.admin-header__views-btn {
  /* width: 100%; */
  flex: 1 auto;
  justify-content: center;
  user-select: none;
}

@media (max-width: 991px) {
	.admin-header__views {
		flex-wrap: wrap;
	}
}
@media (min-width: 992px) {
  .admin-header h1 {margin-bottom: 45px;}
}
</style>