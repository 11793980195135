var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.competition
        ? _c("header", { staticClass: "minisite--loading" })
        : _c(
            "header",
            { staticClass: "minisite" },
            [
              _c(
                "base-photo-sport",
                {
                  attrs: {
                    type: _vm.competition.type,
                    colour: _vm.layout.colourDefault,
                  },
                },
                [
                  _vm.$route.name !== "minisite-landing" &&
                  _vm.$route.name !== "minisite-contact"
                    ? _c("div", { staticClass: "minisite-header" }, [
                        _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row space-between" }, [
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("h1", [_vm._v(_vm._s(_vm.competition.name))]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.competition.location)),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("asDate")(
                                      _vm.competition.startedTime
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm.$route.name === "minisite-list"
                              ? _c("div", { staticClass: "col-12 col-md-3" }, [
                                  _c("div", { staticClass: "players-info" }, [
                                    _c("div", [
                                      _c("span", { staticClass: "big" }, [
                                        _vm._v(_vm._s(_vm.registeredPlayers)),
                                      ]),
                                      _c("span", { staticClass: "small" }, [
                                        _vm._v(
                                          _vm._s(_vm.say.zarejestrowanych)
                                        ),
                                        _c("br"),
                                        _vm._v(_vm._s(_vm.say.uczestników)),
                                      ]),
                                    ]),
                                    !_vm.competitionSetting.showOnlyPayed
                                      ? _c("div", [
                                          _c("span", { staticClass: "big" }, [
                                            _vm._v(_vm._s(_vm.paidPlayers)),
                                          ]),
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(_vm._s(_vm.say.opłaconych)),
                                            _c("br"),
                                            _vm._v(_vm._s(_vm.say.uczestników)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-md-3 minisite-header__back-button",
                                  },
                                  [
                                    _c(
                                      "base-button",
                                      {
                                        staticClass:
                                          "text-upper hover-dark border-none",
                                        attrs: { btn7: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "minisite-landing",
                                              params: _vm.competition,
                                              alias: true,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.say.back_to_competition
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                        ]),
                      ])
                    : _c("div", { staticClass: "minisite-header" }, [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              class: [
                                _vm.layout.posterLink &&
                                _vm.competition.id !== 4762 &&
                                _vm.competition.id != 4784
                                  ? "title--with-poster"
                                  : "",
                              ],
                            },
                            [
                              _vm.layout.posterLink &&
                              _vm.competition.id !== 4762 &&
                              _vm.competition.id != 4784
                                ? _c(
                                    "div",
                                    { staticClass: "col-4 col-md-3 d-flex" },
                                    [
                                      _c("img", {
                                        staticClass: "image-poster",
                                        attrs: { src: _vm.layout.posterLink },
                                        on: {
                                          click: function ($event) {
                                            _vm.index = 0
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-9 minisite-title",
                                  class: [
                                    _vm.layout.posterLink &&
                                    _vm.competition.id !== 4762 &&
                                    _vm.competition.id != 4784
                                      ? "col-8"
                                      : "col-12 title--without-poster",
                                  ],
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("asType")(_vm.competition.type)
                                        ) +
                                        " "
                                    ),
                                    _vm.competition.cycle
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.say.in_cycle) +
                                              " " +
                                              _vm._s(
                                                _vm.competition.cycle.namePl
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("h2", [
                                    _vm._v(_vm._s(_vm.competition.name)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                ]
              ),
            ],
            1
          ),
      _c("CoolLightBox", {
        attrs: { items: _vm.posters, index: _vm.index },
        on: {
          close: function ($event) {
            _vm.index = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }