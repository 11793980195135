import { render, staticRenderFns } from "./LayoutBackHeader.vue?vue&type=template&id=11ec0364&scoped=true&"
import script from "./LayoutBackHeader.vue?vue&type=script&lang=js&"
export * from "./LayoutBackHeader.vue?vue&type=script&lang=js&"
import style0 from "./LayoutBackHeader.vue?vue&type=style&index=0&id=11ec0364&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ec0364",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/dostartu.pl/app.startzone.online/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11ec0364')) {
      api.createRecord('11ec0364', component.options)
    } else {
      api.reload('11ec0364', component.options)
    }
    module.hot.accept("./LayoutBackHeader.vue?vue&type=template&id=11ec0364&scoped=true&", function () {
      api.rerender('11ec0364', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LayoutBackHeader.vue"
export default component.exports