<template>
  <div>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('stoper') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('stoper')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img novelty__box-img--laptop"
            src="@/assets/images/stoperInLaptop@3.png"
            alt=""
        >
        <img
            class="novelty__box-img novelty__box-img--phone"
            src="@/assets/images/stoperInPhone@3.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Nowa wersja stopera!</template>
      <p>• obsługa startu falowego netto/brutto</p>
      <p>• dowolna ilość punktów pomiarowych</p>
      <p>• możliwość edytowania czasów</p>
      <p>• sygnalizacja o błędnych numerach</p>

      <p class="mat16">
          W miejscach trudno dostępnych bez dostępu do <br>
          zasilenia, alternatywa dla sprzętu pomiarowego
      </p>                
    </panel-novelty-box>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('insurance') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('insurance')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img"
            src="@/assets/images/novelty-insurance.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Uruchom sprzedaż ubezpieczeń</template>
      <p>Daj uczestnikom możliwość ubezpieczenia startu w zawodach podczas zapisów. Zawodnicy będą mogli wykupić ubezpieczenia od:</p>
      <p>• rezygnacji z udziału w zawodach</p>
      <p>• następstw nieszczęśliwych wypadków</p>
      <p class="mat16">To tylko jeden dodatkowy krok w formularzu rejestracyjnym.</p>          
    </panel-novelty-box>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('msg') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('msg')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img"
            src="@/assets/images/novelty-msg.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Wysyłaj wiadomości uczestnikom</template>
      <p>Komunikuj się z zawodnikami zapisanymi na Twoje wydarzenie poprzez wiadomości mailowe.</p>
      <p>• personalizacja poprzez znaczniki (np. imię, numer, link)</p>
      <p>• możliwość dodania załączników</p>
      <p>• targetowanie do grup uczestników (kategorie, opłaceni/nieopłaceni)</p>
      <p class="mat16">Pozostań w kontakcie!</p>         
    </panel-novelty-box>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('shop') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('shop')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img"
            src="@/assets/images/novelty-shop.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Dodaj produkty do Twojego formularza zapisów</template>
      <!-- wersja aktywnego sklepu przymusowego -->
      <!-- <p>Jeśli nie chcesz uruchomiać sklepu z produktami po prostu go wyłącz. Dodatkowy koszt uruchomienia lub wyłączenia sklepu wynosi jednorazowo 200 zł.</p>

      <p class="mat16">Jeśli nie chcesz ponosić dodatkowych opłat w miejscu Twoich produktów pojawią się produkty przygotowane dla Twoich zawodów 
      
      </p>   
      -->
      <p>Daj uczestnikom możliwość kupienia dodatkowych pamiątek lub produktów potrzebnych do startu</p>
      <p>• ustaw produkty i ich ceny</p>
      <p>• ustaw dodatkowe opcje np: rozmiary, kolory</p>
      <p class="mat16">To tylko jeden dodatkowy krok w formularzu rejestracyjnym.</p>           
    </panel-novelty-box>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('gps') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('gps')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img"
            src="@/assets/images/novelty-gps.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Potrzebujesz śledzenie GPS na Twoich zawodach?</template>
      <p>Zapewnij bezpieczeństwo swoim zawodnikom – niech będą widoczni na trasie dzięki trackingowi GPS.</p>
      <p>• śledzenie zmagań zawodników na żywo</p>
      <p>• większe bezpieczeństwo dzięki lokalizacji startujących</p>
      <p>• długi czas pracy nadajników</p>
      <p>• zapis śladu GPX trasy</p>
      <p class="mat16">Wszystko to dzięki integracji systemu z serwisem Activeprogress.eu</p>
    </panel-novelty-box>
    <panel-novelty-box
      v-if="
          !isClosedNoveltyCookie('lang') &&
              !isClickedCloseNovelty &&
              !isMobile
      "
      @close="closeNoveltyMessage('lang')"
    >
      <template v-slot:images>
        <img
            class="novelty__box-img"
            src="@/assets/images/novelty-lang.png"
            alt=""
        >  
      </template>
      <template v-slot:title>Przetłumacz stronę zawodów na inne języki</template>
      <p>W prosty sposób zamień swoją stronę zapisów w wielojęzyczną.</p>
      <p>• obsługa kilku języków (EN, CZ, DE, FR)</p>
      <p>• zwiększenie zasięgów strony zapisów</p>
      <p>• dodanie plików (np. regulaminu) w innym języku</p>
      <p>• tłumaczenia informacji, klasyfikacji, pól i komunikatów</p>
    </panel-novelty-box>
  </div>
</template>

<script>
import PanelNoveltyBox from '@/components/PanelNoveltyBox';
import Cookies from 'js-cookie';

export default {
  name: 'PanelNoveltyBoxes',
  components: {
    PanelNoveltyBox,
  },
  data() {
    return {
      isClickedCloseNovelty: false,
    };
  },  
  computed: {
    isMobile() {
      return ['sm', 'md'].includes(this.$mq);
    },
  },
  methods: {
    closeNoveltyMessage(novelty) {
      this.isClickedCloseNovelty = true;
      Cookies.set(`novelty-${novelty}`, 'closed', { expires: 365 });
    },
    isClosedNoveltyCookie(novelty) {
      return Cookies.get(`novelty-${novelty}`);
    },      
  },  
}
</script>
