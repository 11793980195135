<template>
<div>
    <div
        v-if="ready"
    >
        <div  v-if="files.length > 0" class="row">
            <div class="col-12">
                <div class="update-results" style="width: auto;">
                    <div class="update-results-content">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20ZM25 28H22V17C22 16.4477 21.5523 16 21 16H17C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18H20V28H17C16.4477 28 16 28.4477 16 29C16 29.5523 16.4477 30 17 30H25C25.5523 30 26 29.5523 26 29C26 28.4477 25.5523 28 25 28ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38ZM22 11C22 10.4477 21.5523 10 21 10H19C18.4477 10 18 10.4477 18 11V13C18 13.5523 18.4477 14 19 14H21C21.5523 14 22 13.5523 22 13V11Z" fill="#455A64"/>
                        </svg>
                        <div>
                            <b>Nie zamykaj tej strony w trakcie pomiaru czasu</b>
                            <p>Zadaniem tej strony jest ciągłe wysyłanie wyników z aparatury pomiarowej do platformy dostartu.<br>Jeśli zamnkiesz tę stronę, przesył wyników zostanie przerwany.</p>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <!-- Checkpoint groups minus meta. -->
        <panel-competition-setting-stoper-item-checkpoint-group
            v-for="checkpointGroup of checkpointGroupsMinusMeta"
            :key="checkpointGroup.name"
            :competition-id="checkpointGroup.competition"
            :checkpoint-name="checkpointGroup.name"
            :files="files"
        />

        <!-- Add checkpoint buttons. -->
        <div class="row mat32 mab40">
            <div class="col-lg-4">
                <div class="border-box--gray patb20 palr32 mar15">
                    <base-button
                        btn9
                        :disabled="!canAddStart"
                        @click="ADD_CHECKPOINT_GROUP(true)"
                    >
                        Dodaj start pomiaru
                    </base-button>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="border-box--gray mar15 mal15 patb20 palr32">
                    <base-button
                        btn9
                        @click="ADD_CHECKPOINT_GROUP(false)"
                    >
                        Dodaj międzyczas
                    </base-button>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="border-box--gray mal15 patb20 palr32">
                    <base-button
                        btn9
                        :disabled="!canAddMeta"
                        @click="ADD_CHECKPOINT_GROUP_META()"
                    >
                        Dodaj punkt mety
                    </base-button>
                </div>
            </div>
        </div>
        <!-- Meta checkpoint groups. -->
        <panel-competition-setting-stoper-item-checkpoint-group
            v-for="meta of checkpointGroupsMeta"
            :key="meta.id"
            :competition-id="meta.competition"
            :checkpoint-name="meta.name"
            :files="files"
        />
        <div class="row" style="margin-top: 48px;">
            <div class="col-12 col-lg-4">
                <base-button @click="showPopupForDefault = !showPopupForDefault" btn4 style="color: #B0BEC5;border-color: #B0BEC5;">Przywróć do domyślnych ustawień</base-button>
            </div>
        </div>
        <!-- Popup. -->
        <base-popup v-if="showPopupForDefault">
            <!-- Title. -->
            <template #title>
                Przywróć do domyślnych ustawień
            </template>
            <!-- Subtitle. -->
            <template #subtitle>
                <b>Czy na pewno, chcesz przywrócić ustawienia domyślne?</b>
            </template>
            <!-- Content. -->
            <template #content>
                <div class="mat16">
                    Ustawienia pomiaru czasu zostaną <b>usunięte</b>.
                </div>
            </template>
            <!-- Buttons. -->
            <template #buttons>
                <base-button
                    class="mar15"
                    btn2
                    @click="showPopupForDefault = !showPopupForDefault"
                >
                    anuluj
                </base-button>
                <base-button
                    class="mal15"
                    btn1
                    @click="
                        (showPopupForDefault = !showPopupForDefault),
                        defaultChrono()
                    "
                >
                    Przywróć
                </base-button>
            </template>
        </base-popup>        
    </div>
    <div class="loader" v-else>
      <div class="lds-ellipsis lds-ellipsis--center">
        <div />
        <div />
        <div />
      </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PanelCompetitionSettingStoperItemCheckpointGroup from '@/components/PanelCompetitionSettingStoperItemCheckpointGroup';
import {
    BOOTSTRAP_STOPER_SETTING,
    ADD_CHECKPOINT_GROUP,
    TEARDOWN_STOPER_SETTING,
    ADD_CHECKPOINT_GROUP_META,
    READ_CHRONOMETRY_FTP_FILES,
    SET_COMPETITION_CHRONO_DEFAULT
} from '@/store/actions.type';

export default {
  name: 'PanelCompetitionSettingMain',
  components: {
    PanelCompetitionSettingStoperItemCheckpointGroup,
  },  
  props: {
    id: {
      type: Number,
      required: true,
    },
  }, 
  data() {
      return {
          ready: false,
          files: [],
          showPopupForDefault: false,
      };
  },
    computed: {
        ...mapGetters([
            'checkpointGroups',
            'stoperClassifications',
            'checkpointGroupsStart',
            'checkpointGroupsMeta',
        ]),
        checkpointGroupsMinusMeta() {
            return this.checkpointGroups.filter(
                (checkpointGroup) => checkpointGroup.number !== 1,
            );
        },
        canAddStart() {
            return (
                this.stoperClassifications.length
                > this.checkpointGroupsStart.length
            );
        },
        canAddMeta() {
            return (
                this.stoperClassifications.length
                > this.checkpointGroupsMeta.length
            );
        },
    },
    async created() {
        await this.BOOTSTRAP_STOPER_SETTING(this.id);
        const data = await this.READ_CHRONOMETRY_FTP_FILES(this.id);
        this.files = data

        this.ready = true;
    },
    async destroyed() {
        await this.TEARDOWN_STOPER_SETTING();
    },
    methods: {
        ...mapActions([
            ADD_CHECKPOINT_GROUP,
            BOOTSTRAP_STOPER_SETTING,
            TEARDOWN_STOPER_SETTING,
            ADD_CHECKPOINT_GROUP_META,
            READ_CHRONOMETRY_FTP_FILES,
            SET_COMPETITION_CHRONO_DEFAULT,
        ]),
        defaultChrono() {
            this.ready = false;
            this.SET_COMPETITION_CHRONO_DEFAULT(this.$props.id).then(() => {
                window.location.reload()
            })
        },
    },     
}
</script>

<style scoped>
.save-btn {
    height: 40px;
    box-sizing: border-box;
}

.border-box--gray {
    border-radius: 4px;
    border: solid 1px var(--color-bluish-grey);
}
.loader {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .lds-ellipsis {
  z-index: 55;
}
</style>