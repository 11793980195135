var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready && _vm.empty
        ? _c(
            "div",
            { staticClass: "info-box--grey pa16 matb15" },
            [
              _c("h3", [
                _vm._v(_vm._s(_vm.say.you_have_no_insurance_policies)),
              ]),
              _c(
                "base-button",
                {
                  staticClass: "matb30 bg--white",
                  attrs: { btn4: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "user-competitions-proposed",
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.see_proposed_competitions) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.competitionsIds, function (id) {
        return _c("base-item-competition", { key: id, attrs: { id: id } })
      }),
      _vm.hasMore
        ? _c(
            "base-button",
            {
              staticClass: "btn-findmore",
              attrs: { btn4: "" },
              on: { click: _vm.readMoreCompetitions },
            },
            [_vm._v(" " + _vm._s(_vm.say.see_more) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }