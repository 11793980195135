import operator from '@/api/schema/operator';
import { schema } from 'normalizr';
import moment from 'moment';

export default new schema.Entity(
    'organizers',
    {
        operator,
    },
    {
        processStrategy: (organizer) => ({
            // Meta.
            _type: 'organizer',
            // Fields.
            ...organizer,
            addedTime: organizer.addedTime ? moment(organizer.addedTime) : '',
        }),
    },
);
