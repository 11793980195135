var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "panel-radio-field",
      attrs: { role: "group", "aria-labelledby": _vm.name },
    },
    [
      _c("p", { staticClass: "mab8", attrs: { id: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c(
        "div",
        { staticClass: "panel-radio-field__wrap" },
        [
          _vm._l(_vm.options, function (option) {
            return [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.innerValue,
                    expression: "innerValue",
                  },
                ],
                key: option.value,
                attrs: {
                  id: `${_vm.name}_${option.value}`,
                  type: "radio",
                  name: _vm.name,
                },
                domProps: {
                  value: option.value,
                  checked: _vm._q(_vm.innerValue, option.value),
                },
                on: {
                  change: function ($event) {
                    _vm.innerValue = option.value
                  },
                },
              }),
              _c(
                "label",
                {
                  key: option.title,
                  attrs: { for: `${_vm.name}_${option.value}` },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.say[option.title] || option.title)),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }