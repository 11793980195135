<template>
    <div class="container">
        <div class="row add-person">
            <div class="col-12">
                <h1 class="add-person__title">
                    {{ isOperator ? "Dodaj operatora" : "Dodaj organizatora" }}
                </h1>
            </div>
            <div class="col-12 col-lg-4">
                <div class="add-person__box">
                    <p class="add-person__box-label">
                        E-mail
                    </p>
                    <base-input-text
                        v-model="email"
                        class="add-person__input"
                        :error.sync="errors.email"
                        label="E-mail"
                    />
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="add-person__box">
                    <p class="add-person__box-label">
                        Nazwa
                    </p>
                    <base-input-text
                        v-model="name"
                        class="add-person__input"
                        label="Nazwa"
                        :error.sync="errors.name"
                    />
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="add-person__box">
                    <p class="add-person__box-label">
                        Telefon (opcjonalnie)
                    </p>
                    <base-input-text
                        v-model="phone"
                        class="add-person__input"
                        label="Telefon"
                    />
                </div>
            </div>
            <div class="col-12">
                <div class="row add-person__buttons">
                    <div class="col-12 col-lg-2">
                        <base-button
                            btn2
                            class="add-person__btn"
                            @click="$emit('close')"
                        >
                            Anuluj
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-2">
                        <base-button
                            btn1
                            class="add-person__btn"
                            @click="add()"
                        >
                            Dodaj
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CREATE_OPERATOR, CREATE_ORGANIZER } from '@/store/actions.type';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    props: {
        isOperator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            email: '',
            phone: '',
            name: '',
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['user']),
        operatorId() {
            if (!this.isOperator) {
                return this.$route.params.operatorId ?? this.user.operator;
            }
            return null;
        },
    },
    methods: {
        ...mapMutations([SET_NOTIFICATION]),
        ...mapActions([CREATE_ORGANIZER, CREATE_OPERATOR]),
        async add() {
            const person = {
                email: this.email,
                phone: this.phone,
                name: this.name,
            };
            let id;
            try {
                if (this.isOperator) {
                    id = await this.CREATE_OPERATOR(person);
                    this.SET_NOTIFICATION('alerts_positive_57');
                } else {
                    id = await this.CREATE_ORGANIZER({
                        operator: this.operatorId,
                        ...person,
                    });
                    this.SET_NOTIFICATION('alerts_positive_43');
                }
            } catch (e) {
                this.errors = e;
                return;
            }
            this.$emit('add-person', id);
        },
    },
};
</script>

<style scoped>
.add-person {
    background-color: var(--color-slate);
    padding: 32px 16px;
    border-radius: 4px;
    justify-content: flex-end;
}
.add-person__box {
    margin-top: 15px;
}
.add-person__title {
    margin-bottom: 16px;
}
.add-person__btn {
    margin-top: 16px;
    min-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
}
.add-person__box-label {
    margin-bottom: 8px;
}
.add-person__buttons {
    flex-direction: column-reverse;
}
.add-person__input {
    max-height: 40px;
    min-height: 40px;
}
@media (min-width: 992px) {
    .add-person__buttons {
        flex-direction: row;
        justify-content: flex-end;
    }
    .add-person__box {
        margin: 0 15px;
    }
    .add-person__btn {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 32px;
    }
    .add-person__title {
        margin-left: 15px;
    }
}
</style>
