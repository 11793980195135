import { any } from '@/utils/array';
import moment from 'moment';
import { sprintf } from 'sprintf-js';

//
// Filter service.
//

export function cast(query) {
    const location = query.lokalizacja?.split(',') ?? [];

    return {
        dateSince: query.od ? moment(query.od, 'DD.MM.YYYY') : undefined,
        dateTo: query.do ? moment(query.do, 'DD.MM.YYYY') : undefined,
        pay: query.platne === 'tak' ? true : (query.platne === 'nie' ? false : undefined), // eslint-disable-line
        remote: query.wirtualne === null ? true : undefined,
        types: (query.typ?.split(',') ?? []).map((type) => Number.parseInt(type, 10)),
        page: query.strona ? Number.parseInt(query.strona, 10) : 1,
        status: query.status ? Number.parseInt(query.status, 10) : undefined,
        name: query.nazwa,
        location: {
            name: location[0],
            geometry: location.length > 1 ? ({
                lat: location[1],
                lng: location[2],
            }) : undefined,
        },
    };
}

export function uncast(filter) {
    const location = filter.location?.geometry ? sprintf('%s,%.5f,%.5f', filter.location.name, filter.location.geometry.lat, filter.location.geometry.lng) : undefined;

    return {
        od: filter.dateSince?.format('DD.MM.YYYY'),
        do: filter.dateTo?.format('DD.MM.YYYY'),
        typ: filter.types?.length ? filter.types.join(',') : undefined,
        platne: filter.pay === true ? 'tak' : filter.pay === false ? 'nie' : undefined, // eslint-disable-line
        lokalizacja: location,
        strona: filter.page > 1 ? filter.page : undefined,
        status: filter.status,
        nazwa: filter.name,
        wirtualne: filter.remote ? null : undefined,
    };
}

export function has(query) {
    const filters = ['od', 'do', 'typ', 'status', 'platne', 'strona', 'nazwa', 'lokalizacja', 'wirtualne'];

    return any(Object.keys(query), filters);
}

export function getCarouselFilter() {
    return {
        dateSince: moment().startOf('day'),
        promoted: true,
    };
}

export function getListFilter(filter, getters) {
    const today = moment().startOf('day');
    const { page } = filter;

    const futureFilter = {
        ...filter,
        dateSince: filter.dateSince?.diff(today) > 0 ? filter.dateSince : today,
        itemsPerPage: getters.route.name === 'main-list-map' ? 100 : 5,
        page,
        organizer: getters.route.name === 'main-site-organizer' ? getters.route.params.id : undefined,
        operator: getters.route.name === 'main-site-operator' ? getters.route.params.id : undefined,
    };

    const pastFilter = {
        ...filter,
        dateTo: filter.dateTo?.diff(today) < 0 ? filter.dateTo : today,
        sort: 'desc',
        itemsPerPage: getters.route.name === 'main-list-map' ? 100 : 5,
        page,
        organizer: getters.route.name === 'main-site-organizer' ? getters.route.params.id : undefined,
        operator: getters.route.name === 'main-site-operator' ? getters.route.params.id : undefined,
    };

    const promotedFilter = {
        ...futureFilter,
        promoted: true,
        itemsPerPage: 1,
        organizer: getters.route.name === 'main-site-organizer' ? getters.route.params.id : undefined,
        operator: getters.route.name === 'main-site-operator' ? getters.route.params.id : undefined,
    };

    return [promotedFilter, futureFilter, pastFilter];
}
