<template>
  <div class="widget__content-line" v-if="weather !== null && Object.keys(weather).length > 1">
    <p
        class="widget__content-label widget__content-label--wheater"
    >
        Prognoza
    </p>
    <div
        class="widget__content-value widget__content-value--img"
    >
      <img :src="require(`@/assets/images/icons/weather/${weather.weatherIcon}.png`)" />
    </div>
    <p
        class="widget__content-value widget__content-value--temp"
    >
        {{ weather.realTemperatureDay }}°C
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { READ_COMPETITION_WEATHER } from '@/store/actions.type';
export default {
  name: 'Weather',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      weather: null
    }
  },  
  created() {
    this.READ_COMPETITION_WEATHER(this.id).then(weather => this.weather = weather )
  },
  methods: {
    ...mapActions([READ_COMPETITION_WEATHER]),
  },
}
</script>

<style scoped>
.widget__content-label {
  line-height: 1.67;
  font-size: 12px;
  margin-bottom: 2px;
  padding: 0 15px;
}
.widget__content-line {
  border-left: 1px solid var(--color-pale-grey);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.widget__content-value--temp {
  padding: 0 0 16px 0;
  font-size: 18px;
  font-family: "Bariol Bold";
}
.widget__content-value--img {
  font-size: 32px;
  padding: 0;
  margin-top: auto;
  margin-bottom: 8px;
}
.widget__content-label--wheater {
  margin-bottom: 14px;
  padding: 12px 0 0 0;
}
@media (min-width: 992px) {
  .widget__content-value--temp {
    padding-bottom: 24px;
    font-size: 24px;
  }
  .widget__content-label {
    padding-left: 0;
    font-size: 14px;
  } 
  .widget__content-label--wheater {
    padding-top: 16px;
  }  
  .widget__content-img {
    height: 100%;
  }   
}
</style>