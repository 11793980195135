<template>
    <div class="container">
        <div class="row login__content">
            <!-- Header. -->
            <div class="col-12 col-lg-3">
                <h1 class="par15">
                    {{ say.create_new_account }}
                </h1>
            </div>

            <!-- Form. -->
            <div class="col-12 col-lg-9">
                <div class="login-section bg--gray row">
                    <div class="col-12">
                        <div class="login-section__inputs">
                            <!-- <div class="row">
                                <div class="col-12 col-lg-5">
                                    <base-button
                                        btn8
                                        @click="continueViaFacebook()"
                                    >
                                        {{ say.create_account_via_facebook }}
                                    </base-button>
                                </div>
                            </div> -->

                            <div class="row mat32">
                                <p>{{ say.type_your_email_and_password }}</p>
                            </div>
                            <div class="row mat24">
                                <div class="col-12 col-lg-5">
                                    <!-- Email  -->
                                    <base-input-text
                                        v-model="credentials.email"
                                        :label="say.email_address"
                                        :error.sync="errors.email"
                                    />
                                </div>
                            </div>
                            <div class="row mat24">
                                <div class="col-12 col-lg-5">
                                    <!-- Password  -->
                                    <base-input-password
                                        v-model="credentials.plainPassword"
                                        :label="say.password"
                                        :error.sync="errors.plainPassword"
                                    />
                                </div>
                                <div
                                    class="col-12 col-lg-4 login-section__password-hint"
                                >
                                    <p>{{ say.password_should_contain }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Validation. -->
                <base-notification-local
                    v-if="errors.email === 'account_email_occupied'"
                    negative
                >
                    <template v-slot:header>
                        {{ say[errors.email] }}
                    </template>
                    <template v-slot:content>
                        {{ say.troubles_with_login }},
                        <router-link
                            :to="{ name: 'authentication-reset', alias: true }"
                        >
                            {{ say.reset_your_password | asLowerCase }}
                        </router-link>
                        .
                    </template>
                </base-notification-local>

                <!-- Buttons. -->
                <div class="row login__buttons-box">
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn1
                            class="login__btn-primary"
                            @click="registerUser()"
                        >
                            {{ say.step_next }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn4
                            class="login__btn-secondary"
                            @click="
                                $router.replace({
                                    name: 'authentication-login',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.login_to_account }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn4
                            class="login__btn-cancel"
                            @click="$router.back()"
                        >
                            {{ say.cancel }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { REGISTER_USER, CONTINUE_VIA_FACEBOOK } from '@/store/actions.type';
import { getRouteToPanel, getRouteByLoginTo } from '@/service/profiles';
import handleLoggedUser from '@/router/guards/login';

export default {
    data() {
        return {
            credentials: {
                email: '',
                plainPassword: '',
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['say', 'user']),
    },
    beforeRouteEnter: handleLoggedUser,
    methods: {
        ...mapActions([REGISTER_USER, CONTINUE_VIA_FACEBOOK]),
        async registerUser() {
            this.errors = {};
            try {
                await this.REGISTER_USER(this.credentials);
                if (this.$route.query.to === 'panel') {
                    // Create organizer view.
                    this.$router.push({
                        name: 'authentication-create-organizer',
                        alias: true,
                    });
                } else {
                    // Choose profile between organizer and user.
                    this.$router.push({
                        name: 'authentication-choose-profile',
                        alias: true,
                    });
                }
            } catch (e) {
                this.errors = e;
            }
        },
        async continueViaFacebook() {
            try {
                await this.CONTINUE_VIA_FACEBOOK();
                if (
                    !this.user.roles.length
                    && this.$route.query.to !== 'panel'
                ) {
                    // New account and route without "to" query.
                    this.$router.push({
                        name: 'authentication-choose-profile',
                        alias: true,
                    });
                } else if (this.$route.query.to === 'panel') {
                    // Route to panel.
                    const route = getRouteToPanel(this.user);
                    this.$router.push(route);
                } else {
                    // Account exist with some role.
                    const route = getRouteByLoginTo(this.user);
                    this.$router.push(route);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
        _keyListener(e) {
            if (e.code === "Enter") { 
                this.registerUser();
            }
        },          
    },
    mounted() {
        window.addEventListener('keypress', this._keyListener);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this._keyListener);
    },     
};
</script>
