var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.label.length
      ? _c("p", { staticClass: "mab8" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "input-field panel-input-autocomplete",
        class: { "input-field--error": _vm.error, focused: _vm.focused },
      },
      [
        _c("v-select", {
          ref: "vselect",
          staticClass: "autocompleter-competition__input",
          attrs: {
            label: _vm.optionLabel,
            options: _vm.suggestions,
            "no-drop": this.suggestions.length === 0,
            "append-to-body": true,
            value: _vm.value,
            filterable: false,
            "clear-search-on-select": _vm.clearSearchOnSelect,
            "clear-search-on-blur": _vm.clearSearchOnBlur,
            clearable: _vm.clearable,
          },
          on: {
            search: _vm.onSearch,
            input: _vm.onSelect,
            "search:blur": function ($event) {
              _vm.focused = !_vm.focused
            },
            "search:focus": function ($event) {
              _vm.focused = !_vm.focused
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "option",
                fn: function (option) {
                  return [_vm._t("option-data", null, null, option)]
                },
              },
            ],
            null,
            true
          ),
        }),
        (_vm.$refs.vselect ? _vm.$refs.vselect.stateClasses["vs--open"] : "")
          ? _c(
              "span",
              {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.$refs.vselect.blur()
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-times" })]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass: "panel-input-error input-error",
        class: { "input-error--show": _vm.error && !_vm.withoutErrorMessage },
      },
      [_vm._v(_vm._s(_vm.say[_vm.error]))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }