var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "welcome" }, [
      _vm.role === "list"
        ? _c("p", [
            _c("b", [
              _vm._v(
                "Wybierz kolumny z imieniem, nazwiskiem, datą urodzenia i płcią."
              ),
            ]),
            _vm._v("Opcjonalnie wskaż inne kolumny np. numer startowy."),
          ])
        : _vm.role === "chip"
        ? _c("p", [
            _c("b", [
              _vm._v("Wybierz kolumny z numerem startowym i numerem chip."),
            ]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c(
          "swiper",
          {
            ref: "carousel",
            class: {
              "fog fog-left": _vm.isNavLeft,
              "fog fog-right": _vm.isNavRight,
            },
            attrs: { options: _vm.swiperOption },
            on: { slideChange: _vm.slideChanged },
          },
          [
            _vm._l(_vm.data[0].length, function (index) {
              return _c("swiper-slide", { key: index }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "header" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.headers[index - 1],
                            expression: "headers[index - 1]",
                          },
                        ],
                        staticClass: "select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.headers,
                              index - 1,
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.headersData, function (header, idx) {
                        return _c(
                          "option",
                          { key: idx, domProps: { value: header.value } },
                          [_vm._v(_vm._s(header.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "body" },
                    _vm._l(_vm.data, function (row) {
                      return _c("div", { staticClass: "body-item" }, [
                        _vm._v(" " + _vm._s(row[index - 1]) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNavLeft,
                    expression: "isNavLeft",
                  },
                ],
                staticClass: "nav-btn button-prev",
                attrs: { slot: "button-prev" },
                slot: "button-prev",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "12",
                      height: "9",
                      viewBox: "0 0 12 9",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1.70711 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H1.70711L4.85355 8.14645C5.04882 8.34171 5.04882 8.65829 4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L0.146447 4.85355C-0.0488155 4.65829 -0.0488155 4.34171 0.146447 4.14645L4.14645 0.146447C4.34171 -0.0488155 4.65829 -0.0488155 4.85355 0.146447C5.04882 0.341709 5.04882 0.658291 4.85355 0.853553L1.70711 4Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNavRight,
                    expression: "isNavRight",
                  },
                ],
                staticClass: "nav-btn button-next",
                attrs: { slot: "button-next" },
                slot: "button-next",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "12",
                      height: "9",
                      viewBox: "0 0 12 9",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }