var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass: "row align-items-center flex-nowrap",
              staticStyle: { "min-height": "48px" },
            },
            [
              _c("router-link", { attrs: { to: _vm.navigateToList } }, [
                _c("img", {
                  staticClass: "header__logo-ds",
                  attrs: {
                    src: require("@/assets/images/logo-dostartu-white.svg"),
                    alt: "Logo dostartu",
                  },
                }),
              ]),
              !_vm.isSettingStoperFullscreen
                ? _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _vm.ready
                        ? [
                            _vm.isAdmin
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "header__button header__button--list-users",
                                    attrs: { btn12: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "panel-users-list",
                                          alias: true,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "header__button-name" },
                                      [_vm._v(" Lista użytkowników ")]
                                    ),
                                    _c("img", {
                                      staticClass: "header__button-icon",
                                      attrs: {
                                        src: require("@/assets/images/icons/shopping-trolley.svg"),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isAdmin
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "header__button header__button--list-provision",
                                    attrs: { btn12: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "panel-provision-admin",
                                          alias: true,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "header__button-name" },
                                      [_vm._v(" Lista płatności ")]
                                    ),
                                    _c("img", {
                                      staticClass: "header__button-icon",
                                      attrs: {
                                        src: require("@/assets/images/icons/provision-admin.svg"),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isOperator || _vm.isOrganizer
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "header__button header__button--list-provision",
                                    attrs: { btn12: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "panel-provision",
                                          alias: true,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "header__button-name" },
                                      [_vm._v(" Moje opłaty ")]
                                    ),
                                    _c("img", {
                                      staticClass: "header__button-icon",
                                      attrs: {
                                        src: require("@/assets/images/icons/provision-operator.svg"),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "base-button",
                              {
                                staticClass:
                                  "header__button header__button--list-competitions",
                                attrs: { btn12: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(_vm.navigateToList)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "header__button-name" },
                                  [_vm._v(" Lista zawodów ")]
                                ),
                                _c("img", {
                                  staticClass: "header__button-icon",
                                  attrs: {
                                    src: require("@/assets/images/hamburger-icon-white.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "base-button",
                              {
                                staticClass:
                                  "header__button header__button--settings",
                                attrs: { btn12: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.navigateToSettings()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "header__button-name" },
                                  [_vm._v("Ustawienia")]
                                ),
                                _c("img", {
                                  staticClass: "header__button-icon",
                                  attrs: {
                                    src: require("@/assets/images/settings-icon-white.svg"),
                                  },
                                }),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "base-button",
                        {
                          staticClass: "header__button",
                          attrs: { btn12: "" },
                          on: {
                            click: function ($event) {
                              _vm.showUserButtons = !_vm.showUserButtons
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "header__button-name" }, [
                            _vm._v(" Konto "),
                          ]),
                          _c("img", {
                            staticClass: "header__button-icon",
                            attrs: {
                              src: require("@/assets/images/user-circle-white.svg"),
                              alt: "",
                            },
                          }),
                          _vm.showUserButtons
                            ? _c(
                                "div",
                                { staticClass: "header__button-actions" },
                                [
                                  _c("user-dropdown-buttons", {
                                    attrs: { "is-panel": "" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm.isModerator && _vm.organizerId && !_vm.isSettingStoperFullscreen
        ? _c("panel-mini-header", {
            attrs: { "organizer-id": _vm.organizerId },
          })
        : _vm._e(),
      _vm.operatorId
        ? _c("panel-mini-header-operator", {
            attrs: { "operator-id": _vm.operatorId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }