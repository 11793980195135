<template>
    <div>
        <main v-if="!ready">
            <div class="d-flex flex-column mat24">
                <h2 class="text-center">
                    {{ say.start_list_is_loading }}
                </h2>
                <div class="mat16 lds-ellipsis lds-ellipsis--center">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </main>
        <main v-if="ready">
            <div class="list-header">
                <!-- Select buttons. -->
                <div class="container">
                    <div class="row">
                        <div
                            class="col-12 col-lg-3 mar-auto align-items-center"
                        >
                            <h1 class="l-short">
                                {{ say.start_list }}
                            </h1>
                        </div>
                        <div class="col-12 col-lg-3">
                            <base-input-select
                                v-model="selectedClassification"
                                class="mar15"
                                :options="classifications"
                                :label="say.classification"
                                :label-select="`name${activeLang}`"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <base-input-select
                                v-if="selectedClassification"
                                v-model="selectedCategory"
                                :options="categoriesBy(selectedClassification)"
                                :label="say.category"
                                :label-select="`name${activeLang}`"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="list-header__search-input">
                                <base-input-text-with-icon
                                    v-model="search"
                                    :label="say.search"
                                    icon="fas fa-search"
                                    badge="fas fa-times"
                                    @icon-click="search = ''"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="container-result">
                    <!-- List. -->
                    <base-table
                        v-if="items.length"
                        class="table-list"
                        :headers="headers"
                        :items="items"
                        :search="search"
                        sortColumnDefault="1"
                    >
                        <template v-slot:header="{ sort }">
                            <tr class="table-list__row bg--white">
                                <th
                                    v-for="(header, column) in headers"
                                    :key="column"
                                    class="table-list__header"
                                    :class="{
                                        'table-list__header-pay':
                                            header.value === 'isPay',
                                    }"
                                    @click="sort(column)"
                                >
                                    {{ header.text }}
                                </th>
                            </tr>
                        </template>

                        <template v-slot:body="{ displayedItems }">
                            <tr
                                v-for="item in displayedItems"
                                :key="item.id"
                                class="table-list__row"
                            >
                                <td
                                    v-for="(header, column) in headers"
                                    :key="column"
                                    class="table-list__data"
                                    :class="{
                                        'align-right table-list__data-pay':
                                            header.value === 'isPay',
                                    }"
                                >
                                    <template
                                        v-if="
                                            header.value === 'isPay' &&
                                                !item[header.value] &&
                                                ((limits[item.classificationId]
                                                    .playersAvailable > 0 &&
                                                    competitionSetting.playersKindLimit ===
                                                    'pay') ||
                                                    competitionSetting.playersKindLimit ==
                                                    'register')
                                        "
                                    >
                                        <router-link
                                            v-if="
                                                competition.isAutoPay !==
                                                    null &&
                                                    competition.status < 3
                                            "
                                            :to="{
                                                name: 'minisite-paylink',
                                                params: {
                                                    name: competition.name,
                                                    id: id,
                                                    playerId: item.id,
                                                },
                                                alias: true,
                                            }"
                                            class="c-orange"
                                        >
                                            {{ say.pay_for_participation }}
                                        </router-link>
                                        <p v-else-if="competition.status === 3">
                                            {{ say.not_payed }}
                                        </p>
                                        <p v-else>
                                            <!-- Empty field if competition has not autopay and player did not payed and status 3-->
                                        </p>
                                    </template>
                                    <p
                                        v-else-if="
                                            header.value === 'isPay' &&
                                                !item[header.value] &&
                                                (competition.status === 3 ||
                                                    (limits[item.classificationId]
                                                        .playersAvailable === 0 &&
                                                        competitionSetting.playersKindLimit ===
                                                        'pay'))
                                        "
                                    >
                                        {{ say.not_payed }}
                                    </p>
                                    <p
                                        v-else-if="
                                            header.value === 'isPay' &&
                                                item[header.value] === true
                                        "
                                    >
                                        {{ say.payed }}
                                    </p>
                                    <p
                                        v-else-if="
                                            header.value === 'isPay' &&
                                                item[header.value] === 'free'
                                        "
                                    >
                                        {{ say.feeless }}
                                    </p>
                                    <template
                                        v-else-if="
                                            header.value == 'name' &&
                                                item.isAnonymous
                                        "
                                    >
                                        {{ say.anonymous_participant }}
                                    </template>
                                    <template  v-else-if="header.value == 'number'">
                                        <span v-if="item[header.value] === 0">-</span>
                                        <span v-else>{{ item[header.value] }}</span>                                        
                                    </template>
                                    <template v-else>
                                        {{ item[header.value] }}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:no-results="{ search: term }">
                            <p class="bg--gray pa16">
                                {{ say.no_search_results }}
                                <b>{{ term }}</b>
                            </p>
                        </template>
                    </base-table>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { insert } from '@/utils/array';
import {
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_PLAYERS,
    BOOTSTRAP_MINISITE,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
    DECLARE_PLAYERS_REGISTERED,
    DECLARE_PLAYERS_PAID,
} from '@/store/actions.type';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            search: '',
            selectedClassification: null,
            selectedCategory: null,
            headersDefault: [
                { text: 'Nr', value: 'number' },
                { text: 'Nazwisko i imię', value: 'name' },
                { text: 'Klub', value: 'clubname' },
                { text: 'Kraj', value: 'country' },
                { text: 'Miejscowość', value: 'city' },
                { text: 'Klas./Kat.', value: 'classificationAndCategory' },
                { text: '', value: 'isPay' },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            'pricesBy',
            'categoriesBy',
            'competitionsSettings',
            'classificationsBy',
            'playersBy',
            'minisiteId',
            'limits',
        ]),
        competition() {
            return this.competitions[this.minisiteId];
        },
        competitionSetting() {
            return this.competitionsSettings[this.minisiteId];
        },
        classifications() {
            return this.classificationsBy(this.competition);
        },
        players() {
            return this.playersBy(this.classifications);
        },
        headers() {
            // if (this.competition.isRemote && this.competition.id !== 4877) {
            // return this.headersDefault.filter(
            //     (header) => header.value !== 'number',
            // );
            if (this.competition.frontColumn) {
                const frontColumn = [{text: this.competition.frontColumn, value: 'frontInfo'}]

                return insert(
                    this.headersDefault,
                    6,
                    frontColumn,
                );
            }            
            return this.headersDefault;
        },
        items() {
            const filtered = this.players.filter((player) => {
                const prices = this.pricesBy(this.classificationsBy(player)[0]);
                
                if (this.selectedCategory  && this.competitionSetting.showOnlyPayed) {
                    return prices.length === 0 ? player.category.includes(this.selectedCategory.id) : player.category.includes(this.selectedCategory.id) && player.pay;
                }
                if (this.selectedCategory) {
                    return player.category.includes(this.selectedCategory.id);
                }

                if (this.selectedClassification && this.competitionSetting.showOnlyPayed) {
                    return (
                        prices.length === 0 ? player.classification === this.selectedClassification.id : player.classification === this.selectedClassification.id && player.pay
                    );
                }

                if (this.selectedClassification) {
                    return (
                        player.classification === this.selectedClassification.id
                    );
                }

                if (this.competitionSetting.showOnlyPayed) {
                    return prices.length === 0 ? player : player.pay;
                }

                return true;
            });

            let i = 0;
            const list = [];

            for (const player of filtered) {
                i += 1;

                const [normalCategory] = this.categoriesBy(player).filter(
                    (category) => !category.special,
                );
                const prices = this.pricesBy(this.classificationsBy(player)[0]);

                list.push({
                    position: i,
                    name: `${player.lastname} ${player.firstname}`,
                    number: player.playerNumber,
                    country: player.country,
                    city: player.city,
                    clubname: player.clubname,
                    classificationAndCategory: `${
                        this.classificationsBy(player)[0]?.short
                    }/${normalCategory ? normalCategory.short : '-'}`,
                    isPay: prices.length === 0 ? 'free' : player.pay,
                    isAnonymous: player.isAnonymous,
                    id: player.id,
                    classificationId: player.classification,
                    frontInfo: player.frontInfo,
                });
            }

            return list;
        },
        activeLang() {
            return this.$store.getters.lang[0].toUpperCase() + this.$store.getters.lang.slice(1);
        }
    },
    watch: {
        selectedClassification() {
            this.selectedCategory = null;
        },
        items() {
            this.registeredPlayers()
            this.paidPlayers()
        }
    },
    async created() {
        await Promise.all([
            this.READ_COMPETITION_CLASSIFICATIONS(this.id),
            this.READ_COMPETITION_PLAYERS(this.id),
            this.BOOTSTRAP_MINISITE(this.id),
            this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
        ]);

        this.ready = true;
    },
    methods: {
        ...mapActions([
            BOOTSTRAP_MINISITE,
            READ_COMPETITION_CLASSIFICATIONS,
            READ_COMPETITION_PLAYERS,
            READ_COMPETITION_CLASSIFICATIONS_LIMITS,
            DECLARE_PLAYERS_REGISTERED,
            DECLARE_PLAYERS_PAID,
        ]),
        registeredPlayers() {
            this.DECLARE_PLAYERS_REGISTERED(this.items.length)
        },
        paidPlayers() {
            this.DECLARE_PLAYERS_PAID(this.items.filter((x,i) => { return x.isPay; }).length)
        }        
    },
};
</script>
