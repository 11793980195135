import authentication from './authentication';
import common from './common';
import entities from './entities';
import facebook from './facebook';
import google from './google';
import internationalisation from './internationalisation';
import main from './main';
import minisite from './minisite';
import notification from './notification';
import stoper from './stoper';
import stoperSettings from './stoper-settings';
import user from './user';
import panel from './panel';
import notifications from './notifications';
import messages from './messages';

export default {
    authentication,
    common,
    entities,
    facebook,
    google,
    internationalisation,
    main,
    minisite,
    notification,
    stoper,
    stoperSettings,
    user,
    panel,
    notifications,
    messages,
};
