import { render, staticRenderFns } from "./PanelCompetitionFormBox.vue?vue&type=template&id=d3f04668&scoped=true&"
import script from "./PanelCompetitionFormBox.vue?vue&type=script&lang=js&"
export * from "./PanelCompetitionFormBox.vue?vue&type=script&lang=js&"
import style0 from "./PanelCompetitionFormBox.vue?vue&type=style&index=0&id=d3f04668&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3f04668",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/dostartu.pl/app.startzone.online/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d3f04668')) {
      api.createRecord('d3f04668', component.options)
    } else {
      api.reload('d3f04668', component.options)
    }
    module.hot.accept("./PanelCompetitionFormBox.vue?vue&type=template&id=d3f04668&scoped=true&", function () {
      api.rerender('d3f04668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PanelCompetitionFormBox.vue"
export default component.exports