<template>
  <div class="rangeSliderItemWrap" :class="{active: isActive, hover}" ref="resize-parent">
    <div :id="`panel-${index}`" @click="itemHandle" class="rangeSlider__item" :style="styles" ref="resizable-element" @mouseover="hover = true" @mouseleave="hover = false">
      <span class="rangeSlider__text" :class="{active: isActive || hover}">{{ dateRangeText }}</span>
      <span v-if="isActive && hover" class="rangeSlider__icon"><img src="@/assets/images/icons/range-slider/close.svg" alt=""></span>
      <span v-if="!isActive && hover" class="rangeSlider__icon"><img src="@/assets/images/icons/range-slider/plus.svg" alt=""></span>
    </div>
   </div>
</template>

<script>
  export default {
    name: 'ageCategoriesRangeSliderItem',
    props: {
      data: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        hover: false,
      }
    },
    computed: {
      isActive() {
        return this.$props.data.active
      },
      styles() {
        return {
          width: this.$props.data.width,
          left: this.$props.data.left,
        };
      },
      dateRangeText() {
        const text_age_from = this.$props.data.age_from
        const text_age_to = this.$props.data.age_to
        return text_age_from === text_age_to ? `${text_age_from}` : `${text_age_from} - ${text_age_to}`
      }
    },
    methods: {
      itemHandle() {
        if(!this.isActive) {
          this.$emit('active', {
            index: this.$props.index,
          })
        } else {
          this.$emit('disabled', {
            index: this.$props.index,
          })
        }
      }
    },
    mounted() {}    
  }
</script>

<style scoped>

.rangeSlider__item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  cursor: pointer;
}
.rangeSliderItemWrap:hover:not(.active) .rangeSlider__item {
  background-color: var(--rs-bg-track-hover);
}
.rangeSliderItemWrap.active:hover .rangeSlider__item {
  background-color: var(--rs-bg-active-hover);
}
.rangeSliderItemWrap.active .rangeSlider__item {
  background-color: var(--rs-bg-item-active);
  /* border-left: 1px solid #fff; */
  /* border-right: 1px solid #fff; */
}
.rangeSliderItemWrap.active .rangeSlider__handle {
  display: block;
}
.rangeSlider__text {
  display: none;
  position: absolute;
  top: -30px;
  color: #455A64;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}
.rangeSlider__text.active {
  display: block;
}
.rangeSlider__text span {
  font-weight: inherit;
}
</style>