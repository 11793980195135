var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h100" },
    [_c("pwa-notification"), _c(`layout-${_vm.layout}`, { tag: "component" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }