var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("modal", {
        ref: "checkOrganizerSetupModal",
        staticClass: "modal-background",
        attrs: { id: "checkOrganizerSetupModal" },
        on: {
          "modal-close": function ($event) {
            return _vm.$emit("close")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Uzupełnij dane")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "info-box info-bg" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-md-6" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "mab16",
                          staticStyle: { "line-height": "1.1" },
                        },
                        [
                          _vm._v(
                            "Uzupełnij dane organizatora i zaakceptuj umowę powierzenia przetwarzania danych osobowych."
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          "Aby dostosować działanie systemu do obowiązujących przepisów, potrzebujemy danych organizatorów do wystawienia umów o przetwarzaniu danych osobowych uczestników."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "base-button",
                  {
                    attrs: { btn2: "", short: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.checkOrganizerSetupModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
                _c(
                  "base-button",
                  {
                    attrs: { btn1: "", short: "" },
                    on: { click: _vm.goToPage },
                  },
                  [_vm._v("Przejdź do ustawień")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }