<template>
  <div v-if="ready" class="container">
    <div class="row mat24">
      <div class="col-lg-9 col-12 dashboard">
        <div class="dashboard-wrapper">
          <div v-if="competitionSetting.canInsuranceOn === false" class="insurance">
            <div class="insurance-status">
              <img src="@/assets/images/close-circle.svg" alt="" />
              <h2>Ubezpieczenia nieaktywne</h2>
            </div>
            <div class="insurance-content">
              <p><b>Daj uczestnikom możliwość ubezpieczenia startu w zawodach.</b></p>
              <p>Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?</p>
              <p>Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.</p>
              <p class="text-green">Włącz płatności automatyczne Dotpay, aby móc uruchomić ubezpieczenia.</p>
            </div>
          </div>          
          <div v-else-if="competitionSetting.insurancesOn" class="insurance">
            <div class="insurance-status active">
              <img src="@/assets/images/checkmark-circle.svg" alt="" />
              <h2 class="text-green">Ubezpieczenia aktywne</h2>
            </div>
            <div class="insurance-content">
              <p><b>Daj uczestnikom możliwość ubezpieczenia startu w zawodach.</b></p>
              <p>Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?</p>
              <p>Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.</p>
            </div>
            <base-button btn2 @click="save('off')">Wyłącz ubezpieczenia</base-button>
          </div>
          <div v-else class="insurance">
            <div class="insurance-status">
              <img src="@/assets/images/close-circle.svg" alt="" />
              <h2>Ubezpieczenia nieaktywne</h2>
            </div>
            <div class="insurance-content">
              <p><b>Daj uczestnikom możliwość ubezpieczenia startu w zawodach.</b></p>
              <p>Chcesz uruchomić w swoich zawodach możliwość wykupienia przez uczestników ubezpieczenia rezygnacji z uczestnictwa oraz następstwa nieszczęśliwych wypadków?</p>
              <p>Dla uczestników to jeden dodatkowy krok w formularzu rejestracyjnym.</p>
            </div>
            <base-button btn1 @click="save('on')">Włącz ubezpieczenia</base-button>
          </div>

        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="insurance-box">
          <p>Ubezpieczenia dostarcza</p>
          <img src="@/assets/images/Uniqa_Insurance_Group.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'PanelCompetitionInsurance',
  props: {
    id: {
      type: Number,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
  },  
  data() {
    return {
      ready: true,
    };
  },
  computed: {
    ...mapGetters([
      'competitionsSettings',
    ]),
    competitionSetting() {
      return this.competitionsSettings[this.id];
    },  
  },
  methods: {
    async save(availability) {
      await axios({
          method: 'put',
          url: `panel/competitions/${this.id}/settings`,
          data: {
            ...this.competitionSetting,
            insurancesOn: availability === 'on' ? true : false,
          }  
      })
      availability === 'on' ? this.competitionSetting.insurancesOn = true : this.competitionSetting.insurancesOn = false
    },
  }
}
</script>

<style scoped>
.text-green {
  color: var(--color-dark-pastel-green);
}
.dashboard {
  overflow: hidden;
}
.insurance {
  background: #455A64;
  border-radius: 4px;
  padding: 32px;
}
.insurance-status {
  background: #546E7A;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 26px 22px;
}
.insurance-status.active {
  background: #EDF6EE;
}

.insurance-status img {
  margin-right: 22px;
}
.insurance-content {
  margin-top: 40px;
  margin-bottom: 32px;
}
.insurance-content p b {
  font-size: 16px;
  font-weight: 600;
}
.insurance-content p {
  margin-bottom: 16px;
}
.insurance-box {
  background: #FFF;
  position: relative;
  border-radius: 4px;
  padding: 12px 16px;
}
.insurance-box p {
  color: #78909C;
}
.insurance >>> .btn {
  display: inline-flex;
  min-width: 208px;
}

.insurance-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .dashboard-wrapper {
    margin-right: 30px;
  }
  .insurance-box {
    min-height: 268px;
  }
}
</style>
