import Router from '@/router/router';
import routes from '@/router/routes';
import { authorize } from '@/service/security';
import store from '@/store';

export default (to, from, next) => {
    const { user } = store.getters;

    const redirect = authorize(to, from, user);

    if (redirect) {
        // Access denied.
        next(Router.getParsed(redirect, routes));
    } else {
        // Access granted.
        next();
    }
};
