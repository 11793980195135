var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register-shop-variants__quantity" }, [
    _c(
      "div",
      {
        staticClass: "jsQty jsQtyMinus",
        class: { "jsQty-disabled": _vm.quantity <= 0 },
        on: { click: _vm.decrement },
      },
      [_c("i", { staticClass: "fa fa-minus" })]
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.quantity,
          expression: "quantity",
        },
      ],
      staticClass: "jsShopAssortment",
      attrs: {
        type: "text",
        name: `shop_assortment[${_vm.product.id}][${_vm.model?.id || 0}]`,
      },
      domProps: { value: _vm.quantity },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.quantity = $event.target.value
          },
          _vm.emitQuantity,
        ],
      },
    }),
    _c(
      "div",
      { staticClass: "jsQty jsQtyPlus", on: { click: _vm.increment } },
      [_c("i", { staticClass: "fa fa-plus" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }