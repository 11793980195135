<template>
    <div>
        <!-- Form. -->
        <user-form-insurance
            v-model="insurance"
            :validation.sync="errors"
        />

        <!-- Validation. -->
        <base-form-validation :validation="errors" />

        <!-- Actions. -->
        <user-form-actions @save="save()" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UserFormInsurance from '@/components/UserFormInsurance';
import UserFormActions from '@/components/UserFormActions';
import { pick } from '@/utils/object';
import { SET_NOTIFICATION } from '@/store/mutations.type';
import { UPDATE_USER } from '@/store/actions.type';

export default {
    components: {
        UserFormInsurance,
        UserFormActions,
    },
    data() {
        return {
            insurance: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.insurance = pick(this.user, [
            'id',
            'pesel',
            'street',
            'houseNumber',
            'flatNumber',
            'city',
            'zipcode',
            'country',
        ]);
    },
    methods: {
        ...mapActions([UPDATE_USER]),
        ...mapMutations([SET_NOTIFICATION]),
        async save() {
            this.errors = {};
            try {
                await this.UPDATE_USER(this.insurance);

                this.SET_NOTIFICATION('alerts_positive_5');
            } catch (errors) {
                this.errors = errors;
            }
        },
    },
};
</script>
