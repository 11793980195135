import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('users', {}, {
    processStrategy: (user) => ({
        // Meta.
        _type: 'user',
        // Fields.
        ...user,
        // Cast types.
        birthTime: user.birthTime ? moment(user.birthTime) : null,
    }),
});
