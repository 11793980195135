var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-field input-field--focus-bolder" }, [
    _c(
      "label",
      {
        staticClass: "input-field__label",
        class: { "input-field__label--show": _vm.value },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _c("input", {
      staticClass: "input-field__input",
      class: { "input-field__input--padding-top": _vm.value },
      attrs: { type: "text", placeholder: _vm.label },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _c("i", {
      staticClass: "input-field__icon",
      class: [_vm.value && _vm.badge ? _vm.badge : _vm.icon],
      on: {
        click: function ($event) {
          return _vm.$emit("icon-click")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }