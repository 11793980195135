var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "register-classification",
      class: {
        disabledCl: !_vm.inAgeRange || !_vm.classification.posible,
        error: _vm.error,
      },
    },
    [
      _c(
        "div",
        { staticClass: "register-classification__header" },
        [
          _c("div", { staticClass: "register-classification__name" }, [
            _vm._v(_vm._s(_vm.classification.name)),
          ]),
          _vm.classificationSetting &&
          _vm.classificationSetting.isPay &&
          _vm.prices &&
          !_vm.prices.length
            ? [
                _c("div", { staticClass: "register-classification__price" }, [
                  _vm._v(_vm._s(_vm.say.unknown_payment_information)),
                ]),
              ]
            : _vm.classificationSetting && !_vm.classificationSetting.isPay
            ? [
                _c("div", { staticClass: "register-classification__price" }, [
                  _vm._v(_vm._s(_vm.say.feeless)),
                ]),
              ]
            : _vm.ascSortPrices && _vm.ascSortPrices.length
            ? [
                _c("div", { staticClass: "register-classification__price" }, [
                  _vm._v(
                    _vm._s(_vm.ascSortPrices[0].price) +
                      " " +
                      _vm._s(_vm.currency)
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "register-classification__details" }, [
        _c(
          "div",
          [
            _c("div", [
              _c("p", [_vm._v(_vm._s(_vm.say.distance))]),
              _c("b", [_vm._v(_vm._s(_vm.classification.distance) + " km")]),
            ]),
            _c("div", [
              _c("p", [_vm._v(_vm._s(_vm.say.gender))]),
              _c(
                "b",
                [
                  _vm.canM && _vm.canK
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(_vm.say.woman_short) +
                            "," +
                            _vm._s(_vm.say.man_short) +
                            " "
                        ),
                      ]
                    : _vm.canM
                    ? [_vm._v(" " + _vm._s(_vm.say.man_short) + " ")]
                    : _vm.canK
                    ? [_vm._v(" " + _vm._s(_vm.say.woman_short) + " ")]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("div", [
              _c("p", [_vm._v(_vm._s(_vm.say.age))]),
              _c("b", [
                _vm._v(_vm._s(_vm.age.from) + "-" + _vm._s(_vm.age.to)),
              ]),
            ]),
            _vm.canRegisterClassification(_vm.competition, _vm.limits)
              ? [
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.say.available))]),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.limits.playersAvailable) +
                          " / " +
                          _vm._s(_vm.limits.playersLimit)
                      ),
                    ]),
                  ]),
                ]
              : [
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.say.number_of_participants))]),
                    _c("b", [_vm._v(_vm._s(_vm.limits.playersUnavailable))]),
                  ]),
                ],
          ],
          2
        ),
        _c("div", [
          _c("div", { staticClass: "btnchk" }, [
            _c(
              "label",
              {
                attrs: {
                  for: `classifications[${_vm.competitionId}][${_vm.id}]`,
                },
                on: {
                  change: function ($event) {
                    _vm.$emit("input", {
                      value: $event.target.value,
                      price:
                        _vm.prices && Object.keys(_vm.prices).length > 0
                          ? _vm.prices.price
                          : 0,
                    })
                  },
                },
              },
              [
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: `classifications[${_vm.competitionId}][${_vm.id}]`,
                    name: `classification[${_vm.competitionId}]`,
                  },
                  domProps: { value: _vm.id, checked: _vm.checked },
                }),
                _vm._m(0),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.classificationSetting &&
      _vm.classificationSetting.isPay &&
      _vm.prices &&
      Object.keys(_vm.prices).length === 0
        ? [
            _c("div", { staticClass: "register-classification__details" }, [
              _c("div", {
                staticClass: "register-classification__price",
                domProps: {
                  innerHTML: _vm._s(_vm.say.unknown_payment_information),
                },
              }),
            ]),
          ]
        : _vm.classificationSetting && !_vm.classificationSetting.isPay
        ? [
            _c("div", { staticClass: "register-classification__details" }, [
              _c("div", {
                staticClass: "register-classification__price",
                domProps: { innerHTML: _vm._s(_vm.say.feeless) },
              }),
            ]),
          ]
        : _vm.prices && Object.keys(_vm.prices).length > 0
        ? [
            _c("div", { staticClass: "register-classification__details" }, [
              _c("div", { staticClass: "register-classification__price" }, [
                _vm._v(_vm._s(_vm.prices.price) + " " + _vm._s(_vm.currency)),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.classification && _vm.classification.error_info
        ? _c("div", { staticClass: "validation-text" }, [
            _vm._v(_vm._s(_vm.classification.error_info)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btnchk-wrap" }, [
      _c("span", { staticClass: "btnchk-txt" }, [
        _vm._v("Wybierz klasyfikację"),
      ]),
      _c("span", { staticClass: "btnchk-ico" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }