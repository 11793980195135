<template>
    <div>
        <!-- Form. -->
        <user-form-profile
            v-model="profile"
            :validation.sync="errors"
        />

        <!-- Validation. -->
        <base-form-validation :validation="errors" />

        <!-- Actions. -->
        <user-form-actions @save="save()" />
    </div>
</template>

<script>
import UserFormProfile from '@/components/UserFormProfile';
import UserFormActions from '@/components/UserFormActions';
import { pick } from '@/utils/object';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { UPDATE_USER } from '@/store/actions.type';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        UserFormProfile,
        UserFormActions,
    },
    data() {
        return {
            profile: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.profile = pick(this.user, [
            'id',
            'firstname',
            'lastname',
            'gender',
            'birthTime',
            'phonePrefix',
            'phone',
            'clubname',
            'city',
            'nationality',
        ]);
		if (this.profile.phonePrefix === null) {
			this.profile.phonePrefix = '48'
		}
    },
    methods: {
        ...mapActions([UPDATE_USER]),
        ...mapMutations([SET_NOTIFICATION]),
        async save() {
            this.errors = {};
            try {
                await this.UPDATE_USER(this.profile);

                this.SET_NOTIFICATION('alerts_positive_5');
            } catch (errors) {
                this.errors = errors;
            }
        },
    },
};
</script>
