<template>
    <div
        class="input-field input-field--focus-bolder"
        :class="{'input-field--error' : error, 'input-field--short': isPanel}"
    >
        <label
            v-if="!error && !isPanel"
            class="input-field__label"
            :class="{'input-field__label--show' : isInput}"
        >{{ label }}</label>

        <v-select
            append-to-body
            class="input-field__select"
            :class="{
                'input-field__select--with-selected': !isInput,
                'input-field__input--padding-bottom' : error && !isPanel,
                'input-field__input--padding-top' : isInputValid && !isPanel,
                'input-field__select--panel': isPanel
            }"
            :options="options"
            :label="labelSelect"
            :reduce="reduce"
            :value="value"
            :placeholder="placeholder"
            :clearable="clearable"
            :searchable="searchable"
            @input="$emit('input', $event), $emit('update:error', null)"
        />

        <span
            class="input-error"
            :class="{'input-error--show' : error && !withoutErrorMessage}"
        >{{ say[error] || error }}</span>
    </div>
</template>

<script>
import VSelect from 'vue-select';
import input from '@/mixins/input';
import { mapGetters } from 'vuex';

export default {
    components: {
        VSelect,
    },
    mixins: [input],
    props: {
        reduce: {
            type: Function,
            default: undefined,
        },
        labelSelect: {
            type: String,
            default: 'text',
        },
        options: {
            type: Array,
            default: () => [],
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        isPanel: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style src='vue-select/dist/vue-select.css'></style>

<style scoped>
.input-field__select {
    width: 100%;
}
.input-field__select >>> .vs__dropdown-toggle {
    border: none;
    padding: 0;
    height: 100%;
}
.input-field__select >>> .vs__selected {
    display: flex;
    margin-top: auto;
    position: relative !important;
}
.input-field__select >>> input {
    width: 100%;
}
.input-field__select >>> .vs__search {
    position: absolute;
    bottom: 0;
}

.input-field__select--with-selected >>> input {
    height: 100%;
}
.input-field__select >>> .vs__selected-options {
    padding-bottom: 3px;
}
.input-field__select--panel >>> .vs__selected {
    margin-top: 0;
}
.input-field {
    position: relative;
    min-height: 48px;
    border: solid 1px var(--color-slate-two);
    border-radius: 4px;
    background-color: var(--color-white);
    display: flex;
    box-sizing: border-box;
    padding: 0;
}
.input-field--focus-bolder:focus-within {
    border: solid 2px var(--color-dark-pastel-green);
}
.input-field--focus-bolder:focus-within .vs__search {
    padding: 0 6px;
}

.input-field--focus-bolder:focus-within .input-field__input {
    padding-left: 9px;
    padding-right: 0;
}
.input-field--error {
    border: solid 2px var(--color-pinky-red)!important;
}
.input-field__label {
    position: absolute;
    opacity: 0;
    top: 5px;
    left: 10px;
    font-size: 12px;
    color: var(--color-greyish-brown);
    transition: all var(--animation-fast);
}
.input-field__label--show {
    opacity: 1;
    z-index: 2;
}
.input-field__input--padding-top {
    padding-top: 15px;
}
.input-field__input--padding-bottom {
    padding-bottom: 15px;
}
.input-error {
    position: absolute;
    transition: all var(--animation-fast);
    opacity: 0;
    bottom: 5px;
    left: 10px;
    color: var(--color-pinky-red);
    font-size: 12px;
    z-index: -1;
}
.input-error--show {
    opacity: 1;
    z-index: 1;
}
</style>
