<template>
    <div class="stoper-prices">
        <div
            class="stoper-price__left-background"
            :style="{ width: widthOfAdditionalBackground + 'px' }"
        />
        <div
            class="stoper-price__right-background"
            :style="{ width: widthOfAdditionalBackground + 'px' }"
        />

        <div
            ref="prices"
            class="container"
        >
            <div class="row align-items-end">
                <div class="col-lg-3">
                    <div class="mar30">
                        <h2>Koszt pomiaru czasu stoperem</h2>
                        <p class="stoper-prices__hint">
                            Jeśli do Twoich zawodów zapisało się ponad 50
                            uczestników, otrzymasz od nas fakturę VAT na kwotę
                            uzależnioną do liczby uczesników w zawodach.
                        </p>
                        <p class="stoper-prices__hint">
                            W zawodach do 50 uczestników pomiar stoperem jest
                            <span class="positive">DARMOWY</span>
                        </p>
                        <div
                            v-if="competitionPlayerCount"
                            class="stoper-prices__players row"
                        >
                            <h1 class="stoper-prices__players-value">
                                {{ playersCount }}
                            </h1>
                            <div class="stoper-prices__players-hint">
                                <p>
                                    Aktualna liczba zarejestrowanych uczestników
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row h100">
                        <div class="col-lg-4">
                            <div
                                class="stoper-prices__box"
                                :class="{
                                    'stoper-prices__box--active':
                                        playersCount <= 50,
                                }"
                            >
                                <div class="stoper-prices__box-hint">
                                    <p>Zawody sportowe</p>
                                    <h3 class="f36">
                                        do 50
                                    </h3>
                                    <p>uczestników</p>
                                </div>
                                <h2 class="stoper-prices__box-price">
                                    0 zł
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div
                                class="stoper-prices__box"
                                :class="{
                                    'stoper-prices__box--active':
                                        playersCount > 50 &&
                                        playersCount <= 100,
                                }"
                            >
                                <div class="stoper-prices__box-hint">
                                    <p>Zawody sportowe</p>
                                    <h3 class="f36">
                                        do 100
                                    </h3>
                                    <p>uczestników</p>
                                </div>
                                <h2 class="stoper-prices__box-price">
                                    250 zł
                                </h2>
                                <p class="text-center mat4">
                                    za zawody
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div
                                class="stoper-prices__box"
                                :class="{
                                    'stoper-prices__box--active':
                                        playersCount > 100,
                                }"
                            >
                                <div class="stoper-prices__box-hint">
                                    <p>Zawody sportowe</p>
                                    <h3 class="f36">
                                        ponad 100
                                    </h3>
                                    <p>uczestników</p>
                                </div>
                                <h2 class="stoper-prices__box-price">
                                    2,50 zł
                                </h2>
                                <p class="text-center mat4">
                                    za uczestnika
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { READ_COMPETITION_COUNT } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        competition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            widthOfAdditionalBackground: 0,
            peopleCount: 99,
        };
    },
    computed: {
        ...mapGetters(['competitionPlayerCounts', 'competitionsSettings']),
        competitionSetting() {
            return this.competitionsSettings[
                this.competition.competitionSetting
            ];
        },
        isRegisterPlayerLimit() {
            return this.competitionSetting.playersKindLimit === 'register';
        },
        competitionPlayerCount() {
            return this.competitionPlayerCounts[this.competition.id];
        },
        playersCount() {
            if (this.isRegisterPlayerLimit) {
                return this.competitionPlayerCount?.playersCount;
            }
            return this.competitionPlayerCount?.playersPayCount;
        },
    },
    async created() {
        await this.READ_COMPETITION_COUNT(this.competition.id);
    },
    mounted() {
        this.$nextTick(() => this.measureWidthOfAdditionalBackground());
        window.addEventListener('resize', () => this.measureWidthOfAdditionalBackground());
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => this.measureWidthOfAdditionalBackground());
    },

    methods: {
        ...mapActions([READ_COMPETITION_COUNT]),
        measureWidthOfAdditionalBackground() {
            const stoperPricesWidth = this.$refs.prices?.clientWidth;
            const windowWidth = document.body.clientWidth;
            const widthOfOneSide = Number.parseFloat(
                (windowWidth - stoperPricesWidth) / 2,
            );
            this.widthOfAdditionalBackground = widthOfOneSide;
        },
    },
};
</script>

<style scoped>
.stoper-prices {
    background-color: var(--color-slate);
    padding-top: 40px;
    position: relative;
    line-height: 1.43;
    padding-bottom: 130px;
}
.stoper-prices__hint {
    margin-top: 24px;
}
.positive {
    color: var(--color-light-sage);
}
.stoper-prices__players {
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: flex-start;
}
.stoper-prices__players-value {
    margin-right: 20px;
    font-size: 72px;
    color: var(--color-light-sage);
}
.stoper-prices__box {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--color-slate-two);
    margin: 0 15px;
    height: 100%;
    box-sizing: border-box;
    height: 289px;
}
.stoper-prices__box-hint {
    border-radius: 4px;
    background-color: var(--color-slate);
    text-align: center;
    padding-top: 24px;
    font-weight: 600;
    line-height: 1.71;
    padding-bottom: 16px;
}
.stoper-prices__box-price {
    font-size: 64px;
    text-align: center;
    margin-top: 24px;
    line-height: 0.94;
}
.stoper-prices__box--active {
    border: solid 2px var(--color-light-sage);
}
.stoper-price__left-background {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    background-color: var(--color-slate);
    width: 100px;
    height: 100%;
    top: 0;
    transform: translateX(-100%);
}
.stoper-price__right-background {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    background-color: var(--color-slate);
    width: 100px;
    height: 100%;
    top: 0;
    transform: translateX(100%);
}
.stoper-prices__players-hint {
    max-width: var(--col-5);
}
</style>
