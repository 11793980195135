import classification from '@/api/schema/classification';
import user from '@/api/schema/user';
import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('players', {
    classification,
    user,
    parent: user,
}, {
    processStrategy: (player) => ({
        // Meta.
        _type: 'player',
        // Fields.
        ...player,
        // Cast fields.
        // Time must have multiply by 10, becouse in panel time is reading to 2 decimal places.
        time: player.time ? moment.utc(player.time * 10, 'x') : null,
        birthTime: player.birthTime ? moment(player.birthTime) : null,
    }),
});
