<template>
    <div
        v-if="notification"
        :class="classes"
    >
        {{ say[notification] }}
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { CLEAR_NOTIFICATION } from '@/store/mutations.type';

export default {
    computed: {
        ...mapGetters(['notification', 'say']),
        positive() {
            return this.notification.search('positive') >= 0;
        },
        negative() {
            return !this.positive;
        },
        isPanel() {
            const rootRoute = this.$route.matched.find((route) => route.meta.layout);
            if (rootRoute.meta.layout === 'back') {
                return true;
            }
            return false;
        },
        classes() {
            return {
                notification: true,
                'notification--positive': this.positive && !this.isPanel,
                'notification--negative': this.negative && !this.isPanel,
                'notification--positive-panel': this.positive && this.isPanel,
                'notification--negative-panel': this.negative && this.isPanel,
            };
        },
    },
    watch: {
        notification() {
            if (this.notification) {
                this.scrollToTop();
                setTimeout(this.CLEAR_NOTIFICATION, 3000);
            }
        },
    },
    methods: {
        ...mapMutations([CLEAR_NOTIFICATION]),
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style scoped>
.notification {
    padding: 12px;
    text-align: center;
    line-height: 1.43;
}

.notification--negative {
    background-color: var(--color-pinky-red-10);
}

.notification--positive {
    background-color: var(--color-light-sage-40);
}
.notification--positive-panel {
    background-color: var(--color-dark-pastel-green);
}
.notification--negative-panel {
    background-color: var(--color-lipstick);
}
</style>
