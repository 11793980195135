var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("user-form-child", {
        attrs: { validation: _vm.errors },
        on: {
          "update:validation": function ($event) {
            _vm.errors = $event
          },
        },
        model: {
          value: _vm.newChild,
          callback: function ($$v) {
            _vm.newChild = $$v
          },
          expression: "newChild",
        },
      }),
      _c("user-form-actions", {
        on: {
          save: function ($event) {
            return _vm.save()
          },
          cancel: function ($event) {
            return _vm.$router.push({
              name: "user-family-children",
              alias: true,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }