var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row chrono-classification mat8",
      class: { "chrono-classification--disabled": !_vm.checkpoint },
    },
    [
      _c(
        "div",
        {
          staticClass: "col-lg-8 align-items-center",
          class: { disabled: !_vm.checkpoint },
        },
        [_vm._v(" " + _vm._s(_vm.classification.namePl) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "col-lg-4 d-flex align-items-center justify-content-end",
        },
        [
          _vm.checkpoint && _vm.checkpoint.number === 0
            ? _c(
                "div",
                { staticClass: "event-wrap" },
                [
                  _c("base-input-mask", {
                    staticClass: "chrono-classification__input",
                    attrs: {
                      mask: "00.00.0000 00:00:00",
                      placeholder: "DD.MM.RRRR GG:MM:SS",
                    },
                    model: {
                      value: _vm.startTime,
                      callback: function ($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime",
                    },
                  }),
                  _vm.loading
                    ? _c("img", {
                        staticClass: "event-wrap-img",
                        attrs: {
                          src: require("@/assets/images/icons/loading.svg"),
                        },
                      })
                    : _vm._e(),
                  _vm.saved
                    ? _c("img", {
                        staticClass: "event-wrap-img",
                        attrs: {
                          src: require("@/assets/images/icons/checkmark-circle.svg"),
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "chrono-classification__checkbox",
              class: {
                "chrono-classification__checkbox--disabled":
                  _vm.checkpoint && (_vm.isStart || _vm.isMeta),
              },
              on: {
                click: function ($event) {
                  !_vm.checkpoint
                    ? _vm.ENABLE_CHECKPOINT({
                        classification: _vm.classification,
                        checkpointGroup: _vm.checkpointGroup,
                      })
                    : _vm.isStart || _vm.isMeta
                    ? ""
                    : _vm.DISABLE_CHECKPOINT(_vm.checkpoint)
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas",
                class: [_vm.checkpoint ? "fa-check-square" : "fa-square"],
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }