var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass: "table-list__row",
      class: {
        "table-list__row--paid": _vm.playerPay || _vm.playerFree,
        "table-list__row--not__paid": !_vm.playerPay && !_vm.playerFree,
        "table-list__row--active": _vm.hover,
      },
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    _vm._l(_vm.headersDefault, function (header, column) {
      return _c(
        "td",
        {
          key: column,
          staticClass: "table-list__data",
          class: {
            "table-list__data--first-cell": header.value === "playerNumber",
            "table-list__data--min-width": header.value === "playerChip",
            "table-list__data--max-width": header.value === "playerGpsNumber",
            "table-list__data--lp": header.value === "ordinalNumber",
            "table-list__data--pay": header.value === "pay",
          },
          on: {
            click: function ($event) {
              return _vm.userDetails(_vm.item, header.value)
            },
          },
        },
        [
          header.value === "ordinalNumber"
            ? [_vm._v(" " + _vm._s(_vm.lp + 1) + " ")]
            : header.value === "playerNumber"
            ? [
                _vm.hover || _vm.index === _vm.openedIndex || _vm.isMobile
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updatePlayer({
                              id: _vm.item.id,
                              playerNumber: _vm.playerNumber,
                            })
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "inputWrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.playerNumber,
                                expression: "playerNumber",
                              },
                            ],
                            domProps: { value: _vm.playerNumber },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.playerNumber = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isMobile,
                                  expression: "!isMobile",
                                },
                              ],
                              class: {
                                accepted: _vm.state_number.accepted,
                                wrong: _vm.state_number.wrong,
                              },
                            },
                            [
                              _vm.state_number.submitted
                                ? _c("i", { staticClass: "fas fa-spinner" })
                                : _vm.state_number.accepted
                                ? _c("i", { staticClass: "fas fa-check" })
                                : _vm.state_number.wrong
                                ? _c("i", {
                                    staticClass: "fas fa-exclamation-triangle",
                                  })
                                : _c("i", {
                                    staticClass: "fas fa-check-circle",
                                  }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : !_vm.item["playerNumber"]
                  ? _c("span", [_vm._v("-")])
                  : _c("span", [_vm._v(_vm._s(_vm.item["playerNumber"]))]),
              ]
            : header.value === "payLink"
            ? [
                _vm.playerFree
                  ? _c("span")
                  : _c("div", { staticClass: "btn" }, [
                      _c("img", {
                        staticStyle: { "margin-right": "4px" },
                        attrs: {
                          src: require("../assets/images/icons/link.svg"),
                        },
                      }),
                      _vm._v(" Kopiuj link"),
                    ]),
              ]
            : header.value === "pay"
            ? _c(
                "div",
                { staticClass: "flex-wrap" },
                [
                  _vm.playerFree
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          staticStyle: { cursor: "default" },
                        },
                        [_vm._m(0, true)]
                      )
                    : [
                        _vm.isMobile
                          ? _c("panel-input-switch-mini", {
                              attrs: { value: _vm.playerPay },
                              model: {
                                value: _vm.playerPay,
                                callback: function ($$v) {
                                  _vm.playerPay = $$v
                                },
                                expression: "playerPay",
                              },
                            })
                          : _c(
                              "div",
                              {
                                staticClass: "btn",
                                class: {
                                  active:
                                    _vm.hover || _vm.index === _vm.openedIndex,
                                  yes: _vm.playerPay,
                                  no: !_vm.playerPay,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.playerPay = !_vm.playerPay
                                  },
                                },
                              },
                              [
                                _vm.playerPay
                                  ? _c("span", { staticClass: "yes" }, [
                                      _c("i", {
                                        staticClass: "fas fa-check-circle",
                                      }),
                                      _vm._v(" Opłacony"),
                                    ])
                                  : _c("span", { staticClass: "no" }, [
                                      _c("i", {
                                        staticClass: "fas fa-times-circle",
                                      }),
                                      _vm._v(" Nieopłacony"),
                                    ]),
                              ]
                            ),
                      ],
                ],
                2
              )
            : header.value === "payNumber"
            ? _c(
                "div",
                { staticClass: "flex-wrap" },
                [
                  _vm.isMobile
                    ? _c("panel-input-switch-mini", {
                        attrs: { value: _vm.playerpayNumber },
                        model: {
                          value: _vm.playerpayNumber,
                          callback: function ($$v) {
                            _vm.playerpayNumber = $$v
                          },
                          expression: "playerpayNumber",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "btn",
                          class: {
                            active: _vm.hover || _vm.index === _vm.openedIndex,
                            yes: _vm.playerpayNumber,
                            no: !_vm.playerpayNumber,
                          },
                          on: {
                            click: function ($event) {
                              _vm.playerpayNumber = !_vm.playerpayNumber
                            },
                          },
                        },
                        [
                          _vm.playerpayNumber
                            ? _c("span", { staticClass: "yes" }, [
                                _c("i", { staticClass: "fas fa-check-circle" }),
                                _vm._v(" Opłacony"),
                              ])
                            : _c("span", { staticClass: "no" }, [
                                _c("i", { staticClass: "fas fa-times-circle" }),
                                _vm._v(" Nieopłacony"),
                              ]),
                        ]
                      ),
                ],
                1
              )
            : header.value === "isAnonymous"
            ? _c(
                "div",
                { staticClass: "flex-wrap" },
                [
                  _vm.isMobile
                    ? _c("panel-input-switch-mini", {
                        attrs: { value: _vm.playerisAnonymous },
                        model: {
                          value: _vm.playerisAnonymous,
                          callback: function ($$v) {
                            _vm.playerisAnonymous = $$v
                          },
                          expression: "playerisAnonymous",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "btn",
                          class: {
                            active: _vm.hover || _vm.index === _vm.openedIndex,
                            yes: _vm.playerisAnonymous,
                            no: !_vm.playerisAnonymous,
                          },
                          on: {
                            click: function ($event) {
                              _vm.playerisAnonymous = !_vm.playerisAnonymous
                            },
                          },
                        },
                        [
                          _vm.playerisAnonymous
                            ? _c("span", { staticClass: "yes" }, [
                                _c("i", { staticClass: "fas fa-check-circle" }),
                                _vm._v(" Anonimowy"),
                              ])
                            : _c("span", { staticClass: "no" }, [
                                _c("i", { staticClass: "fas fa-times-circle" }),
                                _vm._v(" Nieanonimowy"),
                              ]),
                        ]
                      ),
                ],
                1
              )
            : header.value === "playerChip"
            ? [
                _vm.hover || _vm.index === _vm.openedIndex || _vm.isMobile
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updatePlayer({
                              id: _vm.item.id,
                              playerChip: _vm.playerChip,
                            })
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "inputWrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.playerChip,
                                expression: "playerChip",
                              },
                            ],
                            domProps: { value: _vm.playerChip },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.playerChip = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isMobile,
                                  expression: "!isMobile",
                                },
                              ],
                              class: {
                                accepted: _vm.state_chip.accepted,
                                wrong: _vm.state_chip.wrong,
                              },
                            },
                            [
                              _vm.state_chip.submitted
                                ? _c("i", { staticClass: "fas fa-spinner" })
                                : _vm.state_chip.accepted
                                ? _c("i", { staticClass: "fas fa-check" })
                                : _vm.state_chip.wrong
                                ? _c("i", {
                                    staticClass: "fas fa-exclamation-triangle",
                                  })
                                : _c("i", {
                                    staticClass: "fas fa-check-circle",
                                  }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _c("span", { staticClass: "ellipis" }, [
                      _vm._v(_vm._s(_vm.item["playerChip"])),
                    ]),
              ]
            : header.value === "playerGpsNumber"
            ? [
                _vm.hover || _vm.index === _vm.openedIndex || _vm.isMobile
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            _vm.updatePlayer({
                              id: _vm.item.id,
                              playerGpsNumber: Number(_vm.playerGpsNumber),
                            })
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "inputWrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.playerGpsNumber,
                                expression: "playerGpsNumber",
                              },
                            ],
                            staticStyle: { "max-width": "68px" },
                            domProps: { value: _vm.playerGpsNumber },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.playerGpsNumber = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isMobile,
                                  expression: "!isMobile",
                                },
                              ],
                              class: {
                                accepted: _vm.state_gps.accepted,
                                wrong: _vm.state_gps.wrong,
                              },
                            },
                            [
                              _vm.state_gps.submitted
                                ? _c("i", { staticClass: "fas fa-spinner" })
                                : _vm.state_gps.accepted
                                ? _c("i", { staticClass: "fas fa-check" })
                                : _vm.state_gps.wrong
                                ? _c("i", {
                                    staticClass: "fas fa-exclamation-triangle",
                                  })
                                : _c("i", {
                                    staticClass: "fas fa-check-circle",
                                  }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _c("span", { staticClass: "ellipis" }, [
                      _vm._v(_vm._s(_vm.item["playerGpsNumber"])),
                    ]),
              ]
            : typeof header.value === "number"
            ? [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.playerInputs(header.value)),
                  },
                }),
              ]
            : header.value === "status"
            ? [
                _vm.playerStatus === 0 || _vm.playerStatus === 1
                  ? [
                      _vm.isMobile
                        ? _c("panel-input-switch-mini", {
                            attrs: { value: _vm.playerStatus },
                            model: {
                              value: _vm.playerStatus,
                              callback: function ($$v) {
                                _vm.playerStatus = $$v
                              },
                              expression: "playerStatus",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "btn",
                              class: {
                                active:
                                  _vm.hover || _vm.index === _vm.openedIndex,
                                yes: _vm.playerStatus,
                                no: !_vm.playerStatus,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.playerStatus = !_vm.playerStatus
                                },
                              },
                            },
                            [
                              _vm.playerStatus === 0
                                ? _c("span", { staticClass: "yes" }, [
                                    _c("i", {
                                      staticClass: "fas fa-check-circle",
                                    }),
                                    _vm._v(" Udział"),
                                  ])
                                : _vm.playerStatus === 1
                                ? _c("span", { staticClass: "no" }, [
                                    _c("i", {
                                      staticClass: "fas fa-times-circle",
                                    }),
                                    _vm._v(" Brak udziału"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                    ]
                  : [_vm._v("-")],
              ]
            : header.value === "classification" ||
              header.value === "ageCategory"
            ? [
                _vm.item[header.value] === "" || _vm.item[header.value] === null
                  ? _c("span", { staticClass: "no" }, [
                      _c("i", { staticClass: "fas fa-exclamation-triangle" }),
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.item[header.value]))]),
              ]
            : header.value === "gender"
            ? [
                _vm.genderIssue
                  ? _c("span", { staticClass: "issue" }, [
                      _c("i", { staticClass: "fas fa-exclamation-triangle" }),
                    ])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.item[header.value]) + " "),
              ]
            : header.value === "frontColumn"
            ? [_vm._v(" " + _vm._s(_vm.item["frontInfo"]) + " ")]
            : [
                typeof _vm.item[header.value] === "string"
                  ? [_vm._v(_vm._s(_vm.item[header.value].toLowerCase()))]
                  : [_vm._v(_vm._s(_vm.item[header.value]))],
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "yes" }, [
      _c("i", { staticClass: "fas fa-check-circle" }),
      _vm._v(" Bezpłatne"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }