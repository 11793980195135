<template>
    <div
        v-if="ready"
        class="overflow-hidden mat48 pab16"
    >
        <main-header
            :header-color="headerColor"
            is-main-site
            :with-posters="canShowCarousel"
        >
            <template #left>
                <main-site
                    :is-tall="canShowCarousel"
                    :person="organizer"
                    :layout="layout"
                />
            </template>
            <template #right>
                <main-header-search is-main-site />
            </template>
        </main-header>
        <div
            v-if="canShowCarousel"
            class="container"
        >
            <div class="row justify-content-end">
                <div class="col-lg-9 main-site__box-carousel">
                    <main-carousel
                        :competition-filter="{organizer: organizer.id}"
                        @ready="carouselCompetitionsIds = $event, carouselReady=true"
                    />
                </div>
            </div>
        </div>
        <main-list />
    </div>
</template>

<script>
import MainSite from '@/components/MainSite';
import { mapActions, mapGetters } from 'vuex';
import { READ_ORGANIZER, READ_ORGANIZER_LAYOUT } from '@/store/actions.type';
import MainHeaderSearch from '@/components/MainHeaderSearch';
import MainHeader from '@/components/MainHeader';
import MainList from '@/components/MainList';
import MainCarousel from '@/components/MainCarousel';
import MainSiteMixin from '@/mixins/main-site';

export default {
    components: {
        MainSite,
        MainHeaderSearch,
        MainHeader,
        MainList,
        MainCarousel,
    },
    mixins: [MainSiteMixin],
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['layouts', 'organizers']),
        organizer() {
            return this.organizers[this.id];
        },
    },
    async created() {
        await Promise.all([
            this.READ_ORGANIZER(this.id),
            (this.layoutId = await this.READ_ORGANIZER_LAYOUT(this.id)),
        ]);
        this.ready = true;
    },
    methods: {
        ...mapActions([READ_ORGANIZER, READ_ORGANIZER_LAYOUT]),
    },
};
</script>
<style scoped>
.main-site__box-carousel {
    max-width: 100%;
}
@media (min-width: 992px) {
    .main-site__box-carousel {
        max-width: 75%;
    }
}
</style>
