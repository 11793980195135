var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "competition-edit ml-md-30" },
    [
      !_vm.ready
        ? _c("div", { staticClass: "row mat32" }, [_vm._m(0)])
        : _vm._e(),
      _vm.ready
        ? [
            _c("panel-competition-form-box", [
              _c("div", { staticClass: "row w100" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", [_vm._v("Regulamin zawodów")]),
                ]),
                _c("div", { staticClass: "col-12 col-md-8" }, [
                  _c("div", { staticClass: "radios2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.statute_type,
                          expression: "statute_type",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "statute_type_file",
                        value: "file",
                      },
                      domProps: { checked: _vm._q(_vm.statute_type, "file") },
                      on: {
                        change: function ($event) {
                          _vm.statute_type = "file"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticStyle: { "line-height": "32px" },
                        attrs: { for: "statute_type_file" },
                      },
                      [_vm._v("Wgraj plik")]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticClass:
                          "hrline-left fileinput mab16 jsStatuteFile",
                        class: { disabled: _vm.statute_type === "link" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              class: [_vm.isThereAStatute ? "col-9" : "col-12"],
                            },
                            [
                              _c("base-input-file", {
                                attrs: {
                                  isMultiple: false,
                                  accept: ".pdf",
                                  hint: "Wybierz plik",
                                  disabled: _vm.statute_type === "link",
                                },
                                model: {
                                  value: _vm.statuteList,
                                  callback: function ($$v) {
                                    _vm.statuteList = $$v
                                  },
                                  expression: "statuteList",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isThereAStatute
                            ? _c(
                                "div",
                                { attrs: { col: "col-3" } },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticStyle: {
                                        height: "40px",
                                        "box-sizing": "border-box",
                                      },
                                      attrs: { btn2: "" },
                                      on: { click: _vm.removeFile },
                                    },
                                    [_vm._v("Usuń plik")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.statute_type,
                          expression: "statute_type",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "statute_type_link",
                        value: "link",
                      },
                      domProps: { checked: _vm._q(_vm.statute_type, "link") },
                      on: {
                        change: function ($event) {
                          _vm.statute_type = "link"
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticStyle: { "line-height": "32px" },
                        attrs: { for: "statute_type_link" },
                      },
                      [_vm._v("Regulamin pod linkiem")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "hrline-left mab8 jsStatuteLink",
                        class: { disabled: _vm.statute_type === "file" },
                      },
                      [
                        _c("base-input-text", {
                          attrs: { "without-error-message": "" },
                          model: {
                            value: _vm.statute_link,
                            callback: function ($$v) {
                              _vm.statute_link = $$v
                            },
                            expression: "statute_link",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "competition-content ml-md-30" },
                    [
                      _vm.isThereAStatute
                        ? [
                            _c("p", [_vm._v("Podgląd pliku")]),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.competition.statuteFilePl,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [_vm._v("Regulamin zawodów")]
                            ),
                          ]
                        : [
                            _c("p", [
                              _vm._v(
                                "Dodaj regulamin w pliku PDF lub podaj link do regulaminu na zewnętrznej stronie."
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Link może prowadzić do strony html lub bezpośrednio do pliku z regulaminem."
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
              _c("div", { staticClass: "row justify-content-end" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "base-button",
                      { attrs: { btn1: "" }, on: { click: _vm.save } },
                      [_vm._v("Zapisz")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "admin-header__loading-state" }, [
        _vm._v(" Trwa wczytywanie "),
        _c("div", { staticClass: "mal15 lds-ellipsis" }, [
          _c("div"),
          _c("div"),
          _c("div"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }