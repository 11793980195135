import api from '@/api';
import {
  READ_USER_MESSAGES_UNREAD,
  READ_USER_MESSAGES,
  UPDATE_USER_MESSAGES,
} from '@/store/actions.type';
import {
  SET_USER_MESSAGES,
  SET_IS_USER_MESSAGES,
} from '@/store/mutations.type';
export default {
  state: {
    messages: null,
    unread: false,
  },
  getters: {
    messages: (state) => state.messages,
    isMessagesUnread: (state) => state.unread,
  },
  mutations: {
    [SET_USER_MESSAGES](state, payload) {
      state.messages = payload;
    },
    [SET_IS_USER_MESSAGES](state, payload) {
      state.unread = payload;
    },
  },
  actions: {
    async [READ_USER_MESSAGES_UNREAD]({commit}) {
      const data = await api.get('/user/messages/unread');

      commit(SET_IS_USER_MESSAGES, data)
    },
    async [READ_USER_MESSAGES]({commit}) {
      const data = await api.get('/user/messages');

      commit(SET_USER_MESSAGES, data)
    },
    async [UPDATE_USER_MESSAGES]() {
      await api.post('/user/messages');
    },
  }
}