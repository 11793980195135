<template>
    <div
        class="input-field input-field--focus-bolder input-field--file"
        :class="{
            'input-field--error': error,
            'input-field--drag-enter': isDragEnter,
        }"
        @dragover.prevent
        @drop.prevent="dropFileHandler($event), (isDragEnter = !isDragEnter)"
        @dragenter="isDragEnter = !isDragEnter"
        @dragleave="isDragEnter = !isDragEnter"
        @dragend="isDragEnter = false"
    >
        <div
            class="row patb32 w100 justify-content-center"
            @drop.prevent
        >
            <p
                v-if="value[0] && value[0].name"
                class="col-12 align-items-center justify-content-center transition-all"
            >
                {{ saySelectedFiles }}
            </p>
            <p
                v-else
                class="col-12 align-items-center justify-content-center transition-all"
            >
                {{ say.drop_files_here }} {{ say.or }}
            </p>

            <input
                ref="file"
                class="input-field__input-file"
                type="file"
                multiple
                @change="inputFileHandler($event)"
            >

            <base-button
                v-if="!isDragEnter"
                btn2
                class="mat20 text-upper"
                @drop.prevent
                @click="$refs.file.click(), updateError()"
            >
                {{ say.select_from_disk }}
            </base-button>
            <i
                v-else
                class="input-field__icon-upload fas fa-sign-in-alt"
                @drop.prevent
            />
            <span class="input-error input-error--show">{{ say[error] }}</span>
        </div>
        <div class="row">
            <div class="col-12">
                <div
                    v-for="item of value"
                    :key="item.name"
                    class="input-field__file"
                    :class="{ 'input-field__file--border-dashed': isDragEnter }"
                >
                    <div>
                        {{ item.name }}
                    </div>
                    <i
                        class="input-field__file-delete fas fa-times"
                        @click="deleteFile(item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';
import inputFiles from '@/mixins/input-files';

export default {
    mixins: [input, inputFiles],
    props: {
        fileCount: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            isDragEnter: false,
        };
    },
    computed: {
        ...mapGetters(['say']),
        saySelectedFiles() {
            if (this.fileCount !== null) {
                return this.say.selected_n_files_tpl.replace(
                    '{{ n }}',
                    this.fileCount,
                );
            }
            return this.say.selected_n_files_tpl.replace(
                '{{ n }}',
                this.value.length,
            );
        },
    },
};
</script>
