<template>
<div class="input-checkbox" :class="[`is-${this.$props.variant}`]">
  <label>
    <input
      v-model="innerValue"
      :name="name"
      :value="checkboxValue"
      type="checkbox"
      @change="onChange"
    />
    <span><slot></slot></span>
  </label>
  <span
    class="panel-input-error input-error"
    :class="{'input-error--show' : error}"
  >{{ say[error] }}</span>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PanelInputCheckbox',
  props: {
    value: {
      type: null,
      default: '',
    },
    checkboxValue: {
      type: null,
      default: '',
    },
    name: {
      type: null,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      innerValue: '',
    }
  },
  computed: {
    ...mapGetters(['say']),
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value)
    },
  },       
}
</script>

<style scoped>
.input-checkbox {
  position: relative;
}
 .input-checkbox input[type='checkbox']:not(:checked), .input-checkbox input[type='checkbox']:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
 .input-checkbox input[type='checkbox'] + span {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #455A64;
}

 .input-checkbox input[type='checkbox'] + span:before, .input-checkbox input[type='checkbox'] + span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  z-index: 0;
	border: 2px solid #4A4A4A;
  border-radius: 1px;
  transition: 0.2s;
}

 .input-checkbox input[type='checkbox']:checked + span:before {
  top: 2px;
  left: 1px;
  width: 6px;
  height: 10px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #50AE55;
  border-bottom: 2px solid #50AE55;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
}
.input-checkbox input[type='checkbox']:checked + span:after {
  border-color: #50AE55; 
}

.input-checkbox.is-white input[type='checkbox'] + span { color: #fff; } 
.input-checkbox.is-white input[type='checkbox'] + span:before, .input-checkbox.is-white input[type='checkbox'] + span:after { border-color: #fff; }
.input-checkbox.is-white input[type='checkbox']:checked + span:before {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #50AE55;
  border-bottom: 2px solid #50AE55;
}
.input-checkbox.is-white input[type='checkbox']:checked + span:after {
  border-color: #50AE55; 
}
</style>