import api from '@/api';
import schema from '@/api/schema';
import {
    UPDATE_CHECKPOINT,
    UPDATE_CLASSIFICATION,
    UPDATE_COMPETITION,
    UPDATE_GATE,
    UPDATE_MEASURE,
    UPDATE_USER,
    UPDATE_USER_CHILD,
    UPDATE_LOCATION,
    UPDATE_TRACKPOINT,
    UPDATE_MEASURE_WITHOUT_ID,
    UPDATE_PLAYER_MEASURES,
    INVALIDATED_PLAYER_MEASURES,
    UPDATE_PLAYERS,
    UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS,
    UPDATE_PANEL_PLAYER,
} from '@/store/actions.type';
import { SET_ENTITIES } from '@/store/mutations.type';
import { normalize } from 'normalizr';

export default {
    async [UPDATE_USER]({ commit }, user) {
        await api.put('/user', user);

        const normalized = normalize(user, schema.user);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_USER_CHILD]({ commit }, child) {
        await api.put(`/user/children/${child.id}`, child);

        const normalized = normalize(child, schema.user);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_CHECKPOINT]({ commit }, checkpoint) {
        await api.put(`/checkpoints/${checkpoint.id}`, checkpoint);

        const normalized = normalize(checkpoint, schema.checkpoint);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_MEASURE](context, measure) {
        if (measure.id) {
            const normalized = normalize(measure, schema.measure);
            context.commit(SET_ENTITIES, normalized.entities);
        } else {
            context.dispatch(UPDATE_MEASURE_WITHOUT_ID, measure);
        }
        try {
            if (measure.id) {
                await api.put(`/measures/${measure.id}`, measure);
            } else {
                const token = measure.deviceTime.format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
                await api.put(`measures/${token}`, measure);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('network error');
        }
    },
    async [UPDATE_GATE]({ commit }, gate) {
        await api.put(`/gates/${gate.id}`, gate);

        const normalized = normalize(gate, schema.gate);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_CLASSIFICATION]({ commit }, classification) {
        try {
            await api.put(`/classifications/${classification.id}`, classification);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        const normalized = normalize(classification, schema.classification);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_COMPETITION]({ commit }, competition) {
        await api.put(`/panel/competitions/${competition.id}`, competition);

        const normalized = normalize(competition, schema.competition);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_LOCATION]({ commit }, location) {
        await api.put(`/locations/${location.id}`, location);

        const normalized = normalize(location, schema.location);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_TRACKPOINT]({ commit }, trackpoint) {
        await api.put(`/trackpoints/${trackpoint.id}`, trackpoint);

        const normalized = normalize(trackpoint, schema.trackpoint);

        commit(SET_ENTITIES, normalized.entities);
    },
    async [UPDATE_PLAYER_MEASURES](context, payload) {
        const data = await api.put(`/players/${payload.id}/measures`, payload.measure);
        return data
   },
    async [INVALIDATED_PLAYER_MEASURES](context, payload) {
        const data = await api.put(`/measures/${payload.id}`, { invalidated: payload.invalidated });
        return data
   },
    async [UPDATE_PLAYERS](context, payload) {
        const data = await api.put(`/panel/players/${payload.id}`, payload);
        return data
   },
   async [UPDATE_PANEL_COMPETITION_PLAYERS_INPUTS](context, payload) {
       const data = await api.put(`/panel/inputs/${payload.id}`, payload);
       return data
    },
};
