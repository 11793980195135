// eslint-disable-next-line
export function createPoints(trackpoints) {
    const points = {};

    for (const trackpoint of trackpoints) {
        const point = points[trackpoint.location] ?? {
            location: trackpoint.location,
            trackpoints: {},
        };

        point.trackpoints[trackpoint.type] = trackpoint.id;

        points[trackpoint.location] = point;
    }

    return points;
}
