var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframe-wrap" },
    _vm._l(_vm.resultsLiveLink, function (link, index) {
      return _c("iframe", {
        key: index,
        attrs: { src: link.url, frameborder: "0" },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }