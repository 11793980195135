import gate from '@/api/schema/gate';
import moment from 'moment';
import { schema } from 'normalizr';

export default new schema.Entity('measures', {
    gate,
}, {
    processStrategy: (measure) => ({
        // Meta.
        _type: 'measure',
        // Fields.
        ...measure,
        // Cast types.
        deviceTime: moment(measure.deviceTime),
    }),
});
