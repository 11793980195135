<template>
    <base-form-section>
        <strong class="form__title">{{ say.family_member_data }}</strong>

        <!-- Form profile. -->
        <user-form-profile
            v-model="form"
            :validation.sync="errors"
        />

        <!-- <strong class="form__title">{{ say.insurance_data }}</strong> -->

        <!-- Form insurance. -->
        <user-form-insurance
            v-model="form"
            :validation.sync="errors"
        />
    </base-form-section>
</template>

<script>
import { mapGetters } from 'vuex';
import form from '@/mixins/form';
import UserFormProfile from '@/components/UserFormProfile';
import UserFormInsurance from '@/components/UserFormInsurance';

export default {
    components: {
        UserFormProfile,
        UserFormInsurance,
    },
    mixins: [form],
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.form__title {
    display: block;
    margin: 0 0 -8px 16px;
}
</style>
