var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.weather !== null && Object.keys(_vm.weather).length > 1
    ? _c("div", { staticClass: "widget__content-line" }, [
        _c(
          "p",
          {
            staticClass: "widget__content-label widget__content-label--wheater",
          },
          [_vm._v(" Prognoza ")]
        ),
        _c(
          "div",
          { staticClass: "widget__content-value widget__content-value--img" },
          [
            _c("img", {
              attrs: {
                src: require(`@/assets/images/icons/weather/${_vm.weather.weatherIcon}.png`),
              },
            }),
          ]
        ),
        _c(
          "p",
          { staticClass: "widget__content-value widget__content-value--temp" },
          [_vm._v(" " + _vm._s(_vm.weather.realTemperatureDay) + "°C ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }