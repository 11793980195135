import { render, staticRenderFns } from "./BasePhotoSport.vue?vue&type=template&id=74eea6c3&scoped=true&"
import script from "./BasePhotoSport.vue?vue&type=script&lang=js&"
export * from "./BasePhotoSport.vue?vue&type=script&lang=js&"
import style0 from "./BasePhotoSport.vue?vue&type=style&index=0&id=74eea6c3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74eea6c3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/dostartu.pl/app.startzone.online/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74eea6c3')) {
      api.createRecord('74eea6c3', component.options)
    } else {
      api.reload('74eea6c3', component.options)
    }
    module.hot.accept("./BasePhotoSport.vue?vue&type=template&id=74eea6c3&scoped=true&", function () {
      api.rerender('74eea6c3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BasePhotoSport.vue"
export default component.exports