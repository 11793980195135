<template>
    <div>
        <user-header />

        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="user-profile">
                        <user-sidebar />
                    </div>
                </div>

                <div class="col-12 col-md-8 col-lg-9 user-content">
                    <user-widget v-if="isUserWidget && competitionsIds.length > 0 && competition.isRemote === false" class="user-content__widget">
                        <template
                            v-slot:label
                        >
                            Twoje najbliższe zawody
                        </template>
                        <template v-slot:content>
                            <user-widget-competition :id="competition.id" />
                        </template>
                    </user-widget>
                    <user-navigation />
                    <router-view />
                </div>
            </div>
        </div>
        <user-mobile-nav />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { READ_USER_COMPETITIONS } from '@/store/actions.type';
import UserHeader from '@/components/UserHeader';
import UserSidebar from '@/components/UserSidebar';
import UserNavigation from '@/components/UserNavigation';
import UserMobileNav from '@/components/UserMobileNav';
import UserWidget from '@/components/UserWidget';
import UserWidgetCompetition from '@/components/UserCompetitionWidget';

export default {
    data() {
        return {
            competitionsIds: [],
        };
    },    
    components: {
        UserHeader,
        UserSidebar,
        UserNavigation,
        UserMobileNav,
        UserWidget,
        UserWidgetCompetition,
    },
    computed: {
        ...mapGetters([
            'competitions',
        ]),        
        isUserWidget() {
            return ['user-competitions-future', 'user-competitions-proposed', 'user-competitions-past'].includes(this.$route.name);
        },
        competition() {
            return this.competitions[this.competitionsIds[0]];
        },         
    },
    async created() {
        const { competitions } = await this.READ_USER_COMPETITIONS({
            type: 'future',
        });

        this.competitionsIds = competitions;
    },
    methods: {
        ...mapActions([READ_USER_COMPETITIONS]),
    },    
};
</script>

<style scoped>
.user-profile {
    padding: 32px 10px 30px 15px;
    display: none;
}
.user-content {
    padding-top: 16px;
}
.user-content__widget {
    margin-bottom: 32px;
}
@media (min-width: 768px) {
    .user-profile {
        display: block;
    }
    .user-content {
        overflow: visible;
        padding-top: 32px;
    }
}
</style>
