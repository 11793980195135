<template>
  <main ref="minisite-top" class="container">
    <div class="row justify-content-start">
      <!-- Miniside sidebar. -->
      <minisite-sidebar v-if="ready" :id="id" />
      <div v-if="!ready" class="col-12 col-md-3 mat33 pat15">
        <div class="minisite-sidebar--skeleton" />
      </div>
      <!-- Minisite landing. -->
      <div class="col-12 col-md-8 col-lg-9 mat48">
        <!-- Missing translation. -->
        <div v-if="ready && !competitionSetting[`${lang}On`]" class="row mab32">
          <div class="col-12">
            <div class="nolang">
              <p>
                {{ say.translation_not_provided_by_organizer }}
              </p>
            </div>
          </div>
        </div>

        <!-- Remote competition. -->
        <div
          v-if="ready && competition.isRemote && competition.id !== 4762"
          class="row minisite-section"
        >
          <div class="col-12">
            <div class="bg--green remote-competition">
              <div class="row justify-content-space-between">
                <div class="col-12 col-lg-5 d-flex justify-content-center">
                  <img
                    class="remote-competition__image"
                    src="@/assets/images/illustration-remote.svg"
                  />
                </div>
                <div class="col-12 col-lg-7 remote-competition__description">
                  <div class="remote-competition__description-header">
                    {{ say.remote_competition }}
                  </div>
                  <div>
                    {{ say.remote_competition_information }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->

        <!-- Competition summary. -->
        <div
          v-if="!ready"
          class="row minisite-section minisite-section--without-padding"
        >
          <div class="col-12">
            <!-- First row. -->
            <div class="row competition__info">
              <!-- Competition status. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl pair-lbl--no-margin pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- To start counter. -->

              <div class="col-12 col-lg-4">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- Players limits. -->
              <div class="col-12 col-lg-3">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>
            </div>

            <!-- Second row. -->
            <div class="row competition__info">
              <!-- Competition status. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl pair-lbl--no-margin pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- To start counter. -->

              <div class="col-12 col-lg-4">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- Players limits. -->
              <div class="col-12 col-lg-3">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>
            </div>

            <!-- Third row. -->
            <div class="row competition__info">
              <!-- Competition status. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl pair-lbl--no-margin pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- To start counter. -->

              <div class="col-12 col-lg-4">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>

              <!-- Players limits. -->
              <div class="col-12 col-lg-3">
                <p class="pair-lbl pair-lbl--skeleton" />
                <p class="pair-inf pair-inf--skeleton" />
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->

        <!-- Competition summary. -->
        <div
          v-else
          class="row minisite-section minisite-section--without-padding"
        >
          <div class="col-12">
            <!-- First row. -->
            <div
              v-if="competition.formType === 'registration'"
              class="row competition__info"
            >
              <!-- Competition status. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl pair-lbl--no-margin">
                  {{ say.competition_status }}
                </p>
                <p
                  v-if="
                    competition.status === 2 && limits.playersAvailable === 0
                  "
                  class="pair-inf tc--negative"
                >
                  {{ say.statuses_3 }}
                </p>
                <p
                  v-else
                  class="pair-inf"
                  :class="{
                    'tc--positive': [2, 6].includes(competition.status),
                    'tc--negative': ![2, 6].includes(competition.status),
                  }"
                >
                  {{ competition.status | asStatus }}
                </p>
              </div>

              <!-- To start counter. -->
              <template v-if="competition.preStartedTime">
                <div class="col-12 col-lg-4">
                  <p class="pair-lbl">
                    {{ say.duration_of_competition }}
                  </p>
                  <p class="pair-inf">
                    {{ competition.preStartedTime | asDate }}
                    -
                    <span class="pair-inf-countdown">{{
                      competition.startedTime | asDate
                    }}</span>
                  </p>
                </div>
              </template>
              <template v-else-if="competition.status === 3">
                <div class="col-12 col-lg-4">
                  <p class="pair-lbl">
                    {{ say.registration_end_in }}
                  </p>
                  <p class="pair-inf pair-inf-countdown">0d 0g 0m</p>
                </div>
              </template>
              <template v-else>
                <div class="col-12 col-lg-4">
                  <p class="pair-lbl">
                    {{ say.registration_end_in }}
                  </p>
                  <p class="pair-inf pair-inf-countdown">
                    {{ competition.provisionTime | asCounter }}
                  </p>
                </div>
              </template>

              <!-- Players limits. -->
              <div class="col-12 col-lg-3">
                <template
                  v-if="
                    (competition.status === 3 && limits.playersLimit > 0) ||
                    (competition.status === 2 && limits.playersAvailable === 0)
                  "
                >
                  <p class="pair-lbl">
                    {{ say.available }}
                  </p>
                  <p
                    v-if="limits.playersAvailable === 0"
                    class="pair-inf tc--negative"
                  >
                    {{ say.age_limit_none }}
                  </p>
                  <p v-else class="pair-inf tc--negative">
                    {{ say.time_is_up }}
                  </p>
                </template>
                <template v-else>
                  <template
                    v-if="
                      canRegister(competition, limits) &&
                      limits.playersAvailable !== true
                    "
                  >
                    <p class="pair-lbl">
                      {{ say.available }}
                    </p>
                    <p class="pair-inf">
                      <span class="pair-inf-countdown">{{
                        limits.playersAvailable
                      }}</span>
                      <span>/{{ limits.playersLimit }}</span>
                    </p>
                  </template>
                  <template v-else>
                    <p class="pair-lbl">
                      {{ say.number_of_participants }}
                    </p>
                    <p class="pair-inf">
                      {{ limits.playersUnavailable }}
                    </p>
                  </template>
                </template>
              </div>
            </div>

            <!-- Second row. -->
            <div class="row competition__info">
              <!-- Location. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl">
                  {{ say.location }}
                </p>
                <p v-if="competition.location" class="pair-inf pair-inf--wrap">
                  {{ competition.location }}
                </p>
                <p v-else class="pair-inf pair-inf--wrap">
                  {{ say.whole_world }}
                </p>
              </div>
              <!-- Website. -->
              <div class="col-12 col-lg-4">
                <template v-if="competition.websitePl">
                  <p class="pair-lbl">
                    {{ say.competition_website }}
                  </p>
                  <p
                    class="pair-inf cpointer"
                    :href="competition.websitePl"
                    @click="
                      $router.push({
                        path: competition.websitePl,
                        tab: true,
                      })
                    "
                  >
                    {{ competition.websitePl }}
                  </p>
                </template>
              </div>

              <!-- Started time. -->
              <div class="col-12 col-lg-3">
                <template v-if="!competition.preStartedTime">
                  <p class="pair-lbl">
                    {{ say.start }}
                  </p>
                  <p v-if="competition.startedTime" class="pair-inf">
                    {{ competition.startedTime | asTime }}
                  </p>
                </template>
              </div>
            </div>

            <!-- Third row. -->
            <div class="row competition__info">
              <!-- Organizer name. -->
              <div class="col-12 col-lg-5">
                <p class="pair-lbl">
                  {{ say.organizer }}
                </p>
                <p class="pair-inf" style="cursor: pointer;" @click="
                      $router.push({
                          name: 'main-site-organizer',
                          params: organizer,
                          alias: true,
                      })">
                  {{ organizer.name }}
                </p>
              </div>

              <!-- Organizer email. -->
              <div class="col-12 col-lg-4">
                <p class="pair-lbl">
                  {{ say.organizer_email }}
                </p>
                <p
                  class="pair-inf pair-inf--wrap pointer"
                  @click="
                    $router.push({
                      name: 'minisite-contact',
                      params: competition,
                      alias: true,
                    })
                  "
                >
                  {{ competition.contactEmail }}
                </p>
              </div>

              <!-- Organizer phone -->
              <div class="col-12 col-lg-3">
                <p class="pair-lbl">
                  {{ say.organizer_phone }}
                </p>
                <p class="pair-inf">
                  {{ competition.contactPhone }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Remarks. -->
        <div v-if="ready && competition.remarksPl" class="row minisite-section">
          <div class="col-12">
            <div class="boxremarks">
              <h4>{{ say.attention | asUpperCase }}</h4>
              <div
                v-html="
                  sanitizeHtml(
                    $options.filters.asLink(
                      $options.filters.asLang(competition, 'remarks', lang)
                    )
                  )
                "
              />
            </div>
          </div>
        </div>

        <!-- Classifications list. -->
        <div class="row minisite-section">
          <div class="col-12">
            <h4 class="mab16">
              {{ say.classifications }}
            </h4>
            <template v-if="!ready">
              <base-item-classification
                v-for="i of 1"
                :id="0"
                :key="i"
                :competition-id="id"
                is-light
              />
            </template>
            <template v-else>
              <base-item-classification
                v-for="classification of classifications"
                :id="classification.id"
                :key="classification.id"
                :competition-id="id"
                is-light
              />
            </template>
          </div>
        </div>

        <!-- Description. -->
        <div
          v-if="ready && competition.descriptionPl"
          class="row minisite-section"
        >
          <div
            class="col-12 pre-line"
            v-html="
              sanitizeHtml(
                $options.filters.asLink(
                  $options.filters.asLang(competition, 'description', lang)
                )
              )
            "
          />
        </div>

        <!-- Map. -->
        <div v-if="ready && isGpxTrack" class="row minisite-section">
          <div v-if="isReadyMap" class="col-12 mab16">
            <h4>{{ say.map }}</h4>
          </div>
          <div v-if="isReadyMap" class="col-12">
            <div class="row minisite-section__nav">
              <div
                v-for="classification in classifications"
                :key="classification.id"
                class="minisite-section__nav-btn"
                :class="{
                  'minisite-section__nav-btn--active':
                    selectedClassification.id === classification.id,
                }"
                @click="selectedClassification = classification"
              >
                {{
                  classification[
                    `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                  ]
                }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <map-competition
              disable-scroll
              :competition="competition"
              :classifications="classifications"
              :selected-classification.sync="selectedClassification"
              read-only
              :fullscreenable="false"
              @map:ready="(isGpxTrack = $event), (isReadyMap = $event)"
            >
              <template #optional-button>
                <l-control position="bottomright">
                  <base-button-download
                    v-if="gpxFiles[selectedClassification.id]"
                    class="minisite-section__btn-download"
                    :disabled="gpxFiles[selectedClassification.id].url === null"
                    :url="gpxFiles[selectedClassification.id].url"
                    :file-name="selectedClassification.namePl + '.gpx'"
                  >
                    {{ say.download_gpx_track }}
                  </base-button-download>
                </l-control>
              </template>
            </map-competition>
          </div>
        </div>
        <div
          v-else-if="
            ready &&
            competition.locationLat &&
            competition.locationLng &&
            baseLocationPoint
          "
          class="row minisite-section"
        >
          <div class="col-12">
            <h4 class="mab16">
              {{ say.location }}
            </h4>
            <div class="minisite-section__map">
              <base-map
                disable-scroll
                :lat="competition.locationLat"
                :lng="competition.locationLng"
                :fullscreenable="false"
                :zoom="12"
              >
                <!-- <map-competition-point
                  read-only
                  :point="baseLocationPoint"
                  :competition-id="id"
                  :selected-classification-id="selectedClassification.id"
                /> -->
                <map-competition-point
                    v-for="point in points"
                    :key="point.id"
                    read-only
                    :point="point"
                    :competition-id="id"
                    :selected-classification-id="selectedClassification.id"
                />                
              </base-map>
            </div>
          </div>
        </div>

        <!-- Next in cycle. -->
        <div v-if="ready && nextInCycleIds.length" class="row minisite-section">
          <div class="col-12">
            <h4 class="mab0">
              {{ say.other_cycle_competitions }}
            </h4>

            <div class="row cycles pab25">
              <base-item-competition-small
                v-for="cmp of nextInCycle"
                :id="cmp.id"
                :key="cmp.id"
              />
            </div>
          </div>
        </div>

        <!-- Facebook comments. -->
        <div v-if="ready" class="row">
          <div class="col-12">
            <facebook-comments
              :settings="{
                href: competition.permaLink,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import MinisiteSidebar from "@/components/MinisiteSidebar";
import actions from "@/mixins/actions";
import {
  READ_NEXT_IN_CYCLE_COMPETITIONS,
  READ_COMPETITION_CLASSIFICATIONS,
  READ_COMPETITION_ORGANIZER,
  BOOTSTRAP_MINISITE,
  READ_COMPETITION_LIMITS,
  READ_COMPETITION_CLASSIFICATIONS_AGES,
  READ_COMPETITION_CLASSIFICATIONS_LIMITS,
  CREATE_BASE_POINT,
  CREATE_LOCATION_POINT,
  CHANGE_LANG,
} from "@/store/actions.type";
import { pick } from "@/utils/object";
import MapCompetition from "@/components/MapCompetition";
import { LControl } from "vue2-leaflet";
import MapCompetitionPoint from "@/components/MapCompetitionPoint";
import { createPoints } from '@/service/panel-map';
import sanitizeHtml from 'sanitize-html';

export default {
  components: {
    MinisiteSidebar,
    MapCompetition,
    LControl,
    MapCompetitionPoint,
  },
  mixins: [actions],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      nextInCycleIds: [],
      selectedClassification: {},
      isGpxTrack: true,
      isReadyMap: false,
      baseLocationPoint: null,
    };
  },

  computed: {
    ...mapGetters([
      "lang",
      "say",
      "time",
      "competitions",
      "organizers",
      "limitsBy",
      "competitionsSettings",
      "classificationsBy",
      "minisiteId",
      "classificationsSettings",
      "gpxFiles",
      "trackpointsBy",
    ]),
    competition() {
      return this.competitions[this.minisiteId];
    },
    competitionSetting() {
      return this.competitionsSettings[this.id];
    },
    limits() {
      return this.limitsBy(this.competition)[0];
    },
    classifications() {
      const classifications = this.classificationsBy(this.competition);

      for (const classification of classifications) {
        classification.position =
          this.classificationsSettings[
            classification.classificationSetting
          ].position;
      }
      return _.orderBy(classifications, "position", "asc");
    },
    nextInCycle() {
      return pick(this.competitions, this.nextInCycleIds);
    },
    organizer() {
      return this.organizers[this.competition.organizer];
    },
    points() {
      return createPoints(this.trackpoints);
    },    
    trackpoints() {
      return this.trackpointsBy(this.classifications);
    },    
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.bootstrap();
      },
    },
    async isGpxTrack() {
      if (!this.isGpxTrack) {
        // Create default point with start icon.
        this.baseLocationPoint = await this.createDefaultLocationPoint(
          this.competition.locationLat,
          this.competition.locationLng
        );
      }
    },
  },
  mounted() {
    window.scroll(0, 0);
    if(this.id === 6772) this.CHANGE_LANG('en');
  },
  methods: {
    ...mapActions([
      BOOTSTRAP_MINISITE,
      READ_COMPETITION_ORGANIZER,
      READ_COMPETITION_CLASSIFICATIONS,
      READ_NEXT_IN_CYCLE_COMPETITIONS,
      READ_COMPETITION_LIMITS,
      READ_COMPETITION_CLASSIFICATIONS_LIMITS,
      READ_COMPETITION_CLASSIFICATIONS_AGES,
      CREATE_BASE_POINT,
      CREATE_LOCATION_POINT,
      CHANGE_LANG,
    ]),
    async bootstrap() {
      this.ready = false;

      const [{ competitions: nextInCycleIds }] = await Promise.all([
        this.READ_NEXT_IN_CYCLE_COMPETITIONS(this.id),
        this.READ_COMPETITION_ORGANIZER(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS(this.id),
        this.BOOTSTRAP_MINISITE(this.id),
        this.READ_COMPETITION_LIMITS(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS_AGES(this.id),
        this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
      ]);
      this.nextInCycleIds = nextInCycleIds;

      const [firstClassification] = this.classifications;
      this.selectedClassification = firstClassification;
      this.ready = true;
    },
    async createDefaultLocationPoint(lat, lng) {
      const location = await this.CREATE_LOCATION_POINT({ lat, lng });
      const classificationsIds = this.classifications.map((cls) => cls.id);
      const point = await this.CREATE_BASE_POINT({
        classifications: classificationsIds,
        type: "STA",
        locationId: location.id,
      });
      return point;
    },
    sanitizeHtml: sanitizeHtml,
  },
};
</script>
<style scoped>
.pre-line {
  white-space: pre-line;
}
.cycles {
    justify-content: flex-start;
}
.minisite-sidebar--skeleton {
    width: 100%;
    height: 250px;
    background-color: var(--color-pale-grey);
    border-radius: 8px;
}
.nolang {
    padding: 16px;
    border-radius: 4px;
    background-color: rgba(84, 110, 122, 0.1);
    font-size: 16px;
    font-weight: 600;
    color: #4a4a4a;
    font-family: 'Open Sans';
}

.nolang p {
    margin: 0;
}
.minisite-section {
    margin-bottom: 48px;
}

.minisite-section__map {
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}
.minisite-section__map-select {
    margin-top: 0;
}

.minisite-section__btn-download {
    margin-top: 16px;
    width: 100%;
}

.minisite-section__btn-download:hover {
    opacity: 1;
}

.minisite-section__nav {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-cool-grey);
    margin-left: 0px;
    margin-right: 0px;
    flex-wrap: nowrap;
    overflow: auto;
}

.minisite-section__nav-btn {
    position: relative;
    padding: 15px 0;
    cursor: pointer;
    font-weight: 600;
    margin-right: 24px;
    white-space: nowrap;
}

.minisite-section__nav-btn--active:after {
    background: linear-gradient(
        to left,
        var(--color-red-orange) 100%,
        var(--color-pinky-red) 1%
    );
    bottom: 0;
    left: 0;
    height: 2px;
    position: absolute;
    display: inline-block;
    width: 100%;
    content: '';
}
.remote-competition {
    border-radius: 4px;
    background-color: rgba(184, 233, 134, 0.4);
    padding: 16px;
}

.remote-competition__description {
    margin-top: 16px;
}

.remote-competition__description-header {
    font-size: 16px;
    font-weight: 600;
}
.minisite-section {
    margin-bottom: 48px;
}
.minisite-section__map {
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}

.pair-lbl--skeleton {
    height: 16px;
    width: 80%;
    background-color: var(--color-pale-grey);
    border-radius: 8px;
}

.pair-inf--skeleton {
    height: 16px;
    width: 50%;
    background-color: var(--color-pale-grey);
    border-radius: 8px;
    margin-top: 16px !important;
}

.text-classification--skeleton {
    height: 16px;
    width: 60%;
    background-color: var(--color-cool-grey);
    border-radius: 8px;
}
.tc--positive {
    color: #50ae55;
    font-size: 18px;
    font-weight: bold;
}

.tc--negative {
    color: #ff1744;
    font-size: 18px;
    font-weight: bold;
}
.w40i {
    width: 40% !important;
}
.text-classification--margin-mobile {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}

.minisite-sidebar--skeleton {
    width: 100%;
    height: 250px;
    background-color: var(--color-pale-grey);
    border-radius: 8px;
}

.pair-lbl,
.pair-inf {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.pair-inf--wrap {
    white-space: normal;
}

.pair-lbl {
    opacity: 0.7;
    font-size: 14px;
    color: #4a4a4a;
}

.pair-inf {
    margin: 5px 0 0 0;
    line-height: 1.5;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}
.pair-inf--wrap {
    white-space: normal;
}
.pair-inf-countdown {
    font-family: 'Bariol Bold';
    font-size: 24px;
}
.boxremarks {
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    background-color: rgba(255, 23, 68, 0.1);
}
.boxremarks h4 {
    font-size: 16px;
    font-weight: bold;
    color: #ff1744;
}
.col-12 {
    max-width: 100%;
}
.remote-competition__image {
    width: 100%;
    height: auto;
    padding-right: 15px;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .pair-lbl--skeleton {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .pair-inf--skeleton {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .nolang {
        margin-left: 15px;
    }
    .container {
        max-width: 720px;
    }
    .minisite-section {
        padding-left: 15px;
    }
    .minisite-section--without-padding {
        padding-left: 0;
    }
    .col-md-3 {
        max-width: var(--col-3);
    }
    .col-md-8 {
        max-width: var(--col-8);
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}
@media (min-width: 768px) {
    .pair-inf {
        padding-left: 15px;
        padding-right: 15px;
    }
    .pair-lbl {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 992px) {
    .pair-lbl {
        margin-top: 16px;
    }
    .pair-lbl--no-margin {
        margin-top: 0;
    }
}
@media (min-width: 992px) {
    .col-lg-5 {
        max-width: var(--col-5);
    }
    .col-lg-4 {
        max-width: var(--col-4);
    }
    .col-lg-3 {
        max-width: var(--col-3);
    }
    .minisite-section {
        margin-bottom: 32px;
    }
    .minisite-section__btn-download {
        margin-top: 0;
        width: unset;
    }
    .remote-competition {
        padding: 18px 24px 26px 48px;
    }
    .competition__info {
        margin-bottom: 24px;
    }
    .competition__info:last-of-type {
        margin-bottom: 0;
    }
}
</style>
