var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", _vm._b({}, "table", _vm.$attrs, false), [
    _c(
      "thead",
      [
        _vm._t(
          "header",
          function () {
            return [
              _c(
                "tr",
                _vm._l(_vm.headers, function (header, column) {
                  return _c(
                    "th",
                    {
                      key: column,
                      on: {
                        click: function ($event) {
                          return _vm.sort(column)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(header.text) + " ")]
                  )
                }),
                0
              ),
            ]
          },
          { headers: _vm.headers, sort: _vm.sort }
        ),
      ],
      2
    ),
    _vm.displayedItems.length
      ? _c(
          "tbody",
          [
            _vm._t(
              "body",
              function () {
                return _vm._l(_vm.displayedItems, function (item, row) {
                  return _c(
                    "tr",
                    { key: row },
                    _vm._l(_vm.headers, function (header, column) {
                      return _c("td", { key: column }, [
                        _vm._v(" " + _vm._s(item[header.value]) + " "),
                      ])
                    }),
                    0
                  )
                })
              },
              { headers: _vm.headers, displayedItems: _vm.displayedItems }
            ),
          ],
          2
        )
      : _c("tbody", [
          _c("tr", [
            _c(
              "td",
              { attrs: { colspan: _vm.headers.length } },
              [
                _vm._t(
                  "no-results",
                  function () {
                    return [_vm._v(" No results. ")]
                  },
                  { search: _vm.search }
                ),
              ],
              2
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }