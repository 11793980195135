<template>
    <div
        class="btn btn-download"
        :class="{ disabled: disabled }"
        @click="disabled ? '' : download()"
    >
        <i class="btn-download__icon fas fa-file-download" />
        <slot />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        url: {
            required: true,
            validator: (url) => typeof url === 'string' || url === null,
        },
        fileName: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        downloadFileLink(response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.fileName);
            document.body.appendChild(link);
            link.click();
            link.style.display = 'none';
        },
        download() {
            if (this.url) {
                axios({
                    method: 'get',
                    url: this.url,
                })
                    .then((response) => {
                        this.downloadFileLink(response);
                    })
                    // eslint-disable-next-line no-console
                    .catch(() => console.log('download error'));
            }
        },
    },
};
</script>

<style scoped>
.btn {
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    min-height: 48px;
    padding: 12px 15px;
    line-height: 1.1;
    background-color: #fff;
    background-image: none;
    color: #4a4a4a;
    border: 1px solid #b0bec5;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
}
.btn-download {
    box-shadow: 0 8px 12px 0 rgba(55, 71, 79, 0.1),
        0 0 1px 0 rgba(55, 71, 79, 0.3);
    color: var(--color-greyish-brown);
    line-height: 1.71;
    border: none;
    font-size: 14px;
}
.btn-download__icon {
    margin-right: 8px;
    font-size: 18px;
}
.disabled {
    opacity: 0.5 !important;
}
.disabled:hover {
    opacity: 0.5 !important;
    cursor: default;
}
</style>
