<template>
    <div>
        <!-- Header loading. -->
        <header
            v-if="!competition"
            class="minisite--loading"
        />

        <!-- Header loaded. -->
        <header
            v-else
            class="minisite"
        >
            <base-photo-sport
                :type="competition.type"
                :colour="layout.colourDefault"
            >
                <!-- Header without poster. -->
                <div
                    v-if="
                        $route.name !== 'minisite-landing' &&
                            $route.name !== 'minisite-contact'
                    "
                    class="minisite-header"
                >
                    <div class="container">
                        <div class="row space-between">
                            <div class="col-12 col-md-6">
                                <h1>{{ competition.name }}</h1>
                                <p>{{ competition.location }}</p>
                                <p>{{ competition.startedTime | asDate }}</p>
                            </div>
                            <div v-if="$route.name === 'minisite-list'" class="col-12 col-md-3">
                                <div class="players-info">
                                    <div>
                                        <span class="big">{{ registeredPlayers }}</span>
                                        <span class="small">{{ say.zarejestrowanych }}<br />{{ say.uczestników }}</span>
                                    </div>
                                    <div v-if="!competitionSetting.showOnlyPayed">
                                        <span class="big">{{ paidPlayers }}</span>
                                        <span class="small">{{ say.opłaconych }}<br />{{ say.uczestników }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else
                                class="col-12 col-md-3 minisite-header__back-button"
                            >
                                <base-button
                                    btn7
                                    class="text-upper hover-dark border-none"
                                    @click="
                                        $router.push({
                                            name: 'minisite-landing',
                                            params: competition,
                                            alias: true,
                                        })
                                    "
                                >
                                    {{ say.back_to_competition }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Header with poster. -->
                <div
                    v-else
                    class="minisite-header"
                >
                    <div class="container">
                        <div
                            class="row"
                            :class="[
                                layout.posterLink &&
                                    competition.id !== 4762 &&
                                    competition.id != 4784
                                    ? 'title--with-poster'
                                    : '',
                            ]"
                        >
                            <div
                                v-if="
                                    layout.posterLink &&
                                        competition.id !== 4762 &&
                                        competition.id != 4784
                                "
                                class="col-4 col-md-3 d-flex"
                            >
                                <img
                                    class="image-poster"
                                    @click="index = 0"
                                    :src="layout.posterLink"
                                >
                            </div>
                            <div
                                class="col-md-9 minisite-title"
                                :class="[
                                    layout.posterLink &&
                                        competition.id !== 4762 &&
                                        competition.id != 4784
                                        ? 'col-8'
                                        : 'col-12 title--without-poster',
                                ]"
                            >
                                <p>
                                    {{ competition.type | asType }}
                                    <span
                                        v-if="competition.cycle"
                                    >{{ say.in_cycle }}
                                        {{ competition.cycle.namePl }}</span>
                                </p>
                                <h2>{{ competition.name }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </base-photo-sport>
        </header>
        <CoolLightBox 
            :items="posters" 
            :index="index"
            @close="index = null">
        </CoolLightBox>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CoolLightBox from 'vue-cool-lightbox'

export default {
    components: {
        CoolLightBox,
    },   
    data: function () {
        return {
            index: null
        };
    },    
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            'layoutsBy',
            'minisiteId',
            'registeredPlayers',
            'paidPlayers',
            'competitionsSettings',
        ]),
        competition() {
            return this.competitions[this.minisiteId];
        },
        layout() {
            return this.layoutsBy(this.competition)[0];
        },
        posters() {
            const arr = []
            if(this.layout && this.layout.posterLink) {
                arr.push(this.layout.posterLink)
            }      
            return arr      
        },
        competitionSetting() {
            return this.competitionsSettings[this.minisiteId];
        },        
    },
};
</script>
<style scoped>
.minisite--loading {
    height: 260px;
    background-color: #edf0f1;
}

.minisite .minisite-title {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.minisite h2 {
    color: #fff;
    font-size: 64px;
    line-height: 1.13 !important;
    margin: 0;
}

.minisite p {
    color: #fff;
    font-size: 32px;
    line-height: 1.25;
    margin: 0;
    font-family: 'Bariol Bold';
}

.minisite-header {
    color: white;
    padding: 16px 0;
}

.minisite-header h1 {
    font-size: 24px;
    line-height: 1.5;
}

.minisite-header p {
    font-size: 14px !important;
    font-family: 'Open Sans' !important;
}

.minisite-header__back-button {
    border-radius: 4px;
    margin-top: 16px;
    white-space: nowrap;
    max-width: unset;
    background-color: white;
}
.title--with-poster {
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: nowrap;
}
.image-poster {
    width: 100%;
    height: 100%;
}

.title--without-poster {
    padding-top: 34px;
    padding-bottom: 37px;
    padding-left: 15px !important;
}

.players-info,
.players-info > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.players-info {
    height: 100%;
}

.players-info > div + div {
    margin-left: 32px;
}

.players-info .big {
    font-family: 'Bariol Bold';
    font-weight: normal;
    font-size: 48px;
}
.players-info .small {
    margin-left: 12px;
    font-size: 14px;
}

@media (max-width: 445px) {
    .minisite h2 {
        font-size: 24px;
    }
    .minisite p {
        font-size: 16px;
    }
    .players-info .big {
        font-size: 24px;
    }
    .players-info .small {
        font-size: 12px;
    }
}

@media (min-width: 446px) {
    .minisite h2 {
        font-size: 36px;
    }
    .minisite p {
        font-size: 18px;
    }
}

@media (max-width: 575px) {
    .minisite p {
        margin-bottom: 4px;
    }
}

@media (max-width: 767px) {
    .players-info {
        justify-content: flex-start;
    }
}
@media (min-width: 768px) {
    .minisite h2 {
        font-size: 40px;
    }
    header.minisite p {
        font-size: 20px;
    }
    .minisite-header__back-button {
        margin: auto 0;
    }
    .title--without-poster {
        padding: 48px 0 48px 15px;
    }
}

@media (min-width: 992px) {
    .minisite h2 {
        font-size: 64px;
    }
    .minisite p {
        font-size: 32px;
    }
    .minisite-header h1 {
        font-size: 32px;
    }
}
</style>
