<template>
    <div class="row patb48 user-edit__box-buttons">
        <!-- Delete button. -->
        <div
            v-if="$router.matched('user-edit', $route)"
            class="col-lg-3 col-md-4 col-12"
        >
            <base-button
                class="user-edit__button user-edit__button-delete"
                btn6
                @click="erase()"
            >
                {{ say.delete_your_account }}
            </base-button>
        </div>

        <!-- Cancel button. -->
        <div
            v-else
            class="col-lg-3 col-md-4 col-12"
        >
            <base-button
                btn3
                class="user-edit__button user-edit__button-cancel"
                @click="cancel()"
            >
                {{ say.cancel }}
            </base-button>
        </div>

        <!-- Save button. -->
        <div class="col-lg-3 col-md-4 col-12">
            <base-button
                class="user-edit__button-save"
                btn1
                @click="save()"
            >
                {{ say.save }}
            </base-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DELETE_USER } from '@/store/actions.type';

export default {
    computed: {
        ...mapGetters(['say']),
    },
    methods: {
        ...mapActions([DELETE_USER]),
        async erase() {
            await this.DELETE_USER();

            this.$router.push({ name: 'main-landing', alias: true });
        },
        cancel() {
            this.$router.go(-1);
        },
        save() {
            this.$emit('save');
        },
    },
};
</script>

<style scoped>
.user-edit__button {
    border: 1px solid #f00;
    box-shadow: none;
}
.user-edit__box-buttons {
    flex-direction: column-reverse;
}
.user-edit__button-delete {
    margin-top: 10px;
}
.user-edit__button-cancel {
    margin-top: 16px;
}
@media (min-width: 992px) {
    .user-edit__box-buttons {
        flex-direction: row;
    }
    .user-edit__button-delete {
        margin-top: 0;
    }
    .user-edit__button-cancel {
        margin-top: 0;
    }
}
</style>
