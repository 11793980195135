<template>
  <div> 
    <div class="rangeSlider" style="background-color: var(--vt-c-white);max-width: 956px">
      <div class="rangeSlider__track" ref="sliderTrack">
        <RangeSliderItem v-for="(item, index) in categories" :key="keys[index]" :data="item" :index="index" @active="activeHandle" @disabled="disableHandle" />
      </div>
      <div class="rangeSlider__handles" ref="sliderHandles">
        <RangeSliderHandle v-for="(item, index) in categories" :key="keys[index]" :data="item" :index="index" :categories="categories" @remove="removeHandle" :length="categories.length - 1" :gap="trackGap" :max-width="trackWidth" @update="updateData" @update:end="dragEnd" @update:handleend="dragHandleEnd" />
      </div>
      <RangeSliderNewHandle :gap="trackGap" @new="newRange" />
    </div>
    <!-- <div v-for="cat in categories">
      {{ cat }}
    </div> -->
  </div>
</template>

<script>
  import RangeSliderItem from '@/components/ageCategoriesRangeSlider/RangeSliderItem.vue';
  import RangeSliderHandle from '@/components/ageCategoriesRangeSlider/RangeSliderHandle.vue';
  import RangeSliderNewHandle from '@/components/ageCategoriesRangeSlider/RangeSliderNewHandle.vue';
  import { v4 as uuidv4 } from 'uuid';

  export default {
    name: 'ageCategoriesRangeSlider',
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      RangeSliderItem,
      RangeSliderHandle,
      RangeSliderNewHandle,
    },
    data() {
      return {
        isMounted: false,
        categories: [],
        keys: [],
        defaultCategories: [ { age_from: 0, age_to: 19, active: false }, { age_from: 20, age_to: 29, active: true }, { age_from: 30, age_to: 39, active: true }, { age_from: 40, age_to: 49, active: true }, { age_from: 50, age_to: 59, active: true }, { age_from: 60, age_to: 99, active: true }],
        // aCat: [
        //   {"age_from":0,"age_to":5, "active":false, handle: false, },
        //   {"age_from":6,"age_to":9, "active":true, handle: true, },
        //   {"age_from":10,"age_to":18, "active":false, handle: true, },
        // ],
        aCat: [
          {"age_from":0,"age_to":15,"active":false, handle: false, },
          {"age_from":16,"age_to":30,"active":true, handle: true},
          {"age_from":31,"age_to":35,"active":false, handle: true},
          {"age_from":36,"age_to":50,"active":true, handle: true},
          {"age_from":51,"age_to":60,"active":true, handle: true},
          {"age_from":61,"age_to":80,"active":true, handle: true},
          {"age_from":81,"age_to":100,"active":false, handle: true},
        ],
        // aCat: [
        //   {"age_from":0,"age_to":5,"active":true, handle: true, "text_from": 0, "text_to": 5 },
        //   {"age_from":6,"age_to":19,"active":false, handle: true, "text_from": 6, "text_to": 19 },
        //   {"age_from":20,"age_to":29,"active":true, handle: true, "text_from": 20, "text_to": 29},
        //   {"age_from":30,"age_to":39,"active":true, handle: true, "text_from": 30, "text_to": 39},
        //   {"age_from":40,"age_to":49,"active":true, handle: true, "text_from": 40, "text_to": 49},
        //   {"age_from":50,"age_to":59,"active":true, handle: true, "text_from": 50, "text_to": 59},
        //   {"age_from":60,"age_to":99,"active":true, handle: true, "text_from": 60, "text_to": 99},

        // ],
        // aCat: [
        //   {"age_from":0,"age_to":19,"active":false, handle: false, "text_from": 0, "text_to": 19 },
        //   {"age_from":20,"age_to":29,"active":true, handle: true, "text_from": 20, "text_to": 29},
        //   {"age_from":30,"age_to":39,"active":true, handle: true, "text_from": 30, "text_to": 39},
        //   {"age_from":40,"age_to":49,"active":true, handle: true, "text_from": 40, "text_to": 49},
        //   {"age_from":50,"age_to":59,"active":true, handle: true, "text_from": 50, "text_to": 59},
        //   {"age_from":60,"age_to":99,"active":true, handle: true, "text_from": 60, "text_to": 99},

        // ],
        testCategories: [ { age_from: 16, age_to: 29 }, { age_from: 35, age_to: 49 }, { age_from: 50, age_to: 59 }, { age_from: 60, age_to: 79 }],
        ageMax: 101,
        // ageMax: 19,
        test: [],
        trackWidth: null,
        isResizing: false,
        draggableHandles: [],
        resizables: null,
        currentHandle: null,
        startPanelWidth: [],
        resizingPanels: null,
      }
    },
    computed: {
      trackGap()  {
        if (!this.isMounted) return;
        return this.trackWidth / this.ageMax
      },
    },
    mounted() {
      this.isMounted = true;
      this.trackWidth = this.$refs.sliderTrack.offsetWidth
      window.addEventListener('resize', this.resizeHandler);
      this.categories = this.$props.data.length > 0 ? this.$props.data : this.aCat
      this.init()

      this.categories.forEach((value, index) => {
        this.categories[index] = this.getItemData(value)
        this.keys[index] = uuidv4()
      })
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
      resizeHandler() {
        this.trackWidth = this.$refs.sliderTrack.offsetWidth
      },
      init() {
        const tempArr = []
        for (let i = 0; i < this.testCategories.length; i++) {
          const prev = (this.testCategories[i - 1]);
          const next = (this.testCategories[i + 1]);
          const self = (this.testCategories[i]);
          const obj = {
            'age_from': self.age_from,
            'age_to': self.age_to,
            'active': true
          }
          
          if (!prev) {
            const newObj = {};
            newObj['age_from'] = 0
            newObj['age_to'] = self.age_from - 1
            newObj['active'] = false
            tempArr.push(newObj)
          }

          tempArr.push(obj)

          if (!next) {
            const newObj = {};
            newObj['age_from'] = self.age_to + 1
            newObj['age_to'] = 99
            newObj['active'] = false
            tempArr.push(newObj)
          } else if ((next.age_from - 1) !== self.age_to) {
            const newObj = {};
            newObj['age_from'] = self.age_to + 1
            newObj['age_to'] = next.age_from - 1
            newObj['active'] = false
            tempArr.push(newObj)
          }
        }
        
        this.test = tempArr
      },
      defaultRangeSlider(array) {
        this.categories = array
      },
      getItemData(item) {
        let difference = (item.age_to) - item.age_from
        let xPos = (this.trackGap * item.age_from).toFixed(2)
        let width = ((this.trackGap * (difference + 1))).toFixed(2)

        return {
          ...item,
          'left': xPos + 'px',
          'width': width + 'px',
        }
      },
      updateData(payload) {
        const prev = (this.categories[payload.index - 1]);
        const self = (this.categories[payload.index]);


          if (prev) {
            this.$set(this.categories, payload.index - 1, {
              ...prev,
              age_to: payload.age_from - 1,
            })
          }
          this.$set(this.categories, payload.index, {
            ...self,
            age_from: payload.age_from,
          })
      },
      dragEnd(payload) {
        const prev = (this.categories[payload.index - 1]);
        const self = (this.categories[payload.index]);

          if (prev) {
            this.$set(this.categories, payload.index - 1, {
              ...prev,
              age_to: payload.age_from - 1,
              width: payload.prevWidth+'px',
              left: payload.prevLeft+'px',
            })
          }
          this.$set(this.categories, payload.index, {
            ...self,
            age_from: payload.age_from,
            width: payload.width+'px',
            left: payload.left+'px',
          })

        if (payload.index === 0) {
          this.$set(this.categories, payload.index, {
            ...this.categories[payload.index],
            age_from: payload.age_from
          })
          this.categories.unshift({ "age_from": 0, "age_to": payload.age_from - 1, "active": false, "handle": false })
          this.categories.forEach((value, index) => {
            this.categories[index] = this.getItemData(value)
            this.keys[index] = uuidv4()
          })
        }
      },
      dragHandleEnd(payload) {
        if (payload.age_to) {
            this.$set(this.categories, payload.index, {
              ...(this.categories[payload.index]),
              age_to: payload.age_to,
            })
            if (payload.age_to < this.ageMax - 1 && payload.addNewHandle) {
              this.categories.push({ "age_from": payload.age_to + 1, "age_to": this.ageMax - 1, "active": false, "handle": true })
              this.categories.forEach((value, index) => {
                this.categories[index] = this.getItemData(value)
                this.keys[index] = uuidv4()
              })
            }
          }
      },
      newRange(payload) {
        const years = Math.round(payload.x / this.trackGap)
        function numberInRange(num, first, second) {
          const max = Math.max(first, second);
          const min = Math.min(first, second);

          if (num >= min && num <= max) {
            return true;
          }

          return false;
        }
        let jackpotIndex
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        for (const [index, cat] of this.categories.entries()) {
          if (numberInRange(years, cat.age_from, cat.age_to)) jackpotIndex = index
        }
        const oldSpot = {
          age_from: this.categories[jackpotIndex].age_from,
          age_to: years - 1,
          active: true,
          handle: true,
        }
        const newSpot = {
          age_from: years,
          age_to: this.categories[jackpotIndex].age_to,
          active: true,
          handle: true,
        }
        const indexes = [jackpotIndex];
        this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
        this.categories = insert(this.categories, jackpotIndex, this.getItemData(oldSpot))
        this.categories = insert(this.categories, jackpotIndex + 1, this.getItemData(newSpot))
        this.categories.forEach((_value, index) => {
          this.keys[index] = uuidv4()
        })
      },
      activeHandle(payload) {
        this.$set(this.categories, payload.index, {
          ...this.categories[payload.index],
          active: true,
          handle: true,
        })
      },
      removeHandle(payload) {
        const prev = this.categories[payload.index - 1]
        const target = this.categories[payload.index]
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        // console.table([[prev?.active, prev?.handle], [target.active, target.handle], [next?.active, next?.handle]])
        const newSpot = {
          age_from: prev.age_from,
          age_to: target.age_to,
          active: true,
          handle: true,
        }

        const indexes = [payload.index - 1, payload.index];
        this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
        this.categories = insert(this.categories, payload.index - 1, this.getItemData(newSpot))
        this.categories.forEach((_value, index) => {
          this.keys[index] = uuidv4()
        })
      },
      disableHandle(payload) {
        const prev = this.categories[payload.index - 1]
        const target = this.categories[payload.index]
        const next = this.categories[payload.index + 1]
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ]
        // console.table([[prev?.active, prev?.handle], [target.active, target.handle], [next?.active, next?.handle]])

        if (prev && prev.active && prev.handle && next && next.active && next.handle) {
          // console.log('poprzedni aktywny z handle, nastepny aktywny z handle')
          this.$set(this.categories, payload.index, {
            ...this.categories[payload.index],
            active: false,
          })
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && !prev.active && !prev.handle && next && !next.active && next.handle) {
          // console.log('poprzedni nieaktywny bezhandle, nastepny nieaktywny z handle')
          const newSpot = {
            age_from: prev.age_from,
            age_to: next.age_to,
            active: false,
            handle: false,
          }
          const indexes = [payload.index - 1, payload.index, payload.index + 1];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));

          this.categories.unshift(this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && !prev.active && !prev.handle && next && next.active && next.handle) {
          // console.log('poprzedni nieaktywny bezhandle, nastepny aktywny z handle')
          const newSpot = {
            age_from: prev.age_from,
            age_to: target.age_to,
            active: false,
            handle: false,
          }

          const indexes = [payload.index - 1, payload.index];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));

          this.categories.unshift(this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && prev.active && prev.handle && next && !next.active && next.handle) {
          // console.log('poprzedni aktywny z handle, nastepny nieaktywny z handle')
          const newSpot = {
            age_from: target.age_from,
            age_to: next.age_to,
            active: false,
            handle: true,
          }
          const indexes = [payload.index, payload.index + 1];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
          this.categories = insert(this.categories, payload.index, this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && !prev.active && prev.handle && next && !next.active && next.handle) {
          // console.log('poprzedni nieaktywny z handle, nastepny nieaktywny z handle')
          const newSpot = {
            age_from: prev.age_from,
            age_to: next.age_to,
            active: false,
            handle: true,
          }
          const indexes = [payload.index - 1, payload.index, payload.index + 1];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
          this.categories = insert(this.categories, payload.index - 1, this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && !prev.active && prev.handle && next && next.active && next.handle) {
          // console.log('poprzedni nieaktywny z handle, nastepny aktywny z handle')
          const newSpot = {
            age_from: prev.age_from,
            age_to: target.age_to,
            active: false,
            handle: true,
          }
          const indexes = [payload.index - 1, payload.index];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
          this.categories = insert(this.categories, payload.index - 1, this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev === undefined && next && next.active && next.handle) {
          // console.log('poprzedni nieistnieje, nastepny aktywny z handle')
          this.$set(this.categories, payload.index, {
            ...this.categories[payload.index],
            active: false,
            handle: false,
          })
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev === undefined && next && !next.active && next.handle) {
          // console.log('poprzedni nieistnieje, nastepny nieaktywny z handle')
          const newSpot = {
            age_from: 0,
            age_to: next.age_to,
            active: false,
            handle: false,
          }
          const indexes = [payload.index - 1, payload.index, payload.index + 1];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
          this.categories = insert(this.categories, 0, this.getItemData(newSpot))
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && prev.active && prev.handle && next === undefined) {
          // console.log('poprzedni aktywny z handle, nastepny nieistnieje')
          this.$set(this.categories, payload.index, {
            ...this.categories[payload.index],
            active: false,
            handle: true,
          })
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev && !prev.active && prev.handle && next === undefined) {
          // console.log('poprzedni nieaktywny z handle, nastepny nieistnieje')
          const newSpot = {
            age_from: prev.age_from,
            age_to: this.ageMax - 1,
            active: false,
            handle: true,
          }
          const indexes = [payload.index - 1, payload.index, payload.index + 1];
          this.categories = this.categories.filter((_value, index) => !indexes.includes(index));
          this.categories = insert(this.categories, payload.index, this.getItemData(newSpot))

          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
        if (prev === undefined && next === undefined) {
          this.$set(this.categories, payload.index, {
            ...this.categories[payload.index],
            active: false,
            handle: false,
          })
          this.categories.forEach((_value, index) => {
            this.keys[index] = uuidv4()
          })
        }
      },
    }    
  }
</script>

<style>
:root {
  --rs-bg-track: hsla(200, 15%, 73%, 1);
  --rs-bg-track-hover: hsla(200, 15%, 54%, 1);
  --rs-bg-active-hover: hsl(200, 15%, 46%);
  --rs-bg-item-active: hsla(200, 18%, 26%, 1);
}
/* .rangeSlider__handle {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 24px;
  height: 36px;
  z-index: 22;
  background-color: #000;
} */
.rangeSlider {
  position: relative;
  padding-top: 27px;
  padding-left: 12px; 
  padding-bottom: 77px;
  /* overflow: hidden; */
}
.rangeSlider__track {
  height: 64px;
  width: 100%;
  background-color: var(--rs-bg-track);
  border-radius: 4px;
  display: flex;
  position: relative;
}
.rangeSlider__actions {
  position: relative;
  height: 100px;
  width: 100%;
  background: #dadada;
}
</style>

<style>
.dev {
  margin-top: 48px;
  display: flex;
  gap: 32px;
}
</style>