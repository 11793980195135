<template>
  <div>
    <p
      v-if="label.length"
      class="mab8"
    >
      {{ label }}
    </p>
    <DatePicker 
      v-model="innerValue"
      :mode="mode"
      :model-config="modelConfig"
      :popover="{ visibility: 'hidden' }"
      is24hr
    >
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="input-wrap" :class="{ 'input-field--error': error }">
          <input
            :placeholder="placeholder"
            :value="inputValue"
            v-on="inputEvents"
          />
          <div
            class="input-icon"
            @click="togglePopover()"
          >
            <img src="@/assets/images/icons/calendar.svg" />
          </div>
        </div>
      </template>
    </DatePicker>
    <span
      class="panel-input-error input-error"
      :class="{'input-error--show' : error}"
    >{{ say[error] }}</span>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { mapGetters } from 'vuex';

export default {
    components: {
      DatePicker,
    },
    props: {
      label: {
        type: String,
        default: '',
      },      
      placeholder: {
        type: String,
        default: '',
      },      
      mode: {
        type: String,
        default: 'date'
      },
      modelConfig: {
        type: Object,
        default: () => ({
          type: 'string',
          mask: 'DD.MM.YYYY', 
        })
      },
      value: {
        type: null,
        default: '',
      }, 
      error: {
        type: String,
        default: null,
      },
    },    
    data () {
      return {
          innerValue: null,
      }
    },
    watch: {
      innerValue(newVal) {
          this.$emit('update:error', null);
          this.$emit('input', newVal)
      },
      value(newVal) {
          this.innerValue = newVal
      },
    },
    created() {
      if (this.value) {
          this.innerValue = this.value
      }
    }, 
    computed: {
        ...mapGetters(['say']),
        // date: {
        //     get() {
        //         return this.value?.valueOf();
        //     },
        //     set(date) {
        //         this.$emit('input', date);
        //     },
        // },
    },
};
</script>

<style scoped>
.input-wrap {
  position: relative;
  border-radius: 4px;
}
.input-wrap input {
  color: var(--color-slate);
  background: #fff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 2px solid transparent;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
}
.input-wrap input:focus {
  border: solid 2px var(--color-dark-pastel-green);   
}
.input-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
.panel-input-error.input-error {
    bottom: 2px;
    left: 20px;
}
</style>