var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "link-input" },
    [
      _c("p", { staticClass: "link-input__value" }, [
        _vm._v(" " + _vm._s(_vm.link) + " "),
      ]),
      _c(
        "base-button",
        {
          staticClass: "link-input__btn",
          attrs: { btn9: "" },
          on: {
            click: function ($event) {
              return _vm.onClick()
            },
          },
        },
        [
          _vm.isMobile
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/link-icon.svg"),
                  alt: "link-icon",
                },
              })
            : _vm._e(),
          !_vm.isMobile
            ? _c("span", [_vm._v(" " + _vm._s(_vm.buttonName))])
            : _vm._e(),
        ]
      ),
      _vm.hint
        ? _c("div", { staticClass: "link-input__hint" }, [
            _vm._v(" " + _vm._s(_vm.hint) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }