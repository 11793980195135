var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("h2", { staticClass: "mat40" }, [
            _vm._v(" " + _vm._s(_vm.say.pay_for_participation) + " "),
          ]),
        ]),
        _c("div", { staticClass: "row mat40" }, [
          _c("div", { staticClass: "col-12 col-lg-2 mab16" }, [
            _c("h4", [_vm._v(_vm._s(_vm.say.email_paylink))]),
          ]),
          _c("div", { staticClass: "col-12 col-lg-9" }, [
            _c("div", { staticClass: "malr15" }, [
              _c("div", { staticClass: "pa24 bg--gray ma0" }, [
                _c("p", { staticClass: "f16" }, [
                  _vm._v(" " + _vm._s(_vm.say.paylink_hint) + " "),
                ]),
                _c("p", { staticClass: "f16 mat16" }, [
                  _vm._v(" " + _vm._s(_vm.say.participation_pay_hint) + " "),
                ]),
              ]),
              _c("div", { staticClass: "row minisite-pay__btn-send" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { btn1: "" },
                        on: {
                          click: function ($event) {
                            return _vm.send()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.say.email_paylink) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row mat48" }, [
          _c("div", { staticClass: "col-12 col-lg-2 mab16" }, [
            _c("h4", [_vm._v(_vm._s(_vm.say.or_create_account))]),
            _c("p", { staticClass: "minisite-pay__register-hint" }, [
              _vm._v(" " + _vm._s(_vm.say.register_email_hint) + " "),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-lg-9" }, [
            _c("div", { staticClass: "pa24 bg--gray ma0" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.say.create_account_to_access) + ":"),
              ]),
              _c(
                "p",
                { staticClass: "minisite-pay__hint minisite-pay__hint--first" },
                [_vm._v(" " + _vm._s(_vm.say.lists_upcoming_competition) + " ")]
              ),
              _c("p", { staticClass: "minisite-pay__hint" }, [
                _vm._v(" " + _vm._s(_vm.say.easy_registration) + " "),
              ]),
              _c("p", { staticClass: "minisite-pay__hint" }, [
                _vm._v(" " + _vm._s(_vm.say.list_your_results) + " "),
              ]),
              _c("p", { staticClass: "minisite-pay__hint" }, [
                _vm._v(" " + _vm._s(_vm.say.list_of_insurance_polices) + " "),
              ]),
            ]),
            _c("div", { staticClass: "row mat48 minisite-pay__buttons" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-3 minisite-pay__btn-back" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "text-upper",
                      attrs: { btn4: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.step_back) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "text-upper",
                      attrs: { btn2: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "authentication-register",
                            alias: true,
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.create_new_account) + " ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }