import { render, staticRenderFns } from "./StoperList.vue?vue&type=template&id=74c32d1d&scoped=true&"
import script from "./StoperList.vue?vue&type=script&lang=js&"
export * from "./StoperList.vue?vue&type=script&lang=js&"
import style0 from "./StoperList.vue?vue&type=style&index=0&id=74c32d1d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c32d1d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/dostartu.pl/app.startzone.online/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74c32d1d')) {
      api.createRecord('74c32d1d', component.options)
    } else {
      api.reload('74c32d1d', component.options)
    }
    module.hot.accept("./StoperList.vue?vue&type=template&id=74c32d1d&scoped=true&", function () {
      api.rerender('74c32d1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StoperList.vue"
export default component.exports