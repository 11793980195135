var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-form-section",
    [
      _c(
        "base-form-input",
        {
          scopedSlots: _vm._u([
            {
              key: "hint",
              fn: function () {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$router.matched("user-edit-profile", _vm.$route)
                          ? _vm.say.fill_user_data_to_register_faster
                          : _vm.say.fill_family_member_data
                      ) +
                      " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("base-input-text", {
            attrs: { error: _vm.errors.firstname, label: _vm.say.firstname },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "firstname", $event)
              },
            },
            model: {
              value: _vm.form.firstname,
              callback: function ($$v) {
                _vm.$set(_vm.form, "firstname", $$v)
              },
              expression: "form.firstname",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-text", {
            attrs: { error: _vm.errors.lastname, label: _vm.say.lastname },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "lastname", $event)
              },
            },
            model: {
              value: _vm.form.lastname,
              callback: function ($$v) {
                _vm.$set(_vm.form, "lastname", $$v)
              },
              expression: "form.lastname",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-radio", {
            attrs: {
              options: [
                { value: "M", title: "man" },
                { value: "K", title: "woman" },
              ],
              error: _vm.errors.gender,
            },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "gender", $event)
              },
            },
            model: {
              value: _vm.form.gender,
              callback: function ($$v) {
                _vm.$set(_vm.form, "gender", $$v)
              },
              expression: "form.gender",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-date", {
            attrs: {
              border: "",
              error: _vm.errors.birthTime,
              label: _vm.say.birthtime,
              initialView: "year",
              openDate: new Date("1983-01-01"),
            },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "birthTime", $event)
              },
            },
            model: {
              value: _vm.form.birthTime,
              callback: function ($$v) {
                _vm.$set(_vm.form, "birthTime", $$v)
              },
              expression: "form.birthTime",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        {
          attrs: { half: "" },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c("base-input-text", {
                    attrs: { label: _vm.say.phone, error: _vm.errors.phone },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$set(_vm.errors, "phone", $event)
                      },
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("base-input-select", {
            attrs: {
              reduce: (prefix) => prefix.id,
              clearable: false,
              options: _vm.prefixes,
              label: _vm.say.prefix,
            },
            model: {
              value: _vm.form.phonePrefix,
              callback: function ($$v) {
                _vm.$set(_vm.form, "phonePrefix", $$v)
              },
              expression: "form.phonePrefix",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-text", {
            attrs: { label: _vm.say.club, error: _vm.errors.clubname },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "clubname", $event)
              },
            },
            model: {
              value: _vm.form.clubname,
              callback: function ($$v) {
                _vm.$set(_vm.form, "clubname", $$v)
              },
              expression: "form.clubname",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-autocomplete", {
            staticClass: "user-form__autocomplete",
            attrs: {
              label: _vm.say.city,
              error: _vm.cityError,
              action: "AUTOCOMPLETE_LOCATION",
            },
            on: {
              "update:error": function ($event) {
                _vm.cityError = $event
              },
              input: _vm.cityWithoutSelect,
              select: _vm.selectCity,
              "search-blur": _vm.onSearchBlur,
            },
            model: {
              value: _vm.searchValueCity,
              callback: function ($$v) {
                _vm.searchValueCity = $$v
              },
              expression: "searchValueCity",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-map" } },
        [
          _vm.isCoordsCompetition
            ? _c("base-form-input", [
                _c(
                  "div",
                  { staticClass: "form-map" },
                  [
                    _c(
                      "base-map",
                      {
                        staticClass: "form-map__map",
                        attrs: {
                          lat: _vm.form.cityLat,
                          lng: _vm.form.cityLng,
                          zoom: 10,
                          fullscreenable: false,
                        },
                      },
                      [
                        _c(
                          "l-marker",
                          {
                            attrs: {
                              "lat-lng": [_vm.form.cityLat, _vm.form.cityLng],
                              draggable: true,
                            },
                            on: { "update:lat-lng": _vm.updateCoords },
                          },
                          [
                            _c(
                              "l-icon",
                              { attrs: { "icon-anchor": [12, 32] } },
                              [
                                _c("div", { staticClass: "form-map__marker" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/marker-ds.svg"),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-select", {
            attrs: {
              options: _vm.countries,
              clearable: false,
              label: _vm.say.nationality,
              reduce: (country) => country.id,
              error: _vm.errors.nationality,
            },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "nationality", $event)
              },
            },
            model: {
              value: _vm.form.nationality,
              callback: function ($$v) {
                _vm.$set(_vm.form, "nationality", $$v)
              },
              expression: "form.nationality",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }