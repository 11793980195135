import '@/components';
import '@/debug';
import '@/filters';
import '@/plugins';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import './registerServiceWorker';
import { SAVE_PROMPT_EVENT } from '@/store/mutations.type';
import firebaseMessaging from '@/plugins/firebase';
/**
 * @todo Switch routes to async import after Webpack 5 release.
 * @todo Move CSS to scoped SASS in components.
 * @todo Remove BaseButtonDownload and BaseInputTextWithIcon.
 * @todo Fix store/entities/find method as many-to-many relation.
 */
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    store.commit(SAVE_PROMPT_EVENT, e);
});

Vue.prototype.$messaging = firebaseMessaging

new Vue({
    router,
    store,
    render: (h) => h('router-view'),
}).$mount('#app');
