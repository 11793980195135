var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("panel-operator-header", {
        attrs: {
          "operator-id": _vm.operatorId,
          "is-organizer-list": _vm.isOrganizerList,
        },
        on: {
          "update:isOrganizerList": function ($event) {
            _vm.isOrganizerList = $event
          },
          "update:is-organizer-list": function ($event) {
            _vm.isOrganizerList = $event
          },
          "show-add-organizer": function ($event) {
            _vm.showAddOrganizer = !_vm.showAddOrganizer
          },
          "search-competitions": function ($event) {
            _vm.searchCompetitions = $event
          },
          "search-organizers": function ($event) {
            _vm.searchOrganizers = $event
          },
        },
      }),
      _c(
        "transition",
        { attrs: { name: "operator-form" } },
        [
          _vm.showAddOrganizer
            ? _c("panel-add-person", {
                staticClass: "operator-header__add-box",
                on: {
                  "add-person": _vm.onAddOrganizer,
                  close: function ($event) {
                    _vm.showAddOrganizer = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.ready
        ? _c("div", { staticClass: "container mat32" }, [_vm._m(0)])
        : _c("panel-list", {
            attrs: {
              "is-organizers": _vm.isOrganizerList,
              "is-competitions": !_vm.isOrganizerList,
              items: _vm.items,
            },
            on: {
              "row-click": _vm.onRowClick,
              "switch-show-competition": _vm.onSwitchCompetition,
              "send-access": _vm.sendAccessToOrganizer,
              "delete-competition": function ($event) {
                ;(_vm.showPopupWithDelete = true),
                  (_vm.competitionIdToDelete = $event)
              },
              "delete-person": function ($event) {
                ;(_vm.showPopupWithDelete = true),
                  (_vm.organizerIdToDelete = $event)
              },
            },
          }),
      !_vm.isOrganizerList && _vm.ready
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row operator__pages" }, [
              _c(
                "div",
                {
                  staticClass: "operator__pages-btn",
                  class: {
                    "operator__pages-btn--disabled": !_vm.isPreviousPage,
                  },
                  on: {
                    click: function ($event) {
                      _vm.isPreviousPage ? _vm.prevPage() : ""
                    },
                  },
                },
                [
                  _vm.isLoadingPrevCompetitions
                    ? _c("div", { staticClass: "operator__pages-loading" }, [
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" Poprzednie "),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "operator__pages-btn",
                  class: { "operator__pages-btn--disabled": !_vm.isNextPage },
                  on: {
                    click: function ($event) {
                      _vm.isNextPage ? _vm.nextPage() : ""
                    },
                  },
                },
                [
                  _vm.isLoadingNextCompetitions
                    ? _c("div", { staticClass: "operator__pages-loading" }, [
                        _vm._m(2),
                      ])
                    : _vm._e(),
                  _vm._v(" Następne "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.showPopupWithDelete
        ? _c("base-popup", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isOrganizerList
                              ? "Usuń organizatora"
                              : "Usuń zawody"
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "subtitle",
                  fn: function () {
                    return [
                      _c("b", [
                        _vm._v(
                          "Czy na pewno, chcesz usunąć " +
                            _vm._s(
                              _vm.isOrganizerList ? "organizatora" : "zawody"
                            ) +
                            "?"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _vm.isOrganizerList
                        ? _c("div", { staticClass: "mat16 c-negative" }, [
                            _vm._v(
                              " Zostaną usunięte wszystkie zawody organizatora "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "mat8" }, [
                        _vm._v(" Tej czynności nie będzie można cofnąć. "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "base-button",
                        {
                          staticClass: "mar15",
                          attrs: { btn2: "" },
                          on: {
                            click: function ($event) {
                              return _vm.onClosePopup()
                            },
                          },
                        },
                        [_vm._v(" anuluj ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "mal15",
                          attrs: { btn1: "" },
                          on: {
                            click: function ($event) {
                              _vm.onDelete(),
                                (_vm.showPopupWithDelete =
                                  !_vm.showPopupWithDelete)
                            },
                          },
                        },
                        [_vm._v(" Usuń ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2217794508
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "operator-header__loading-state" }, [
          _vm._v(" Trwa wczytywanie "),
          _c("div", { staticClass: "mal15 lds-ellipsis" }, [
            _c("div"),
            _c("div"),
            _c("div"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }