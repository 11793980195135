var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row flex-nowrap nav-bar" }, [
    _c(
      "div",
      { staticClass: "col-lg-7" },
      [
        _c(
          "base-button-group",
          { staticClass: "mar30 h100", attrs: { horizontal: "" } },
          [
            _c("base-button", {
              staticClass: "competition-topbar__btn competition-topbar__btn--1",
              attrs: {
                btn11: "",
                badge: "far fa-home",
                pressed: _vm.$route.name === "panel-competition-dashboard",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-dashboard",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            }),
            _c(
              "base-button",
              {
                staticClass:
                  "nowrap competition-topbar__btn competition-topbar__btn--2",
                attrs: { btn11: "", badge: "fas fa-tachometer-alt" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "panel-competition-setting-info",
                      params: _vm.competition,
                      alias: true,
                    })
                  },
                },
              },
              [_vm._v(" Ustawienia zawodów ")]
            ),
            _c(
              "base-button",
              {
                staticClass:
                  "nowrap competition-topbar__btn competition-topbar__btn--3",
                attrs: { btn11: "", badge: "far fa-users" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "panel-competition-list-new",
                      params: _vm.competition,
                      alias: true,
                    })
                  },
                },
              },
              [_vm._v(" Lista startowa ")]
            ),
            _vm.competition.isRemote === false
              ? _c(
                  "base-button",
                  {
                    staticClass:
                      "nowrap competition-topbar__btn competition-topbar__btn--4",
                    attrs: { btn11: "", badge: "fas fa-list-ol" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "panel-competition-result",
                          params: _vm.competition,
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" Wyniki ")]
                )
              : _c(
                  "base-button",
                  {
                    staticClass:
                      "nowrap competition-topbar__btn competition-topbar__btn--4",
                    attrs: { btn11: "", badge: "fas fa-list-ol" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "panel-competition-result-legacy",
                          params: _vm.competition,
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" Wyniki ")]
                ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-2" },
      [
        _c(
          "panel-menu-button",
          {
            staticClass: "mar30",
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function () {
                  return [
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "far fa-clock" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-stoper",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Stoper ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "fas fa-envelope" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-mail",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Wiadomości ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "far fa-shopping-bag" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-shop",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Sklep ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "fas fa-shield-alt" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-insurance",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Ubezpieczenia ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "far fa-language" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-languages",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Wersje językowe ")]
                    ),
                    _c(
                      "base-button",
                      {
                        attrs: { btn11: "", badge: "far fa-map" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "panel-competition-map",
                              params: _vm.competition,
                              alias: true,
                            })
                          },
                        },
                      },
                      [_vm._v(" Trasa i punkty ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "base-button",
              {
                class: {
                  "menu-btn": true,
                  "menu-btn--active": _vm.isMenuActive,
                },
                attrs: { btn11: "", icon: "fas fa-caret-down" },
              },
              [_vm._v(" Narzędzia ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.isResultsStatuses
      ? _c(
          "div",
          { staticClass: "col-lg-3 d-flex" },
          [
            _c(
              "base-button",
              {
                staticClass: "status-btn mar3",
                class: {
                  "status-btn--negative": [
                    _vm.STATUSES.close,
                    _vm.STATUSES.info,
                  ].includes(_vm.competition.status),
                },
                attrs: { btn9: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateCompetitionStatus(_vm.STATUSES.close)
                  },
                },
              },
              [_vm._v(" Zamknięta ")]
            ),
            _c(
              "base-button",
              {
                staticClass: "status-btn",
                class: {
                  "status-btn--positive":
                    _vm.competition.status == _vm.STATUSES.open,
                },
                attrs: { btn9: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateCompetitionStatus(_vm.STATUSES.open)
                  },
                },
              },
              [_vm._v(" Otwarta ")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "col-lg-3 d-flex" },
          [
            _vm.competition.status <= _vm.STATUSES.live
              ? _c(
                  "base-button",
                  {
                    staticClass: "mar4 status-result",
                    class: {
                      "status-btn--positive":
                        _vm.competition.status == _vm.STATUSES.live,
                    },
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.updateCompetitionStatus(_vm.STATUSES.live)
                      },
                    },
                  },
                  [_vm._v(" Live ")]
                )
              : _vm._e(),
            _c(
              "base-button",
              {
                staticClass: "mar4 status-result",
                class: {
                  "status-btn--positive":
                    _vm.competition.status == _vm.STATUSES.unofficial,
                },
                attrs: { btn9: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateCompetitionStatus(_vm.STATUSES.unofficial)
                  },
                },
              },
              [_vm._v(" Nieoficjalne ")]
            ),
            _c(
              "base-button",
              {
                staticClass: "status-result",
                class: {
                  "status-btn--positive":
                    _vm.competition.status == _vm.STATUSES.official,
                },
                attrs: { btn9: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateCompetitionStatus(_vm.STATUSES.official)
                  },
                },
              },
              [_vm._v(" Oficjalne ")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }