var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "filters-datepicker",
      class: {
        "input-field--error": _vm.error,
        "input-field--short": _vm.isShort,
      },
    },
    [
      _c("datepicker", {
        class: {
          "filters-datepicker__input-error":
            _vm.error && !_vm.withoutErrorMessage,
        },
        attrs: {
          "monday-first": "",
          format: _vm.format,
          "wrapper-class": _vm.border
            ? "filters-datepicker__wrapper-class border"
            : "filters-datepicker__wrapper-class",
          "calendar-button-icon": "far fa-calendar",
          "input-class": "filters-datepicker__input",
          language: _vm.pl,
          placeholder: _vm.placeholder,
          "initial-view": _vm.initialView,
          "open-date": _vm.openDate,
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _c("i", {
        staticClass: "far fa-calendar filters-datepicker__calendar-icon",
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error && !_vm.withoutErrorMessage },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error] || _vm.error))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }