import { schema } from 'normalizr';

export default new schema.Entity('locations', {}, {
    processStrategy: (location) => ({
        // Meta.
        _type: 'location',
        // Fields.
        ...location,
    }),
});
