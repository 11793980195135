var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-md-4 col-lg-3 d-flex justify-content-space-between",
          },
          [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "logods",
                    attrs: { to: { name: "main-landing", alias: true } },
                  },
                  [_vm._v(" DO:ST:AR:TU ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "justify-content-space-between footer__icon-fb--mobile",
              },
              [
                _c("p", [
                  _c("a", { attrs: { href: _vm.FACEBOOK_PAGE } }, [
                    _c("i", {
                      staticClass: "fab fa-2x fa-facebook-square",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-md-8 col-lg-9 d-flex justify-content-space-between",
          },
          [
            _c("div", { staticClass: "footer--links" }, [
              _c("div", { staticClass: "row out-gutter" }, [
                _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.say.organization))]),
                  _c(
                    "p",
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: `${_vm.organizerUrl}?section=rules` },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.say.cooperation_regulations) + " "
                          ),
                        ]
                      ),
                      _c("a", { attrs: { href: _vm.organizerUrl } }, [
                        _vm._v(" " + _vm._s(_vm.say.organizator_panel) + " "),
                      ]),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "timing-offer",
                              alias: true,
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.say.find_operator) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.say.participation))]),
                  _c(
                    "p",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "user-competitions-future",
                              alias: true,
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.say.user_account) + " ")]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "main-list",
                              query: { status: 1 },
                              alias: true,
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.say.competition_registration) + " "
                          ),
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "main-list",
                              query: { status: 0 },
                              alias: true,
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.say.competition_results) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.say.about_dostartu))]),
                  _c("p", [
                    _c("a", { attrs: { href: _vm.organizerUrl } }, [
                      _vm._v(" " + _vm._s(_vm.say.what_is_dostartu) + " "),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.BASE_URL + "polityka_prywatnosci.pdf",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.say.privacy_policy))]
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.BASE_URL + "Regulamin serwisu.pdf",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.say.dostartu_regulations))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-lg-3" }, [
                  _c("h5", [_vm._v(_vm._s(_vm.say.contact))]),
                  _c("p", [
                    _c("a", { attrs: { href: "mailto:" + _vm.MAIL_ADDRESS } }, [
                      _vm._v(_vm._s(_vm.say.write_to_us)),
                    ]),
                    _c("a", { attrs: { href: "mailto:" + _vm.MAIL_ADDRESS } }, [
                      _vm._v(_vm._s(_vm.MAIL_ADDRESS)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "footer__icon-fb--desktop footer--social" },
              [
                _c("a", { attrs: { href: _vm.FACEBOOK_PAGE } }, [
                  _c("i", {
                    staticClass: "fab fa-2x fa-facebook-square",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]),
    ["minisite-landing", "minisite-contact"].includes(_vm.$route.name)
      ? _c("div", { staticClass: "footer-cash pat32" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row justify-content-end" }, [
              _c("p", { staticClass: "transaction-dotpay" }, [
                _vm._v(
                  " " + _vm._s(_vm.say.transaction_settlement_by_dotpay) + " "
                ),
              ]),
              _vm._m(0),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("img", {
        staticClass: "img-fluid mal24",
        attrs: { src: require("@/assets/images/logo-dotpay.svg") },
      }),
      _c("img", {
        staticClass: "img-fluid mal24",
        attrs: { src: require("@/assets/images/logo-visa.svg") },
      }),
      _c("img", {
        staticClass: "img-fluid mal24",
        attrs: { src: require("@/assets/images/logo-mastercard.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }