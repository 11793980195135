import api from '@/api';
import { API_ADDRESS, API_ADDRESS_NO_CACHE } from '@/config';

export default (to, from, next) => {
    const rootRoute = to.matched.find((route) => route.meta.layout);
    if (rootRoute.meta.layout === 'back') {
        api.defaults.baseURL = API_ADDRESS_NO_CACHE;
        api.defaults.headers.get = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        };
    } else {
        api.defaults.baseURL = API_ADDRESS;
        api.defaults.headers.get = {};
    }
    next();
};
