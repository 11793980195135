<template>
    <base-form-section>
        <!-- Pesel. -->
        <!-- <base-form-input>
            <base-input-text
                v-model="form.pesel"
                :label="say.nr_pesel"
                :error.sync="errors.pesel"
            />
            <template v-slot:hint>
                {{
                    $route.name === 'user-edit-insurance' ?
                        say.fill_insurance_data :
                        say.save_default_data_to_buy_insurance
                }}
            </template>
        </base-form-input> -->

        <strong class="form__title">{{ say.accomodation_address }}</strong>

        <!-- Street. -->
        <base-form-input>
            <base-input-text
                v-model="form.street"
                :label="say.street"
                :error.sync="errors.street"
            />
        </base-form-input>

        <base-form-input half>
            <!-- House number. -->
            <base-input-text
                v-model="form.houseNumber"
                :label="say.house_number"
                :error.sync="errors.houseNumber"
            />
            <!-- Flat number. -->
            <template v-slot:right>
                <base-input-text
                    v-model="form.flatNumber"
                    :label="say.flat_number"
                    :error.sync="errors.flatNumber"
                />
            </template>
        </base-form-input>

        <!-- City. -->
        <base-form-input>
            <base-input-autocomplete
                v-model="searchValueCity"
                class="user-form__autocomplete"
                :label="say.city"
                :error.sync="cityError"
                :clear-search-on-select="true"
                action="AUTOCOMPLETE_LOCATION"
                @select="selectCity($event)"
            />
            <!-- <base-input-text
                v-model="form.city"
                :label="say.city"
                :error.sync="errors.city"
            /> -->
            <!-- <base-input-location
                v-model="city"
                :label="say.city"
                :error="cityError"
                @select="city=$event"
            /> -->
        </base-form-input>

        <!-- Zipcode. -->
        <base-form-input>
            <base-input-text
                v-model="form.zipcode"
                :label="say.zip_code"
                :error.sync="errors.zipcode"
            />
        </base-form-input>

        <!-- Country. -->
        <base-form-input>
            <base-input-select
                v-model="form.country"
                :options="countries"
                :clearable="false"
                :label="say.country"
                :reduce="country=>country.id"
                :error.sync="errors.country"
                :placeholder="say.country"
            />
        </base-form-input>
    </base-form-section>
</template>

<script>
import { mapGetters } from 'vuex';
import countries from '@/assets/data/countries';
import form from '@/mixins/form';

export default {
    mixins: [form],
    data() {
        return {
            countries,
            searchValueCity: form.city,
        };
    },
    watch: {
        'form.city': {
            immediate: true,
            handler() {
                this.searchValueCity = this.form.city;
            },
        },
    },
    computed: {
        ...mapGetters(['say']),
        cityError: {
            get() {
                return (
                    this.errors.city
                    || this.errors.cityLat
                    || this.errors.cityLng
                );
            },
            set() {
                this.errors.city = '';
                this.errors.cityLat = '';
                this.errors.cityLng = '';
            },
        },
        city: {
            get() {
                return this.form.city;
            },
            set(value) {
                if (value.name) {
                    // Place changed event.
                    this.$set(this.form, 'cityLng', value.cityLng);
                    this.$set(this.form, 'cityLat', value.cityLat);
                    this.$set(this.form, 'city', value.city);
                } else {
                    // Input event.
                    this.$set(this.form, 'cityLat', null);
                    this.$set(this.form, 'cityLng', null);
                    this.$set(this.form, 'city', value);
                }
            },
        },
    },
    methods: {
        selectCity(value) {
            // place changed event
            if (value) {
                this.$set(this.form, 'cityLng', value.cityLng);
                this.$set(this.form, 'cityLat', value.cityLat);
                this.$set(this.form, 'city', value.city);
                setTimeout(() => {
                    this.searchValueCity = value.text;
                }, 40);
            }
        },
    },
};
</script>

<style scoped>
.form__title {
    display: block;
    margin-top: 32px;
}
</style>
