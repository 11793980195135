<template>
  <div>
    <h3 class="mab24">Powiadomienia</h3>
    <p class="mab24">Możemy przypominać Ci o nadchodzących startach oraz informować o wynikach i nowych zawodach.</p>
    <h4 class="mab12">{{ say.enable_notifications_for }}</h4>
    <div class="bsh mab24">
        <base-button
            btn6
            class="minisite__button minisite__button__nptb"
        >
            <span>{{ say.notifications_for_proposed_competition }}</span>
            <base-input-switch @change.native="updateNotifications()" v-model="notifications.proposedCompetition" :checked="notifications.proposedCompetition" />
        </base-button>
        <base-button
            btn6
            class="minisite__button minisite__button__nptb"
        >
            <span>{{ say.notifications_for_upcoming_races }}</span>
            <base-input-switch @change.native="updateNotifications()" v-model="notifications.incomingCompetition" :checked="notifications.incomingCompetition" />
        </base-button>
        <base-button
            btn6
            class="minisite__button minisite__button__nptb"
        >
            <span>{{ say.notifications_for_my_results }}</span>
            <base-input-switch @change.native="updateNotifications()" v-model="notifications.myResults" :checked="notifications.myResults" />
        </base-button>
    </div>
    <p class="mab24">Do konfiguracji przychodzących powiadomień możesz zawsze wrócić w sekcji Więcej.</p>
    <base-button
      @click="updateNotifications()"
      class="btn-add"
      btn1
    >
      Zapisz
    </base-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { 
    CREATE_USER_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATIONS,
    READ_USER_NOTIFICATIONS,
} from '@/store/actions.type';

export default {
  name: "UserNotifications",
  computed: {
    ...mapGetters(['say', 'notifications', 'createNotifications']),
  },
  methods: {
    ...mapActions([
        CREATE_USER_NOTIFICATIONS,
        UPDATE_USER_NOTIFICATIONS,
        READ_USER_NOTIFICATIONS,
    ]),
    updateNotifications() {
      this.$messaging.getToken({ vapidKey: 'BMD60s7nRtWIi-qladjK7a1kHi1TCNSODn9XaU-nghZVvbs2mE78WHInwzIPMymEFKSdNMbfc-OQaYBcRcI02fM' }).then((currentToken) => {
        if (currentToken) {
            this.notifications.deviceToken = currentToken
            if(this.createNotifications) {
                this.CREATE_USER_NOTIFICATIONS(this.notifications)
            } else {
                this.UPDATE_USER_NOTIFICATIONS(this.notifications)
            }     
            this.$router.push('main')               
            // console.log('client token', currentToken)
        }
      }).catch(() => {
        this.notifications.deviceToken = null
        if(this.createNotifications) {
            this.CREATE_USER_NOTIFICATIONS(this.notifications)
        } else {
            this.UPDATE_USER_NOTIFICATIONS(this.notifications)
        }     
        this.$router.push('main') 
        // console.log('An error occurred while retrieving token. ', err);
      })            
    },       
  },
  mounted() {
     this.READ_USER_NOTIFICATIONS();
  }      
}
</script>

<style scoped>
.bsh {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(55, 71, 79, 0.2);
  border-radius: 4px;
}
.minisite__button {
  padding: 14px 15px;
  line-height: 1.43;
  border: none;
  border-bottom: 1px solid #edf0f1;
  box-shadow: none;
  justify-content: space-between;
}
.minisite__button__nptb {
  cursor: initial;
  padding-top: 0;
  padding-bottom: 0;
}
</style>