<template>
    <div>
        <panel-organizer-header
            :organizer-id="organizerId"
            @search-competitions="searchInput = $event"
            @add-competition="onAddCompetition"
        />
        <div
            v-if="!ready"
            class="container mat32"
        >
            <div class="row">
                <div class="col-12">
                    <div class="organizer-header__loading-state">
                        Trwa wczytywanie
                        <div class="mal15 lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <panel-list
            v-else-if="ready && competitionsIds.length"
            is-competitions
            :items="items"
            @row-click="onRowClick"
            @switch-show-competition="switchShowCompetitions"
            @delete-competition="
                (showPopupWithDelete = true), (competitionIdToDelete = $event)
            "
        />
        <organizer-account-hint
            v-else-if="
                ready &&
                    !competitionsIds.length &&
                    page === 1 &&
                    !isLoadingPrevCompetitions
            "
            :organizer-id="organizerId"
        />
        <!-- Pagination. -->
        <div
            v-if="ready"
            class="container"
        >
            <div class="row organizer__pages">
                <div
                    class="organizer__pages-btn"
                    :class="{
                        'organizer__pages-btn--disabled': !isPreviousPage,
                    }"
                    @click="isPreviousPage ? prevPage() : ''"
                >
                    <div
                        v-if="isLoadingPrevCompetitions"
                        class="organizer__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Poprzednie
                </div>
                <div
                    class="organizer__pages-btn"
                    :class="{ 'organizer__pages-btn--disabled': !isNextPage }"
                    @click="isNextPage ? nextPage() : ''"
                >
                    <div
                        v-if="isLoadingNextCompetitions"
                        class="organizer__pages-loading"
                    >
                        <div class="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    Następne
                </div>
            </div>
        </div>
        <base-popup v-if="showPopupWithDelete">
            <!-- Title. -->
            <template #title>
                Usuń zawody
            </template>
            <!-- Subtitle. -->
            <template #subtitle>
                <b>Czy na pewno, chcesz usunąć zawody?</b>
            </template>
            <!-- Content. -->
            <template #content>
                <div class="mat16">
                    Tej czynności nie będzie można cofnąć.
                </div>
            </template>
            <!-- Buttons. -->
            <template #buttons>
                <base-button
                    class="mar15"
                    btn2
                    @click="onClosePopup()"
                >
                    anuluj
                </base-button>
                <base-button
                    class="mal15"
                    btn1
                    @click="
                        deleteCompetition(competitionIdToDelete),
                        (showPopupWithDelete = !showPopupWithDelete)
                    "
                >
                    Usuń
                </base-button>
            </template>
        </base-popup>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    BOOTSTRAP_ORGANIZER,
    DELETE_COMPETITION,
    READ_COMPETITIONS,
    UPDATE_COMPETITION,
} from '@/store/actions.type';
import PanelList from '@/components/PanelList';
import OrganizerAccountHint from '@/components/OrganizerAccountHint';
import PanelOrganizerHeader from '@/components/PanelOrganizerHeader';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        PanelList,
        OrganizerAccountHint,
        PanelOrganizerHeader,
    },
    data() {
        return {
            searchInput: '',
            competitionsIds: [],
            ready: false,
            page: 1,
            isLoadingPrevCompetitions: false,
            isLoadingNextCompetitions: false,
            showPopupWithDelete: null,
            competitionIdToDelete: null,
        };
    },

    computed: {
        ...mapGetters(['user', 'competitions', 'competitionPlayerCounts', 'organizers']),
        organizerId() {
            if (this.$route.params.organizerId && this.isModerator) {
                return this.$route.params.organizerId;
            }
            return this.user.organizer;
        },
        isModerator() {
            return (
                this.user.roles.includes('ROLE_ADMIN')
                || this.user.roles.includes('ROLE_OPERATOR')
            );
        },
        isPreviousPage() {
            return this.page > 1;
        },
        organizer() {
            return this.organizers[this.organizerId];
        },
        isNextPage() {
            return this.competitionsIds.length === 30 && !this.searchInput;
        },
        items() {
            const list = [];
            for (const id of this.competitionsIds) {
                const competition = this.competitions[id];
                const competitionInfo = {
                    id,
                    startedTime: this.$options.filters.asTime(
                        competition.startedTime,
                    ),
                    name: competition.name,
                    registered: this.competitionPlayerCounts[id].playersCount,
                    paid: this.competitionPlayerCounts[id].playersPayCount,
                    location: competition.location,
                    status: this.$options.filters.asStatus(competition.status),
                    statusId: competition.status,
                    isShow: competition.showOnOrganizerPage,
                };
                list.push(competitionInfo);
            }
            return list;
        },
    },
    watch: {
        async searchInput() {
            if (this.searchInput.length >= 3) {
                const { competitions } = await this.READ_COMPETITIONS({
                    itemsPerPage: 10,
                    organizer: this.organizerId,
                    page: 1,
                    panel: 1,
                    name: this.searchCompetitions,
                });
                this.competitionsIds = competitions;
            } else if (!this.searchInput) {
                this.page = 1;
                const { competitions } = await this.READ_COMPETITIONS({
                    itemsPerPage: 30,
                    organizer: this.organizerId,
                    page: this.page,
                    panel: 1,
                });
                this.competitionsIds = competitions;
            }
        },
    },
    async created() {
        const { competitionsIds } = await this.BOOTSTRAP_ORGANIZER(
            this.organizerId,
        );
        this.competitionsIds = competitionsIds;
        this.ready = true;
    },
    methods: {
        ...mapActions([
            BOOTSTRAP_ORGANIZER,
            READ_COMPETITIONS,
            UPDATE_COMPETITION,
            DELETE_COMPETITION,
        ]),
        ...mapMutations([SET_NOTIFICATION]),
        async nextPage() {
            this.page += 1;
            this.isLoadingNextCompetitions = true;
            const { competitions } = await this.READ_COMPETITIONS({
                organizer: this.organizerId,
                panel: 1,
                page: this.page,
                itemsPerPage: 30,
            });
            this.competitionsIds = competitions;
            this.isLoadingNextCompetitions = false;
        },
        async prevPage() {
            this.page -= 1;
            this.isLoadingPrevCompetitions = true;
            const { competitions } = await this.READ_COMPETITIONS({
                organizer: this.organizerId,
                panel: 1,
                page: this.page,
                itemsPerPage: 30,
            });
            this.competitionsIds = competitions;
            this.isLoadingPrevCompetitions = false;
        },
        onRowClick(item) {
            this.$router.push({
                name: 'panel-competition-dashboard',
                params: {
                    name: item.name,
                    id: item.id,
                },
                alias: true,
            });
        },
        async switchShowCompetitions(id) {
            const competition = {
                ...this.competitions[id],
                showOnOrganizerPage: !this.competitions[id].showOnOrganizerPage,
            };
            await this.UPDATE_COMPETITION(competition);
        },
        onAddCompetition() {
            this.$router.push({
                name: 'panel-create-competition',
                alias: true,
                params: {
                    organizerId: this.organizerId,
                },
            });
        },
        // Delete competition handler.
        async deleteCompetition(id) {
            try {
                const competitionIndex = this.competitionsIds.findIndex(
                    (competitionId) => competitionId === id,
                );
                this.competitionsIds.splice(competitionIndex, 1);
                await this.DELETE_COMPETITION(id);
                this.SET_NOTIFICATION('alerts_positive_56');
            } catch (e) {
                this.SET_NOTIFICATION('alerts_negative_4');
                this.competitionsIds.push(id);
            }
        },
        onClosePopup() {
            this.organizerIdToDelete = null;
            this.competitionIdToDelete = null;
            this.showPopupWithDelete = false;
        },
    },
};
</script>

<style scoped>
.organizer__pages {
    margin-top: 32px;
    margin-bottom: 32px;
    position: relative;
}
.organizer__pages-btn {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    position: relative;
}
.organizer__pages-btn--disabled {
    opacity: 0.5;
}
.operator-header__views-btn {
    width: 100%;
    justify-content: center;
    user-select: none;
}
.organizer__pages-loading {
    position: absolute;
    top: -25px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.organizer-header__loading-state {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.operator-header__organizer {
    background-color: var(--color-slate);
    margin-top: 2px;
}
/* Animation loading next/prev page */
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}
.lds-ripple div {
    position: absolute;
    border: 1px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 8px;
        left: 8px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 16px;
        height: 16px;
        opacity: 0;
    }
}
/* End animation loading next/prev page */

@media (min-width: 992px) {
    .operator-header__views {
        margin-right: 15px;
    }
}
</style>
