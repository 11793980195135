<template>
  <div class="container mat40">
    <div class="row admin-header">
      <div class="col-12 col-md-4">
        <h1  v-if="listParent === 'payments'">Lista płatności</h1>
        <h1  v-if="listParent === 'agreements'">Lista umów</h1>
      </div>
      <div class="col-12 col-md-8">
        <div class="buttons-wrap">
          <base-input-text
            v-model="search"
            icon="fas fa-search"
            label="Szukaj..."
            class="admin-header__search"
          />
          <base-button-group
            horizontal
            class="admin-header__views"
          >
            <base-button
                class="admin-header__views-btn"
                btn11
                :pressed="listParent === 'payments'"
                @click="changeUsersList('payments')"
            >
              Płatności
            </base-button>
            <base-button
                class="admin-header__views-btn"
                btn11
                :pressed="listParent === 'agreements'"
                @click="changeUsersList('agreements')"                  
            >
              Umowy
            </base-button>
          </base-button-group>
        </div>
      </div>  
    </div>
    <div
      v-if="!ready"
      class="mat32"
    >
      <div class="row">
        <div class="col-12">
          <div class="admin-header__loading-state">
            Trwa wczytywanie
            <div class="mal15 lds-ellipsis">
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row container-list">
      <div v-if="listParent === 'payments'" class="col-12">
        <div
          ref="list"
        >
          <base-table
            class="list"
            :headers="tableHeaders"
            :items="items"
            sortColumnDefault="0"
          >
            <!-- Headers. -->
            <template v-slot:header="{ sort }">
              <tr class="list__row">
                <th
                  v-for="(header, column) in tableHeaders"
                  :key="column"
                  class="list__row-header"
                  @click="sort(column)"
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <!-- Body. -->
            <template v-slot:body="{ displayedItems }">
              <tr
                v-for="item in displayedItems"
                :key="item.id"
                class="list__row"
                :class="{
                  'list__row--overtime': item.overDate,
                  'list__row--payed': item.payed,
                  'list__row--notpayed': !item.payed,
                }" 
              >
                <td
                  v-for="(header, column) in tableHeaders"
                  :key="column"
                  class="list__row-data"
                  :class="{
                    'list__row-data--name': header.value === 'competitionName',
                    'list__row-data--loading': loading,
                    'list__row-data--date': item[header.value] === item.paymentDate,
                    'list__row-data--payed': item[header.value] === item.payed,
                  }" 
                  @click="
                    header.value === 'more'
                      ? menuClickHandler($event, item.id)
                      : ''
                  "                                               
                >
                  <template v-if="header.value === 'more'">
                    <div
                      :ref="item.id"
                      class="more-btn"
                    >
                      <i class="fas fa-ellipsis-v" />
                      <div
                        v-if="menuCompetitionId === item.id"
                        class="more-btn__content"
                        :class="{
                            'more-btn__content--from-up': isMenuCompetitionIdInBottom,
                        }"
                      >
                        <div
                          class="more-btn__content-btn"
                          @click="sendPaymentMessage(item.id)"
                        >

                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 5H4.5C3.11929 5 2 6.11929 2 7.5V16.5096C2 17.8903 3.11929 19.0096 4.5 19.0096H19.5C20.8807 19.0096 22 17.8903 22 16.5096V7.5C22 6.11929 20.8807 5 19.5 5ZM20.1444 6.1451C19.9492 6.05207 19.7307 6 19.5 6H4.5C4.26933 6 4.05081 6.05207 3.85557 6.1451L12 12.8523L20.1444 6.1451ZM3.1431 17.1498C3.14773 17.1451 3.15247 17.1405 3.15732 17.1359L8.9772 11.6584L3.18215 6.88597C3.16951 6.87556 3.15752 6.86466 3.14617 6.85332C3.05247 7.04913 3 7.26844 3 7.5V16.5096C3 16.7386 3.05132 16.9557 3.1431 17.1498ZM3.84675 17.8603C4.04422 17.956 4.26584 18.0096 4.5 18.0096H19.5C19.7342 18.0096 19.9558 17.956 20.1533 17.8603L14.2446 12.2992L12.3179 13.886C12.1332 14.038 11.8668 14.038 11.6821 13.886L9.75538 12.2992L3.84675 17.8603ZM21 16.5096C21 16.7386 20.9487 16.9557 20.8569 17.1498C20.8523 17.1451 20.8475 17.1405 20.8427 17.1359L15.0228 11.6584L20.8179 6.88597C20.8305 6.87556 20.8425 6.86466 20.8538 6.85333C20.9475 7.04914 21 7.26844 21 7.5V16.5096Z" fill="#455A64"/>
                          </svg>
                          <span class="sended" style="display: none;">Wiadomość wysłana</span>
                          <span class="send" >Wyślij wiadmość o płatności</span>
                        </div>
                        <div
                          v-if="item.payed"
                          class="more-btn__content-btn"
                          @click="sendPaymentStatus(item.id, false)"
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z" fill="#455A64"/>
                          </svg> 
                          Oznacz jako nieopłacony
                        </div>
                        <div
                          v-else
                          class="more-btn__content-btn"
                          @click="sendPaymentStatus(item.id, true)"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.8536 9.14645C15.6583 8.95118 15.3417 8.95118 15.1464 9.14645L10.5 13.7929L8.85355 12.1464C8.65829 11.9512 8.34171 11.9512 8.14645 12.1464C7.95118 12.3417 7.95118 12.6583 8.14645 12.8536L10.1464 14.8536C10.3417 15.0488 10.6583 15.0488 10.8536 14.8536L15.8536 9.85355C16.0488 9.65829 16.0488 9.34171 15.8536 9.14645Z" fill="#455A64"/>
                          </svg>
                          Oznacz jako opłacony
                        </div>
                        <div
                          class="more-btn__content-btn"
                          @click="$router.push({
                              name: 'panel-competition-dashboard',
                              params: {
                                  name: item.competitionName,
                                  id: item.competition,
                              },
                              alias: true,
                          });"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM3.5 12H14.2929L11.1464 15.1464C10.9512 15.3417 10.9512 15.6583 11.1464 15.8536C11.3417 16.0488 11.6583 16.0488 11.8536 15.8536L15.8536 11.8536C16.0488 11.6583 16.0488 11.3417 15.8536 11.1464L11.8536 7.14645C11.6583 6.95118 11.3417 6.95118 11.1464 7.14645C10.9512 7.34171 10.9512 7.65829 11.1464 7.85355L14.2929 11H3.5C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12Z" fill="#455A64"/>
                          </svg>
                          Przejdź do zawodów
                        </div>                        
                      </div>
                    </div>
                  </template>                
                  <template v-else-if="item[header.value] === item.payed">
                    <span v-if="item.overDate">
                      <img src="@/assets/images/icons/notpaid.svg" class="icon" /> Opóźniona
                    </span>
                    <span v-else-if="item.payed">
                      <img src="@/assets/images/icons/checkmark-circle.svg" class="icon" /> Opłacona
                    </span>
                    <span v-else>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z" fill="#455A64"/>
                      </svg> Nieopłacona
                    </span>
                  </template>
                  <template v-else>
                    {{ item[header.value] }}
                  </template>
                </td>
              </tr>
            </template>
            <template v-slot:no-results="{ search: term }">
              <div v-if="term.length">
                Brak wyników wyszukiwania
                <b>{{ term }}</b>
              </div>
            </template>
          </base-table>
        </div>
      </div>
      <div v-if="listParent === 'agreements'" class="col-12">
        <div
          ref="list"
        >
          <base-table
            class="list"
            :headers="tableHeaders2"
            :items="items"
            sortColumnDefault="0"
          >
            <!-- Headers. -->
            <template v-slot:header="{ sort }">
              <tr class="list__row">
                <th
                  v-for="(header, column) in tableHeaders2"
                  :key="column"
                  class="list__row-header"
                  @click="sort(column)"
                >
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <!-- Body. -->
            <template v-slot:body="{ displayedItems }">
              <tr
                v-for="item in displayedItems"
                :key="item.id"
                class="list__row"
                :class="{
                  'list__row--overtime': item.overDate,
                  'list__row--payed': item.payed,
                  'list__row--notpayed': !item.payed,
                }" 
              >
                <td
                  v-for="(header, column) in tableHeaders2"
                  :key="column"
                  class="list__row-data"
                  :class="{
                    'list__row-data--name': header.value === 'competitionName',
                    'list__row-data--loading': loading,
                  }" 
                  @click="
                    header.value === 'more'
                      ? menuClickHandler($event, item.id)
                      : ''
                  "                                               
                >
                  <template v-if="header.value === 'more'">
                    <div
                      :ref="item.id"
                      class="more-btn"
                    >
                      <i class="fas fa-ellipsis-v" />
                      <div
                        v-if="menuCompetitionId === item.id"
                        class="more-btn__content"
                        :class="{
                            'more-btn__content--from-up': isMenuCompetitionIdInBottom,
                        }"
                      >
                        <div
                          class="more-btn__content-btn"
                          @click="sendPaymentMessage(item.id)"
                        >

                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 5H4.5C3.11929 5 2 6.11929 2 7.5V16.5096C2 17.8903 3.11929 19.0096 4.5 19.0096H19.5C20.8807 19.0096 22 17.8903 22 16.5096V7.5C22 6.11929 20.8807 5 19.5 5ZM20.1444 6.1451C19.9492 6.05207 19.7307 6 19.5 6H4.5C4.26933 6 4.05081 6.05207 3.85557 6.1451L12 12.8523L20.1444 6.1451ZM3.1431 17.1498C3.14773 17.1451 3.15247 17.1405 3.15732 17.1359L8.9772 11.6584L3.18215 6.88597C3.16951 6.87556 3.15752 6.86466 3.14617 6.85332C3.05247 7.04913 3 7.26844 3 7.5V16.5096C3 16.7386 3.05132 16.9557 3.1431 17.1498ZM3.84675 17.8603C4.04422 17.956 4.26584 18.0096 4.5 18.0096H19.5C19.7342 18.0096 19.9558 17.956 20.1533 17.8603L14.2446 12.2992L12.3179 13.886C12.1332 14.038 11.8668 14.038 11.6821 13.886L9.75538 12.2992L3.84675 17.8603ZM21 16.5096C21 16.7386 20.9487 16.9557 20.8569 17.1498C20.8523 17.1451 20.8475 17.1405 20.8427 17.1359L15.0228 11.6584L20.8179 6.88597C20.8305 6.87556 20.8425 6.86466 20.8538 6.85333C20.9475 7.04914 21 7.26844 21 7.5V16.5096Z" fill="#455A64"/>
                          </svg>
                          <span class="sended" style="display: none;">Wiadomość wysłana</span>
                          <span class="send" >Wyślij wiadmość o płatności</span>
                        </div>
                        <div
                          v-if="item.payed"
                          class="more-btn__content-btn"
                          @click="sendPaymentStatus(item.id, false)"
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z" fill="#455A64"/>
                          </svg> 
                          Oznacz jako nieopłacony
                        </div>
                        <div
                          v-else
                          class="more-btn__content-btn"
                          @click="sendPaymentStatus(item.id, true)"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.8536 9.14645C15.6583 8.95118 15.3417 8.95118 15.1464 9.14645L10.5 13.7929L8.85355 12.1464C8.65829 11.9512 8.34171 11.9512 8.14645 12.1464C7.95118 12.3417 7.95118 12.6583 8.14645 12.8536L10.1464 14.8536C10.3417 15.0488 10.6583 15.0488 10.8536 14.8536L15.8536 9.85355C16.0488 9.65829 16.0488 9.34171 15.8536 9.14645Z" fill="#455A64"/>
                          </svg>
                          Oznacz jako opłacony
                        </div>
                        <div
                          class="more-btn__content-btn"
                          @click="$router.push({
                              name: 'panel-competition-dashboard',
                              params: {
                                  name: item.competitionName,
                                  id: item.competition,
                              },
                              alias: true,
                          });"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="mar8" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM3.5 12H14.2929L11.1464 15.1464C10.9512 15.3417 10.9512 15.6583 11.1464 15.8536C11.3417 16.0488 11.6583 16.0488 11.8536 15.8536L15.8536 11.8536C16.0488 11.6583 16.0488 11.3417 15.8536 11.1464L11.8536 7.14645C11.6583 6.95118 11.3417 6.95118 11.1464 7.14645C10.9512 7.34171 10.9512 7.65829 11.1464 7.85355L14.2929 11H3.5C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12Z" fill="#455A64"/>
                          </svg>
                          Przejdź do zawodów
                        </div>                        
                      </div>
                    </div>
                  </template>                
                  <template v-else-if="header.value === 'email'">
                    pole@zmailem.pl
                  </template>
                  <template v-else-if="header.value === 'allPayments'">
                    16 789,23 PLN
                  </template>
                  <template v-else-if="header.value === 'lastCountedProvision'">
                    6 %
                  </template>
                  <template v-else-if="header.value === 'agreement'">
                    <img src="@/assets/images/icons/checkmark-circle.svg" class="icon" /> Podpisana
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="icon" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.5 2.53125C4.02083 1.01042 5.85417 0.25 8 0.25C10.1458 0.25 11.9688 1.01042 13.4688 2.53125C14.9896 4.03125 15.75 5.85417 15.75 8C15.75 10.1458 14.9896 11.9792 13.4688 13.5C11.9688 15 10.1458 15.75 8 15.75C5.85417 15.75 4.02083 15 2.5 13.5C1 11.9792 0.25 10.1458 0.25 8C0.25 5.85417 1 4.03125 2.5 2.53125ZM3.5625 12.4375C4.79167 13.6458 6.27083 14.25 8 14.25C9.72917 14.25 11.1979 13.6458 12.4062 12.4375C13.6354 11.2083 14.25 9.72917 14.25 8C14.25 6.27083 13.6354 4.80208 12.4062 3.59375C11.1979 2.36458 9.72917 1.75 8 1.75C6.27083 1.75 4.79167 2.36458 3.5625 3.59375C2.35417 4.80208 1.75 6.27083 1.75 8C1.75 9.72917 2.35417 11.2083 3.5625 12.4375ZM11.1875 6.0625L9.25 8L11.1875 9.9375C11.3542 10.125 11.3542 10.3021 11.1875 10.4688L10.4688 11.1875C10.3021 11.3542 10.125 11.3542 9.9375 11.1875L8 9.25L6.0625 11.1875C5.875 11.3542 5.69792 11.3542 5.53125 11.1875L4.8125 10.4688C4.64583 10.3021 4.64583 10.125 4.8125 9.9375L6.75 8L4.8125 6.0625C4.64583 5.875 4.64583 5.69792 4.8125 5.53125L5.53125 4.8125C5.69792 4.64583 5.875 4.64583 6.0625 4.8125L8 6.75L9.9375 4.8125C10.125 4.64583 10.3021 4.64583 10.4688 4.8125L11.1875 5.53125C11.3542 5.69792 11.3542 5.875 11.1875 6.0625Z" fill="#455A64"/>
                    </svg> Niepodpisana
                  </template>
                  <template v-else>
                    {{ item[header.value] }}
                  </template>
                </td>
              </tr>
            </template>
            <template v-slot:no-results="{ search: term }">
              <div v-if="term.length">
                Brak wyników wyszukiwania
                <b>{{ term }}</b>
              </div>
            </template>
          </base-table>
        </div>
      </div>
    </div>
    <div v-if="ready" class="row admin__pages" style="display: none;">
        <div
            class="admin__pages-btn"
            :class="{
                'admin__pages-btn--disabled': !isPreviousPage,
            }"
            @click="isPreviousPage ? prevPage() : ''"
        >
            <div
                v-if="isLoadingPrev"
                class="admin__pages-loading"
            >
                <div class="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            Poprzednie
        </div>
        <div
            class="admin__pages-btn"
            :class="{ 'admin__pages-btn--disabled': !isNextPage }"
            @click="isNextPage ? nextPage() : ''"
        >
            <div
                v-if="isLoadingNext"
                class="admin__pages-loading"
            >
                <div class="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            Następne
        </div>
    </div>     
  </div>  
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'PanelProvisionListAdmin',
  data() {
    return {
      menuCompetitionId: null,
      isMenuCompetitionIdInBottom: false,      
      search: '',
      listParent: 'payments',
      ready: false,
      loading: false,
      tableHeaders: [
        { text: 'Płatnośc do', value: 'paymentDate' },
        { text: 'Nazwa zawodów', value: 'competitionName' },
        { text: 'Nazwa organizatora', value: 'organizer' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Suma opłat', value: 'lastCountedPayments' },
        { text: 'Prowizja', value: 'lastCountedProvision' },
        { text: 'Płatność', value: 'payed' },
        { text: '', value: 'more' },
      ],
      tableHeaders2: [
        { text: 'Nazwa organizatora', value: 'organizer' },
        { text: 'Adres e-mail', value: 'email' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Suma prowizji', value: 'allPayments' },
        { text: 'Prowizja', value: 'lastCountedProvision' },
        { text: 'Umowa', value: 'agreement' },
        { text: '', value: 'more' },
      ],
      page: 1,
      itemsPerPage: 50,
      data: [],
      isLoadingNext: false,
      isLoadingPrev: false,
      errors: {},
      isPaymentMessageSend: false,
    };
  },
  watch: {
    search() {
      this.debouncedSearchQuery();
    },
    menuCompetitionId(id) {
      const distanceTopParentElement = this.$refs.list.getBoundingClientRect().top;
      const distanceTopChildElement = this.$refs.list.getBoundingClientRect().top;
      if (id) {
        if (distanceTopChildElement - distanceTopParentElement > 200) {
          this.isMenuCompetitionIdInBottom = true;
        } else {
          this.isMenuCompetitionIdInBottom = false;
        }
      }
    },    
  },
  mounted() {
    document.addEventListener('click', this.closeMenu);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMenu);
  },  
  computed: {
    ...mapGetters(['user', 'operators', 'organizers', 'competitions']),
    isAdmin() {
      return this.user.roles.includes('ROLE_ADMIN')
    },
    isOperator() {
      return this.user.operator;
    },
    isModerator() {
      return (
          this.user.roles.includes('ROLE_ADMIN')
          || this.user.roles.includes('ROLE_OPERATOR')
      );
    },    
    apiParams() {
      return  {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        name: this.search,
      }
    },
    items() {
        const list = [];
        for (const provision of this.data) {
            const provisionInfo = {
              id: provision.id,
              competition: provision.competition,
              competitionName: provision.competitionName,
              lastCountedPayments: new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(provision.lastCountedPayments),
              lastCountedProvision: new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(provision.lastCountedProvision),
              overDate: provision.overDate,
              payed: provision.payed,
              paymentDate: provision.paymentDate?.split('T')[0],
              phone: provision.organizerPhone,
              organizer: provision.organizerName,
            };
            list.push(provisionInfo);
        } 
        return list           
    },
    isPreviousPage() {
      return this.page > 1;
    },
    isNextPage() {
      // Pagination is blocked if search input is in use
      // return !this.searchUsers;
      return true
    },
  },
  async created() {
    this.debouncedSearchQuery = debounce(this.searchQuery, 500);
    this.getData();
  },
  methods: {
    async getData() {
      if(this.isAdmin) {
        const data = await axios({ method: 'get', url: `/competitions/provision` })
        this.data = data;
      } else {
        const data = await axios({ method: 'get', url: `/organizers/${this.isOperator}/provision` })
        this.data = data;
      }
      this.ready = true;
    },
    async changeUsersList(status) {
      this.ready = false;
      this.data = []
      this.listParent = status;
      this.page = 1;
      await this.getData();
    },  
    async searchQuery() {
      this.ready = false;
      this.page = 1
      const data = await axios({ method: 'get', url: `/competitions/provision/search?search=${this.search}` })
      this.data = data;
      this.ready = true;
    },
    menuClickHandler(event, id) {
      event.stopPropagation();
      if (this.menuCompetitionId === id) {
        // this.menuCompetitionId = null;
      } else {
        this.menuCompetitionId = id;
      }
    },
    closeMenu() {
      this.menuCompetitionId = null;
    },
    sendPaymentMessage(id) {
      axios({ method: 'post', url: `/competitions/provision/${id}/send` })
      this.$refs[id][0].querySelector('.send').style.display = 'none';
      this.$refs[id][0].querySelector('.sended').style.display = 'inline-block';
    },
    sendPaymentStatus(id, payed) {
      axios({ method: 'put', url: `/competitions/provision/${id}`, data: { payed: payed } })
      this.menuCompetitionId = null;
      this.items.find(item => item.id === id).payed = payed
    },
  }
}
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  gap: 32px;
}
.buttons-wrap .input-field {min-height: 40px;width: 100%;}
.buttons-wrap .admin-header__views {flex-shrink: 0}
.container-list {
  overflow: auto;
  margin-top: 46px;
  margin-bottom: 46px;
}
.list {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
}
.list >>> tbody {
  background-color: var(--color-white);
  color: var(--color-slate);
}
.list__row-data {
  padding: 10px;
  border-bottom: 1px solid rgba(84, 110, 122, 0.1);
  white-space: nowrap;
  height: 48px;
  box-sizing: border-box;
}
.list__row-header {
  text-align: left;
  padding-bottom: 8px;
  padding-left: 10px;
  color: var(--color-silver);
  font-weight: normal;
}
.list__row-data--sticky-right {
    position: sticky;
    right: 0;
    z-index: 1;
    max-width: 48px;
    min-width: 48px;
    background-color: var(--color-white);
    box-shadow: inset 1px 0px 0px #e5e5e5;
    cursor: pointer;
    opacity: 1 !important;
}
@media (min-width: 992px) {
  .list__row-data--name {
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.list__row-data--active {
  z-index: 10;
}
.list__row--disabled td {
  opacity: 0.5;
}
.list__row-data--loading {
  opacity: 0.1 !important;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}
.list__row--overtime {
  background: #f9dee5 !important;
}
.list__row--overtime .list__row-data--date,
.list__row--overtime .list__row-data--payed {
  color: #E62E53;
}
.list__row--payed {
  background: #e1f9e3;
}
.list__row--notpayed {
  background: #fff;
}
.icon {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 4px;
}
.more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.more-btn__content {
  position: absolute;
  top: 16px;
  right: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-pale-grey);
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 99;
}
.more-btn__content--from-up {
  bottom: 16px;
  top: unset;
}
.more-btn__content-btn {
  cursor: pointer;
  padding: 14px 8px;
  box-shadow: inset 0px -1px 0px #edf0f1;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.more-btn__content-btn:hover {
  background: #f7f9f9;
}
.more-btn__content-btn:last-of-type {
  box-shadow: none;
}
@media (min-width: 992px) {
  .list__row-data--sticky-right {
    box-shadow: none;
  }
  .container-list {
    overflow: visible;
  }
}
</style>
