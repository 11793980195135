import classification from '@/api/schema/classification';
import location from '@/api/schema/location';
import { schema } from 'normalizr';

export default new schema.Entity('trackpoints', {
    classifications: [classification],
    location,
}, {
    processStrategy: (trackpoint) => ({
        // Meta.
        _type: 'trackpoint',
        // Fields.
        ...trackpoint,
    }),
});
