<template>
  <div>
    <panel-competition-form-box class="info mab24">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-8 col-md-10">
            <div class="form__headline">Konfiguracja zaawansowana</div>
          </div>
          <div class="col-4 col-md-2">
            <base-button v-if="!advanced" @click="advanced = !advanced" btn2 short class="mal4 w100" icon="fa fa-caret-down">Rozwiń</base-button>
            <base-button v-else @click="advanced = !advanced" btn2 short class="mal4 w100" icon="fa fa-caret-up">Zwiń</base-button>
          </div>
        </div>
      </div>
      <div v-if="advanced" class="row">
        <div class="line"></div>
        <div class="col-12 col-lg-8">
          <div class="row justify-content-start">
            <div class="col-12 mab16">
              <p>Limity liczone według</p>
            </div>
            <div class="row align-items-center">
              <div class="col-4 col-md-3 col-lg-2">
                <panel-input-text
                  v-model="form.name"
                  class="mar16"
                />
              </div>
              <div class="col-8 col-md-9 col-lg-10">
                <p>Limit uczestników dla całych zawodów <span class="optional">(opcjonalnie)</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
            <p class="field-helper">
              Limity pozwalją ograniczyć ilość uczestników w zawodach. Po osiągnięciu limitu, możliwość rejestracji zostanie automatycznie zablokowana.
            </p>
        </div>
        <div class="row mat16 mab16">
          <div class="col-12 col-lg-8">
            <PanelInputBaseRadio 
              v-model="form.limit"
              name="formGender"
              :option="{ value: 'qwe', title: 'Zarejestrowanych uczestników' }"
            />
            <PanelInputBaseRadio 
              v-model="form.limit"
              name="formGender"
              :option="{ value: 'qwer', title: 'Opłaconych uczestników' }"
            />
            <div class="indent1 mat8 pat8">
              <PanelInputBaseRadio 
                v-model="form.limite"
                name="limite"
                :option="{ value: 'qwe1', title: 'Miękki limit' }"
              />
              <PanelInputBaseRadio 
                v-model="form.limite"
                name="limite"
                :option="{ value: 'qwer2', title: 'Twardy limit' }"
              />
            </div>
            <div class="indent2">
              <div class="row justify-content-start">
                <div class="col-12  mab8">
                  <p>Oczekiwanie na potwierdzenie płatności</p>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-md-3 col-lg-2">
                    <panel-input-text
                      v-model="form.paylimit"
                      class="mar16"
                    />
                  </div>
                  <div class="col-8 col-md-9 col-lg-10">
                    <p>Czas (w minutach), po którym płatność online zostanie przedawniona (3 minuty - 99 minut) <span class="optional">(opcjonalnie)</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
              <p class="field-helper">
                Miękki limit daje możliwość udostępnienia uczestnikom klasycznego przelewu jako formy płatności, jednak z powowdu opóźnień wpływania opłat, limit liczby uczestników może zostać przekroczony o uczestników płacących przelewem.
              </p>
          </div>
        </div>
        <div class="line"></div>
        <div class="row mat16 mab16">
          <div class="col-12 mab16">
            <p>Przydzielanie do kategorii według</p>
          </div>
          <div class="col-12 col-lg-8">
            <PanelInputBaseRadio 
              v-model="form.birthlimit"
              name="birthlimit"
              :option="{ value: 'qwe', title: 'Dokładnej daty urodzenia' }"
            />
            <PanelInputBaseRadio 
              v-model="form.birthlimit"
              name="birthlimit"
              :option="{ value: 'qwer', title: 'Roku urodzenia' }"
            />
          </div>
          <div class="col-12 col-lg-4">
            <p class="field-helper">Przydzielanie uczestników do kategorii, może odbywać się według dokładnej daty urodzenia lub samego roku urodzenia.</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="row mat16 mab16">
          <div class="col-12 mab16">
            <p>Wyświetlanie na liście startowej</p>
          </div>
          <div class="col-12 col-lg-8">
            <PanelInputBaseRadio 
              v-model="form.startlimit"
              name="startlimit"
              :option="{ value: 'qwe', title: 'Wszystkich uczestników' }"
            />
            <PanelInputBaseRadio 
              v-model="form.startlimit"
              name="startlimit"
              :option="{ value: 'qwer', title: 'Tylko opłaconych' }"
            />
          </div>
          <div class="col-12 col-lg-4">
            <p class="field-helper">Lista startowa może zawierać wszystkich zarejestrowanych uczestników, lub tylko tych, którzy dokonali płatności za start.</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="row mat16 mab16">
          <div class="col-12 col-lg-8">
            <div class="row justify-content-start">
              <div class="col-12 mab16">
                <p>Płatność odroczona</p>
              </div>
              <div class="row align-items-center">
                <div class="col-4 col-md-3 col-lg-2">
                  <panel-input-text
                    v-model="form.namqwee"
                    class="mar16"
                  />
                </div>
                <div class="col-8 col-md-9 col-lg-10">
                  <p>Ilość dni na opłatę od chwili rejestracji <span class="optional">(zawody płatne)</span></p>
                </div>
              </div>
            </div>
          </div> 
          <div class="col-12 col-lg-4">
            <p class="field-helper">Uczestnicy mogą mieć możliwość opłaty za start po dokonaniu rejestracji online. Aby zablokować płatność odroczoną, ustaw 0 dni.</p>
          </div>   
        </div>
        <div class="line"></div>
        <div class="col-lg-12 mat16">
          <div class="row justify-content-end">
            <div class="col-12 col-lg-4">
              <base-button
                  btn1
                  short
                  @click="save()"
              >
                  Zapisz
              </base-button>
            </div>
          </div>
        </div> 
      </div>       
    </panel-competition-form-box>
    <div class="classifications-wrap">
      <div class="classifications-items">
        <div class="classification is-active">
          <div class="classification-title">
            <div class="row">
              <div class="col-6 classification-label">Klasyfikacja dorosłych</div>
              <div class="col-6 text-right"><span class="badge mar8">bezpł.</span><span class="badge badge-positive">domyślna</span></div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="classification-name classification-text">Mężczyźni 10 km</div>
              </div>
            </div>
          </div>
          <div class="classification-content">
            <ul class="classification-content-list">
              <li @click="$refs.classificationAgeCategoryModal.openModal()"><span class="classification-content-list-item"><span class="classification-content-list-title"><i class="fas fa-caret-down"></i> Mężczyźni</span> <BaseTooltip :data="{ content: `W tym<br>opłaconych: 72<br>nieopłaconych: 23`, html: true}"><span class="classification-tooltip"><img src="@/assets/images/icons/shopping-trolley.svg" /> 95</span></BaseTooltip></span>
                <div>
                  <ul>
                    <li>Mężczyźni 18-29 <div class="count"><span>23</span></div></li>
                    <li>Mężczyźni 30-39 <div class="count"><span>31</span></div></li>
                    <li>Mężczyźni 40-49 <div class="count"><span>19</span></div></li>
                    <li>Mężczyźni 50-59 <div class="count"><span>13</span></div></li>
                    <li>Mężczyźni 60-99 <div class="count"><span class="badge">-15%</span><span>9</span></div></li>
                  </ul>
                </div>
              </li>  
              <li><span class="classification-content-list-item is-empty"><span class="classification-content-list-title"><i class="fas fa-plus-square"></i> <span>Kobiety</span></span> <span>brak kategorii</span></span></li>
              <li><span class="classification-content-list-item is-empty"><span class="classification-content-list-title"><i class="fas fa-plus-square"></i> <span>Kategorie specjalne</span></span> <span>brak kategorii</span></span></li>
            </ul>
          </div>
        </div>
        <div class="classification">
          <div class="classification-title">
            <div class="row">
              <div class="col-6 classification-label">Klasyfikacja dorosłych</div>
              <div class="col-6 text-right"><span class="badge">bezpłatna</span></div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="classification-name classification-text">Kobiety 10 km</div>
              </div>
            </div>
          </div>
          <div class="classification-content">
            <ul class="classification-content-list">
              <li><span class="classification-content-list-item"><span class="classification-content-list-title"><i class="fas fa-caret-right"></i> Kobiety</span> <BaseTooltip :data="{ content: `W tym<br>opłaconych: 72<br>nieopłaconych: 23`, html: true}"><span class="classification-tooltip"><img src="@/assets/images/icons/shopping-trolley.svg" /> 95</span></BaseTooltip></span></li>  
              <li><span class="classification-content-list-item is-empty"><span class="classification-content-list-title"><i class="fas fa-plus-square"></i> Mężczyźni</span> <span>brak kategorii</span></span></li>
              <li><span class="classification-content-list-item is-empty"><span class="classification-content-list-title"><i class="fas fa-plus-square"></i> Kategorie specjalne</span> <span>brak kategorii</span></span></li>
            </ul>
          </div>
        </div>

        <div class="classification-actions">
          <base-button btn2 short class="w100">Dodaj klasyfikację</base-button>
          <base-button btn2 short class="w100">Importuj z istniejących zawodów</base-button>
        </div>
      </div>
      <div class="classifications-content">
        <div class="classification-title">
          <div class="classification-category classification-label">Klasyfikacja dorosłych</div>
          <div class="classification-name classification-text">Nazwa klasyfikacji</div>
        </div>
        <div class="classification-actions">
          <base-button class="status" btn2 short status="positive">Klasyfikacja domyślna</base-button>
          <base-button btn2 short icon="fas fa-trash-alt">Usuń</base-button>
          <base-button btn2 short icon="fas fa-pencil-alt" @click="$refs.classificationModal.openModal()">Edytuj</base-button>
          <base-button-group horizontal shadow :border="false">
            <base-button btn2 short :radius="false" icon="fas fa-long-arrow-alt-up" ></base-button>
            <base-button btn2 short :radius="false" icon="fas fa-long-arrow-alt-down"></base-button>
          </base-button-group>
        </div>
        <div class="classification-headline">
          Parametry klasyfikacji
        </div>
        <div class="row mab32">
          <div class="col-6 col-md-4">
            <div class="classification-label">Skrót nazwy</div>
            <div class="classification-text text-bold">M15</div>
          </div>
          <div class="col-6 col-md-4">
            <div class="classification-label">Dystans</div>
            <div class="classification-text text-bold">10 km</div>
          </div>
          <div class="col-12 col-md-4">
            <div class="classification-label">Limit uczestników</div>
            <div class="classification-text text-bold">500</div>
          </div>
        </div>
        <div class="classification-headline">
          Daty
        </div>
        <div class="row mab32">
          <div class="col-6">
            <div class="classification-label">Zapisy otwarte do</div>
            <div class="classification-text text-bold">15.12.2023  23:59</div>
          </div>
          <div class="col-6">
            <div class="classification-label">Klasyfikacja trwa do</div>
            <div class="classification-text text-bold">01.12.2019</div>
          </div>
        </div> 
        <div class="classification-headline">
          Opłaty
        </div>
        <div class="row">
          <div class="col-12 col-md-4 mab24">
            <div class="classification-label">Opłata za start</div>
            <div class="classification-text text-bold">75,00 zł</div>
          </div>
          <div class="col-12 col-md-8">
            <div class="classification-label mab16">Opłaty promocyjne</div>
            <ul>
              <li>Opłata do 15.06.2023<span class="classification-text text-bold">50,00 zł</span></li>
              <li>Opłata do 15.08.2023<span class="classification-text text-bold">60,00 zł</span></li>
              <li>Opłata do 15.10.2023<span class="classification-text text-bold">65,00 zł</span></li>
            </ul>
          </div>
        </div>                       
      </div>
    </div>
    <modal ref="classificationModal" id="classificationModal" class="modal-background">
      <template v-slot:header>Edytuj klasyfikację</template>
      <template v-slot:body>
        <div class="form-body">
          <div class="form-row form-row-center">
            <div class="form-col form-col-3">
              <p>Typ klasyfikacji</p>
            </div>
            <div class="form-col form-col-9">
              <panel-input-radio
                v-model="form.gender"
                name="formGender"
                label=""
                :options="[
                    { value: 'M', title: 'dla dorosłych' },
                    { value: 'K', title: 'dla dzieci' },
                ]"
                :value="form.gender"
                class="nobg mab16"
                :error.sync="errors.gender"
              />
            </div>
          </div>
          <div class="hr__line">&nbsp;</div>
          <div class="form-row mab16">
            <div class="form-col form-col-6">
              <panel-input-text
                v-model="form.icePhone"
                label="Nazwa klasyfikacji"
                :error.sync="errors.icePhone"
              />
            </div>
            <div class="form-col form-mobile-6 form-col-3">
              <panel-input-text
                v-model="form.icePhone"
                label="Skrót nazwy"
                :error.sync="errors.icePhone"
              />
            </div>
            <div class="form-col form-mobile-6 form-col-3">
              <panel-input-text
                v-model="form.icePhone"
                label="Dystans"
                :error.sync="errors.icePhone"
              />
            </div>
          </div>
          <div class="hr__line">&nbsp;</div>
          <div class="form-row">
            <div class="form-col form-col-4">
              <panel-input-date
                v-model="form.birthTime"
                :value="form.birthTime"
                label="Zapisy otwarte do"
                placeholder="dd.mm.yyyy"
                class="mab16"
                :error.sync="errors.birthTime"
              />
            </div>
            <div class="form-col form-col-4">
              <panel-input-date
                v-model="form.birthTime"
                :value="form.birthTime"
                label="Klasyfikacja trwa do"
                placeholder="dd.mm.yyyy"
                class="mab16"
                :error.sync="errors.birthTime"
              />
            </div>
            <div class="form-col form-mobile-6 form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Limit uczestników"
                :error.sync="errors.icePhone"
              />
            </div>
          </div>   
          <div class="hr__line">&nbsp;</div>
          <div class="form-row form-row-center">
            <div class="form-col form-col-4">
              <p>Opłata za udział</p>
            </div>
            <div class="form-col form-col-8">
              <panel-input-switch
                v-model="form.pay"
                :value="form.pay"
                name="formStatus"
                label=""
                :options="[
                  { value: 'free', title: 'Klasyfikacja bezpłatna' },
                  { value: 'paid', title: 'Klasyfikacja płatna' },
                ]"
                :vBoolean="false"
                class="gap32 mab24"
                :error.sync="errors.pay"
              />
            </div>
          </div>
          <div class="form-row mat32 mab16">
            <div class="d-mobile-none form-col form-col-4">&nbsp;</div>
            <div class="form-col form-mobile-6 form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Opłata"
                :error.sync="errors.icePhone"
              />
            </div>
            <div class="form-col form-mobile-6 form-col-4 mat24">
              <p>Obowiązuje do<br/> dnia zawodów</p>
            </div>
          </div>                
          <div class="classification-paid-info">
            <i class="fas fa-money-bill-alt" />
            <p><b>Pobierasz opłatę startową?</b></p>
            <p>Za organizowanie zawodów z płatnymi klasyfikacjami pobieramy <b>6% prowizji od opłaty za udział</b>, którą pobierasz od uczestnika.</p>
            <p>Jeśli ustawisz opłatę za klasyfikację, wystawimy Ci fakturę VAT i wyślemy po dacie startu zawodów. Termin płatności faktury to 30 dni od daty startu zawodów.</p>
            <p>Przykład:<br />Organizujesz zawody z opłatą za udział w wysokości 20 zł. Jesli na zawody zapisze się 100 uczestników, otrzymasz od nas fakturę VAT na kwotę 120,00 zł (netto).</p>
            <p><b>Organizowanie zawodów bezpłatnych jest darmowe.</b></p>
            <button class="btn btn-primary">Akceptuję prowizję</button>
          </div> 
          <div class="hr__line">&nbsp;</div>
          <div class="form-row form-row-center">
            <div class="form-col form-col-4">
              <p>Opłaty promocyjne</p>
            </div>
            <div class="form-col form-col-8">
              <panel-input-radio
                v-model="form.gender"
                name="formGender"
                :options="[
                    { value: 'M', title: 'Limit uczestników' },
                    { value: 'K', title: 'Limit czasu' },
                ]"
                :value="form.gender"
                class="mab16 nobg"
                :error.sync="errors.gender"
              />
            </div>
          </div>
          <div class="form-row form-row-end">
            <div class="form-col form-col-4">
              <div style="display: flex;gap: 12px;align-items: center;">
                <div class="form-col-btn">
                  <i class="fas fa-trash" />
                </div>
                Stawka 1
              </div>
            </div>
            <div class="form-col form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Opłata"
                :error.sync="errors.icePhone"
                disabled
              />              
            </div>
            <div class="form-col form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Limit uczestników"
                :error.sync="errors.icePhone"
                disabled
              />              
            </div>
          </div>
          <div class="form-row form-row-end">
            <div class="form-col form-col-4">
              <div class="form-col-btn">Dodaj stawkę</div>
            </div>
            <div class="form-col form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Opłata"
                :error.sync="errors.icePhone"
              />              
            </div>
            <div class="form-col form-col-4">
              <panel-input-text
                v-model="form.icePhone"
                label="Limit uczestników"
                :error.sync="errors.icePhone"
              />              
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn" @click="$refs.classificationModal.closeModal()">Anuluj</button>
        <button class="btn btn-primary">Zapisz</button>
      </template>        
    </modal>
    <modal ref="classificationAgeCategoryModal" id="classificationAgeCategoryModal" class="modal-background">
      <template v-slot:header>Edytuj kategorie</template>
      <template v-slot:body>
        <ageCategoriesRangeSlider />
      </template>
      <template v-slot:footer>
        <!-- <div class="d-flex">
          <panel-input-switch-mini />
          <label>Zniżki opłaty startowej dla kategorii wiekowych</label>
        </div> -->
          <button class="btn" @click="$refs.classificationAgeCategoryModal.closeModal()">Anuluj</button>
          <button class="btn btn-primary">Zapisz</button>
      </template> 
    </modal>
  </div>
</template>

<script>
import BaseTooltip from '@/components/BaseTooltip';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import PanelInputText from '@/components/PanelInputText';
import PanelInputBaseRadio from '@/components/PanelInputBaseRadio';
import PanelInputRadio from '@/components/PanelInputRadio';
import PanelInputDate from '@/components/PanelInputDate';
import PanelInputSwitch from '@/components/PanelInputSwitch';
import Modal from '@/components/Modal';
import ageCategoriesRangeSlider from '@/components/ageCategoriesRangeSlider/RangeSlider';
import PanelInputSwitchMini from '@/components/PanelInputSwitchMini';

// import form from '@/mixins/form';

export default {
  components: {
    BaseTooltip,
    PanelCompetitionFormBox,
    PanelInputText,
    PanelInputRadio,
    PanelInputBaseRadio,
    PanelInputDate,
    PanelInputSwitch,
    PanelInputSwitchMini,
    Modal,
    ageCategoriesRangeSlider
  },
  // mixins: [form],
  data() {
    return {
      advanced: false,
      form: {},
      errors: {}
    };
  },
}
</script>

<style scoped>
.classifications-wrap {
  margin-bottom: 56px;
}
.line {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
}
.line::before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 60px);
  height: 1px;
  background: var(--color-bluish-grey);
}
.indent1 {
  position: relative;
  padding-left: 32px;
}
.indent1:before {
  display: block;
  position: absolute;
  content: '';
  width: 32px;
  height: 100%;
  left: 11px;
  top: 0;
  border-left: 1px solid var(--color-bluish-grey);
}

.indent2 {
  position: relative;
  padding-left: 64px;
}

.indent2:before {
  display: block;
  position: absolute;
  content: '';
  width: 32px;
  height: 100%;
  left: 11px;
  top: 0;
  border-left: 1px solid var(--color-bluish-grey);
}

.indent2:after {
  display: block;
  position: absolute;
  content: '';
  width: 32px;
  height: calc(100% - 16px);
  left: 11px;
  top: 16px;
  border-right: 1px solid var(--color-bluish-grey);
}

.badge {
  display: inline-flex;
  height: 24px;
  padding: 0 8px;
  background: var(--color-charcoal-grey);
  border-radius: 4px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.badge-positive {
  border: 1px solid var(--color-dark-pastel-green);
  background-color: var(--color-dark-pastel-green-10);
}

.badge-negative {
  border: 1px solid var(--color-lipstick);
  background: var(--color-lipstick-10);
}

.classifications-items,
.classifications-content {
  background-color: var(--color-slate);
  border-radius: 4px;
}

.classifications-items {
  padding: 8px;
}

.classifications-content {
  padding: 12px 16px 24px;
}

.classifications-items .classification-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.classifications-items .classification-title {
  padding: 16px 24px;
}
.classifications-content .classification-title {
  margin-bottom: 16px;
}

.classifications-content .classification-actions {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
}

.classification-headline {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid var(--color-bluish-grey);
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.classifications-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.classifications-content ul li {
  display: flex;
  justify-content: space-between;
}

.classifications-content ul li + li {
  margin-top: 8px;
}

.classification-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.classification-text {
  font-size: 16px;
  line-height: 24px;
}

.classification-name {
  font-size: 18px;
}

.text-bold {
  font-weight: 600;
}

.classification-tooltip {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text-right {
  text-align: right;
}

.classification-content-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.classification-content-list-item {
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.classification.is-active {
  background-color: var(--color-bg-650);
}
.classification.is-active .classification-title {
  background-color: var(--color-slate-two);
}
.classification-content-list-item.is-empty span:not(.classification-content-list-title) {
  opacity: 0.5;
}
.classification-content-list-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.classification-content-list-item .fas {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classification-content-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.classification-content-list ul  li {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0 24px 0 56px;
}

.classification-content-list .count {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
.classification-content-list .count span:not(.badge) {
  min-width: 32px;
  text-align: right;
}

.classification-paid-info {
  margin-top: 32px;
  padding: 36px 24px 24px 24px;
  color: var(--color-white);
  background-color: var(--color-slate-two);
}

.classification-paid-info i.fas {
  font-size: 48px;
  margin-bottom: 16px;
}

.classification-paid-info p {
  font-size: 14px;
  line-height: 24px;
}

.classification-paid-info p + p {
  margin-top: 16px;
}

.classification-paid-info .btn {
  margin-top: 16px
}

/* grind */
.form-row {margin-right: -16px; margin-left: -16px;display: flex;}
.form-row.out-gutter {padding-right: 16px; padding-left: 16px;}
.form-row.out-gutter > [class*='form-col-'] { padding-right: 0; padding-left: 0;}
.form-row:after, .form-row:before { display: table; content: " ";}
.form-row:after {clear: both;}
.form-col { position: relative;min-height: 1px;padding-right: 16px;padding-left: 16px;float: left;}
.form-row >>> p {color: #455A64 !important;line-height: 20px;}
.form-row-center { align-items: center; }
.form-row-end {align-items: flex-end;}
.hr__line {position:relative;height: 32px;}
.hr__line:after {content: '';position: absolute; top: 15px;left: 0;background-color: rgba(84, 110, 122, 0.1);width: 100%; height: 1px;}

.form-body::v-deep .panel-radio-field.nobg .panel-radio-field__wrap {box-shadow: none;background: transparent;}

#classificationAgeCategoryModal::v-deep .modal__footer {background-color: #DBE3E6;}

@media (max-width: 991px) {
  .classifications-content .classification-actions {
    flex-wrap: wrap;
  }
  .classifications-content .classification-actions .status {
    flex: 1 0 calc(100% - 88px);
    order: 1;
  }
  .classifications-content .classification-actions .border-box {
    order: 2;
  }
  .classifications-content .classification-actions > .btn--with-icon {
    flex: 1;
    order: 3;
  }
  .form-body {padding: 16px}
  .form-row {flex-wrap: wrap;}
  .d-mobile-none {display: none;}
  .form-col {width: 100%;margin-bottom: 16px;}
  .form-body::v-deep .panel-radio-field.gap32 .panel-radio-field__wrap {gap: 8px;}
  .form-body::v-deep .panel-radio-field.nobg .panel-radio-field__wrap {gap: 28px;flex-wrap: wrap;}
  .form-body::v-deep .panel-radio-field.nobg .panel-radio-wrapper {width: 100%;margin-left: 0;}
  .form-row {margin-left: -12px;margin-right: -12px;}
  .form-col {padding-left: 12px;padding-right: 12px;}
  .form-mobile-6 {width: calc(50% - 24px);}
  #classificationModal >>> .modal__footer {padding: 8px 16px 8px 16px;gap: 16px;}
  #classificationModal >>> .modal__footer .btn {padding: 0 16px;min-width: auto;}
  #classificationModal >>> .btn-primary {width: 100%;}
}

@media (min-width: 992px) {
  .classifications-wrap {
    display: flex;
    align-items: flex-start;
    gap: 32px;
  }
  .classifications-items {
    width: 368px;
  }
  .classifications-content {
    flex: 1;
  }
  .classifications-content {
    padding: 24px;
  }
  .classifications-content .classification-actions > .btn {
    flex: 1;
  }

  .classifications-content .classification-actions .status {
    flex-grow: 2;
    flex-shrink: 0;
  }
  .form-body {padding: 24px 32px}
  .form-col-3 {width: 25%;}
  .form-col-4 {width: 33.33333333%;}
  .form-col-6 {width: 50%;}
  .form-col-8 {width: 66.66666667%;}
  .form-col-9 {width: 75%;}
  .form-body::v-deep .panel-radio-field.gap32 .panel-radio-field__wrap {gap: 32px;}
  .form-body::v-deep .panel-radio-field.nobg .panel-radio-field__wrap {gap: 32px;justify-content: flex-end;}
  .form-body::v-deep .panel-radio-field.gap32 {margin-left: -12px;}

  #classificationAgeCategoryModal::v-deep .modal__dialog {width: 1032px}
  #classificationAgeCategoryModal::v-deep .modal__body {height: auto; padding: 24px 32px;}
}

</style>