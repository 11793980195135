var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row h100 header-box__person",
      class: { "header-box__person--tall": _vm.isTall },
    },
    [
      _vm.layout.logoLink
        ? _c("div", { staticClass: "col-12" }, [
            _c("img", {
              staticClass: "header-box__person-logo",
              attrs: { src: _vm.layout.logoLink },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row header-box__person-info" }, [
          _c("div", { staticClass: "header-box__person-phone" }, [
            _c("p", [_vm._v(_vm._s(_vm.say.organizer_phone))]),
            _c("strong", [_vm._v(_vm._s(_vm.person.phone))]),
          ]),
          _c(
            "div",
            {
              staticClass: "header-box__person-email",
              class: {
                "header-box__person-email--margin": !_vm.person.website,
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.say.organizer_email))]),
              _c("strong", [_vm._v(_vm._s(_vm.person.email))]),
            ]
          ),
          _vm.person.website
            ? _c(
                "div",
                { staticClass: "header-box__person-site" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "person-btn w100",
                      attrs: { btn6: "" },
                      on: {
                        click: function ($event) {
                          return _vm.openWebsite()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.websiteUrl) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }