import { SET_NOTIFICATION, CLEAR_NOTIFICATION } from '@/store/mutations.type';

export default {
    state: {
        notification: '',
    },
    getters: {
        notification: (state) => state.notification,
    },
    mutations: {
        [SET_NOTIFICATION](state, notification) {
            state.notification = notification;
        },
        [CLEAR_NOTIFICATION](state) {
            state.notification = null;
        },
    },
};
