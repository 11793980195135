var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", class: { "dropdown--dark": _vm.isPanel } },
    [
      _c(
        "div",
        {
          staticClass: "dropdown__button",
          class: {
            "dropdown__button--center dropdown__button--pointer":
              _vm.isExistUserRole,
            "dropdown__button--border-dark": _vm.isPanel,
          },
          on: {
            click: function ($event) {
              return _vm.navigateToUserProfile()
            },
          },
        },
        [
          _c("div", [
            _c("img", {
              staticClass: "dropdown__button-img mat7",
              attrs: {
                src: _vm.isPanel
                  ? require("@/assets/images/t-shirt-icon-white.svg")
                  : require("@/assets/images/t-shirt-icon.svg"),
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "dropdown__button-box" },
            [
              !_vm.isExistUserRole
                ? _c(
                    "base-button",
                    {
                      staticClass: "dropdown__button-create",
                      class: { "dropdown__button-create--white": _vm.isPanel },
                      attrs: { btn4: "" },
                      on: {
                        click: function ($event) {
                          return _vm.createUserRole()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.create_participant_profile) + " "
                      ),
                    ]
                  )
                : [
                    _c("input", {
                      staticClass: "d-none",
                      attrs: {
                        id: "participant",
                        type: "radio",
                        name: "participant",
                      },
                      domProps: { checked: !_vm.isPanel },
                    }),
                    _c("div", { staticClass: "dropdown__button-radio" }, [
                      _vm._v(" " + _vm._s(_vm.say.participant_profile) + " "),
                    ]),
                  ],
              !_vm.withoutHints && !_vm.isExistUserRole
                ? _c("div", { staticClass: "dropdown__button-description" }, [
                    _c("p", [_vm._v("• " + _vm._s(_vm.say.collect_results))]),
                    _c("p", [
                      _vm._v("• " + _vm._s(_vm.say.save_more_efficiently)),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dropdown__button",
          class: {
            "dropdown__button--center dropdown__button--pointer":
              _vm.isExistPanelRole,
            "dropdown__button--border-dark": _vm.isPanel,
          },
          on: {
            click: function ($event) {
              return _vm.navigateToPanel()
            },
          },
        },
        [
          _c("img", {
            staticClass: "dropdown__button-img mat7",
            attrs: {
              src: _vm.isPanel
                ? require("@/assets/images/stoper-icon-white.svg")
                : require("@/assets/images/stoper-icon.svg"),
            },
          }),
          _c(
            "div",
            { staticClass: "dropdown__button-box" },
            [
              !_vm.isExistPanelRole
                ? _c(
                    "base-button",
                    {
                      staticClass: "dropdown__button-create",
                      class: { "dropdown__button-create--white": _vm.isPanel },
                      attrs: { btn4: "" },
                      on: { click: _vm.createOrganizerRole },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.say.create_organizer_profile) + " "
                      ),
                    ]
                  )
                : [
                    _c("input", {
                      staticClass: "d-none",
                      attrs: {
                        id: "participant",
                        type: "radio",
                        name: "participant",
                      },
                      domProps: { checked: _vm.isPanel },
                    }),
                    _c("div", { staticClass: "dropdown__button-radio" }, [
                      _vm._v(" " + _vm._s(_vm.profileType) + " "),
                    ]),
                  ],
              !_vm.withoutHints && !_vm.isExistPanelRole
                ? _c("div", { staticClass: "dropdown__button-description" }, [
                    _c("p", [
                      _vm._v("• " + _vm._s(_vm.say.create_competition_site)),
                    ]),
                    _c("p", [
                      _vm._v("• " + _vm._s(_vm.say.run_registration_form)),
                    ]),
                    _c("p", [_vm._v("• " + _vm._s(_vm.say.measure_results))]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.isExistUserRole || _vm.isExistPanelRole
        ? _c(
            "div",
            {
              staticClass:
                "dropdown__button dropdown__button--center dropdown__button--pointer",
              class: { "dropdown__button--border-dark": _vm.isPanel },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "edit-credentials" })
                },
              },
            },
            [
              _c("img", {
                staticClass: "dropdown__button-img",
                attrs: {
                  src: _vm.isPanel
                    ? require("@/assets/images/edit-pencil-white.svg")
                    : require("@/assets/images/edit-pencil.svg"),
                },
              }),
              _vm._v(" " + _vm._s(_vm.say.change_password) + " "),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "dropdown__button dropdown__button--center dropdown__button--pointer",
          class: { "dropdown__button--border-dark": _vm.isPanel },
          on: {
            click: function ($event) {
              return _vm.logoutUser()
            },
          },
        },
        [
          _c("img", {
            staticClass: "dropdown__button-img",
            attrs: {
              src: _vm.isPanel
                ? require("@/assets/images/sign-out-white.svg")
                : require("@/assets/images/sign-out.svg"),
            },
          }),
          _vm._v(" " + _vm._s(_vm.say.logout) + " "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }