var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--track pointer",
      on: {
        click: function ($event) {
          _vm.clickHandler(), _vm.$emit("click")
        },
      },
    },
    [
      _vm.value.length
        ? _c("p", { staticClass: "input-field__file-name" }, [
            _vm._v(" " + _vm._s(_vm.value[0].name) + " "),
          ])
        : _c("p", { staticClass: "input-field__hint" }, [
            _vm._v(" " + _vm._s(_vm.placeholder) + " "),
          ]),
      _c("input", {
        ref: "file",
        staticClass: "input-field__input-file",
        attrs: {
          type: "file",
          multiple: _vm.isMultiple,
          accept: _vm.accept,
          disabled: _vm.disabled,
        },
        on: {
          change: function ($event) {
            return _vm.inputFileHandler($event)
          },
        },
      }),
      _c("base-button", {
        staticClass: "btn-add",
        attrs: { btn2: "", icon: "fas fa-plus", disabled: _vm.disabled },
        on: {
          click: function ($event) {
            return _vm.clickHandler()
          },
        },
      }),
      _c("span", { staticClass: "input-error input-error--show" }, [
        _vm._v(_vm._s(_vm.say[_vm.error] || _vm.error)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }