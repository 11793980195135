var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.carouselItemClasses },
    [
      _c("router-link", { attrs: { to: _vm.link } }, [
        _c("div", { staticClass: "home-promote__competition" }, [
          _vm.competition
            ? _c("img", {
                staticClass: "home-promote__competition-poster",
                attrs: { src: _vm.layout.posterLink },
              })
            : _c(
                "div",
                {
                  staticClass:
                    "home-promote__competition-poster home-promote__competition-poster--skeleton",
                },
                [
                  _c(
                    "div",
                    { staticClass: "lds-ellipsis lds-ellipsis--bottom-left" },
                    [_c("div"), _c("div"), _c("div")]
                  ),
                ]
              ),
          _vm.competition
            ? _c(
                "div",
                { staticClass: "home-promote__competition-info row mat24" },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "p",
                      { staticClass: "home-promote__competition-category" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("asType")(_vm.competition.type)) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "h3",
                      { staticClass: "home-promote__competition-title" },
                      [_vm._v(" " + _vm._s(_vm.competition.name) + " ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "home-promote__competition-info--bottom" },
                    [
                      _c(
                        "p",
                        { staticClass: "home-promote__competition-place" },
                        [_vm._v(" " + _vm._s(_vm.competition.location) + " ")]
                      ),
                      _c(
                        "p",
                        { staticClass: "home-promote__competition-date" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("asTime")(_vm.competition.startedTime)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "home-promote__competition-hint" },
                        [
                          _c("i", { staticClass: "fas fa-info-circle f16" }),
                          _c("p", { staticClass: "d-inline mal8" }, [
                            _vm._v(
                              " " + _vm._s(_vm.say.promoted_competition) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _c("div", { staticClass: "row mat24" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3 loading-text" }),
                  ]),
                  _c("div", { staticClass: "row mat12" }, [
                    _c("div", { staticClass: "col-9 loading-text" }),
                  ]),
                  _c("div", { staticClass: "row mat16" }, [
                    _c("div", { staticClass: "col-5 loading-text" }),
                  ]),
                  _c("div", { staticClass: "row mat36" }, [
                    _c("div", { staticClass: "col-6 loading-text" }),
                  ]),
                  _c("div", { staticClass: "row mat18" }, [
                    _c("div", { staticClass: "col-11 loading-text" }),
                  ]),
                  _c("div", { staticClass: "row mat22 mab46" }, [
                    _c("div", { staticClass: "col-9 loading-text" }),
                  ]),
                ]),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "scroll-point" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }