<template>
    <div
        class="filters-datepicker"
        :class="{ 'input-field--error': error, 'input-field--short': isShort }"
    >
        <datepicker
            v-model="date"
            monday-first
            format="dd.MM.yyyy"
            :wrapper-class="
                border
                    ? 'filters-datepicker__wrapper-class border'
                    : 'filters-datepicker__wrapper-class'
            "
            calendar-button-icon="far fa-calendar"
            input-class="filters-datepicker__input"
            :language="pl"
            :placeholder="placeholder"
            :class="{
                'filters-datepicker__input-error':
                    error && !withoutErrorMessage,
            }"
            :initial-view="initialView"
            :open-date="openDate"
        />

        <i class="far fa-calendar filters-datepicker__calendar-icon" />

        <span
            class="input-error"
            :class="{ 'input-error--show': error && !withoutErrorMessage }"
        >{{ say[error] || error }}</span>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { pl } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import input from '@/mixins/input';
import { mapGetters } from 'vuex';

export default {
    components: {
        Datepicker,
    },
    mixins: [input],
    props: {
        border: {
            type: Boolean,
            default: false,
        },
        initialView: {
            type: String,
            default: 'day'
        },
        openDate: {
            type: Date,
            default: function () { return new Date() }
        }
    },
    data() {
        return {
            pl,
        };
    },
    computed: {
        ...mapGetters(['say']),
        date: {
            get() {
                return this.value?.valueOf();
            },
            set(date) {
                this.$emit('input', moment(date));
            },
        },
    },
};
</script>

<style scoped>
.border {
    margin: 0;
    box-shadow: none;
    border: solid 1px var(--color-slate-two);
}
</style>
