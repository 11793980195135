var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.isMobile ? "padding-bottom: 48px;" : null },
    [
      !_vm.isOnline
        ? _c("div", { staticClass: "network-info network-info--sticky" }, [
            _vm._m(0),
          ])
        : _vm._e(),
      _c("base-notification-global"),
      _vm.$route.name !== "stoper-demo" ? _c("layout-front-header") : _vm._e(),
      _c("router-view"),
      _c("layout-front-footer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", { staticClass: "align-center" }, [
          _vm._v(" Jesteś w trybie offline. "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }