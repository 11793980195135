<template>
    <div>
        <div class="competition-edit">
            <panel-competition-form
                v-model="competitionToEdit"
                :validation.sync="errors"
                @save="saveCompetition()"
            />
        </div>
    </div>
</template>

<script>
import PanelCompetitionForm from '@/components/PanelCompetitionForm';
import { pick } from '@/utils/object';
import { mapActions } from 'vuex';
import { UPDATE_COMPETITION } from '@/store/actions.type';

export default {
    components: {
        PanelCompetitionForm,
    },
    props: {
        competition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            competitionToEdit: {},
            errors: {},
        };
    },
    created() {
        this.competitionToEdit = pick(this.competition, [
            'id',
            'name',
            'descriptionPl',
            'location',
            'locationLat',
            'locationLng',
            'provisionTime',
            'remarksPl',
            'startedTime',
            'type',
            'websitePl',
            'isRemote',
            'preStartedTime',
            'contactEmail',
            'contactPhone',
        ]);
    },
    methods: {
        ...mapActions([UPDATE_COMPETITION]),
        async saveCompetition() {
            try {
                await this.UPDATE_COMPETITION(this.competitionToEdit);
                this.$router.push({
                    name: 'panel-competition-dashboard',
                    params: this.competitionToEdit,
                    alias: true,
                    query: { showInfo: true }
                });
            } catch (e) {
                this.errors = e;
            }
        },
    },
};
</script>
<style scoped>
.competition-edit {
    margin-left: 0;
}
@media (min-width: 992px) {
    .competition-edit {
        margin-left: 30px;
    }
}
</style>
