var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "panel-radio-base-field",
      attrs: { role: "group", "aria-labelledby": _vm.name },
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.innerValue,
            expression: "innerValue",
          },
        ],
        key: _vm.option.value,
        attrs: {
          id: _vm.option.value || _vm.option.title,
          type: "radio",
          name: _vm.name,
        },
        domProps: {
          value: _vm.option.value,
          checked: _vm._q(_vm.innerValue, _vm.option.value),
        },
        on: {
          change: function ($event) {
            _vm.innerValue = _vm.option.value
          },
        },
      }),
      _c(
        "label",
        {
          key: _vm.option.title,
          attrs: { for: _vm.option.value || _vm.option.title },
        },
        [_vm._v(_vm._s(_vm.say[_vm.option.title] || _vm.option.title))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }