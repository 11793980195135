/* eslint-disable import/prefer-default-export */
export function getRouteToPanel(user) {
    if (user.roles.includes('ROLE_ADMIN')) {
        return { name: 'panel-admin', alias: true };
    }
    if (user.roles.includes('ROLE_OPERATOR')) {
        return { name: 'panel-operator', alias: true };
    }
    if (user.roles.includes('ROLE_ORGANIZER')) {
        return { name: 'panel-organizer', alias: true };
    }
    return { name: 'authentication-create-organizer', alias: true };
}

export function getRouteByLoginTo(user) {
    if (user.loginTo === 'panel') {
        return getRouteToPanel(user);
    }
    return { name: 'user-competitions-future', alias: true };
}
