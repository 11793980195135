<template>
<div v-if="ready" class="container">
    <div class="row mat24">
        <div class="col-lg-9 col-12 dashboard">
            <div class="dashboard-wrapper">
                <panel-novelty-boxes />
                <div class="update-results update-results__dashboard" v-if="showUpdateInfo">
                    <div class="update-results-content">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.1429 20.5625C17.1429 20.7232 17.0179 20.8571 16.8571 20.8571H15.1429C14.9821 20.8571 14.8571 20.7232 14.8571 20.5625V18.8661C14.8571 18.7054 14.9821 18.5714 15.1429 18.5714H16.8571C17.0179 18.5714 17.1429 18.7054 17.1429 18.8661V20.5625ZM17.125 17.2232C17.1161 17.3393 16.9821 17.4286 16.8214 17.4286H15.1696C15 17.4286 14.8661 17.3393 14.8661 17.2232L14.7143 13.1429C14.7143 13.0893 14.7411 13 14.8036 12.9554C14.8571 12.9107 14.9375 12.8571 15.0179 12.8571H16.9821C17.0625 12.8571 17.1429 12.9107 17.1964 12.9554C17.2589 13 17.2857 13.0714 17.2857 13.125L17.125 17.2232ZM17 8.88393C16.8036 8.51786 16.4196 8.28571 16 8.28571C15.5804 8.28571 15.1964 8.51786 15 8.88393L8.14286 21.4554C7.94643 21.8036 7.95536 22.2321 8.16071 22.5804C8.36607 22.9286 8.74107 23.1429 9.14286 23.1429H22.8571C23.2589 23.1429 23.6339 22.9286 23.8393 22.5804C24.0446 22.2321 24.0536 21.8036 23.8571 21.4554L17 8.88393Z" fill="#FED25A"/>
                        </svg>
                        <b>Zmiany zostaną opublikowane do ~5 minut.</b>
                        <div
                            class="update-results-close"
                            @click="showUpdateInfo = false"
                        >
                            <i class="fas fa-times" />
                        </div>
                    </div>
                </div>
                <div
                    class="dashboard-link dashboard-link--without-margin"
                >
                    <p class="dashboard-link__label dashboard-link__label--">
                        Pod tym adresem użytkownicy mogą się zarejestrować i
                        zobaczyć wyniki zawodów
                    </p>
                    <base-link-input
                        class="dashboard-link__value"
                        :link="competitionPageLink"
                        button-name="Otwórz"
                        type="open"
                    />
                </div>
                <div class="dashboard-charts none-desktop">
                    <chart-competition-players
                        class="dashboard-charts__item"
                        :labels="dataCounters.chartLables"
                        :background-colors="dataCounters.chartBackgrounds"
                        :data="dataCounters.registerCounter"
                        description="zarejestrowanych uczestników"
                        :summary="competitionPlayerCount.playersCount"
                    />
                    <chart-competition-players
                        class="dashboard-charts__item"
                        :labels="dataCounters.chartLables"
                        :background-colors="dataCounters.chartBackgrounds"
                        :data="dataCounters.paidCounter"
                        description="opłaconych uczestników"
                        :summary="competitionPlayerCount.playersPayCount"
                    />
                    <div
                        v-if="paymentCount.value !== null"
                        class="dashboard-charts__info"
                    >
                        <span class="dashboard-charts__info-price">
                            {{ paymentCount.value }} zł
                        </span>
                        <span
                            class="dashboard-charts__info-description"
                        >zebranych wpłat</span>
                    </div>
                </div>
                <!-- Competition info. -->
                <div class="dashboard-info">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <p class="dashboard-info__label">
                                Lokalizacja
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.location }}
                            </p>
                        </div>
                        <div class="col-lg-3 col-6">
                            <p class="dashboard-info__label">
                                Data zawodów
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.startedTime | asDate }}
                                {{
                                    isMobile
                                        ? asHour(competition.startedTime)
                                        : ''
                                }}
                            </p>
                        </div>
                        <div class="col-lg-3 col-6">
                            <p class="dashboard-info__label">
                                Zapisy online do
                                <span class="none-mobile">dnia</span>
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.provisionTime | asDate }}
                                {{
                                    isMobile
                                        ? asHour(competition.provisionTime)
                                        : ''
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <p class="dashboard-info__label">
                                Kategoria zawodów
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.type | asType }}
                            </p>
                        </div>
                        <div class="col-3 none-mobile">
                            <p class="dashboard-info__label">
                                Godzina startu
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.startedTime | asHour }}
                            </p>
                        </div>
                        <div class="col-3 none-mobile">
                            <p class="dashboard-info__label">
                                Zapisy online do godziny
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.provisionTime | asHour }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="dashboard-info__label">
                                Oficjalna strona zawodów
                            </p>
                            <p
                                class="dashboard-info__value dashboard-info__value--pointer"
                                @click="openSite(competition.websitePl)"
                            >
                                {{ competition.websitePl }}
                            </p>
                        </div>
                        <div class="col-3">
                            <p class="dashboard-info__label" />
                            <p class="dashboard-info__value" />
                        </div>
                        <div class="col-3">
                            <p class="dashboard-info__label" />
                            <p class="dashboard-info__value" />
                        </div>
                    </div>

                    <div class="row">
                        <div
                            v-if="cycle"
                            class="col-6"
                        >
                            <p class="dashboard-info__label">
                                Cykl zawodów
                            </p>
                            <p class="dashboard-info__value">
                                {{ cycle.name }}
                            </p>
                        </div>
                        <div
                            v-if="competition.apiKey"
                            class="col-6"
                        >
                            <p class="dashboard-info__label">
                                Klucz API do zawodów
                            </p>
                            <p class="dashboard-info__value">
                                {{ competition.apiKey }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Competition description -->
                <div class="row mab36">
                    <p class="dashboard-info__label">
                        Opis zawodów
                    </p>
                    <div
                        ref="competitionDescription"
                        class="dashboard-info__description"
                        :class="{
                            'dashboard-info__description--show-all':
                                showAllDescription || !isOverloadedDescription,
                        }"
                        v-html="competition.descriptionPl"
                    />
                    <div
                        v-if="isOverloadedDescription"
                        class="dashboard-info__more"
                        @click="showAllDescription = !showAllDescription"
                    >
                        {{
                            showAllDescription ? 'Pokaż mniej' : 'Pokaż więcej'
                        }}
                    </div>
                </div>
                <!-- Competition important message. -->
                <div
                    v-if="competition.remarksPl"
                    class="row"
                >
                    <div class="col-12">
                        <p class="dashboard-info__label">
                            Ważny komunikat dla uczestników
                        </p>
                        <div class="dashboard-info__value mab36">
                            {{ competition.remarksPl }}
                        </div>
                    </div>
                </div>
                <!-- Classifications -->
                <div class="row mab150">
                    <div class="col-12">
                        <p class="dashboard-info__value mab16">
                            Klasyfikacje
                        </p>
                    </div>
                    <div class="col-12">
                        <base-item-classification
                            v-for="id of classificationsIds"
                            :id="id"
                            :key="id"
                            :competition-id="competition.id"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 dashboard__chart none-mobile">
            <chart-competition-players
                class="mat16"
                :labels="dataCounters.chartLables"
                :background-colors="dataCounters.chartBackgrounds"
                :data="dataCounters.registerCounter"
                description="zarejestrowanych uczestników"
                :summary="competitionPlayerCount.playersCount"
            />
            <chart-competition-players
                class="mat24"
                :labels="dataCounters.chartLables"
                :background-colors="dataCounters.chartBackgrounds"
                :data="dataCounters.paidCounter"
                description="opłaconych uczestników"
                :summary="competitionPlayerCount.playersPayCount"
            />
            <div
                v-if="paymentCount.value !== null"
                class="dashboard-charts__info"
            >
                <span class="dashboard-charts__info-price">
                    {{ paymentCount.value }} zł
                </span>
                <span
                    class="dashboard-charts__info-description"
                >zebranych wpłat</span>
            </div>
            <div v-if="paymentDetails && paymentDetails.lastCountedProvision > 0" class="paymentDetailsBox" :class="{'overdate': paymentDetails.overDate}">
                <div>
                    <p>opłata za korzystanie z systemu</p>
                    <span class="price">{{ new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(paymentDetails.lastCountedProvision) }}</span>
                </div>
                <div>
                    <p>termin płatności {{ paymentDetails.paymentDate.split('T')[0] }}</p>
                    <base-button @click="$router.push({ name: 'panel-provision', alias: true,});" btn2>Przejdź do opłat</base-button>
                    <p>lub zadzwoń 502 678 564</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import PanelNoveltyBoxes from '@/components/PanelNoveltyBoxes';
import { mapActions, mapGetters } from 'vuex';
import {
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_CLASSIFICATIONS_AGES,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
    READ_COMPETITION_LIMITS,
    READ_COMPETITION_COUNT,
    READ_COMPETITION_PAYMENT_COUNT,
    READ_COMPETITION_CATEGORY_COUNT,
} from '@/store/actions.type';
import Cookies from 'js-cookie';
import ChartCompetitionPlayers from '@/components/ChartCompetitionPlayers';

export default {
    components: {
        PanelNoveltyBoxes,
        ChartCompetitionPlayers,
    },
    props: {
        competition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAllDescription: false,
            ready: false,
            classificationsIds: null,
            isOverloadedDescription: false,
            isClickedCloseNovelty: false,
            showUpdateInfo: this.$route.query.showInfo ? this.$route.query.showInfo : false,
            paymentDetails: null
        };
    },
    computed: {
        ...mapGetters([
            'user',
            'cycles',
            'competitionPlayerCounts',
            'categories',
            'paymentCounts',
            'categoryCounts',
        ]),
        isAdmin() {
            return this.user.roles.includes('ROLE_ADMIN')
        },
        cycle() {
            return this.cycles[this.competition.cycle];
        },
        isClosedNoveltyCookie() {
            return Cookies.get('novelty-stoper');
        },
        competitionPageLink() {
            const host = window.location.origin;
            const { href } = this.$router.resolve({
                name: 'minisite-landing',
                params: this.competition,
                alias: true,
            });

            return `${host}${href}`;
        },
        isMobile() {
            return ['sm', 'md'].includes(this.$mq);
        },
        competitionPlayerCount() {
            return this.competitionPlayerCounts[this.competition.id];
        },
        paymentCount() {
            return this.paymentCounts[this.competition.id];
        },
        competitionCategoriesIds() {
            const competitionCategories = Object.values(
                this.categories,
            ).filter((item) => this.classificationsIds.includes(item.classification));
            return competitionCategories.map((item) => item.id);
        },
        competitionCategoryCounts() {
            return Object.values(this.categoryCounts).filter((item) => this.competitionCategoriesIds.includes(item.category));
        },
        dataCounters() {
            const chartLables = [];
            const paidCounter = [];
            const registerCounter = [];
            const chartBackgrounds = [];
            const red = '#ff1744';
            const orange = '#FF3D00';
            for (const count of this.competitionCategoryCounts) {
                paidCounter.push(count.playersPayCount);
                registerCounter.push(count.playersCount);
                chartLables.push(this.categories[count.category].namePl);
                chartBackgrounds.push(red);
                chartBackgrounds.push(orange);
            }
            return {
                chartLables,
                paidCounter,
                registerCounter,
                chartBackgrounds,
            };
        },
    },
    async created() {
        const [{ classifications }] = await Promise.all([
            this.READ_COMPETITION_CLASSIFICATIONS(this.competition.id),
            this.READ_COMPETITION_CLASSIFICATIONS_AGES(this.competition.id),
            this.READ_COMPETITION_LIMITS(this.competition.id),
            this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.competition.id),
            this.READ_COMPETITION_COUNT(this.competition.id),
            this.READ_COMPETITION_PAYMENT_COUNT(this.competition.id),
            this.READ_COMPETITION_CATEGORY_COUNT(this.competition.id),
        ]);
        this.classificationsIds = classifications;
        this.getPaymentDetails()
        this.ready = true;
        this.$nextTick(() => {
            this.isOverloadedDescription = this.checkIsOverloadedDescription();
        });
    },
    methods: {
        ...mapActions([
            READ_COMPETITION_CLASSIFICATIONS,
            READ_COMPETITION_CLASSIFICATIONS_AGES,
            READ_COMPETITION_LIMITS,
            READ_COMPETITION_CLASSIFICATIONS_LIMITS,
            READ_COMPETITION_COUNT,
            READ_COMPETITION_PAYMENT_COUNT,
            READ_COMPETITION_CATEGORY_COUNT,
        ]),
        asHour(date) {
            return this.$options.filters.asHour(date);
        },
        openSite(site) {
            window.open(site, '_blank');
        },
        checkIsOverloadedDescription() {
            return this.$refs.competitionDescription?.offsetHeight > 180;
        },
        closeNoveltyMessage() {
            this.isClickedCloseNovelty = true;
            Cookies.set('novelty-stoper', 'closed', { expires: 14 });
        },
        async getPaymentDetails() {
            const data = await axios({ method: 'get', url: `/competitions/${this.competition.id}/count/provision` })
            this.paymentDetails = data;
        }
    },
};
</script>

<style scoped>
.dashboard {
    overflow: hidden;
}
.dashboard-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 40px;
    color: var(--color-silver);
}
.dashboard-charts__item {
    width: calc(50vw - 32px);
    max-width: 320px;
}
.dashboard-charts__info {
    margin-top: 54px;
    width: 100%;
    text-align: center;
}
.dashboard-charts__info-price {
    font-size: 32px;
    line-height: 35px;
    font-family: 'Bariol Bold';
    font-weight: 700;
    margin-right: 12px;
}
.dashboard-charts__info-description {
    line-height: 19px;
}
.dashboard-link {
    margin-top: 24px;
}
.dashboard-link--without-margin {
    margin-top: 0;
}
.dashboard-link__label {
    font-size: 12px;
    color: var(--color-silver);
    line-height: 1.33;
    margin-bottom: 8px;
}

.dashboard-info {
    border-top: 1px solid var(--color-slate-two);
    border-bottom: 1px solid var(--color-slate-two);
    margin-top: 78px;
    padding-top: 8px;
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.dashboard-info__label {
    line-height: 1.43;
    color: var(--color-silver);
    margin-bottom: 4px;
    margin-top: 16px;
}
.dashboard-info__value {
    font-size: 16px;
    line-height: 1.5;
}
.dashboard-info__description {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 4px;
    max-height: 180px;
    overflow: hidden;
    border-bottom: 1px solid var(--color-slate-two);
    padding-bottom: 10px;
    position: relative;
}
.dashboard-info__description:not(.dashboard-info__description--show-all)::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
        180deg,
        rgba(55, 71, 79, 0) 0%,
        rgba(55, 71, 79, 1) 100%
    );
}
.dashboard-info__description--show-all {
    max-height: unset;
}
.dashboard-info__more {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: solid 1px var(--color-slate-two);
    padding: 8px 24px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border-top: none;
}
.dashboard-info__value--pointer {
    cursor: pointer;
}
.dashboard__chart {
    max-width: var(--col-3);
}
@media (min-width: 992px) {
    .dashboard-wrapper {
        margin-right: 30px;
    }
    .dashboard-info {
        margin-top: 40px;
        padding-top: 16px;
        padding-bottom: 32px;
        margin-bottom: 32px;
    }
    .dashboard-link__value >>> .link-input__btn {
        max-width: 166px;
    }
    .dashboard-info__label {
        margin-top: 16px;
    }
}
.update-results__dashboard {
    position: relative;
    margin-top: 0;
    margin-bottom: 24px;
    width: auto;
}
.update-results-close {
    position: absolute;
    top: 10px;
    right: 15px;
}
.paymentDetailsBox >>> .btn--2 {
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 8px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}
.paymentDetailsBox {
    margin-top: 40px;
    background: #455A64;
    border-radius: 4px;
    padding: 12px 12px 4px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.paymentDetailsBox p {
    margin-bottom: 8px;
}
.paymentDetailsBox .price {
    font-family: "Bariol Bold";
    font-size: 24px;
    line-height: 26px;
}
.paymentDetailsBox div {
    width: 100%;
}
.paymentDetailsBox div + div {
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid #546E7A;
}
.paymentDetailsBox.overdate {
    background: #FFF1CC;
    color: #455A64;
}
</style>
