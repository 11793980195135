<template>
    <div class="container">
        <div class="row justify-content-center minisite-paylink__confirmation">
            <div class="col-lg-3 col-12 justify-content-center">
                <img src="@/assets/images/illustration-link-in-email.svg">
            </div>
            <div class="col-lg-4 col-12 minisite-pay__send-info">
                <div class="row mab24">
                    <div class="col-12">
                        <h3>{{ say.sent_paylink }}</h3>
                    </div>
                </div>
                <div class="row mat12">
                    <div class="col-12 col-lg-11">
                        <p>{{ say.open_paylink_hint }}</p>
                    </div>
                    <div class="col-12 col-lg-11 mat16">
                        <p>{{ say.no_received_paylink_hint }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mat16 minisite-pay__back-to-list justify-content-center">
            <div class="col-lg-2" />
            <div class="col-12 col-lg-3">
                <base-button
                    btn2
                    class="text-upper"
                    @click="$router.push({name: 'minisite-list', params: competition, alias: true})"
                >
                    {{ say.back_to_start_list }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BOOTSTRAP_MINISITE } from '@/store/actions.type';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['say', 'competitions', 'minisiteId']),
        competition() {
            return this.competitions[this.minisiteId];
        },
    },
    async created() {
        await this.BOOTSTRAP_MINISITE(this.id);

        this.ready = true;
    },
    methods: {
        ...mapActions([BOOTSTRAP_MINISITE]),
    },
};
</script>
