<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="payment-result">
          <div class="payment-result-content">
            <img src="@/assets/images/icons/checkmark-circle.svg" alt="">
            <h3>Płatność zakończona sukcesem</h3>
            <p>Wysłaliśmy wiadomości SMS do uczestników</p>
          </div>
          <div class="payment-result-buttons">
            <base-button @click="goToPage('panel-competition-result')" btn9>Przejdż do strony z wynikami</base-button>
            <base-button @click="goToPage('panel-competition-sms')" btn9>Przejdź do wysłanych widomości</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'panel-competition-payment-result',
  computed: {
    ...mapGetters(['competitions']),
    competition() {
        return this.competitions[this.$route.params.id];
    },  
  },  
  methods: {
    goToPage(name) {
      this.$router.push({
        name,
        params: this.competition,
        alias: true,
      });
    }
  }
}
</script>

<style scoped>
.payment-result {
  margin-top: 48px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #455A64;
  overflow: hidden;
}
.payment-result-content {
  padding: 48px 0;
  text-align: center;
}
.payment-result-content h3 {
  margin-top: 24px;
  margin-bottom: 8px;
}
.payment-result-buttons {
  background: #ECEFF1;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.payment-result-buttons .btn {
  min-width: 268px;
}
@media screen and (max-width: 767px) {
  .payment-result-buttons .btn {
    width: 90%;
  }
  .payment-result-buttons .btn + .btn { 
    margin-top: 8px;
  }
}
@media screen and (min-width: 768px) {
  .payment-result-buttons .btn + .btn { 
    margin-left: 16px;
  }
}
</style>