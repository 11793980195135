<template>
    <div>
        <!-- Empty state. -->
        <div v-if="empty">
            <div class="info-box--grey pa16 matb15">
                <h3>{{ say.empty_past }}</h3>
                <p>{{ say.you_have_no_past_competitions }}</p>
                <base-button
                    class="btn-proposed"
                    btn4
                    @click="$router.push({name: 'user-competitions-proposed', alias: true})"
                >
                    {{ say.see_proposed_competitions }}
                </base-button>
            </div>
        </div>

        <!-- Competitions list. -->
        <base-item-competition
            v-for="id in competitionsIds"
            :id="id"
            :key="id"
        />

        <!-- Read more button. -->
        <base-button
            v-if="hasMore"
            class="btn-findmore"
            btn4
            @click="readMoreCompetitions"
        >
            {{ say.see_more }}
        </base-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    READ_USER_COMPETITIONS,
    READ_COMPETITION_PLAYERS,
} from '@/store/actions.type';

export default {
    data() {
        return {
            competitionsIds: [-1],
            hasMore: true,
        };
    },
    computed: {
        ...mapGetters(['say']),
        empty() {
            return this.competitionsIds.length === 0;
        },
    },
    created() {
        this.readMoreCompetitions();
    },
    methods: {
        ...mapActions([READ_USER_COMPETITIONS, READ_COMPETITION_PLAYERS]),
        async readMoreCompetitions() {
            const { competitions } = await this.READ_USER_COMPETITIONS({
                type: 'past',
                offset: this.competitionsIds.length,
                limit: 10,
            });

            this.competitionsIds = competitions;
            this.hasMore = competitions.length > 0;
        },
    },
};
</script>

<style scoped>
.btn-proposed {
    margin-bottom: 16px;
    margin-top: 60px;
    max-width: 263px;
    background-color: var(--color-white);
}
.btn-findmore {
    margin: 30px 0;
    box-sizing: border-box;
}

@media (min-width: 992px) {
    .btn-findmore {
        width: 263px;
    }
}
</style>
