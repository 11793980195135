var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", { staticClass: "mab24" }, [_vm._v(_vm._s(_vm.say.more))]),
    _c("h4", { staticClass: "mab12" }, [
      _vm._v(_vm._s(_vm.say.access_account_settings)),
    ]),
    _c(
      "div",
      { staticClass: "bsh mab24" },
      [
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.$router.push({
                  name: "edit-credentials",
                  alias: true,
                })
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.change_password))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/chevron-right.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ],
      1
    ),
    _c("h4", { staticClass: "mab12" }, [
      _vm._v(_vm._s(_vm.say.participant_profile_settings)),
    ]),
    _c(
      "div",
      { staticClass: "bsh mab24" },
      [
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.$router.push({
                  name: "user-edit-profile",
                  alias: true,
                })
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.edit_personal_data))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/chevron-right.svg"),
                alt: "",
              },
            }),
          ]
        ),
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.$router.push({
                  name: "user-edit-insurance",
                  alias: true,
                })
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.edit_insurance_data))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/chevron-right.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ],
      1
    ),
    _c("h4", { staticClass: "mab12" }, [
      _vm._v(_vm._s(_vm.say.enable_notifications_for)),
    ]),
    _c(
      "div",
      { staticClass: "bsh mab24" },
      [
        _c(
          "base-button",
          {
            staticClass: "minisite__button minisite__button__nptb",
            attrs: { btn6: "" },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.say.notifications_for_proposed_competition)),
            ]),
            _c("base-input-switch", {
              attrs: { checked: _vm.notifications.proposedCompetition },
              nativeOn: {
                change: function ($event) {
                  return _vm.updateNotifications()
                },
              },
              model: {
                value: _vm.notifications.proposedCompetition,
                callback: function ($$v) {
                  _vm.$set(_vm.notifications, "proposedCompetition", $$v)
                },
                expression: "notifications.proposedCompetition",
              },
            }),
          ],
          1
        ),
        _c(
          "base-button",
          {
            staticClass: "minisite__button minisite__button__nptb",
            attrs: { btn6: "" },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.say.notifications_for_upcoming_races)),
            ]),
            _c("base-input-switch", {
              attrs: { checked: _vm.notifications.incomingCompetition },
              nativeOn: {
                change: function ($event) {
                  return _vm.updateNotifications()
                },
              },
              model: {
                value: _vm.notifications.incomingCompetition,
                callback: function ($$v) {
                  _vm.$set(_vm.notifications, "incomingCompetition", $$v)
                },
                expression: "notifications.incomingCompetition",
              },
            }),
          ],
          1
        ),
        _c(
          "base-button",
          {
            staticClass: "minisite__button minisite__button__nptb",
            attrs: { btn6: "" },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.notifications_for_my_results))]),
            _c("base-input-switch", {
              attrs: { checked: _vm.notifications.myResults },
              nativeOn: {
                change: function ($event) {
                  return _vm.updateNotifications()
                },
              },
              model: {
                value: _vm.notifications.myResults,
                callback: function ($$v) {
                  _vm.$set(_vm.notifications, "myResults", $$v)
                },
                expression: "notifications.myResults",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("h4", { staticClass: "mab12" }, [_vm._v(_vm._s(_vm.say.extra_inputs))]),
    _c(
      "div",
      { staticClass: "bsh mab32" },
      [
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.navigate(_vm.BASE_URL + "polityka_prywatnosci.pdf")
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.privacy_policy))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/chevron-right.svg"),
                alt: "",
              },
            }),
          ]
        ),
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.navigate(_vm.BASE_URL + "Regulamin serwisu.pdf")
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.dostartu_regulations))]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/icons/chevron-right.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bsh mab32" },
      [
        _c(
          "base-button",
          {
            staticClass: "minisite__button",
            attrs: { btn6: "" },
            on: {
              click: function ($event) {
                return _vm.logoutUser()
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.say.logout))]),
            _c(
              "svg",
              {
                attrs: {
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM7.85355 15.1464L4.70711 12H15.5C15.7761 12 16 11.7761 16 11.5C16 11.2239 15.7761 11 15.5 11H4.70711L7.85355 7.85355C8.04882 7.65829 8.04882 7.34171 7.85355 7.14645C7.65829 6.95118 7.34171 6.95118 7.14645 7.14645L3.14645 11.1464C2.95118 11.3417 2.95118 11.6583 3.14645 11.8536L7.14645 15.8536C7.34171 16.0488 7.65829 16.0488 7.85355 15.8536C8.04882 15.6583 8.04882 15.3417 7.85355 15.1464Z",
                    fill: "#FF1744",
                  },
                }),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "deleteAccount",
        on: {
          click: function ($event) {
            return _vm.erase()
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.say.delete_your_account) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }