import { render, staticRenderFns } from "./PanelInputSwitch.vue?vue&type=template&id=031f2bac&scoped=true&"
import script from "./PanelInputSwitch.vue?vue&type=script&lang=js&"
export * from "./PanelInputSwitch.vue?vue&type=script&lang=js&"
import style0 from "./PanelInputSwitch.vue?vue&type=style&index=0&id=031f2bac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031f2bac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/dostartu.pl/app.startzone.online/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('031f2bac')) {
      api.createRecord('031f2bac', component.options)
    } else {
      api.reload('031f2bac', component.options)
    }
    module.hot.accept("./PanelInputSwitch.vue?vue&type=template&id=031f2bac&scoped=true&", function () {
      api.rerender('031f2bac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PanelInputSwitch.vue"
export default component.exports