var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dark-bg dark-bg--fixed" }, [
    _c("div", { staticClass: "popup", class: _vm.className }, [
      _c("div", { staticClass: "popup__label" }, [_vm._t("title")], 2),
      _c(
        "div",
        { staticClass: "popup__content" },
        [_vm._t("subtitle"), _vm._t("content")],
        2
      ),
      _c("div", { staticClass: "popup__buttons" }, [_vm._t("buttons")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }