<template>
    <div class="none-mobile pab1">
        <p
            class="mat32 pointer mab40 mal15"
            @click="isHide=!isHide"
        >
            Pozostałe numery
        </p>
        <transition name="toggle">
            <div
                v-show="isHide"
                class="container-number-groups transition"
            >
                <div class="w100 mab40 dflex flex-wrap overflow-auto">
                    <!-- <div class="box-numbers"> -->
                    <!-- <div class="empty-space"></div> -->
                    <base-button
                        v-for="(player, idx) in sortedStoperPlayers"
                        :key="player.id"
                        btn9
                        class="number-other"
                        :class="{'awarded-btn': idx % 10 === 0}"
                        :disabled="stoperMeasured(player.playerNumber)"
                        @click="ADD_STOPER_MEASURE(player.playerNumber)"
                    >
                        {{ player.playerNumber }}
                    </base-button>
                    <!-- </div> -->
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ADD_STOPER_MEASURE } from '@/store/actions.type';
import _ from 'lodash';

export default {
    data() {
        return {
            isHide: false,
        };
    },
    computed: {
        ...mapGetters(['stoperPlayers', 'stoperMeasured']),
        sortedStoperPlayers() {
            return _.sortBy(this.stoperPlayers, 'playerNumber');
        },
    },
    methods: {
        ...mapActions([ADD_STOPER_MEASURE]),
    },
};
</script>

<style scoped>
.w100 {
    width: 100%;
}
.awarded-btn {
    background-color: var(--color-bluish-grey);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}
.dflex {
    display: flex;
}
.absolute {
    position: absolute;
    max-height: 240px;
    width: 100%;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
}
.container-number-groups {
    display: flex;
    overflow: hidden;
    max-height: 240px;
}

.box-numbers {
    display: flex;
    height: 220px;
    width: 118px;
    min-width: 118px;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 4px;
    margin-right: 4px;
}
.empty-space {
    width: 57px;
    height: 44px;
}
.number-other {
    height: 40px;
    width: 57px;
    margin-bottom: 4px;
    margin-right: 4px;
}
.flex-wrap {
    flex-wrap: wrap;
}

.toggle-enter {
    max-height: 0;
}

.toggle-enter-to {
    max-height: 240px;
}

.toggle-leave-to {
    max-height: 0;
}

.toggle-enter-active,
.toggle-leave-active {
    transition: all 0.6s;
}
</style>
