var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sport-image", class: _vm.classPhoto }, [
    _c(
      "div",
      { staticClass: "sport-image__colour", style: _vm.styleColour },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }