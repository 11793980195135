<template>
  <div style="max-width: 100vw;">
    <div class="row nowrap-lg" style="overflow: hidden;">
      <div class="col-12 col-lg-2">
        <main-list-filters class="map-margin-top" />
      </div>
      <div class="col-12 col-lg-10 mapWrap">
        <div v-if="loading" class="loading">
          <div class="lds-ellipsis lds-ellipsis--center">
            <div></div>
            <div></div> 
            <div></div> 
          </div>
        </div> 
        <div v-else>
        <l-map
          v-if="showMap"
          ref="map"
          @ready="onReady"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          class="mapWrapper"
        >
          <l-control position="topleft" class="custom-control">
            <base-button
              btn6
              class="ma4"
              @click="toggleMap()"
            >
              <span class="d-none d-md-inline-block">Zobacz listę</span> <i class="fas fa-list" />
            </base-button> 
          </l-control>
          <l-control-zoom position="topright"></l-control-zoom>
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <v-marker-cluster ref="markers">
            <template v-for="(competition, index) in filteredAllCompetitions">
              <CompetitionsMapMarker 
                :index="index" 
                :selectedIndex="selectedIndex"
                :key="competition"
                :competitionId="competition"
                @markers="bounds"
                @ref="markersRefs"
                @click="changeSlide(index)"
              />
            </template>
          </v-marker-cluster>
        </l-map>
        </div>
        <div class="carousel-wrap" v-show="!loading && selectedIndex !== null">
          <swiper ref="mySwiper" class="carousel" :options="swiperOption" @slideChange="slideChanged">
            <swiper-slide v-for="(id, index) in filteredAllCompetitions" :index="index" :id="id":key="id">
              {{ index }}
              <base-item-competition :id="id" />
            </swiper-slide>
          </swiper>
          <base-button btn6 class="carousel-prev"  v-show="boundsArr.length > selectedIndex + 1">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.70711 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H1.70711L4.85355 8.14645C5.04882 8.34171 5.04882 8.65829 4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L0.146447 4.85355C-0.0488155 4.65829 -0.0488155 4.34171 0.146447 4.14645L4.14645 0.146447C4.34171 -0.0488155 4.65829 -0.0488155 4.85355 0.146447C5.04882 0.341709 5.04882 0.658291 4.85355 0.853553L1.70711 4Z" fill="#4A4A4A"/>
            </svg>
          </base-button>
          <base-button btn6 class="carousel-next" v-show="selectedIndex !== 0">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z" fill="#4A4A4A"/>
            </svg>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import { SEARCH_COMPETITIONS_LIST } from '@/store/actions.type';
import { SET_FILTER } from '@/store/mutations.type';
import MainListFilters from '@/components/MainListFilters';
import CompetitionsMapMarker from '@/components/CompetitionsMapMarker';
import {
  LMap,
  LTileLayer,
  LControlZoom,
  LControl,
} from 'vue2-leaflet';
import { latLng } from "leaflet";
import * as L from "leaflet";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  name: 'MainListMap',
  components: {
    MainListFilters,
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
    CompetitionsMapMarker,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    Swiper,
    SwiperSlide 
  },
  directives: {
    swiper: directive
  },  
  data() {
    return {
      promotedCompetitions: [],
      futureCompetitions: [-5, -4, -3, -2, -1],
      pastCompetitions: [-5, -4, -3, -2, -1],
      allCompetitions: [],
      loading: false,
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
        scrollWheelZoom: false,
      },
      showMap: true,
      staticAnchor: [15, 37],
      boundsArr: [],
      selectedIndex: null,
      swiperOption: {
        loop: false,
        effect: 'fade',
        fadeEffect: { crossFade: true },
        navigation: {
          nextEl: '.carousel-prev',
          prevEl: '.carousel-next'
        }        
      },
      markersRefArray: {}
    };
  },
  computed: {
    ...mapGetters([
      'say',
      'time',
      'filter',
      'competitions',
    ]),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    filteredAllCompetitions() {
      return this.allCompetitions.filter((id) => this.competitions[id].isRemote === false)
    }
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler() {
        this.loading = true
        this.boundsArr = []
        this.selectedIndex = null
        this.searchCompetitions();
      },
    },
  },
  methods: {
    ...mapActions([SEARCH_COMPETITIONS_LIST]),
    ...mapMutations([SET_FILTER]),
    onReady() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.fitBounds(this.boundsArr, { padding: [50, 50] });
      })
    },  
    bounds(location) {
      this.boundsArr.push(location)
    },
    changeSlide(index) {
      this.selectedIndex = index
      var markers = this.$refs.markers.mapObject
      var marker = this.markersRefArray[index] ? this.markersRefArray[index].mapObject : undefined
      markers.eachLayer((marker) => {
        marker.setIcon(this.createIcon(require('@/assets/images/marker-map.png')))
      })
      marker.setIcon(this.createIcon(require('@/assets/images/marker-map-active.png')))
      this.swiper.slideTo(index, 1000, false)
    },
    slideChanged() {
      var index = this.swiper.activeIndex
      var markers = this.$refs.markers.mapObject
      var marker = this.markersRefArray[index] ? this.markersRefArray[index].mapObject : undefined
      var visibleLayer = markers.getVisibleParent(marker);
      this.selectedIndex = index
      markers.eachLayer((marker) => {
        marker.setIcon(this.createIcon(require('@/assets/images/marker-map.png')))
      })
      marker.setIcon(this.createIcon(require('@/assets/images/marker-map-active.png')))
      this.$refs.map.mapObject.setView(this.boundsArr[index])
      if (visibleLayer instanceof L.MarkerCluster) {
        visibleLayer.spiderfy();
      } else {
        this._unspiderfyPreviousClusterIfNotParentOf(marker);
      }
    },
    createIcon(url) {
      return new L.Icon({
        iconUrl: url,
        iconAnchor: [16, 37],
      });
    },
    _unspiderfyPreviousClusterIfNotParentOf(marker) {
      var markers = this.$refs.markers.mapObject

      var spiderfiedCluster = markers._spiderfied;

      if (
        spiderfiedCluster
        && !this._clusterContainsMarker(spiderfiedCluster, marker)
      ) {
        spiderfiedCluster.unspiderfy();
      }
    },
    _clusterContainsMarker(cluster, marker) {
      var currentLayer = marker;

      while (currentLayer && currentLayer !== cluster) {
        currentLayer = currentLayer.__parent;
      }

      // Say if we found a cluster or nothing.
      return !!currentLayer;
    },
    markersRefs(event) {
      this.markersRefArray[event.index] = event.refs
    },
    toggleMap() {
      this.SET_FILTER({
        filter: {
            ...this.filter,
        },
        destination: 'main-list',
      });
    },        
    scrollToListTop() {
      const el = this.$refs.listTop;
      if (window.pageYOffset > el.getBoundingClientRect().top) {
        el.scrollIntoView({
            behavior: 'smooth',
        });
      }
    },
    checkCompetition() {
      return this.competitions[this.id];
    },    
    searchCompetitions() {
      this.searchCompetitionsThrottled(this);
    },
    searchCompetitionsThrottled: _.throttle(
      (vm) => vm.searchCompetitionsDo(),
      250,
    ),
    async searchCompetitionsDo() {
        const [
            promotedCompetitions,
            futureCompetitions,
            pastCompetitions,
        ] = await this.SEARCH_COMPETITIONS_LIST();

        this.promotedCompetitions = promotedCompetitions;
        this.futureCompetitions = futureCompetitions;
        this.pastCompetitions = pastCompetitions;
        this.allCompetitions = []
        this.allCompetitions.push(...promotedCompetitions)
        this.allCompetitions.push(...futureCompetitions)
        this.allCompetitions.push(...pastCompetitions)
        this.allCompetitions = _.uniq(this.allCompetitions);
        this.loading = false
    },
    prev() {
        this.setPage(this.filter.page - 1);
    },
    next() {
        this.setPage(this.filter.page + 1);
    },
    setPage(page) {
        this.SET_FILTER({
            filter: {
                ...this.filter,
                page,
            },
            route: this.$route,
        });
        // this.scrollToListTop();
    },
  },    
}
</script>

<style>
@import '~leaflet/dist/leaflet.css';
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '~swiper/css/swiper.css';

.custom-control .btn:hover {
  background-color: var(--color-white) !important;
}
.custom-control .btn span {
  margin-right: 16px;
}

.carousel-wrap .competition-box {
background-color: #fff;
margin-top: 0;
}
.carousel {
  overflow: hidden;
}

.carousel-wrap .carousel-prev,
.carousel-wrap .carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  padding: 0;
  z-index: 44;
}
.carousel-wrap .carousel-prev:hover,
.carousel-wrap .carousel-next:hover {
background-color: #fff !important;
}
.mapWrap {
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .d-md-inline-block {
    display: inline-block;
  }
}
@media (max-width: 991px) {
  .map-margin-top {
    display: none;
    padding: 0 8px;
    margin-bottom: 16px;
  }
  .mapWrapper.vue2leaflet-map,
  .mapWrap {
   height: calc(100vh - 48px);
  }
  .carousel-wrap {
    width: calc(100% - 80px);
    margin: 0 auto;
    position: absolute;
    z-index: 5555;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .carousel-wrap .competition-box {
    min-height: 212px;
  }

  .carousel-wrap .swiper-wrapper {
    align-items: end;
  }

  .carousel-wrap .competition-box__image {
    display: none;
  }
  .carousel-wrap .carousel-prev,
  .carousel-wrap .carousel-next {
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 100%;
    padding: 0;
    z-index: 44;
    box-shadow: none;
  }
  .carousel-wrap .carousel-prev {
    left: -40px;
  }
  .carousel-wrap .carousel-next {
    right: -40px;
  }
}
@media (min-width: 992px) {
  .mapWrapper.vue2leaflet-map,
  .mapWrap {
   height: calc(100vh - 48px - 112px);
  }
  .carousel-wrap {
    width: calc(100% - 15% * 2);
    margin: 0 auto;
    position: absolute;
    z-index: 5555;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
  .nowrap-lg {
    flex-wrap: nowrap;
  }
  .carousel-wrap .competition-box {
    min-height: 171px;
  }
  .carousel-wrap .carousel-prev {
    left: -32px;
  }
  .carousel-wrap .carousel-next {
    right: -32px;
  }
  .map-margin-top {
    margin-top: 48px;
  }
}
</style>