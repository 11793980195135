<template>
    <div class="row">
        <div class="col-12">
            <b>
                <slot name="label" />
            </b>
        </div>
        <div class="user-widget col-12">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.user-widget {
    margin-top: 24px;
}
</style>
