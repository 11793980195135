var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row novelty" }, [
    _c("div", { staticClass: "novelty__arrow" }),
    _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "novelty__box" }, [_vm._t("images")], 2),
    ]),
    _c("div", { staticClass: "col-8" }, [
      _c("div", { staticClass: "novelty__description" }, [
        _c(
          "h5",
          { staticClass: "novelty__description-title" },
          [_vm._t("title")],
          2
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 mat16" }, [_vm._t("default")], 2),
        ]),
        _c(
          "div",
          {
            staticClass: "novelty__description-close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }