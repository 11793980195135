var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        staticClass:
          "row justify-content-center minisite-paylink__confirmation",
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "col-lg-4 col-12 minisite-pay__send-info" }, [
          _c("div", { staticClass: "row mab24" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("h3", [_vm._v(_vm._s(_vm.say.sent_paylink))]),
            ]),
          ]),
          _c("div", { staticClass: "row mat12" }, [
            _c("div", { staticClass: "col-12 col-lg-11" }, [
              _c("p", [_vm._v(_vm._s(_vm.say.open_paylink_hint))]),
            ]),
            _c("div", { staticClass: "col-12 col-lg-11 mat16" }, [
              _c("p", [_vm._v(_vm._s(_vm.say.no_received_paylink_hint))]),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "row mat16 minisite-pay__back-to-list justify-content-center",
      },
      [
        _c("div", { staticClass: "col-lg-2" }),
        _c(
          "div",
          { staticClass: "col-12 col-lg-3" },
          [
            _c(
              "base-button",
              {
                staticClass: "text-upper",
                attrs: { btn2: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "minisite-list",
                      params: _vm.competition,
                      alias: true,
                    })
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.say.back_to_start_list) + " ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-lg-3 col-12 justify-content-center" },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/illustration-link-in-email.svg"),
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }