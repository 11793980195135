<template>
    <footer>
        <div class="container">
            <div class="row">
                <div
                    class="col-12 col-md-4 col-lg-3 d-flex justify-content-space-between"
                >
                    <div>
                        <router-link
                            class="logods"
                            :to="{ name: 'main-landing', alias: true }"
                        >
                            DO:ST:AR:TU
                        </router-link>
                    </div>
                    <div
                        class="justify-content-space-between footer__icon-fb--mobile"
                    >
                        <p>
                            <a :href="FACEBOOK_PAGE">
                                <i
                                    class="fab fa-2x fa-facebook-square"
                                    aria-hidden="true"
                                />
                            </a>
                        </p>
                    </div>
                </div>

                <div
                    class="col-12 col-md-8 col-lg-9 d-flex justify-content-space-between"
                >
                    <div class="footer--links">
                        <div class="row out-gutter">
                            <div class="col-12 col-sm-6 col-lg-3">
                                <h5>{{ say.organization }}</h5>
                                <p>
                                    <a :href="`${organizerUrl}?section=rules`">
                                        {{ say.cooperation_regulations }}
                                    </a>
                                    <a :href="organizerUrl">
                                        {{ say.organizator_panel }}
                                    </a>
                                    <router-link
                                        :to="{
                                            name: 'timing-offer',
                                            alias: true,
                                        }"
                                    >
                                        {{ say.find_operator }}
                                    </router-link>
                                </p>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <h5>{{ say.participation }}</h5>
                                <p>
                                    <router-link
                                        :to="{
                                            name: 'user-competitions-future',
                                            alias: true,
                                        }"
                                    >
                                        {{ say.user_account }}
                                    </router-link>
                                    <router-link
                                        :to="{
                                            name: 'main-list',
                                            query: { status: 1 },
                                            alias: true,
                                        }"
                                    >
                                        {{ say.competition_registration }}
                                    </router-link>
                                    <router-link
                                        :to="{
                                            name: 'main-list',
                                            query: { status: 0 },
                                            alias: true,
                                        }"
                                    >
                                        {{ say.competition_results }}
                                    </router-link>
                                </p>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <h5>{{ say.about_dostartu }}</h5>
                                <p>
                                    <a :href="organizerUrl">
                                        {{ say.what_is_dostartu }}
                                    </a>
                                    <a
                                        :href="
                                            BASE_URL +
                                                'polityka_prywatnosci.pdf'
                                        "
                                        target="_blank"
                                    >{{ say.privacy_policy }}</a>
                                    <a
                                        :href="
                                            BASE_URL + 'Regulamin serwisu.pdf'
                                        "
                                        target="_blank"
                                    >{{ say.dostartu_regulations }}</a>
                                </p>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <h5>{{ say.contact }}</h5>
                                <p>
                                    <a :href="'mailto:' + MAIL_ADDRESS">{{
                                        say.write_to_us
                                    }}</a>
                                    <a :href="'mailto:' + MAIL_ADDRESS">{{
                                        MAIL_ADDRESS
                                    }}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="footer__icon-fb--desktop footer--social">
                        <a :href="FACEBOOK_PAGE">
                            <i
                                class="fab fa-2x fa-facebook-square"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="
                ['minisite-landing', 'minisite-contact'].includes($route.name)
            "
            class="footer-cash pat32"
        >
            <div class="container">
                <div class="row justify-content-end">
                    <p class="transaction-dotpay">
                        {{ say.transaction_settlement_by_dotpay }}
                    </p>
                    <span>
                        <img
                            class="img-fluid mal24"
                            src="@/assets/images/logo-dotpay.svg"
                        >
                        <img
                            class="img-fluid mal24"
                            src="@/assets/images/logo-visa.svg"
                        >
                        <img
                            class="img-fluid mal24"
                            src="@/assets/images/logo-mastercard.svg"
                        >
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import { BASE_URL, MAIL_ADDRESS, FACEBOOK_PAGE } from '@/config';

export default {
    data() {
        return {
            organizerUrl: 'https://organizator.dostartu.pl',
        };
    },
    computed: {
        ...mapGetters(['say']),
        BASE_URL() {
            return BASE_URL;
        },
        MAIL_ADDRESS() {
            return MAIL_ADDRESS;
        },
        FACEBOOK_PAGE() {
            return FACEBOOK_PAGE;
        },
    },
};
</script>
<style scoped>
footer {
    font-size: 12px;
    line-height: 2.67;
    margin: 60px 0 30px 0;
    box-shadow: inset 0px 1px 0px 0px rgba(84, 110, 122, 0.1);
    padding-top: 20px;
}

footer h5 {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: var(--color-greyish-brown);
}

footer p a {
    display: block;
    width: fit-content;
    line-height: 1.2;
    word-break: break-all;
    margin: 10px 5px 10px 0;
}

footer img {
    height: 32px !important;
}

footer .footer--links {
    width: 100%;
}

footer .footer--social {
    width: 80px;
    padding-top: 12px;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
}

.footer-cash {
    border-top: 1px solid rgba(84, 110, 122, 0.1);
}

.footer-cash p {
    max-width: 300px;
    text-align: right;
}

.footer-cash span {
    min-width: 324px;
}

footer a {
    color: var(--color-greyish-brown);
    outline: none;
    text-decoration: none;
}

.footer__icon-fb--desktop {
    display: none;
}

@media (min-width: 768px) {
    .footer__icon-fb--mobile {
        display: none;
    }
    .footer__icon-fb--desktop {
        display: block;
    }
}

.footer-cash {
    border-top: 1px solid rgba(84, 110, 122, 0.1);
}

.footer-cash p {
    max-width: 300px;
    text-align: right;
}

.footer-cash span {
    min-width: 324px;
}
.footer-cash img {
    display: inline-block;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.transaction-dotpay {
    line-height: 1.47;
    margin-bottom: 16px;
}
@media (min-width: 768px) {
    .transaction-dotpay {
        margin-bottom: 0;
    }
}
</style>
