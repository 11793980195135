var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "payment-result" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "payment-result-buttons" },
            [
              _c(
                "base-button",
                {
                  attrs: { btn9: "" },
                  on: {
                    click: function ($event) {
                      return _vm.goToPage("panel-competition-result")
                    },
                  },
                },
                [_vm._v("Przejdż do strony z wynikami")]
              ),
              _c(
                "base-button",
                {
                  attrs: { btn9: "" },
                  on: {
                    click: function ($event) {
                      return _vm.goToPage("panel-competition-sms")
                    },
                  },
                },
                [_vm._v("Przejdź do wysłanych widomości")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "payment-result-content" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/checkmark-circle.svg"),
          alt: "",
        },
      }),
      _c("h3", [_vm._v("Płatność zakończona sukcesem")]),
      _c("p", [_vm._v("Wysłaliśmy wiadomości SMS do uczestników")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }