// import facebookSdk from '@p803/facebook-sdk';
import { READ_FACEBOOK_SIGNED_REQUEST } from '@/store/actions.type';

export default {
    actions: {
        async [READ_FACEBOOK_SIGNED_REQUEST]() {
            // const sdk = await facebookSdk();
            // const { authResponse } = await sdk.login({
            //     scope: ['email'],
            // });

            // if (!authResponse) {
            //     throw new Error('Facebook user cancelled login or did not fully authorize.');
            // }

            // return authResponse.signedRequest;
        },
    },
};
