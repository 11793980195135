import Router from '@/router/router';
import router from '@/router';
import routes from '@/router/routes';
import store from "@/store";

import { stringify } from 'qs';

export default (to, from, next) => {
    // Redirect to trim legacy `.pl.html` suffix.
    const fullPath = to.fullPath.replace(/\.[a-z]{2}\.html/i, '');

    if (fullPath !== to.fullPath) {
        next({ path: fullPath, replace: true });

        return;
    }

    // Redirect to legacy system if legacy view.
    if (to.meta.isLegacyRoute) {
        if (to.name === 'minisite-register' && store.state.authentication.userId === null && !/skip=true/.test(to.fullPath)) {
            router.push({ name: 'authentication-login', query: { redirect: to.fullPath }, alias: true })
            return;
        }
        window.location.href = `${to.path}.pl.html?${stringify(to.query)}`;

        return;
    }

    // Redirect to main landing if bad from route.
    if (to.meta.from !== undefined && to.meta.from !== from.name) {
        next(Router.getParsed({ name: 'main-landing', alias: true }, routes));

        return;
    }

    // No redirect.
    next();
};
