var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "base-button-group",
        [
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-info",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Informacje"), _c("br"), _vm._v("o zawodach ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-classifications",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Klasyfikacje, "), _c("br"), _vm._v("limity, opłaty ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-numbers",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Numeracja "), _c("br"), _vm._v("startowa ")]
          ),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "mat16" },
        [
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-rebates",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Kody rabatowe ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-discount",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Rabaty za starty ")]
          ),
        ],
        1
      ),
      _c(
        "base-button-group",
        { staticClass: "mat16" },
        [
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-register",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Rejestracja ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-cycle",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Zawody cykliczne ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-regulation",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Regulamin zawodów ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-statement",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Zgody "), _c("br"), _vm._v("uczestników ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-confirmation",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Potwierdzenie"), _c("br"), _vm._v("rejestracji ")]
          ),
          _c(
            "base-button",
            {
              attrs: { btn11: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "panel-competition-setting-layout",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [_vm._v(" Wygląd ")]
          ),
        ],
        1
      ),
      _vm.isOperator
        ? _c(
            "base-button-group",
            { staticClass: "mat16" },
            [
              _c(
                "base-button",
                {
                  attrs: { btn11: "", novelty: "" },
                  on: { click: _vm.competitionSettingStoperFullscreen },
                },
                [_vm._v(" Pomiar czasu ")]
              ),
            ],
            1
          )
        : _c(
            "base-button-group",
            { staticClass: "mat16" },
            [
              _c(
                "base-button",
                {
                  attrs: { btn11: "", novelty: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "panel-competition-setting-stoper",
                        params: _vm.competition,
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" Pomiar czasu ")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }