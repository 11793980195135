import { schema } from 'normalizr';

export default new schema.Entity(
    'categoryCounts',
    {},
    {
        idAttribute: 'category',
        processStrategy: (categoryCount) => ({
            // Meta.
            _type: 'categoryCount',
            // Fields.
            ...categoryCount,
        }),
    },
);
