import moment from 'moment';
import store from '@/store';

export default {
    methods: {
        // Register actions.
        canRegister(competition, limits) {
            return competition.status === 2 && competition.formType === 'registration' && limits.playersAvailable;
        },
        canRegisterOutside(competition) {
            return competition.formType === 'insurance' && competition.websitePl;
        },
        canRegisterClassification(competition, limits) {
            return competition.status === 2 && limits.playersLimit > 0;
        },

        // Family actions.
        canEditChild(association) {
            return association.enabled && !association.childActive;
        },

        // Player actions.
        canPlayerPay(player, competition) {
            return (
                competition.status === 2
                && !player.pay
                && store.getters.classificationsSettings[player.classification].isPay
                && competition.payOnline
                && ((store.getters.limits[player.classification].playersAvailable
                    && store.getters.competitionsSettings[competition.id].playersKindLimit === 'pay')
                    || store.getters.competitionsSettings[competition.id].playersKindLimit === 'register')
            );
        },
        canPlayerEdit(player, competition) {
            return competition.status === 2 && !player.pay;
        },
        canPlayerTransfer(player, competition) {
            return competition.status === 2 && player.pay && !!competition.cycle;
        },
        canPlayerShop(player, competition) {
            return competition.status === 2 && player.pay;
        },
        canPlayerResign(player, competition) {
            return competition.status === 2 && !player.pay;
        },
        canPlayerDelete(player, competition) {
            return competition.status !== 2 && !player.pay;
        },
        canPlayerDeclareResult(player, competition) {
            return (
                competition.isRemote
                && competition.status !== 6
                && ((store.getters.classificationsSettings[player.classification].isPay && player.pay)
                    || !store.getters.classificationsSettings[player.classification].isPay)
            );
        },
        canPlayerAskForResult(player, competition) {
            return (
                ![4, 5].includes(competition.status)
                && moment().unix() - 24 * 3600 > competition.startedTime
                && player.pay
            );
        },

        // User actions.
        canDeclareResults(user, competition) {
            return user && user.roles.includes('ROLE_USER') && competition.isRemote;
        },
    },
};
