<template>
    <div>
        <!-- Credentials. -->
        <user-form-credentials
            v-model="credentials"
            :validation.sync="errors"
        />

        <!-- Validation. -->
        <base-form-validation :validation="errors" />

        <!-- Actions. -->
        <user-form-actions @save="save()" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UserFormCredentials from '@/components/UserFormCredentials';
import UserFormActions from '@/components/UserFormActions';
import { pick } from '@/utils/object';
import { UPDATE_USER } from '@/store/actions.type';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        UserFormCredentials,
        UserFormActions,
    },
    data() {
        return {
            credentials: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.credentials = {
            ...pick(this.user, ['id', 'email']),
            plainPassword: '',
        };
    },
    methods: {
        ...mapActions([UPDATE_USER]),
        ...mapMutations([SET_NOTIFICATION]),
        async save() {
            this.errors = {};
            try {
                await this.UPDATE_USER(this.credentials);

                this.SET_NOTIFICATION('alerts_positive_5');
            } catch (errors) {
                this.errors = errors;
            }
        },
    },
};
</script>
