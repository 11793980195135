<template>
    <div :class="homePromoteClasses">
        <div :class="promoteBoxClasses">
            <!-- Previous button. -->
            <div
                class="home-promote__btn home-promote__btn--left"
                :class="{'home-promote__btn--high': $route.name !== 'main-landing'}"
                @click="prev()"
            >
                <i class="fas fa-chevron-left" />
            </div>

            <!-- Carousel items. -->
            <div
                id="carousel"
                class="row home-promote__carousel"
            >
                <main-carousel-item
                    v-for="id in ids"
                    :id="id"
                    :key="id"
                    @loaded="delay(activeClassWatcher)"
                />
            </div>

            <!-- Next button. -->
            <div
                class="home-promote__btn home-promote__btn--right"
                :class="{'home-promote__btn--high': $route.name !== 'main-landing'}"
                @click="next()"
            >
                <i class="fas fa-chevron-right" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SEARCH_COMPETITIONS_CAROUSEL } from '@/store/actions.type';
import MainCarouselItem from '@/components/MainCarouselItem';

export default {
    components: {
        MainCarouselItem,
    },
    props: {
        competitionFilter: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            ids: [-1, -2, -3, -4], // skeletons
        };
    },
    computed: {
        ...mapGetters(['competitions']),
        homePromoteClasses() {
            return {
                'home-promote': true,
                'home-promote--person': this.$route.name !== 'main-landing',
                container: this.$route.name === 'main-landing',
            };
        },
        promoteBoxClasses() {
            return {
                promote__box: this.$route.name === 'main-landing',
                'position-relative': this.$route.name !== 'main-landing',
            };
        },
    },
    async created() {
        this.ids = await this.SEARCH_COMPETITIONS_CAROUSEL(
            this.competitionFilter,
        );
        this.$emit('ready', this.ids);
    },
    mounted() {
        this.addCarouselListener();
    },
    methods: {
        ...mapActions([SEARCH_COMPETITIONS_CAROUSEL]),

        delay(callback) {
            setTimeout(callback, 60);
        },

        prev() {
            const activeItems = document.getElementsByClassName(
                'home-promote__competition--active',
            );
            const widthFirstActiveItem = activeItems[0].parentNode.clientWidth;
            document.getElementById('carousel').scrollBy({
                left: -widthFirstActiveItem,
                behavior: 'smooth',
            });
        },

        next() {
            const activeItems = document.getElementsByClassName(
                'home-promote__competition--active',
            );
            const widthFirstActiveItem = activeItems[0].parentNode.clientWidth;
            document.getElementById('carousel').scrollBy({
                left: widthFirstActiveItem,
                behavior: 'smooth',
            });
        },

        elementInViewport(item) {
            const bounding = item.getBoundingClientRect();
            return (
                bounding.left >= 0
                && bounding.right
                    <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },

        activeClassWatcher() {
            const competitions = document.getElementsByClassName(
                'home-promote__competition',
            );

            for (const elem in competitions) {
                if (this.elementInViewport(competitions.item(elem))) {
                    competitions.item(elem).className = 'home-promote__competition home-promote__competition--active';
                } else {
                    competitions.item(elem).className = 'home-promote__competition';
                }
            }
        },

        addCarouselListener() {
            const carousel = document.getElementById('carousel');
            carousel.addEventListener('scroll', this.activeClassWatcher);
        },
    },
};
</script>
