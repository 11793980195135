<template>
    <div class="container confirmation">
        <div class="row confirmation__content">
            <div class="col-12 col-lg-3">
                <img
                    class="confirmation__content-image"
                    src="@/assets/images/success-thumb.svg"
                    alt=""
                >
            </div>
            <div class="col-12 col-lg-3">
                <h2 class="confirmation__content-title">
                    {{ say.thanks_for_result }}
                </h2>
                <p
                    v-if="isTime"
                    class="confirmation__content-subtitle"
                >
                    {{ `${say.your_declared} ${say.time}` }}
                </p>
                <p
                    v-else
                    class="confirmation__content-subtitle"
                >
                    {{
                        `${say.your_declared} ${$options.filters.asLowerCase(
                            say.distance
                        )}`
                    }}
                </p>
                <h2 class="confirmation__content-result">
                    {{
                        isTime
                            ? time
                            : `${player.declaredDistance} km`
                    }}
                </h2>
                <base-button
                    class="confirmation__content-button"
                    btn2
                    @click="showResultList()"
                >
                    {{ say.see_list_results }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        playerId: {
            type: Number,
            required: true,
        },
        isTimeCompetition: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['competitions', 'minisiteId', 'players', 'say']),
        player() {
            return this.players[this.playerId];
        },
        time() {
            return this.$options.filters.asStoper(this.player.declaredTime);
        },
        competition() {
            return this.competitions[this.minisiteId];
        },
        isTime() {
            return Boolean(this.isTimeCompetition);
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'minisite-declare-result') {
            next();
            return;
        }
        next(from);
    },
    methods: {

        showResultList() {
            this.$router.push({
                name: 'minisite-results',
                params: {
                    classificationId: this.player.classification,
                    ...this.competition,
                },
                alias: true,
            });
        },
    },
};
</script>

<style scoped>
.confirmation {
    margin-top: 40px;
}
.confirmation__content {
    text-align: center;
}
.confirmation__content-title {
    margin-top: 16px;
    color: var(--color-dark-pastel-green);
}
.confirmation__content-subtitle {
    margin-top: 16px;
}
.confirmation__content-button {
    margin-top: 16px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 992px) {
    .confirmation {
        margin-top: 80px;
    }
    .confirmation__content-title {
        margin-top: 0;
    }
    .confirmation__content-subtitle {
        margin-top: 32px;
    }
    .confirmation__content {
        text-align: left;
        justify-content: center;
    }
    .confirmation__content-button {
        max-width: unset;
        margin-top: 32px;
    }
}
</style>
