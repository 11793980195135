<template>
    <div class="container">
        <div
            ref="list"
            class="row container-list"
        >
            <base-table
                class="list"
                :headers="headers"
                :items="items"
            >
                <!-- Headers. -->
                <template v-slot:header="{ sort }">
                    <tr class="list__row">
                        <th
                            v-for="(header, column) in headers"
                            :key="column"
                            class="list__row-header"
                            @click="sort(column)"
                        >
                            {{ header.text }}
                        </th>
                    </tr>
                </template>
                <!-- Body. -->
                <template v-slot:body="{ displayedItems }">
                    <tr
                        v-for="item in displayedItems"
                        :key="item.id"
                        class="list__row list__row--pointer list__row--hover"
                        :class="{
                            'list__row--disabled':
                                isCompetitions && !item.isShow,
                        }"
                        @click="onRowClick(item)"
                    >
                        <td
                            v-for="(header, column) in headers"
                            :key="column"
                            class="list__row-data"
                            :class="{
                                'list__row-data--sticky-right':
                                    header.value === 'more',
                                'list__row-data--name': header.value === 'name',
                                'list__row-data--location':
                                    header.value === 'location',
                                'list__row-data--active':
                                    header.value === 'more' &&
                                    item.id === menuCompetitionId,
                                'list__row-data--loading': loading
                            }"
                            @click="
                                header.value === 'more'
                                    ? menuClickHandler($event, item.id)
                                    : header.value === 'status' ? statusClickHandler($event) : ''
                            "
                        >
                            <!-- Button with actions. -->
                            <template v-if="header.value === 'more'">
                                <div
                                    :ref="item.id"
                                    class="more-btn"
                                >
                                    <i class="fas fa-ellipsis-h" />
                                    <div
                                        v-if="menuCompetitionId === item.id"
                                        class="more-btn__content"
                                        :class="{
                                            'more-btn__content--from-up': isMenuCompetitionIdInBottom,
                                        }"
                                    >
                                        <template v-if="isCompetitions">
                                            <!-- Operator and organizer actions - competitions list.  -->
                                            <!-- Stoper button. -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    $router.push({
                                                        name:
                                                            'panel-competition-stoper',
                                                        params: item,
                                                        alias: true,
                                                    })
                                                "
                                            >
                                                <i
                                                    class="fas fa-stopwatch mar8"
                                                />
                                                Otwórz stoper
                                            </div>
                                            <!-- Copy button. -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    copyCompetition(item.id)
                                                "
                                            >
                                                <i class="far fa-copy mar8" />
                                                Kopiuj zawody
                                            </div>
                                            <!-- Show public -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    $emit(
                                                        'switch-show-competition',
                                                        item.id,
                                                    )
                                                "
                                            >
                                                <i
                                                    class="far mar8"
                                                    :class="[
                                                        item.isShow
                                                            ? 'fa-eye-slash'
                                                            : 'fa-eye',
                                                    ]"
                                                />
                                                {{
                                                    !item.isShow
                                                        ? 'Pokaż na liście zawodów'
                                                        : 'Ukryj na liście zawodów'
                                                }}
                                            </div>
                                            <div
                                                v-if="isAdmin"
                                                class="more-btn__content-btn"
                                                @click="
                                                    $emit(
                                                        'switch-global-shop-competition',
                                                        item.id,
                                                    )
                                                "
                                            >
                                                <i
                                                    class="far mar8"
                                                    :class="[
                                                        item.globalShop
                                                            ? 'fa-cart-arrow-down'
                                                            : 'fa-cart-plus',
                                                    ]"
                                                />
                                                {{
                                                    !item.globalShop
                                                        ? 'Otwórz sklep partnerski'
                                                        : 'Zamknij sklep partnerski'
                                                }}
                                            </div>
                                            <!-- Delete competition button. -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    $emit(
                                                        'delete-competition',
                                                        item.id,
                                                    )
                                                "
                                            >
                                                <i class="far fa-trash mar8" />
                                                Usuń zawody
                                            </div>
                                            <template v-if="isAdmin">
                                                <!-- Promote competiton. -->
                                                <div
                                                    class="more-btn__content-btn"
                                                    @click="
                                                        $emit(
                                                            'promote-competition',
                                                            item.id,
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fas fa-dollar-sign mar8"
                                                    />
                                                    Promuj
                                                </div>
                                                <!-- Gamification. -->
                                                <div
                                                    class="more-btn__content-btn"
                                                    @click="
                                                        $emit(
                                                            'gamification-competition',
                                                            item.id,
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fas fa-trophy mar8"
                                                    />
                                                    {{
                                                        item.isGamification
                                                            ? 'Usuń grywalizacje'
                                                            : 'Grywalizacja'
                                                    }}
                                                </div>
                                            </template>
                                        </template>
                                        <!-- Operator and admin actions on operator/organizers list -->
                                        <template v-else>
                                            <!-- Send access button. -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    $emit(
                                                        'send-access',
                                                        item.id,
                                                    )
                                                "
                                            >
                                                <i
                                                    class="far fa-envelope mar8"
                                                />
                                                {{
                                                    isOrganizers
                                                        ? 'Wyślij dostęp organizatorowi'
                                                        : 'Wyślij dostęp operatorowi'
                                                }}
                                            </div>
                                            <!-- Contract button. -->
                                            <!-- <div class="more-btn__content-btn">
                                                <i class="far fa-file mar8" />
                                                Umowa z organizatorem
                                            </div> -->

                                            <!-- Delete organizer/operator button. -->
                                            <div
                                                class="more-btn__content-btn"
                                                @click="
                                                    $emit(
                                                        'delete-person',
                                                        item.id,
                                                    )
                                                "
                                            >
                                                <i class="far fa-trash mar8" />
                                                {{
                                                    isOrganizers
                                                        ? 'Usuń organizatora'
                                                        : 'Usuń operatora'
                                                }}
                                            </div>
                                            <!-- Payment button. -->
                                            <!-- <div class="more-btn__content-btn">
                                                <i
                                                    class="fas fa-dollar-sign mar8"
                                                />
                                                Płatności online
                                            </div> -->
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="header.value === 'status'">
                                <div style="width: 200px">
                                    <select v-model="selectedStatus[item.id]" @change="setStatus(item)">
                                        <option :value="status" v-for="status in statuses">{{ status.label }}</option>
                                    </select>
                                </div>
                            </template>    
                            <template v-else>
                                {{ item[header.value] }}
                            </template>
                        </td>
                    </tr>
                </template>
                <template v-slot:no-results="{ search: term }">
                    <div v-if="term.length">
                        Brak wyników wyszukiwania
                        <b>{{ term }}</b>
                    </div>
                    <div v-else-if="isCompetitions">
                        Brak zorganizowanych zawodów
                    </div>
                    <div v-else-if="isOrganizers">
                        Brak organizatorów
                    </div>
                    <div v-else>
                        Brak operatorów
                    </div>
                </template>
            </base-table>
        </div>
    </div>
</template>

<script>
import { COPY_COMPETITION, UPDATE_COMPETITION } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        isOrganizers: {
            type: Boolean,
            default: false,
        },
        isCompetitions: {
            type: Boolean,
            default: false,
        },
        isOperators: {
            type: Boolean,
            default: false,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            menuCompetitionId: null,
            isMenuCompetitionIdInBottom: false,
            organizerHeaders: [
                { text: 'Data dodania', value: 'addedTime' },
                { text: 'Nazwa organizatora', value: 'name' },
                { text: 'Telefon', value: 'phone' },
                { text: 'E-mail', value: 'email' },
                { text: '', value: 'more' },
            ],
            competitionsHeaders: [
                { text: 'Start', value: 'startedTime' },
                { text: 'Nazwa zawodów', value: 'name' },
                { text: 'Zarej.', value: 'registered' },
                { text: 'Opłac.', value: 'paid' },
                { text: 'Miejscowość', value: 'location' },
                { text: 'Status', value: 'status' },
                { text: 'Więcej', value: 'more' },
            ],
            statuses: [
                {label: 'Informacja o zawodach', code: 1},
                {label: 'Rejestracja otwarta', code: 2},
                {label: 'Rejestracja zamknięta', code: 3},
                {label: 'Wyniki LIVE', code: 4},
                {label: 'Wyniki nieoficjalne', code: 5},
                {label: 'Wyniki oficjalne', code: 6},
            ],
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['competitions']),
        headers() {
            if (this.isCompetitions) {
                return this.competitionsHeaders;
            }
            if (this.isOperators) {
                const orgHeader = this.organizerHeaders.find(
                    (header) => header.value === 'name',
                );
                orgHeader.text = 'Nazwa operatora';
                return this.organizerHeaders;
            }
            return this.organizerHeaders;
        },
        selectedStatus() {
            const array = {}
            for (const item of this.items) {
                let obj = {
                    label: item.status, code: item.statusId
                }
                array[item.id] = obj
            }
            return array
        },
    },
    watch: {
        // isMenuCompetitionIdInBottom determines when should show menu above button.
        menuCompetitionId(id) {
            const distanceTopParentElement = this.$refs.list.getBoundingClientRect()
                .top;
            const distanceTopChildElement = this.$refs.list.getBoundingClientRect()
                .top;
            if (id) {
                if (distanceTopChildElement - distanceTopParentElement > 200) {
                    this.isMenuCompetitionIdInBottom = true;
                } else {
                    this.isMenuCompetitionIdInBottom = false;
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeMenu);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeMenu);
    },
    methods: {
        ...mapActions([COPY_COMPETITION, UPDATE_COMPETITION]),
        menuClickHandler(event, id) {
            event.stopPropagation();
            if (this.menuCompetitionId === id) {
                this.menuCompetitionId = null;
            } else {
                this.menuCompetitionId = id;
            }
        },
        statusClickHandler(event) {
            event.stopPropagation();
        },
        closeMenu() {
            this.menuCompetitionId = null;
        },
        onRowClick(item) {
            this.$emit('row-click', item);
        },
        async copyCompetition(id) {
            const competitionId = await this.COPY_COMPETITION(id);
            this.$router.push({
                name: 'panel-competition-dashboard',
                params: this.competitions[competitionId],
                alias: true,
            });
        },
        async setStatus(item) {
            this.loading = true
            const status = this.selectedStatus[item.id].code
            const competition = this.competitions[item.id]
            try {
                await this.UPDATE_COMPETITION({ ...competition, status });
            } catch(e) {
                const index = this.items.findIndex(competition => competition.id === item.id)
                this.selectedStatus[item.id] = { code: this.items[index].statusId, label: this.items[index].status }
                alert('Status nie został zmieniony')
            }
            this.loading = false
        }
    },
};
</script>

<style scoped>
.container-list {
    overflow: auto;
    margin-top: 36px;
    margin-bottom: 36px;
}
.list {
    width: 100%;
    border-spacing: 0;
    border-radius: 4px;
}
.list >>> tbody {
    background-color: var(--color-white);
    color: var(--color-slate);
}
.list__row-data {
    padding: 10px;
    border-bottom: 1px solid rgba(84, 110, 122, 0.1);
    white-space: nowrap;
    height: 48px;
    box-sizing: border-box;
}
.list__row--pointer {
    cursor: pointer;
}
.list__row--hover:hover {
    background: #f7f9f9;
}
.list__row-header {
    text-align: left;
    padding-bottom: 8px;
    padding-left: 10px;
    color: var(--color-silver);
    font-weight: normal;
}
.more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}
.more-btn__content {
    position: absolute;
    top: 16px;
    right: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-pale-grey);
    box-sizing: border-box;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 99;
}
.more-btn__content--from-up {
    bottom: 16px;
    top: unset;
}
.more-btn__content-btn {
    padding: 14px 8px;
    box-shadow: inset 0px -1px 0px #edf0f1;
    font-size: 14px;
}
.more-btn__content-btn:hover {
    background: #f7f9f9;
}
.more-btn__content-btn:last-of-type {
    box-shadow: none;
}
.list__row-data--sticky-right {
    position: sticky;
    right: 0;
    z-index: 1;
    max-width: 48px;
    min-width: 48px;
    background-color: var(--color-white);
    box-shadow: inset 1px 0px 0px #e5e5e5;
    cursor: pointer;
    opacity: 1 !important;
}
.list__row--hover:hover .list__row-data--sticky-right {
    background: #f7f9f9;
}
.list__row-data--active {
    z-index: 10;
}
.list__row--disabled td {
    opacity: 0.5;
}
.list__row-data--location {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.list__row-data--loading {
    opacity: 0.1 !important;
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}
@media (min-width: 992px) {
    .list__row-data--sticky-right {
        box-shadow: none;
    }
    .container-list {
        overflow: visible;
    }
    .list__row-data--name {
        max-width: 340px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
