var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container pab1" }, [
        _c("div", { staticClass: "row admin-header" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-5" },
            [
              _c(
                "base-button-group",
                {
                  staticClass: "admin-header__views",
                  attrs: { horizontal: "" },
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { pressed: !_vm.isCompetitionList, btn11: "" },
                      on: {
                        click: function ($event) {
                          _vm.isCompetitionList = false
                        },
                      },
                    },
                    [_vm._v(" Lista operatorów ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { btn11: "", pressed: _vm.isCompetitionList },
                      on: {
                        click: function ($event) {
                          _vm.isCompetitionList = true
                        },
                      },
                    },
                    [_vm._v(" Lista zawodów ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              !_vm.isCompetitionList
                ? _c("base-input-text", {
                    staticClass: "admin-header__search",
                    attrs: { icon: "fas fa-search", label: "Szukaj..." },
                    model: {
                      value: _vm.searchOperators,
                      callback: function ($$v) {
                        _vm.searchOperators = $$v
                      },
                      expression: "searchOperators",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-3" },
            [
              !_vm.isCompetitionList
                ? _c(
                    "base-button",
                    {
                      staticClass: "admin-header__add-operator",
                      attrs: { btn1: "" },
                      on: {
                        click: function ($event) {
                          _vm.showAddOperator = !_vm.showAddOperator
                        },
                      },
                    },
                    [_vm._v(" Dodaj operatora ")]
                  )
                : _c("base-input-text", {
                    staticClass:
                      "admin-header__search admin-header__search--competitions",
                    attrs: { icon: "fas fa-search", label: "Szukaj..." },
                    model: {
                      value: _vm.searchCompetitions,
                      callback: function ($$v) {
                        _vm.searchCompetitions = $$v
                      },
                      expression: "searchCompetitions",
                    },
                  }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "admin-transition" } },
        [
          _vm.showPromotePanel
            ? _c("panel-promote-competition", {
                attrs: {
                  competition: _vm.competitions[_vm.competitionIdToPromote],
                },
                on: {
                  "close-promote": function ($event) {
                    ;(_vm.showPromotePanel = false),
                      (_vm.competitionIdToPromote = null)
                  },
                },
              })
            : _vm._e(),
          _vm.showAddOperator
            ? _c("panel-add-person", {
                staticClass: "admin-header__add-box",
                attrs: { "is-operator": "" },
                on: {
                  "add-person": _vm.addOperator,
                  close: function ($event) {
                    _vm.showAddOperator = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.ready
        ? _c("div", { staticClass: "container mat32" }, [_vm._m(0)])
        : _c("panel-list", {
            attrs: {
              "is-operators": !_vm.isCompetitionList,
              "is-competitions": _vm.isCompetitionList,
              "is-admin": "",
              items: _vm.items,
            },
            on: {
              "search-competitions": function ($event) {
                _vm.searchInput = $event
              },
              "row-click": _vm.onRowClick,
              "send-access": _vm.sendAccessToOperator,
              "delete-competition": function ($event) {
                ;(_vm.showPopupWithDelete = true),
                  (_vm.competitionIdToDelete = $event)
              },
              "delete-person": function ($event) {
                ;(_vm.showPopupWithDelete = true),
                  (_vm.operatorIdToDelete = $event)
              },
              "switch-show-competition": _vm.onSwitchCompetition,
              "switch-global-shop-competition": _vm.globalShopCompetition,
              "promote-competition": _vm.promoteCompetition,
              "gamification-competition": _vm.setGamification,
            },
          }),
      _vm.isCompetitionList && _vm.ready
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row admin__pages" }, [
              _c(
                "div",
                {
                  staticClass: "admin__pages-btn",
                  class: {
                    "admin__pages-btn--disabled": !_vm.isPreviousPage,
                  },
                  on: {
                    click: function ($event) {
                      _vm.isPreviousPage ? _vm.prevPage() : ""
                    },
                  },
                },
                [
                  _vm.isLoadingPrevCompetitions
                    ? _c("div", { staticClass: "admin__pages-loading" }, [
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" Poprzednie "),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "admin__pages-btn",
                  class: { "admin__pages-btn--disabled": !_vm.isNextPage },
                  on: {
                    click: function ($event) {
                      _vm.isNextPage ? _vm.nextPage() : ""
                    },
                  },
                },
                [
                  _vm.isLoadingNextCompetitions
                    ? _c("div", { staticClass: "admin__pages-loading" }, [
                        _vm._m(2),
                      ])
                    : _vm._e(),
                  _vm._v(" Następne "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.showPopupWithDelete
        ? _c("base-popup", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isCompetitionList
                              ? "Usuń zawody"
                              : "Usuń operatora"
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "subtitle",
                  fn: function () {
                    return [
                      _c("b", [
                        _vm._v(
                          "Czy na pewno, chcesz usunąć " +
                            _vm._s(
                              _vm.isCompetitionList ? "zawody" : "operatora"
                            ) +
                            "?"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mat16" }, [
                        _vm._v(" Tej czynności nie będzie można cofnąć. "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "base-button",
                        {
                          staticClass: "mar15",
                          attrs: { btn2: "" },
                          on: {
                            click: function ($event) {
                              return _vm.onClosePopup()
                            },
                          },
                        },
                        [_vm._v(" anuluj ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "mal15",
                          attrs: { btn1: "" },
                          on: {
                            click: function ($event) {
                              _vm.onDelete(),
                                (_vm.showPopupWithDelete =
                                  !_vm.showPopupWithDelete)
                            },
                          },
                        },
                        [_vm._v(" Usuń ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2045243787
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "admin-header__loading-state" }, [
          _vm._v(" Trwa wczytywanie "),
          _c("div", { staticClass: "mal15 lds-ellipsis" }, [
            _c("div"),
            _c("div"),
            _c("div"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }