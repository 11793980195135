/* eslint-disable no-param-reassign */
export default (to) => {
    if (to.params.id) {
        to.params.id = Number.parseInt(to.params.id, 10);
    }

    if (to.params.playerId) {
        to.params.playerId = Number.parseInt(to.params.playerId, 10);
    }

    if (to.params.classificationId) {
        to.params.classificationId = Number.parseInt(to.params.classificationId, 10);
    }
    if (to.params.organizerId) {
        to.params.organizerId = Number.parseInt(to.params.organizerId, 10);
    }
    if (to.params.operatorId) {
        to.params.operatorId = Number.parseInt(to.params.operatorId, 10);
    }
};
