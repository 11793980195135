<template>
    <div
        class="border-box"
        :class="[
            horizontal
                ? 'border-box--horizontal d-flex'
                : 'border-box--vertical',
            {   
                'noBorder': !border,
                'shadow': shadow,
            },
        ]"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true
        },
        shadow: {
            type: Boolean,
            default: false,
        }
    },
};
</script>

<style scoped>
.border-box {
    border-radius: 4px;
    border: solid 1px var(--color-bluish-grey);
    overflow: hidden;
    box-sizing: border-box;
}
.border-box.noBorder {
    border: 0;
}
.border-box.shadow {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}
.border-box--vertical div:not(:last-of-type) {
    border-bottom: 1px solid var(--color-bluish-grey);
}
.border-box--horizontal div:not(:first-of-type) {
    border-left: 1px solid var(--color-bluish-grey);
}
</style>
