var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row organizer-header" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-4" },
        [
          _c("base-input-text", {
            staticClass: "organizer-header__search",
            attrs: { icon: "fas fa-search", label: "Szukaj..." },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-lg-5 organizer-header__link" },
        [
          _c("base-link-input", {
            staticClass: "organizer-header__link-box",
            attrs: {
              link: _vm.organizerLink,
              "button-name": "Kopiuj link",
              hint: "To jest publiczny adres strony z listą Twoich zawodów",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-lg-3" },
        [
          _c(
            "base-button",
            {
              staticClass: "organizer-header__add-competition",
              attrs: { btn1: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("add-competition")
                },
              },
            },
            [_vm._v(" Zorganizuj nowe zawody ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }