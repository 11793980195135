import actionsCreate from '@/store/modules/entities/actions.create';
import actionsDelete from '@/store/modules/entities/actions.delete';
import actionsRead from '@/store/modules/entities/actions.read';
import actionsUpdate from '@/store/modules/entities/actions.update';
import gettersById from '@/store/modules/entities/getters.by-id';
import gettersByRelation from '@/store/modules/entities/getters.by-relation';
import mutations from '@/store/modules/entities/mutations';
import state from '@/store/modules/entities/state';

export default {
    state,
    getters: {
        ...gettersById,
        ...gettersByRelation,
    },
    mutations,
    actions: {
        ...actionsCreate,
        ...actionsRead,
        ...actionsUpdate,
        ...actionsDelete,
    },
};
