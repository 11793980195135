<template>
    <div class="cbx--photo--container">
        <base-photo-sport
            :type="competition.type"
            :colour="competitionLayout.colourDefault"
        >
            <div class="cbx--photo">
                <h4 class="mab16">
                    <router-link
                        :to="{
                            name: 'minisite-landing',
                            params: competition,
                            alias: true,
                        }"
                    >
                        {{ competition.type | asType }}
                    </router-link>
                </h4>
                <!-- <div class="cbx--payment">
                    <template v-if="hasPrices">
                        <span class="par10">{{ say.price }}</span>&nbsp;
                        <span
                            v-if="hasDifferentPrices"
                        >{{ competitionPrice.minPrice | asPrice }} -
                            {{ competitionPrice.maxPrice | asPrice }}</span>
                        <span v-else>{{
                            competitionPrice.minPrice | asPrice
                        }}</span>
                    </template>
                    <span v-else-if="hasClassificationWithPay">{{
                        say.according_to_regulation
                    }}</span>
                    <span v-else>{{ say.competitions_feeless }}</span>
                </div> -->
            </div>
        </base-photo-sport>
    </div>
</template>

<script>
export default {
    props: {
        competition: {
            type: Object,
            required: true,
        },
        competitionLayout: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style scoped>
.item-competition--small .cbx--payment h4 {
    margin: 0;
}
.cbx--photo--container {
    width: 100%;
}
.cbx--photo--container .sport-image__colour {
    height: 110px;
}
.cbx--photo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    height: 100%;
    color: #fff;
}

.cbx--photo h4 {
    word-break: break-all;
    font-size: 18px;
}

.cbx--photo a {
    color: #fff;
}

.insurance .cbx--photo a {
    color: #4a4a4a;
}
.cbx.insurance .cbx--photo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 136px;
}

.cbx.insurance .cbx--photo h4 {
    color: #4a4a4a;
}
</style>
