<template>
    <l-marker
        :draggable="!readOnly"
        :lat-lng="[lat, lng]"
        @ready="hookEvents($event)"
        @click="$emit('click')"
    >
        <!-- Icon. -->
        <l-icon
            :class-name="Object.entries(iconClasses).filter(([cls, x]) => x).map(([cls]) => cls).join(' ')"
            :icon-anchor="[20, 41]"
        >
            <slot name="icon" />
            <!-- pointer icon. -->
            <div :class="pointerClasses" />
        </l-icon>

        <!-- Popup. -->
        <l-popup
            v-if="!readOnly"
            class="marker__popup"
            :options="{closeButton: false, offset: pointAnchorPopup}"
        >
            <slot name="popup" />
        </l-popup>
    </l-marker>
</template>

<script>
import { LMarker, LIcon, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
import { mapGetters } from 'vuex';
import { TRACKPOINT_TYPES } from '@/config';

export default {
    components: {
        LMarker,
        LIcon,
        LPopup,
    },
    props: {
        lat: {
            type: Number,
            required: true,
        },
        lng: {
            type: Number,
            required: true,
        },
        point: {
            type: Object,
            required: true,
        },
        selectedClassificationId: {
            type: Number,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pointAnchorPopup: L.point(10, -40),
            types: TRACKPOINT_TYPES,
        };
    },
    computed: {
        ...mapGetters(['trackpoints']),
        isSelectedClassificationMarker() {
            const trackpoints = [];
            for (const id of this.trackpointsIds) {
                trackpoints.push(this.trackpoints[id]);
            }
            const trackpointsClassifications = new Set();
            for (const track of trackpoints) {
                for (const classification of track.classifications) {
                    trackpointsClassifications.add(classification);
                }
            }
            return trackpointsClassifications.has(
                this.selectedClassificationId,
            );
        },
        trackpointsIds() {
            return Object.values(this.point.trackpoints);
        },
        isMeta() {
            const trackpointsTypes = [];
            for (const id of this.trackpointsIds) {
                trackpointsTypes.push(this.trackpoints[id].type);
            }
            return (
                trackpointsTypes.includes(this.types.meta)
                && this.trackpoints[
                    this.point.trackpoints?.MET
                ].classifications.includes(this.selectedClassificationId)
            );
        },
        iconClasses() {
            return {
                marker: true,
                'marker--with-icons': true,
                'marker--disabled': !this.isSelectedClassificationMarker,
                'marker--red':
                    this.isMeta && this.isSelectedClassificationMarker,
            };
        },
        pointerClasses() {
            return {
                marker__pointer: true,
                'marker__pointer--disabled': !this
                    .isSelectedClassificationMarker,
                'marker__pointer--red':
                    this.isMeta && this.isSelectedClassificationMarker,
            };
        },
    },
    methods: {
        hookEvents(lMarker) {
            // Update latlng on dragend event.
            lMarker.on('dragend', () => this.$emit('change:latlng', lMarker.getLatLng()));
        },
    },
};
</script>

<style>
.marker {
    background-color: var(--color-slate-two);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: auto;
}

.marker--red {
    background-image: linear-gradient(
        to left,
        var(--color-red-orange) 100%,
        var(--color-pinky-red) 1%
    );
}
.marker--disabled {
    background-color: var(--color-bluish-grey);
}
.marker--with-icons {
    position: relative;
    display: flex !important;
    padding: 4px;
    width: unset !important;
    height: unset !important;
    box-sizing: unset;
}
.marker__popup {
    min-width: 240px;
    margin: -14px -20px;
    padding: 8px;
    background-color: var(--color-slate-two);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.marker__pointer {
    position: absolute;
    top: 98%;
    left: 12px;
    mask: url("../assets/images/pointer-black.svg");
    height: 8px;
    width: 16px;
    background: var(--color-slate-two);
}
.marker__pointer--red {
    background-image: linear-gradient(
        to left,
        var(--color-red-orange) 100%,
        var(--color-pinky-red) 1%
    );
}
.marker__pointer--disabled {
    background: var(--color-bluish-grey);
}
</style>
