var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        [
          _vm.empty
            ? _c("div", [
                _c("div", { staticClass: "info-box--grey pa16 matb15" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.say.empty_parents))]),
                  _c("p", [_vm._v(_vm._s(_vm.say.empty_parents_hint))]),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.parentsAssociations, function (association) {
            return _c("user-family-item-parent", {
              key: association.id,
              attrs: { id: association.id },
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }