import api from '@/api';
import {
    AUTOCOMPLETE_COMPETITION_NAME,
    AUTOCOMPLETE_COMPETITION_PLAYER,
    BOOTSTRAP,
    READ_ACCESS_TOKEN,
    READ_LANGS,
    READ_MESSAGES,
    READ_USER,
    READ_USER_ID,
    IMPORT_MEASURES,
    AUTOCOMPLETE_LOCATION,
} from '@/store/actions.type';
import {
    SET_BOOTSTRAPED,
    SET_CLOCK,
    SET_TIME,
    SET_ACCESS_TOKEN,
    SET_ZEN_MODE,
    CLEAR_CLOCK,
    SET_IS_ONLINE,
    SAVE_PROMPT_EVENT,
    CLEAR_PROMPT_EVENT,
    TOGGLE_NAV,
} from '@/store/mutations.type';
import { TIME_INTERVAL, GEOCODE_API_KEY } from '@/config';
import moment from 'moment';
import Cookies from 'js-cookie';

export default {
    state: {
        bootstraped: false,
        clock: null,
        isZenMode: false,
        time: moment(),
        isOnline: true,
        promptEvent: null,
        // Flag for PanelCompetitionNavMobile
        isNavActive: false,
    },
    getters: {
        time: (state) => state.time,
        bootstraped: (state) => state.bootstraped,
        isZenMode: (state) => state.isZenMode,
        route: (state, getters, rootState) => rootState.route,
        isOnline: (state) => state.isOnline,
        promptEvent: (state) => state.promptEvent,
        isNavActive: (state) => state.isNavActive,
    },
    mutations: {
        [SET_BOOTSTRAPED](state) {
            state.bootstraped = true;
        },
        [SET_ACCESS_TOKEN](state, accessToken) {
            api.defaults.headers.common['X-Access-Token'] = accessToken;
        },
        [SET_TIME](state) {
            state.time = moment();
        },
        [SET_ZEN_MODE](state, isZenMode) {
            state.isZenMode = isZenMode;
        },
        [SET_CLOCK](state, clock) {
            state.clock = setInterval(clock, TIME_INTERVAL);
        },
        [CLEAR_CLOCK](state) {
            clearInterval(state.clock);
        },
        [SET_IS_ONLINE](state, isOnline) {
            state.isOnline = isOnline;
        },
        [SAVE_PROMPT_EVENT](state, event) {
            state.promptEvent = event;
        },
        [CLEAR_PROMPT_EVENT](state) {
            state.promptEvent = null;
            Cookies.set('prompt-pwa', 'true', { expires: 7 });
        },
        [TOGGLE_NAV](state) {
            state.isNavActive = !state.isNavActive;
        },
    },
    actions: {
        async [BOOTSTRAP]({ dispatch, commit, getters }) {
            commit(SET_CLOCK, () => commit(SET_TIME));

            await dispatch(READ_ACCESS_TOKEN);

            await Promise.all([dispatch(READ_LANGS), dispatch(READ_MESSAGES, getters.lang), dispatch(READ_USER_ID)]);

            if (getters.userId) {
                await dispatch(READ_USER, getters.userId);
            }

            commit(SET_BOOTSTRAPED);
        },
        async [AUTOCOMPLETE_COMPETITION_PLAYER](context, value ) {
            const data = await api.get(`panel/organizers/${value.id}/player/suggestion`, { params: { value: value.search } });

            return data;
        },
        async [AUTOCOMPLETE_COMPETITION_NAME](context, term) {
            const data = await api.get('/autocompleter/competitions', { params: { term } });

            return data;
        },
        async [AUTOCOMPLETE_LOCATION](context, term) {
            if (term.length < 3) return [];
            const data = await api.get(`/autocomplete/${term}`);

            for (const feature of data) {
                feature.text = feature.city;
            }
            return data;
        },
        async [IMPORT_MEASURES](context, { gate, file }) {
            const httpFile = {
                ...file,
                content: btoa(file.content),
            };

            await api.post(`/measures-importer/${gate.secureId}`, httpFile);
        },
    },
};
