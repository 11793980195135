<template>
    <div
        v-if="ready"
        class="container"
    >
        <h1 class="remote-competition__title">
            {{ say.add_files }}
        </h1>
        <div class="row">
            <!-- Sidebar. -->
            <div class="col-12 col-md-4 col-lg-3">
                <div class="row">
                    <h2>{{ player.firstname }} {{ player.lastname }}</h2>
                </div>
            </div>
            <!-- View. -->
            <div class="col-12 col-md-8 col-lg-9">
                <div class="remote-competition__box bg--gray">
                    <!-- Add files. -->
                    <div
                        class="row remote-competition__box-file transfer-files"
                    >
                        <div class="col-12 col-lg-5 remote-competition__hint">
                            <p class="hide--mobile">
                                {{ say.select_files_to_upload }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-6">
                            <base-input-filearea
                                v-model="inputFiles"
                                :error.sync="errors.files"
                            />
                        </div>
                        <div class="col-12">
                            <p class="show--mobile mab15">
                                {{ say.select_files_to_upload }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-12 col-lg-4 remote-competition__button">
                        <base-button
                            btn1
                            :loading="isLoading"
                            @click="addFiles"
                        >
                            {{ say.add_files }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    READ_PLAYER,
    BOOTSTRAP_MINISITE,
    READ_CLASSIFICATION,
    ADD_FILES,
} from '@/store/actions.type';

export default {
    props: {
        token: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            playerId: null,
            ready: false,
            inputFiles: [],
            errors: {},
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters(['players', 'say', 'classificationsBy', 'competitions']),
        player() {
            return this.players[this.playerId];
        },
        classification() {
            return this.classificationsBy(this.player)[0];
        },
        competition() {
            return this.competitions[this.classification.competition];
        },
    },
    async created() {
        try {
            this.playerId = await this.READ_PLAYER(this.token);
            await this.READ_CLASSIFICATION(this.player.classification);
        } catch (e) {
            this.$router.push({ name: 'main-landing', alias: true });
            return;
        }
        await this.BOOTSTRAP_MINISITE(this.classification.competition);

        this.ready = true;
    },

    methods: {
        ...mapActions([
            READ_PLAYER,
            BOOTSTRAP_MINISITE,
            READ_CLASSIFICATION,
            ADD_FILES,
        ]),
        async addFiles() {
            this.isLoading = true;
            try {
                await this.ADD_FILES({
                    token: this.token,
                    files: this.inputFiles,
                });
                this.isLoading = false;
                this.$router.push({
                    name: 'minisite-transfer-confirmation',
                    params: this.competition,
                    alias: true,
                });
            } catch (e) {
                this.errors = e;
                this.isLoading = false;
            }
        },
        removeFile(fileToRemove) {
            const idx = this.filesInQueue.findIndex(
                (file) => file.name === fileToRemove.name,
            );
            this.filesInQueue.splice(idx, 1);
        },
    },
};
</script>
<style scoped>
.pointer {
    cursor: pointer;
}
.pointer--red {
    color: var(--color-pinky-red);
}
.transfer-files {
    flex-direction: column-reverse;
    margin-top: 0;
}
.hide--mobile {
    display: none;
}
.show--mobile {
    display: block;
}
.mab15 {
    margin-bottom: 15px;
}
@media (min-width: 992px) {
    .transfer-files {
        flex-direction: row-reverse;
    }
    .hide--mobile {
        display: block;
    }
    .show--mobile {
        display: none;
    }
}
</style>
