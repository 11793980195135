<template>
    <div v-if="ready && stoperGate" class="container">
        <div
            class="h100"
            :class="{ height: !stoperGate }"
        >
            <stoper />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    BOOTSTRAP_STOPER,
    TEARDOWN_STOPER,
    SELECT_STOPER_GATE,
} from '@/store/actions.type';
import Stoper from '@/components/Stoper';
import { DEVICE_TYPES } from '@/config';
import _ from 'lodash';

export default {
    components: {
        Stoper,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['stoperGate', 'stoperGates', 'devices']),
    },
    async created() {
        await this.BOOTSTRAP_STOPER({ id: this.id, isSettingPage: false });
        const stoper = _.find(
            this.stoperGates,
            (stoperGate) => this.devices[stoperGate.device].type === DEVICE_TYPES.stoper,
        );
        // Select first gate.
        await this.SELECT_STOPER_GATE(stoper);

        this.ready = true;
    },
    async destroyed() {
        await this.TEARDOWN_STOPER();
    },
    methods: {
        ...mapActions([BOOTSTRAP_STOPER, SELECT_STOPER_GATE, TEARDOWN_STOPER]),
    },
};
</script>

<style scoped>
.height {
    height: 100vh;
}
</style>
