<template>
  <div class="panel-info-box">
    <div class="panel-info-box-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>
.panel-info-box {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  color: #455A64;
  background: #FFF1CC;
  margin-bottom: 40px;
}
.panel-info-box-content {
  display: flex;
  align-items: center;
  gap: 16px;
}
.panel-info-box svg {
  flex-shrink: 0;
}
</style>
