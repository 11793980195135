<template>
    <div
        class="sport-image"
        :class="classPhoto"
    >
        <div
            class="sport-image__colour"
            :style="styleColour"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import { hexToRgb } from '@/utils/text';

export default {
    props: {
        type: {
            type: Number,
            required: true,
        },
        colour: {
            type: String,
            required: true,
        },
    },
    computed: {
        classPhoto() {
            return `sport-image--${this.type}`;
        },
        styleColour() {
            const hex = this.colour;
            const rgb = hexToRgb(hex).join(',');

            return `background-image: linear-gradient(to top, ${hex}, rgba(${rgb}, 0.5))`;
        },
    },
};
</script>
<style scoped>
.sport-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
}
/* bieg */
.sport-image--1 {
    background-image: url('../assets/images/sport-1-@1x.png');
}

.sport-image--2 {
    background-image: url('../assets/images/photo-sport-2.png');
}
/* bieg górski */
.sport-image--6 {
    background-image: url('../assets/images/sport-6-@1x.png');
}
/* bieg narciarski */
.sport-image--11 {
    background-image: url('../assets/images/sport-11-@1x.png');
}
/* ocr */
.sport-image--16 {
    background-image: url('../assets/images/sport-16-@1x.png');
}
/* nordic walking */
.sport-image--21 {
    background-image: url('../assets/images/sport-21-@1x.png');
}
/* duathlon  */
.sport-image--26 {
    background-image: url('../assets/images/sport-26-@1x.png');
}
/*  swimrum */
.sport-image--29 {
    background-image: url('../assets/images/sport-29-@1x.png');
}
/* kolarstwo */
.sport-image--31 {
    background-image: url('../assets/images/sport-31-@1x.png');
}
/* mtb */
.sport-image--36 {
    background-image: url('../assets/images/sport-36-@1x.png');
}
/* rajd przygodowy */
.sport-image--39 {
    background-image: url('../assets/images/sport-39-@1x.png');
}
/* rolki  */
.sport-image--41 {
    background-image: url('../assets/images/sport-41-@1x.png');
}
/* triathlon */
.sport-image--46 {
    background-image: url('../assets/images/sport-46-@1x.png');
}
/* narciarstwo */
.sport-image--51 {
    background-image: url('../assets/images/sport-51-@1x.png');
}
/* plywactwo */
.sport-image--60 {
    background-image: url('../assets/images/sport-60-@1x.png');
}
/* dzieciece */
.sport-image--89 {
    background-image: url('../assets/images/sport-89-@1x.png');
}
/* strzelectwo */
.sport-image--91 {
    background-image: url(../assets/images/sport-91-@1x.png);
}
/* inne */
.sport-image--99 {
    background-image: url('../assets/images/sport-99-@1x.png');
}
@media (min-width: 768px) {
    .sport-image--1 {
        background-image: url('../assets/images/sport-1-@3x.png');
    }
    .sport-image--2 {
        background-image: url('../assets/images/photo-sport-2.png');
    }
    .sport-image--6 {
        background-image: url('../assets/images/sport-6-@3x.png');
    }
    .sport-image--11 {
        background-image: url('../assets/images/sport-11-@3x.png');
    }
    .sport-image--16 {
        background-image: url('../assets/images/sport-16-@3x.png');
    }
    .sport-image--21 {
        background-image: url('../assets/images/sport-21-@3x.png');
    }
    .sport-image--26 {
        background-image: url('../assets/images/sport-26-@3x.png');
    }
    .sport-image--29 {
        background-image: url('../assets/images/sport-29-@3x.png');
    }
    .sport-image--31 {
        background-image: url('../assets/images/sport-31-@3x.png');
    }
    .sport-image--36 {
        background-image: url('../assets/images/sport-36-@3x.png');
    }
    .sport-image--39 {
        background-image: url('../assets/images/sport-39-@3x.png');
    }
    .sport-image--41 {
        background-image: url('../assets/images/sport-41-@3x.png');
    }
    .sport-image--46 {
        background-image: url('../assets/images/sport-46-@3x.png');
    }
    .sport-image--51 {
        background-image: url('../assets/images/sport-51-@3x.png');
    }
    .sport-image--60 {
        background-image: url('../assets/images/sport-60-@3x.png');
    }
    .sport-image--89 {
        background-image: url('../assets/images/sport-89-@3x.png');
    }
    .sport-image--91 {
        background-image: url(../assets/images/sport-91-@3x.png);
    }
    .sport-image--99 {
        background-image: url('../assets/images/sport-99-@3x.png');
    }
}
</style>
