<template>
    <table v-bind="$attrs">
        <thead>
            <slot
                :headers="headers"
                :sort="sort"
                name="header"
            >
                <tr>
                    <th
                        v-for="(header, column) in headers"
                        :key="column"
                        @click="sort(column)"
                    >
                        {{ header.text }}
                    </th>
                </tr>
            </slot>
        </thead>
        <tbody v-if="displayedItems.length">
            <slot
                :headers="headers"
                :displayedItems="displayedItems"
                name="body"
            >
                <tr
                    v-for="(item, row) in displayedItems"
                    :key="row"
                >
                    <td
                        v-for="(header, column) in headers"
                        :key="column"
                    >
                        {{ item[header.value] }}
                    </td>
                </tr>
            </slot>
        </tbody>
        <tbody v-else>
            <tr>
                <td :colspan="headers.length">
                    <slot
                        :search="search"
                        name="no-results"
                    >
                        No results.
                    </slot>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        search: {
            type: String,
            default: '',
        },
        itemsPerPage: {
            type: Number,
            default: 9999,
        },
        page: {
            type: Number,
            default: 1,
        },
        sortColumnDefault: {
            type: String,
            required: false,
        },
        sortReverseDefault: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            sortColumn: this.sortColumnDefault ? this.sortColumnDefault : undefined,
            sortReverse: this.sortReverseDefault ? this.sortReverseDefault : false,
        };
    },
    computed: {
        displayedItems() {
            return this.paginatedItems;
        },
        offset() {
            return (this.page - 1) * this.itemsPerPage;
        },
        pages() {
            return Math.ceil(this.displayedItems.length / this.itemsPerPage);
        },
        searchTokens() {
            return this.search.split(/\s+/).filter((token) => token);
        },
        searchedItems() {
            return _.filter(this.items, (item) => {
                const matchedFields = _.filter(item, (field) => {
                    const matchedTokens = _.filter(this.searchTokens, (token) => {
                        const pattern = new RegExp(token, 'i');

                        return String(field).search(pattern) > -1;
                    });

                    return matchedTokens.length === this.searchTokens.length;
                });

                return matchedFields.length;
            });
        },
        sortValue() {
            return this.headers[this.sortColumn].value;
        },
        sortedItems() {
            if (this.sortColumn === undefined) {
                return this.searchedItems;
            }
            let sortedItems;
            if (this.searchedItems.length > 0 && _.isString(this.searchedItems[0][this.sortValue]) && this.searchedItems[0][this.sortValue].match(/\d\d.\d\d.\d\d\d\d/g)) {
            // sort by date.
                sortedItems = _.sortBy(
                    this.searchedItems,
                    (item) => moment(item[this.sortValue], 'DD.MM.YYYY hh:mm').unix(),
                );
            } else if(this.sortValue === 'lastname' || this.sortValue === 'firstname') {
                sortedItems = this.searchedItems.sort((a, b) => a[this.sortValue].localeCompare(b[this.sortValue]) );
            } else if(this.sortValue === 'clubname') {
                sortedItems = _.sortBy(
                    this.searchedItems,
                    (item) => {
                        if (item[this.sortValue] === "") {
                            return null                     
                        } else {
                            return item[this.sortValue] ? item[this.sortValue].toLowerCase() : item[this.sortValue]
                        }
                    }
                );

            } else if (this.sortValue === 'payment') {
                sortedItems = this.searchedItems.sort((a, b) => {
                    a = a[this.sortValue].split(' PLN')[0];
                    b = b[this.sortValue].split(' PLN')[0];

                    return a - b
                });
            } else {
                sortedItems = _.sortBy(
                    this.searchedItems,
                    (item) => item[this.sortValue],
                );
            }

            return this.sortReverse ? _.reverse(sortedItems) : sortedItems;
        },
        paginatedItems() {
            return _.slice(
                this.sortedItems,
                this.offset,
                this.offset + this.itemsPerPage,
            );
        },
    },
    watch: {
        search() {
            this.$emit('update:page', 1);
        },
    },
    methods: {
        sort(column) {
            this.sortReverse = this.sortColumn === column ? !this.sortReverse : false;
            this.sortColumn = column;
            this.$emit('update-sort', {sortColumn: this.sortColumn, sortReverse: this.sortReverse } );
        },
    },
};
</script>
