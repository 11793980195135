var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-form-section",
    [
      _c("strong", { staticClass: "form__title" }, [
        _vm._v(_vm._s(_vm.say.family_member_data)),
      ]),
      _c("user-form-profile", {
        attrs: { validation: _vm.errors },
        on: {
          "update:validation": function ($event) {
            _vm.errors = $event
          },
        },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c("user-form-insurance", {
        attrs: { validation: _vm.errors },
        on: {
          "update:validation": function ($event) {
            _vm.errors = $event
          },
        },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }