<template>
    <div class="menu">
        <slot />
        <div class="arrow" />
        <base-button-group class="menu-button-group">
            <slot name="menu" />
        </base-button-group>
    </div>
</template>

<script>
export default {
    props: {
        pressed: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.menu:hover .menu-button-group {
    display: block;
}
.menu:hover .arrow {
    display: flex;
    z-index: 22;
}
.menu {
    position: relative;
}
.menu-btn {
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--color-bluish-grey);
}
.menu-btn--active {
    background-color: var(--color-slate);
}
.menu-btn--active:hover {
    background-color: var(--color-slate) !important;
}
.arrow {
    position: absolute;
    height: 20px;
    width: 100%;
    bottom: -19px;
    overflow: hidden;
    display: none;
    justify-content: center;
}
.arrow::before {
    content: "";
    display: block;
    border: 1px solid var(--color-bluish-grey);
    background-color: var(--color-charcoal-grey);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 14px;
    transform: rotate(45deg);
    overflow: hidden;
    z-index: 9999;
    left: calc(50% - 22.5px);
}

.menu-button-group {
    position: absolute;
    list-style-type: none;
    border: solid 1px var(--color-bluish-grey);
    width: 100%;
    top: 60px;
    z-index: 9998;
    background-color: var(--color-charcoal-grey);
    padding: 0;
    margin: 0;
    display: none;
    border-radius: 4px;
}
.menu .menu-button-group >>> div {
    padding: 0 0 0 10px;
    white-space: nowrap;
    height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: solid 1px var(--color-bluish-grey);
    overflow: hidden;
    justify-content: flex-start;
}
.menu .menu-button-group >>> div:last-child {
    border: none;
}
</style>
