var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row input-time--margin-right" }, [
    _c("div", { staticClass: "col-3" }, [
      _c(
        "div",
        { staticClass: "input-time__field" },
        [
          _c("base-input-text", {
            attrs: {
              value: _vm.value.hours,
              hint: "00",
              label: _vm.say.hours,
              error: _vm.error,
              "without-error-message": "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", { ..._vm.value, hours: $event })
              },
              "update:error": function ($event) {
                return _vm.$emit("update:error", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-3" }, [
      _c(
        "div",
        { staticClass: "input-time__field" },
        [
          _c("base-input-text", {
            attrs: {
              value: _vm.value.minutes,
              hint: "00",
              label: _vm.say.minutes,
              error: _vm.error,
              "without-error-message": "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", { ..._vm.value, minutes: $event })
              },
              "update:error": function ($event) {
                return _vm.$emit("update:error", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-3" }, [
      _c(
        "div",
        { staticClass: "input-time__field" },
        [
          _c("base-input-text", {
            attrs: {
              value: _vm.value.seconds,
              hint: "00",
              label: _vm.say.seconds,
              error: _vm.error,
              "without-error-message": "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", { ..._vm.value, seconds: $event })
              },
              "update:error": function ($event) {
                return _vm.$emit("update:error", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "col-3" }, [
      _c(
        "div",
        { staticClass: "input-time__field" },
        [
          _c("base-input-text", {
            attrs: {
              value: _vm.value.milliseconds,
              hint: "00",
              label: _vm.say.centyseconds,
              error: _vm.error,
              "without-error-message": "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.value,
                  milliseconds: $event,
                })
              },
              "update:error": function ($event) {
                return _vm.$emit("update:error", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }