<template>
    <div>
        <div class="bg--gray">
            <div class="container">
                <div class="row result-classification__header">
                    <h3>{{ say.results }}</h3>
                </div>
            </div>
        </div>
        <div
            v-if="ready"
            class="container"
        >
            <minisite-item-classification-result
                v-for="classification of classifications"
                :id="classification.id"
                :key="classification.id"
                :top-player-id="
                    topPlayersIdsByClassification[classification.id]
                "
                :show-top-results="showTopResult"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    BOOTSTRAP_MINISITE,
    READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS,
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
    READ_DECLARE_RESULT_CONFIG,
} from '@/store/actions.type';
import MinisiteItemClassificationResult from '@/components/MinisiteItemClassificationResult';

export default {
    components: {
        MinisiteItemClassificationResult,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            topPlayersIdsByClassification: {},
            ready: false,
            showTopResult: true,
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            'players',
            'classificationsBy',
            'minisiteId',
        ]),
        competition() {
            return this.competitions[this.minisiteId];
        },
        classifications() {
            return this.classificationsBy(this.competition);
        },
    },
    async created() {
        const [playersIds] = await Promise.all([
            this.READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS(this.id),
            this.BOOTSTRAP_MINISITE(this.id),
            this.READ_COMPETITION_CLASSIFICATIONS(this.id),
            this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
        ]);
        this.topPlayersIdsByClassification = {};
        if (this.competition.isRemote) {
            const config = await this.READ_DECLARE_RESULT_CONFIG(this.id);
            // Show top results only for competition with time (not for distance).
            this.showTopResult = config.isTime;
        }
        for (const id of playersIds) {
            const player = this.players[id];

            if (player) {
                this.topPlayersIdsByClassification[player.classification] = id;
            }
        }
        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_COMPETITION_CLASSIFICATIONS_TOP_RESULTS,
            BOOTSTRAP_MINISITE,
            READ_COMPETITION_CLASSIFICATIONS,
            READ_COMPETITION_CLASSIFICATIONS_LIMITS,
            READ_DECLARE_RESULT_CONFIG,
        ]),
    },
};
</script>
