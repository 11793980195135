<template>
    <div class="keyboard">
        <!-- Editing. -->
        <template v-if="!isEditingMeasureNumber">
            <!-- Start button. -->
            <base-button
                v-if="!stoperStarted && !isPanelGate"
                btn1
                class="keyboard__primary-btn"
                :class="
                    isLargeScreen
                        ? 'keyboard__primary-btn--large'
                        : 'keyboard__primary-btn--small'
                "
                @click="START_STOPER()"
            >
                Start
            </base-button>

            <!-- Stop button. -->
            <base-button
                v-else
                btn1
                :disabled="!stoperStarted || stoperStopped"
                class="keyboard__primary-btn"
                :class="
                    isLargeScreen
                        ? 'keyboard__primary-btn--large'
                        : 'keyboard__primary-btn--small'
                "
                @click="ADD_STOPER_MEASURE_NUMBERS(stoperNumber)"
            >
                Stop
            </base-button>
        </template>
        <template v-else>
            <div
                class="row"
                :class="{ palr8: $route.name === 'panel-gate' }"
            >
                <div class="col-6">
                    <base-button
                        btn9
                        class="mar4 cancel-btn"
                        @click="cancelEditNumber()"
                    >
                        Anuluj
                    </base-button>
                </div>
                <div class="col-6">
                    <base-button
                        btn9
                        @click="updateMeasureNumber()"
                    >
                        Zapisz
                    </base-button>
                </div>
            </div>
        </template>

        <!-- Keyboard buttons. -->
        <div
            class="keyboard__keys"
            :class="isLargeScreen ? 'mat16' : 'mat12'"
        >
            <div
                class="row"
                :class="{ palr8: $route.name === 'panel-gate' }"
            >
                <!-- Digit button. -->
                <div
                    v-for="x in 9"
                    :key="x"
                    class="col-4"
                >
                    <base-button
                        btn9
                        class="keyboard__keys-item"
                        :class="[
                            { 'mar4 mab4': ![3, 6, 9].includes(x) },
                            isLargeScreen
                                ? 'keyboard__keys-item--large'
                                : 'keyboard__keys-item--small',
                        ]"
                        @click="
                            isEditingMeasureNumber
                                ? digitMeasureInput(x)
                                : digit(x)
                        "
                    >
                        {{ x }}
                    </base-button>
                </div>

                <!-- Space button. -->
                <div class="col-4">
                    <div
                        class="keyboard__keys-action mar4"
                        :class="
                            isLargeScreen
                                ? 'keyboard__keys-item--large'
                                : 'keyboard__keys-item--small'
                        "
                        @click="space()"
                    >
                        <i class="fas fa-arrow-right" />
                    </div>
                </div>

                <!-- Digit zero button. -->
                <div class="col-4">
                    <base-button
                        btn9
                        class="mar4 keyboard__keys-item"
                        :class="
                            isLargeScreen
                                ? 'keyboard__keys-item--large'
                                : 'keyboard__keys-item--small'
                        "
                        @click="
                            isEditingMeasureNumber
                                ? digitMeasureInput(0)
                                : digit(0)
                        "
                    >
                        0
                    </base-button>
                </div>

                <!-- Backspace button. -->
                <div class="col-4">
                    <div
                        class="keyboard__keys-action"
                        :class="
                            isLargeScreen
                                ? 'keyboard__keys-item--large'
                                : 'keyboard__keys-item--small'
                        "
                        @click="
                            isEditingMeasureNumber
                                ? backspaceMeasureInput()
                                : backspace()
                        "
                    >
                        <i class="fas fa-backspace" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    START_STOPER,
    ADD_STOPER_MEASURE_NUMBERS,
    UPDATE_MEASURE,
} from '@/store/actions.type';
import {
    SET_STOPER_NUMBER,
    SET_STOPER_NUMBERS,
    SET_MEASURE_NUMBER,
    SET_IS_EDITING_MEASURE_NUMBER,
    SET_EDITING_MEASURE,
} from '@/store/mutations.type';

export default {
    props: {
        isLargeScreen: {
            type: Boolean,
            default: false,
        },
        isPanelGate: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters([
            'isZenMode',
            'stoperStarted',
            'stoperNumber',
            'stoperNumbers',
            'measureNumber',
            'isEditingMeasureNumber',
            'editingMeasure',
            'stoperStopped',
        ]),
    },
    methods: {
        ...mapActions([
            START_STOPER,
            ADD_STOPER_MEASURE_NUMBERS,
            UPDATE_MEASURE,
        ]),
        ...mapMutations([
            SET_STOPER_NUMBER,
            SET_STOPER_NUMBERS,
            SET_MEASURE_NUMBER,
            SET_IS_EDITING_MEASURE_NUMBER,
            SET_EDITING_MEASURE,
        ]),
        space() {
            this.SET_STOPER_NUMBERS(
                this.stoperNumbers.concat(this.stoperNumber),
            );
            this.SET_STOPER_NUMBER('');
        },
        backspace() {
            if (this.stoperNumber) {
                this.SET_STOPER_NUMBER(this.stoperNumber.slice(0, -1));
            } else {
                this.SET_STOPER_NUMBERS(this.stoperNumbers.slice(0, -1));
            }
        },
        digit(x) {
            this.SET_STOPER_NUMBER(this.stoperNumber.concat(x));
        },
        digitMeasureInput(x) {
            this.SET_MEASURE_NUMBER(this.measureNumber.concat(x));
        },
        backspaceMeasureInput() {
            if (this.measureNumber.length) {
                this.SET_MEASURE_NUMBER(
                    this.measureNumber.split('').slice(0, -1).join(''),
                );
            }
        },
        updateMeasureNumber() {
            const measure = this.editingMeasure;
            measure.tag = this.measureNumber;
            this.SET_MEASURE_NUMBER('');
            this.UPDATE_MEASURE(measure);
            this.SET_IS_EDITING_MEASURE_NUMBER(false);
            this.SET_EDITING_MEASURE(null);
        },
        cancelEditNumber() {
            this.SET_EDITING_MEASURE(null);
            this.SET_MEASURE_NUMBER('');
            this.SET_IS_EDITING_MEASURE_NUMBER(false);
        },
    },
};
</script>

<style scoped>
.cancel-btn {
    background: transparent;
    border: 1px solid var(--color-bluish-grey);
}
.keyboard {
    width: 100%;
}
.keyboard__keys-item--large {
    box-sizing: border-box;
    height: 56px;
}
.keyboard__keys-item--small {
    box-sizing: border-box;
    height: 44px;
}
.keyboard__keys-item {
    background-color: var(--color-charcoal-grey);
    padding: 7px;
    font-size: 24px;
    font-family: "Bariol Bold";
}
.keyboard__primary-btn {
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 600;
}
.keyboard__primary-btn--large {
    height: 56px;
}
.keyboard__primary-btn--small {
    height: 48px;
}
.keyboard__keys-action {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 100%;
    cursor: pointer;
}
@media (min-width: 992px) {
    .keyboard {
        display: none;
    }
}
</style>
