import store from '@/store';
import { LOGIN_USER } from '@/store/actions.type';

export default async (to, _, next) => {
    const { token } = to.query;
    const { user } = store.getters;

    if (token && !user) {
        // Login via token.
        await store.dispatch(LOGIN_USER, { token });
    }

    if (token) {
        // Remove token from query string.
        next({
            query: {
                ...to.query,
                token: undefined,
            },
            replace: true,
        });
    } else {
        next();
    }
};
