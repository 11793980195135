<template>
    <div class="container">
        <div class="row login__content">
            <!-- Header. -->
            <div class="col-12 col-lg-3">
                <h1 class="par15">
                    {{ say.login }}
                </h1>
            </div>

            <div class="col-12 col-lg-9">
                <!-- Form. -->
                <div class="login-section bg--gray row">
                    <div class="login-section__inputs col-12 col-lg-5">
                        <!-- <base-button
                            btn8
                            @click="continueViaFacebook()"
                        >
                            {{ say.login_via_facebook }}
                        </base-button> -->

                        <p class="mat32">
                            {{ say.type_your_email_and_password }}
                        </p>

                        <!-- Email. -->
                        <base-input-text
                            v-model="credentials.email"
                            :label="say.email_address"
                            :error.sync="errors.email"
                            class="mat24"
                        />

                        <!-- Password. -->
                        <base-input-password
                            v-model="credentials.plainPassword"
                            :label="say.password"
                            :error.sync="errors.plainPassword"
                            class="mat24"
                        />

                        <p class="mat16">
                            {{ say.forgotten_password }}
                            <router-link
                                class="c-orange"
                                :to="{
                                    name: 'authentication-reset',
                                    alias: true,
                                }"
                            >
                                {{ say.reset_password }}
                            </router-link>
                        </p>
                    </div>
                </div>

                <!-- Buttons. -->
                <div class="row login__buttons-box">
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn1
                            class="login__btn-primary"
                            @click="loginUser()"
                        >
                            {{ say.login_to_account }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn4
                            class="login__btn-secondary"
                            @click="
                                $router.replace({
                                    name: 'authentication-register',
                                    alias: true,
                                })
                            "
                        >
                            {{ say.create_new_account }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn4
                            class="login__btn-cancel"
                            @click="$router.back()"
                        >
                            {{ say.cancel }}
                        </base-button>
                    </div>
                </div>

                <!-- Register without account. -->
                <div
                    v-if="showRegisterWithoutLogin"
                    class="row register-without-account"
                >
                    <div class="col-12 col-lg-5">
                        <p class="d-inline-block">
                            {{ say.fast_register_ask }}
                        </p>
                        <p class="d-inline-block">
                            {{ say.collect_results_ask }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn4
                            class="register-without-account__btn"
                            @click="registerWithoutLogin"
                        >
                            {{ say.apply_for_participation_without_account }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOGIN_USER, CONTINUE_VIA_FACEBOOK, READ_USER_NOTIFICATIONS, READ_COMPETITION } from '@/store/actions.type';
import { getRouteByLoginTo, getRouteToPanel } from '@/service/profiles';
import handleLoggedUser from '@/router/guards/login';

export default {
    data() {
        return {
            credentials: {
                email: '',
                plainPassword: '',
            },
            errors: {},
            showRegisterWithoutLogin: false,
        };
    },
    computed: {
        ...mapGetters(['say', 'user', 'createNotifications', 'competitions']),
        isRedirect() {
            return this.$route.query.redirect;
        },
        redirect() {
            return this.$router.match(this.$route.query.redirect ?? '');
        },
        competition() {
            return this.competitions[this.redirect?.params.id];
        },
    },
    beforeRouteEnter: handleLoggedUser,
    async created() {
        if (this.redirect?.params.id) {
            await this.READ_COMPETITION(this.redirect.params.id);
            if (this.$router.matched('minisite', this.redirect) && !this.competition?.cycle) this.showRegisterWithoutLogin = true
        }
    },
    methods: {
        ...mapActions([LOGIN_USER, CONTINUE_VIA_FACEBOOK, READ_USER_NOTIFICATIONS, READ_COMPETITION]),
        async loginUser() {
            this.errors = {};
            try {
                await this.LOGIN_USER(this.credentials);
                this.READ_USER_NOTIFICATIONS();
                this.navigateAfterLogin();
            } catch (e) {
                this.errors = e;
            }
        },
        async continueViaFacebook() {
            try {
                await this.CONTINUE_VIA_FACEBOOK();
                this.READ_USER_NOTIFICATIONS();
                this.navigateAfterLogin();
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
        navigateAfterLogin() {
            let route = getRouteByLoginTo(this.user);
            console.log(this.createNotifications)
            if(this.createNotifications === true) {
                route = { name: 'user-notifications', alias: true };
            } else {
                if (!this.user.roles.length) {
                    route = { name: 'authentication-choose-profile', alias: true };
                } else if (this.$route.query.to === 'panel') {
                    route = getRouteToPanel(this.user);
                } else if (this.isRedirect) {
                    route = { ...this.redirect, alias: true };
                }
            }
            this.$router.push(route);
        },
        _keyListener(e) {
            if (e.code === "Enter") { 
                this.loginUser();
            }
        },
        registerWithoutLogin() {
            const url = this.$router.resolve({path: this.redirect.fullPath, query: { skip: 'true'}})
            window.location.href = url.href
        }
    },
    mounted() {
        window.addEventListener('keypress', this._keyListener);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this._keyListener);
    },    
};
</script>
