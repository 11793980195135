var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "remote-competition__title" }, [
          _vm._v(" " + _vm._s(_vm.say.add_files) + " "),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4 col-lg-3" }, [
            _c("div", { staticClass: "row" }, [
              _c("h2", [
                _vm._v(
                  _vm._s(_vm.player.firstname) +
                    " " +
                    _vm._s(_vm.player.lastname)
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-md-8 col-lg-9" }, [
            _c("div", { staticClass: "remote-competition__box bg--gray" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row remote-competition__box-file transfer-files",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-5 remote-competition__hint" },
                    [
                      _c("p", { staticClass: "hide--mobile" }, [
                        _vm._v(
                          " " + _vm._s(_vm.say.select_files_to_upload) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-6" },
                    [
                      _c("base-input-filearea", {
                        attrs: { error: _vm.errors.files },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(_vm.errors, "files", $event)
                          },
                        },
                        model: {
                          value: _vm.inputFiles,
                          callback: function ($$v) {
                            _vm.inputFiles = $$v
                          },
                          expression: "inputFiles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12" }, [
                    _c("p", { staticClass: "show--mobile mab15" }, [
                      _vm._v(
                        " " + _vm._s(_vm.say.select_files_to_upload) + " "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 remote-competition__button" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { btn1: "", loading: _vm.isLoading },
                      on: { click: _vm.addFiles },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.add_files) + " ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }