<template>
    <div class="container">
        <div class="row mat32">
            <div class="col-lg-12">
                <h3>Nowe zawody</h3>
            </div>
        </div>
        <div class="row justify-content-end mat16">
            <div class="col-lg-10">
                <panel-competition-form
                    v-model="competition"
                    :validation.sync="errors"
                    @save="createCompetition()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PanelCompetitionForm from '@/components/PanelCompetitionForm';
import { mapActions, mapGetters } from 'vuex';
import { CREATE_COMPETITION } from '@/store/actions.type';

export default {
    components: {
        PanelCompetitionForm,
    },
    props: {
        organizerId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            competition: {
                descriptionPl: '',
                location: '',
                locationLat: null,
                locationLng: null,
                name: '',
                provisionTime: null,
                remarksPl: '',
                startedTime: null,
                type: null,
                websitePl: '',
                isRemote: false,
                preStartedTime: null,
                contactEmail: '',
                contactPhone: '',
                organizer: this.organizerId,
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['competitions']),
    },
    methods: {
        ...mapActions([CREATE_COMPETITION]),
        async createCompetition() {
            try {
                const competitionId = await this.CREATE_COMPETITION(
                    this.competition,
                );
                this.$router.push({
                    name: 'panel-competition-dashboard',
                    params: this.competitions[competitionId],
                    alias: true,
                });
            } catch (e) {
                this.errors = e;
            }
        },
    },
};
</script>

<style scoped>
.competition__date {
    color: black;
}
.competition__date >>> .filters-datepicker__calendar-icon {
    top: 13px;
}
.form-input__competition-type--active {
    background-color: var(--color-dark-pastel-green);
}
.form-input__location >>> .vs__selected-options:focus-within .vs__selected {
    opacity: 0 !important;
}

.form-input__location >>> .vs__selected {
    display: flex !important;
}

.form-input__location {
    max-height: 40px;
    min-height: 40px;
}

.form-input__location >>> .vs__selected,
.form-input__location >>> .vs__search {
    font-family: 'Open Sans' !important;
    font-size: 14px !important;
    color: var(--color-black) !important;
    position: absolute;
    height: 100%;
    margin: 0;
}

.form-input__location >>> .autocompleter-competition__input {
    transition: all var(--animation-fast);
}
.form-input__hint {
    line-height: 21px;
    margin-left: 15px;
}
.form-input__label {
    margin-bottom: 8px;
}
.form-input__label--positive {
    color: var(--color-light-sage);
}
.form-map {
    overflow: hidden;
}
.form-map__map {
    width: 100%;
    height: 300px;
}
.fade-map-enter {
    max-height: 0;
}

.fade-map-enter-to {
    max-height: 300px;
}

.fade-map-leave-to {
    max-height: 0;
}

.fade-map-enter-active,
.fade-map-leave-active {
    transition: all 0.6s;
}
</style>
