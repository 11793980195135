var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mat40" },
    [
      _vm.newAgreements && Object.keys(_vm.newAgreements).length > 0
        ? _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Umowy wymagane do zaakceptowania")]),
              ]),
              _c(
                "div",
                { staticClass: "agreement-items" },
                _vm._l(_vm.newAgreements, function (agreement, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "agreement-item" },
                    [
                      _c("b", { staticClass: "agreement-name" }, [
                        _vm._v(
                          "Umowa z " +
                            _vm._s(_vm.getOperatorName(agreement.contractor))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "agreement-date" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.getSampleAgreement(
                                    agreement.principal,
                                    agreement.contractor
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Pobierz wzór umowy")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "agreement-actions" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn1: "", short: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.postAgreement(agreement)
                                },
                              },
                            },
                            [_c("span", [_vm._v("AKCEPTUJĘ")])]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.agreements && Object.keys(_vm.agreements).length > 0
        ? _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Moje umowy")]),
              ]),
              _c(
                "div",
                { staticClass: "agreement-items" },
                _vm._l(_vm.agreements, function (agreement, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "agreement-item" },
                    [
                      _c("b", { staticClass: "agreement-name" }, [
                        _vm._v("Umowa z " + _vm._s(agreement.fvFirmname)),
                      ]),
                      _c("b", { staticClass: "agreement-date" }, [
                        _vm._v(
                          _vm._s(_vm.timestampToDate(agreement.addedTime))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "agreement-actions" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadAgreement(
                                    agreement.secretId
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("Pobierz umowę")])]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }