var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 confirmation" }, [
        _c("h1", [
          _vm._v(" " + _vm._s(_vm.say.create_organizer_profile_to_go) + " "),
        ]),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row profile" }, [
          _c("div", { staticClass: "col-12 col-lg-9" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row profile__option" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "profile__option-box" }, [
                    _c("p", { staticClass: "profile__option-title" }, [
                      _vm._v(" " + _vm._s(_vm.say.organizes_competition) + " "),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.say.create_organizer_profile_for) +
                          ": "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.create_competition_page) + " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.start_online_registration) + " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " • " + _vm._s(_vm.say.measure_results_stoper) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-12 profile__option-btn" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-5" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn2: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseOrganizer()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.say.create_organizer_profile) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("img", {
        staticClass: "profile__option-image",
        attrs: { src: require("@/assets/images/stoper-icon.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }