import { schema } from 'normalizr';

export default new schema.Entity('competitionsSettings', {}, {
    idAttribute: 'competition', // one-to-one relation
    processStrategy: (competitionSetting) => ({
        // Meta.
        _type: 'competitionSetting',
        // Fields.
        ...competitionSetting,
    }),
});
