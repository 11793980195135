import api from '@/api';
import schema from '@/api/schema';
import {
    CREATE_CHECKPOINT,
    CREATE_DEVICE,
    CREATE_GATE,
    CREATE_MEASURE,
    CREATE_RESULT,
    CREATE_FILES,
    CREATE_SESSION,
    CREATE_USER,
    CREATE_USER_CHILD,
    CREATE_LOCATION,
    CREATE_TRACKPOINT,
    CREATE_CLASSIFICATION_GPX_FILE,
    CREATE_PLAYER_ADDRESS,
    CREATE_USER_AVATAR,
    CREATE_ORGANIZER,
    CREATE_OPERATOR,
    CREATE_COMPETITION,
    CREATE_PLAYER_MEASURES,
    CREATE_PANEL_COMPETITION_PLAYERS_INPUTS,
    CREATE_PLAYER,
} from '@/store/actions.type';
import { SET_ENTITIES, SET_OFFLINE_MEASURE, CLEAR_OFFLINE_MEASURE } from '@/store/mutations.type';
import { normalize } from 'normalizr';

export default {
    async [CREATE_USER_CHILD]({ commit }, child) {
        const data = await api.post('/user/children', child);

        const normalized = normalize(data, schema.user);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_SESSION](context, credentials) {
        const data = await api.post('/auth', credentials);

        return data;
    },
    async [CREATE_USER]({ commit }, user) {
        const data = await api.post('/users', user);

        const normalized = normalize(data, schema.user);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_USER_AVATAR]({ commit, getters }, avatar) {
        // eslint-disable-next-line no-param-reassign
        avatar.content = btoa(avatar.content); // base64 encode
        const data = await api.post('/user/avatar', { files: [avatar] });

        commit(SET_ENTITIES, {
            users: {
                [getters.user.id]: {
                    ...getters.user,
                    avatar: `${data}?${Math.random()}`,
                },
            },
        });
    },
    async [CREATE_RESULT](context, { token, result }) {
        await api.post(`/players/${token}/result`, result);
    },
    async [CREATE_PLAYER_ADDRESS](context, { token, address }) {
        await api.post(`/players/${token}/address`, address);
    },
    async [CREATE_FILES](context, { token, files, isResult = false }) {
        for (const file of files) {
            file.content = btoa(file.content); // base64 encode
        }
        if (isResult) {
            await api.post(`/players/${token}/result-files`, { files });
        } else {
            await api.post(`/players/${token}/files`, { files });
        }
    },
    async [CREATE_CHECKPOINT]({ commit }, checkpoint) {
        const data = await api.post('/checkpoints', checkpoint);

        const normalized = normalize(data, schema.checkpoint);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_DEVICE]({ commit }, device) {
        const data = await api.post('/devices', device);

        const normalized = normalize(data, schema.device);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_GATE]({ commit }, gate) {
        const data = await api.post('/gates', gate);

        const normalized = normalize(data, schema.gate);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_MEASURE]({ commit, getters }, measure) {
        commit(SET_OFFLINE_MEASURE, measure);

        const data = await api.post('/measures', measure);
        if (data) {
            const offlineMeasureIndex = getters.stoperOfflineMeasures.findIndex((offlineMeasure) => offlineMeasure.deviceTime.isSame(measure.deviceTime));
            if (offlineMeasureIndex >= 0) {
                const offlineMeasure = getters.stoperOfflineMeasures[offlineMeasureIndex];
                if (offlineMeasure.tag !== data.tag) {
                    data.tag = offlineMeasure.tag;
                }
                commit(CLEAR_OFFLINE_MEASURE, offlineMeasureIndex);
            }
            const normalized = normalize(data, schema.measure);

            commit(SET_ENTITIES, normalized.entities);
            return normalized.result;
        }
        return null;
    },
    async [CREATE_LOCATION]({ commit }, location) {
        const data = await api.post('/locations', location);

        const normalized = normalize(data, schema.location);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_TRACKPOINT]({ commit }, trackpoint) {
        const data = await api.post('/trackpoints', trackpoint);

        const normalized = normalize(data, schema.trackpoint);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_CLASSIFICATION_GPX_FILE]({ commit }, { gpxFile, id }) {
        // eslint-disable-next-line no-param-reassign
        gpxFile.content = btoa(gpxFile.content);

        const data = await api.post(`/classifications/${id}/gpx-file`, gpxFile);

        const gpxFileEntity = {
            classification: id,
            url: `${data}?v=${Math.random()}`,
        };

        const normalized = normalize(gpxFileEntity, schema.gpxFile);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_ORGANIZER]({ commit }, organizer) {
        const data = await api.post('/panel/organizers', organizer);

        const normalized = normalize(data, schema.organizer);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_OPERATOR]({ commit }, operator) {
        const data = await api.post('/panel/operators', operator);

        const normalized = normalize(data, schema.operator);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_COMPETITION]({ commit }, competition) {
        const data = await api.post('/panel/competitions', competition);

        const normalized = normalize(data, schema.competition);

        commit(SET_ENTITIES, normalized.entities);

        return normalized.result;
    },
    async [CREATE_PLAYER_MEASURES](context, payload) {
        const data = await api.post(`/players/${payload.id}/measures`, payload.measure);

        return data
    },
    async [CREATE_PANEL_COMPETITION_PLAYERS_INPUTS](context, payload) {
        const data = await api.post(`/panel/inputs`, payload);

        return data
    },
    async [CREATE_PLAYER](context, payload) {
        const data = await api.post(`/panel/players`, payload);

        return data
    },
};
