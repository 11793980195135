import checkpoint from '@/api/schema/checkpoint';
import measure from '@/api/schema/measure';
import player from '@/api/schema/player';
import { schema } from 'normalizr';

export default new schema.Entity('results', {
    checkpoint,
    measure,
    player,
}, {
    idAttribute: (result) => `${result.checkpoint}_${result.measure}_${result.player}`, // non-persistent entity
    processStrategy: (result) => ({
        // Meta.
        _type: 'result',
        // Fields.
        ...result,
    }),
});
