var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row add-person" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h1", { staticClass: "add-person__title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isOperator ? "Dodaj operatora" : "Dodaj organizatora"
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-4" }, [
        _c(
          "div",
          { staticClass: "add-person__box" },
          [
            _c("p", { staticClass: "add-person__box-label" }, [
              _vm._v(" E-mail "),
            ]),
            _c("base-input-text", {
              staticClass: "add-person__input",
              attrs: { error: _vm.errors.email, label: "E-mail" },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "email", $event)
                },
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-12 col-lg-4" }, [
        _c(
          "div",
          { staticClass: "add-person__box" },
          [
            _c("p", { staticClass: "add-person__box-label" }, [
              _vm._v(" Nazwa "),
            ]),
            _c("base-input-text", {
              staticClass: "add-person__input",
              attrs: { label: "Nazwa", error: _vm.errors.name },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "name", $event)
                },
              },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-12 col-lg-4" }, [
        _c(
          "div",
          { staticClass: "add-person__box" },
          [
            _c("p", { staticClass: "add-person__box-label" }, [
              _vm._v(" Telefon (opcjonalnie) "),
            ]),
            _c("base-input-text", {
              staticClass: "add-person__input",
              attrs: { label: "Telefon" },
              model: {
                value: _vm.phone,
                callback: function ($$v) {
                  _vm.phone = $$v
                },
                expression: "phone",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row add-person__buttons" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-2" },
            [
              _c(
                "base-button",
                {
                  staticClass: "add-person__btn",
                  attrs: { btn2: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(" Anuluj ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2" },
            [
              _c(
                "base-button",
                {
                  staticClass: "add-person__btn",
                  attrs: { btn1: "" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v(" Dodaj ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }