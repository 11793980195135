<template>
    <div
        class="input-field input-field--focus-bolder"
        :class="{'input-field--error' : error, 'input-field--short': isShort}"
    >
        <label
            v-if="(!error || withoutErrorMessage) && label"
            class="input-field__label"
            :class="{'input-field__label--show' : isInput}"
        >{{ label }}</label>

        <input
            class="input-field__input"
            :type="type"
            :class="{
                'input-field__input--padding-bottom' : error && !withoutErrorMessage,
                'input-field__input--padding-top' : (isInputValid || withoutErrorMessage) && label
            }"
            :value="value"
            :placeholder="placeholder"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', $event.target.value)"
            @keyup.enter="$emit('enter')"
        >

        <i
            v-if="icon"
            class="input-field__icon"
            :class="icon"
            @click="$emit('icon-click')"
        />

        <span
            class="input-error"
            :class="{'input-error--show' : error && !withoutErrorMessage}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
    },
    mixins: [input],
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
