<template>
    <main
        v-if="ready"
        class="container"
    >
        <div class="row">
            <!-- Miniside sidebar. -->
            <minisite-sidebar />

            <!-- Minisite contact. -->
            <div class="col-12 col-md-9 mat33 pat15">
                <div class="row minisite-contact">
                    <div class="col-12">
                        <h3 class="pab16 inf">
                            {{ say.contact_with_organizer }}
                        </h3>
                    </div>
                    <div class="col-12 mab48">
                        <div class="bgarea pa24">
                            <div class="form-wrapper">
                                <div class="row d-md-none mab16">
                                    <div class="col-12">
                                        {{ say.do_you_want_ask }}
                                    </div>
                                </div>

                                <div class="row mab16">
                                    <div class="col-12 col-md-6">
                                        <div class="form-input">
                                            <base-input-text
                                                v-model="mail.name"
                                                :label="
                                                    say.firstname_and_lastname
                                                "
                                                :error.sync="errors.name"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 d-md-block">
                                        <div class="form-description">
                                            {{ say.do_you_want_ask }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row mab16">
                                    <div class="col-12 col-md-6">
                                        <div class="form-input">
                                            <base-input-text
                                                v-model="mail.email"
                                                :label="say.email_address"
                                                :error.sync="errors.email"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 d-md-block">
                                        <div class="form-description">
                                            {{ say.organizer_email }}:
                                            <strong>{{
                                                competition.contactEmail
                                            }}</strong>
                                            <br>
                                            {{ say.organizer_phone }}:
                                            <strong>{{
                                                competition.contactPhone
                                            }}</strong>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mab16">
                                    <div class="col-12 col-md-6">
                                        <div class="form-input">
                                            <base-input-area
                                                v-model="mail.message"
                                                :label="say.message_content"
                                                :rows="5"
                                                :error.sync="errors.message"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row d-md-none mab16">
                                    <div class="col-12">
                                        <div class="form-description">
                                            {{ say.organizer_email }}:
                                            <strong>{{
                                                competition.contactEmail
                                            }}</strong>
                                            <br>
                                            {{ say.organizer_phone }}:
                                            <strong>{{
                                                competition.contactPhone
                                            }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-end">
                        <div class="col-12 col-md-5 col-lg-4">
                            <base-button
                                btn1
                                class="mab20"
                                @click="send()"
                            >
                                {{ say.send }}
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MinisiteSidebar from '@/components/MinisiteSidebar';
import { SEND_CONTACT_MAIL, BOOTSTRAP_MINISITE } from '@/store/actions.type';

export default {
    components: {
        MinisiteSidebar,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            mail: {
                name: '',
                email: '',
                message: '',
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['say', 'competitions', 'minisiteId']),
        competition() {
            return this.competitions[this.minisiteId];
        },
    },
    async created() {
        await this.BOOTSTRAP_MINISITE(this.id);

        this.ready = true;
    },
    methods: {
        ...mapActions([BOOTSTRAP_MINISITE, SEND_CONTACT_MAIL]),
        async send() {
            this.errors = {};
            try {
                await this.SEND_CONTACT_MAIL(this.mail);

                this.$router.go(-1);
            } catch (e) {
                this.errors = e;
            }
        },
    },
};
</script>
<style scoped>
.bgarea {
    border-radius: 4px;
    background-color: rgba(84, 110, 122, 0.1);
}
.form-wrapper {
    max-width: 650px;
    margin: 0 auto;
}
.d-md-block {
    display: none;
}

@media (min-width: 768px) {
    .minisite-contact {
        margin-left: 15px;
    }
    .d-md-none {
        display: none;
    }
    .d-md-block {
        display: block;
    }
    .form-input {
        margin-right: 15px;
    }
    .form-description {
        margin-left: 15px;
    }
}
</style>
