import api from '@/api';
import {
    CONTINUE_VIA_FACEBOOK,
    CREATE_SESSION,
    CREATE_USER,
    DELETE_SESSION,
    LOGIN_USER,
    LOGOUT_USER,
    READ_USER,
    READ_USER_ID,
    REGISTER_USER,
    RESET_PASSWORD,
    RESET_PASSWORD_LINK,
    READ_ACCESS_TOKEN,
    READ_FACEBOOK_SIGNED_REQUEST,
} from '@/store/actions.type';
import { SET_USER_ID, SET_ACCESS_TOKEN } from '@/store/mutations.type';
import { IS_PRODUCTION_ENV } from '@/config';
// import facebookSdk from '@p803/facebook-sdk';

export default {
    state: {
        userId: null,
    },
    getters: {
        userId: (state) => state.userId,
        user: (state, getters) => getters.users[state.userId],
    },
    mutations: {
        [SET_USER_ID](state, userId) {
            state.userId = userId;
        },
    },
    actions: {
        async [LOGIN_USER]({ dispatch, commit }, credentials) {
            const id = await dispatch(CREATE_SESSION, credentials);

            await dispatch(READ_USER, id);

            commit(SET_USER_ID, id);
        },
        async [REGISTER_USER]({ dispatch }, credentials) {
            await dispatch(CREATE_USER, credentials);
            await dispatch(LOGIN_USER, credentials);
        },
        async [CONTINUE_VIA_FACEBOOK]({ dispatch }) {
            const signedRequest = await dispatch(READ_FACEBOOK_SIGNED_REQUEST);

            try {
                await dispatch(LOGIN_USER, { signedRequest });
            } catch (e) {
                // const sdk = await facebookSdk();
                // const loginStatus = await sdk.getLoginStatus(true);
                // await dispatch(REGISTER_USER, { signedRequest: loginStatus.authResponse.signedRequest });
            }
        },
        async [LOGOUT_USER]({ dispatch, commit }) {
            await dispatch(DELETE_SESSION);

            // const sdk = await facebookSdk();
            // const loginStatus = await sdk.getLoginStatus();

            // if (loginStatus.status === 'connected') {
            //     await sdk.logout();
            // }

            commit(SET_USER_ID, null);
        },
        async [RESET_PASSWORD](context, email) {
            await api.post('/password-reset', {
                email,
            });
        },
        async [RESET_PASSWORD_LINK](context, email) {
            const data = await api.get('/password-reset-link', {
                email,
            });
            return data
        },
        async [READ_USER_ID]({ commit }) {
            const data = await api.get('/auth');

            commit(SET_USER_ID, data);
        },
        async [READ_ACCESS_TOKEN]({ commit }) {
            const host = IS_PRODUCTION_ENV ? window.location.origin : 'https://sandbox.dostartu.pl';

            const data = await api.get(`${host}/read_access_token.php`);

            commit(SET_ACCESS_TOKEN, data);
        },
    },
};
