import { schema } from 'normalizr';

export default new schema.Entity('ages', {}, {
    idAttribute: 'classification', // one-to-one relation
    processStrategy: (age) => ({
        // Meta.
        _type: 'age',
        // Fields.
        ...age,
    }),
});
