var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.data.fType === "fi"
        ? _c("panel-input-text", {
            staticClass: "mab24",
            attrs: {
              label: _vm.data.fNamePl,
              value: _vm.$attrs.value,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fl"
        ? _c("panel-input-area", {
            staticClass: "mab24",
            attrs: {
              label: _vm.data.fNamePl,
              value: _vm.$attrs.value,
              rows: 2,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fr"
        ? _c("panel-input-radio", {
            key: `${_vm.data.fType}_${_vm.data.id}_key`,
            staticClass: "mab24",
            attrs: {
              name: `${_vm.data.fType}_${_vm.data.id}`,
              label: _vm.data.fNamePl,
              value: _vm.$attrs.value,
              options: _vm.radioOptions,
              error: _vm.localErrors,
              wrap: _vm.options?.fr?.wrap,
            },
            on: {
              input: _vm.radioOnInput,
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fs"
        ? _c("panel-input-select", {
            staticClass: "mab24",
            attrs: {
              "select-label": _vm.data.fNamePl,
              label: "",
              "track-by": "",
              options: _vm.data.fDescriptionPl,
              "hide-selected": false,
              value: _vm.$attrs.value,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fj"
        ? _c("panel-input-text", {
            staticClass: "mab24",
            attrs: {
              label: _vm.data.fNamePl,
              hint: _vm.data.fDescriptionPl,
              value: _vm.$attrs.value,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fh"
        ? _c("panel-input-date", {
            staticClass: "mab24",
            attrs: {
              label: _vm.data.fNamePl,
              mode: "time",
              "model-config": {
                type: "string",
                mask: "hh.mm",
              },
              placeholder: "hh.mm",
              value: _vm.$attrs.value,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fd"
        ? _c("panel-input-date", {
            staticClass: "mab24",
            attrs: {
              label: _vm.data.fNamePl,
              placeholder: "dd.mm.yyyy",
              value: _vm.$attrs.value,
              error: _vm.localErrors,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              "update:error": function ($event) {
                _vm.localErrors = $event
              },
            },
          })
        : _vm.data.fType === "fc"
        ? _c(
            "panel-input-checkbox",
            {
              staticClass: "mab24",
              attrs: {
                "checkbox-value": {
                  value: _vm.data.id,
                  text: _vm.data.fNamePl || _vm.data.fDescriptionPl,
                },
                name: `input_ids_add[${_vm.data.id}]`,
                value: _vm.$attrs.value,
                error: _vm.localErrors,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
                "update:error": function ($event) {
                  _vm.localErrors = $event
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.data.fNamePl || _vm.data.fDescriptionPl) + " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }