<template>
    <div
        v-if="ready"
        class="container"
    >
        <div class="row">
            <h2 class="mat40">
                {{ say.pay_for_participation }}
            </h2>
        </div>
        <div class="row mat40">
            <div class="col-12 col-lg-2 mab16">
                <h4>{{ say.email_paylink }}</h4>
            </div>

            <!-- Send paylink. -->
            <div class="col-12 col-lg-9">
                <div class="malr15">
                    <div class="pa24 bg--gray ma0">
                        <p class="f16">
                            {{ say.paylink_hint }}
                        </p>
                        <p class="f16 mat16">
                            {{ say.participation_pay_hint }}
                        </p>
                    </div>
                    <div class="row minisite-pay__btn-send">
                        <div class="col-12 col-lg-4">
                            <base-button
                                btn1
                                @click="send()"
                            >
                                {{ say.email_paylink }}
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mat48">
            <!-- Create new account  -->
            <div class="col-12 col-lg-2 mab16">
                <h4>{{ say.or_create_account }}</h4>
                <p class="minisite-pay__register-hint">
                    {{ say.register_email_hint }}
                </p>
            </div>

            <div class="col-12 col-lg-9">
                <div class="pa24 bg--gray ma0">
                    <h3>{{ say.create_account_to_access }}:</h3>
                    <p
                        class="minisite-pay__hint minisite-pay__hint--first"
                    >
                        {{ say.lists_upcoming_competition }}
                    </p>
                    <p class="minisite-pay__hint">
                        {{ say.easy_registration }}
                    </p>
                    <p class="minisite-pay__hint">
                        {{ say.list_your_results }}
                    </p>
                    <p class="minisite-pay__hint">
                        {{ say.list_of_insurance_polices }}
                    </p>
                </div>

                <div class="row mat48 minisite-pay__buttons">
                    <div class="col-12 col-lg-3 minisite-pay__btn-back">
                        <base-button
                            btn4
                            class="text-upper"
                            @click="$router.go(-1)"
                        >
                            {{ say.step_back }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn2
                            class="text-upper"
                            @click="$router.push({name: 'authentication-register', alias: true})"
                        >
                            {{ say.create_new_account }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    READ_PLAYER,
    SEND_PAYLINK_MAIL,
    BOOTSTRAP_MINISITE,
} from '@/store/actions.type';

export default {
    props: {
        playerId: {
            type: Number,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['say', 'players', 'competitions', 'minisiteId']),
        player() {
            return this.players[this.playerId];
        },
        competition() {
            return this.competitions[this.minisiteId];
        },
    },
    async created() {
        await this.READ_PLAYER(this.playerId);
        await this.BOOTSTRAP_MINISITE(this.id);

        this.ready = true;
    },
    methods: {
        ...mapActions([READ_PLAYER, BOOTSTRAP_MINISITE, SEND_PAYLINK_MAIL]),
        async send() {
            await this.SEND_PAYLINK_MAIL(this.player);

            this.$router.push({
                name: 'minisite-paylink-confirmation',
                params: this.competition,
                alias: true,
            });
        },
    },
};
</script>
