<template>
    <div class="row widget">
        <div v-show="ready"  class="col-12">
            <div class="row widget__header">
                <div class="col-12 col-lg-unset widget__header-competition">
                    {{ competition.name }}
                </div>
                <template
                    v-if="
                            Object.keys(userPlayers).length &&
                            (competition.status == 2 || competition.isRemote || competition.status >= 5 )
                    "
                >                
                    <div class="col-12 col-lg-unset widget__header-classification">
                        <span v-for="player of userPlayers" :key="player.id">{{ classifications[player.classification].namePl }}</span>
                    </div>
                </template>
            </div>
            <div class="row widget__content">
                <div class="col-12 col-lg-5">
                    <div class="row">
                        <div class="col-8">
                            <p
                                class="widget__content-label widget__content-label--time"
                            >
                                {{ say.competition_starts_in }}
                            </p>
                            <div class="widget__content-value">
                                <h1>{{ competition.startedTime | asCounter }}</h1>
                            </div>
                            <p
                                class="widget__content-label widget__content-label--start"
                            >
                                {{ say.start }}
                            </p>
                            <p
                                class="widget__content-value widget__content-value--date"
                            >
                                {{ competition.startedTime | asTime }}
                            </p>
                        </div>
                        <div class="col-4">
                            <Weather :id="competition.id" :key="competition.id" />
                        </div>
                        <hr class="widget__content-figure">
                    </div>
                </div>
                <div class="col-12 col-lg-7 widget__content-box">
                    <div v-if="competition.location !== ''" class="widget__content-place">
                        <p
                            class="widget__content-label widget__content-label--localization"
                        >
                            Lokalizacja
                        </p>
                        {{ competition.location }}
                    </div>
                    <base-button
                        class="widget__content-open"
                        btn5
                        icon="fas fa-map-pin"
                        @click="openLink(competition.mapGoogleLink)"
                    >
                        Otwórz w Mapach Google
                    </base-button>
                    <div class="widget__content-map">
                        <img
                            class="widget__content-img"
                            :src="osmStaticMapLink(competition.id)"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!ready" class="col-12">
            <div class="row widget__header"  style="min-height: 48px;    box-sizing: border-box;">
                <div class="col-12 col-lg-unset widget__header-competition">&nbsp;</div>
            </div>
            <div class="row widget__content">
                <div class="col-12 col-lg-5">
                    <div class="row">
                        <div class="col-8">
                            <p class="widget__content-label base-skeleton-text base-skeleton-text--location" style="margin-top:10px;margin-bottom: 8px;" />
                            <p class="widget__content-value base-skeleton-text competition-box__description-title--skeleton" />  
                            <p class="widget__content-label--start base-skeleton-text base-skeleton-text--date" />
                            <p class="widget__content-label--date base-skeleton-text base-skeleton-text--location  " style="margin-top: 8px;"/>
                        </div>
                        <div class="col-4">
                            <div class="widget__content-line">
                                <p class="widget__content-label base-skeleton-text competition-box__description-title--skeleton" style="margin-top:10px;margin-bottom: 8px;" />  
                                <p class="widget__content-value widget__content-value--temp base-skeleton-text competition-box__description-title--skeleton" />  
                                <p class="widget__content-value widget__content-value--img base-skeleton-text competition-box__description-title--skeleton" style="margin-bottom: 8px"/>  
                            </div>
                        </div>
                        <hr class="widget__content-figure">
                    </div>
                </div>
                <div class="col-12 col-lg-7 widget__content-box">
                    <p class="widget__content-label base-skeleton-text base-skeleton-text--location" style="margin-left: 12px; margin-top:10px;margin-bottom: 8px;" />
                    <p class="base-skeleton-text competition-box__description-title--skeleton" style="margin-left: 12px;margin-bottom: 8px; " />  
                    <p class="base-skeleton-text competition-box__description-title--skeleton" style="margin-left: 12px;margin-bottom: 8px;" />  
                    <p class="base-skeleton-text competition-box__description-title--skeleton" style="margin-left: 12px;margin-bottom: 8px;" />  
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API_ADDRESS } from '@/config';
import actions from '@/mixins/actions';
import Weather from '@/components/Weather';
import {
    READ_USER_COMPETITION_PLAYERS,
    READ_CLASSIFICATION,
    READ_COMPETITION_CLASSIFICATIONS_LIMITS,
} from '@/store/actions.type';
import { pick } from '@/utils/object';

export default {
    name: 'UserCompetitionWidget',
    mixins: [actions],    
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            userPlayersIds: [],
            ready: false,
        };
    },    
    components: { Weather },
    computed: {
        ...mapGetters([
            'competitions',
            'say',
            'user',
            'classifications',
            'players',
            'limits',
        ]),
        competition() {
            return this.competitions[this.id];
        },
        userPlayers() {
            return pick(this.players, this.userPlayersIds);
        },            
    },
    async created() {
        if (this.user && this.user.roles.includes('ROLE_USER') && this.id > 0) {
            const userPlayersIds = await this.READ_USER_COMPETITION_PLAYERS(
                this.id,
            );
            const readPlayersClassifications = [];
            if (userPlayersIds.length) {
                for (const id of userPlayersIds) {
                    readPlayersClassifications.push(
                        this.READ_CLASSIFICATION(
                            this.players[id].classification,
                        ),
                    );
                }
                // Fetch classifications limits.
                readPlayersClassifications.push(
                    this.READ_COMPETITION_CLASSIFICATIONS_LIMITS(this.id),
                );
                await Promise.all(readPlayersClassifications);
            }
            this.userPlayersIds = userPlayersIds;
        }
        this.ready = true;
    },    
    methods: {
        ...mapActions([
            READ_USER_COMPETITION_PLAYERS,
            READ_CLASSIFICATION,
            READ_COMPETITION_CLASSIFICATIONS_LIMITS,
        ]),        
        openLink(url) {
            window.open(url, '_blank');
        },
        osmStaticMapLink(id) {
            return `${API_ADDRESS}/competitions/${id}/static-map`
        },
    } 
};
</script>

<style scoped>
.widget {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(55, 71, 79, 0.2);
}
.widget__header {
    background-color: var(--color-pale-grey);
    padding: 12px 15px;
}
.widget__header-competition {
    font-family: "Bariol Bold";
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 4px;
    max-width: calc(100vw - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.widget__content-label {
    line-height: 1.67;
    font-size: 12px;
    margin-bottom: 2px;
    padding: 0 15px;
}
.widget__content-line {
    border-left: 1px solid var(--color-pale-grey);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.widget__content-value {
    font-weight: 600;
    padding: 0 15px;
}
.widget__content-value--date {
    padding-bottom: 16px;
}
.widget__content-value--temp {
    padding: 0 0 16px 0;
    font-size: 18px;
    font-size: "Bariol Bold";
}
.widget__content-value--img {
    font-size: 32px;
    padding: 0;
    margin-top: auto;
}
.widget__content-label--wheater {
    margin-bottom: 14px;
    padding: 12px 0 0 0;
}
.widget__content-label--time {
    padding-top: 12px;
}
.widget__content-figure {
    height: 1px;
    background-color: var(--color-pale-grey);
    width: 100%;
    margin: 0 0 12px 0;
}
.widget__content-place {
    font-family: "Bariol Bold";
    font-size: 18px;
    margin-left: 15px;
    margin-right: 15px;
}
.widget__content-open {
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;
    z-index: 9;
}
.widget__content-map {
    position: relative;
    top: -20px;
    margin-bottom: -20px;
}
.widget__content-img {
    display: flex;
    height: 100%;
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.widget__content-map::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-image: linear-gradient(
        to bottom,
        var(--color-white),
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 97%
    );
}
.widget__content-label--localization {
    display: none;
}
.widget__content-box {
    display: block;
}
@media (min-width: 992px) {
    .widget__content-open {
        width: 50%;
        margin-left: 24px;
        margin-bottom: 24px;
        margin-top: auto;
    }
    .widget__content-figure {
        display: none;
    }
    .widget__content-line {
        border-left: 1px solid var(--color-cool-grey);
        border-right: 1px solid var(--color-cool-grey);
    }
    .widget__content-label--localization {
        display: block;
        padding-top: 16px;
        font-family: "Open Sans";
    }
    .widget__content-value--temp {
        padding-bottom: 24px;
        font-size: 24px;
    }
    .widget__content-label {
        padding-left: 0;
        font-size: 14px;
    }
    .widget__content-value {
        padding-left: 0;
    }
    .widget__content-label--wheater {
        padding-top: 16px;
    }
    .widget__content-label--time {
        padding-top: 16px;
    }
    .widget__content {
        padding-left: 24px;
    }
    .widget__header {
        padding-left: 24px;
    }
    .widget__content-box {
        display: flex;
        position: relative;
        flex-direction: column;
    }
    .widget__content-place {
        margin-left: 24px;
        font-size: 24px;
        line-height: 2;
    }
    .widget__content-map {
        margin-bottom: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 60%;
    }
    .widget__content-map::after {
        background-image: linear-gradient(
            to right,
            var(--color-white),
            rgba(255, 255, 255, 0) 60%,
            rgba(255, 255, 255, 0) 97%
        );
    }
    .widget__content-img {
        height: 100%;
    }
    .widget__header-competition {
        margin-bottom: 0;
    }
    .widget__header-classification {
        display: flex;
        align-items: center;
    }
    .widget__content-label--start {
        margin-top: 24px;
    }
    .widget__content-value--date {
        padding-bottom: 24px;
        font-size: 16px;
    }
}
</style>
