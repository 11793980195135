var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row mat40 flex-nowrap" },
      [
        _c(
          "div",
          { staticClass: "side-bar none-mobile" },
          [_c("panel-competition-setting-sidebar")],
          1
        ),
        _c("router-view", { attrs: { competition: _vm.competition } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }