var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-form-section",
    [
      _c("strong", { staticClass: "form__title" }, [
        _vm._v(_vm._s(_vm.say.accomodation_address)),
      ]),
      _c(
        "base-form-input",
        [
          _c("base-input-text", {
            attrs: { label: _vm.say.street, error: _vm.errors.street },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "street", $event)
              },
            },
            model: {
              value: _vm.form.street,
              callback: function ($$v) {
                _vm.$set(_vm.form, "street", $$v)
              },
              expression: "form.street",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        {
          attrs: { half: "" },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c("base-input-text", {
                    attrs: {
                      label: _vm.say.flat_number,
                      error: _vm.errors.flatNumber,
                    },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$set(_vm.errors, "flatNumber", $event)
                      },
                    },
                    model: {
                      value: _vm.form.flatNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "flatNumber", $$v)
                      },
                      expression: "form.flatNumber",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("base-input-text", {
            attrs: {
              label: _vm.say.house_number,
              error: _vm.errors.houseNumber,
            },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "houseNumber", $event)
              },
            },
            model: {
              value: _vm.form.houseNumber,
              callback: function ($$v) {
                _vm.$set(_vm.form, "houseNumber", $$v)
              },
              expression: "form.houseNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-autocomplete", {
            staticClass: "user-form__autocomplete",
            attrs: {
              label: _vm.say.city,
              error: _vm.cityError,
              "clear-search-on-select": true,
              action: "AUTOCOMPLETE_LOCATION",
            },
            on: {
              "update:error": function ($event) {
                _vm.cityError = $event
              },
              select: function ($event) {
                return _vm.selectCity($event)
              },
            },
            model: {
              value: _vm.searchValueCity,
              callback: function ($$v) {
                _vm.searchValueCity = $$v
              },
              expression: "searchValueCity",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-text", {
            attrs: { label: _vm.say.zip_code, error: _vm.errors.zipcode },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "zipcode", $event)
              },
            },
            model: {
              value: _vm.form.zipcode,
              callback: function ($$v) {
                _vm.$set(_vm.form, "zipcode", $$v)
              },
              expression: "form.zipcode",
            },
          }),
        ],
        1
      ),
      _c(
        "base-form-input",
        [
          _c("base-input-select", {
            attrs: {
              options: _vm.countries,
              clearable: false,
              label: _vm.say.country,
              reduce: (country) => country.id,
              error: _vm.errors.country,
              placeholder: _vm.say.country,
            },
            on: {
              "update:error": function ($event) {
                return _vm.$set(_vm.errors, "country", $event)
              },
            },
            model: {
              value: _vm.form.country,
              callback: function ($$v) {
                _vm.$set(_vm.form, "country", $$v)
              },
              expression: "form.country",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }