var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "competition-settings" },
    [
      _c("panel-competition-form-box", { staticClass: "mab24 info" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "form__headline mab16" }, [
            _vm._v("Klasyfikacje, kategorie, opłaty i limity"),
          ]),
          _c("p", [
            _vm._v(
              "Po rejestracji wysyłamy do uczestnika wiadomość e-mail potwierdzającą jego zapis do zawodów."
            ),
            _c("br"),
            _vm._v("W tym miejscu możesz zmienić treść tej wiadomości."),
          ]),
        ]),
      ]),
      _c("PanelCompetitionClassificationsForm", {
        attrs: { validation: _vm.errors },
        on: {
          "update:validation": function ($event) {
            _vm.errors = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }