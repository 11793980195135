var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message" }, [
    _c("div", { staticClass: "message-time" }, [
      _vm.data.unreaded ? _c("span", { staticClass: "ellipse" }) : _vm._e(),
      _vm._v(_vm._s(_vm.data.ago)),
    ]),
    _c("div", { staticClass: "message-title" }, [
      _vm._v(_vm._s(_vm.data.title)),
    ]),
    _c("div", { staticClass: "message-content" }, [
      _vm._v(" " + _vm._s(_vm.data.body) + " "),
      _vm.data.clickAction
        ? _c("a", { attrs: { href: _vm.data.clickAction } }, [
            _vm._v(_vm._s(_vm.data.clickAction)),
          ])
        : _vm._e(),
    ]),
    _vm.data.image
      ? _c("div", { staticClass: "message-image" }, [
          _c("img", { attrs: { src: _vm.data.image, alt: "" } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }