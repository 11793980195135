export default {
    data() {
        return {
            top: 0,
        };
    },
    computed: {
        isVisible() {
            return this.top >= 0;
        },
    },
    created() {
        window.addEventListener('scroll', this.updateTop);
    },
    mounted() {
        this.updateTop();
    },
    methods: {
        updateTop() {
            this.top = this.$refs.trackVisibility.getBoundingClientRect().top;
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.updateTop);
    },
    watch: {
        isVisible(isVisible) {
            this.$emit('visibility:update', isVisible);
        },
    },
};
