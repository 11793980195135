var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "base-map",
        {
          staticClass: "map-container__map",
          class: { h100i: _vm.isZenMode },
          attrs: {
            zoom: 12,
            lat: _vm.competition.locationLat,
            lng: _vm.competition.locationLng,
            fullscreenable: _vm.fullscreenable,
            "disable-scroll": _vm.disableScroll,
          },
          on: {
            ready: function ($event) {
              ;(_vm.map = $event), _vm.$emit("ready", $event)
            },
          },
        },
        [
          _vm._l(_vm.points, function (point) {
            return _c("map-competition-point", {
              key: point.id,
              attrs: {
                "read-only": _vm.readOnly,
                point: point,
                "competition-id": _vm.competition.id,
                "selected-classification-id":
                  _vm.selectedClassification && _vm.selectedClassification.id,
              },
              on: {
                "delete-point": function ($event) {
                  return _vm.$emit("delete-point", $event)
                },
              },
            })
          }),
          _vm.map
            ? _c("base-map-track", {
                attrs: {
                  classifications: _vm.classifications,
                  map: _vm.map,
                  "selected-classification-id":
                    _vm.selectedClassification && _vm.selectedClassification.id,
                },
                on: { trackClick: _vm.trackClickHandler },
              })
            : _vm._e(),
          _vm._t("optional-button"),
          _vm._v("> "),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }