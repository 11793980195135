<template>
    <div v-if="ready">
        <!-- Form. -->
        <user-form-child
            v-model="editedChild"
            :validation.sync="errors"
        />

        <!-- Validation. -->
        <base-form-validation :validation="errors" />

        <!-- Actions. -->
        <user-form-actions @save="save()" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UserFormChild from '@/components/UserFormChild';
import UserFormActions from '@/components/UserFormActions';
import { READ_USER_CHILD, UPDATE_USER_CHILD } from '@/store/actions.type';
import { pick } from '@/utils/object';
import { SET_NOTIFICATION } from '@/store/mutations.type';

export default {
    components: {
        UserFormChild,
        UserFormActions,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            editedChild: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['say', 'users']),
        child() {
            return this.users[this.id];
        },
    },
    async created() {
        await this.READ_USER_CHILD(this.id);

        this.editedChild = pick(this.child, [
            'id',
            'birthTime',
            'city',
            'cityLat',
            'cityLng',
            'clubname',
            'country',
            'firstname',
            'flatNumber',
            'houseNumber',
            'gender',
            'lastname',
            'nationality',
            'phone',
            'phonePrefix',
            'pesel',
            'street',
            'zipcode',
        ]);

        this.ready = true;
    },
    methods: {
        ...mapActions([READ_USER_CHILD, UPDATE_USER_CHILD]),
        ...mapMutations([SET_NOTIFICATION]),
        async save() {
            this.errors = {};
            try {
                await this.UPDATE_USER_CHILD(this.editedChild);

                this.SET_NOTIFICATION('alerts_positive_5');

                this.$router.push({
                    name: 'user-family-children',
                    alias: true,
                });
            } catch (errors) {
                this.errors = errors;
            }
        },
    },
};
</script>
