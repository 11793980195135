var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile-bar d-lg-none" }, [
    _c(
      "div",
      {
        staticClass: "mobile-bar-item",
        class: {
          "is-active": _vm.$router.matched("user-competitions", _vm.$route),
        },
        on: {
          click: function ($event) {
            return _vm.$router.push({
              name: "user-competitions-future",
              alias: true,
            })
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M5.0715 2C4.55509 2 4.19932 2.51802 4.3847 3L7.75415 11.7606C6.67025 12.8461 6 14.3448 6 16C6 19.3137 8.68629 22 12 22C15.3137 22 18 19.3137 18 16C18 12.6863 15.3137 10 12 10C11.9982 10 11.9964 10 11.9946 10L14.6869 3H18.5441L15.4361 11.0807C15.7258 11.2834 15.9968 11.5111 16.246 11.7606L19.6155 3C19.8008 2.51802 19.4451 2 18.9287 2H14.6869C14.2731 2 13.9021 2.25483 13.7535 2.64102L12.0001 7.2L10.2466 2.64102C10.0981 2.25483 9.72705 2 9.31328 2H5.0715ZM10.8833 10.1037L11.4644 8.59284L9.31328 3H5.45611L8.56404 11.0806C9.24668 10.6029 10.0333 10.2637 10.8833 10.1037ZM17 16C17 18.7614 14.7614 21 12 21C9.23858 21 7 18.7614 7 16C7 13.2386 9.23858 11 12 11C14.7614 11 17 13.2386 17 16Z",
                fill: "#4A4A4A",
              },
            }),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.say.competitions))]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "mobile-bar-item",
        class: {
          "is-active": _vm.$router.matched("user-family", _vm.$route),
        },
        on: {
          click: function ($event) {
            return _vm.$router.push({
              name: "user-family-children",
              alias: true,
            })
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M14.0425 13.5651C13.4189 13.8446 12.7276 14 12 14C11.2739 14 10.5839 13.8452 9.96127 13.5668L5.8793 15.4223C5.3438 15.6657 5 16.1996 5 16.7878V17.5C5 18.3284 5.67157 19 6.5 19H11.5C11.7761 19 12 19.2239 12 19.5C12 19.7761 11.7761 20 11.5 20H6.5C5.11929 20 4 18.8807 4 17.5V16.7878C4 15.8075 4.57301 14.9176 5.46549 14.5119L8.92216 12.9407C7.75209 12.0255 7 10.6006 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9C17 10.5929 16.2551 12.0119 15.0946 12.9275L17.6966 14.0403C17.9505 14.1489 18.0683 14.4427 17.9597 14.6966C17.8511 14.9505 17.5573 15.0683 17.3034 14.9597L14.0425 13.5651ZM17 19V16.5C17 16.2239 17.2239 16 17.5 16C17.7761 16 18 16.2239 18 16.5V19H20.5C20.7761 19 21 19.2239 21 19.5C21 19.7761 20.7761 20 20.5 20H18V22.5C18 22.7761 17.7761 23 17.5 23C17.2239 23 17 22.7761 17 22.5V20H14.5C14.2239 20 14 19.7761 14 19.5C14 19.2239 14.2239 19 14.5 19H17ZM16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9Z",
                fill: "#4A4A4A",
              },
            }),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.say.family))]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "mobile-bar-item",
        class: {
          "is-active": _vm.$router.matched("user-messages", _vm.$route),
        },
        on: {
          click: function ($event) {
            return _vm.$router.push({
              name: "user-messages",
              alias: true,
            })
          },
        },
      },
      [
        _c("div", {
          staticClass: "new-messages",
          class: { "is-active": _vm.isMessagesUnread },
        }),
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M12 21C13.2866 21 14.4116 20.1832 14.8292 19H16.4225C16.7723 19 17.1182 18.9266 17.4378 18.7845C18.6995 18.2238 19.2678 16.7464 18.707 15.4847L18 13.8939V11C18 8.38756 16.3304 6.16508 14 5.34141V5C14 3.89543 13.1046 3 12 3C10.8954 3 9.99999 3.89543 9.99999 5V5.34141C7.66961 6.16508 5.99999 8.38756 5.99999 11V13.8939L5.29299 15.4847C5.15092 15.8043 5.07751 16.1502 5.07751 16.5C5.07751 17.8807 6.1968 19 7.57751 19H9.17072C9.58711 20.1802 10.7106 21 12 21ZM10.2671 19H13.7329C13.3836 19.6045 12.7308 20 12 20C11.2679 20 10.6158 19.6033 10.2671 19ZM13 5.08296V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V5.08296C11.3252 5.0284 11.6593 5 12 5C12.3407 5 12.6748 5.0284 13 5.08296ZM6.99999 11C6.99999 8.23858 9.23857 6 12 6C14.7614 6 17 8.23858 17 11V14L17.0431 14.2031L17.7932 15.8908C18.1296 16.6478 17.7887 17.5343 17.0317 17.8707C16.8399 17.956 16.6323 18 16.4225 18H7.57751C6.74909 18 6.07751 17.3284 6.07751 16.5C6.07751 16.2901 6.12156 16.0826 6.2068 15.8908L6.9569 14.2031L6.99999 14V11Z",
                fill: "#4A4A4A",
              },
            }),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.say.messages))]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "mobile-bar-item",
        class: {
          "is-active": _vm.$router.matched("user-more", _vm.$route),
        },
        on: {
          click: function ($event) {
            return _vm.$router.push({
              name: "user-more",
              alias: true,
            })
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M8 12.5V11.5C8 10.6716 7.32843 10 6.5 10H5.5C4.67157 10 4 10.6716 4 11.5V12.5C4 13.3284 4.67157 14 5.5 14H6.5C7.32843 14 8 13.3284 8 12.5ZM14 12.5V11.5C14 10.6716 13.3284 10 12.5 10H11.5C10.6716 10 10 10.6716 10 11.5V12.5C10 13.3284 10.6716 14 11.5 14H12.5C13.3284 14 14 13.3284 14 12.5ZM20 12.5V11.5C20 10.6716 19.3284 10 18.5 10H17.5C16.6716 10 16 10.6716 16 11.5V12.5C16 13.3284 16.6716 14 17.5 14H18.5C19.3284 14 20 13.3284 20 12.5ZM18.5 11C18.7761 11 19 11.2239 19 11.5V12.5C19 12.7761 18.7761 13 18.5 13H17.5C17.2239 13 17 12.7761 17 12.5V11.5C17 11.2239 17.2239 11 17.5 11H18.5ZM13 11.5C13 11.2239 12.7761 11 12.5 11H11.5C11.2239 11 11 11.2239 11 11.5V12.5C11 12.7761 11.2239 13 11.5 13H12.5C12.7761 13 13 12.7761 13 12.5V11.5ZM7 11.5C7 11.2239 6.77614 11 6.5 11H5.5C5.22386 11 5 11.2239 5 11.5V12.5C5 12.7761 5.22386 13 5.5 13H6.5C6.77614 13 7 12.7761 7 12.5V11.5Z",
                fill: "#4A4A4A",
              },
            }),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.say.more))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }