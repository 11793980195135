import { equal, diff } from '@/utils/object';

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        validation: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            form: {},
            errors: {},
        };
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler() {
                if (!equal(this.form, this.value)) {
                    this.form = { ...this.value }; // copy
                }
            },
        },
        validation: {
            deep: true,
            immediate: true,
            handler() {
                if (!equal(this.errors, this.validation)) {
                    this.errors = { ...this.validation }; // copy
                }
            },
        },
        form: {
            deep: true,
            handler() {
                if (!equal(this.form, this.value)) {
                    this.$emit('input', { ...this.form }); // copy
                }
                for (const field of diff(this.form, this.value)) {
                    this.$delete(this.errors, field);
                }
            },
        },
        errors: {
            deep: true,
            handler() {
                if (!equal(this.errors, this.validation)) {
                    this.$emit('update:validation', { ...this.errors }); // copy
                }
            },
        },
    },
};
