var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "family-child" }, [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("div", { staticClass: "family-child__img" }, [
        _c("strong", [
          _vm._v(
            _vm._s(_vm.child.firstname.charAt(0)) +
              _vm._s(_vm.child.lastname.charAt(0))
          ),
        ]),
      ]),
      _c("h3", { staticClass: "family-child__name" }, [
        _vm._v(
          " " +
            _vm._s(_vm.child.firstname) +
            " " +
            _vm._s(_vm.child.lastname) +
            " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "row family-child__info" }, [
      _c(
        "div",
        {
          staticClass:
            "col-12 col-sm-12 col-md-6 col-lg-4 family-child__info-section",
        },
        [
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.say.birthtime) + " "),
              _c("br"),
              _c("big", [
                _vm._v(_vm._s(_vm._f("asDate")(_vm.child.birthTime))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-sm-12 col-md-6 col-lg-4 family-child__info-section",
        },
        [
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.say.city) + " "),
              _c("br"),
              _c("big", [
                _vm._v(
                  _vm._s(_vm.child.city) + ", " + _vm._s(_vm.child.nationality)
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-sm-12 col-md-12 col-lg-4 family-child__info-section",
        },
        [
          _c(
            "div",
            { staticClass: "buttons-group" },
            [
              _vm.canEditChild(_vm.familyAssociation)
                ? _c(
                    "base-button",
                    {
                      staticClass: "btn-user btn-user--border-right",
                      attrs: { icon: "fa fa-pencil-alt f14", btn5: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "user-family-children-edit",
                            params: _vm.child,
                            alias: true,
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.edit_data) + " ")]
                  )
                : _vm._e(),
              _c(
                "base-button",
                {
                  staticClass: "btn-user",
                  attrs: { icon: "fa fa-trash-alt f14", btn5: "" },
                  on: {
                    click: function ($event) {
                      return _vm.DELETE_USER_CHILD(_vm.child.id)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.delete_from_list) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }