<template>
    <base-form-section>
        <!-- Email. -->
        <base-form-input>
            <base-input-text
                v-model="form.email"
                :label="say.email"
                :error.sync="errors.email"
            />
            <template v-slot:hint>
                {{ say.you_will_use_this_email_to_login }}
            </template>
        </base-form-input>

        <!-- Password. -->
        <base-form-input>
            <base-input-password
                v-model="form.plainPassword"
                :error.sync="errors.plainPassword"
                :label="say.password"
            />
            <template v-slot:hint>
                {{ say.password_should_contain }}
            </template>
        </base-form-input>
    </base-form-section>
</template>

<script>
import { mapGetters } from 'vuex';
import form from '@/mixins/form';

export default {
    mixins: [form],
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
