<template>
    <div class="promote__box promote-box--competition mat16 pointer">
        <!-- Link. -->
        <div
            class="promote-competition"
            @click="
                $router.push({
                    name: 'minisite-landing',
                    params: competition,
                    alias: true,
                })
            "
        >
            <!-- Poster. -->
            <div class="promote-competition__poster-box">
                <img
                    class="promote-competition__poster"
                    :src="layout.posterLink"
                >
            </div>

            <div class="promote-competition__info row mat24">
                <div class="col-12 position-relative">
                    <!-- Type. -->
                    <p class="promote-competition__category">
                        {{ competition.type | asType }}
                    </p>

                    <!-- Name. -->
                    <h3 class="promote-competition__title">
                        {{ competition.name }}
                    </h3>

                    <!-- Location. -->
                    <p class="promote-competition__place">
                        {{ competition.location }}
                    </p>

                    <!-- Started time. -->
                    <p class="promote-competition__date">
                        {{ competition.startedTime | asTime }}
                    </p>

                    <!-- <template v-if="competitionPrice"> -->
                    <!-- Prices range. -->
                    <!-- <p
                            v-if="competitionPrice.minPrice && (competitionPrice.minPrice !== competitionPrice.maxPrice)"
                            class="promote-competition__price"
                        >
                            {{ say.participation_fee }} {{ competitionPrice.minPrice | asPrice }} - {{ competitionPrice.maxPrice | asPrice }}
                        </p> -->

                    <!-- Single price. -->
                    <!-- <p
                            v-else-if="competitionPrice.minPrice"
                            class="promote-competition__price"
                        >
                            {{ say.participation_fee }} {{ competitionPrice.minPrice | asPrice }} zł
                        </p>
                    </template> -->

                    <!-- Feeless. -->
                    <!-- <p
                        v-else
                        class="promote-competition__price"
                    >
                        {{ say.competitions_feeless }}
                    </p> -->

                    <!-- Hint. -->
                    <div class="promote-competition__hint">
                        <i class="fas fa-info-circle f16" />
                        <p class="d-inline mal8">
                            {{ say.promoted_competition }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            // 'competitionsPrices',
            'layoutsBy',
        ]),
        competition() {
            return this.competitions[this.id];
        },
        // competitionPrice() {
        //     return this.competitionsPrices[this.id];
        // },
        layout() {
            return this.layoutsBy(this.competition)[0];
        },
    },
};
</script>
