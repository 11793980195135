var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "communicator-bg", class: { "is-open": _vm.isOpen } },
    [
      _c(
        "div",
        { staticClass: "communicator", class: { "is-open": _vm.isOpen } },
        [
          _c("div", { staticClass: "communicator-header" }, [
            _c("div", { staticClass: "communicator-header-icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "48",
                    height: "48",
                    viewBox: "0 0 48 48",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M33.3574 14.6429C39.6177 16.5391 44 22.3366 44 29C44 31.5826 43.3456 34.0715 42.1193 36.2775L43.9615 42.7253C44.177 43.4796 43.4796 44.1771 42.7253 43.9615L36.2774 42.1193C34.0715 43.3456 31.5826 44 29 44C22.343 44 16.5423 39.6253 14.6429 33.3572C13.6288 33.0499 12.6506 32.6352 11.7225 32.1193L5.27472 33.9615C4.52037 34.1771 3.82294 33.4796 4.03847 32.7253L5.88071 26.2775C4.65443 24.0715 4 21.5826 4 19C4 10.7157 10.7157 4 19 4C25.7689 4 31.4907 8.48358 33.3574 14.6429ZM11.5796 30.0801C11.8444 30.0044 12.1288 30.0413 12.3655 30.1821C14.3564 31.3661 16.6306 32 19 32C26.1797 32 32 26.1797 32 19C32 11.8203 26.1797 6 19 6C11.8203 6 6 11.8203 6 19C6 21.3694 6.63388 23.6436 7.81788 25.6345C7.95866 25.8712 7.99557 26.1556 7.91991 26.4204L6.45602 31.544L11.5796 30.0801ZM34 19C34 18.3004 33.9521 17.6119 33.8594 16.9377C38.6999 18.886 42 23.6173 42 29C42 31.3694 41.3661 33.6436 40.1821 35.6345C40.0413 35.8712 40.0044 36.1556 40.0801 36.4204L41.544 41.544L36.4204 40.0801C36.1555 40.0044 35.8712 40.0413 35.6345 40.1821C33.6436 41.3661 31.3694 42 29 42C23.622 42 18.8884 38.7052 16.9378 33.8593C17.6159 33.9525 18.3046 34 19 34C27.2843 34 34 27.2843 34 19Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
            ]),
            _vm._m(0),
          ]),
          _vm.step === 1
            ? _c(
                "div",
                {
                  staticClass: "communicator-content",
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "baseline",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "communicator-option",
                      on: {
                        click: function ($event) {
                          return _vm.next("Zgłoszenie błędu")
                        },
                      },
                    },
                    [_vm._v("Zgłoszenie błędu")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "communicator-option",
                      on: {
                        click: function ($event) {
                          return _vm.next("Sugestia funkcjonalności")
                        },
                      },
                    },
                    [_vm._v("Sugestia funkcjonalności")]
                  ),
                ]
              )
            : _vm._e(),
          _vm.step === 2
            ? _c(
                "div",
                {
                  staticClass: "communicator-content",
                  staticStyle: {
                    position: "relative",
                    "background-color": "#F7F9F9",
                    "padding-bottom": "0",
                  },
                },
                [
                  _c("p", { staticStyle: { "margin-bottom": "4px" } }, [
                    _vm._v("Twoja wiadomość do Dostartu"),
                  ]),
                  _c("base-input-area", {
                    attrs: { rows: 5 },
                    model: {
                      value: _vm.ticket.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ticket, "description", $$v)
                      },
                      expression: "ticket.description",
                    },
                  }),
                  _vm.loading
                    ? _c("div", { staticClass: "loading" }, [_vm._m(1)])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.step === 3
            ? _c(
                "div",
                { staticClass: "communicator-content-complete" },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "48",
                        height: "48",
                        viewBox: "0 0 48 48",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M17 20.7639L18.7639 17.2361C19.5768 15.6103 20 13.8176 20 12V11.5C20 9.567 21.567 8 23.5 8C25.433 8 27.0515 9.46471 27.2438 11.3881L27.905 18H34.58C37.3415 18 39.58 20.2386 39.58 23C39.58 23.1661 39.5718 23.3322 39.5552 23.4975L38.3552 35.4975C38.0996 38.0535 35.9488 40 33.38 40H21C19.6549 40 18.4338 39.4688 17.5351 38.6048C17.0032 39.4434 16.0666 40 15 40H11C9.34315 40 8 38.6569 8 37V23C8 21.3431 9.34315 20 11 20H15C15.7684 20 16.4693 20.2889 17 20.7639ZM10 23V37C10 37.5523 10.4477 38 11 38H15C15.5523 38 16 37.5523 16 37V23C16 22.4477 15.5523 22 15 22H11C10.4477 22 10 22.4477 10 23ZM18 35V23.2361L20.5528 18.1305C21.5045 16.227 22 14.1281 22 12V11.5C22 10.6716 22.6716 10 23.5 10C24.4055 10 25.1636 10.6861 25.2537 11.5871L26.005 19.0995C26.0561 19.6107 26.4862 20 27 20H34.58C36.2369 20 37.58 21.3431 37.58 23C37.58 23.0997 37.5751 23.1993 37.5652 23.2985L36.3652 35.2985C36.2118 36.8321 34.9213 38 33.38 38H21C19.3431 38 18 36.6569 18 35Z",
                          fill: "#455A64",
                        },
                      }),
                    ]
                  ),
                  _c("p", [_vm._v("Dziękujemy za wiadomość")]),
                  _c(
                    "base-button",
                    { attrs: { btn9: "" }, on: { click: _vm.close } },
                    [_vm._v("Zamknij")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.step >= 2 && _vm.step < 3 && !_vm.loading
            ? _c(
                "div",
                { staticClass: "communicator-footer" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "communicator-back",
                      attrs: { btn9: "" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "12",
                            height: "9",
                            viewBox: "0 0 12 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M1.70711 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H1.70711L4.85355 8.14645C5.04882 8.34171 5.04882 8.65829 4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L0.146447 4.85355C-0.0488155 4.65829 -0.0488155 4.34171 0.146447 4.14645L4.14645 0.146447C4.34171 -0.0488155 4.65829 -0.0488155 4.85355 0.146447C5.04882 0.341709 5.04882 0.658291 4.85355 0.853553L1.70711 4Z",
                              fill: "white",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm.step === 2
                    ? _c(
                        "base-button",
                        {
                          staticClass: "communicator-submit",
                          attrs: {
                            btn1: "",
                            disabled: _vm.ticket.description.length < 10,
                          },
                          on: { click: _vm.sendTicket },
                        },
                        [_vm._v("Wyślij")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "communicator-trigger", on: { click: _vm.openPopup } },
        [
          _vm.isOpen
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "8",
                    height: "4",
                    viewBox: "0 0 8 4",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M7.17461 0.120381C7.38428 -0.0593303 7.69993 -0.0350495 7.87964 0.174613C8.05935 0.384276 8.03507 0.699926 7.8254 0.879637L4.3254 3.87964C4.13816 4.04013 3.86186 4.04013 3.67461 3.87964L0.174613 0.879637C-0.0350495 0.699926 -0.0593303 0.384276 0.120381 0.174613C0.300092 -0.0350495 0.615742 -0.0593303 0.825405 0.120381L4.00001 2.84147L7.17461 0.120381Z",
                      fill: "white",
                    },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M16.6787 7.32147C19.8089 8.26957 22 11.1683 22 14.5C22 15.7913 21.6728 17.0357 21.0596 18.1387L21.9808 21.3626C22.0885 21.7398 21.7398 22.0885 21.3626 21.9808L18.1387 21.0596C17.0357 21.6728 15.7913 22 14.5 22C11.1715 22 8.27117 19.8126 7.32146 16.6786C6.81439 16.5249 6.32531 16.3176 5.86128 16.0596L2.63736 16.9808C2.26019 17.0885 1.91148 16.7398 2.01924 16.3626L2.94036 13.1387C2.32722 12.0357 2 10.7913 2 9.5C2 5.35786 5.35787 2 9.5 2C12.8845 2 15.7453 4.24179 16.6787 7.32147ZM5.78981 15.04C5.92223 15.0022 6.06438 15.0207 6.18274 15.0911C7.17822 15.6831 8.3153 16 9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3C5.91015 3 3 5.91015 3 9.5C3 10.6847 3.31694 11.8218 3.90894 12.8173C3.97933 12.9356 3.99779 13.0778 3.95996 13.2102L3.22801 15.772L5.78981 15.04ZM17 9.5C17 9.15018 16.9761 8.80595 16.9297 8.46886C19.35 9.44299 21 11.8087 21 14.5C21 15.6847 20.6831 16.8218 20.0911 17.8173C20.0207 17.9356 20.0022 18.0778 20.04 18.2102L20.772 20.772L18.2102 20.04C18.0778 20.0022 17.9356 20.0207 17.8173 20.0911C16.8218 20.6831 15.6847 21 14.5 21C11.811 21 9.44419 19.3526 8.46891 16.9296C8.80798 16.9763 9.15229 17 9.5 17C13.6421 17 17 13.6421 17 9.5Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h5", [_vm._v("Napisz do nas")]),
      _c("p", [
        _vm._v("Masz pytania, lub komentarze dotyczące działania systemu "),
        _c("b", [_vm._v("Dostartu?")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis lds-ellipsis--center" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }