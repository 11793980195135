var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("user-header"),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4 col-lg-3" }, [
            _c("div", { staticClass: "user-profile" }, [_c("user-sidebar")], 1),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-md-8 col-lg-9 user-content" },
            [
              _vm.isUserWidget &&
              _vm.competitionsIds.length > 0 &&
              _vm.competition.isRemote === false
                ? _c("user-widget", {
                    staticClass: "user-content__widget",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [_vm._v(" Twoje najbliższe zawody ")]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("user-widget-competition", {
                                attrs: { id: _vm.competition.id },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3615267267
                    ),
                  })
                : _vm._e(),
              _c("user-navigation"),
              _c("router-view"),
            ],
            1
          ),
        ]),
      ]),
      _c("user-mobile-nav"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }