<template>
    <div class="container">
        <div class="row">
            <div class="col-12 confirmation">
                <h1>
                    {{ say.create_organizer_profile_to_go }}
                </h1>
            </div>
            <div class="col-12">
                <div class="row profile">
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <div class="col-12">
                                <div class="row profile__option">
                                    <div class="col-12">
                                        <img
                                            class="profile__option-image"
                                            src="@/assets/images/stoper-icon.svg"
                                        >
                                    </div>
                                    <div class="profile__option-box">
                                        <p class="profile__option-title">
                                            {{ say.organizes_competition }}
                                        </p>
                                        <p>
                                            {{
                                                say.create_organizer_profile_for
                                            }}:
                                        </p>
                                        <p>
                                            • {{ say.create_competition_page }}
                                        </p>
                                        <p>
                                            •
                                            {{ say.start_online_registration }}
                                        </p>
                                        <p>
                                            • {{ say.measure_results_stoper }}
                                        </p>
                                    </div>
                                    <div class="col-12 profile__option-btn">
                                        <div class="row">
                                            <div class="col-12 col-lg-5">
                                                <base-button
                                                    btn2
                                                    @click="chooseOrganizer()"
                                                >
                                                    {{
                                                        say.create_organizer_profile
                                                    }}
                                                </base-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ADD_ORGANIZER_ROLE } from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['user', 'say']),
    },
    created() {
        if (!this.user) {
            this.$router.push({ name: 'authentication-login', alias: true });
        }
    },

    methods: {
        ...mapActions([ADD_ORGANIZER_ROLE]),
        async chooseOrganizer() {
            await this.ADD_ORGANIZER_ROLE();
            this.$router.push({ name: 'panel-organizer', alias: true });
        },
    },
};
</script>

<style scoped>
.profile {
    margin-top: 56px;
    justify-content: flex-end;
}
.profile__option {
    background-color: var(--color-pale-grey);
    padding: 24px;
    margin-top: 16px;
}
.profile__option-title {
    margin-top: 16px;
    margin-bottom: 3px;
    font-size: 18px;
    font-family: 'Bariol Bold';
}
.profile__option-box {
    line-height: 24px;
}
.profile__option-btn {
    margin-top: 16px;
}
.confirmation {
    margin-top: 24px;
}
.profile__option-image {
    height: 68px;
}
@media (min-width: 992px) {
    .profile {
        margin-bottom: 20px;
    }
    .profile__option {
        margin-top: 0;
        height: 100%;
        box-sizing: border-box;
    }
    .profile__option-title {
        margin-top: 32px;
    }
    .confirmation {
        margin-top: 48px;
    }
    .profile__option-btn {
        margin-top: 24px;
    }
}
</style>
