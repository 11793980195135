<template>
    <div
        :class="classButton"
        @click.stop="!disabled && $emit('click')"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
    >
        <div
            v-if="loading"
            :class="classAnimation"
        >
            <!-- Loading state. -->
            <div />
            <div />
            <div />
            <div />
        </div>
        <template v-else-if="btn8">
            <!-- Facebook button. -->
            <p>
                <slot />
            </p>
            <i class="fab fa-facebook-square btn__icon" />
        </template>
        <template v-else>
            <!-- Standard button. -->
            <i
                v-if="badge"
                :class="[badge, 'f18', { mar10: !isEmptySlot }]"
            />
            <slot />
            <i
                v-if="icon"
                :class="icon"
                class="mal12 f18"
            />
            <p
                v-if="novelty"
                class="btn__novelty"
            >
                nowość
            </p>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            // Icon before.
            type: String,
            default: '',
        },
        badge: {
            // Icon after.
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pressed: {
            type: Boolean,
            default: false,
        },
        novelty: {
            type: Boolean,
            default: false,
        },
        btn1: {
            type: Boolean,
            default: false,
        },
        btn1admin: {
            type: Boolean,
            default: false,
        },
        btn2: {
            type: Boolean,
            default: false,
        },
        btn3: {
            type: Boolean,
            default: false,
        },
        btn4: {
            type: Boolean,
            default: false,
        },
        btn5: {
            type: Boolean,
            default: false,
        },
        btn6: {
            type: Boolean,
            default: false,
        },
        btn7: {
            type: Boolean,
            default: false,
        },
        btn8: {
            type: Boolean,
            default: false,
        },
        btn9: {
            type: Boolean,
            default: false,
        },
        btn10: {
            type: Boolean,
            default: false,
        },
        btn11: {
            type: Boolean,
            default: false,
        },
        btn12: {
            type: Boolean,
            default: false,
        },
        short: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasSlotContent: false,
        };
    },
    computed: {
        classButton() {
            return {
                nowrap: true,
                btn: !this.btn10 && !this.btn11,
                'btn--1': this.btn1,
                'btn--1 btn--1__admin': this.btn1admin,
                'btn--2': this.btn2,
                'btn--3': this.btn3,
                'btn--4': this.btn4,
                'btn--5': this.btn5,
                'btn--6': this.btn6,
                'btn--7': this.btn7,
                'btn--8': this.btn8,
                'btn--9': this.btn9,
                'btn--10': this.btn10,
                'btn--11': this.btn11,
                'btn--12': this.btn12,
                'btn--with-icon': this.icon,
                'btn--with-badge': this.badge,
                'btn--only-icon': !this.hasSlotContent,
                'btn--pressed': this.pressed && !this.btn6,
                'btn--pressed-dark': this.pressed && this.btn6,
                'btn--novelty': this.novelty,
                'btn--disabled': this.disabled,
                'btn--short': this.short,
            };
        },
        classAnimation() {
            return {
                'load-animation': true,
                'load-animation--dark': this.type === 3,
                'load-animation--grey': this.type === 4 || this.type === 5,
            };
        },
        isEmptySlot() {
            return !this.$slots.default;
        },
    },
    beforeUpdate() {
        this.hasSlotContent = this.checkForSlotContent();
    },
    created() {
        this.hasSlotContent = this.checkForSlotContent();
    },
    methods: {
        checkForSlotContent() {
            const checkForContent = (hasContent, node) => hasContent || node.tag || (node.text && node.text.trim());

            return (
                this.$slots.default
                && this.$slots.default.reduce(checkForContent, false)
            );
        },
    },
};
</script>

<style scoped>
.btn {
    color: white;
    background-color: var(--color-greyish-brown);
    padding: 14px 15px;
    border: 1px solid var(--color-cool-grey);
    border-radius: 4px;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
    cursor: pointer;
    user-select: none;
    transition: opacity 0.2s, background 0.2s;
    position: relative;
    overflow: hidden;
}

.btn--1 {
    background-image: linear-gradient(
        to left,
        var(--color-red-orange),
        var(--color-pinky-red)
    );
    border: none;
    box-shadow: 0 8px 12px 0 rgba(255, 42, 35, 0.3);
    min-height: 20px;
    text-transform: uppercase;
}

.btn--1__admin {
    box-shadow:  0px 8px 12px rgba(0, 0, 0, 0.1);
}

.btn--2 {
    background-color: var(--color-slate-two);
    border: none;
    min-height: 20px;
}

.btn--3 {
    color: var(--color-greyish-brown);
    background-color: transparent;
    border: solid 1px var(--color-greyish-brown);
    box-shadow: none;
    min-height: 18px;
}

.btn--3:hover:not(.btn--disabled):not(.btn--pressed):not(.btn--pressed-dark) {
    background-color: var(--color-gray-hover);
}

.btn--4 {
    color: var(--color-greyish-brown);
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    min-height: 18px;
}

.btn--4:hover:not(.btn--disabled):not(.btn--pressed):not(.btn--pressed-dark) {
    background-color: var(--color-gray-hover);
}

.btn--5 {
    background-color: var(--color-pale-grey);
    color: var(--color-greyish-brown);
    box-sizing: border-box;
    height: 40px;
    padding: 8px 16px;
    border-radius: 0;
    border: none;
    box-shadow: 0 8px 12px 0 rgba(55, 71, 79, 0.1),
        0 0 1px 0 rgba(55, 71, 79, 0.3);
    line-height: 1;
    white-space: nowrap;
    text-align: center;
}

.btn--6 {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
    color: var(--color-greyish-brown);
    border: none;
}

.btn--6:hover:not(.btn--disabled):not(.btn--pressed):not(.btn--pressed-dark) {
    background-color: var(--color-gray-hover);
}

.btn--7 {
    color: var(--color-greyish-brown);
    background-color: white;
    border: 1px solid var(--color-slate-two);
}

.btn--7:hover:not(.btn--disabled):not(.btn--pressed):not(.btn--pressed-dark) {
    background-color: var(--color-gray-hover);
}

.btn--8 {
    background-color: #3b5998;
    padding: 8px 8px 8px 12px;
    border: none;
    box-shadow: 0 8px 12px 0 rgba(59, 89, 152, 0.2);
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.71;
}

.btn--9 {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--color-slate-two);
    border: none;
    height: 40px;
    padding: 10px;
}

.btn--10 {
    color: var(--color-white);
    padding: 20px 16px;
    cursor: pointer;
    line-height: 1.43;
    opacity: 0.8;
}

.btn--10:hover {
    opacity: 1;
}

.btn--11 {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    display: flex;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.43;
    padding: 10px 15px;
}

.btn--12 {
    border: none;
    border-radius: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    box-shadow: none;
    color: var(--color-greyish-brown);
    padding: 0 12px;
    font-size: 12px;
    overflow: visible;
    position: unset;
}
.btn--12:hover {
    opacity: 1 !important;
}
.btn--11:hover:not(.btn--pressed) {
    background-color: var(--color-light-slate);
}

.btn--disabled {
    opacity: 0.5;
    cursor: default !important;
}

.btn--disabled.btn::after {
    display: none;
}

.btn--only-icon {
    padding: 0;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
}

.btn__icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.btn--with-icon {
    justify-content: space-between;
}

.btn--with-badge {
    padding: 10px 43px 10px 43px;
    align-items: center;
    justify-content: center;
}

.btn--novelty {
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 var(--color-bluish-grey);
    border: solid 1px var(--color-light-sage);
    border-radius: 4px;
}

.btn__novelty {
    color: var(--color-light-sage);
}

.btn--pressed {
    background-color: var(--color-slate);
}

.btn--pressed-dark {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--color-slate-two);
    color: white;
}

/* .btn--pressed {
    color: white;
    background-color: var(--color-dark-pastel-green);
} */

/* Ripple effect. */

.btn:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}

.btn:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
}

.btn:hover:not(.btn--disabled) {
    opacity: 0.9;
}
.btn--12::after {
    display: none;
}
.btn--short {
    box-sizing: border-box;
    min-height: 40px;
    max-height: 40px;
}
@media (min-width: 768px) {
    .btn--12 {
        position: relative;
    }
}

.dsi {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.dsi-shopping-trolley {
    background-image: url('../assets/images/icons/shopping-trolley.svg');
}
.dsi-clock {
    background-image: url('../assets/images/icons/clock.svg');
}
.dsi-home {
    background-image: url('../assets/images/icons/home.svg');
}
.dsi-list-number {
    background-image: url('../assets/images/icons/list-number.svg');
}
.dsi-medal {
    background-image: url('../assets/images/icons/medal.svg');
}
.dsi-more-v {
    background-image: url('../assets/images/icons/more-v.svg');
}
.dsi-slider-rectangle-h {
    background-image: url('../assets/images/icons/slider-rectangle-h.svg');
}
.dsi-cog {
    background-image: url('../assets/images/icons/cog.svg');
}
.dsi-collapse-wide {
    background-image: url('../assets/images/icons/collapse-wide.svg');
}
.dsi-expand-wide {
    background-image: url('../assets/images/icons/expand-wide.svg');
}
.dsi-cloud-upload {
    background-image: url('../assets/images/icons/cloud-upload.svg');
}
.dsi-download {
    background-image: url('../assets/images/icons/download.svg');
}
.dsi-sort-numeric-down {
    background-image: url('../assets/images/icons/sort-numeric-down.svg');
}
.dsi-link {
    background-image: url('../assets/images/icons/link.svg');
}

</style>
