var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("panel-competition-form-box", [
        _c(
          "div",
          { staticClass: "col-12 col-lg-8" },
          [
            _c("panel-input-text", {
              attrs: {
                label: "Nazwa wydarzenia",
                error: _vm.errors.name,
                hint: "Wpisz nazwe",
              },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "name", $event)
                },
              },
              model: {
                value: _vm.form.name,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-8 mat16" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-7" },
              [
                _c("p", { staticClass: "form-input__label" }, [
                  _vm._v(" Typ wydarzenia "),
                ]),
                _c("base-input-select", {
                  attrs: {
                    error: _vm.errors.type,
                    "is-panel": "",
                    searchable: false,
                    clearable: false,
                    options: _vm.competitionTypes,
                    reduce: (item) => item.id,
                    "without-error-message": "",
                  },
                  on: {
                    "update:error": function ($event) {
                      return _vm.$set(_vm.errors, "type", $event)
                    },
                  },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "col-12 col-lg-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "form-input__label form-input__label--competition-type",
                },
                [_vm._v(" Wydarzenie ")]
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "w100",
                      class: {
                        "form-input__competition-type--active":
                          !_vm.form.isRemote,
                      },
                      attrs: { btn2: "", short: "" },
                      on: {
                        click: function ($event) {
                          _vm.form.isRemote = false
                        },
                      },
                    },
                    [_vm._v(" Rzeczywiste ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "mal4 w100",
                      class: {
                        "form-input__competition-type--active":
                          _vm.form.isRemote,
                      },
                      attrs: { btn2: "", short: "" },
                      on: {
                        click: function ($event) {
                          _vm.form.isRemote = true
                        },
                      },
                    },
                    [_vm._v(" Wirtualne ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "panel-competition-form-box",
        { staticClass: "mat24", attrs: { "align-center": !_vm.form.isRemote } },
        [
          _c("div", { staticClass: "col-12 col-lg-8" }, [
            _c("div", { staticClass: "row justify-content-start" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("p", { staticClass: "form-input__label" }, [
                  _vm._v(" Data i godzina zamknięcia zapisów online "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-8 col-lg-6" },
                [
                  _c("base-input-date", {
                    staticClass: "form-input__date",
                    attrs: {
                      error: _vm.errors.provisionTime,
                      "is-short": "",
                      hint: "Wybierz",
                      "without-error-message": "",
                    },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$set(_vm.errors, "provisionTime", $event)
                      },
                    },
                    model: {
                      value: _vm.form.provisionTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "provisionTime", $$v)
                      },
                      expression: "form.provisionTime",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-4 col-lg-3" }, [
                _c(
                  "div",
                  { staticClass: "form-input__hour" },
                  [
                    _c("base-input-mask", {
                      attrs: {
                        mask: "00:00",
                        placeholder: "GG:MM",
                        value: _vm.provisionTimeHour,
                      },
                      on: { complete: _vm.changeProvisionTime },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-12" }, [
              _vm.form.isRemote || _vm.form.type === _vm.types.camp
                ? _c("div", { staticClass: "row justify-content-start" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 form-input__label form-input__label--duration",
                      },
                      [_vm._v(" Czas trwania wydarzenia ")]
                    ),
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v(" Data rozpoczęcia "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-8 col-lg-6" },
                      [
                        _c("base-input-date", {
                          staticClass: "form-input__date",
                          attrs: {
                            "is-short": "",
                            error: _vm.errors.preStartedTime,
                            hint: "Wybierz",
                            "without-error-message": "",
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(
                                _vm.errors,
                                "preStartedTime",
                                $event
                              )
                            },
                          },
                          model: {
                            value: _vm.form.preStartedTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "preStartedTime", $$v)
                            },
                            expression: "form.preStartedTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-4 col-lg-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-input__hour" },
                        [
                          _c("base-input-mask", {
                            attrs: {
                              mask: "00:00",
                              placeholder: "GG:MM",
                              value: _vm.preStartedTimeHour,
                            },
                            on: { complete: _vm.changePreStartedTime },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "form-input__label form-input__label--send-results",
                        },
                        [_vm._v(" Data zakończenia ")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-8 col-lg-6 mat16" },
                      [
                        _c("base-input-date", {
                          staticClass: "form-input__date",
                          attrs: {
                            "is-short": "",
                            error: _vm.errors.startedTime,
                            hint: "Wybierz",
                            "without-error-message": "",
                          },
                          on: {
                            "update:error": function ($event) {
                              return _vm.$set(_vm.errors, "startedTime", $event)
                            },
                          },
                          model: {
                            value: _vm.form.startedTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startedTime", $$v)
                            },
                            expression: "form.startedTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-4 col-lg-3 mat16" }, [
                      _c(
                        "div",
                        { staticClass: "form-input__hour" },
                        [
                          _c("base-input-mask", {
                            attrs: {
                              mask: "00:00",
                              placeholder: "GG:MM",
                              value: _vm.startedTimeHour,
                            },
                            on: { complete: _vm.changeStartedTime },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "row mat16 justify-content-start" },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("p", { staticClass: "form-input__label" }, [
                          _vm._v(" Data i godzina wydarzenia "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8 col-lg-6" },
                        [
                          _c("base-input-date", {
                            staticClass: "form-input__date",
                            attrs: {
                              "is-short": "",
                              error: _vm.errors.startedTime,
                              hint: "Wybierz",
                              "without-error-message": "",
                            },
                            on: {
                              "update:error": function ($event) {
                                return _vm.$set(
                                  _vm.errors,
                                  "startedTime",
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.form.startedTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startedTime", $$v)
                              },
                              expression: "form.startedTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4 col-lg-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-input__hour" },
                          [
                            _c("base-input-mask", {
                              attrs: {
                                mask: "00:00",
                                placeholder: "GG:MM",
                                value: _vm.startedTimeHour,
                              },
                              on: { complete: _vm.changeStartedTime },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-lg-4" }, [
            _c("p", { staticClass: "form-input__hint" }, [
              _vm._v(
                " Data i godzina zamknięcia zapisów online powoduje automatyczne zablokowanie możliwości zapisu w wydarzeniu. Rejestrację w każdym momencie można zamknąć/otworzyć ręcznie. "
              ),
              _c("br"),
              _c("br"),
            ]),
            _vm.form.isRemote
              ? _c("p", { staticClass: "form-input__hint" }, [
                  _vm._v(
                    " Czas trwania wydarzenia to przedział czasu, w którym uczestnicy mają możliwość zgłoszenia swojego wyniku. "
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "panel-competition-form-box",
        { staticClass: "mat16", attrs: { "align-end": "" } },
        [
          _c("div", { staticClass: "col-12 col-lg-8" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-5" },
                [
                  _c("panel-input-text", {
                    attrs: {
                      label: "Nr telefonu organizatora",
                      error: _vm.errors.phone,
                    },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$set(_vm.errors, "phone", $event)
                      },
                    },
                    model: {
                      value: _vm.form.contactPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactPhone", $$v)
                      },
                      expression: "form.contactPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-lg-7" },
                [
                  _c("panel-input-text", {
                    staticClass: "form-input__email",
                    attrs: {
                      label: "Adres e-mail organizatora",
                      error: _vm.errors.email,
                    },
                    on: {
                      "update:error": function ($event) {
                        return _vm.$set(_vm.errors, "email", $event)
                      },
                    },
                    model: {
                      value: _vm.form.contactEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactEmail", $$v)
                      },
                      expression: "form.contactEmail",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-lg-4" }, [
            _c("p", { staticClass: "form-input__hint" }, [
              _vm._v(
                " Możesz wyedytować dane kontaktowe organizatora dla tych wydarzenia. "
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "panel-competition-form-box",
        { staticClass: "mat16" },
        [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { ref: "location", staticClass: "form-input__label" }, [
              _vm._v(" Lokalizacja "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-lg-8" },
            [
              _c("base-input-autocomplete", {
                staticClass: "form-input__location",
                class: { mab16: _vm.isCoordsCompetition },
                attrs: {
                  error: _vm.errors.location,
                  hint: "wpisz lokalizację",
                  action: "AUTOCOMPLETE_LOCATION",
                  "without-error-message": "",
                  clearable: "",
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(_vm.errors, "location", $event)
                  },
                  input: _vm.cityWithoutSelect,
                  select: _vm.selectCity,
                  "search-blur": _vm.onSearchBlur,
                },
                model: {
                  value: _vm.locationName,
                  callback: function ($$v) {
                    _vm.locationName = $$v
                  },
                  expression: "locationName",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-lg-4" }),
          _c("transition", { attrs: { name: "fade-map" } }, [
            _vm.isCoordsCompetition
              ? _c("div", { staticClass: "col-12 form-map" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { key: "map", staticClass: "col-12 col-lg-8" },
                      [
                        _c(
                          "base-map",
                          {
                            staticClass: "form-map__map",
                            attrs: {
                              lat: _vm.form.locationLat,
                              lng: _vm.form.locationLng,
                              zoom: 10,
                              fullscreenable: false,
                            },
                          },
                          [
                            _c(
                              "l-marker",
                              {
                                attrs: {
                                  "lat-lng": [
                                    _vm.form.locationLat,
                                    _vm.form.locationLng,
                                  ],
                                  draggable: true,
                                },
                                on: { "update:lat-lng": _vm.updateCoords },
                              },
                              [
                                _c(
                                  "l-icon",
                                  { attrs: { "icon-anchor": [12, 32] } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-map__marker" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/marker-ds.svg"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isCoordsCompetition
                      ? _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-input__hint" }, [
                            _vm._v(
                              " Przesuń marker, aby ustawić dokładną lokalizację. "
                            ),
                            !_vm.isEditPage
                              ? _c("p", [
                                  _vm._v(
                                    " Ustawienie trasy i punktów można później dodać w zakladce Narzędzia. "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "none-mobile" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        " Dokładną trasę i punkty możesz ustawić: "
                                      ),
                                    ]),
                                    _c(
                                      "base-button",
                                      {
                                        staticClass: "mat16",
                                        attrs: {
                                          btn9: "",
                                          badge: "fas fa-map",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "panel-competition-map",
                                              params: _vm.form,
                                              alias: true,
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v(" Trasa i punkty ")]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("panel-competition-form-box", { staticClass: "mat16" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "form-input__label" }, [
            _vm._v(" Opis wydarzenia "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8" },
          [
            _c("base-input-area", {
              attrs: {
                hint: "Dodaj opis wydarzenia",
                error: _vm.errors.descriptionPl,
                rows: 10,
              },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "descriptionPl", $event)
                },
              },
              model: {
                value: _vm.form.descriptionPl,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "descriptionPl", $$v)
                },
                expression: "form.descriptionPl",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "form-input__hint" }, [
            _vm._v(
              " Dodaj opis wydarzenia. Przekaż uczestnikom kluczowe informacje dotyczące wydarzenia. Opisz lokalizację, nagrody, harmonogram."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Pamiętaj, że treścią tego opisu możesz wpłynąć na decyzję potencjalnych uczestników o wzięciu udziału w wydarzeniu. "
            ),
          ]),
        ]),
      ]),
      _c("panel-competition-form-box", { staticClass: "mat16" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "form-input__label" }, [
            _vm._v(" Ważny komunikat dla uczestników "),
            _c("span", { staticClass: "form-input__label--positive" }, [
              _vm._v("(opcjonalnie)"),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8" },
          [
            _c("base-input-area", {
              attrs: {
                hint: "Dodaj ważny komunikat",
                error: _vm.errors.remarksPl,
                rows: 10,
              },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "remarksPl", $event)
                },
              },
              model: {
                value: _vm.form.remarksPl,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "remarksPl", $$v)
                },
                expression: "form.remarksPl",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "form-input__hint" }, [
            _vm._v(
              " Informacje wpisane w tym miejscu zostaną pokazane w wyróżnionym miejscu na stronie wydarzenia."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Użyj tego miejsca aby poinformować uczestników np. o zmianie godziny startu."
            ),
            _c("br"),
            _c("br"),
            _vm._v(" Podstawowe informacje umieść w opisie wydarzenia. "),
          ]),
        ]),
      ]),
      _c("panel-competition-form-box", { staticClass: "mat16" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "form-input__label" }, [
            _vm._v(" Oficjalna strona wydarzenia "),
            _c("span", { staticClass: "form-input__label--positive" }, [
              _vm._v("(opcjonalnie)"),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8" },
          [
            _c("panel-input-text", {
              attrs: { error: _vm.errors.websitePl, hint: "https://" },
              on: {
                "update:error": function ($event) {
                  return _vm.$set(_vm.errors, "websitePl", $event)
                },
              },
              model: {
                value: _vm.form.websitePl,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "websitePl", $$v)
                },
                expression: "form.websitePl",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          _c("p", { staticClass: "form-input__hint" }, [
            _vm._v(
              " Podaj link do oficjalnej strony lub strony wydarzenia na Facebooku. "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
        _c("div", { staticClass: "row justify-content-end" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c(
                "base-button",
                {
                  attrs: { btn1: "", short: "" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" Zapisz ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }