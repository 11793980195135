var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canRegister(_vm.competition, _vm.limits)
        ? _c(
            "base-button",
            { attrs: { btn1: "" }, on: { click: _vm.handleSignUp } },
            [_vm._v(" " + _vm._s(_vm.say.sign_up) + " ")]
          )
        : _vm.competition.status >= 4
        ? _c(
            "base-button",
            {
              attrs: { btn1: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "minisite-results-pick-classification",
                    params: _vm.competition,
                    alias: true,
                  })
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.competition.status === 4
                      ? _vm.say.statuses_4
                      : _vm.say.results
                  ) +
                  " "
              ),
            ]
          )
        : _vm.canRegisterOutside(_vm.competition)
        ? _c(
            "base-button-info",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    path: _vm.competition.websitePl,
                    tab: true,
                  })
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "info",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.say.registrations_off_platform) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.say.sign_up) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }