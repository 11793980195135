var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder",
      class: {
        "input-field--error": _vm.error,
        "input-field--short": _vm.isShort,
      },
    },
    [
      (!_vm.error || _vm.withoutErrorMessage) && _vm.label
        ? _c(
            "label",
            {
              staticClass: "input-field__label",
              class: { "input-field__label--show": _vm.isInput },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("input", {
        staticClass: "input-field__input",
        class: {
          "input-field__input--padding-bottom":
            _vm.error && !_vm.withoutErrorMessage,
          "input-field__input--padding-top":
            (_vm.isInputValid || _vm.withoutErrorMessage) && _vm.label,
        },
        attrs: { type: _vm.type, placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
          change: function ($event) {
            return _vm.$emit("change", $event.target.value)
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("enter")
          },
        },
      }),
      _vm.icon
        ? _c("i", {
            staticClass: "input-field__icon",
            class: _vm.icon,
            on: {
              click: function ($event) {
                return _vm.$emit("icon-click")
              },
            },
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error && !_vm.withoutErrorMessage },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }