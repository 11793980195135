<template>
    <div class="container change-box">
        <div class="row">
            <h1>{{ say.edit_email_password }}</h1>
        </div>
        <div class="row change-box__form">
            <div class="col-12">
                <user-edit-credentials />
            </div>
        </div>
    </div>
</template>

<script>
import UserEditCredentials from '@/views/UserEditCredentials';
import { mapGetters } from 'vuex';

export default {
    components: {
        UserEditCredentials,
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.change-box {
    margin-top: 32px;
}
.change-box__form {
    margin-top: 32px;
}
</style>
