<template>
  <div class="competition-settings">
    <panel-competition-form-box class="mab24 info">
      <div class="col-12">
        <div class="form__headline mab16">Klasyfikacje, kategorie, opłaty i limity</div>
        <p>Po rejestracji wysyłamy do uczestnika wiadomość e-mail potwierdzającą jego zapis do zawodów.<br />W tym miejscu możesz zmienić treść tej wiadomości.</p>
      </div>
    </panel-competition-form-box>
    <PanelCompetitionClassificationsForm :validation.sync="errors" />
  </div>
</template>

<script>
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import PanelCompetitionClassificationsForm from '@/components/PanelCompetitionClassificationsForm';

export default {
  components: {
    PanelCompetitionFormBox,
    PanelCompetitionClassificationsForm,
  },
  data() {
    return {
      errors: {},
    };
  },
}
</script>

<style scoped>
.competition-settings {
  width: 100%;
}
@media (min-width: 992px) {
  .competition-settings {
    margin-left: 30px;
  }
}
</style>
