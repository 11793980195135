var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "resizeHandle",
        staticClass: "rangeSlider__handle",
        class: { active: _vm.isActive },
        style: _vm.handleStyles,
        attrs: { id: `handle-${_vm.index}` },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/icons/range-slider/marker_no_shadow.svg"),
            alt: "",
          },
        }),
        _vm.canRemoveHandle
          ? _c(
              "div",
              { staticClass: "removeHandle", on: { click: _vm.removeHandle } },
              [
                _c("img", {
                  staticStyle: {
                    position: "relative",
                    left: "auto",
                    top: "auto",
                  },
                  attrs: {
                    src: require("@/assets/images/icons/range-slider/close.svg"),
                    alt: "",
                  },
                }),
              ]
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHandleEnd,
            expression: "isHandleEnd",
          },
        ],
        ref: "resizeHandleEnd",
        staticClass: "rangeSlider__handle active",
        style: { transform: `translate(${_vm.handleEndPosition}px, 64px)` },
        attrs: { id: `handleend-${_vm.index}` },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/icons/range-slider/marker_no_shadow.svg"),
            alt: "",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }