<template>
    <imask-input
        class="input-mask input-mask--border"
        :value="value"
        :mask="mask"
        :unmask="false"
        :placeholder="placeholder"
        :overwrite="true"
        @input="$emit('input', $event)"
        @accept="onAccept"
        @complete="onComplete"
    />
</template>

<script>
import { IMaskComponent } from 'vue-imask';

export default {
    components: {
        'imask-input': IMaskComponent,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        mask: {
            type: [String, RegExp],
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    methods: {
        onComplete(value) {
            this.$emit('complete', value);
        },
        onAccept(value) {
            this.$emit('accept', value);
        },
    },
};
</script>
<style scoped>
.input-mask {
    padding-left: 10px;
    min-height: 40px !important;
    max-height: 40px !important;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
}
.input-mask--border {
    border: solid 2px transparent;
}
.input-mask--border:focus {
    border: solid 2px var(--color-dark-pastel-green);
}
</style>
