// import googleMapsApi from '@p803/google-maps-api';
import { READ_GOOGLE_PLACE_PREDICTIONS, READ_GOOGLE_GEOCODE } from '@/store/actions.type';

function call(method, params) {
    const callback = (resolve, reject) => (response, status) => {
        if (status === 'OK') {
            resolve(response);
        } else {
            reject(new Error(`Google Maps API returned an error: ${status}`));
        }
    };

    return new Promise((resolve, reject) => method(params, callback(resolve, reject)));
}

export default {
    actions: {
        async [READ_GOOGLE_PLACE_PREDICTIONS](context, params) {
            // const api = await googleMapsApi();
            // const autocomplete = new api.places.AutocompleteService();

            // return call(autocomplete.getPlacePredictions, params);
        },
        async [READ_GOOGLE_GEOCODE](context, params) {
            // const api = await googleMapsApi();
            // const geocoder = new api.Geocoder();

            // return call(geocoder.geocode, params);
        },
    },
};
