var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row widget" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.ready,
            expression: "ready",
          },
        ],
        staticClass: "col-12",
      },
      [
        _c(
          "div",
          { staticClass: "row widget__header" },
          [
            _c(
              "div",
              { staticClass: "col-12 col-lg-unset widget__header-competition" },
              [_vm._v(" " + _vm._s(_vm.competition.name) + " ")]
            ),
            Object.keys(_vm.userPlayers).length &&
            (_vm.competition.status == 2 ||
              _vm.competition.isRemote ||
              _vm.competition.status >= 5)
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-lg-unset widget__header-classification",
                    },
                    _vm._l(_vm.userPlayers, function (player) {
                      return _c("span", { key: player.id }, [
                        _vm._v(
                          _vm._s(
                            _vm.classifications[player.classification].namePl
                          )
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "row widget__content" }, [
          _c("div", { staticClass: "col-12 col-lg-5" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "widget__content-label widget__content-label--time",
                  },
                  [_vm._v(" " + _vm._s(_vm.say.competition_starts_in) + " ")]
                ),
                _c("div", { staticClass: "widget__content-value" }, [
                  _c("h1", [
                    _vm._v(
                      _vm._s(_vm._f("asCounter")(_vm.competition.startedTime))
                    ),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticClass:
                      "widget__content-label widget__content-label--start",
                  },
                  [_vm._v(" " + _vm._s(_vm.say.start) + " ")]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "widget__content-value widget__content-value--date",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("asTime")(_vm.competition.startedTime)) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c("Weather", {
                    key: _vm.competition.id,
                    attrs: { id: _vm.competition.id },
                  }),
                ],
                1
              ),
              _c("hr", { staticClass: "widget__content-figure" }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-lg-7 widget__content-box" },
            [
              _vm.competition.location !== ""
                ? _c("div", { staticClass: "widget__content-place" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "widget__content-label widget__content-label--localization",
                      },
                      [_vm._v(" Lokalizacja ")]
                    ),
                    _vm._v(" " + _vm._s(_vm.competition.location) + " "),
                  ])
                : _vm._e(),
              _c(
                "base-button",
                {
                  staticClass: "widget__content-open",
                  attrs: { btn5: "", icon: "fas fa-map-pin" },
                  on: {
                    click: function ($event) {
                      return _vm.openLink(_vm.competition.mapGoogleLink)
                    },
                  },
                },
                [_vm._v(" Otwórz w Mapach Google ")]
              ),
              _c("div", { staticClass: "widget__content-map" }, [
                _c("img", {
                  staticClass: "widget__content-img",
                  attrs: {
                    src: _vm.osmStaticMapLink(_vm.competition.id),
                    alt: "",
                  },
                }),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    !_vm.ready
      ? _c("div", { staticClass: "col-12" }, [_vm._m(0), _vm._m(1)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row widget__header",
        staticStyle: { "min-height": "48px", "box-sizing": "border-box" },
      },
      [
        _c(
          "div",
          { staticClass: "col-12 col-lg-unset widget__header-competition" },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row widget__content" }, [
      _c("div", { staticClass: "col-12 col-lg-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-8" }, [
            _c("p", {
              staticClass:
                "widget__content-label base-skeleton-text base-skeleton-text--location",
              staticStyle: { "margin-top": "10px", "margin-bottom": "8px" },
            }),
            _c("p", {
              staticClass:
                "widget__content-value base-skeleton-text competition-box__description-title--skeleton",
            }),
            _c("p", {
              staticClass:
                "widget__content-label--start base-skeleton-text base-skeleton-text--date",
            }),
            _c("p", {
              staticClass:
                "widget__content-label--date base-skeleton-text base-skeleton-text--location",
              staticStyle: { "margin-top": "8px" },
            }),
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "widget__content-line" }, [
              _c("p", {
                staticClass:
                  "widget__content-label base-skeleton-text competition-box__description-title--skeleton",
                staticStyle: { "margin-top": "10px", "margin-bottom": "8px" },
              }),
              _c("p", {
                staticClass:
                  "widget__content-value widget__content-value--temp base-skeleton-text competition-box__description-title--skeleton",
              }),
              _c("p", {
                staticClass:
                  "widget__content-value widget__content-value--img base-skeleton-text competition-box__description-title--skeleton",
                staticStyle: { "margin-bottom": "8px" },
              }),
            ]),
          ]),
          _c("hr", { staticClass: "widget__content-figure" }),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-7 widget__content-box" }, [
        _c("p", {
          staticClass:
            "widget__content-label base-skeleton-text base-skeleton-text--location",
          staticStyle: {
            "margin-left": "12px",
            "margin-top": "10px",
            "margin-bottom": "8px",
          },
        }),
        _c("p", {
          staticClass:
            "base-skeleton-text competition-box__description-title--skeleton",
          staticStyle: { "margin-left": "12px", "margin-bottom": "8px" },
        }),
        _c("p", {
          staticClass:
            "base-skeleton-text competition-box__description-title--skeleton",
          staticStyle: { "margin-left": "12px", "margin-bottom": "8px" },
        }),
        _c("p", {
          staticClass:
            "base-skeleton-text competition-box__description-title--skeleton",
          staticStyle: { "margin-left": "12px", "margin-bottom": "8px" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }