var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-header", {
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [_c("main-header-search")]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "transition",
        { attrs: { name: "home-promote" } },
        [_vm.$route.name === "main-landing" ? _c("main-carousel") : _vm._e()],
        1
      ),
      _vm.$route.name !== "main-list-map" ? _c("main-list") : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }