var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        [
          _c("user-form-child", {
            attrs: { validation: _vm.errors },
            on: {
              "update:validation": function ($event) {
                _vm.errors = $event
              },
            },
            model: {
              value: _vm.editedChild,
              callback: function ($$v) {
                _vm.editedChild = $$v
              },
              expression: "editedChild",
            },
          }),
          _c("base-form-validation", { attrs: { validation: _vm.errors } }),
          _c("user-form-actions", {
            on: {
              save: function ($event) {
                return _vm.save()
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }