var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-field input-field--focus-bolder",
      class: {
        "input-field--error": _vm.error,
        "input-field--short": _vm.isPanel,
      },
    },
    [
      !_vm.error && !_vm.isPanel
        ? _c(
            "label",
            {
              staticClass: "input-field__label",
              class: { "input-field__label--show": _vm.isInput },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("v-select", {
        staticClass: "input-field__select",
        class: {
          "input-field__select--with-selected": !_vm.isInput,
          "input-field__input--padding-bottom": _vm.error && !_vm.isPanel,
          "input-field__input--padding-top": _vm.isInputValid && !_vm.isPanel,
          "input-field__select--panel": _vm.isPanel,
        },
        attrs: {
          "append-to-body": "",
          options: _vm.options,
          label: _vm.labelSelect,
          reduce: _vm.reduce,
          value: _vm.value,
          placeholder: _vm.placeholder,
          clearable: _vm.clearable,
          searchable: _vm.searchable,
        },
        on: {
          input: function ($event) {
            _vm.$emit("input", $event), _vm.$emit("update:error", null)
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "input-error",
          class: { "input-error--show": _vm.error && !_vm.withoutErrorMessage },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error] || _vm.error))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }