<template>
    <!-- Views for logout user from old panel. -->
    <div />
</template>

<script>
import { LOGOUT_USER } from '@/store/actions.type';
import { mapActions } from 'vuex';

export default {
    async created() {
        await this.logout();
    },

    methods: {
        ...mapActions([LOGOUT_USER]),
        async logout() {
            await this.LOGOUT_USER();

            this.$router.replace({ name: 'authentication-login', alias: true });
        },
    },
};
</script>

<style></style>
