import store from '@/store';
import { getRouteToPanel, getRouteByLoginTo } from '@/service/profiles';
import Router from '@/router/router';
import routes from '@/router/routes';

export default (to, _, next) => {
    const queryTo = to.query.to;
    const { user } = store.getters;
    if (!user) {
        next();

        return;
    }
    if (queryTo === 'panel' && user) {
        next(Router.getParsed(getRouteToPanel(user), routes));

        return;
    }
    // User logged
    next(getRouteByLoginTo(user));
};
