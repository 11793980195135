import {
    BOOTSTRAP_OPERATOR,
    BOOTSTRAP_ORGANIZER,
    READ_OPERATOR_ORGANIZERS,
    READ_COMPETITIONS,
    READ_OPERATOR,
    READ_ORGANIZER,
    SEND_ORGANIZER_ACCESS,
    SEND_OPERATOR_ACCESS,
    BOOTSTRAP_ADMIN,
    READ_OPERATORS,
    COPY_COMPETITION,
    CREATE_DEFAULT_COMPETITION,
    CREATE_GAMIFICATION,
    DELETE_GAMIFICATION,
    READ_RESULTS_EDIT_STATUS,
    READ_USERS_LIST,
} from '@/store/actions.type';
import { SET_ENTITIES, SET_RESULTS_EDIT_STATUS } from '@/store/mutations.type';
import api from '@/api';
import { normalize } from 'normalizr';
import schema from '@/api/schema';

export default {
    state: {
        resultsEditStatus: false,
    },
    getters: {
        isResultsEdit: (state) => state.resultsEditStatus
    },
    mutations: {
        [SET_RESULTS_EDIT_STATUS](state, payload) {
            state.resultsEditStatus = payload
        },
    },
    actions: {
        async [BOOTSTRAP_OPERATOR]({ dispatch }, operatorId) {
            const [organizersIds, { competitions }] = await Promise.all([
                dispatch(READ_OPERATOR_ORGANIZERS, operatorId),
                dispatch(READ_COMPETITIONS, {
                    operator: operatorId,
                    panel: 1,
                    page: 1,
                    itemsPerPage: 30,
                }),
                dispatch(READ_OPERATOR, operatorId),
            ]);
            return { organizersIds, competitionsIds: competitions };
        },
        async [BOOTSTRAP_ORGANIZER]({ dispatch }, organizerId) {
            const [{ competitions }] = await Promise.all([
                dispatch(READ_COMPETITIONS, {
                    organizer: organizerId,
                    panel: 1,
                    itemsPerPage: 30,
                    page: 1,
                }),
                dispatch(READ_ORGANIZER, organizerId),
            ]);
            return { competitionsIds: competitions };
        },
        async [BOOTSTRAP_ADMIN]({ dispatch }) {
            const [operatorsIds, { competitions }] = await Promise.all([
                dispatch(READ_OPERATORS),
                dispatch(READ_COMPETITIONS, {
                    panel: 1,
                    itemsPerPage: 30,
                    page: 1,
                }),
            ]);
            return { competitionsIds: competitions, operatorsIds };
        },
        async [READ_USERS_LIST](_, filter) {
           
            const data = await api.get('/users', {
                params: filter,
            });

            return data;
        },
        async [SEND_ORGANIZER_ACCESS](_, id) {
            await api.post(`/panel/organizer/${id}/send/password`);

            return true;
        },
        async [SEND_OPERATOR_ACCESS](_, id) {
            await api.post(`/panel/operator/${id}/send/password`);

            return true;
        },
        async [COPY_COMPETITION]({ commit }, id) {
            const competition = await api.post(`/panel/competitions/${id}/copy`);

            const normalized = normalize(competition, schema.competition);

            commit(SET_ENTITIES, normalized.entities);

            return normalized.result;
        },
        async [CREATE_DEFAULT_COMPETITION]({ commit }, organizerId) {
            const competition = await api.post(`/panel/organizers/${organizerId}/competitions/default`);

            const normalized = normalize(competition, schema.competition);

            commit(SET_ENTITIES, normalized.entities);

            return normalized.result;
        },
        async [CREATE_GAMIFICATION]({ commit, getters }, id) {
            await api.post(`/panel/competitions/${id}/gamification`);
            const competition = getters.competitions[id];
            competition.gamification = !competition.gamification;

            commit(SET_ENTITIES, { competitions: { [competition.id]: competition } });

            return true;
        },
        async [DELETE_GAMIFICATION]({ commit, getters }, id) {
            await api.delete(`/panel/competitions/${id}/gamification`);
            const competition = getters.competitions[id];
            competition.gamification = !competition.gamification;

            commit(SET_ENTITIES, { competitions: { [competition.id]: competition } });

            return true;
        },
        async [READ_RESULTS_EDIT_STATUS]({ commit }, id) {
            try {
                const data = await api.get(`panel/competitions/${id}/results/status`);
                commit(SET_RESULTS_EDIT_STATUS, data.resultEdited)
            } catch(err) {
                return err
            }
        },
    },
};
