<template>
  <l-marker
    :lat-lng="[competition.locationLat, competition.locationLng]"
    @ready="$emit('markers', [competition.locationLat, competition.locationLng])"
    @click="$emit('click')"
    :name="competition.name"
    ref="markersRef"
  >
    <l-icon
      :icon-anchor="[15, 37]"
    >
      <img  src="@/assets/images/marker-map.png">
    </l-icon>
  </l-marker>
</template>

<script>
import { LMarker, LIcon } from 'vue2-leaflet';
import { mapGetters } from 'vuex';

export default {
    components: {
      LMarker,
      LIcon,
    },
    props: {
      competitionId: {
        type: Number,
        default: null,
      },
      index: {
        type: Number,
        required: true, 
      },
      selectedIndex: {
        type: Number,
        default: null, 
      },
    },
    data() {
      return {

      };
    },
    mounted: function() {
      this.$nextTick(() => {
        this.$emit('ref', { refs: this.$refs.markersRef, index: this.index})
      });
    },       
    computed: {
        ...mapGetters([
          'competitions',
        ]),
        competition() {
          return this.competitions[this.competitionId];
        },
    },
    methods: {

    },
};
</script>
