var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.form && Object.keys(_vm.form).length > 0
    ? _c(
        "div",
        { staticClass: "mat40" },
        [
          _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Konfiguracja własnej domeny")]),
              ]),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Wybierz protokół"),
                      ]),
                      _c("multiselect", {
                        attrs: {
                          options: _vm.protocols,
                          searchable: false,
                          "allow-empty": false,
                          "close-on-select": true,
                          "show-labels": false,
                          placeholder: "Wybierz protokół",
                        },
                        model: {
                          value: _vm.form.protocol,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "protocol", $$v)
                          },
                          expression: "form.protocol",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("div", { staticClass: "malr24" }, [
                      _c("p", { staticClass: "form-input__helper" }, [
                        _vm._v(
                          "Dodaj własną domenę dla wykazu swoich zawodów. Po dodaniu domeny do ustawień, koniecznie zgłoś tan fakt do naszego działu technicznego pod adres info@dostartu.pl."
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("p", { staticClass: "form-input__label" }, [
                        _vm._v("Wpisz domenę"),
                      ]),
                      _c("base-input-text", {
                        model: {
                          value: _vm.form.domain,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "domain", $$v)
                          },
                          expression: "form.domain",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "panel-competition-form-box",
            { staticClass: "panel-form mab24" },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Konfiguracja skrzynki pocztowej")]),
              ]),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Host SMTP (np. mail.dostartu.pl)"),
                            ]),
                            _c("base-input-text", {
                              model: {
                                value: _vm.form.host,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "host", $$v)
                                },
                                expression: "form.host",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Port SMTP (np. 587)"),
                            ]),
                            _c("base-input-text", {
                              model: {
                                value: _vm.form.port,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "port", $$v)
                                },
                                expression: "form.port",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v(
                                "Nazwa użytkownika (np. info@dostartu.pl)"
                              ),
                            ]),
                            _c("base-input-text", {
                              model: {
                                value: _vm.form.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "username", $$v)
                                },
                                expression: "form.username",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Hasło użytkownika"),
                            ]),
                            _c("base-input-text", {
                              attrs: { type: "password" },
                              model: {
                                value: _vm.form.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "password", $$v)
                                },
                                expression: "form.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Adres email (np. info@dostartu.pl)"),
                            ]),
                            _c("base-input-text", {
                              model: {
                                value: _vm.form.fromemail,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fromemail", $$v)
                                },
                                expression: "form.fromemail",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v(
                                "Nazwa wyświetlana (np. DO:ST:AR:TU - zawody biegowe)"
                              ),
                            ]),
                            _c("base-input-text", {
                              model: {
                                value: _vm.form.fromname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fromname", $$v)
                                },
                                expression: "form.fromname",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mab16",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("p", { staticClass: "form-input__label" }, [
                              _vm._v("Ustawienia zaawansowane:"),
                            ]),
                            _c(
                              "panel-input-checkbox",
                              {
                                staticClass: "mab8",
                                attrs: {
                                  "checkbox-value": true,
                                  name: `input_ids_add_1`,
                                  variant: "white",
                                  value: false,
                                },
                                model: {
                                  value: _vm.form.keepalive,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "keepalive", $$v)
                                  },
                                  expression: "form.keepalive",
                                },
                              },
                              [_vm._v(" Włącz połączenie stałe (keepalive) ")]
                            ),
                            _c(
                              "panel-input-checkbox",
                              {
                                staticClass: "mab8",
                                attrs: {
                                  "checkbox-value": true,
                                  name: `input_ids_add_1`,
                                  variant: "white",
                                  value: false,
                                },
                                model: {
                                  value: _vm.form.secure,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "secure", $$v)
                                  },
                                  expression: "form.secure",
                                },
                              },
                              [
                                _vm._v(
                                  " Użyj połączenia SSL/TLS (secure) (zalecane) "
                                ),
                              ]
                            ),
                            _c(
                              "panel-input-checkbox",
                              {
                                staticClass: "mab8",
                                attrs: {
                                  "checkbox-value": true,
                                  name: `input_ids_add_1`,
                                  variant: "white",
                                  value: false,
                                },
                                model: {
                                  value: _vm.form.auth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "auth", $$v)
                                  },
                                  expression: "form.auth",
                                },
                              },
                              [
                                _vm._v(
                                  " Włącz autoryzację SMTP (auth) (zalecane) "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("div", { staticClass: "malr24" }, [
                      _c("p", { staticClass: "form-input__helper" }, [
                        _vm._v(
                          "Ustaw swoje konto e-mail do komunikacji z uczestnikami. Wszystkie dane potrzebne do wypełnienia formularza znajdziesz w panelu konfiguracji skrzynki e-mail u swojego usługodawcy. Dzięki tym zabiegom, w prosty sposób dostosujesz system do własnych potrzeb."
                        ),
                      ]),
                      _c("br"),
                      _c("b", { staticClass: "form-input__helper" }, [
                        _vm._v("UWAGA"),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("p", { staticClass: "form-input__helper" }, [
                        _vm._v(
                          " Należy zwrócić szczególną uwagę na poprawność ustawionej konfiguracji konta pocztowego. W przypadku błędnej konfiguracji, zawodnicy nie otrzymają powiadomień z istotnymi informacjami (rejestracja, płatności, wyniki)"
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "col-lg-12 mat16 mab64" }, [
            _c("div", { staticClass: "row justify-content-end" }, [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { disabled: _vm.loading, btn1: "", short: "" },
                      on: { click: _vm.saveForm },
                    },
                    [_vm._v("Zapisz")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "panel-competition-form-box",
            {
              staticClass: "panel-form mat40 mab24",
              staticStyle: { display: "none" },
            },
            [
              _c("div", { staticClass: "col-12 mab24" }, [
                _c("h2", [_vm._v("Sprawdzenie konfiguracji konta pocztowego")]),
              ]),
              _c(
                "div",
                { staticClass: "row mab16", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("base-button", { attrs: { btn1: "", short: "" } }, [
                        _vm._v("Sprawdź połączenie SMTP"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("div", { staticClass: "malr24" }, [
                      _c("p", { staticClass: "form-input__helper" }, [
                        _vm._v(
                          "Aktualnie wiadomości e-mail wysyłane są ze skrzynki operatora z adresu "
                        ),
                        _c("b", [_vm._v("noreply@dostartu.pl")]),
                        _vm._v("."),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-bottom": "48px" } },
            [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    {
                      staticStyle: {
                        color: "#B0BEC5",
                        "border-color": "#B0BEC5",
                      },
                      attrs: { btn4: "" },
                      on: {
                        click: function ($event) {
                          _vm.showPopupForDefault = !_vm.showPopupForDefault
                        },
                      },
                    },
                    [_vm._v("Przywróć do domyślnych ustawień")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.showPopupForDefault
            ? _c("base-popup", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Przywróć do domyślnych ustawień ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "subtitle",
                      fn: function () {
                        return [
                          _c("b", [
                            _vm._v(
                              "Czy na pewno, chcesz przywrócić ustawienia domyślne?"
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "mat16" }, [
                            _vm._v(
                              " Ustawienia własnej domeny i skrzynki pocztowej zostaną "
                            ),
                            _c("b", [_vm._v("usunięte")]),
                            _vm._v(". "),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "mar15",
                              attrs: { btn2: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showPopupForDefault =
                                    !_vm.showPopupForDefault
                                },
                              },
                            },
                            [_vm._v(" anuluj ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "mal15",
                              attrs: { btn1: "" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.showPopupForDefault =
                                    !_vm.showPopupForDefault),
                                    _vm.defaultEmailDomain()
                                },
                              },
                            },
                            [_vm._v(" Przywróć ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2824619973
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }