<template>
    <div class="checkpoint">
        <!-- Header row. -->
        <div class="row">
            <!-- Name. -->
            <div
                v-if="!editing && !edited"
                class="checkpoint__title"
            >
                <p>{{ isStart ? 'Start pomiaru' : isMeta ? 'Meta' : 'Międzyczas' }}</p>
                <b class="f16">{{ checkpointGroup.name }}</b>
            </div>

            <!-- Name input. -->
            <div
                v-else
                class="checkpoint__edit-title col-lg-4"
            >
                <base-input-text
                    v-model="name"
                    :label="isStart ? 'Start pomiaru' : isMeta ? 'Meta' : 'Międzyczas'"
                    :error.sync="error"
                />
            </div>

      <div class="d-flex">
        <!-- Edit button. -->
        <base-button
          btn9
          icon="fas fa-pencil-alt"
          class="edit-btn"
          :class="{ mar16: isMidpoint }"
          @click="editing = !editing"
        >
          Edytuj
        </base-button>

        <!-- Up/down buttons. -->
        <div v-if="isMidpoint" class="buttons-group">
          <base-button
            btn9
            class="radius0"
            icon="fas fa-caret-up"
            :disabled="checkpointGroups[position - 1].number === 0"
            @click="moveCheckpointGroup(-1)"
          />
          <base-button
            btn9
            class="radius0 border-left"
            icon="fas fa-caret-down"
            :disabled="checkpointGroups[position + 1].number === 1"
            @click="moveCheckpointGroup(+1)"
          />
        </div>
      </div>
    </div>

    <!-- Details. -->
    <div class="row checkpoint__content border-top--gray">
      <div class="col-lg-9">
        <div class="row mar30">
          <!-- Device. -->
          <div class="col-lg-12">
            <div class="mat8 row flex-nowrap">
              <!-- Select device input. -->
              <div :class="[ device && device.type === 'GEP' ? 'col-lg-6' : 'col-lg-8' ]">
                <p class="mab8">Urządzenie pomiarowe</p>
                <div class="h100 mar16">
                  <v-select
                    v-model="device"
                    label="name"
                    class="bg--white checkpoint__input-select"
                    :options="devices"
                    :clearable="false"
                  />
                </div>
              </div>
              <div v-if="device && device.type === 'GEP'">
                <div class="col-lg-2 mar16">
                  <p class="mab8">Odczyt co <span style="float:right;">sek</span></p>
                  <base-input-text
                    v-model="gapTime"
                    :error.sync="error"
                  />
                </div>
              </div>
              <!-- Add device button. -->
              <div class="col-lg-4">
                <p style="height: 29px;"></p>
                <base-button btn9 icon="far fa-plus" @click="addDeviceModal()">
                  Dodaj nowe urządzenie
                </base-button>

                <div class="positive" />
              </div>
            </div>
          </div>
          <!-- Checkpoints. -->
          <div class="col-lg-12 mat16">
              <div class="row mat16">
                  <div class="col-lg-8">
                      <p>
                          {{
                              isStart ? 'Start pomiaru dla klasyfikacji'
                                  : isMeta ? 'Meta' 
                                      : 'Międzyczas dla klasyfikacji'
                          }}
                      </p>
                  </div>
                  <div
                      v-if="isStart"
                      class="col-lg-4"
                  >
                      <p class="mal16">Czas startu</p>
                  </div>
              </div>
              <panel-competition-setting-stoper-item-checkpoint
                  v-for="classification in classifications"
                  :key="classification.id"
                  :checkpoint-name="checkpointGroup.name"
                  :classification-id="classification.id"
              />
          </div>
                    
          <!-- Stoper link. -->
          <div v-if="link" class="col-12 mat16">
            <div class="row">
              <div class="col-lg-12">Link do pomiaru</div>
              <div
                class="
                  col-lg-12
                  mat8
                  border-box--gray
                  checkpoint__link
                  align-items-center
                  justify-content-space-between
                "
              >
                <p>{{ link }}</p>
                <base-button
                  btn9
                  icon="far fa-link"
                  class="col-lg-3"
                  @click="copyLink(link)"
                >
                  Kopiuj link
                </base-button>
              </div>
            </div>
          </div>
          <div v-if="files && files.length > 0 && device && device.type !== 'SYS' && device.type !== 'STP' " class="col-12 mat16">
            <div class="row">
              <div class="col-12">Plik z wynikami</div>
              <div class="mat8" :class="[file ? 'col-8' : 'col-12']">
                <v-select
                  v-model="file"
                  label="name"
                  class="bg--white checkpoint__input-select"
                  :class="[file ? 'mar16' : '']"
                  placeholder="Wybierz plik"
                  :options="files"
                  :clearable="false"
                  style="height: 40px"
                />                
              </div>
              <div v-if="file" class="mat8 col-4">
                <base-button
                  btn9
                  icon="fas fa-window-close"
                  class=""
                  @click="REMOVE_FTP_MEASURES_IMPORTER"
                >
                  Odłącz urządzenie
                </base-button>                
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Remove checkpoint button. -->
      <div class="col-lg-3 d-flex columnWrap">
        <div class="infoWrap">
          <base-button
            v-if="!isMeta && !isStart"
            btn9
            icon="fas fa-trash-alt"
            class=""
            @click="REMOVE_CHECKPOINT_GROUP(checkpointGroup)"
          >
            {{ isStart ? "Usuń punkt startu" : "Usuń międzyczas" }}
          </base-button>
          <p v-if="files && files.length > 0 && device && device.type !== 'SYS' && device.type !== 'STP' ">Wybierz plik z wynikami z urządzenia pomiarowego </p>
        </div>
      </div>
    </div>
    <modal ref="modaladdDevice">
      <template v-slot:header> Dodaj urządzenie pomiarowe </template>

      <template v-slot:body>
        <div class="deviceswrap">
          <div class="row">
            <div class="col-lg-12">
              <p class="form-input__label">Wybierz urządzenie</p>
              <v-select
                style="height: 48px"
                v-model="newDevice.name"
                label="name"
                class="bg--white checkpoint__input-select"
                placeholder="Wybierz urządzenie"
                :options="newDevices"
                :clearable="false"
              />
            </div>
            <div class="mt-16 col-lg-12">
              <p class="form-input__label">Nazwa</p>
              <base-input-text v-model="newDevice.title" label="Nazwa" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <button class="btn" @click="$refs.modaladdDevice.closeModal()">
          Zamknij
        </button>
        <button class="btn btn-primary" @click="addDevice()">Dodaj</button>
      </template>
    </modal>
  </div>
</template>

<script>
import VSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import {
  SELECT_CHECKPOINT_GROUP_DEVICE,
  MOVE_CHECKPOINT_GROUP,
  UPDATE_CHECKPOINT_GROUP,
  ADD_OPERATOR_DEVICE,
  REMOVE_CHECKPOINT_GROUP,
} from "@/store/actions.type";
import PanelCompetitionSettingStoperItemCheckpoint from "@/components/PanelCompetitionSettingStoperItemCheckpoint";
import Modal from "@/components/Modal";
import api from '@/api';

export default {
  components: {
    Modal,
    VSelect,
    PanelCompetitionSettingStoperItemCheckpoint,
  },
  props: {
    competitionId: {
      type: Number,
      required: true,
    },
    checkpointName: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      editing: false,
      name: this.checkpointName,
      newDevice: {
        name: null,
        title: "",
      },
      file: null,
      interval: null,
      newDevices: [
        {
          id: 1,
          name: "Stoper",
          value: "STP",
        },
        {
          id: 2,
          name: "Gepard",
          value: "GEP",
        },
        {
          id: 3,
          name: "Chronotrack",
          value: "CHR",
        },
        {
          id: 4,
          name: "Race result",
          value: "RAR",
        },
      ],
      error: "",
      gapTime: 40,
    };
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters([
      "competitions",
      "organizers",
      "operators",
      "checkpointGroups",
      "classificationsBy",
      "devicesBy",
      "gatesBy",
      "checkpointsBy",
    ]),
    checkpointGroup() {
      // Should be excatly one group in this competition with this name.
      return this.checkpointGroups.find(
        (checkpointGroup) =>
          checkpointGroup.competition === this.competitionId &&
          checkpointGroup.name === this.checkpointName
      );
    },
    competition() {
      return this.competitions[this.competitionId];
    },
    classifications() {
      return this.classificationsBy(this.competition);
    },
    organizer() {
      return this.organizers[this.competition.organizer];
    },
    operator() {
      return this.operators[this.organizer.operator];
    },
    devices() {
      return this.devicesBy(this.operator);
    },
    isStart() {
      return this.checkpointGroup.number === 0;
    },
    isMeta() {
      return this.checkpointGroup.number === 1;
    },
    isMidpoint() {
      return !this.isStart && !this.isMeta;
    },
    position() {
      return this.checkpointGroups
        .map((checkpointGroup) => checkpointGroup.name)
        .indexOf(this.checkpointGroup.name);
    },
    edited() {
      return this.name !== this.checkpointGroup.name;
    },
    gate() {
      // Curently we support only one gate per checkpoint.
      return this.gatesBy(this.checkpointsBy(this.checkpointGroup))[0];
    },
    link() {
      const host = window.location.origin;

      return this.gate ? `${host}/pomiar-${this.gate.secureId}` : "";
    },
    device: {
      get() {
        const deviceId = this.gate?.device;

        return this.$store.getters.devices[deviceId];
      },
      set(device) {
        this.SELECT_CHECKPOINT_GROUP_DEVICE({
          checkpointGroup: this.checkpointGroup,
          device,
        });
      },
    },
  },
  watch: {
    async editing() {
      if (!this.editing && this.edited) {
        try {
          await this.UPDATE_CHECKPOINT_GROUP({
            ...this.checkpointGroup,
            name: this.name,
            gates: this.gate ? [this.gate.id] : [],
          });
        } catch (e) {
          this.error = "edit";
          this.editing = true;
        }
      }
    },
    checkpointName() {
      this.name = this.checkpointName;
    },
    file(newValue) {
      clearInterval(this.interval)
      if(newValue !== null) {
        this.FTP_MEASURES_IMPORTER(newValue);
        this.interval = setInterval(() => {
          this.FTP_MEASURES_IMPORTER(newValue);
        }, 30000)  
      }
    }
  },
  methods: {
    ...mapActions([
      SELECT_CHECKPOINT_GROUP_DEVICE,
      ADD_OPERATOR_DEVICE,
      MOVE_CHECKPOINT_GROUP,
      UPDATE_CHECKPOINT_GROUP,
      REMOVE_CHECKPOINT_GROUP,
    ]),
    moveCheckpointGroup(dir) {
      this.MOVE_CHECKPOINT_GROUP({
        checkpointGroup: this.checkpointGroup,
        position: this.position + dir,
        oldPosition: this.position,
      });
    },
    addDeviceModal() {
      this.$refs.modaladdDevice.openModal();
    },
    addDevice() {
      if (this.newDevice.title === "") {
        alert("Wypełnij nazwę");
      } else if (this.newDevice.name === null) {
        alert("Wybierz urządzenie");
      } else {
        this.ADD_OPERATOR_DEVICE({
          name: this.newDevice.title,
          type: this.newDevice.name.value,
        });
        this.newDevice.title = "";
        this.newDevice.name = null;
        this.$refs.modaladdDevice.closeModal();
      }

      // const stoperDevices = this.devices.filter(
      //     (device) => device.type === 'STP',
      // );
      // this.ADD_OPERATOR_DEVICE({
      //     name: `Stoper(${stoperDevices.length + 1})`,
      // });
    },
    copyLink(link) {
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    FTP_MEASURES_IMPORTER(file) {
      let data = { name: file }
      if(this.device.type === 'GEP') {
        data = { name: file, gapTime: this.gapTime }
      } 
      api.post(`/ftp-measures-importer/${this.gate.secureId}`, data );
    },
    REMOVE_FTP_MEASURES_IMPORTER() {
      this.file = null
    }
  },
};
</script>

<style scoped>
.checkpoint {
    border-radius: 4px;
    background-color: var(--color-slate);
    padding: 16px 30px 28px 30px;
    line-height: 1.5;
    margin-top: 16px;
}
.checkpoint:first-of-type {
    margin-top: 0;
}
.checkpoint__title {
    height: 40px;
}

.checkpoint__content {
    margin-top: 16px;
    padding-top: 26px;
}

.checkpoint__link {
    padding: 4px 4px 4px 16px;
    box-sizing: border-box;
}

.checkpoint__input-select {
  height: 40px;
}

.edit-btn {
    width: 137px;
}

.border-box--gray {
    border-radius: 4px;
    border: solid 1px var(--color-bluish-grey);
}

.border-top--gray {
    border-top: 1px solid var(--color-bluish-grey);
}
.buttons-group {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.border-left {
    border-left: 1px solid var(--color-bluish-grey) !important;
}
.checkpoint__input-select >>> .vs__dropdown-toggle {
    padding: 0;
    height: 100%;
}
.checkpoint__input-select >>> .vs__selected {
    margin-top: 0;
    height: 100%;
}
.modal .btn {
  height: 40px;
  min-width: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #546E7A;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
}

.modal .btn-disabled {
  background: #ECEFF1;
  border: 1px solid #CFD8DC;
  box-shadow: none;
  color: #455A64;
  pointer-events: none;
}

.modal .btn-success {
  background: #50AE55;
  color: #fff;
}

.modal .btn-primary {
  min-width: 268px;
  background: linear-gradient(270deg, #FF3D00 0%, #FF1744 99.2%);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}
.deviceswrap {
    padding: 16px;
    background-color: #ECEFF1;
}

.mt-16 {
    margin-top: 16px;
}
.columnWrap {
  flex-wrap: wrap;
  flex-direction: column;
}
.columnWrap .infoWrap {
  margin-top: auto;
}
.columnWrap .btn + p {
  margin-top: 49px;
}
@media screen and (max-width: 992px) {
  .modal .btn {
    min-width: 30%;
  }
  .modal .btn-primary {
    min-width: 45%;
  }
}
.input-field {
  min-height: 40px;
}
</style>
