var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c("div", _vm._b({ staticClass: "modal" }, "div", _vm.$attrs, false), [
          _c(
            "div",
            _vm._g(
              { staticClass: "modal__backdrop" },
              _vm.disabledBackdrop ? {} : { click: () => _vm.closeModal() }
            )
          ),
          _c("div", { staticClass: "modal__dialog", class: _vm.size }, [
            _c(
              "div",
              { staticClass: "modal__header" },
              [
                _vm._t("header"),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabledBackdrop,
                        expression: "!disabledBackdrop",
                      },
                    ],
                    staticClass: "modal__close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 352 512",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal__body", class: _vm.bodyClass },
              [_vm._t("body")],
              2
            ),
            _c("div", { staticClass: "modal__footer" }, [_vm._t("footer")], 2),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }