// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/pointer-black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.marker {\n    background-color: var(--color-slate-two);\n    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n    width: auto;\n}\n.marker--red {\n    background-image: linear-gradient(\n        to left,\n        var(--color-red-orange) 100%,\n        var(--color-pinky-red) 1%\n    );\n}\n.marker--disabled {\n    background-color: var(--color-bluish-grey);\n}\n.marker--with-icons {\n    position: relative;\n    display: flex !important;\n    padding: 4px;\n    width: unset !important;\n    height: unset !important;\n    box-sizing: unset;\n}\n.marker__popup {\n    min-width: 240px;\n    margin: -14px -20px;\n    padding: 8px;\n    background-color: var(--color-slate-two);\n    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n}\n.marker__pointer {\n    position: absolute;\n    top: 98%;\n    left: 12px;\n    -webkit-mask: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n            mask: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height: 8px;\n    width: 16px;\n    background: var(--color-slate-two);\n}\n.marker__pointer--red {\n    background-image: linear-gradient(\n        to left,\n        var(--color-red-orange) 100%,\n        var(--color-pinky-red) 1%\n    );\n}\n.marker__pointer--disabled {\n    background: var(--color-bluish-grey);\n}\n", ""]);
// Exports
module.exports = exports;
