var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkpoint" },
    [
      _c("div", { staticClass: "row" }, [
        !_vm.editing && !_vm.edited
          ? _c("div", { staticClass: "checkpoint__title" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.isStart
                      ? "Start pomiaru"
                      : _vm.isMeta
                      ? "Meta"
                      : "Międzyczas"
                  )
                ),
              ]),
              _c("b", { staticClass: "f16" }, [
                _vm._v(_vm._s(_vm.checkpointGroup.name)),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "checkpoint__edit-title col-lg-4" },
              [
                _c("base-input-text", {
                  attrs: {
                    label: _vm.isStart
                      ? "Start pomiaru"
                      : _vm.isMeta
                      ? "Meta"
                      : "Międzyczas",
                    error: _vm.error,
                  },
                  on: {
                    "update:error": function ($event) {
                      _vm.error = $event
                    },
                  },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "base-button",
              {
                staticClass: "edit-btn",
                class: { mar16: _vm.isMidpoint },
                attrs: { btn9: "", icon: "fas fa-pencil-alt" },
                on: {
                  click: function ($event) {
                    _vm.editing = !_vm.editing
                  },
                },
              },
              [_vm._v(" Edytuj ")]
            ),
            _vm.isMidpoint
              ? _c(
                  "div",
                  { staticClass: "buttons-group" },
                  [
                    _c("base-button", {
                      staticClass: "radius0",
                      attrs: {
                        btn9: "",
                        icon: "fas fa-caret-up",
                        disabled:
                          _vm.checkpointGroups[_vm.position - 1].number === 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.moveCheckpointGroup(-1)
                        },
                      },
                    }),
                    _c("base-button", {
                      staticClass: "radius0 border-left",
                      attrs: {
                        btn9: "",
                        icon: "fas fa-caret-down",
                        disabled:
                          _vm.checkpointGroups[_vm.position + 1].number === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.moveCheckpointGroup(+1)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row checkpoint__content border-top--gray" }, [
        _c("div", { staticClass: "col-lg-9" }, [
          _c("div", { staticClass: "row mar30" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "mat8 row flex-nowrap" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.device && _vm.device.type === "GEP"
                        ? "col-lg-6"
                        : "col-lg-8",
                    ],
                  },
                  [
                    _c("p", { staticClass: "mab8" }, [
                      _vm._v("Urządzenie pomiarowe"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "h100 mar16" },
                      [
                        _c("v-select", {
                          staticClass: "bg--white checkpoint__input-select",
                          attrs: {
                            label: "name",
                            options: _vm.devices,
                            clearable: false,
                          },
                          model: {
                            value: _vm.device,
                            callback: function ($$v) {
                              _vm.device = $$v
                            },
                            expression: "device",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.device && _vm.device.type === "GEP"
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "col-lg-2 mar16" },
                        [
                          _vm._m(0),
                          _c("base-input-text", {
                            attrs: { error: _vm.error },
                            on: {
                              "update:error": function ($event) {
                                _vm.error = $event
                              },
                            },
                            model: {
                              value: _vm.gapTime,
                              callback: function ($$v) {
                                _vm.gapTime = $$v
                              },
                              expression: "gapTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-lg-4" },
                  [
                    _c("p", { staticStyle: { height: "29px" } }),
                    _c(
                      "base-button",
                      {
                        attrs: { btn9: "", icon: "far fa-plus" },
                        on: {
                          click: function ($event) {
                            return _vm.addDeviceModal()
                          },
                        },
                      },
                      [_vm._v(" Dodaj nowe urządzenie ")]
                    ),
                    _c("div", { staticClass: "positive" }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-lg-12 mat16" },
              [
                _c("div", { staticClass: "row mat16" }, [
                  _c("div", { staticClass: "col-lg-8" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isStart
                              ? "Start pomiaru dla klasyfikacji"
                              : _vm.isMeta
                              ? "Meta"
                              : "Międzyczas dla klasyfikacji"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.isStart
                    ? _c("div", { staticClass: "col-lg-4" }, [
                        _c("p", { staticClass: "mal16" }, [
                          _vm._v("Czas startu"),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._l(_vm.classifications, function (classification) {
                  return _c(
                    "panel-competition-setting-stoper-item-checkpoint",
                    {
                      key: classification.id,
                      attrs: {
                        "checkpoint-name": _vm.checkpointGroup.name,
                        "classification-id": classification.id,
                      },
                    }
                  )
                }),
              ],
              2
            ),
            _vm.link
              ? _c("div", { staticClass: "col-12 mat16" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _vm._v("Link do pomiaru"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-lg-12 mat8 border-box--gray checkpoint__link align-items-center justify-content-space-between",
                      },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.link))]),
                        _c(
                          "base-button",
                          {
                            staticClass: "col-lg-3",
                            attrs: { btn9: "", icon: "far fa-link" },
                            on: {
                              click: function ($event) {
                                return _vm.copyLink(_vm.link)
                              },
                            },
                          },
                          [_vm._v(" Kopiuj link ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.files &&
            _vm.files.length > 0 &&
            _vm.device &&
            _vm.device.type !== "SYS" &&
            _vm.device.type !== "STP"
              ? _c("div", { staticClass: "col-12 mat16" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v("Plik z wynikami"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mat8",
                        class: [_vm.file ? "col-8" : "col-12"],
                      },
                      [
                        _c("v-select", {
                          staticClass: "bg--white checkpoint__input-select",
                          class: [_vm.file ? "mar16" : ""],
                          staticStyle: { height: "40px" },
                          attrs: {
                            label: "name",
                            placeholder: "Wybierz plik",
                            options: _vm.files,
                            clearable: false,
                          },
                          model: {
                            value: _vm.file,
                            callback: function ($$v) {
                              _vm.file = $$v
                            },
                            expression: "file",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.file
                      ? _c(
                          "div",
                          { staticClass: "mat8 col-4" },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  btn9: "",
                                  icon: "fas fa-window-close",
                                },
                                on: { click: _vm.REMOVE_FTP_MEASURES_IMPORTER },
                              },
                              [_vm._v(" Odłącz urządzenie ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "col-lg-3 d-flex columnWrap" }, [
          _c(
            "div",
            { staticClass: "infoWrap" },
            [
              !_vm.isMeta && !_vm.isStart
                ? _c(
                    "base-button",
                    {
                      attrs: { btn9: "", icon: "fas fa-trash-alt" },
                      on: {
                        click: function ($event) {
                          return _vm.REMOVE_CHECKPOINT_GROUP(
                            _vm.checkpointGroup
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isStart
                              ? "Usuń punkt startu"
                              : "Usuń międzyczas"
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.files &&
              _vm.files.length > 0 &&
              _vm.device &&
              _vm.device.type !== "SYS" &&
              _vm.device.type !== "STP"
                ? _c("p", [
                    _vm._v("Wybierz plik z wynikami z urządzenia pomiarowego "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c("modal", {
        ref: "modaladdDevice",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(" Dodaj urządzenie pomiarowe ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "deviceswrap" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-12" },
                      [
                        _c("p", { staticClass: "form-input__label" }, [
                          _vm._v("Wybierz urządzenie"),
                        ]),
                        _c("v-select", {
                          staticClass: "bg--white checkpoint__input-select",
                          staticStyle: { height: "48px" },
                          attrs: {
                            label: "name",
                            placeholder: "Wybierz urządzenie",
                            options: _vm.newDevices,
                            clearable: false,
                          },
                          model: {
                            value: _vm.newDevice.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.newDevice, "name", $$v)
                            },
                            expression: "newDevice.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-16 col-lg-12" },
                      [
                        _c("p", { staticClass: "form-input__label" }, [
                          _vm._v("Nazwa"),
                        ]),
                        _c("base-input-text", {
                          attrs: { label: "Nazwa" },
                          model: {
                            value: _vm.newDevice.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.newDevice, "title", $$v)
                            },
                            expression: "newDevice.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.modaladdDevice.closeModal()
                      },
                    },
                  },
                  [_vm._v(" Zamknij ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.addDevice()
                      },
                    },
                  },
                  [_vm._v("Dodaj")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mab8" }, [
      _vm._v("Odczyt co "),
      _c("span", { staticStyle: { float: "right" } }, [_vm._v("sek")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }