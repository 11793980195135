import { any, many } from '@/utils/array';
import { compile } from 'path-to-regexp';
import VueRouter from 'vue-router';

/* eslint-disable class-methods-use-this */
class Router extends VueRouter {
    static initialHistoryLength = window.history.length;

    resolve(to, current, append) {
        return super.resolve(this.parse(to), current, append);
    }

    back() {
        const canGoBack = window.history.length > this.initialHistoryLength;

        if (canGoBack) {
            super.back();
        } else {
            this.push({ name: 'main-landing', alias: true });
        }
    }

    push(to, onComplete, onAbort) {
        if (to.tab) {
            window.open(to.path, '_blank').focus();
        } else {
            super.push(this.parse(to), onComplete, onAbort);
        }
    }

    replace(to, onComplete, onAbort) {
        super.replace(this.parse(to), onComplete, onAbort);
    }

    matched(name, { matched }) {
        const names = many(name);
        const matchedNames = matched.map((subroute) => subroute.name);

        return any(names, matchedNames);
    }

    parse(location) {
        return Router.getParsed(location, this.options.routes);
    }

    static getParsed(location, routes) {
        if (location.alias) {
            const alias = Router.getAlias(location, routes);

            if (!alias) {
                throw new Error(`Router: Missing alias for location: ${location.name}`);
            }

            // Format competition name.
            const params = {
                ...location?.params ?? {},
                name: location.params?.name
                    ? String(location.params?.name)
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .replace(/\W+/g, ' ')
                        .trim()
                        .replace(/ /g, '-')
                        .toLocaleLowerCase()
                    : undefined,
            };

            return {
                ...location,
                name: undefined, // clear name
                path: compile(alias)(params), // set path explicitly
            };
        }

        return location;
    }

    static getAlias(location, routes) {
        for (const route of routes) {
            if (route.name === location.name) {
                return route.alias;
            }

            const childAlias = Router.getAlias(location, route.children || []);

            if (childAlias) {
                return childAlias;
            }
        }

        return null;
    }
}

export default Router;
