var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promote__box promote-box--competition mat16 pointer" },
    [
      _c(
        "div",
        {
          staticClass: "promote-competition",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "minisite-landing",
                params: _vm.competition,
                alias: true,
              })
            },
          },
        },
        [
          _c("div", { staticClass: "promote-competition__poster-box" }, [
            _c("img", {
              staticClass: "promote-competition__poster",
              attrs: { src: _vm.layout.posterLink },
            }),
          ]),
          _c("div", { staticClass: "promote-competition__info row mat24" }, [
            _c("div", { staticClass: "col-12 position-relative" }, [
              _c("p", { staticClass: "promote-competition__category" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("asType")(_vm.competition.type)) + " "
                ),
              ]),
              _c("h3", { staticClass: "promote-competition__title" }, [
                _vm._v(" " + _vm._s(_vm.competition.name) + " "),
              ]),
              _c("p", { staticClass: "promote-competition__place" }, [
                _vm._v(" " + _vm._s(_vm.competition.location) + " "),
              ]),
              _c("p", { staticClass: "promote-competition__date" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("asTime")(_vm.competition.startedTime)) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "promote-competition__hint" }, [
                _c("i", { staticClass: "fas fa-info-circle f16" }),
                _c("p", { staticClass: "d-inline mal8" }, [
                  _vm._v(" " + _vm._s(_vm.say.promoted_competition) + " "),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }