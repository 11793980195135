var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "competition-edit" },
      [
        _c("panel-competition-form", {
          attrs: { validation: _vm.errors },
          on: {
            "update:validation": function ($event) {
              _vm.errors = $event
            },
            save: function ($event) {
              return _vm.saveCompetition()
            },
          },
          model: {
            value: _vm.competitionToEdit,
            callback: function ($$v) {
              _vm.competitionToEdit = $$v
            },
            expression: "competitionToEdit",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }