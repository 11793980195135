var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row reset-password" }, [
      _c("div", { staticClass: "col-12 col-lg-3" }, [
        _c("h1", [_vm._v(_vm._s(_vm.say.reset_your_password))]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-9" }, [
        _c(
          "div",
          { staticClass: "row bg--gray reset-password__confirmation" },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("h2", { staticClass: "confirmation__title" }, [
                _vm._v(" " + _vm._s(_vm.say.check_email) + " "),
              ]),
            ]),
            _c("div", { staticClass: "col-12 confirmation__message" }, [
              _c("p", [_vm._v(_vm._s(_vm.say.password_reset_msg))]),
            ]),
            _c(
              "div",
              { staticClass: "col-12 col-lg-3" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "confirmation__return-button",
                    attrs: { btn4: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({
                          name: "authentication-login",
                          alias: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.say.return_to_login) + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }