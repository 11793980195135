var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-marker",
    {
      ref: "markersRef",
      attrs: {
        "lat-lng": [_vm.competition.locationLat, _vm.competition.locationLng],
        name: _vm.competition.name,
      },
      on: {
        ready: function ($event) {
          return _vm.$emit("markers", [
            _vm.competition.locationLat,
            _vm.competition.locationLng,
          ])
        },
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("l-icon", { attrs: { "icon-anchor": [15, 37] } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/marker-map.png") },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }