import { schema } from 'normalizr';

export default new schema.Entity('layouts', {}, {
    idAttribute: (value) => (value.competition ? value.competition : value.id), // one-to-one relation
    processStrategy: (layout) => ({
        // Meta.
        _type: 'layout',
        // Fields.
        ...layout,
    }),
});
