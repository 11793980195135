<template>
    <div class="link-input">
        <p class="link-input__value">
            {{ link }}
        </p>
        <base-button
            class="link-input__btn"
            btn9
            @click="onClick()"
        >
            <img
                v-if="isMobile"
                src="@/assets/images/link-icon.svg"
                alt="link-icon"
            >
            <span v-if="!isMobile"> {{ buttonName }}</span>
        </base-button>
        <div
            v-if="hint"
            class="link-input__hint"
        >
            {{ hint }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            required: true,
        },
        withHint: {
            type: Boolean,
            default: true,
        },
        buttonName: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'copy',
        },
        hint: {
            type: String,
            default: '',
        },

    },
    computed: {
        isMobile() {
            return ['sm', 'md'].includes(this.$mq);
        },
    },
    methods: {
        onClick() {
            if (this.type === 'copy') {
                this.copy();
            }
            if (this.type === 'open') {
                window.open(this.link, '_blank');
            }
        },
        copy() {
            const el = document.createElement('textarea');
            el.value = this.link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
    },
};
</script>

<style scoped>
.link-input {
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    height: 40px;
    position: relative;
    justify-content: space-between;
}
.link-input__value {
    color: var(--color-bluish-grey);
    color: #455a64;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    padding-left: 12px;
    align-items: center;
}
.link-input__btn {
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.link-input__hint {
    font-size: 12px;
    line-height: 16px;
    bottom: -16px;
    position: absolute;
    color: var(--color-silver);
    white-space: nowrap;
}
@media(min-width: 992px) {
    .link-input__value {
        color: var(--color-slate);
        max-width: 70%;
        width: 100%;
    }
    .link-input__btn {
            width: 100%;
            max-width: 30%;
        }
    }
</style>
