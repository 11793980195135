<template>
    <div
        class="row form"
        :class="{
            'form--align-end': alignEnd,
            'form--align-center': alignCenter,
        }"
    >
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        alignEnd: {
            type: Boolean,
            default: false,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style scoped>
.form {
    padding: 16px 16px 24px 16px;
    background-color: var(--color-slate);
    border-radius: 4px;
    margin-left: -15px;
    margin-right: -15px;
}
.form--align-end {
    align-items: flex-end;
}
.form--align-center {
    align-items: center;
}
.form__right {
    line-height: 21px;
}
@media (min-width: 992px) {
    .form {
        padding: 24px 30px 40px 30px;
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
