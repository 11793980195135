<template>
    <div :class="carouselItemClasses">
        <!-- Link. -->
        <router-link :to="link">
            <div class="home-promote__competition">
                <!-- Poster. -->
                <img
                    v-if="competition"
                    class="home-promote__competition-poster"
                    :src="layout.posterLink"
                >

                <!-- Poster skeleton. -->
                <div
                    v-else
                    class="home-promote__competition-poster home-promote__competition-poster--skeleton"
                >
                    <div class="lds-ellipsis lds-ellipsis--bottom-left">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>

                <!-- Description. -->
                <div
                    v-if="competition"
                    class="home-promote__competition-info row mat24"
                >
                    <div class="col-12">
                        <!-- Type. -->
                        <p class="home-promote__competition-category">
                            {{ competition.type | asType }}
                        </p>

                        <!-- Name. -->
                        <h3 class="home-promote__competition-title">
                            {{ competition.name }}
                        </h3>
                    </div>
                    <div class="home-promote__competition-info--bottom">
                        <!-- Location. -->
                        <p class="home-promote__competition-place">
                            {{ competition.location }}
                        </p>

                        <!-- Started time. -->
                        <p class="home-promote__competition-date">
                            {{ competition.startedTime | asTime }}
                        </p>

                        <!-- Prices range. -->
                        <!-- <p
                            v-if="competitionPrice && competitionPrice.minPrice && (competitionPrice.minPrice !== competitionPrice.maxPrice)"
                            class="home-promote__competition-price"
                        >
                            {{ say.participation_fee }} {{ competitionPrice.minPrice }} {{ currency }} - {{ competitionPrice.maxPrice }} {{ currency }}
                        </p> -->

                        <!-- Single price. -->
                        <!-- <p
                            v-else-if="competitionPrice && competitionPrice.minPrice"
                            class="home-promote__competition-price"
                        >
                            {{ say.participation_fee }} {{ competitionPrice.minPrice }} {{ currency }}
                        </p> -->

                        <!-- Feeless. -->
                        <!-- <p
                            v-else
                            class="home-promote__competition-price"
                        >
                            {{ say.competitions_feeless }}
                        </p> -->

                        <!-- Hint. -->
                        <div class="home-promote__competition-hint">
                            <i class="fas fa-info-circle f16" />
                            <p class="d-inline mal8">
                                {{ say.promoted_competition }}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Description skeleton. -->
                <div
                    v-else
                    class="row mat24"
                >
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 loading-text" />
                        </div>
                        <div class="row mat12">
                            <div class="col-9 loading-text" />
                        </div>
                        <div class="row mat16">
                            <div class="col-5 loading-text" />
                        </div>
                        <div class="row mat36">
                            <div class="col-6 loading-text" />
                        </div>
                        <div class="row mat18">
                            <div class="col-11 loading-text" />
                        </div>
                        <div class="row mat22 mab46">
                            <div class="col-9 loading-text" />
                        </div>
                    </div>
                </div>
            </div>
        </router-link>

        <div class="scroll-point" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'say',
            'competitions',
            // 'competitionsPrices',
            'layoutsBy',
            // 'competitionsCurrency',
        ]),
        competition() {
            return this.competitions[this.id];
        },
        // currency() {
        //     if (this.competitionsCurrency[this.competition.id]) {
        //         return this.competitionsCurrency[this.competition.id]
        //             .currencySymbol;
        //     }
        //     return 'zł';
        // },
        // competitionPrice() {
        //     return this.competitionsPrices[this.id];
        // },
        layout() {
            return this.layoutsBy(this.competition)[0];
        },
        link() {
            if (this.competition) {
                return {
                    name: 'minisite-landing',
                    params: this.competition,
                    alias: true,
                };
            }

            return '';
        },
        carouselItemClasses() {
            return {
                'home-promote__carousel-item': true,
                'justify-content-center': true,
                'col-lg-3': this.$route.name === 'main-landing',
                'col-lg-4': this.$route.name !== 'main-landing',
            };
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                if (this.competition) {
                    this.$emit('loaded');
                }
            },
        },
    },
};
</script>
