export default {
    ages: (state) => state.ages,
    categories: (state) => state.categories,
    checkpoints: (state) => state.checkpoints,
    classifications: (state) => state.classifications,
    classificationsSettings: (state) => state.classificationsSettings,
    competitions: (state) => state.competitions,
    competitionsPrices: (state) => state.competitionsPrices,
    competitionsSettings: (state) => state.competitionsSettings,
    competitionsCurrency: (state) => state.competitionsCurrency,
    cycles: (state) => state.cycles,
    devices: (state) => state.devices,
    familyAssociations: (state) => state.familyAssociations,
    gates: (state) => state.gates,
    layouts: (state) => state.layouts,
    limits: (state) => state.limits,
    locations: (state) => state.locations,
    measures: (state) => state.measures,
    operators: (state) => state.operators,
    organizers: (state) => state.organizers,
    players: (state) => state.players,
    panelPlayers: (state) => state.panelPlayers,
    prices: (state) => state.prices,
    results: (state) => state.results,
    trackpoints: (state) => state.trackpoints,
    users: (state) => state.users,
    gpxFiles: (state) => state.gpxFiles,
    competitionPlayerCounts: (state) => state.competitionPlayerCounts,
    paymentCounts: (state) => state.paymentCounts,
    categoryCounts: (state) => state.categoryCounts,
};
