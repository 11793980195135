<template>
    <div
        class="input-field input-field--focus-bolder"
        :class="{'input-field--error' : error}"
    >
        <label
            v-if="!error"
            class="input-field__label"
            :class="{'input-field__label--show' : isInput}"
        >{{ label }}</label>

        <google-maps-autocomplete
            class="input-field__input"
            :class="{
                'input-field__input--padding-bottom' : error,
                'input-field__input--padding-top' : isInputValid
            }"
            :options="options"
            :placeholder="placeholder"
            :value="value"
            @input="$emit('input', $event)"
            @place_changed="onPlaceChanged($event)"
        />

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import input from '@/mixins/input';
import { READ_GOOGLE_PLACE_PREDICTIONS, READ_GOOGLE_GEOCODE } from '@/store/actions.type';

export default {
    mixins: [input],
    data() {
        return {
            options: {
                types: ['(cities)'],
                fields: ['geometry', 'name'],
            },
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
    methods: {
        ...mapActions([READ_GOOGLE_PLACE_PREDICTIONS, READ_GOOGLE_GEOCODE]),
        async onPlaceChanged($event) {
            let place;

            if ($event.geometry) {
                // Selected place prediction.
                place = $event;
            } else if ($event.name) {
                // Selected raw input.
                place = await this.autocomplete($event.name);
            } else {
                // Selected empty input.
                place = undefined;
            }

            const location = {
                name: place.name ?? place.address_components[0].long_name ?? '',
                geometry: place.geometry
                    ? {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    }
                    : undefined,
            };

            this.$emit('select', location);
        },
        async autocomplete(name) {
            // Get name predictions.
            const predictions = await this.READ_GOOGLE_PLACE_PREDICTIONS({
                ...this.options,
                input: name,
            });

            // Geocode first prediction.
            const places = await this.READ_GOOGLE_GEOCODE({
                placeId: predictions[0].place_id,
            });

            // Return the place.
            return places[0];
        },
    },
};
</script>
