<template>
    <base-notification-local
        v-if="validationList.length"
        negative
    >
        <template v-slot:header>
            {{ say.correct_form_fields }}:
        </template>
        <template v-slot:content>
            {{ validationList }}.
        </template>
    </base-notification-local>
</template>

<script>
import { mapGetters } from 'vuex';
import { pick } from '@/utils/object';
import { toSnakeCase } from '@/utils/text';

export default {
    props: {
        validation: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['say']),
        validationList() {
            const fields = Object
                .entries(this.validation)
                .filter(([/** key */, value]) => value !== null)
                .map(([key]) => toSnakeCase(key));

            return Object.values(pick(this.say, fields)).join(', ');
        },
    },
};
</script>
