<template>
    <div class="family-child">
        <div class="d-flex align-items-center">
            <!-- <div
                class="family-child__img"
                style="background-image: url(https://placeimg.com/320/320/people);"
            > -->
            <div class="family-child__img">
                <strong>{{ child.firstname.charAt(0) }}{{ child.lastname.charAt(0) }}</strong>
            </div>
            <h3 class="family-child__name">
                {{ child.firstname }} {{ child.lastname }}
            </h3>
        </div>
        <div class="row family-child__info">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 family-child__info-section">
                <p>
                    {{ say.birthtime }}
                    <br>
                    <big>{{ child.birthTime | asDate }}</big>
                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 family-child__info-section">
                <p>
                    {{ say.city }}
                    <br>
                    <big>{{ child.city }}, {{ child.nationality }}</big>
                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 family-child__info-section">
                <div class="buttons-group">
                    <base-button
                        v-if="canEditChild(familyAssociation)"
                        class="btn-user btn-user--border-right"
                        icon="fa fa-pencil-alt f14"
                        btn5
                        @click="$router.push({name:'user-family-children-edit', params: child, alias: true})"
                    >
                        {{ say.edit_data }}
                    </base-button>
                    <base-button
                        class="btn-user"
                        icon="fa fa-trash-alt f14"
                        btn5
                        @click="DELETE_USER_CHILD(child.id)"
                    >
                        {{ say.delete_from_list }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import actions from '@/mixins/actions';
import { DELETE_USER_CHILD } from '@/store/actions.type';

export default {
    mixins: [actions],
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['say', 'familyAssociations', 'users']),
        familyAssociation() {
            return this.familyAssociations[this.id];
        },
        child() {
            return this.users[this.familyAssociation.childUser];
        },
    },
    methods: {
        ...mapActions([DELETE_USER_CHILD]),
    },
};
</script>

<style scoped>
.family-child__img {
    width: 64px;
    height: 64px;
    background-position: center center;
    background-size: cover;
    -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #546E7A;
}
.family-child__img strong { 
    font-size: 30px;
 }
.family-child__name {
    margin: 0;
}
.family-child__info {
    padding-left: 89px;
}
.family-child__info-section {
    margin-bottom: 16px;
    line-height: 1.43;
}
.buttons-group {
    display: flex;
}
.btn-user {
    font-size: 12px;
    padding: 0 12px;
    white-space: normal;
    width: 100%;
}
.btn-user--border-right {
    border-right: 1px solid rgba(55, 71, 79, 0.3);
}
</style>
