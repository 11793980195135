<template>
    <div>
        <!-- Main filter group. -->
        <div class="col-12 home-filter">
            <base-button
                btn2
                icon="fas fa-map"
                class="ma4 mab48"
                v-if="$route.name !== 'main-list-map' && this.filter.remote !== true"
                @click="$router.push({
                    name: 'main-list-map',
                    alias: true,
                })"
            >
                Zobacz mapę
            </base-button>
            <div class="row">
                <p class="bolder">
                    {{ say.competition_status }}
                </p>
                <!-- See all filter button. -->
                <p
                    class="d-inline c-positive pointer"
                    @click="showFiltersOnMobile = true"
                >
                    {{ say.other_filters }}
                </p>
            </div>
            <div class="row home-filter__buttons">
                <!-- Filter future. -->
                <base-button
                    btn6
                    class="ma4"
                    :pressed="enabledFutureFilter"
                    @click="toggleFutureFilter()"
                >
                    {{ say.all_upcoming }}
                </base-button>

                <!-- Filter past. -->
                <base-button
                    btn6
                    class="ma4"
                    :pressed="enabledPastFilter"
                    @click="togglePastFilter()"
                >
                    {{ say.past_competitions }}
                </base-button>

                <!-- Filter status with open registration. -->
                <base-button
                    btn6
                    class="ma4"
                    :pressed="enabledStatusOpenRegistrationFilter"
                    @click="toggleStatusOpenRegistrationFilter()"
                >
                    {{ say.open_registration }}
                </base-button>

                <!-- Filter status with results. -->
                <base-button
                    btn6
                    class="ma4"
                    :pressed="enabledStatusWithResultFilter"
                    @click="toggleStatusWithResultFilter()"
                >
                    {{ say.results }}
                </base-button>

                <!-- Filter remote. -->
                <base-button
                    v-if="$route.name !== 'main-list-map'"
                    btn6
                    class="ma4"
                    :pressed="enabledRemoteFilter"
                    @click="toggleRemoteFilter()"
                >
                    {{ say.remote }}
                </base-button>
            </div>
        </div>

        <!-- All filter group. -->
        <div
            class="col-lg-3 home-filter--desktop"
            :class="{'home-filter--other-filters': showFiltersOnMobile}"
        >
            <div class="container filters">
                <base-button
                    btn2
                    icon="fas fa-map"
                    class="ma4 mab48"
                    v-if="$route.name !== 'main-list-map' && this.filter.remote !== true"
                    @click="toggleMap()"
                >
                    Zobacz mapę
                </base-button>
                <h1 class="d-sm">
                    {{ say.filters }}
                </h1>
                <!-- Main filter group. -->
                <div class="row filters__section filters__section--first">
                    <!-- Clear main filter group. -->
                    <div class="mab12 col-12">
                        <b>{{ say.competition_status }}</b>
                        &nbsp;
                        <b
                            v-if="enabledMainFilter"
                            class="pointer c-orange"
                            @click="clearMainFilter()"
                        >{{ say.remove_filter }}</b>
                    </div>

                    <!-- Filter future. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledFutureFilter"
                        @click="toggleFutureFilter()"
                    >
                        {{ say.all_upcoming }}
                    </base-button>

                    <!-- Filter past. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledPastFilter"
                        @click="togglePastFilter()"
                    >
                        {{ say.past_competitions }}
                    </base-button>

                    <!-- Filter status with open registration. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledStatusOpenRegistrationFilter"
                        @click="toggleStatusOpenRegistrationFilter()"
                    >
                        {{ say.open_registration }}
                    </base-button>

                    <!-- Filter status with results. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledStatusWithResultFilter"
                        @click="toggleStatusWithResultFilter()"
                    >
                        {{ say.results }}
                    </base-button>

                    <!-- Filter remote. -->
                    <base-button
                        v-if="$route.name !== 'main-list-map'"
                        btn6
                        class="ma4"
                        :pressed="enabledRemoteFilter"
                        @click="toggleRemoteFilter()"
                    >
                        {{ say.remote }}
                    </base-button>
                </div>

                <!-- Date filter group. -->
                <div class="row filters__section">
                    <!-- Clear date filter. -->
                    <div class="col-12 mab12">
                        <b>{{ say.when }}</b>?
                        <b
                            v-if="enabledDateFilter"
                            class="pointer c-orange"
                            @click="clearDateFilter()"
                        >{{ say.remove_filter }}</b>
                    </div>

                    <!-- Filter date since. -->
                    <base-input-date
                        v-model="dateSince"
                        class="col-6"
                        :label="say.from_date"
                    />

                    <!-- Filter date to. -->
                    <base-input-date
                        v-model="dateTo"
                        class="col-6"
                        :label="say.to_date"
                    />
                </div>

                <!-- Types filter group. -->
                <div class="row filters__section">
                    <!-- Clear type filter. -->
                    <div class="col-12 mab12">
                        <b>{{ say.competitions_type }}</b>&nbsp;
                        <b
                            v-if="enabledTypesFilter"
                            class="pointer c-orange"
                            @click="clearTypesFilter()"
                        >{{ say.remove_filter }}</b>
                    </div>

                    <!-- Filter type. -->
                    <base-button
                        v-for="type in types"
                        :key="type"
                        btn6
                        class="ma4"
                        :pressed="enabledTypeFilter(type)"
                        @click="toggleTypeFilter(type)"
                    >
                        {{ say[`types_${type}`] }}
                    </base-button>
                </div>

                <!-- Pay filter group. -->
                <div class="row filters__section filters__section--last">
                    <!-- Clear pay filter. -->
                    <div class="col-12 mab12">
                        <b>{{ say.participation_fee }}</b>&nbsp;
                        <b
                            v-if="enabledPayFilter"
                            class="pointer c-orange"
                            @click="clearPayFilter()"
                        >{{ say.remove_filter }}</b>
                    </div>

                    <!-- Filter pay yes. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledPayYesFilter"
                        @click="togglePayYesFilter()"
                    >
                        {{ say.for_pay }}
                    </base-button>

                    <!-- Filter pay no. -->
                    <base-button
                        btn6
                        class="ma4"
                        :pressed="enabledPayNoFilter"
                        @click="togglePayNoFilter()"
                    >
                        {{ say.feeless }}
                    </base-button>
                </div>

                <!-- Bottom buttons. -->
                <div class="row filters__bottom-label">
                    <!-- Close filter button. -->
                    <p
                        class="pointer c-positive d-flex items-center mar30"
                        @click="showFiltersOnMobile = false, clearFilter()"
                    >
                        {{ say.see_all }}
                    </p>
                    <!-- Set filter button. -->
                    <base-button
                        btn1
                        @click="showFiltersOnMobile = false"
                    >
                        {{ say.set_filter }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { remove } from '@/utils/array';
import { SPORT_TYPES } from '@/config';
import { SET_FILTER } from '@/store/mutations.type';

export default {
    data() {
        return {
            types: SPORT_TYPES,
            showFiltersOnMobile: false,
        };
    },
    created() {
        if(this.$route.name === 'main-list-map' && !this.enabledMainFilter) {
            this.toggleFutureFilter()
        }
    },
    computed: {
        ...mapGetters(['say', 'time', 'filter']),
        today() {
            return this.time.startOf('day');
        },
        dateSince: {
            get() {
                return this.filter.dateSince;
            },
            set(dateSince) {
                this.SET_FILTER({
                    filter: {
                        ...this.filter,
                        dateSince,
                        page: 1,
                    },
                    route: this.$route,
                });
            },
        },
        dateTo: {
            get() {
                return this.filter.dateTo;
            },
            set(dateTo) {
                this.SET_FILTER({
                    filter: {
                        ...this.filter,
                        dateTo,
                        page: 1,
                    },
                    route: this.$route,
                });
            },
        },
        // Atomic filters.
        enabledRemoteFilter() {
            return this.filter.remote === true;
        },
        enabledFutureFilter() {
            return this.filter.dateSince?.isSame(this.today);
        },
        enabledPastFilter() {
            return this.filter.dateTo?.isSame(this.today);
        },
        enabledStatusOpenRegistrationFilter() {
            return this.filter.status === 2;
        },
        enabledStatusWithResultFilter() {
            return this.filter.status === 4;
        },
        enabledPayYesFilter() {
            return this.filter.pay === true;
        },
        enabledPayNoFilter() {
            return this.filter.pay === false;
        },
        // Group filters status.
        enabledMainFilter() {
            return (
                this.enabledFutureFilter
                || this.enabledPastFilter
                || this.enabledStatusOpenRegistrationFilter
                || this.enabledStatusWithResultFilter
                || this.enabledRemoteFilter
            );
        },
        enabledDateFilter() {
            return this.filter.dateSince || this.filter.dateTo;
        },
        enabledTypesFilter() {
            return this.filter.types.length > 0;
        },
        enabledPayFilter() {
            return this.filter.pay !== undefined;
        },
    },
    methods: {
        ...mapMutations([SET_FILTER]),
        // Parametrized.
        enabledTypeFilter(type) {
            return this.filter.types.includes(type);
        },
        toggleMap() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                },
                destination: 'main-list-map',
            });
        },        
        // Atomic filters toggle.
        toggleFutureFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    dateSince: this.enabledFutureFilter
                        ? undefined
                        : this.today,
                    dateTo: undefined,
                    page: 1,
                },
                route: this.$route,
            });
        },
        togglePastFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    dateSince: undefined,
                    dateTo: this.enabledPastFilter ? undefined : this.today,
                    page: 1,
                },
                route: this.$route,
            });
        },
        toggleStatusOpenRegistrationFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    status: this.enabledStatusOpenRegistrationFilter
                        ? undefined
                        : 2,
                    page: 1,
                },
                route: this.$route,
            });
        },
        toggleStatusWithResultFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    status: this.enabledStatusWithResultFilter ? undefined : 4,
                    page: 1,
                },
                route: this.$route,
            });
        },
        toggleRemoteFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    remote: this.enabledRemoteFilter ? undefined : true,
                    page: 1,
                },
                route: this.$route,
            });
        },
        toggleTypeFilter(type) {
            const { types } = this.filter;

            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    type: this.enabledTypeFilter(type)
                        ? remove(types, type)
                        : types.push(type),
                    page: 1,
                },
                route: this.$route,
            });
        },
        togglePayYesFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    pay: this.enabledPayYesFilter ? undefined : true,
                    page: 1,
                },
                route: this.$route,
            });
        },
        togglePayNoFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    pay: this.enabledPayNoFilter ? undefined : false,
                    page: 1,
                },
                route: this.$route,
            });
        },
        // Group filters toggle.
        clearMainFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    dateSince: undefined,
                    dateTo: undefined,
                    status: undefined,
                    remote: undefined,
                    page: 1,
                },
                route: this.$route,
            });
        },
        clearDateFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    dateSince: undefined,
                    dateTo: undefined,
                    page: 1,
                },
                route: this.$route,
            });
        },
        clearTypesFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    types: [],
                    page: 1,
                },
                route: this.$route,
            });
        },
        clearPayFilter() {
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    pay: undefined,
                    page: 1,
                },
                route: this.$route,
            });
        },
        clearFilter() {
            this.SET_FILTER({
                filter: {},
                route: this.$route,
            });
        },
    },
};
</script>
