var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.name !== "panel-competition-stoper" &&
    _vm.$router.name !== "panel-competition-map" &&
    !_vm.isZenMode
    ? _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("p", [
                _vm._v("© DO:ST:AR:TU " + _vm._s(_vm.time.format("YYYY"))),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "pointer",
                  attrs: {
                    tag: "p",
                    to: { name: "main-landing", alias: true },
                  },
                },
                [_vm._v(" regulamin platformy dostartu ")]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }