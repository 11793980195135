var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row login__content" }, [
      _c("div", { staticClass: "col-12 col-lg-3" }, [
        _c("h1", { staticClass: "par15" }, [
          _vm._v(" " + _vm._s(_vm.say.login) + " "),
        ]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-9" }, [
        _c("div", { staticClass: "login-section bg--gray row" }, [
          _c(
            "div",
            { staticClass: "login-section__inputs col-12 col-lg-5" },
            [
              _c("p", { staticClass: "mat32" }, [
                _vm._v(
                  " " + _vm._s(_vm.say.type_your_email_and_password) + " "
                ),
              ]),
              _c("base-input-text", {
                staticClass: "mat24",
                attrs: {
                  label: _vm.say.email_address,
                  error: _vm.errors.email,
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(_vm.errors, "email", $event)
                  },
                },
                model: {
                  value: _vm.credentials.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.credentials, "email", $$v)
                  },
                  expression: "credentials.email",
                },
              }),
              _c("base-input-password", {
                staticClass: "mat24",
                attrs: {
                  label: _vm.say.password,
                  error: _vm.errors.plainPassword,
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(_vm.errors, "plainPassword", $event)
                  },
                },
                model: {
                  value: _vm.credentials.plainPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.credentials, "plainPassword", $$v)
                  },
                  expression: "credentials.plainPassword",
                },
              }),
              _c(
                "p",
                { staticClass: "mat16" },
                [
                  _vm._v(" " + _vm._s(_vm.say.forgotten_password) + " "),
                  _c(
                    "router-link",
                    {
                      staticClass: "c-orange",
                      attrs: {
                        to: {
                          name: "authentication-reset",
                          alias: true,
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.reset_password) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row login__buttons-box" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c(
                "base-button",
                {
                  staticClass: "login__btn-primary",
                  attrs: { btn1: "" },
                  on: {
                    click: function ($event) {
                      return _vm.loginUser()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.login_to_account) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c(
                "base-button",
                {
                  staticClass: "login__btn-secondary",
                  attrs: { btn4: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.replace({
                        name: "authentication-register",
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.create_new_account) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c(
                "base-button",
                {
                  staticClass: "login__btn-cancel",
                  attrs: { btn4: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.cancel) + " ")]
              ),
            ],
            1
          ),
        ]),
        _vm.showRegisterWithoutLogin
          ? _c("div", { staticClass: "row register-without-account" }, [
              _c("div", { staticClass: "col-12 col-lg-5" }, [
                _c("p", { staticClass: "d-inline-block" }, [
                  _vm._v(" " + _vm._s(_vm.say.fast_register_ask) + " "),
                ]),
                _c("p", { staticClass: "d-inline-block" }, [
                  _vm._v(" " + _vm._s(_vm.say.collect_results_ask) + " "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "register-without-account__btn",
                      attrs: { btn4: "" },
                      on: { click: _vm.registerWithoutLogin },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.say.apply_for_participation_without_account
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }