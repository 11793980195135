var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row mat24" }, [
          _c("div", { staticClass: "col-lg-9 col-12 dashboard" }, [
            _c(
              "div",
              { staticClass: "dashboard-wrapper" },
              [
                _c("panel-novelty-boxes"),
                _vm.showUpdateInfo
                  ? _c(
                      "div",
                      {
                        staticClass: "update-results update-results__dashboard",
                      },
                      [
                        _c("div", { staticClass: "update-results-content" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "32",
                                height: "32",
                                viewBox: "0 0 32 32",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M17.1429 20.5625C17.1429 20.7232 17.0179 20.8571 16.8571 20.8571H15.1429C14.9821 20.8571 14.8571 20.7232 14.8571 20.5625V18.8661C14.8571 18.7054 14.9821 18.5714 15.1429 18.5714H16.8571C17.0179 18.5714 17.1429 18.7054 17.1429 18.8661V20.5625ZM17.125 17.2232C17.1161 17.3393 16.9821 17.4286 16.8214 17.4286H15.1696C15 17.4286 14.8661 17.3393 14.8661 17.2232L14.7143 13.1429C14.7143 13.0893 14.7411 13 14.8036 12.9554C14.8571 12.9107 14.9375 12.8571 15.0179 12.8571H16.9821C17.0625 12.8571 17.1429 12.9107 17.1964 12.9554C17.2589 13 17.2857 13.0714 17.2857 13.125L17.125 17.2232ZM17 8.88393C16.8036 8.51786 16.4196 8.28571 16 8.28571C15.5804 8.28571 15.1964 8.51786 15 8.88393L8.14286 21.4554C7.94643 21.8036 7.95536 22.2321 8.16071 22.5804C8.36607 22.9286 8.74107 23.1429 9.14286 23.1429H22.8571C23.2589 23.1429 23.6339 22.9286 23.8393 22.5804C24.0446 22.2321 24.0536 21.8036 23.8571 21.4554L17 8.88393Z",
                                  fill: "#FED25A",
                                },
                              }),
                            ]
                          ),
                          _c("b", [
                            _vm._v("Zmiany zostaną opublikowane do ~5 minut."),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "update-results-close",
                              on: {
                                click: function ($event) {
                                  _vm.showUpdateInfo = false
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "dashboard-link dashboard-link--without-margin",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "dashboard-link__label dashboard-link__label--",
                      },
                      [
                        _vm._v(
                          " Pod tym adresem użytkownicy mogą się zarejestrować i zobaczyć wyniki zawodów "
                        ),
                      ]
                    ),
                    _c("base-link-input", {
                      staticClass: "dashboard-link__value",
                      attrs: {
                        link: _vm.competitionPageLink,
                        "button-name": "Otwórz",
                        type: "open",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "dashboard-charts none-desktop" },
                  [
                    _c("chart-competition-players", {
                      staticClass: "dashboard-charts__item",
                      attrs: {
                        labels: _vm.dataCounters.chartLables,
                        "background-colors": _vm.dataCounters.chartBackgrounds,
                        data: _vm.dataCounters.registerCounter,
                        description: "zarejestrowanych uczestników",
                        summary: _vm.competitionPlayerCount.playersCount,
                      },
                    }),
                    _c("chart-competition-players", {
                      staticClass: "dashboard-charts__item",
                      attrs: {
                        labels: _vm.dataCounters.chartLables,
                        "background-colors": _vm.dataCounters.chartBackgrounds,
                        data: _vm.dataCounters.paidCounter,
                        description: "opłaconych uczestników",
                        summary: _vm.competitionPlayerCount.playersPayCount,
                      },
                    }),
                    _vm.paymentCount.value !== null
                      ? _c("div", { staticClass: "dashboard-charts__info" }, [
                          _c(
                            "span",
                            { staticClass: "dashboard-charts__info-price" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.paymentCount.value) + " zł "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dashboard-charts__info-description",
                            },
                            [_vm._v("zebranych wpłat")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "dashboard-info" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Lokalizacja "),
                      ]),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(" " + _vm._s(_vm.competition.location) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-3 col-6" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Data zawodów "),
                      ]),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asDate")(_vm.competition.startedTime)
                            ) +
                            " " +
                            _vm._s(
                              _vm.isMobile
                                ? _vm.asHour(_vm.competition.startedTime)
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-3 col-6" }, [
                      _vm._m(0),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asDate")(_vm.competition.provisionTime)
                            ) +
                            " " +
                            _vm._s(
                              _vm.isMobile
                                ? _vm.asHour(_vm.competition.provisionTime)
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 col-12" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Kategoria zawodów "),
                      ]),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("asType")(_vm.competition.type)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-3 none-mobile" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Godzina startu "),
                      ]),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asHour")(_vm.competition.startedTime)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-3 none-mobile" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Zapisy online do godziny "),
                      ]),
                      _c("p", { staticClass: "dashboard-info__value" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asHour")(_vm.competition.provisionTime)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("p", { staticClass: "dashboard-info__label" }, [
                        _vm._v(" Oficjalna strona zawodów "),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "dashboard-info__value dashboard-info__value--pointer",
                          on: {
                            click: function ($event) {
                              return _vm.openSite(_vm.competition.websitePl)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.competition.websitePl) + " ")]
                      ),
                    ]),
                    _vm._m(1),
                    _vm._m(2),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm.cycle
                      ? _c("div", { staticClass: "col-6" }, [
                          _c("p", { staticClass: "dashboard-info__label" }, [
                            _vm._v(" Cykl zawodów "),
                          ]),
                          _c("p", { staticClass: "dashboard-info__value" }, [
                            _vm._v(" " + _vm._s(_vm.cycle.name) + " "),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.competition.apiKey
                      ? _c("div", { staticClass: "col-6" }, [
                          _c("p", { staticClass: "dashboard-info__label" }, [
                            _vm._v(" Klucz API do zawodów "),
                          ]),
                          _c("p", { staticClass: "dashboard-info__value" }, [
                            _vm._v(" " + _vm._s(_vm.competition.apiKey) + " "),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "row mab36" }, [
                  _c("p", { staticClass: "dashboard-info__label" }, [
                    _vm._v(" Opis zawodów "),
                  ]),
                  _c("div", {
                    ref: "competitionDescription",
                    staticClass: "dashboard-info__description",
                    class: {
                      "dashboard-info__description--show-all":
                        _vm.showAllDescription || !_vm.isOverloadedDescription,
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.competition.descriptionPl),
                    },
                  }),
                  _vm.isOverloadedDescription
                    ? _c(
                        "div",
                        {
                          staticClass: "dashboard-info__more",
                          on: {
                            click: function ($event) {
                              _vm.showAllDescription = !_vm.showAllDescription
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showAllDescription
                                  ? "Pokaż mniej"
                                  : "Pokaż więcej"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm.competition.remarksPl
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("p", { staticClass: "dashboard-info__label" }, [
                          _vm._v(" Ważny komunikat dla uczestników "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dashboard-info__value mab36" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.competition.remarksPl) + " "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row mab150" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    _vm._l(_vm.classificationsIds, function (id) {
                      return _c("base-item-classification", {
                        key: id,
                        attrs: { id: id, "competition-id": _vm.competition.id },
                      })
                    }),
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-3 dashboard__chart none-mobile" },
            [
              _c("chart-competition-players", {
                staticClass: "mat16",
                attrs: {
                  labels: _vm.dataCounters.chartLables,
                  "background-colors": _vm.dataCounters.chartBackgrounds,
                  data: _vm.dataCounters.registerCounter,
                  description: "zarejestrowanych uczestników",
                  summary: _vm.competitionPlayerCount.playersCount,
                },
              }),
              _c("chart-competition-players", {
                staticClass: "mat24",
                attrs: {
                  labels: _vm.dataCounters.chartLables,
                  "background-colors": _vm.dataCounters.chartBackgrounds,
                  data: _vm.dataCounters.paidCounter,
                  description: "opłaconych uczestników",
                  summary: _vm.competitionPlayerCount.playersPayCount,
                },
              }),
              _vm.paymentCount.value !== null
                ? _c("div", { staticClass: "dashboard-charts__info" }, [
                    _c(
                      "span",
                      { staticClass: "dashboard-charts__info-price" },
                      [_vm._v(" " + _vm._s(_vm.paymentCount.value) + " zł ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "dashboard-charts__info-description" },
                      [_vm._v("zebranych wpłat")]
                    ),
                  ])
                : _vm._e(),
              _vm.paymentDetails && _vm.paymentDetails.lastCountedProvision > 0
                ? _c(
                    "div",
                    {
                      staticClass: "paymentDetailsBox",
                      class: { overdate: _vm.paymentDetails.overDate },
                    },
                    [
                      _c("div", [
                        _c("p", [_vm._v("opłata za korzystanie z systemu")]),
                        _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(
                              new Intl.NumberFormat("pl-PL", {
                                style: "currency",
                                currency: "PLN",
                              }).format(_vm.paymentDetails.lastCountedProvision)
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c("p", [
                            _vm._v(
                              "termin płatności " +
                                _vm._s(
                                  _vm.paymentDetails.paymentDate.split("T")[0]
                                )
                            ),
                          ]),
                          _c(
                            "base-button",
                            {
                              attrs: { btn2: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "panel-provision",
                                    alias: true,
                                  })
                                },
                              },
                            },
                            [_vm._v("Przejdź do opłat")]
                          ),
                          _c("p", [_vm._v("lub zadzwoń 502 678 564")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "dashboard-info__label" }, [
      _vm._v(" Zapisy online do "),
      _c("span", { staticClass: "none-mobile" }, [_vm._v("dnia")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("p", { staticClass: "dashboard-info__label" }),
      _c("p", { staticClass: "dashboard-info__value" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("p", { staticClass: "dashboard-info__label" }),
      _c("p", { staticClass: "dashboard-info__value" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "dashboard-info__value mab16" }, [
        _vm._v(" Klasyfikacje "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }