var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.ready
      ? _c(
          "div",
          [
            _vm.files.length > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "update-results",
                        staticStyle: { width: "auto" },
                      },
                      [
                        _c("div", { staticClass: "update-results-content" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "40",
                                height: "40",
                                viewBox: "0 0 40 40",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d: "M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20ZM25 28H22V17C22 16.4477 21.5523 16 21 16H17C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18H20V28H17C16.4477 28 16 28.4477 16 29C16 29.5523 16.4477 30 17 30H25C25.5523 30 26 29.5523 26 29C26 28.4477 25.5523 28 25 28ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38ZM22 11C22 10.4477 21.5523 10 21 10H19C18.4477 10 18 10.4477 18 11V13C18 13.5523 18.4477 14 19 14H21C21.5523 14 22 13.5523 22 13V11Z",
                                  fill: "#455A64",
                                },
                              }),
                            ]
                          ),
                          _vm._m(0),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._l(_vm.checkpointGroupsMinusMeta, function (checkpointGroup) {
              return _c(
                "panel-competition-setting-stoper-item-checkpoint-group",
                {
                  key: checkpointGroup.name,
                  attrs: {
                    "competition-id": checkpointGroup.competition,
                    "checkpoint-name": checkpointGroup.name,
                    files: _vm.files,
                  },
                }
              )
            }),
            _c("div", { staticClass: "row mat32 mab40" }, [
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "border-box--gray patb20 palr32 mar15" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { btn9: "", disabled: !_vm.canAddStart },
                        on: {
                          click: function ($event) {
                            return _vm.ADD_CHECKPOINT_GROUP(true)
                          },
                        },
                      },
                      [_vm._v(" Dodaj start pomiaru ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "border-box--gray mar15 mal15 patb20 palr32" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { btn9: "" },
                        on: {
                          click: function ($event) {
                            return _vm.ADD_CHECKPOINT_GROUP(false)
                          },
                        },
                      },
                      [_vm._v(" Dodaj międzyczas ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "border-box--gray mal15 patb20 palr32" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { btn9: "", disabled: !_vm.canAddMeta },
                        on: {
                          click: function ($event) {
                            return _vm.ADD_CHECKPOINT_GROUP_META()
                          },
                        },
                      },
                      [_vm._v(" Dodaj punkt mety ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._l(_vm.checkpointGroupsMeta, function (meta) {
              return _c(
                "panel-competition-setting-stoper-item-checkpoint-group",
                {
                  key: meta.id,
                  attrs: {
                    "competition-id": meta.competition,
                    "checkpoint-name": meta.name,
                    files: _vm.files,
                  },
                }
              )
            }),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-top": "48px" } },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-4" },
                  [
                    _c(
                      "base-button",
                      {
                        staticStyle: {
                          color: "#B0BEC5",
                          "border-color": "#B0BEC5",
                        },
                        attrs: { btn4: "" },
                        on: {
                          click: function ($event) {
                            _vm.showPopupForDefault = !_vm.showPopupForDefault
                          },
                        },
                      },
                      [_vm._v("Przywróć do domyślnych ustawień")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.showPopupForDefault
              ? _c("base-popup", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [_vm._v(" Przywróć do domyślnych ustawień ")]
                        },
                        proxy: true,
                      },
                      {
                        key: "subtitle",
                        fn: function () {
                          return [
                            _c("b", [
                              _vm._v(
                                "Czy na pewno, chcesz przywrócić ustawienia domyślne?"
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "mat16" }, [
                              _vm._v(" Ustawienia pomiaru czasu zostaną "),
                              _c("b", [_vm._v("usunięte")]),
                              _vm._v(". "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "buttons",
                        fn: function () {
                          return [
                            _c(
                              "base-button",
                              {
                                staticClass: "mar15",
                                attrs: { btn2: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPopupForDefault =
                                      !_vm.showPopupForDefault
                                  },
                                },
                              },
                              [_vm._v(" anuluj ")]
                            ),
                            _c(
                              "base-button",
                              {
                                staticClass: "mal15",
                                attrs: { btn1: "" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.showPopupForDefault =
                                      !_vm.showPopupForDefault),
                                      _vm.defaultChrono()
                                  },
                                },
                              },
                              [_vm._v(" Przywróć ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    655074694
                  ),
                })
              : _vm._e(),
          ],
          2
        )
      : _c("div", { staticClass: "loader" }, [_vm._m(1)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("b", [_vm._v("Nie zamykaj tej strony w trakcie pomiaru czasu")]),
      _c("p", [
        _vm._v(
          "Zadaniem tej strony jest ciągłe wysyłanie wyników z aparatury pomiarowej do platformy dostartu."
        ),
        _c("br"),
        _vm._v(
          "Jeśli zamnkiesz tę stronę, przesył wyników zostanie przerwany."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis lds-ellipsis--center" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }