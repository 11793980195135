<template>
    <div class="container">
        <div class="row reset-password">
            <div class="col-12 col-lg-3">
                <h1>{{ say.reset_your_password }}</h1>
            </div>
            <div class="col-12 col-lg-9">
                <div class="row bg--gray reset-password__email-input">
                    <div class="col-12 col-lg-5 reset-password__hint">
                        <p>{{ say.enter_email_address_from_register }}</p>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- Email. -->
                        <base-input-text
                            v-model="email"
                            :label="say.email_address"
                            :error.sync="errors.email"
                        />
                    </div>
                </div>
                <div class="row reset-password__box-buttons">
                    <div class="col-12 col-lg-4">
                        <base-button
                            btn1
                            class="box-buttons__reset-button"
                            @click="resetPassword"
                        >
                            {{ say.reset_your_password }}
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-2 box-buttons__cancel">
                        <base-button
                            btn4
                            @click="$router.back()"
                        >
                            {{ say.cancel }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RESET_PASSWORD } from '@/store/actions.type';

export default {
    data() {
        return {
            email: '',
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
    methods: {
        ...mapActions([RESET_PASSWORD]),
        async resetPassword() {
            this.errors = {};
            try {
                await this.RESET_PASSWORD(this.email);

                this.$router.push({ name: 'authentication-reset-confirmation', alias: true });
            } catch (e) {
                this.errors = e;
            }
        },
    },
};
</script>
