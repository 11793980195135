var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "info-box--grey pa16 matb15" }, [
      _c(
        "div",
        { staticClass: "row justify-content-space-between align-items-center" },
        [
          _c("strong", { staticClass: "col-12 col-lg-9" }, [
            _vm._v(_vm._s(_vm.say.add_members_to_your_family)),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-lg-3" },
            [
              _c(
                "base-button",
                {
                  staticClass: "btn-add",
                  attrs: { btn1: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "user-family-children-add",
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.add_member) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "pat5 pab40" },
      _vm._l(_vm.childrenAssociations, function (association) {
        return _c("user-family-item-child", {
          key: association.id,
          attrs: { id: association.id },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }