<template>
  <div>
        <h3 class="mab24">{{ say.more }}</h3>
        <h4 class="mab12">{{ say.access_account_settings }}</h4>
        <div class="bsh mab24">
            <base-button
                btn6
                class="minisite__button"
                @click="
                    $router.push({
                        name: 'edit-credentials',
                        alias: true,
                    })
                "
            >
                <span>{{ say.change_password }}</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button>
        </div>
        <h4 class="mab12">{{ say.participant_profile_settings }}</h4>
        <div class="bsh mab24">
            <base-button
                btn6
                class="minisite__button"
                @click="
                    $router.push({
                        name: 'user-edit-profile',
                        alias: true,
                    })
                "
            >
                <span>{{ say.edit_personal_data }}</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button>
            <base-button
                btn6
                class="minisite__button"
                @click="
                    $router.push({
                        name: 'user-edit-insurance',
                        alias: true,
                    })
                "
            >
                <span>{{ say.edit_insurance_data }}</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button>
            <!-- <base-button
                btn6
                class="minisite__button"
                @click="
                    $router.push({
                        name: '',
                        alias: true,
                    })
                "
            >
                <span>Edytuj dane faktur VAT</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button> -->
        </div>
        <h4 class="mab12">{{ say.enable_notifications_for }}</h4>
        <div class="bsh mab24">
            <base-button
                btn6
                class="minisite__button minisite__button__nptb"
            >
                <span>{{ say.notifications_for_proposed_competition }}</span>
                <base-input-switch @change.native="updateNotifications()" v-model="notifications.proposedCompetition" :checked="notifications.proposedCompetition" />
            </base-button>
            <base-button
                btn6
                class="minisite__button minisite__button__nptb"
            >
                <span>{{ say.notifications_for_upcoming_races }}</span>
                <base-input-switch @change.native="updateNotifications()" v-model="notifications.incomingCompetition" :checked="notifications.incomingCompetition" />
            </base-button>
            <base-button
                btn6
                class="minisite__button minisite__button__nptb"
            >
                <span>{{ say.notifications_for_my_results }}</span>
                <base-input-switch @change.native="updateNotifications()" v-model="notifications.myResults" :checked="notifications.myResults" />
            </base-button>
        </div>
        <h4 class="mab12">{{ say.extra_inputs }}</h4>
        <div class="bsh mab32">
            <base-button
                btn6
                class="minisite__button"
                @click="navigate(BASE_URL + 'polityka_prywatnosci.pdf')"
            >
                <span>{{ say.privacy_policy }}</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button>
            <base-button
                btn6
                class="minisite__button"
                @click="navigate(BASE_URL + 'Regulamin serwisu.pdf')"
            >
                <span>{{ say.dostartu_regulations }}</span>
                <img src="@/assets/images/icons/chevron-right.svg" alt="">
            </base-button>
        </div>
        <div class="bsh mab32">
            <base-button
                btn6
                class="minisite__button"
                @click="logoutUser()"
            >
                <span>{{ say.logout }}</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10 3.77614 10.2239 4 10.5 4H18.5C19.3284 4 20 4.67157 20 5.5V18.5C20 19.3284 19.3284 20 18.5 20H10.5C10.2239 20 10 20.2239 10 20.5C10 20.7761 10.2239 21 10.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3H10.5C10.2239 3 10 3.22386 10 3.5ZM7.85355 15.1464L4.70711 12H15.5C15.7761 12 16 11.7761 16 11.5C16 11.2239 15.7761 11 15.5 11H4.70711L7.85355 7.85355C8.04882 7.65829 8.04882 7.34171 7.85355 7.14645C7.65829 6.95118 7.34171 6.95118 7.14645 7.14645L3.14645 11.1464C2.95118 11.3417 2.95118 11.6583 3.14645 11.8536L7.14645 15.8536C7.34171 16.0488 7.65829 16.0488 7.85355 15.8536C8.04882 15.6583 8.04882 15.3417 7.85355 15.1464Z" fill="#FF1744"/>
                </svg>
            </base-button>
        </div>
        <div @click="erase()" class="deleteAccount">
            {{ say.delete_your_account }}
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BASE_URL } from '@/config';
import { 
    LOGOUT_USER,
    DELETE_USER,
    CREATE_USER_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATIONS,
    READ_USER_NOTIFICATIONS,
} from '@/store/actions.type';

export default {
    name: "UserMobileMore",
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['say', 'notifications', 'createNotifications']),
        BASE_URL() {
            return BASE_URL;
        },        
    },
    methods: {
        ...mapActions([
            LOGOUT_USER,
            DELETE_USER,
            CREATE_USER_NOTIFICATIONS,
            UPDATE_USER_NOTIFICATIONS,
            READ_USER_NOTIFICATIONS,
,
        ]),
        async logoutUser() {
            await this.LOGOUT_USER();

            this.$router.replace({
                name: 'authentication-login',
                alias: true,
            });
        },        
        async erase() {
            await this.DELETE_USER();

            this.$router.push({ name: 'main-landing', alias: true });
        },
        updateNotifications() {
            this.$messaging.getToken({ vapidKey: 'BMD60s7nRtWIi-qladjK7a1kHi1TCNSODn9XaU-nghZVvbs2mE78WHInwzIPMymEFKSdNMbfc-OQaYBcRcI02fM' }).then((currentToken) => {
                if (currentToken) {
                    this.notifications.deviceToken = currentToken
                    if(this.createNotifications) {
                        this.CREATE_USER_NOTIFICATIONS(this.notifications)
                    } else {
                        this.UPDATE_USER_NOTIFICATIONS(this.notifications)
                    }                    
                    // console.log('client token', currentToken)
                }
                }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            })            
        },
        navigate(link) {
            var a = document.createElement('a');
            a.href = link;
            a.setAttribute('target', '_blank');
            a.click();
        }
    },
    mounted() {
        this.READ_USER_NOTIFICATIONS();
    },
}
</script>

<style scoped>
.bsh {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(55, 71, 79, 0.2);
    border-radius: 4px;
}
.minisite__button {
    padding: 14px 15px;
    line-height: 1.43;
    border: none;
    border-bottom: 1px solid #edf0f1;
    box-shadow: none;
    justify-content: space-between;
}
.minisite__button__nptb {
    cursor: initial;
    padding-top: 0;
    padding-bottom: 0;
}
.deleteAccount {
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    color: var(--color-red-orange);
}
</style>