var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiple", class: { error: _vm.error } }, [
    _c(
      "div",
      { staticClass: "multiple__values" },
      _vm._l(_vm.value, function (val, idx) {
        return _c("span", { key: idx, staticClass: "value" }, [
          _vm._v(_vm._s(val === "" ? "?" : val)),
        ])
      }),
      0
    ),
    _c("input", {
      staticClass: "multiple__input",
      attrs: { id: "multipleInput", type: "text", readonly: _vm.readOnly },
      domProps: { value: _vm.input },
      on: {
        input: function ($event) {
          $event.target.value === " "
            ? null
            : _vm.$emit("input", $event.target.value)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
              "Backspace",
              "Delete",
              "Del",
            ])
          )
            return null
          _vm.input.length === 0 ? _vm.popItem() : null
        },
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            return _vm.pushItem()
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            _vm.handleEnter(), _vm.$emit("enter")
          },
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }