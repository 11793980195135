var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "trackVisibility", staticClass: "col-12 col-md-3 mat33 pat15" },
    [
      !_vm.visibility
        ? _c("div", { staticClass: "mtopbar patb15" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row flex-nowrap" }, [
                _c(
                  "div",
                  { staticClass: "col-6 col-md-3" },
                  [_c("minisite-button", { staticClass: "mtopbar__button" })],
                  1
                ),
                _c("div", { staticClass: "col-6 col-md-5" }, [
                  _c("h4", [_vm._v(" " + _vm._s(_vm.competition.name) + " ")]),
                ]),
                _c("div", { staticClass: "d-none d-md-block col-md-4" }, [
                  _vm.competition.location
                    ? _c("p", [
                        _vm._v(" " + _vm._s(_vm.competition.location) + " "),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asTime")(_vm.competition.startedTime)
                            ) +
                            " "
                        ),
                      ])
                    : _c("p", [
                        _vm._v(" " + _vm._s(_vm.say.whole_world) + " "),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("asTime")(_vm.competition.startedTime)
                            ) +
                            " "
                        ),
                      ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "sidebar-wrapper" },
        [
          _c("minisite-button"),
          _c(
            "div",
            {
              class: [
                _vm.competition.status === 2 || _vm.competition.status >= 4
                  ? "bsh mat32"
                  : "bsh",
              ],
            },
            [
              (_vm.competition.status <= 3 || _vm.competition.isRemote) &&
              ![4439, 4094, 4095, 5165, 8515, 8502, 8519, 8520, 8526].includes(
                _vm.competition.id
              )
                ? _c(
                    "base-button",
                    {
                      staticClass: "minisite__button",
                      attrs: { btn6: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "minisite-list",
                            params: _vm.competition,
                            alias: true,
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.start_list) + " ")]
                  )
                : _vm._e(),
              _vm.canDeclareResults(_vm.user, _vm.competition)
                ? _c(
                    "base-button",
                    {
                      staticClass: "minisite__button",
                      attrs: { btn6: "" },
                      on: {
                        click: function ($event) {
                          _vm.competition.status < 3
                            ? _vm.$router.push({
                                name: "user-competitions-future",
                                alias: true,
                              })
                            : _vm.$router.push({
                                name: "user-competitions-past",
                                alias: true,
                              })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.add_your_result) + " ")]
                  )
                : _vm._e(),
              _vm.competition.status === 4 || _vm.competition.isRemote
                ? _c(
                    "base-button",
                    {
                      staticClass: "minisite__button",
                      attrs: { btn6: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "minisite-results-pick-classification",
                            params: _vm.competition,
                            alias: true,
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.results) + " ")]
                  )
                : _vm._e(),
              _vm.competition.statuteLinkPl || _vm.competition.statuteFilePl
                ? _c(
                    "base-button",
                    {
                      staticClass: "minisite__button",
                      attrs: { btn6: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            path:
                              _vm.competition.statuteLinkPl ||
                              _vm.competition.statuteFilePl,
                            tab: true,
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.regulations) + " ")]
                  )
                : _vm._e(),
              _c(
                "base-button",
                {
                  staticClass: "minisite__button",
                  attrs: { btn6: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "minisite-contact",
                        params: _vm.competition,
                        alias: true,
                      })
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.contact_with_organizer) + " ")]
              ),
            ],
            1
          ),
          _vm.layout.logoLink
            ? _c("img", {
                staticClass: "mat48 w100",
                attrs: { src: _vm.layout.logoLink },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }