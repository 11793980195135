<template>
    <div class="container">
        <div class="row panel-promote">
            <div class="col-12 col-lg-2">
                <h1 class="panel-promote__title">
                    Promuj zawody na dostartu.pl
                </h1>
            </div>
            <div class="col-4 col-lg-2">
                <div class="panel-promote__box-img">
                    <div
                        v-if="isFetching"
                        class="panel-promote__lack-poster"
                    >
                        <div class="mal15 lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    <img
                        v-else-if="isPoster"
                        class="panel-promote__poster"
                        :src="layout.posterLink"
                        alt="promotion poster"
                    >
                    <div
                        v-else
                        class="panel-promote__lack-poster"
                    >
                        Brak plakatu
                    </div>
                </div>
            </div>
            <div class="col-8 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div
                            class="panel-promote__box panel-promote__box--first"
                        >
                            <p class="panel-promote__box-label none-mobile">
                                Nazwa zawodów
                            </p>
                            <p
                                class="panel-promote__box-value panel-promote__box-value--link"
                                @click="
                                    $router.push({
                                        path: `https://dostartu.pl/${competition.name}-v${competition.id}`,
                                        tab: true,
                                    })
                                "
                            >
                                {{ competition.name }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div
                            class="panel-promote__box panel-promote__box--start"
                        >
                            <p class="panel-promote__box-label">
                                Start zawodów
                            </p>
                            <p class="panel-promote__box-value">
                                {{ competition.startedTime | asDate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 panel-promote__actions">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="panel-promote__box">
                            <p
                                class="panel-promote__box-label panel-promote__box-label--date"
                            >
                                W promowanych od
                            </p>
                            <base-input-date
                                v-model="dateStart"
                                class="panel-promote__date"
                                label="DD.MM.RRRR"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="panel-promote__box">
                            <p
                                class="panel-promote__box-label panel-promote__box-label--date"
                            >
                                W promowanych do
                            </p>
                            <base-input-date
                                v-model="dateEnd"
                                class="panel-promote__date"
                                label="DD.MM.RRRR"
                            />
                        </div>
                    </div>
                </div>
                <div class="row panel-promote__buttons">
                    <div class="col-12 col-lg-6">
                        <base-button
                            btn2
                            class="panel-promote__btn"
                            @click="$emit('close-promote')"
                        >
                            Anuluj
                        </base-button>
                    </div>
                    <div class="col-12 col-lg-6">
                        <base-button
                            :disabled="!isPoster"
                            btn1
                            class="panel-promote__btn panel-promote__btn--promote"
                            @click="promote()"
                        >
                            Promuj
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    UPDATE_COMPETITION,
    READ_COMPETITION_LAYOUT,
} from '@/store/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        competition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dateStart: null,
            dateEnd: null,
            isFetching: true,
        };
    },
    computed: {
        ...mapGetters(['layouts']),
        isPoster() {
            return this.layout && this.layout.posterLink;
        },
        layout() {
            return this.layouts[this.competition.id];
        },
    },
    watch: {
        competition: {
            immediate: true,
            deep: true,
            async handler() {
                this.isFetching = true;
                await this.READ_COMPETITION_LAYOUT(this.competition.id);
                this.isFetching = false;
            },
        },
    },
    methods: {
        ...mapActions([UPDATE_COMPETITION, READ_COMPETITION_LAYOUT]),
        async promote() {
            await this.UPDATE_COMPETITION({
                ...this.competition,
                promotedSince: new Date(this.dateStart),
                promotedTo: new Date(this.dateEnd),
            });
            this.$emit('close-promote');
        },
    },
};
</script>

<style scoped>
.panel-promote__date {
    color: black !important;
}
.panel-promote {
    background-color: var(--color-slate);
    padding: 32px 16px;
    border-radius: 4px;
    justify-content: flex-end;
    margin: 0 -15px;
}
.panel-promote__poster {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 8px 12px rgba(0, 0, 0, 0.1);
    max-height: 400px;
}
.panel-promote__box-img {
    margin-right: 18px;
    height: 100%;
}
.panel-promote__box {
    margin-top: 16px;
}
.panel-promote__box--start {
    margin-top: 22px;
}
.panel-promote__box--first {
    margin-top: 0;
}
.panel-promote__title {
    margin-bottom: 16px;
    font-size: 18px;
}
.panel-promote__btn {
    box-sizing: border-box;
    margin-top: 16px;
    min-height: 40px;
    max-height: 40px;
}

.panel-promote__date >>> .filters-datepicker__wrapper-class {
    margin: 0 !important;
}
.panel-promote__buttons {
    flex-direction: column-reverse;
    margin-top: 16px;
}
.panel-promote__box-value {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.panel-promote__box-value--link {
    cursor: pointer;
}
.panel-promote__label {
    line-height: 20px;
}
.panel-promote__box-label--date {
    margin-bottom: 8px;
}
.panel-promote__btn--promote {
    font-weight: 600;
}
.panel-promote__lack-poster {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 8px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 115px;
}
@media (min-width: 992px) {
    .panel-promote__lack-poster {
        height: 220px;
    }
    .panel-promote {
        margin: 0;
        padding: 24px 16px;
    }
    .panel-promote__box {
        margin: 0 15px;
    }
    .panel-promote__btn {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 32px;
    }
    .panel-promote__title {
        font-size: 24px;
        line-height: 32px;
        margin: 0 15px;
    }
    .panel-promote__buttons {
        flex-direction: row;
        margin-top: auto;
    }
    .panel-promote__box-value--link {
        margin-bottom: 15px;
    }
    .panel-promote__box-img {
        margin: 0 15px;
    }
    .panel-promote__actions {
        display: flex;
        flex-direction: column;
    }
    .panel-promote__btn {
        margin-top: 0;
    }
    .panel-promote__poster {
        min-height: 220px;
        max-height: 230px;
    }
}
</style>
