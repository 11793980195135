var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "rangeSlider",
        staticStyle: {
          "background-color": "var(--vt-c-white)",
          "max-width": "956px",
        },
      },
      [
        _c(
          "div",
          { ref: "sliderTrack", staticClass: "rangeSlider__track" },
          _vm._l(_vm.categories, function (item, index) {
            return _c("RangeSliderItem", {
              key: _vm.keys[index],
              attrs: { data: item, index: index },
              on: { active: _vm.activeHandle, disabled: _vm.disableHandle },
            })
          }),
          1
        ),
        _c(
          "div",
          { ref: "sliderHandles", staticClass: "rangeSlider__handles" },
          _vm._l(_vm.categories, function (item, index) {
            return _c("RangeSliderHandle", {
              key: _vm.keys[index],
              attrs: {
                data: item,
                index: index,
                categories: _vm.categories,
                length: _vm.categories.length - 1,
                gap: _vm.trackGap,
                "max-width": _vm.trackWidth,
              },
              on: {
                remove: _vm.removeHandle,
                update: _vm.updateData,
                "update:end": _vm.dragEnd,
                "update:handleend": _vm.dragHandleEnd,
              },
            })
          }),
          1
        ),
        _c("RangeSliderNewHandle", {
          attrs: { gap: _vm.trackGap },
          on: { new: _vm.newRange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }