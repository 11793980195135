var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mab24" }, [_vm._v("Powiadomienia")]),
      _c("p", { staticClass: "mab24" }, [
        _vm._v(
          "Możemy przypominać Ci o nadchodzących startach oraz informować o wynikach i nowych zawodach."
        ),
      ]),
      _c("h4", { staticClass: "mab12" }, [
        _vm._v(_vm._s(_vm.say.enable_notifications_for)),
      ]),
      _c(
        "div",
        { staticClass: "bsh mab24" },
        [
          _c(
            "base-button",
            {
              staticClass: "minisite__button minisite__button__nptb",
              attrs: { btn6: "" },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.say.notifications_for_proposed_competition)),
              ]),
              _c("base-input-switch", {
                attrs: { checked: _vm.notifications.proposedCompetition },
                nativeOn: {
                  change: function ($event) {
                    return _vm.updateNotifications()
                  },
                },
                model: {
                  value: _vm.notifications.proposedCompetition,
                  callback: function ($$v) {
                    _vm.$set(_vm.notifications, "proposedCompetition", $$v)
                  },
                  expression: "notifications.proposedCompetition",
                },
              }),
            ],
            1
          ),
          _c(
            "base-button",
            {
              staticClass: "minisite__button minisite__button__nptb",
              attrs: { btn6: "" },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.say.notifications_for_upcoming_races)),
              ]),
              _c("base-input-switch", {
                attrs: { checked: _vm.notifications.incomingCompetition },
                nativeOn: {
                  change: function ($event) {
                    return _vm.updateNotifications()
                  },
                },
                model: {
                  value: _vm.notifications.incomingCompetition,
                  callback: function ($$v) {
                    _vm.$set(_vm.notifications, "incomingCompetition", $$v)
                  },
                  expression: "notifications.incomingCompetition",
                },
              }),
            ],
            1
          ),
          _c(
            "base-button",
            {
              staticClass: "minisite__button minisite__button__nptb",
              attrs: { btn6: "" },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.say.notifications_for_my_results)),
              ]),
              _c("base-input-switch", {
                attrs: { checked: _vm.notifications.myResults },
                nativeOn: {
                  change: function ($event) {
                    return _vm.updateNotifications()
                  },
                },
                model: {
                  value: _vm.notifications.myResults,
                  callback: function ($$v) {
                    _vm.$set(_vm.notifications, "myResults", $$v)
                  },
                  expression: "notifications.myResults",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "mab24" }, [
        _vm._v(
          "Do konfiguracji przychodzących powiadomień możesz zawsze wrócić w sekcji Więcej."
        ),
      ]),
      _c(
        "base-button",
        {
          staticClass: "btn-add",
          attrs: { btn1: "" },
          on: {
            click: function ($event) {
              return _vm.updateNotifications()
            },
          },
        },
        [_vm._v(" Zapisz ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }