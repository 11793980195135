<template>
    <div>
        <div v-if="this.$route.name === 'panel-competition-results-live' || this.$route.name === 'panel-competition-results-live-all'"></div> 
        <div class="container" v-else>
            <div v-if="!ready" class="row mat32">
                <div class="col-12">
                    <div class="admin-header__loading-state">
                        Trwa wczytywanie
                        <div class="mal15 lds-ellipsis">
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>                     
            <!-- Competition name. -->
            <div
                v-if="ready"
                class="row mat16"
                :class="{
                    'd-none': isZenMode,
                    'none-mobile': $route.name === 'panel-competition-stoper',
                }"
            >
                <h2>{{ competition.name }}</h2>
            </div>

            <div
                v-if="ready"
                class="row buttons-row"
                :class="{
                    'd-none': isZenMode,
                    'none-mobile': $route.name === 'panel-competition-stoper',
                }"
            >
                <!-- Minisite link. -->
                <div class="col-8 minisite-link none-mobile">
                    <span>
                        {{ link }}
                    </span>
                </div>

                <!-- Competition status. -->
                <div class="col-3 none-mobile">
                    <p v-if="competition.status < 4">
                        Rejestracja online
                    </p>
                    <p v-else>
                        Wyniki online
                    </p>
                </div>

                <!-- Topbar. -->
                <panel-competition-topbar class="col-lg-12 mat8 none-mobile" />
                <panel-competition-update-results :competition="competition" />
                <div class="d-flex w100 none-desktop">
                    <div
                        class="button-page"
                        @click="TOGGLE_NAV()"
                    >
                        <img
                            class="button-page__icon"
                            :src="currentPage.icon"
                            alt="Icon"
                        >
                        <p class="button-page__title">
                            {{ currentPage.text }}
                        </p>
                        <img
                            class="button-page__arrow"
                            src="@/assets/images/arrow-down-icon.svg"
                            alt="Arrow down"
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- View. -->
        <router-view
            v-if="competition"
            :competition="competition"
        />
    </div>
</template>

<script>
import PanelCompetitionTopbar from '@/components/PanelCompetitionTopbar';
import PanelCompetitionUpdateResults from '@/components/PanelCompetitionUpdateResults';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { READ_PANEL_COMPETITION } from '@/store/actions.type';
import { TOGGLE_NAV } from '@/store/mutations.type';

const home = require('@/assets/images/icons/home.svg');
const slider = require('@/assets/images/icons/sliders.svg');
const users = require('@/assets/images/icons/users.svg');
const list = require('@/assets/images/icons/list-number.svg');
const clock = require('@/assets/images/icons/clock.svg');

export default {
    components: {
        PanelCompetitionTopbar,
        PanelCompetitionUpdateResults,
    },
    data() {
        return {
            ready: false,
            routesIcons: {
                home,
                slider,
                users,
                list,
                clock,
            },
            // Available routes for mobiles.
            mobileRoutes: [
                {
                    name: 'panel-competition-dashboard',
                    text: 'Zawody',
                    icon: home,
                },
                {
                    name: 'panel-competition-setting-info',
                    text: 'Ustawienia zawodów',
                    icon: slider,
                },
                {
                    name: 'panel-competition-setting-info',
                    text: 'Informacje o zawodach',
                    icon: slider,
                },
                {
                    name: 'panel-competition-setting-classifications',
                    text: 'Klasyfikacje, opłaty, limity',
                    icon: slider,
                },
                {
                    name: 'panel-competition-setting-numbers',
                    text: 'Numeracja startowa',
                    icon: slider,
                },
                {
                    name: 'panel-competition-setting-regulation',
                    text: 'Regulamin zawodów',
                    icon: slider,
                },
                {
                    name: 'panel-competition-list-new',
                    text: 'Lista startowa',
                    icon: users,
                },
                {
                    name: 'panel-competition-result',
                    text: 'Wyniki',
                    icon: list,
                },
                {
                    name: 'panel-competition-stoper',
                    text: 'Stoper',
                    icon: clock,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['competitions', 'isZenMode']),
        competition() {
            return this.competitions[this.$route.params.id];
        },
        link() {
            const host = window.location.origin;
            const { href } = this.$router.resolve({
                name: 'minisite-landing',
                params: this.competition,
                alias: true,
            });

            return `${host}${href}`;
        },
        currentPage() {
            return (
                this.mobileRoutes.find(
                    (route) => route.name === this.$route.name,
                ) ?? {}
            );
        },
    },
    async created() {
        await this.READ_PANEL_COMPETITION(this.$route.params.id);
        this.ready = true;
    },
    methods: {
        ...mapActions([READ_PANEL_COMPETITION]),
        ...mapMutations([TOGGLE_NAV]),
    },
};
</script>

<style scoped>
.d-none {
    display: none;
}
.minisite-link {
    color: var(--color-silver);
    font-size: 12px;
    line-height: 16px;
}
.button-page {
    margin-top: 16px;
    display: flex;
    width: 100%;
    background-color: var(--color-slate);
    border: 1px solid var(--color-bluish-grey);
    border-radius: 4px;
    height: 40px;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
}
.button-page__title {
    margin-left: 12px;
}
.button-page__arrow {
    border-left: 1px solid var(--color-bluish-grey);
    margin-left: auto;
    background-color: var(--color-charcoal-grey);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 32px;
    padding: 4px;
    height: 32px;
}
.admin-header__loading-state {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
