var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "btn btn-download",
      class: { disabled: _vm.disabled },
      on: {
        click: function ($event) {
          _vm.disabled ? "" : _vm.download()
        },
      },
    },
    [
      _c("i", { staticClass: "btn-download__icon fas fa-file-download" }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }