var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row reset-password" }, [
      _c("div", { staticClass: "col-12 col-lg-3" }, [
        _c("h1", [_vm._v(_vm._s(_vm.say.reset_your_password))]),
      ]),
      _c("div", { staticClass: "col-12 col-lg-9" }, [
        _c("div", { staticClass: "row bg--gray reset-password__email-input" }, [
          _c("div", { staticClass: "col-12 col-lg-5 reset-password__hint" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.say.enter_email_address_from_register)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-lg-6" },
            [
              _c("base-input-text", {
                attrs: {
                  label: _vm.say.email_address,
                  error: _vm.errors.email,
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(_vm.errors, "email", $event)
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row reset-password__box-buttons" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-4" },
            [
              _c(
                "base-button",
                {
                  staticClass: "box-buttons__reset-button",
                  attrs: { btn1: "" },
                  on: { click: _vm.resetPassword },
                },
                [_vm._v(" " + _vm._s(_vm.say.reset_your_password) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-lg-2 box-buttons__cancel" },
            [
              _c(
                "base-button",
                {
                  attrs: { btn4: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.say.cancel) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }