import api from '@/api';
import {
    DELETE_CHECKPOINT,
    DELETE_GATE,
    DELETE_SESSION,
    DELETE_USER,
    DELETE_USER_CHILD,
    DELETE_LOCATION,
    DELETE_TRACKPOINT,
    DELETE_COMPETITION,
    DELETE_ORGANIZER,
    DELETE_OPERATOR,
    DELETE_PANEL_COMPETITION_PLAYERS_INPUTS,
    DELETE_PLAYER,
} from '@/store/actions.type';
import { CLEAR_ENTITIES } from '@/store/mutations.type';

export default {
    async [DELETE_USER_CHILD]({ commit, getters }, id) {
        await api.delete(`/user/children/${id}`);

        // Delete family association.
        const familyAssociation = Object.values(getters.familyAssociations()).find(
            (fa) => fa.childUser === id && fa.parentUser === getters.user,
        );

        commit(CLEAR_ENTITIES, { users: [id] });
        commit(CLEAR_ENTITIES, { familyAssociations: [familyAssociation.id] });
    },
    async [DELETE_USER]({ commit, getters }) {
        const id = getters.userId;

        await api.delete('/user');

        commit(CLEAR_ENTITIES, { users: [id] });
    },
    async [DELETE_SESSION]() {
        await api.delete('/auth');
    },
    async [DELETE_CHECKPOINT]({ commit }, id) {
        await api.delete(`/checkpoints/${id}`);

        commit(CLEAR_ENTITIES, { checkpoints: [id] });
    },
    async [DELETE_GATE]({ commit }, id) {
        await api.delete(`/gates/${id}`);

        commit(CLEAR_ENTITIES, { gates: [id] });
    },
    async [DELETE_LOCATION]({ commit }, id) {
        await api.delete(`/locations/${id}`);

        commit(CLEAR_ENTITIES, { locations: [id] });
    },
    async [DELETE_TRACKPOINT]({ commit }, id) {
        await api.delete(`/trackpoints/${id}`);

        commit(CLEAR_ENTITIES, { trackpoints: [id] });
    },
    async [DELETE_COMPETITION]({ commit }, id) {
        await api.delete(`/panel/competitions/${id}`);

        commit(CLEAR_ENTITIES, { competitions: [id] });
    },
    async [DELETE_ORGANIZER]({ commit }, id) {
        await api.delete(`/panel/organizers/${id}`);

        commit(CLEAR_ENTITIES, { organizers: [id] });
    },
    async [DELETE_OPERATOR]({ commit }, id) {
        await api.delete(`/panel/operators/${id}`);

        commit(CLEAR_ENTITIES, { operators: [id] });
    },
    async [DELETE_PANEL_COMPETITION_PLAYERS_INPUTS](context, id) {
        const data = await api.delete(`/panel/inputs/${id}`);

        return data
    },
    async [DELETE_PLAYER](context, id) {
        const data = await api.delete(`/panel/players/${id}`);

        return data
    },
};
