var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.homePromoteClasses }, [
    _c("div", { class: _vm.promoteBoxClasses }, [
      _c(
        "div",
        {
          staticClass: "home-promote__btn home-promote__btn--left",
          class: {
            "home-promote__btn--high": _vm.$route.name !== "main-landing",
          },
          on: {
            click: function ($event) {
              return _vm.prev()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-chevron-left" })]
      ),
      _c(
        "div",
        {
          staticClass: "row home-promote__carousel",
          attrs: { id: "carousel" },
        },
        _vm._l(_vm.ids, function (id) {
          return _c("main-carousel-item", {
            key: id,
            attrs: { id: id },
            on: {
              loaded: function ($event) {
                return _vm.delay(_vm.activeClassWatcher)
              },
            },
          })
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "home-promote__btn home-promote__btn--right",
          class: {
            "home-promote__btn--high": _vm.$route.name !== "main-landing",
          },
          on: {
            click: function ($event) {
              return _vm.next()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-chevron-right" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }