// import { IS_PRODUCTION_ENV } from '@/config';
import modules from '@/store/modules';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    // strict: !IS_PRODUCTION_ENV,
    modules,
});
