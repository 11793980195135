var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirmation row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("h1", { staticClass: "confirmation__title" }, [
        _vm._v(" Dziękujemy za wysłanie danych. "),
      ]),
      _c("div", { staticClass: "confirmation__hint" }, [
        _vm._v(" Twoje pliki zostały wysłane. "),
      ]),
      _c("div", { staticClass: "row confirmation__close" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "base-button",
              {
                staticClass: "confirmation__close-btn",
                attrs: { btn2: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "minisite-landing",
                      params: _vm.competition,
                      alias: true,
                    })
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.say.close) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }