import device from '@/api/schema/device';
import { schema } from 'normalizr';

export default new schema.Entity('gates', {
    device,
}, {
    processStrategy: (gate) => ({
        // Meta.
        _type: 'gate',
        // Fields.
        ...gate,
    }),
});
