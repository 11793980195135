<template>
    <div>
        <!-- Header. -->
        <minisite-header />

        <!-- View. -->
        <router-view />
    </div>
</template>

<script>
import MinisiteHeader from '@/components/MinisiteHeader';

export default {
    components: {
        MinisiteHeader,
    },
};
</script>
