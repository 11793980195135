<template>
    <!-- Search. -->
    <div :class="searchClasses">
        <h2
            class="search-competition__info"
            :class="{
                'col-12 mab16i': [
                    'main-site-operator',
                    'main-site-organizer',
                ].includes($route.name),
            }"
        >
            {{ say.find_competitions_for_you }}
        </h2>

        <!-- Search competition by name autocomplete input. -->
        <div
            class="col-12 search-competition__name-input"
            :class="
                ['main-site-operator', 'main-site-organizer'].includes(
                    $route.name,
                )
                    ? 'col-lg-4 max-w100i'
                    : 'col-lg-3'
            "
        >
            <base-input-autocomplete
                v-model="name"
                action="AUTOCOMPLETE_COMPETITION_NAME"
                :label="say.competition_name"
                @select="$event.value ? setCompetition($event) : setFilter()"
            />
        </div>

        <h4 class="d-lg search-competition__hint">
            {{ say.or | asLowerCase }}
        </h4>

        <!-- Search competition by location input. -->
        <div
            class="col-12 search-competition__location-input"
            :class="
                ['main-site-operator', 'main-site-organizer'].includes(
                    $route.name,
                )
                    ? 'col-lg-4'
                    : 'col-lg-3'
            "
        >
            <base-input-autocomplete
                v-model="locationName"
                action="AUTOCOMPLETE_LOCATION"
                :label="say.location"
                :clear-search-on-select="true"
                :clearable="true"
                only-poland
                @input="locationGeometry = null"
                @select="selectLocationHandler($event)"
            />
        </div>

        <!-- Search button. -->
        <div class="col-12 col-lg-2 search-competition__button">
            <base-button
                btn1
                class="pointer"
                @click="setFilter()"
            >
                {{ say.search }}
            </base-button>
        </div>
    </div>
</template>

<script>
import { SET_FILTER } from '@/store/mutations.type';
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            name: '',
            locationName: '',
            locationGeometry: {},
        };
    },
    computed: {
        ...mapGetters(['say', 'filter']),
        searchClasses() {
            return {
                row: true,
                'search-competition': true,
                patb32: this.$route.name === 'main-list' || this.$route.name === 'main-list-map',
                'search-competition--main-site': [
                    'main-site-operator',
                    'main-site-organizer',
                ].includes(this.$route.name),
            };
        },
    },
    created() {
        this.name = this.filter.name;
        this.locationName = this.filter.location.name;
        this.locationGeometry = this.filter.location.geometry;
    },
    methods: {
        ...mapMutations([SET_FILTER]),
        setFilter() {
            const entrySearch = {
                event: 'klikniecie',
                event_category: 'przyciskszukaj',
                event_label: 'stronaglowna',
            };
            this.$gtm.push(entrySearch);
            this.SET_FILTER({
                filter: {
                    ...this.filter,
                    name: this.name ? this.name : undefined,
                    location: {
                        geometry: this.locationGeometry,
                        name: this.locationName?.split(',')[0],
                    },
                    page: 1,
                },
                route: this.$route,
            });
        },
        setCompetition(suggestion) {
            this.$router.push({
                name: 'minisite-landing',
                params: {
                    name: suggestion.text,
                    id: suggestion.value,
                },
                alias: true,
            });
        },
        selectLocationHandler(event) {
            if (event === null) {
                this.locationName = '';
                this.locationGeometry = null;
                this.setFilter();
            } else {
                setTimeout(() => {
                    this.locationName = event.city;
                    this.locationGeometry = {
                        lng: event.cityLng,
                        lat: event.cityLat,
                    };
                    this.setFilter();
                }, 150);
            }
        },
    },
};
</script>
