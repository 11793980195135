var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row patb48 user-edit__box-buttons" }, [
    _vm.$router.matched("user-edit", _vm.$route)
      ? _c(
          "div",
          { staticClass: "col-lg-3 col-md-4 col-12" },
          [
            _c(
              "base-button",
              {
                staticClass: "user-edit__button user-edit__button-delete",
                attrs: { btn6: "" },
                on: {
                  click: function ($event) {
                    return _vm.erase()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.say.delete_your_account) + " ")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "col-lg-3 col-md-4 col-12" },
          [
            _c(
              "base-button",
              {
                staticClass: "user-edit__button user-edit__button-cancel",
                attrs: { btn3: "" },
                on: {
                  click: function ($event) {
                    return _vm.cancel()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.say.cancel) + " ")]
            ),
          ],
          1
        ),
    _c(
      "div",
      { staticClass: "col-lg-3 col-md-4 col-12" },
      [
        _c(
          "base-button",
          {
            staticClass: "user-edit__button-save",
            attrs: { btn1: "" },
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.say.save) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }