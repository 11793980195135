<template>
    <div >
        <label class="switch">
          <input type="checkbox" v-model="innerValue" :checked="innerValue">
          <span class="slider"></span>
        </label>

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    props: {
        value: {
            type: null,
            default: null,
        },          
    },
    data () {
        return {
            innerValue: null,
        }
    },
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        },
    },
    created() {
        if (this.value || this.value === false) {
            this.innerValue = this.value
        }
    },  
    computed: {
        ...mapGetters(['say']),
    },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E62E53;
  transition: .4s;
  border-radius: 44px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.85246 6L2.54827 8.35656C2.26456 8.64672 2.26125 9.12077 2.5451 9.41107L3.39806 10.2834C3.68139 10.5732 4.14354 10.5723 4.42914 10.2802L6.73334 7.92362L9.03753 10.2802C9.32124 10.5703 9.78476 10.5737 10.0686 10.2834L10.9216 9.41107C11.2049 9.12131 11.204 8.64865 10.9184 8.35656L8.61421 6L10.9184 3.64344C11.2021 3.35328 11.2054 2.87923 10.9216 2.58893L10.0686 1.71658C9.78528 1.42682 9.32313 1.42773 9.03753 1.71982L6.73334 4.07638L4.42914 1.71982C4.14544 1.42966 3.68191 1.42628 3.39806 1.71658L2.5451 2.58893C2.26178 2.87869 2.26267 3.35135 2.54827 3.64344L4.85246 6Z' fill='%23E62E53'/%3E%3C/svg%3E%0A");
  background-position: 45% center;
  background-repeat: no-repeat;
  transition: .4s;
  border-radius: 50%;
}


input:checked + .slider {
  background-color: #50AE55;
}

input:focus + .slider {
  box-shadow: 0 0 1px #50AE55;
}

input:checked + .slider:before {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.647322 3.45962C0.26121 3.8545 0.261209 4.49474 0.647321 4.88963L3.23536 7.53649L3.46915 7.77558L3.64572 7.95617C4.03183 8.35106 4.65784 8.35106 5.04396 7.95617L9.97581 2.91223C10.3619 2.51735 10.3619 1.87711 9.97581 1.48222L9.56545 1.06253C9.17934 0.667643 8.55332 0.667645 8.16721 1.06253L4.34484 4.97178L2.45592 3.03993C2.06981 2.64504 1.44379 2.64504 1.05768 3.03993L0.647322 3.45962Z' fill='%2350AE55'/%3E%3C/svg%3E%0A");
  background-position: center;
  transform: translateX(21px);
}
</style>
