var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VTooltip",
    {
      staticClass: "ds-panel",
      attrs: {
        triggers: ["click", "hover"],
        distance: 0,
        skidding: -8,
        placement: "top-end",
      },
      scopedSlots: _vm._u([
        {
          key: "popper",
          fn: function () {
            return [
              _vm.data.html
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.data.content) },
                  })
                : _c("span", [_vm._v(_vm._s(_vm.data.content))]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }