<template>
    <!-- // Draw gpx path. -->
    <div />
</template>

<script>
import L from 'leaflet-gpx';
import { mapGetters } from 'vuex';

export default {
    props: {
        map: {
            type: Object,
            required: true,
        },
        classifications: {
            type: Array,
            required: true,
        },
        selectedClassificationId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            tracks: {},
            highlightedTrackColor: '#37474f',
            disabledTrackColor: '#78909c',
        };
    },
    computed: {
        ...mapGetters(['gpxFiles']),
        classificationsIds() {
            const classificationsIds = [];
            for (const classification of this.classifications) {
                classificationsIds.push(classification.id);
            }
            return classificationsIds;
        },
    },
    watch: {
        selectedClassificationId() {
            this.setDisabledTracks();
            for (const trackId in this.tracks) {
                if (this.tracks[trackId].classification === this.selectedClassificationId) {
                    this.highlightTrack(this.tracks[trackId]);
                    this.centerOnTrack(this.tracks[trackId]);
                }
            }
        },
        gpxFiles: {
            deep: true,
            handler() {
                this.clearGpxLayers();
                this.readGpxFiles();
            },
        },
    },
    mounted() {
        this.readGpxFiles();
    },
    methods: {
        readGpxFiles() {
            this.tracks = {};
            for (const id of this.classificationsIds) {
                this.tracks[id] = new L.GPX(this.gpxFiles[id].url, {
                    async: true,
                    gpx_options: {
                        parseElements: ['track', 'route'],
                    },
                    polyline_options: {
                        color: this.disabledTrackColor,
                        opacity: 1,
                        weight: 5,
                        lineCap: 'round',
                    },
                    marker_options: {
                        startIconUrl: null,
                        endIconUrl: null,
                        shadowUrl: null,
                    },
                })
                    .on('loaded', (e) => {
                        e.target.classification = id;
                        e.target.bringToBack();
                        if (this.selectedClassificationId === id) {
                            this.centerOnTrack(e.target);
                            this.setDisabledTracks();
                            this.highlightTrack(e.target);
                        }
                    })
                    .on('click', (e) => this.$emit('trackClick', e.target))
                    .on('mouseover', (e) => {
                        this.setDisabledTracks();
                        this.highlightTrack(e.target);
                    })
                    .on('mouseout', () => {
                        const selectedTrack = this.findSelectedTrack();
                        this.setDisabledTracks();
                        this.highlightTrack(selectedTrack);
                    })
                    .addTo(this.map);
            }
        },
        centerOnTrack(track) {
            this.map.fitBounds(track.getBounds());
        },
        setDisabledTracks() {
            for (const trackId in this.tracks) {
                if (Object.prototype.hasOwnProperty.call(this.tracks, trackId)) {
                    this.tracks[trackId].bringToBack();
                    this.tracks[trackId].setStyle({
                        color: this.disabledTrackColor,
                    });
                }
            }
        },
        highlightTrack(track) {
            if (track !== undefined) {
                track.bringToFront();
                track.setStyle({ color: this.highlightedTrackColor });
            }
        },
        findSelectedTrack() {
            for (const trackId in this.tracks) {
                if (Number.parseInt(trackId, 10) === this.selectedClassificationId) {
                    return this.tracks[trackId];
                }
            }
            return undefined;
        },
        clearGpxLayers() {
            this.map.eachLayer((layer) => {
                // eslint-disable-next-line no-underscore-dangle
                if (layer._gpx) {
                    this.map.removeLayer(layer);
                }
            });
        },
    },
};
</script>
