<template>
    <div
        ref="containerStoperList"
        class="container-list"
        :class="
            isLargeScreen ? 'container-list--large' : 'container-list--small'
        "
    >
        <!-- Number input. -->
        <base-input-multiple
            :value="numbers"
            :input="number"
            class="none-desktop container-list__input"
            :read-only="isMobileView"
            @change="numbers = $event"
            @input="number = $event"
        />

        <!-- List. -->
        <base-table
            ref="table"
            :headers="headers"
            :items="items"
            class="list"
        >
            <!-- Header. -->
            <template v-slot:header="{ sort }">
                <tr class="list__row-header">
                    <th
                        v-for="(header, column) in headers"
                        :key="column"
                        :class="classesHeader(column)"
                        @click="sort(column)"
                    >
                        {{ header.text }}
                    </th>
                </tr>
            </template>

            <!-- Body. -->
            <template v-slot:body="{ displayedItems }">
                <tr
                    v-for="(item, row) in displayedItems"
                    :key="row"
                    class="list__row-body"
                    :class="{ disabled: item.isInvalidated }"
                >
                    <td
                        v-for="(header, column) in headers"
                        :key="column"
                        :class="classesCell(column)"
                    >
                        <span
                            v-if="header.value === 'number'"
                            class="list__data-number--border"
                            @click="setMeasureToEdit(item)"
                        />
                        <span
                            v-if="
                                header.value === 'number' &&
                                    (item.name === 'Brak zawodnika' ||
                                        isDuplicate(item.number, displayedItems))
                            "
                            class="c-negative list__data-number--warning"
                        >!</span>
                        <i
                            v-if="header.value === 'position' && row > 0"
                            class="list__btn-position fas fa-plus"
                            @click="swapStoperMeasure(row, row - 1)"
                        />
                        <template v-if="header.value === 'number'">
                            <template
                                v-if="
                                    editingMeasure &&
                                        ((editingMeasure.id &&
                                            editingMeasure.id == item.id) ||
                                            editingMeasure.deviceTime.isSame(
                                                item.deviceTime
                                            ))
                                "
                            >
                                {{ measureNumber }}
                            </template>
                            <input
                                v-show="
                                    !editingMeasure ||
                                        editingMeasure.id !== item.id ||
                                        !editingMeasure.deviceTime.isSame(
                                            item.deviceTime
                                        )
                                "
                                :ref="
                                    item.id
                                        ? item.id
                                        : item.deviceTime.valueOf()
                                "
                                v-model="item[header.value]"
                                class="list__data-input"
                                type="text"
                                :class="[{ cred: item[header.value] === '?' }]"
                                :readonly="isMobileView"
                                @change="changeNumberMeasure(item)"
                            >
                        </template>
                        <template
                            v-else-if="item[header.value] === 'Brak zawodnika'"
                        >
                            <span class="c-negative">{{
                                item[header.value]
                            }}</span>
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                        <i
                            v-if="header.value === 'time'"
                            class="list__btn-edit--mobile"
                            :class="[
                                item.isInvalidated
                                    ? 'fas fa-redo'
                                    : 'fas fa-plus rotate45',
                            ]"
                            @click="toggleInvalidatedStoperMeasure(row)"
                        />
                        <i
                            v-if="header.value === 'clubname'"
                            class="list__btn-edit--desktop"
                            :class="[
                                item.isInvalidated
                                    ? 'fas fa-redo'
                                    : 'fas fa-plus rotate45',
                            ]"
                            @click="toggleInvalidatedStoperMeasure(row)"
                        />
                    </td>
                </tr>
            </template>

            <!-- Empty state. -->
            <template v-slot:no-results>
                <!-- Hints on mobile stoper. -->
                <div class="d-sm">
                    <div class="stoper__hint">
                        <h1 class="stoper__hint-number">
                            1.
                        </h1>
                        <p>Wpisz numer startowy uczestnika i naciśnij stop.</p>
                    </div>
                    <div class="stoper__hint">
                        <h1 class="stoper__hint-number">
                            2.
                        </h1>
                        <p>
                            Możesz dodawać numery do kolejki, używając strzałki.
                        </p>
                    </div>
                    <div class="stoper__hint">
                        <h1 class="stoper__hint-number">
                            3.
                        </h1>
                        <p>
                            Jeśli w kolejce znajdują się numery, przyciskaj stop
                            aby zapisać im czasy.
                        </p>
                    </div>
                    <!-- Brak pomiarów -->
                </div>
                <p class="d-lg">
                    Brak pomiarów
                </p>
            </template>
        </base-table>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { SWAP_STOPER_MEASURES, UPDATE_MEASURE } from '@/store/actions.type';
import {
    SET_STOPER_NUMBER,
    SET_STOPER_NUMBERS,
    SET_EDITING_MEASURE,
    SET_IS_EDITING_MEASURE_NUMBER,
    SET_ENTITIES,
    CLEAR_OFFLINE_MEASURE,
} from '@/store/mutations.type';
import { normalize } from 'normalizr';
import schema from '@/api/schema';
import _ from 'lodash';
import events from '@/events';

export default {
    props: {
        isLargeScreen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            headers: [
                { text: '', value: 'position' },
                { text: 'Nr', value: 'number' },
                { text: 'PK', value: 'time' },
                { text: 'Imię i nazwisko', value: 'name' },
                { text: 'Płeć', value: 'gender' },
                { text: 'Klasyfikacja', value: 'classification' },
                { text: 'Miasto', value: 'city' },
                { text: 'Klub', value: 'clubname' },
            ],
            isMobileView: false,
        };
    },
    computed: {
        ...mapGetters([
            'stoperPlayers',
            'stoperMeasures',
            'measures',
            'stoperDurationMeasure',
            'stoperNumbers',
            'stoperNumber',
            'classificationsBy',
            'editingMeasure',
            'measureNumber',
            'stoperOfflineMeasures',
        ]),
        numbers: {
            get() {
                return this.stoperNumbers;
            },
            set(numbers) {
                this.SET_STOPER_NUMBERS(numbers);
            },
        },
        number: {
            get() {
                return this.stoperNumber;
            },
            set(number) {
                this.SET_STOPER_NUMBER(number);
            },
        },
        items() {
            const playersByTag = {};

            for (const player of this.stoperPlayers) {
                playersByTag[player.playerNumber] = player;
            }

            const stoperMeasuresWithOffline = this.stoperMeasures.concat(
                this.stoperOfflineMeasures,
            );
            let i = 0;
            const sortedMeasures = Object.values(
                stoperMeasuresWithOffline,
            ).sort((a, b) => a.deviceTime.diff(b.deviceTime));
            const items = [];

            for (const measure of sortedMeasures) {
                i += 1;

                const player = playersByTag[measure.tag];
                const duration = player
                    ? this.stoperDurationMeasure(measure, player)
                    : null;

                items.push({
                    id: measure.id, // key of online measures.
                    position: i, // @todo
                    number: measure.tag,
                    time:
                        duration && duration.isValid()
                            ? moment
                                .utc(duration.as('ms'))
                                .format('HH:mm:ss.SS')
                            : '',
                    name: player
                        ? `${player.firstname} ${player.lastname}`
                        : 'Brak zawodnika',
                    gender: player?.gender,
                    classification: this.classificationsBy(player)[0]?.namePl,
                    city: player?.city,
                    clubname: player?.clubname,
                    isInvalidated: measure.invalidated,
                    deviceTime: measure.deviceTime, // key of offline measures.
                });
            }

            return items;
        },
    },
    created() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', ({ data }) => {
                // messages from service worker.
                if (data.name === 'measures') {
                    const normalized = normalize(data.data, [schema.measure]);
                    for (const onlineMeasure of Object.values(
                        normalized.entities.measures,
                    )) {
                        const indexOfMeasure = this.stoperOfflineMeasures.findIndex(
                            (offlineMeasure) => onlineMeasure.deviceTime.isSame(
                                offlineMeasure.deviceTime,
                            ),
                        );
                        if (indexOfMeasure >= 0) {
                            const offlineMeasure = this.stoperOfflineMeasures[
                                indexOfMeasure
                            ];
                            // When offlineMeasure was editing before send to api. Need update normalized result to avoid refreshing site.
                            if (offlineMeasure.tag !== onlineMeasure.tag) {
                                onlineMeasure.tag = offlineMeasure.tag;
                            }
                            onlineMeasure.invalidated = offlineMeasure.invalidated;
                            this.CLEAR_OFFLINE_MEASURE(indexOfMeasure);
                        }
                    }
                    this.SET_ENTITIES(normalized.entities);
                }
            });
        }
    },
    mounted() {
        if (document.body.clientWidth < 992) {
            this.isMobileView = true;
        }
        this.setHeightOfStoperList();
        events.$on('resize-stoper', () => {
            setTimeout(() => this.setHeightOfStoperList(), 200);
        });
    },
    beforeUpdate() {
        this.setHeightOfStoperList();
    },
    updated() {
        this.scrollToBottomList();
    },
    methods: {
        ...mapActions([SWAP_STOPER_MEASURES, UPDATE_MEASURE]),
        ...mapMutations([
            SET_STOPER_NUMBER,
            SET_STOPER_NUMBERS,
            SET_EDITING_MEASURE,
            SET_IS_EDITING_MEASURE_NUMBER,
            CLEAR_OFFLINE_MEASURE,
            SET_ENTITIES,
        ]),
        isDuplicate(number, displayedItems) {
            return (
                displayedItems.filter((el) => el.number === number).length >= 2
            );
        },
        classesHeader(column) {
            return {
                'list__head none-mobile': true,
                'list__data-w9': column === 0,
                'list__data-w9 ': column === 1,
                ' list__data-w10': column === 2,
                'list__data-w20': column === 3,
                'list__data-w5': column === 4,
                'list__data-w11': column === 5,
                'list__data-w10': column === 6,
                'list__data-w26': column === 7,
            };
        },
        classesCell(column) {
            return {
                list__data: true,
                'none-mobile': column > 2,
                'list__data-position list__data-w9': column === 0,
                'list__data-w9 list__data-number': column === 1,
                'list__data-time list__data-w10': column === 2,
                'list__data-w20': column === 3,
                'list__data-w5': column === 4,
                'list__data-w11': column === 5,
                'list__data-w10': column === 6,
                'list__data-w26 position-relative': column === 7,
            };
        },
        async toggleInvalidatedStoperMeasure(i) {
            let measure;
            // measure online (with id)
            if (this.items[i].id) {
                measure = this.measures[this.items[i].id];
            } else {
                // measure offline (without id)
                measure = this.stoperOfflineMeasures.find((offlineMeasure) => offlineMeasure.deviceTime.isSame(this.items[i].deviceTime));
            }
            measure.invalidated = !measure.invalidated;

            await this.UPDATE_MEASURE(measure);
        },
        async swapStoperMeasure(i, j) {
            const measureA = this.items[i].id
                ? this.measures[this.items[i].id]
                : this.stoperOfflineMeasures.find((measure) => measure.deviceTime.isSame(this.items[i].deviceTime));
            const measureB = this.items[j].id
                ? this.measures[this.items[j].id]
                : this.stoperOfflineMeasures.find((measure) => measure.deviceTime.isSame(this.items[j].deviceTime));

            await this.SWAP_STOPER_MEASURES([measureA, measureB]);
        },
        async changeNumberMeasure(item) {
            let measure;
            if (item.id) {
                // Online measure.
                measure = this.measures[item.id];
            } else {
                // Offline measure.
                measure = _.find(this.stoperOfflineMeasures, (m) => m.deviceTime.isSame(item.deviceTime));
            }
            measure.tag = item.number;
            await this.UPDATE_MEASURE(measure);
            document.getElementById('multipleInput').focus();
        },

        setHeightOfStoperList() {
            if (this.isMobileView) return;
            const windowHeight = window.innerHeight;
            const stoperHeight = document.getElementById('layoutBack')
                .offsetHeight;
            const stoperListHeight = `${windowHeight - stoperHeight}px`;
            this.$refs.containerStoperList.style.height = stoperListHeight;
        },
        setMeasureToEdit(item) {
            if (this.isMobileView) {
                let measure;
                if (item.id) {
                    // Online measure.
                    measure = this.measures[item.id];
                } else {
                    // Offline measure.
                    measure = _.find(
                        this.stoperOfflineMeasures,
                        (offlineMeasure) => offlineMeasure.deviceTime.isSame(item.deviceTime),
                    );
                }
                this.SET_EDITING_MEASURE(measure);
                this.SET_IS_EDITING_MEASURE_NUMBER(true);
            } else if (item.id) {
                this.$refs[item.id][0].focus();
            } else {
                this.$refs[item.deviceTime.valueOf()][0].focus();
            }
        },
        scrollToBottomList() {
            this.$refs.table.$el.scrollTo({
                top: this.$refs.table.$el.scrollHeight,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style scoped>
.stoper__hint {
    display: flex;
    color: var(--color-slate);
    line-height: 1.43;
    align-items: center;
    margin-top: 24px;
    margin-right: 15px;
}
.stoper__hint-number {
    margin-right: 20px;
    font-size: 32px;
    margin-left: 20px;
}
.list__data-w9 {
    width: 9%;
}
.list__data-w10 {
    width: 10%;
}
.list__data-w20 {
    width: 20%;
}
.list__data-w5 {
    width: 5%;
}
.list__data-w11 {
    width: 11%;
}
.list__data-w26 {
    width: 26%;
}
.container-list {
    position: absolute;
    left: 0;
    right: 0;
    color: var(--color-slate);
    z-index: 100;
}
.list__row-header {
    background-color: var(--color-charcoal-grey);
    color: var(--color-silver);
    display: inline-table;
    width: 100%;
}
.list__row-body {
    background-color: white;
    display: inline-table;
    width: 100%;
}
.list__row-body:nth-of-type(even) {
    background-color: var(--color-light-grey);
}
.list {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: var(--color-white);
}
.list >>> thead {
    position: sticky;
    top: 0;
    background-color: var(--color-charcoal-grey);
    z-index: 9999;
}
.list__data {
    padding: 0 15px;
    height: 40px;
}
.list__head {
    padding: 0 15px 10px 15px;
}

.list__data-position {
    position: relative;
    padding-left: 48px;
    padding-right: 0;
}
.list__data-input {
    border: none;
    cursor: pointer;
    background: transparent;
    font-weight: normal;
    width: 100%;
    padding: 0;
}
.list__data-number--warning {
    position: absolute;
    left: 60px;
    font-size: 18px;
    font-weight: 800;
}
.cred {
    color: var(--color-red-orange);
}
.list__data-time {
    padding-right: 8px;
}
.list__btn-position {
    z-index: 99;
    position: absolute;
    left: 0;
    top: -1px;
}
.list__btn-position::before {
    position: absolute;
    content: "\f338";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    left: 20px;
    top: -7px;
    font-size: 14px;
    color: var(--color-bluish-grey);
    z-index: 5;
    cursor: pointer;
    opacity: 1 !important;
}
.list__btn-position::after {
    position: absolute;
    left: 8px;
    top: -16px;
    content: "";
    height: 30px;
    width: 30px;
    display: inline-block;
    border-radius: 16px;
    border: solid 1px #e0e0e0;
    background-color: white;
    cursor: pointer;
}
.list__btn-edit--mobile {
    display: none;
}
.list__btn-edit--desktop {
    position: absolute;
    top: 14px;
    right: 30px;
    cursor: pointer;
    color: var(--color-bluish-grey);
}
.rotate45 {
    transform: rotate(45deg);
}
.list__data-number {
    position: relative;
}
.list__data-number--border {
    display: block;
    position: absolute;
    width: 44px;
    height: 20px;
    border: 2px inset #eee;
    top: 8px;
    left: 8px;
    border-radius: 8px;
    cursor: pointer;
}
@media (max-width: 992px) {
    .list__data-input {
        cursor: default;
    }
    .list__row-header {
        display: none;
    }
    .list__data-time {
        text-align: right;
        width: 50%;
    }
    .list__btn-edit--mobile {
        margin-left: 16px;
        cursor: pointer;
        display: inline-block;
        color: var(--color-bluish-grey);
    }
    .list__btn-edit--desktop {
        display: none;
    }
    .container-list {
        position: relative;
        margin: 16px 0;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
        background: white;
        display: flex;
        flex-direction: column-reverse;
        overflow: scroll;
        width: 100%;
    }
    .container-list--small {
        height: 216px;
        max-height: 216px;
    }
    .container-list--large {
        height: 340px;
        max-height: 320px;
    }
    .container-list__input {
        min-height: 56px;
    }
    .list__row-body {
        height: 40px;
        max-height: 48px;
        display: inline-table;
        width: 100%;
    }
    .list__data-position {
        width: 25%;
    }
    .list__data-number {
        width: 25%;
    }
}
</style>
