<template>
    <header>
        <div class="header">
            <div class="container">
                <div class="row align-items-center flex-nowrap" style="min-height: 48px;">
                    <!-- Logo dostartu. -->
                    <router-link :to="navigateToList">
                        <img
                            class="header__logo-ds"
                            src="@/assets/images/logo-dostartu-white.svg"
                            alt="Logo dostartu"
                        >
                    </router-link>

                    <!-- Navigation. -->
                    <div v-if="!isSettingStoperFullscreen" class="d-flex">
                        <template v-if="ready">
                            <!-- Back landing. -->
                            <base-button
                                v-if="isAdmin"
                                btn12
                                class="header__button header__button--list-users"
                                @click="$router.push({ name: 'panel-users-list', alias: true })"
                            >
                                <span class="header__button-name">
                                    Lista użytkowników
                                </span>
                                <img
                                    class="header__button-icon"
                                    src="@/assets/images/icons/shopping-trolley.svg"
                                >
                            </base-button>
                            <!-- Back landing. -->
                            <base-button
                                v-if="isAdmin"
                                btn12
                                class="header__button header__button--list-provision"
                                @click="$router.push({ name: 'panel-provision-admin', alias: true })"
                            >
                                    <span  class="header__button-name">
                                        Lista płatności
                                    </span>
                                    <img
                                        class="header__button-icon"
                                        src="@/assets/images/icons/provision-admin.svg"
                                    >
                            </base-button>
                            <base-button
                                v-if="isOperator || isOrganizer"
                                btn12
                                class="header__button header__button--list-provision"
                                @click="$router.push({ name: 'panel-provision', alias: true })"
                            >
                                <span class="header__button-name">
                                    Moje opłaty
                                </span>
                                <img
                                    class="header__button-icon"
                                    src="@/assets/images/icons/provision-operator.svg"
                                >
                            </base-button>
                            <base-button
                                btn12
                                class="header__button header__button--list-competitions"
                                @click="$router.push(navigateToList)"
                            >
                                <span class="header__button-name">
                                    Lista zawodów
                                </span>
                                <img
                                    class="header__button-icon"
                                    src="@/assets/images/hamburger-icon-white.svg"
                                >
                            </base-button>

                            <!-- Back setting. -->
                            <base-button
                                btn12
                                class="header__button header__button--settings"
                                @click="navigateToSettings()"
                            >
                                <span
                                    class="header__button-name"
                                >Ustawienia</span>
                                <img
                                    class="header__button-icon"
                                    src="@/assets/images/settings-icon-white.svg"
                                >
                            </base-button>
                        </template>

                        <!-- User actions. -->
                        <base-button
                            btn12
                            class="header__button"
                            @click="showUserButtons = !showUserButtons"
                        >
                            <span class="header__button-name"> Konto </span>
                            <img
                                class="header__button-icon"
                                src="@/assets/images/user-circle-white.svg"
                                alt=""
                            >
                            <div
                                v-if="showUserButtons"
                                class="header__button-actions"
                            >
                                <user-dropdown-buttons is-panel />
                            </div>
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
        <panel-mini-header
            v-if="isModerator && organizerId && !isSettingStoperFullscreen"
            :organizer-id="organizerId"
        />
    </header>
</template>

<script>
import UserDropdownButtons from '@/components/UserDropdownButtons';
import { mapActions, mapGetters } from 'vuex';
import { READ_OPERATOR, READ_ORGANIZER } from '@/store/actions.type';
import PanelMiniHeader from '@/components/PanelMiniHeader';

export default {
    components: {
        UserDropdownButtons,
        PanelMiniHeader,
    },
    data() {
        return {
            showUserButtons: false,
            ready: false,
        };
    },
    computed: {
        ...mapGetters(['user', 'operators', 'organizers', 'competitions']),
        isAdmin() {
            return this.user.roles.includes('ROLE_ADMIN')
        },
        isOperator() {
            return this.user.operator;
        },
        isOrganizer() {
            return this.user.organizer;
        },
        isModerator() {
            return (
                this.user.roles.includes('ROLE_ADMIN')
                || this.user.roles.includes('ROLE_OPERATOR')
            );
        },
        // Only admin and operator have access to organizerId.
        organizerId() {
            if (this.isModerator) {
                if (this.$route.params.organizerId) {
                    return this.$route.params.organizerId;
                } if (this.$route.params.id) {
                    return this.competitions[this.$route.params.id]?.organizer;
                }
            }
            return null;
        },
        navigateToList() {
            if (this.user.roles.includes('ROLE_ADMIN')) {
                return { name: 'panel-admin', alias: true };
            } if (this.user.roles.includes('ROLE_OPERATOR')) {
                return { name: 'panel-operator', alias: true };
            }
            return { name: 'panel-organizer', alias: true };
        },
        isSettingStoperFullscreen() {
           return ['panel-competition-setting-stoper-fullscreen'].includes(this.$route.name)
        }
    },
    async created() {
        if (this.isOperator) {
            await this.READ_OPERATOR(this.user.operator);
        }
        if (this.isOrganizer) {
            await this.READ_ORGANIZER(this.user.organizer);
        }
        this.ready = true;
    },
    methods: {
        ...mapActions([READ_OPERATOR, READ_ORGANIZER]),
        navigateToSettings() {
            if (this.isOperator) {
                this.$router.push({
                    name: 'panel-setting-new',
                    params: { name: this.operators[this.user.operator].name },
                    alias: true,
                });
            } else if (this.user.organizer) {
                this.$router.push({
                    name: 'panel-setting-new',
                    params: { name: this.organizers[this.user.organizer].name },
                    alias: true,
                });
            } else {
                this.$router.push({
                    name: 'panel-setting',
                    params: { name: this.user.name },
                    alias: true,
                });
            }
        },
    },
};
</script>

<style scoped>
.header__logo-ds {
    width: 100%;
}
.header {
    background-color: var(--color-slate);
    border-bottom-width: 0;
}
.btn-logout {
    padding: 10px 0;
}
.header__button {
    background-color: var(--color-slate);
    color: var(--color-white);
    height: 48px;
    border-right: 1px solid var(--color-charcoal-grey);
    border-left: 1px solid var(--color-charcoal-grey);
}
.header__button-icon {
    width: 24px;
    height: 24px;
}
.header__button-actions {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 999;
    top: 46px;
}
.header__button-icon {
    margin-left: 8px;
}
.header__button--settings {
    display: none;
    border-right: none;
    border-left: none;
}
.header__button--list-provision {
    display: none;
    border-right: none;
}
.header__button--list-competitions {
    margin-left: 10px;
}
.header__button--list-provision + .header__button--list-competitions {
    margin-left: 0;
}
.header__button--list-users {
    position: relative;
    margin-left: 10px;
    border-right: 0px;
    margin-right: 10px;
    left: 10px;
}
@media (min-width: 768px) {
    .header__button-actions {
        left: unset;
    }
}
@media (min-width: 992px) {
    .header__button--settings,
    .header__button--list-provision {
        display: flex;
    }
    .btn-logout {
        padding: 20px 16px;
    }
    .border-left {
        border-left: 1px solid var(--color-charcoal-grey);
    }
    .border-right {
        border-right: 1px solid var(--color-charcoal-grey);
    }
}
</style>
