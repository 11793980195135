var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "user-header" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row user-header__row" }, [
        _c("div", [
          _c(
            "h1",
            [
              _vm.isMobile && !_vm.isGoBackButton
                ? _c(
                    "div",
                    { staticClass: "mobileGoBack", on: { click: _vm.goBack } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/arrow-left.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass: "logods",
                  attrs: { to: { name: "main-landing", alias: true } },
                },
                [_vm._v(" DO:ST:AR:TU ")]
              ),
            ],
            1
          ),
        ]),
        _vm.bootstraped
          ? _c(
              "div",
              { staticClass: "account-nav" },
              [
                !_vm.user
                  ? [
                      _c(
                        "base-button",
                        {
                          staticClass: "account-nav__btn--register",
                          attrs: { btn12: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRegister()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "account-nav__btn-name" }, [
                            _vm._v(" " + _vm._s(_vm.say.create_account) + " "),
                          ]),
                          _c("img", {
                            staticClass: "account-nav__btn-img",
                            attrs: {
                              src: require("@/assets/images/user-plus.svg"),
                              alt: "create account image",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "base-button",
                        {
                          attrs: { btn12: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleLogin()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "account-nav__btn-name" }, [
                            _vm._v(" " + _vm._s(_vm.say.login) + " "),
                          ]),
                          _c("img", {
                            staticClass: "account-nav__btn-img",
                            attrs: {
                              src: require("@/assets/images/sign-in.svg"),
                              alt: "sign in image",
                            },
                          }),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "base-button",
                        {
                          attrs: { btn12: "" },
                          on: {
                            click: function ($event) {
                              _vm.showUserButtons = !_vm.showUserButtons
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "account-nav__btn-name" }, [
                            _vm._v(" " + _vm._s(_vm.say.account) + " "),
                          ]),
                          _c("img", {
                            staticClass: "account-nav__btn-img",
                            attrs: {
                              src: require("@/assets/images/user-circle.svg"),
                              alt: "",
                            },
                          }),
                          _vm.showUserButtons
                            ? _c(
                                "div",
                                { staticClass: "account-nav__user-buttons" },
                                [
                                  _c("user-dropdown-buttons", {
                                    attrs: {
                                      "without-hints": _vm.$router.matched(
                                        "authentication-choose-profile",
                                        _vm.$route
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                _c(
                  "base-button",
                  {
                    staticClass: "account-nav__btn--lang",
                    attrs: { btn12: "" },
                    on: {
                      mouseover: function ($event) {
                        _vm.showOtherLang = true
                      },
                      mouseleave: function ($event) {
                        _vm.showOtherLang = false
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm._f("asUpperCase")(_vm.lang)) + " "),
                    _c("img", {
                      staticClass: "account-nav__btn-icon",
                      class: {
                        "account-nav__btn-icon--active": _vm.showOtherLang,
                      },
                      attrs: {
                        src: require("@/assets/images/arrow-down.svg"),
                        alt: "",
                      },
                    }),
                    _vm.showOtherLang
                      ? _c("div", { staticClass: "langmnu" }, [
                          _c(
                            "ul",
                            { staticClass: "langmnu__ul" },
                            _vm._l(_vm.langs, function (otherLang) {
                              return _c(
                                "li",
                                { key: otherLang, staticClass: "langmnu__li" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "account-nav__btn",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.CHANGE_LANG(otherLang)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("asUpperCase")(otherLang))
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }