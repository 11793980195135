<template>
  <div class="message">
    <div class="message-time"><span v-if="data.unreaded" class="ellipse"></span>{{ data.ago }}</div>
    <div class="message-title">{{ data.title }}</div>
    <div class="message-content">
      {{ data.body }}
      <a v-if="data.clickAction" :href="data.clickAction">{{ data.clickAction }}</a>
    </div>
    <div class="message-image" v-if="data.image"><img :src="data.image" alt=""></div>
  </div>
</template>

<script>
export default {
  name: 'Messages',
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  computed: {
    url() {
      return location.protocol + '//' + location.hostname
    }
  }
}
</script>

<style scoped>
  .message {
    position: relative;
    box-shadow: inset 0px -1px 0px #EDF0F1;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .message-time {
    color: #4A4A4A;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .message-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .message-content {
    font-size: 14px;
    line-height: 20px;
  }

  .message-content a {
    display: block;
    text-decoration: underline;
    color: var(--color-red-orange);
  }
  .message-image {
    margin-top: 12px;
  }
  .message-image img {
    max-width: 100%;
    height: auto;
  }

  .ellipse {
    background-color: var(--color-red-orange);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
  }
</style>