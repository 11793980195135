import {
  createCheckpoint,
  createCheckpointGroups,
  createCheckpointNumber,
} from "@/service/stoper";
import {
  ADD_CHECKPOINT_GROUP,
  ADD_OPERATOR_DEVICE,
  BOOTSTRAP_STOPER,
  CREATE_CHECKPOINT,
  CREATE_DEVICE,
  CREATE_GATE,
  DELETE_CHECKPOINT,
  DISABLE_CHECKPOINT,
  ENABLE_CHECKPOINT,
  MOVE_CHECKPOINT_GROUP,
  BOOTSTRAP_STOPER_SETTING,
  READ_COMPETITION_ORGANIZER,
  READ_OPERATOR_DEVICES,
  REMOVE_CHECKPOINT_GROUP,
  SELECT_CHECKPOINT_GROUP_DEVICE,
  UPDATE_CHECKPOINT,
  UPDATE_CHECKPOINT_GROUP,
  READ_OPERATOR,
  TEARDOWN_STOPER_SETTING,
  // DELETE_GATE,
  ADD_CHECKPOINT_GROUP_META,
  READ_CHRONOMETRY_FTP_FILES,
  SET_COMPETITION_CHRONO_DEFAULT,
} from "@/store/actions.type";
import api from '@/api';

export default {
  getters: {
    checkpointGroups: (state, getters) =>
      createCheckpointGroups(getters.stoperCompetition, getters),
    stoperOrganizer: (state, getters) =>
      getters.organizers[getters.stoperCompetition.organizer],
    checkpointGroupsStart: (state, getters) =>
      getters.checkpointGroups.filter(
        (checkpointGroup) => checkpointGroup.number === 0
      ),
    checkpointGroupsMeta: (state, getters) =>
      getters.checkpointGroups.filter(
        (checkpointGroup) => checkpointGroup.number === 1
      ),
  },
  actions: {
    async [BOOTSTRAP_STOPER_SETTING]({ dispatch, getters }, id) {
      await Promise.all([
        dispatch(BOOTSTRAP_STOPER, { id, isSettingPage: true }),
        dispatch(READ_COMPETITION_ORGANIZER, id),
      ]);

      await Promise.all([
        dispatch(READ_OPERATOR, getters.stoperOrganizer.operator),
        dispatch(READ_OPERATOR_DEVICES, getters.stoperOrganizer.operator),
      ]);
    },
    async [TEARDOWN_STOPER_SETTING]() {
      await Promise.resolve();
    },
    async [ADD_CHECKPOINT_GROUP]({ dispatch, getters }, isStart) {
      const checkpoint = createCheckpoint(getters.checkpointGroups, isStart);

      const promises = [];
      if (isStart) {
        const { checkpointGroupsStart } = getters;
        // Find checkpointGroups which contains more than 1 checkpoint.
        const [firstCheckpointGroupWithManyCheckpoints] =
          checkpointGroupsStart.filter(
            (checkpointGroup) => checkpointGroup.checkpointsIds.length > 1
          );
        // Get last checkpoint id from CheckpointGroup.
        const [lastCheckpointId] =
          firstCheckpointGroupWithManyCheckpoints.checkpointsIds.slice(-1);
        // Get this checkpoint to know which classification he has.
        const lastCheckpoint = getters.checkpoints[lastCheckpointId];
        await dispatch(DELETE_CHECKPOINT, lastCheckpointId);
        await dispatch(CREATE_CHECKPOINT, {
          ...checkpoint,
          classification: lastCheckpoint.classification,
        });
      } else {
        for (const classification of getters.stoperClassifications) {
          promises.push(
            dispatch(CREATE_CHECKPOINT, {
              ...checkpoint,
              classification: classification.id,
            })
          );
        }
        await Promise.all(promises);
      }
    },
    async [ADD_CHECKPOINT_GROUP_META]({ dispatch, getters }) {
      const checkpoint = createCheckpoint(
        getters.checkpointGroups,
        false,
        true
      );

      const { checkpointGroupsMeta } = getters;
      // Find finishCheckpointGroup with more than 1 checkpoint.
      const [checkpointGroupWithManyCheckpoints] = checkpointGroupsMeta.filter(
        (checkpointGroup) => checkpointGroup.checkpointsIds.length > 1
      );
      const [lastCheckpointId] =
        checkpointGroupWithManyCheckpoints.checkpointsIds.slice(-1);
      const lastCheckpoint = getters.checkpoints[lastCheckpointId];
      await dispatch(DELETE_CHECKPOINT, lastCheckpointId);
      await dispatch(CREATE_CHECKPOINT, {
        ...checkpoint,
        classification: lastCheckpoint.classification,
      });
    },
    async [ENABLE_CHECKPOINT](
      { dispatch, getters },
      { classification, checkpointGroup }
    ) {
      // Currently each checkpoint have the same and only one gate.
      const gateId = getters.checkpointsBy(checkpointGroup)[0].gates[0];
      if (checkpointGroup.number === 0) {
        let startCheckpoints = [];
        for (const checkpointGrp of getters.checkpointGroupsStart) {
          const checkpoints = getters.checkpointsBy(checkpointGrp);
          startCheckpoints = startCheckpoints.concat(checkpoints);
        }
        // Each classification have 1 startCheckpoint. Delete oldStartCheckpoint then create new one.
        const oldStartCheckpoint = startCheckpoints.find(
          (checkpoint) => checkpoint.classification === classification.id
        );
        await dispatch(DELETE_CHECKPOINT, oldStartCheckpoint.id);
      }
      if (checkpointGroup.number === 1) {
        let metaCheckpoints = [];
        for (const checkpointGrp of getters.checkpointGroupsMeta) {
          const checkpoints = getters.checkpointsBy(checkpointGrp);
          metaCheckpoints = metaCheckpoints.concat(checkpoints);
        }
        // Each classification have 1 metaCheckpoint. Delete oldMetaCheckpoint and create new one.
        const oldMetaCheckpoints = metaCheckpoints.find(
          (checkpoint) => checkpoint.classification === classification.id
        );
        await dispatch(DELETE_CHECKPOINT, oldMetaCheckpoints.id);
      }
      await dispatch(CREATE_CHECKPOINT, {
        name: checkpointGroup.name,
        gates: gateId ? [gateId] : [],
        number: checkpointGroup.number,
        hidden: false,
        classification: classification.id,
      });
    },
    async [DISABLE_CHECKPOINT]({ dispatch }, checkpoint) {
      await dispatch(DELETE_CHECKPOINT, checkpoint.id);
    },
    async [MOVE_CHECKPOINT_GROUP](
      { dispatch, getters },
      { checkpointGroup, position, oldPosition }
    ) {
      const number = createCheckpointNumber(
        getters.checkpointGroups,
        position,
        oldPosition
      );

      await dispatch(UPDATE_CHECKPOINT_GROUP, {
        ...checkpointGroup,
        number,
      });
    },
    async [UPDATE_CHECKPOINT_GROUP]({ dispatch, getters }, checkpointGroup) {
      const promises = [];
      for (const checkpoint of getters.checkpointsBy(checkpointGroup)) {
        promises.push(
          dispatch(UPDATE_CHECKPOINT, {
            ...checkpoint,
            number: checkpointGroup.number,
            gates: checkpointGroup.gates,
            name: checkpointGroup.name,
          })
        );
      }
      await Promise.all(promises);
    },
    async [REMOVE_CHECKPOINT_GROUP]({ dispatch, getters }, checkpointGroup) {
      const promises = [];
      // Memorize old gates before normalization.
      // const oldGates = getters.gatesBy(getters.checkpointsBy(checkpointGroup)[0]);
      for (const checkpoint of getters.checkpointsBy(checkpointGroup)) {
        promises.push(dispatch(DELETE_CHECKPOINT, checkpoint.id));
      }

      await Promise.all(promises);

      // Need check.
      // Delete orphaned gates.
      // for (const oldGate of oldGates) {
      //     if (getters.checkpointsBy(oldGate).length === 0) {
      //         dispatch(DELETE_GATE, oldGate.id);
      //     }
      // }
    },
    async [ADD_OPERATOR_DEVICE]({ dispatch, getters }, device) {
      await dispatch(CREATE_DEVICE, {
        name: device.name,
        type: device.type,
        operator: getters.stoperOrganizer.operator,
      });
    },
    async [SELECT_CHECKPOINT_GROUP_DEVICE](
      { dispatch, getters },
      { checkpointGroup, device }
    ) {
      // Map selected device to stoper gate.
      let gate = Object.values(getters.gates).find(
        (g) =>
          g.device === device?.id &&
          g.competition === checkpointGroup.competition
      );

      if (device && !gate) {
        // Create new gate.
        const gateId = await dispatch(CREATE_GATE, {
          device: device.id,
          name: `Gate at ${device.name}`,
          competition: checkpointGroup.competition,
        });

        gate = getters.gates[gateId];
      }
      // Set gate.
      await dispatch(UPDATE_CHECKPOINT_GROUP, {
        ...checkpointGroup,
        gates: gate ? [gate.id] : [], // currently we support only one gate per checkpoint
      });
    },
    async [READ_CHRONOMETRY_FTP_FILES](_, id) {
      try {
        const data = await api.get(`/panel/competitions/${id}/chronometry/files`);
        return data
      } catch(err) {
          return err
      }
    },
    async [SET_COMPETITION_CHRONO_DEFAULT](_, id) {
      try {
        const data = await api.post(`/panel/competitions/${id}/chronoDefault`);
        return data
      } catch(err) {
          return err
      }
    },
  },
};
