import api from '@/api';
import { CHANGE_LANG, READ_LANGS, READ_MESSAGES } from '@/store/actions.type';
import { SET_LANG, SET_LANGS, SET_MESSAGES } from '@/store/mutations.type';

export default {
    state: {
        lang: localStorage.getItem('lang') ?? 'pl',
        langs: [],
        messages: {},
    },
    getters: {
        // Getter for `messages` is named `say` for shortcut.
        say: (state) => state.messages,
        lang: (state) => state.lang,
        langs: (state) => state.langs,
    },
    mutations: {
        [SET_LANGS](state, langs) {
            state.langs = langs;
        },
        [SET_MESSAGES](state, messages) {
            state.messages = messages;
        },
        [SET_LANG](state, lang) {
            state.lang = lang;
            localStorage.setItem('lang', lang);
        },
    },
    actions: {
        async [READ_LANGS]({ commit }) {
            const data = await api.get('/langs');

            commit(SET_LANGS, data);
        },
        async [READ_MESSAGES]({ commit }, lang) {
            const data = await api.get(`/messages/${lang}`);

            commit(SET_MESSAGES, data);
        },
        async [CHANGE_LANG]({ commit, dispatch }, lang) {
            await dispatch(READ_MESSAGES, lang);

            commit(SET_LANG, lang);
        },
    },
};
