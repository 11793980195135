var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "classification",
      class: {
        "classification--dark": !_vm.isLight,
        "classification--light": _vm.isLight,
      },
    },
    [
      _c("div", { staticClass: "row palr15" }, [
        _c("div", { staticClass: "col-12 col-lg-4" }, [
          !_vm.id
            ? _c("p", {
                staticClass:
                  "classification__text-skeleton classification__text-skeleton--margin",
              })
            : _c("h5", { staticClass: "classification__name" }, [
                _vm._v(" " + _vm._s(_vm.classification.namePl) + " "),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8 classification__description" },
          [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "col-sm-3 col-4 col-md-3 col-lg-3" }, [
                  _c(
                    "p",
                    {
                      staticClass: "nowrap classification__description-label",
                      class: {
                        "classification__description-label--light-mode":
                          _vm.isLight,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.distance) + " ")]
                  ),
                  !_vm.id
                    ? _c("p", {
                        staticClass: "classification__text-skeleton mat8i",
                      })
                    : _c(
                        "p",
                        {
                          staticClass: "classification__description-value",
                          class: {
                            "classification__description-value--light-mode":
                              _vm.isLight,
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.classification.distance) + " km "
                          ),
                        ]
                      ),
                ]),
                _c("div", { staticClass: "col-sm-2 col-4 col-md-2 col-lg-3" }, [
                  _c(
                    "p",
                    {
                      staticClass: "nowrap classification__description-label",
                      class: {
                        "classification__description-label--light-mode":
                          _vm.isLight,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.gender) + " ")]
                  ),
                  !_vm.id
                    ? _c("p", {
                        staticClass: "classification__text-skeleton mat8i",
                      })
                    : _c(
                        "p",
                        {
                          staticClass: "classification__description-value",
                          class: {
                            "classification__description-value--light-mode":
                              _vm.isLight,
                          },
                        },
                        [
                          _vm.canM && _vm.canK
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.say.woman_short) +
                                    "," +
                                    _vm._s(_vm.say.man_short) +
                                    " "
                                ),
                              ]
                            : _vm.canM
                            ? [_vm._v(" " + _vm._s(_vm.say.man_short) + " ")]
                            : _vm.canK
                            ? [_vm._v(" " + _vm._s(_vm.say.woman_short) + " ")]
                            : _vm._e(),
                        ],
                        2
                      ),
                ]),
                _c("div", { staticClass: "col-sm-3 col-4 col-md-2 col-lg-3" }, [
                  _c(
                    "p",
                    {
                      staticClass: "nowrap classification__description-label",
                      class: {
                        "classification__description-label--light-mode":
                          _vm.isLight,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.say.age) + " ")]
                  ),
                  !_vm.id
                    ? _c("p", {
                        staticClass: "classification__text-skeleton mat8i",
                      })
                    : _c(
                        "p",
                        {
                          staticClass: "classification__description-value",
                          class: {
                            "classification__description-value--light-mode":
                              _vm.isLight,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.age.from) +
                              "-" +
                              _vm._s(_vm.age.to) +
                              " "
                          ),
                        ]
                      ),
                ]),
                !_vm.id
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "classification__description-label",
                              class: {
                                "classification__description-label--light-mode":
                                  _vm.isLight,
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.say.available) + " ")]
                          ),
                          _c("p", {
                            staticClass: "classification__text-skeleton mat8i",
                          }),
                        ]
                      ),
                    ]
                  : [
                      [
                        _vm.canRegisterClassification(
                          _vm.competition,
                          _vm.limits
                        )
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "classification__description-label",
                                    class: {
                                      "classification__description-label--light-mode":
                                        _vm.isLight,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.say.available) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "classification__description-value",
                                    class: {
                                      "classification__description-value--light-mode":
                                        _vm.isLight,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.limits.playersAvailable) +
                                        " / " +
                                        _vm._s(_vm.limits.playersLimit) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-4 col-12 col-md-5 col-lg-3 txt-align boxclass__players-limits",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "classification__description-label",
                                    class: {
                                      "classification__description-label--light-mode":
                                        _vm.isLight,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.say.number_of_participants) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "classification__description-value",
                                    class: {
                                      "classification__description-value--light-mode":
                                        _vm.isLight,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.limits.playersUnavailable) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ],
                    ],
              ],
              2
            ),
            _c("div", { staticClass: "col-12 pa0" }, [
              _c("hr", {
                staticClass: "classification__line",
                class: { "classification__line--light": _vm.isLight },
              }),
            ]),
            _c(
              "div",
              { staticClass: "row out-gutter" },
              [
                !_vm.id
                  ? [
                      _c("p", {
                        staticClass: "classification__text-skeleton w40i mat8i",
                      }),
                    ]
                  : [
                      _vm.classificationSetting.isPay && !_vm.prices.length
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "classification__description-label",
                                class: {
                                  "classification__description-label--light-mode":
                                    _vm.isLight,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.say.unknown_payment_information
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : !_vm.classificationSetting.isPay
                        ? _c("div", { staticClass: "col-12 col-sm-3" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "classification__description-label",
                                class: {
                                  "classification__description-label--light-mode":
                                    _vm.isLight,
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.say.feeless) + " ")]
                            ),
                          ])
                        : [
                            _c("div", { staticClass: "col-12 col-sm-3" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "classification__description-label",
                                  class: {
                                    "classification__description-label--light-mode":
                                      _vm.isLight,
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.say.prices) + " ")]
                              ),
                            ]),
                            _vm._l(_vm.ascSortPrices, function (price, idx) {
                              return _c(
                                "div",
                                {
                                  key: price.id,
                                  staticClass: "col-6 col-sm-3 boxclass__price",
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "classification__description-label",
                                      class: {
                                        "classification__description-label--light-mode":
                                          _vm.isLight,
                                      },
                                    },
                                    [
                                      _vm.classificationSetting
                                        .pricesControlledBy === "date"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.say.until) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("asDate")(
                                                    price.endedTime
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.say[
                                                    [
                                                      "first_players_fee",
                                                      "next_players_fee",
                                                      "rest_players_fee",
                                                    ][idx]
                                                  ]
                                                ) +
                                                " " +
                                                _vm._s(price.playersLimit) +
                                                " "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "classification__description-value",
                                      class: {
                                        "classification__description-value--light-mode":
                                          _vm.isLight,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(price.price) +
                                          " " +
                                          _vm._s(_vm.currency) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                    ],
              ],
              2
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }