<template>
	<div v-if="role && Object.keys(role).length > 0" class="mat40">
		<div v-if="organizerId" classs="row">
			<div class="col-12 mab24">
				<div class="info-box info-bg">
					<div class="row">
						<div class="col-12 col-md-6">
							<h3 class="mab16" style="line-height: 1.1">Uzupełnij dane organizatora i zaakceptuj umowę powierzenia przetwarzania danych osobowych.</h3>
							<p>Aby dostosować działanie systemu do obowiązujących przepisów, potrzebujemy danych organizatorów do wystawienia umów o przetwarzaniu danych osobowych uczestników.</p>
						</div>
					</div>
				</div>
				<div class="info-box alert-bg">
					<div class="row">
						<div class="col-12 col-md-6">
							<p>Od 15.01.2021 wejście do panelu organizatora będzie możliwe tylko po uzupełnieniu wymaganych danych i zaakceptowaniu umowy.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<panel-competition-form-box class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Dane 
					<template v-if="organizerId">organizatora</template>
					<template v-else-if="operatorId">operatora</template>
				</h2>
			</div>
			<div v-if="organizerId" class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Osobowość prawna organizatora</p>
					<div class="radio-field radio-field__row">
						<input id="private" v-model="form.legalType" type="radio" class="d-none" value="private"><label for="private" class="radio-field__label">Osoba prywatna</label>
						<input id="company" v-model="form.legalType" type="radio" class="d-none" value="company"><label for="company" class="radio-field__label">Firma / Fundacja / Stowarzyszenie</label>
					</div>
				</div>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6 ">
					<p class="form-input__label">NIP</p>                        
					<base-input-text v-model="form.fvNip" />
				</div>
				<div class="col-12 col-md-6">
					<div class="malr24">
						<p class="form-input__helper">Dane	<template v-if="organizerId">organizatora</template><template v-else-if="operatorId">operatora</template> wymagane są do zawarcia umów powerzenia przetwarzania danych osobowych uczestników oraz w przypadku uruchomienia płatności online, umów z operatorem płatności.</p>
					</div>
				</div>
			</div>
			<div class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Nazwa firmy</p>                        
					<base-input-text v-model="form.fvFirmname" />
				</div>
			</div>
			<div v-if="organizerId" class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Prowizja od opłaty startowej</p>                        
					<base-input-text v-model="form.commision" />
				</div>
			</div>
			<div class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Miejscowość</p>                        
					<base-input-text v-model="form.fvCity" />
				</div>
			</div>
			<div class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Kod pocztowy</p>                        
					<base-input-text v-model="form.fvPostcode" />
				</div>
			</div>
			<div class="row" style="width: 100%;">
				<div class="col-12 col-md-6 mab16">
					<p class="form-input__label">Ulica</p>                        
					<base-input-text v-model="form.fvAddress" />
				</div>
			</div>
			<div class="row unset mab16" style="width: 100%;">
				<div class="col-8 col-md-4">
					<p class="form-input__label">Nr domu</p>                        
					<base-input-text v-model="form.fvHouseNumber" />
				</div>
				<div class="col-4 col-md-2">
					<div class="mal8">
						<p class="form-input__label">Nr lokalu</p>                        
						<base-input-text v-model="form.fvFlatNumber" />
					</div>
				</div>
			</div>
		</panel-competition-form-box>
		<panel-competition-form-box class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Publiczne dane <template v-if="organizerId">organizatora</template><template v-else-if="operatorId">operatora</template></h2>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6">
					<p class="form-input__label">Nazwa <template v-if="organizerId">organizatora</template><template v-else-if="operatorId">operatora</template></p>                        
					<base-input-text v-model="form.name" />
				</div>   
				<div class="col-12 col-md-6">
					<div class="malr24">
						<p class="form-input__helper">Wypełnij dane kontaktowe, jeśli chcesz udostępnić uczestnikom inne dane niż swój adres e-mail używany do logowania do panelu i/lub innej nazwy <template v-if="organizerId">organizatora</template><template v-else-if="operatorId">operatora</template> niż Imię i Nazwisko.</p>
					</div>
				</div>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6">
					<p class="form-input__label">Adres e-mail</p>                        
					<base-input-text v-model="form.email" />
				</div>    
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6">
					<p class="form-input__label">Numer telefonu</p>                        
					<base-input-text v-model="form.phone" />
				</div>     
			</div>
		</panel-competition-form-box>
		<div class="col-lg-12 mat16 mab64">
		  <div class="row justify-content-end">
			<div class="col-12 col-lg-4">
			  <base-button @click="saveForm" :disabled="loading" btn1 short>Zapisz</base-button>
			</div>
		  </div>
		</div>
		<!-- 
		<panel-competition-form-box class="panel-form">
			<div class="col-12">
				<h2>Dane Logowania</h2>
			</div>
			<div class="col-12">
				<p class="form-input__label">Adres e-mail logowania organizatora</p>                        
				<base-input-text v-model="form.email" />
			</div>                        
		</panel-competition-form-box>
		-->
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';


export default {
	name: 'PanelAccountSettingsSet',
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
	components: {
		PanelCompetitionFormBox,
	},
	data() {
		return {
			loading: false,
			role: null,
			form: {}
		}
	},
	computed: {
        ...mapGetters(['user']),
		operatorId() {
			return this.organizerId ? null : this.user.operator
		},
		organizerId() {
			return this.$route.params?.id || this.user.organizer
		},
    },
	created() {
		if (this.organizerId) {
			this.getRoleData(this.organizerId, 'organizers')
		} else if (this.operatorId) this.getRoleData(this.operatorId, 'operators')
	},
	methods: {
		async getRoleData(id, role) {
			try {
				const response = await axios.get(`/panel/${role}/${id}`);
				this.role = JSON.parse(JSON.stringify(response))
				this.form = response
			} catch (e) {
				this.errors = e;
				return;
			}
		},
		async saveForm() {
            this.loading = true
            const promises = []
			const url = this.organizerId ? `/panel/organizers/${this.organizerId}` : `/panel/operators/${this.operatorId}`
			const payload = {
				"email": this.form.email,
				"fvFirmname": this.form.fvFirmname,
				"fvPostcode": this.form.fvPostcode,
				"fvCity": this.form.fvCity,
				"fvAddress": this.form.fvAddress,
				"fvNip": this.form.fvNip,
				"name": this.form.name,
				"phone": this.form.phone,
				"fvHouseNumber": this.form.fvHouseNumber,
				"fvFlatNumber": this.form.fvFlatNumber,
			}
			if (this.organizerId) {
				payload["operator"] = this.form.operator
				payload["legalType"] = this.form.legalType
				payload["commision"] = this.form.commision
			}

			const orgPUT = axios({
				method: 'put',
				url,
				data: payload                 
			})
			promises.push(orgPUT)
            await Promise.all(promises).catch(err => {
                this.errors = err;
            });
            this.loading = false
        },
	}
}
</script>

<style scoped>
.info-box {
    padding: 30px;
	border-radius: 4px;
}
.info-box.info-bg {
	background-color: #fff;
	color: #37474f;
    border-radius: 4px 4px 0px 0px;
}
.info-box.alert-bg {
	background-color: #ffe2e8;
	color: #37474f;
    border-radius:0px 0px 4px 4px;
}
.form-input__label {
	margin-bottom: 6px;
}
.row.unset {
    justify-content: unset;
}
@media (max-width: 991px) {
	.form-input__helper {
		margin: 8px 0;
	}
}
@media (min-width: 992px) {
    .malr8 {
        margin-left: 8px;
    }
    .malr24 {
        margin-left: 24px;
    }
}
</style>	