var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label.length
        ? _c("p", { staticClass: "mab8" }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ])
        : _vm._e(),
      _c("DatePicker", {
        attrs: {
          mode: _vm.mode,
          "model-config": _vm.modelConfig,
          popover: { visibility: "hidden" },
          is24hr: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ inputValue, inputEvents, togglePopover }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "input-wrap",
                    class: { "input-field--error": _vm.error },
                  },
                  [
                    _c(
                      "input",
                      _vm._g(
                        {
                          attrs: { placeholder: _vm.placeholder },
                          domProps: { value: inputValue },
                        },
                        inputEvents
                      )
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "input-icon",
                        on: {
                          click: function ($event) {
                            return togglePopover()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/calendar.svg"),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      }),
      _c(
        "span",
        {
          staticClass: "panel-input-error input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }