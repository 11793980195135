<template>
  <div class="container">
    <div class="mab40">
      <PanelCompetitionSettingStoperMain :id="id" />
    </div>
  </div>
</template>

<script>
import PanelCompetitionSettingStoperMain from '@/components/PanelCompetitionSettingStoperMain';

export default {
  name: 'PanelCompetitionSettingStoperMainFullscreen',
  components: {
    PanelCompetitionSettingStoperMain,
  },
  props: {
      id: {
          type: Number,
          required: true,
      },
  },
}
</script>

<style>

</style>