<template>
    <div>
        <p
            v-if="label && label.length"
            class="mab8"
        >
            {{ label
            }}<span
                v-if="optional"
                class="positive"
            >(opcjonalnie)</span>
        </p>
        <base-input-text
            :value="value"
            :hint="hint"
            is-short
            without-error-message
            :error="error"
            @input="$emit('input', $event)"
            @update:error="$emit('update:error', $event)"
            :type="type"
            class="box-shadow"
        />
        <div v-if="error && error === 'field_occupied'" class="panel-tooltip-error">
            <span class="panel-tooltip-text">Numer zajęty</span>
            <img src="@/assets/images/icons/error-exclamation-mark.svg">
        </div>
        <span
            class="panel-input-error input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        hint: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text'
        },
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
<style scoped>
.positive {
    color: var(--color-light-sage);
}
.box-shadow >>> .input-field__input {
    color: var(--color-slate);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}
.panel-input-error.input-error {
    bottom: 2px;
    left: 20px;
}
.panel-tooltip-error {
    position: absolute;
    top: 31px;
    right: 24px;
}
.panel-tooltip-text {
    background-color: #37474F;
    color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    bottom: 130%;
    right: -44px;
    padding: 6px 12px;
    transition: all .5s;
    z-index: 44;
    white-space: nowrap;
}
.panel-tooltip-text::after {
    content: '';
    border-width: 5px;
    border-style: solid;
    border-color: #37474F transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: 5%;
}
</style>
