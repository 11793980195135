<template>
    <div>
        <main-header>
            <template #right>
                <main-header-search />
            </template>
        </main-header>

        <transition name="home-promote">
            <main-carousel v-if="$route.name === 'main-landing'" />
        </transition>

        <main-list v-if="$route.name !== 'main-list-map'" />

        <router-view />
    </div>
</template>

<script>
import MainHeader from '@/components/MainHeader';
import MainList from '@/components/MainList';
import MainCarousel from '@/components/MainCarousel';
import MainHeaderSearch from '@/components/MainHeaderSearch';

export default {
    components: {
        MainHeader,
        MainList,
        MainCarousel,
        MainHeaderSearch,
    },
};
</script>
