var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h100" },
    [
      _c("stoper-controls", { attrs: { "is-panel-gate": _vm.isPanelGate } }),
      _c("stoper-numbers"),
      _vm.stoperStarted &&
      (_vm.stoperMeasures.length || _vm.stoperOfflineMeasures.length)
        ? _c("div", { staticClass: "row justify-content-end" }, [
            _c(
              "span",
              {
                staticClass: "stoper-export",
                class: {
                  "stoper-export--gate": _vm.$route.name === "panel-gate",
                },
                on: {
                  click: function ($event) {
                    return _vm.exportMeasures()
                  },
                },
              },
              [_vm._v("Export pomiarów")]
            ),
          ])
        : _vm._e(),
      _vm.competition &&
      !_vm.stoperMeasures.length &&
      !_vm.stoperOfflineMeasures.length &&
      _vm.isDesktop
        ? _c("stoper-prices", { attrs: { competition: _vm.competition } })
        : _c("stoper-list", {
            attrs: { "is-large-screen": _vm.isLargeScreen },
          }),
      _c("stoper-keyboard", {
        attrs: {
          "is-panel-gate": _vm.isPanelGate,
          "is-large-screen": _vm.isLargeScreen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }