<template>
    <header class="user-header">
        <div class="container">
            <div class="row user-header__row">
                <div>
                    <h1>
                        <div v-if="isMobile && !isGoBackButton" @click="goBack" class="mobileGoBack">
                            <img src="@/assets/images/icons/arrow-left.svg">
                        </div>
                        <!-- Home link. -->
                        <!-- <router-link
                            :to="{ name: 'user-competitions-future', alias: true }"
                            class="logods"
                            v-if="user"
                        >
                            DO:ST:AR:TU
                        </router-link> -->
                        <router-link
                            :to="{ name: 'main-landing', alias: true }"
                            class="logods"
                        >
                            DO:ST:AR:TU
                        </router-link>
                    </h1>
                </div>
                <div
                    v-if="bootstraped"
                    class="account-nav"
                >
                    <!-- User not logged in. -->
                    <template v-if="!user">
                        <!-- Create account. -->
                        <base-button
                            class="account-nav__btn--register"
                            btn12
                            @click="handleRegister()"
                        >
                            <span class="account-nav__btn-name">
                                {{ say.create_account }}
                            </span>
                            <img
                                class="account-nav__btn-img"
                                src="@/assets/images/user-plus.svg"
                                alt="create account image"
                            >
                        </base-button>
                        <!-- Login. -->
                        <base-button
                            btn12
                            @click="handleLogin()"
                        >
                            <span class="account-nav__btn-name">
                                {{ say.login }}
                            </span>
                            <img
                                class="account-nav__btn-img"
                                src="@/assets/images/sign-in.svg"
                                alt="sign in image"
                            >
                        </base-button>
                    </template>
                    <!-- User logged. -->
                    <template v-else>
                        <base-button
                            btn12
                            @click="showUserButtons = !showUserButtons"
                        >
                            <span class="account-nav__btn-name">
                                {{ say.account }}
                            </span>
                            <img
                                class="account-nav__btn-img"
                                src="@/assets/images/user-circle.svg"
                                alt=""
                            >
                            <div
                                v-if="showUserButtons"
                                class="account-nav__user-buttons"
                            >
                                <user-dropdown-buttons
                                    :without-hints="
                                        $router.matched(
                                            'authentication-choose-profile',
                                            $route,
                                        )
                                    "
                                />
                            </div>
                        </base-button>
                    </template>
                    <!-- Change lang. -->
                    <base-button
                        btn12
                        class="account-nav__btn--lang"
                        @mouseover="showOtherLang = true"
                        @mouseleave="showOtherLang = false"
                    >
                        {{ lang | asUpperCase }}
                        <img
                            class="account-nav__btn-icon"
                            :class="{
                                'account-nav__btn-icon--active': showOtherLang,
                            }"
                            src="@/assets/images/arrow-down.svg"
                            alt=""
                        >
                        <div
                            v-if="showOtherLang"
                            class="langmnu"
                        >
                            <ul class="langmnu__ul">
                                <li
                                    v-for="otherLang in langs"
                                    :key="otherLang"
                                    class="langmnu__li"
                                >
                                    <a
                                        href="#"
                                        class="account-nav__btn"
                                        @click="CHANGE_LANG(otherLang)"
                                    >{{ otherLang | asUpperCase }}</a>
                                </li>
                            </ul>
                        </div>
                    </base-button>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CHANGE_LANG } from '@/store/actions.type';
import UserDropdownButtons from '@/components/UserDropdownButtons';

export default {
    components: {
        UserDropdownButtons,
    },
    data() {
        return {
            showUserButtons: false,
            showOtherLang: false,
        };
    },
    computed: {
        ...mapGetters(['say', 'lang', 'langs', 'bootstraped', 'user']),
        isGoBackButton() {
            return [
                'main-landing',
                'user-competitions-future',
                'user-competitions-past',
                'user-competitions-proposed',
                'user-insurance-policies',
                'user-family-parents',
                'user-family-children',
                'user-more',
            ].includes(this.$route.name);
        },
        isMobile() {
            return ['sm', 'md'].includes(this.$mq);
        },         
    },
    methods: {
        ...mapActions([CHANGE_LANG]),
        handleLogin() {
            const entryLogin = {
                event: 'logowanie',
                event_category: 'zalogowany',
            };
            this.$gtm.push(entryLogin);
            this.$router.push({
                name: 'authentication-login',
                alias: true,
            });
        },
        handleRegister() {
            const entryRegister = {
                event: 'zalozenie',
                event_category: 'zalozkonto',
            };
            this.$gtm.push(entryRegister);
            this.$router.push({
                name: 'authentication-register',
                alias: true,
            });
        },
        goBack() {
            this.$router.go(-1)
        }
    },
};
</script>
<style scoped>
.account-nav__btn--register {
    display: none;
}
header {
    box-shadow: inset 0 0 0 1px var(--color-pale-grey);
}
.user-header {
    position: relative;
}
.user-header__row {
    flex-wrap: nowrap !important;
    display: flex;
    justify-content: space-between;
}
.user-header__row h1 {
    display: flex;
}
.logods {
    display: block;
    width: 95px;
    height: 48px;
    background-image: url(../assets/images/logo-dostartu.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    font-size: 11px;
    text-indent: -999px;
    overflow: hidden;
}
.account-nav {
    display: flex;
    margin-right: -15px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.account-nav__btn--lang {
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    position: relative !important;
    flex-direction: column;
}

.account-nav__user-buttons {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 999;
    top: 46px;
}

.account-nav__btn-img {
    margin-left: 0;
    width: 24px;
    height: 24px;
}
.langmnu {
    z-index: 2;
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 48px;
    background-color: #fff;
}

.langmnu__ul {
    margin: 0;
    padding: 0;
}

.langmnu__li {
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    list-style-type: none;
}

.langmnu__li:first-child {
    border-top: solid 1px rgba(0, 0, 0, 0.1);
}
.account-nav__btn-icon {
    width: 24px;
    height: 24px;
    margin-left: 0;
}
.account-nav__btn-icon--active {
    transform: rotate(180deg);
}
.account-nav__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 48px;
    cursor: pointer;
    text-transform: capitalize;
}
.mobileGoBack {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    box-shadow: inset -1px 0px 0px #E5E5E5;
    position: relative;
    left: -15px;
}

@media (min-width: 500px) {
    .account-nav__btn--lang {
        flex-direction: row;
    }
}
@media (min-width: 768px) {
    .account-nav {
        margin-right: 0;
    }
    .account-nav__user-buttons {
        left: unset;
    }
    .account-nav__btn-img {
        margin-left: 8px;
    }
    .account-nav__btn--register {
        display: flex;
    }
}
</style>
