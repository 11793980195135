var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selectLabel.length > 0
        ? _c("p", { staticClass: "mab8" }, [_vm._v(_vm._s(_vm.selectLabel))])
        : _vm._e(),
      _c(
        "multiselect",
        {
          class: { "input-field--error": _vm.error },
          attrs: {
            options: _vm.options,
            "track-by": _vm.trackBy,
            label: _vm.label,
            "show-labels": false,
            searchable: _vm.searchable,
            multiple: _vm.multiple,
            limit: _vm.limit,
            placeholder: _vm.placeholder,
            "max-height": _vm.maxHeight,
            "hide-selected": _vm.hideSelected,
            "limit-text": _vm.limitText,
          },
          scopedSlots: _vm._u([
            {
              key: "tag",
              fn: function ({ option }) {
                return [
                  _c("span", { staticClass: "custom__tag" }, [
                    _vm._v(_vm._s(option[_vm.label]) + ", "),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function ({ option }) {
                return [
                  _c("div", { staticClass: "option__desc" }, [
                    _c("span", { staticClass: "option__title" }, [
                      _vm._v(_vm._s(_vm.label ? option[_vm.label] : option)),
                    ]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.innerValue,
            callback: function ($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue",
          },
        },
        [
          _vm.multiple
            ? _c("template", { slot: "caret" }, [
                _vm.innerValue && _vm.innerValue.length > 0
                  ? _c("span", { staticClass: "caret-count" }, [
                      _vm._v(_vm._s(_vm.innerValue.length)),
                    ])
                  : _c("div", { staticClass: "multiselect__select" }),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "panel-input-error input-error",
          class: { "input-error--show": _vm.error },
        },
        [_vm._v(_vm._s(_vm.say[_vm.error]))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }