<template>
    <div
        class="input-field input-field--focus-bolder"
        :class="{'input-field--error' : error}"
    >
        <label
            v-if="!error"
            class="input-field__label"
            :class="{'input-field__label--show' : value}"
        >{{ label }}</label>

        <input
            class="input-field__input"
            :class="{
                'input-field__input--padding-bottom' : error,
                'input-field__input--padding-top' : isInputValid
            }"
            :value="value"
            :placeholder="label"
            :type="hidden ? 'password' : 'text'"
            @input="$emit('input', $event.target.value)"
        >

        <span
            class="input-error"
            :class="{'input-error--show' : error}"
        >{{ say[error] }}</span>

        <i
            class="input-field__icon"
            :class="hidden ? 'far fa-eye' : 'far fa-eye-slash'"
            @click="hidden = !hidden"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import input from '@/mixins/input';

export default {
    mixins: [input],
    data() {
        return {
            hidden: true,
        };
    },
    computed: {
        ...mapGetters(['say']),
    },
};
</script>
