import Vue from 'vue';

// List base components.
const baseComponents = require.context('.', false, /Base[\w-]+\.vue$/);

// Globally register base components.
for (const fileName of baseComponents.keys()) {
    const component = baseComponents(fileName);

    const componentName = fileName
        .replace(/^\.\//, '') // remove the "./" prefix
        .replace(/\.\w+$/, ''); // remote the file extension suffix

    Vue.component(componentName, component.default || component);
}
