<template>
  <div>
    <div :id="`handle-${index}`" class="rangeSlider__handle" :class="{active: isActive}" ref="resizeHandle" :style="handleStyles">
      <img src="@/assets/images/icons/range-slider/marker_no_shadow.svg" alt="">
      <div v-if="canRemoveHandle" @click="removeHandle" class="removeHandle"><img src="@/assets/images/icons/range-slider/close.svg" alt="" style="position: relative;left: auto;top: auto;"></div>
    </div>
    <div v-show="isHandleEnd" :id="`handleend-${index}`" class="rangeSlider__handle active" ref="resizeHandleEnd" :style="{transform: `translate(${handleEndPosition}px, 64px)`}">
      <img src="@/assets/images/icons/range-slider/marker_no_shadow.svg" alt="">
    </div>
  </div>
</template>

<script>
  import { gsap } from "gsap";
  import { Draggable } from "gsap/Draggable";

  gsap.registerPlugin(Draggable);

  export default {
    name: 'ageCategoriesRangeSliderHandle',
    props: {
      data: {
        type: Object,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      drag: {
        type: Boolean,
        default: true
      },
      index: {
        type: Number,
        required: true,
      },
      length: {
        type: Number,
        required: true,
      },
      gap: {
        type: Number,
        required: true,
      },
      maxWidth: {
        type: Number,
        required: true,
      }
    },
    data() {
      return {
        handle: null,
        handleEnd: null,
        handleEndPosition: (Number(this.$props.data.left.split('px')[0])) + (Number(this.$props.data.width.split('px')[0])), 
      }
    },
    watch: {},
    computed: {
      isActive() {
        return this.$props.data.handle
      },
      isHandleEnd() {
        return this.$props.index === this.$props.length && this.$props.data.active
      },
      handleStyles: function() {
        let x = (Number(this.$props.data.left.split('px')[0]))
        return {
          transform: `translate(${x}px, 64px)`
        };
      },
      canRemoveHandle: function() {
        const remove = 
          this.$props.data.active && 
          this.$props.categories[this.$props.index - 1] && this.$props.categories[this.$props.index - 1].active && this.$props.categories[this.$props.index - 1].handle && 
          (this.$props.categories[this.$props.index + 1] && this.$props.categories[this.$props.index + 1].handle || this.$props.categories[this.$props.index + 1] === undefined) ? true : false
        
        return remove
      }
    },
    methods: {
      getInfo() {
        const prev = document.querySelector("#handle-" + (this.$props.index - 1))
        const next = document.querySelector("#handle-" + (this.$props.index + 1))  || document.querySelector("#handleend-" + (this.$props.index))

        this.handle.applyBounds({
          minX: prev ? gsap.getProperty(prev, "x") + (this.$props.gap) : 0,
          maxX: next ? gsap.getProperty(next, "x") - (this.$props.gap) : this.$props.maxWidth - this.$props.gap
        });
      },
      resizePanels() {
        const deltaX = Number(this.handle.deltaX.toFixed(2))
        const target = document.querySelector("#panel-" + (this.$props.index))
        const prev = document.querySelector("#panel-" + (this.$props.index - 1))
        const age_from = Math.round(gsap.getProperty(target, "left") /  this.$props.gap)
        const prevWidth = gsap.getProperty(prev, "width")
        const prevLeft = gsap.getProperty(prev, "left")

        if (prev) gsap.set("#panel-" + (this.$props.index - 1),{width:"+="+deltaX+'px', autoRound: false});
        gsap.set("#panel-" + (this.$props.index),{left:"+="+deltaX, autoRound: false});
        gsap.set("#panel-" + (this.$props.index),{width:"-="+deltaX, autoRound: false});

        this.$emit('update', {
          index: this.$props.index,
          left: gsap.getProperty(target, "left"),
          width: gsap.getProperty(target, "width"),
          prevLeft,
          prevWidth,
          age_from,
        })
      },
      onDragEnd() {
        const target = document.querySelector("#panel-" + (this.$props.index))
        const prev = document.querySelector("#panel-" + (this.$props.index - 1))
        const age_from = Math.round(gsap.getProperty(target, "left") /  this.$props.gap)
        const prevWidth = gsap.getProperty(prev, "width")
        const prevLeft = gsap.getProperty(prev, "left")

        this.$emit('update:end', {
          index: this.$props.index,
          left: gsap.getProperty(target, "left"),
          width: gsap.getProperty(target, "width"),
          age_from,
          prevLeft,
          prevWidth
        })
      },
      getInfoEnd() {
        const prev = document.querySelector("#handle-" + (this.$props.index))
        const next = document.querySelector("#handle-" + (this.$props.index + 1))

        this.handleEnd.applyBounds({
          minX: prev ? gsap.getProperty(prev, "x") + (this.$props.gap) : 0,
          maxX: next ? gsap.getProperty(next, "x") : this.$props.maxWidth
        });
      },
      resizePanelsEnd() {
        const deltaX = Number(this.handleEnd.deltaX.toFixed(2))
        const target = document.querySelector("#panel-" + (this.$props.index))

        gsap.set("#panel-" + (this.$props.index),{width:"+="+deltaX, autoRound: false});
        const age_to = ((this.$props.data.age_from - 1) + Math.round(gsap.getProperty(target, "width")  /  this.$props.gap))
        this.$emit('update:handleend', {
          index: this.$props.index,
          age_to,
        })
      },
      onDragHandleEnd() {
        const target = document.querySelector("#panel-" + (this.$props.index))
        const age_to = ((this.$props.data.age_from - 1) + Math.round(gsap.getProperty(target, "width")  /  this.$props.gap))
        this.$emit('update:handleend', {
          index: this.$props.index,
          age_to,
          addNewHandle: true,
        })
      },
      removeHandle() {
        this.$emit('remove', {
          index: this.$props.index
        })
      }
    },
    mounted() {

      const self = this
      this.handle = new Draggable(this.$refs.resizeHandle, {
        cursor: 'col-resize',
        type: "x",
        onDragStart: this.getInfo,
        onDrag: this.resizePanels,
        onDragEnd: this.onDragEnd,
        liveSnap: true,
        snap: {
          x: function(value) {
            return Math.round(value / self.$props.gap) * self.$props.gap; 
          },
        },
      });
      this.handleEnd = new Draggable(this.$refs.resizeHandleEnd, {
        cursor: 'col-resize',
        type: "x",
        onDragStart: this.getInfoEnd,
        onDrag: this.resizePanelsEnd,
        onDragEnd: this.onDragHandleEnd,
        liveSnap: true,
        snap: {
          x: function(value) {
            return Math.round(value / self.$props.gap) * self.$props.gap; 
          },
        },
      });
    }    
  }
</script>

<style scoped>
.rangeSlider__handle.active {
  display: block;
}
.rangeSlider__handle {
  display: none;
  position: absolute;
  left: 0px;
  top: 24px;
  width: 24px;
  height: 36px;
  z-index: 22;
}
.rangeSlider__handle::before {
  content: '';
  position: absolute;
  top: calc(-100% - 25px);
  left: 11px;
  width: 1px;
  height: 64px;
  background-color: #fff;
}

.rangeSlider__handle img {
  position: absolute;
  left: 0;
  top: 4px;
  /* left: -12px; shadow */
  z-index: 1;
}
.rangeSlider__handle:hover .removeHandle {
  display: flex;
}
.removeHandle {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 4px;
  position: absolute;
  left: 0;
  bottom: -28px;
  background: #B0BEC5;
  z-index: 22;
  cursor: pointer;
}
/* .removeHandle::before {
  content: '';
  background: #B0BEC5;
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: -100%;
} */
</style>