import JSZip from 'jszip';

export async function zip(files) {
    const archive = new JSZip();

    for (const file of files) {
        archive.file(file.name, file.content64, { base64: true });
    }

    await archive.generateAsync({ type: 'base64' });
}

export function download(name, content64) {
    const ext = name.split('.').slice(-1)[0];
    const a = document.createElement('a');
    a.href = `data:application/${ext};base64,${content64}`;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export async function readTextFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => resolve({
            name: file.name,
            content: event.target.result,
            mime: file.type,
        });

        reader.onerror = (event) => reject(event);

        reader.readAsText(file);
    });
}

export function dataToCsv(data) {
    // data[row][column]
    let csvContent = '';
    for (const row of data) {
        csvContent += row.join(';');
        csvContent += '\n';
    }
    return csvContent;
}

export function downloadCsv(name, csvContent) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadXls(name, xlsContent) {
    const blob = new Blob([xlsContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.xls`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
