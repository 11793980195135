<template>
  <div :class="{ 'switch-slot': label, 'icons': icons }">
    <label class="switch">
        <input 
            v-bind="$attrs"
            type="checkbox" 
            :checked="checked"
            @change="$emit('input', $event.target.checked)"
        >
        <span class="check"></span>
        <slot></slot>
    </label>
  </div>
</template>

<script>
  export default {
    name: "BaseInputSwitch",
    inheritAttrs: false,
    props: {
      checked: {
        type: Boolean,
        required: true,
      },
      label: {
        type: Boolean,
        default: false,
      },
      icons: {
        type: Boolean,
        default: false,
      }
    },
  };
</script>

<style scoped>
.switch-slot .switch {
  min-height: 32px;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
}
.switch-slot .switch input[type="checkbox"] + .check {
  width: 60px;
  height: 32px;
  border-radius: 44px;
  padding: 0 0.2em;
  box-sizing: border-box;
}
.switch-slot .switch input[type="checkbox"]:disabled + .check {
  opacity: 0.5;
}
.switch-slot .switch input[type="checkbox"] + .check::before {
  width: 24px;
  height: 24px;
}
.switch-slot .switch input[type="checkbox"]:checked + .check::before {
  transform: translate3d(120%,0,0);
}
.icons .switch input[type="checkbox"] + .check {
  background: #455A64;
}
.icons .switch input[type="checkbox"]:checked + .check,
.icons .switch:hover input[type="checkbox"]:checked + .check {
  background: #50AE55;
}

.icons .switch input[type="checkbox"] + .check::before{
background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.43517 6L0.293087 9.14208C-0.0937865 9.52896 -0.0982959 10.161 0.288774 10.5481L1.4519 11.7112C1.83825 12.0976 2.46847 12.0964 2.85792 11.7069L6 8.56483L9.14208 11.7069C9.52896 12.0938 10.161 12.0983 10.5481 11.7112L11.7112 10.5481C12.0976 10.1617 12.0964 9.53153 11.7069 9.14208L8.56483 6L11.7069 2.85792C12.0938 2.47104 12.0983 1.83897 11.7112 1.4519L10.5481 0.288774C10.1617 -0.0975791 9.53153 -0.0963646 9.14208 0.293087L6 3.43517L2.85792 0.293087C2.47104 -0.0937865 1.83897 -0.0982959 1.4519 0.288774L0.288774 1.4519C-0.0975791 1.83825 -0.0963646 2.46847 0.293087 2.85792L3.43517 6Z' fill='%23455A64'/%3E%3C/svg%3E%0A");
}
.icons .switch input[type="checkbox"]:checked + .check::before{
  background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.454665 4.52568C0.0641411 4.91621 0.064141 5.54937 0.454665 5.93989L3.98381 9.46904L4.79508 10.2803L5.03586 10.5211C5.42639 10.9116 6.05955 10.9116 6.45008 10.5211L13.6678 3.30337C14.0583 2.91284 14.0583 2.27968 13.6678 1.88915L12.6157 0.837098C12.2252 0.446574 11.5921 0.446575 11.2015 0.837098L5.74297 6.29566L2.92093 3.47363C2.53041 3.0831 1.89724 3.0831 1.50672 3.47363L0.454665 4.52568Z' fill='%2350AE55'/%3E%3C/svg%3E%0A");
}
.switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    border-radius: 50%;
    height: 48px;
    width: 48px;
}
.switch input[type="checkbox"] {
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
}
.switch input[type="checkbox"] + .check,
.switch input[type="checkbox"] + .check::before {
    border-radius: 50%;
}
.switch input[type="checkbox"] + .check {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 2.75em;
    height: 1.575em;
    padding: .2em;
    background: #ccc;
    border-radius: 16px;
    transition: background .15s ease-out,box-shadow .15s ease-out;
}
.switch input[type="checkbox"] + .check::before {
    content: "";
    display: block;
    border-radius: 16px;
    width: 18px;
    height: 18px;
    background: #f5f5f5;
    box-shadow: 0 3px 1px 0 rgba(0,0,0,.05),0 2px 2px 0 rgba(0,0,0,.1),0 3px 3px 0 rgba(0,0,0,.05);
    transition: transform .15s ease-out;
    will-change: transform;
    transform-origin: left;
    background-repeat: no-repeat;
    background-position: center;
}
.switch input[type="checkbox"]:checked + .check::before {
    transform: translate3d(100%,0,0);
}
.switch input[type="checkbox"]:checked + .check,
.switch:hover input[type="checkbox"]:checked + .check {
    background: var(--color-dark-pastel-green);
}
</style>