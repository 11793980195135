/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        registrationOptions: { scope: '/' },
        updated(registration) {
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('serviceWorkerUpdated', { detail: registration }));
            }, 5000);
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}

// register(`${process.env.BASE_URL}/firebase-messaging-sw.js`)